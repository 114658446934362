import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import { NotificationsErrorProvider } from '../../notifications/notifications.error.provider';
import { WebAuthService } from '../auth/web-auth.service';
import { HttpClient } from '@angular/common/http';
import { WebsocketResponseData, WebsocketServerResponse, WebsocketStates } from './WebsocketConstants';
import { environment } from '../../../environments/environment';
import Ably from 'ably/callbacks';
import { JobWrapper } from '@flutaro/package/lib/model/Job';

export const pushChannelPath: string = environment.routingApiURL + `/data/push`;

@Injectable()
export class FlutaroWebsocketService {
	realtime: Ably.Realtime;
	channel: Ably.Types.RealtimeChannelCallbacks;

	jobSocket: Subject<any> = new Subject<any>();
	driverSocket: Subject<any> = new Subject<any>();
	clientSocket: Subject<any> = new Subject<any>();
	synonymSocket: Subject<any> = new Subject<any>();
	vehicleSocket: Subject<any> = new Subject<any>();
	relationSocket: Subject<any> = new Subject<any>();
	noteSocket: Subject<any> = new Subject<any>();
	contractorSocket: Subject<any> = new Subject();

	$websocketRecoveredFromFailingState: Subject<boolean> = new Subject<boolean>();
	filterEmail: string;

	constructor(
		private errorProvider: NotificationsErrorProvider,
		private auth: WebAuthService,
		public http: HttpClient,
	) {
		this.initService();
	}

	initService() {
		this.auth.$userIsAuthorized.subscribe(($userIsAuthorized) => {
			console.log(
				`FlutaroWebsocketService, ${new Date().toISOString()} establishing/closing Ably connection due to $userIsAuthorized change`,
			);
			$userIsAuthorized ? this.createOrUpdateAblyRealtimeConnection() : this.closeAblyRealtimeConnection();
		});
	}

	closeAblyRealtimeConnection() {
		if (this.realtime) {
			console.log(`closeAblyRealtimeConnection, current realtime.connection-State: ${this.realtime.connection.state}`);
			this.realtime.connection.close();
			console.log(
				`closeAblyRealtimeConnection, closed Ably Connection - current realtime.connection-State: ${this.realtime.connection.state}`,
			);
		}
		if (this.channel) {
			this.channel.unsubscribe();
			this.channel.off();
		}
	}

	private async createOrUpdateAblyRealtimeConnection() {
		if (!this.auth.$userIsAuthorized.getValue()) {
			console.log('createOrUpdateAblyRealtimeConnection, User not authorized! Aborting Websocket Connection init ');
			return;
		}
		if (this.realtime) {
			console.log('createOrUpdateAblyRealtimeConnection, already initialized');
			return;
		}
		console.log(
			`createOrUpdateAblyRealtimeConnection, creating Ably Connection. Current ablyConnectionCurrentState: ${this.realtime?.connection?.state}`,
		);
		const clientOptions = {
			authCallback: async (tokenParams, callback) => {
				try {
					const tokenRequest = await lastValueFrom(this.http.get(pushChannelPath)); // Make a network request to your server
					console.log(`ably tokenRequest result: ${tokenRequest}`);
					callback(null, tokenRequest);
				} catch (error) {
					callback(error, null);
				}
			},
		};
		this.realtime = new Ably.Realtime(clientOptions);
		this.realtime.connection.on((state) => {
			switch (state.current) {
				case WebsocketStates.CONNECTED: {
					console.log(`createAblyRealtimeConnection, CONNECTED`);
					this.listenToCompanyChannel();
					this.setWebsocketConnectionAsRecovered();
					break;
				}
				case WebsocketStates.FAILED: {
					console.log(`createAblyRealtimeConnection, FAILED - Current WebsocketState: ${state.current}`);
					this.errorProvider.temporaryWebSocketLostLostMessage();
					break;
				}
				default: {
					console.log(`createAblyRealtimeConnection, Current WebsocketState: ${state.current}`);
				}
			}
		});
	}

	private listenToCompanyChannel() {
		this.channel = this.realtime.channels.get(this.auth.getUserProfile().company);
		this.channel.subscribe(`update`, (msg) => {
			this.setCorrectSocketOnNewData(msg);
		});
	}

	private setCorrectSocketOnNewData(socketData: WebsocketServerResponse) {
		const data: WebsocketResponseData<any> = JSON.parse(socketData.data);
		const userProfile = this.auth.$userProfile.getValue();

		// Just to be sure ...
		if (userProfile.company !== data.storable?.company) {
			return;
		}

		if (this.auth.$vehicleIdsFilter.getValue()) {
			console.debug(`setCorrectSocketOnNewData, detected readonly-filtering user - checking filter action status ...`);
			switch (data.typeName) {
				case 'JobWrapper':
					if (!this.auth.$vehicleIdsFilter.getValue().includes((data.storable as JobWrapper).vehicleId)) {
						console.debug(`setCorrectSocketOnNewData, JobWrapper readonly-filtering activated - aborting`);
						return;
					}
					break;
				case 'Vehicle':
					if (!this.auth.$vehicleIdsFilter.getValue().includes((data.storable as JobWrapper).backendId)) {
						console.debug(`setCorrectSocketOnNewData, Vehicle readonly-filtering activated - aborting`);
						return;
					}
					break;
				default:
					console.log(`setCorrectSocketOnNewData, unsupported data type for readonly filtering - aborting`);
					return;
			}
			console.debug(
				`setCorrectSocketOnNewData, data object passed readonly-filtering - proceeding with data processing`,
			);
		}

		switch (data.typeName) {
			case 'JobWrapper':
				this.jobSocket.next(data);
				break;
			case 'Driver':
				this.driverSocket.next(data);
				break;
			case 'Synonym':
				this.synonymSocket.next(data);
				break;
			case 'Client':
				this.clientSocket.next(data);
				break;
			case 'Vehicle':
				this.vehicleSocket.next(data);
				break;
			case 'Relation':
				this.relationSocket.next(data);
				break;
			case 'Contractor':
				this.contractorSocket.next(data);
				break;
			case 'Note':
				this.noteSocket.next(data);
				break;
			default:
				console.error('Ably Realtime sent a Type we dont know: ' + data.typeName);
				break;
		}
	}

	private setWebsocketConnectionAsRecovered() {
		if (this.errorProvider.websocketHasError) {
			this.$websocketRecoveredFromFailingState.next(true);
			console.log(`deactivateBrokenSocketConnection, $websocketRecoveredFromFailingState = true emit`);
			this.errorProvider.hideNoWebSocketConnectionErrorDisplay();
		}
	}
}

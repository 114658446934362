<mat-card-title>
	<p *ngIf="isNew" class="driverDialogAppointmentLabel">{{ 'SCHEDULE_NEW' | translate }}</p>
	<p *ngIf="!isNew" class="driverDialogAppointmentLabel">{{ 'SCHEDULE_EDIT' | translate }}</p>
</mat-card-title>

<mat-card-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
	<div fxLayout="row" fxLayoutAlign="center center" fxFlexFill class="margin0">
		<mat-icon class="marginRight10">
			<i class="material-icons greyColorMediumSize">{{
				type === TYPES.DRIVER_SCHEDULE ? 'person' : 'local_shipping'
			}}</i>
		</mat-icon>
		<!-- vehicle needs adjustment-->
		<mat-form-field fxFlex="100" style="width: 100%">
			<input
				#entitySearch
				[(ngModel)]="_entityName"
				[disabled]="disabled"
				[matAutocomplete]="scheduleEntity"
				matInput
				placeholder="{{ (type === TYPES.DRIVER_SCHEDULE ? 'DRIVER' : 'VEHICLE') | translate }}"
				style="color: #505759"
				type="text"
			/>
			<button
				(click)="clearEntityInSchedule($event)"
				[disabled]="disabled"
				aria-label="Clear"
				class="appClearButton"
				mat-button
				type="button"
				matSuffix
			>
				<i class="material-icons synonymClearButtonIcon">close</i>
			</button>
		</mat-form-field>
		<mat-autocomplete #scheduleEntity="matAutocomplete" (optionSelected)="setEntityInSchedule($event.option.value)">
			<mat-option
				*ngFor="
					let vehicle of vehicles
						| availableInIntervalForSchedule: type:schedule:vehicle:drivers
						| filterElementsBy: entitySearch.value?.toString()
				"
				[disabled]="disabled"
				[value]="vehicle"
			>
				<span
					>{{ vehicle.toString() }}
					{{ type === TYPES.TRAILER_SCHEDULE ? '(' + (vehicle.carType | translate) + ')' : '' }}</span
				>
			</mat-option>
		</mat-autocomplete>
	</div>

	<span
		*ngIf="schedule | entitiesFollowingSchedule: vehicles"
		class="driverHasFollowingScheduleInfo"
		fxLayout="row"
		fxLayoutAlign="start center"
	>
		<mat-icon>warning</mat-icon>
		{{ (schedule.type === 'driverSchedules' ? 'DRIVER' : 'TRAILER') | translate }}
		{{ 'HAS_FOLLOWING_SCHEDULE_AT_VEHICLE' | translate }}
		{{ ((schedule | entitiesFollowingSchedule: vehicles)?.id | vehicleForScheduleId: vehicles)?.licensePlate }}
	</span>

	<div fxLayout="row" fxLayoutAlign="space-around center" fxFlexFill>
		<app-datetimepicker
			[(date)]="schedule.startDate"
			[allowDynamicWidth]="true"
			[max]="vehicle | scheduleMaxDate: schedule:vehicles:true"
			[min]="vehicle | scheduleMinDate: schedule:vehicles:true"
			[required]="true"
			[type]="'datetime'"
			placeholder="{{ 'START' | translate }}"
		>
		</app-datetimepicker>

		<app-datetimepicker
			[(date)]="schedule.endDate"
			[allowDynamicWidth]="true"
			[max]="vehicle | scheduleMaxDate: schedule:vehicles:false"
			[min]="vehicle | scheduleMinDate: schedule:vehicles:false"
			[type]="'datetime'"
			[required]="!!(vehicle | scheduleMaxDate: schedule:vehicles:false)"
			placeholder="{{ 'END' | translate }}"
		>
		</app-datetimepicker>
	</div>
</mat-card-content>

<mat-card-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
	<button (click)="abortChanges()" class="importButton mat-raised" mat-button type="button">
		<mat-icon><i class="material-icons">close</i></mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="storeChanges()"
		[disabled]="
			!schedule.entityId ||
			!schedule.startDate ||
			(!!(vehicle | scheduleMaxDate: schedule:vehicles:false) && !schedule.endDate)
		"
		class="importButtonDone mat-primary_flutaro"
		mat-button
		type="button"
	>
		<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-card-actions>

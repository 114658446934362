import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ExternalJobInCorrection } from '../../interfaces/ExternalJob/ExternalJobWeb';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';

import { JobDestination } from '@flutaro/package/lib/model/Job';

import { objToMap } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { JobHelperService } from '../../job/job.helper.service';
import { setDestinationsPlannedDatesToEarliestValidDate } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { areDestinationsPositionsValid } from '@flutaro/package/lib/functions/job/JobValidationFunctions';

@Component({
	selector: 'app-external-job-import-component',
	templateUrl: './external-job-import-component.component.html',
	styleUrls: ['./external-job-import-component.component.css', '../../job/form/job.destination.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalJobImportComponentComponent extends AppBasicComponent {
	@Input() externalJobs: ExternalJobInCorrection[];

	allJobsSelected: boolean = true;
	indeterminateState: boolean = false;

	constructor(
		private cd: ChangeDetectorRef,
		private jobHelper: JobHelperService,
	) {
		super();
	}

	triggerJobsComponentsCD(extJob: ExternalJobInCorrection) {
		extJob.fixedJobDestinations = extJob.fixedJobDestinations.slice();
		this.cd.detectChanges();
		setDestinationsPlannedDatesToEarliestValidDate(extJob.fixedJobDestinations);
	}

	async updateJobOnChangedDestinationPosition(
		updatedDestination: JobDestination,
		index: number,
		extJob: ExternalJobInCorrection,
	) {
		console.log(`updateJobOnChangedDestinationPosition starting for job ${extJob.identifier}`);
		extJob.fixedJobDestinations[index] = updatedDestination;
		if (!areDestinationsPositionsValid(extJob.fixedJobDestinations)) {
			console.debug(
				`updateJobOnChangedDestinationPosition, not all destinations have valid positions. Cant update job yet`,
			);
			return;
		}
		await this.jobHelper.getAndUpdateExternalJobsDistances(extJob);
		this.triggerJobsComponentsCD(extJob);
		console.log(`updateJobOnChangedDestinationPosition finished`);
	}

	attributesToString(attributes) {
		let attributeString = '';
		if (!attributes) return attributeString;
		let attributesAsMap = objToMap(attributes);
		attributesAsMap.forEach((value, key) => {
			if (attributeString) attributeString = attributeString + ', ';
			attributeString = attributeString + key + ' : ' + value;
		});
		return attributeString;
	}

	changeAllCheckedStatus() {
		this.externalJobs.forEach((extJob) => (extJob.isChecked = this.allJobsSelected));
		//this.changeDetectorRef.markForCheck();
	}
}

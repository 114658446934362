import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportExcelComponent } from './TableComponent/import.excel.component';
import { ImportExcelFileProvider } from './import.excel.file.provider';
import { ImportExcelStorageProvider } from './import.excel.store.provider';
import { ImportDataTableSettingsProvider } from './import.table.settings.provider';
import { FormsModule } from '@angular/forms';
import { NotificationModule } from '../notifications/notification.module';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { ImportValidationProvider } from './import.validation.provider';
import { FlutaroImportStatusComponent } from './StatusComponent/import.status.component';
import { HotTableModule } from '@handsontable/angular';
import { ExternalJobImportComponentComponent } from './external-job-import-component/external-job-import-component.component';
import { ComponentsModule } from '../app/components/components.module';
import { JobModule } from '../job/job.module';

@NgModule({
	declarations: [ImportExcelComponent, FlutaroImportStatusComponent, ExternalJobImportComponentComponent],
	exports: [ImportExcelComponent, FlutaroImportStatusComponent, ExternalJobImportComponentComponent],
	imports: [
		CommonModule,
		FlutaroCustomMaterialModule,
		HotTableModule,
		FormsModule,
		NotificationModule,
		ComponentsModule,
		JobModule,
	],
	providers: [
		ImportExcelFileProvider,
		ImportExcelStorageProvider,
		ImportDataTableSettingsProvider,
		ImportValidationProvider,
	],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class ImportModule {}

<div fxLayout="column" *ngIf="driver" class="donotdrag fullHeight">
	<div class="donotdrag fullHeight">
		<div *ngIf="driver.appointments" fxLayout="row">
			<div
				*ngFor="let appointment of driver.appointments | driverAppointmentsInTimetable: timetable"
				[class.timetableAppointmentStartsTheDayBefore]="!(appointment.startDate | marginInTimetable: timetable)"
				[matTooltipPosition]="'below'"
				[style.marginLeft]="appointment.startDate | marginInTimetable: timetable"
				[style.width]="appointment.startDate | widthInTimetable: appointment.endDate:timetable"
				class="appointmentDriver donotdrag"
				matTooltip="{{ appointment.note }} {{ appointment.location }}"
			>
				<span class="timetableDriverAppointmentLabel">{{ 'APPOINTMENT' | translate }}</span>
			</div>
		</div>
		<div
			*ngIf="timetableEntry.driverEntry.workweekStart"
			[class.absenceWithCargo]="driver.keepCargoDuringAbsence"
			[style.marginLeft]="timetableEntry.driverEntry.workweekStart.marginLeft"
			[style.width]="timetableEntry.driverEntry.workweekStart.width"
			class="workweekDriver donotdrag timetableDriverWeekBorderLeft"
		>
			<div class="timetableDriverWorkweek">
				<span>{{ 'WEEKEND' | translate }}</span>
			</div>
			<div *ngIf="driver.keepCargoDuringAbsence" class="workweekKeepCargoText">
				<span>{{ 'KEEPCARGODURINGABSENCE' | translate }}</span>
			</div>
		</div>
		<div
			*ngIf="timetableEntry.driverEntry.workweekEnd"
			[class.absenceWithCargo]="driver.keepCargoDuringAbsence"
			[style.marginLeft]="timetableEntry.driverEntry.workweekEnd.marginLeft"
			[style.width]="timetableEntry.driverEntry.workweekEnd.width"
			class="workweekDriver donotdrag timetableDriverWeekBorderRight"
		>
			<div class="timetableDriverWorkweek">
				<span>{{ 'WEEKEND' | translate }}</span>
			</div>
			<div *ngIf="driver.keepCargoDuringAbsence" class="workweekKeepCargoText">
				<span>({{ 'KEEPCARGODURINGABSENCE' | translate }}</span>
			</div>
		</div>
	</div>
	<div *ngIf="timetableEntry.blockEntry" class="donotdrag fullHeight">
		<div
			[class.blockedDriverOverlapping]="timetableEntry.blockEntry.isOverlapping"
			[class.timetableDriverBlockStartsTheDayBefore]="
				timetableEntry.blockEntry.marginLeft === '0%' || timetableEntry.blockEntry.marginLeft === '0px'
			"
			[matTooltipPosition]="'below'"
			[style.border-color]="timetableEntry.blockEntry.capacityColor1"
			[style.marginLeft]="driver.block.blockStartDate | marginInTimetable: timetable"
			[style.outline-color]="timetableEntry.blockEntry.capacityColor2"
			[style.width]="driver.block.blockStartDate | widthInTimetable: driver.block.blockEndDate:timetable"
			class="blockedDriver"
			matTooltip="{{ 'BLOCKENTRY_TT' | translate }}"
		>
			<div class="driverBlockLabel">
				<span
					>{{ 'BLOCKED' | translate }}
					<span *ngIf="driver.block.blockReasonDescription"> ({{ driver.block.blockReasonDescription }}) </span>
				</span>
			</div>
			<div class="driverBlockLabelPLZ">
				<span *ngIf="driver.block.blockZip"> ({{ driver.block.blockZip }}) </span>
			</div>
		</div>
	</div>
</div>

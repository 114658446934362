<mat-form-field fxFlex="100">
	<mat-select
		placeholder="{{ 'REGULATINGTIMEINMINUTES' | translate }}"
		[(ngModel)]="regulating"
		(ngModelChange)="regulatingChange.emit(regulating)"
		name="Stellzeit"
	>
		<mat-option class="mat-option-odd" [value]="0">00:00 h </mat-option>
		<mat-option [value]="30">00:30 h</mat-option>
		<mat-option class="mat-option-odd" [value]="60">01:00 h </mat-option>
		<mat-option [value]="90">01:30 h</mat-option>
		<mat-option class="mat-option-odd" [value]="120">02:00 h </mat-option>
		<mat-option [value]="150">02:30 h</mat-option>
		<mat-option class="mat-option-odd" [value]="180">03:00 h </mat-option>
		<mat-option [value]="210">03:30 h</mat-option>
		<mat-option class="mat-option-odd" [value]="240">04:00 h </mat-option>
	</mat-select>
</mat-form-field>

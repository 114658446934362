<mat-form-field fxFlex="100">
	<mat-select
		[(ngModel)]="timewindow"
		placeholder="{{ 'TIMEWINDOW' | translate }}"
		(ngModelChange)="timewindowChange.emit(timewindow)"
	>
		<!-- for safety reasons select empty value-->
		<mat-option class="mat-option-odd" [value]="0">00:00 h </mat-option>
		<mat-option [value]="30">00:30 h</mat-option>
		<mat-option class="mat-option-odd" [value]="60">01:00 h </mat-option>
		<mat-option [value]="90">01:30 h</mat-option>
		<mat-option class="mat-option-odd" [value]="120">02:00 h </mat-option>
		<mat-option [value]="180">03:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="240">04:00 h </mat-option>
		<mat-option [value]="300">05:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="360">06:00 h </mat-option>
		<mat-option [value]="420">07:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="480">08:00 h </mat-option>
		<mat-option [value]="540">09:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="600">10:00 h </mat-option>
		<mat-option [value]="660">11:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="720">12:00 h </mat-option>
		<mat-option [value]="780">13:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="840">14:00 h </mat-option>
		<mat-option [value]="900">15:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="960">16:00 h </mat-option>
		<mat-option [value]="1020">17:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="1080">18:00 h </mat-option>
		<mat-option [value]="1140">19:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="1200">20:00 h </mat-option>
		<mat-option [value]="1260">21:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="1320">22:00 h </mat-option>
		<mat-option [value]="1380">23:00 h</mat-option>
		<mat-option class="mat-option-odd" [value]="1440">24:00 h </mat-option>
	</mat-select>
</mat-form-field>

import { Injectable } from '@angular/core';
import differenceBy from 'lodash-es/differenceBy';

import { FlutaroTimetableFilterService } from '../timetable/data/timetable.data.provider';
import {
	FilterDriverEntryForUninformedJobs,
	filterForOverlappingTimetableEntries,
	getVehiclesWithStatus,
} from '../timetable/data/TimetableFilterFunctions';
import { JobFilterStatus, TimetableFilteredEntry, VehicleFilterStatus } from '../timetable/data/TimetableFilterClasses';
import { JobStatisticsSummary, JobSummary } from '../statistics/Statistics';
import {
	calculateJobsStatisticsForTimeRange,
	getDriversWithMismatchingVehicleType,
} from '../statistics/statistics.functions';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { ChartStatusData } from './SidenavClasses';
import { TranslateService } from '@ngx-translate/core';

class DriverChartStatistics {
	totalDriverWithChartStatus: number = 0;
	uninformedDrivers: number = 0;
	overlappingDrivers: number = 0;
	invalidDrivers: number = 0;
	vehicleWithStatus: number = 0;
	mismatchedVehicleType: number = 0;
}

@Injectable()
export class InfoSidenavProvider {
	statistics: JobStatisticsSummary = new JobStatisticsSummary();
	jobStatistics: JobSummary = new JobSummary();
	public jobStatusStatistics: ChartStatusData[] = [];
	public truckStatusStatistics: ChartStatusData[] = [];
	truckStatistics: DriverChartStatistics = new DriverChartStatistics();

	constructor(private timetableFilterService: FlutaroTimetableFilterService, private translate: TranslateService) {}

	/**
	 * This filter is activated by the "reset Filter"-Button
	 */
	public resetFilteredData() {
		// Reset Status since its filtering is handled inside the createFilteredTimetableMap function
		this.timetableFilterService.resetFilterStore();
		this.timetableFilterService.createFilteredTimetableMap();
	}

	/**
	 * Filter reset happens on Tab-Change
	 */
	public resetFilter() {
		this.timetableFilterService.resetFilterStore();
	}

	public calculateInfoSidenavData(currentJobs: JobWrapper[], startDate: Date, endDate: Date) {
		this.calculateInfoJobStatusStatistics(currentJobs, startDate, endDate);
		this.calculateDriverStatistics(this.timetableFilterService.$filteredDriverJobsMap.getValue());
	}

	public calculateInfoJobStatusStatistics(currentJobData: JobWrapper[], startDate: Date, endDate: Date) {
		this.jobStatistics = calculateJobsStatisticsForTimeRange(currentJobData, startDate, endDate);
		this.jobStatusStatistics = [];

		if (this.jobStatistics.invalidPlanned > 0) {
			this.jobStatusStatistics.push({
				value: this.jobStatistics.invalidPlanned,
				name: this.translate.instant('INVALID'),
				extra: { key: 'invalid' },
			});
		}
		if (this.jobStatistics.planned > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_PLANNED'),
				value: this.jobStatistics.planned,
				extra: { key: JobStatus.PLANNED },
			});
		}
		if (this.jobStatistics.sent > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_SENT'),
				value: this.jobStatistics.sent,
				extra: { key: JobStatus.SENT },
			});
		}
		if (this.jobStatistics.received > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_RECEIVED'),
				value: this.jobStatistics.received,
				extra: { key: JobStatus.RECEIVED },
			});
		}
		if (this.jobStatistics.accepted > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_ACCEPTED'),
				value: this.jobStatistics.accepted,
				extra: { key: JobStatus.ACCEPTED },
			});
		}
		if (this.jobStatistics.started > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_STARTED'),
				value: this.jobStatistics.started,
				extra: { key: JobStatus.STARTED },
			});
		}
		if (this.jobStatistics.declined > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_DECLINED'),
				value: this.jobStatistics.declined,
				extra: { key: JobStatus.DECLINED },
			});
		}
		if (this.jobStatistics.done > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('JOB_STATUS_DONE'),
				value: this.jobStatistics.done,
				extra: { key: JobStatus.DONE },
			});
		}
		if (this.jobStatistics.withoutCosts > 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('WITHOUT_COSTS'),
				extra: { key: 'withoutCosts' },
				value: this.jobStatistics.withoutCosts,
			});
		}
		if (this.jobStatusStatistics.length === 0) {
			this.jobStatusStatistics.push({
				name: this.translate.instant('ALL_OK'),
				extra: { key: 0 },
				value: this.jobStatistics.validPlanned > 0 ? this.jobStatistics.validPlanned : 1,
			});
		}
	}

	public calculateDriverStatistics(timetableEntries: TimetableFilteredEntry[]) {
		this.truckStatusStatistics = [];
		this.truckStatistics = new DriverChartStatistics();
		let overlappingTrucks: TimetableFilteredEntry[] = filterForOverlappingTimetableEntries(timetableEntries);
		let uninformedDrivers: TimetableFilteredEntry[] = FilterDriverEntryForUninformedJobs(timetableEntries);
		let vehicleWithStatusses: TimetableFilteredEntry[] = getVehiclesWithStatus(timetableEntries);

		const vehicleTypeMismatching = getDriversWithMismatchingVehicleType(timetableEntries);
		this.truckStatistics.mismatchedVehicleType = vehicleTypeMismatching.length;

		this.truckStatistics.uninformedDrivers = uninformedDrivers.length;
		this.truckStatistics.overlappingDrivers = overlappingTrucks.length;
		this.truckStatistics.vehicleWithStatus = vehicleWithStatusses.length;
		let totalDrivers: TimetableFilteredEntry[] = uninformedDrivers;
		totalDrivers = totalDrivers.concat(differenceBy(overlappingTrucks, totalDrivers, 'driverEntry.driver.backendId'));
		totalDrivers = totalDrivers.concat(
			differenceBy(vehicleTypeMismatching, totalDrivers, 'driverEntry.driver.backendId'),
		);
		let newInvalidDrivers = [];
		this.truckStatistics.totalDriverWithChartStatus = newInvalidDrivers.length + totalDrivers.length;
		if (this.truckStatistics.totalDriverWithChartStatus === 0) {
			timetableEntries.length > 0
				? (this.truckStatistics.totalDriverWithChartStatus = timetableEntries.length)
				: (this.truckStatistics.totalDriverWithChartStatus = 1);
		}

		if (this.truckStatistics.uninformedDrivers > 0) {
			this.truckStatusStatistics.push({
				extra: { key: 'Uninformiert' },
				name: 'Uninformiert',
				value: this.truckStatistics.uninformedDrivers,
			});
		}
		if (this.truckStatistics.overlappingDrivers > 0) {
			this.truckStatusStatistics.push({
				extra: { key: VehicleFilterStatus.VEHICLE_WITH_OVERLAPPING },
				name: 'Doppelbelegt',
				value: this.truckStatistics.overlappingDrivers,
			});
		}
		if (this.truckStatistics.vehicleWithStatus > 0) {
			this.truckStatusStatistics.push({
				extra: { key: VehicleFilterStatus.VEHICLE_WITH_STATUS },
				name: 'Status vorhanden',
				value: this.truckStatistics.vehicleWithStatus,
			});
		}
		if (this.truckStatistics.mismatchedVehicleType > 0) {
			this.truckStatusStatistics.push({
				extra: { key: VehicleFilterStatus.VEHICLETYPE_MISMATCHING },
				name: 'Fahrzeugtyp inkompatibel',
				value: this.truckStatistics.mismatchedVehicleType,
			});
		}
		// Show all Drivers are ok if no driver has an error status
		if (this.truckStatusStatistics.length === 0) {
			this.truckStatusStatistics.push({
				extra: { key: 'Alle ok' },
				name: 'Alle ok',
				value: this.truckStatistics.totalDriverWithChartStatus,
			});
		}
	}

	filterJobStatusData(key: JobFilterStatus | JobStatus) {
		if (!key) return;
		if (key === JobFilterStatus.WITHOUT_COSTS || key === JobFilterStatus.INVALID) {
			this.timetableFilterService.filterViewDataForAppJobStatus(key);
			return;
		}
		this.timetableFilterService.filterViewDataForJobStatus(key as JobStatus);
	}

	filterDriverStatusData(status: VehicleFilterStatus) {
		this.timetableFilterService.filterViewDataForVehicleStatus(status);
	}
}

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { addDays, differenceInHours } from 'date-fns';
import { Driver, DriverBlock } from '@flutaro/package/lib/model/Driver';

@Component({
	selector: 'app-driver-block',
	templateUrl: './sidenav.driver.block.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['sidenav.driver.block.component.scss'],
})
export class SidenavDriverBlockComponent implements OnInit {
	driver: Driver;
	block: DriverBlock;
	isBlockedFor24Hours: boolean = true;
	isDateValid: boolean = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData,
		public dialogRef: MatDialogRef<SidenavDriverBlockComponent>,
		private driverService: FlutaroDriverService,
	) {}

	ngOnInit() {
		this.driver = this.dialogData.driver;
		if (!this.driver.block) {
			this.block = new DriverBlock();
		} else {
			this.block = <DriverBlock>{ ...this.driver.block };
			this.isBlockedFor24Hours = differenceInHours(this.block.blockEndDate, this.block.blockStartDate) === 24;
		}
	}

	saveBlock() {
		if (this.block.blocked && this.block.blockZip && this.block.blockZip.length < 5) return;
		if (this.isBlockedFor24Hours) this.block.blockEndDate = addDays(this.block.blockStartDate, 1);
		if (!this.block.blocked) this.block = null;
		this.driver.block = this.block;
		this.driverService.store(this.driver);
		this.dialogRef.close();
	}

	onAbort() {
		this.dialogRef.close();
	}
}

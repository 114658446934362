import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FlutaroDataServerlogService } from './data.server.log.service';
import { FlutaroJobService } from './data.job.service';
import { SERVER_STATUS, ServerLog } from './ServerLog';
import { WebAuthService } from '../../app/auth/web-auth.service';
import { ExternalJobsProvider } from '../../interfaces/ExternalJob/externalJobs.provider';
import { CompanyService } from '../../company/company.service';
import { ExternalJob, ExternalJobsDialogData } from '@flutaro/package/lib/model/ExternalJob';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExternalJobDialogComponent } from '../../interfaces/ExternalJob/externalJob.dialog.component';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroVehicleService } from './data.vehicle.service';
import { FlutaroDriverService } from './data.driver.service';

@Injectable({
	providedIn: 'root',
})
export class InterfaceService {
	public $validImportLogs: BehaviorSubject<ServerLog[]> = new BehaviorSubject<ServerLog[]>([]);
	public showImportMessage = true;
	protected validJobName = 'com.flutaro.routing.api.model.objects.JobWrapper';

	constructor(
		private serverLogs: FlutaroDataServerlogService,
		private jobService: FlutaroJobService,
		private authFb: WebAuthService,
		private externalJobsProvider: ExternalJobsProvider,
		private companyService: CompanyService,
		private notifications: FlutaroNotificationsProvider,
		private dialog: MatDialog,
		private translate: TranslateService,
		private vehicleProvider: FlutaroVehicleService,
		private driverProvider: FlutaroDriverService,
	) {
		this.authFb.$userIsAuthorized.subscribe(async (isAuthorized) => {
			if (!isAuthorized || !this.authFb.getUserProfile().hasApiRole || this.authFb.getUserProfile().hasReadOnlyRole)
				return;

			this.serverLogs.activateServerLogService();
			this.serverLogs.$newServerEventsSubject.subscribe((newEvents) => this.checkForInterfaceData(newEvents));
			this.externalJobsProvider.initExternalJobsProvider();
		});
	}

	async showExternalJobsDialog() {
		const externalJobs = await this.externalJobsProvider.requestExternalJobsAndLock();
		if (!externalJobs.length) {
			this.notifications.showBasicSnackBar(this.translate.instant('EXTERNALJOBS_ALL_JOBS_IN_CORRECTION'));
			this.externalJobsProvider.resetExternalJobsCounter();
			return;
		}
		let externalJobDialogConfig = new MatDialogConfig();
		externalJobDialogConfig.data = new ExternalJobsDialogData(
			externalJobs,
			this.companyService.$companySettings.getValue(),
			this.authFb.$userProfile.getValue(),
			this.vehicleProvider.getData(),
			this.driverProvider.getData(),
		);
		let dialogRef = this.dialog.open(ExternalJobDialogComponent, externalJobDialogConfig);
		const result: ExternalJob[] | undefined = await lastValueFrom(dialogRef.afterClosed());
		if (!result?.length) {
			return;
		}
		await this.externalJobsProvider.ctrlExternalJobsSending(result);
		this.externalJobsProvider.updateExternalJobsCounterState();
	}

	hideInterfaceNotificationDisplay() {
		this.showImportMessage = false;
	}

	/**
	 * Checks for valid imported Data and imports all imported JobWrappers
	 * @param importEvents
	 */
	protected checkForValidInterfaceImports(importEvents: ServerLog[]) {
		this.$validImportLogs.next(
			importEvents.filter((event) => event.status === SERVER_STATUS.CREATED && event.name === this.validJobName),
		);
	}

	/**
	 * Requests the JobWrapper for each Item in validImportLogs and empties validImportLogs afterwards
	 */
	protected getValidJobWrappers() {
		this.$validImportLogs.getValue().forEach((importedJobLog) => {
			this.jobService.getElementByIdFromServer(importedJobLog.relativeId);
		});
	}

	/**
	 * On updated serverEvents checks if an interface-import Action is required
	 */
	private async checkForInterfaceData(newEvents: ServerLog[]) {
		this.checkForValidInterfaceImports(newEvents);
		this.externalJobsProvider.updateExternalJobsCounterState();
		if (this.$validImportLogs.getValue().length) {
			this.getValidJobWrappers();
			await flutaroWait(5000);
			this.$validImportLogs.next([]);
		}
	}
}

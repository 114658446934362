import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	forwardRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CargoContent } from '@flutaro/package/lib/model/CargoClasses';

@Component({
	selector: 'app-child-cargo',
	templateUrl: './child-cargo.component.html',
	styleUrls: ['./child-cargo.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChildCargoComponent),
			multi: true,
		},
	],
})
export class ChildCargoComponent implements OnChanges, AfterContentInit {
	@Input() childCargo: CargoContent[];
	@Input() disabled: boolean;
	@Output() childCargoChange = new EventEmitter<FormArray>();
	@Output() isChildCargoInputChange = new EventEmitter<boolean>();
	childCargoForm: FormGroup;
	childCargoControls: FormGroup;
	childCargoStep: number;

	constructor(private fb: FormBuilder) {}

	get childCargoRows(): FormArray {
		return this.childCargoForm.get('childCargoRows') as FormArray;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['cargo'] && this.childCargo) {
			if (this.childCargo.length === 0) {
				console.log('No Cargo in Job. Closing Input.');
				this.isChildCargoInputChange.next(false);
			}
		}
	}

	ngAfterContentInit() {
		this.initializeFormFields();
		this.childCargoControls = <FormGroup>this.childCargoForm.controls.childCargoRows;
	}

	writeValue(value: any) {
		this.childCargo = value;
	}

	propagateChange = (_: any) => {};

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {}

	addCargo(): void {
		const control = <FormArray>(<FormArray>this.childCargoForm.get('childCargoRows'));
		control.push(this.initCargo(Array(new CargoContent()))[0]);
	}

	removeCargo(cargoIndex: number): void {
		const control = <FormArray>(<FormArray>this.childCargoForm.get('childCargoRows'));
		control.removeAt(cargoIndex);
		this.isChildCargoInputChange.next(false);
	}

	setCargoInputstate(index: number, state: string): void {
		this.childCargoStep = index;
		if (state === 'open') this.isChildCargoInputChange.emit(true);
		if (state === 'close') {
			this.childCargoChange.emit(<FormArray>this.childCargoForm.controls.childCargoRows);
			this.isChildCargoInputChange.emit(false);
		}
	}

	nextCargo(): void {
		this.childCargoStep++;
	}

	prevCargo(): void {
		this.childCargoStep--;
	}

	private initializeFormFields(): void {
		this.childCargoForm = this.fb.group({
			childCargoRows: this.fb.array(this.initCargo(this.childCargo)),
		});
	}

	private initCargo(cargos: CargoContent[]): FormGroup[] {
		let fbGroup = [];
		if (!cargos) return fbGroup;
		cargos.forEach((cargo) => {
			fbGroup.push(
				this.fb.group({
					amount: new FormControl({ value: cargo.amount, disabled: this.disabled }, Validators.compose([])),
					loadingEquipmentType: new FormControl(
						{
							value: cargo.loadingEquipmentType,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					netWeight: new FormControl({ value: cargo.netWeight, disabled: this.disabled }, Validators.compose([])),
					netWeightUnit: new FormControl(
						{
							value: cargo.netWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					grossWeight: new FormControl(
						{
							value: cargo.grossWeight,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					grossWeightUnit: new FormControl(
						{
							value: cargo.grossWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					exchangeLoadingEquipment: new FormControl(
						{
							value: cargo.exchangeLoadingEquipment,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					contents: new FormControl(
						{
							value: cargo.contents,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					action: new FormControl(
						{
							value: cargo.action,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					references: new FormControl(
						{
							value: cargo.references,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					tareWeight: new FormControl(
						{
							value: cargo.tareWeight,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					tareWeightUnit: new FormControl(
						{
							value: cargo.tareWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					payableWeight: new FormControl(
						{
							value: cargo.payableWeight,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					payableWeightUnit: new FormControl(
						{
							value: cargo.payableWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					valueOfGoods: new FormControl(
						{
							value: cargo.valueOfGoods,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					valueOfGoodsUnit: new FormControl(
						{
							value: cargo.valueOfGoodsUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					insuranceValue: new FormControl(
						{
							value: cargo.insuranceValue,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					insuranceValueUnit: new FormControl(
						{
							value: cargo.insuranceValueUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					cubage: new FormControl(
						{
							value: cargo.cubage,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					cubageUnit: new FormControl(
						{
							value: cargo.cubageUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					length: new FormControl(
						{
							value: cargo.length,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					lengthUnit: new FormControl(
						{
							value: cargo.lengthUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					width: new FormControl(
						{
							value: cargo.width,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					widthUnit: new FormControl(
						{
							value: cargo.widthUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					height: new FormControl(
						{
							value: cargo.height,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					heightUnit: new FormControl(
						{
							value: cargo.heightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					loadingMeters: new FormControl(
						{
							value: cargo.loadingMeters,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					amountLoadingEquipmentDelivered: new FormControl(
						{
							value: cargo.amountLoadingEquipmentDelivered,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					amountLoadingEquipmentTaken: new FormControl(
						{
							value: cargo.amountLoadingEquipmentTaken,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					customerRemarks: new FormControl(
						{
							value: cargo.customerRemarks,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					hazardIndicator: new FormControl(
						{
							value: cargo.hazardIndicator,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					wasteIndicator: new FormControl(
						{
							value: cargo.wasteIndicator,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					freezeIndicator: new FormControl(
						{
							value: cargo.freezeIndicator,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
				}),
			);
		});
		return fbGroup;
	}
}

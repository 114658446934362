export const JobLabelAttributes = [
	{
		jobLabel: 'WZ zu LOCS',
		jobColor: '#00FA9A',
	},
	{
		jobLabel: 'WZ zu KWD',
		jobColor: '#32CD32',
	},
	{
		jobLabel: 'Fläche',
		jobColor: '#228B22',
	},
	{
		jobLabel: 'Zellen',
		jobColor: '#FF0000',
	},
	{
		jobLabel: 'AVIS',
		jobColor: '#8B0000',
	},
	{
		jobLabel: 'Batterien',
		jobColor: '#FF8C00',
	},
	{
		jobLabel: 'LG-ACCU',
		jobColor: '#8A2BE2',
	},
	{
		jobLabel: 'LG-RB',
		jobColor: '#00BFFF',
	},
	{
		jobLabel: 'Antalis',
		jobColor: '#FFFF00',
	},
	{
		jobLabel: 'Sammler',
		jobColor: '#8B4513',
	},
	{
		jobLabel: 'TDDK',
		jobColor: '#7B68EE',
	},
	{
		jobLabel: 'T.T RB',
		jobColor: '#87CEEB',
	},
	{
		jobLabel: 'T.T KM',
		jobColor: '#0000CD',
	},
	{
		jobLabel: 'MBE',
		jobColor: '#800080',
	},
];

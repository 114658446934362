import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';

import { ContractorDataProvider } from '../contractor/contractor.data.provider';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { VEHICLE_BASIC_TYPES, VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { SCHEDULE_TYPES } from '@flutaro/package/lib/model/AppScheduleClasses';
import { VEHICLE_EQUIPMENT, VEHICLE_PROFILES_SPEED, loadUnits } from '@flutaro/package/lib/model/FlutaroRequirements';
import { VehicleDialogData } from 'src/app/dialog/AppDialog';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { updateFormValues } from '../form/FlutaroFormFunctions';
import { CurrencyFormValidator, LegalCharacterFormValidator } from '../form/form.validators';
import { prepareVehicleBeforeStorage } from '@flutaro/package/lib/functions/VehicleDataFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { TranslateService } from '@ngx-translate/core';
import { hasScheduleForNow } from '@flutaro/package/lib/functions/AppScheduleFunctions';

@Component({
	selector: 'app-vehicle-dialog',
	templateUrl: './vehicle.dialog.component.html',
	styleUrls: ['vehicle.dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDialogComponent implements OnInit {
	isAddressInvalid: boolean = false;
	vehicleForm: FormGroup;
	vehicle: Vehicle;
	contractorName: string;
	vehicleSpeedProfiles = VEHICLE_PROFILES_SPEED;
	VEHICLE_TYPES: VEHICLE_TYPE[] = VEHICLE_BASIC_TYPES;
	CAR_TYPES: VEHICLE_TYPE[] = Object.values(VEHICLE_TYPE);
	VehicleEquipments = Object.values(VEHICLE_EQUIPMENT);
	LoadUnits = loadUnits;
	companySettings: CompanySettings;
	// Intern
	SCHEDULES = SCHEDULE_TYPES;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: VehicleDialogData,
		public dialogRef: MatDialogRef<VehicleDialogComponent>,
		private vehicleService: FlutaroVehicleService,
		private notifications: FlutaroNotificationsProvider,
		private contractorProvider: ContractorDataProvider,
		private fb: FormBuilder,
		public cd: ChangeDetectorRef,
		private translate: TranslateService,
	) {}

	ngOnInit() {
		this.vehicle = cloneDeep(this.dialogData.data);
		this.companySettings = this.dialogData.companySettings;
		if (this.companySettings.isDriverDeactivated) this.vehicle.isDepotAddressBased = true;
		if (this.contractorProvider.getContractorForVehicle(this.vehicle.backendId))
			this.contractorName = this.contractorProvider.getContractorForVehicle(this.vehicle.backendId).name;
		this.initializeFormFields();
		console.log(`ngOnInit,
    vehicleForm is invalid ${this.vehicleForm.invalid},
    addressForm is invalid ${this.isAddressInvalid}`);
	}

	onAbort() {
		this.dialogRef.close();
	}

	changeType(newType: VEHICLE_TYPE.TRUCK | VEHICLE_TYPE.TRAILER | VEHICLE_TYPE.CAR) {
		console.log(`changeType, newType of vehicle.type: ${newType}. Updating carType to defaults `);
		switch (newType) {
			case VEHICLE_TYPE.TRUCK:
				this.updateFormValue('carType', VEHICLE_TYPE.TRUCK);
				break;
			case VEHICLE_TYPE.TRAILER:
				this.updateFormValue('carType', VEHICLE_TYPE.CURTAINSIDER);
				this.updateFormValue('vehicleEmail', '');
				this.updateFormValue('chooseEmailBasedOnVehicle', false);
				this.updateFormValue('isDepotAddressBased', false);
				this.vehicle.driverSchedules = [];
				this.vehicle.trailerSchedules = [];
				break;
			case VEHICLE_TYPE.CAR:
				this.updateFormValue('carType', VEHICLE_TYPE.TRANSPORTER);
				break;
			default:
				console.error(`changeType, unsupported value ${newType}`);
				break;
		}
		this.cd.detectChanges();
	}

	async onSave() {
		// Update trim-atrributes in Object
		updateFormValues(this.vehicleForm, this.vehicle, ['kmRate', 'fixedDailyCosts']);
		prepareVehicleBeforeStorage(this.vehicle, this.companySettings);
		await this.vehicleService.store(this.vehicle);
		this.dialogRef.close();
	}

	private updateFormValue(controlKey: string, newValue: any) {
		this.vehicleForm.controls[controlKey].patchValue(newValue);
	}

	private initializeFormFields() {
		this.vehicleForm = this.fb.group({
			name: [this.vehicle.name, [Validators.required]],
			licensePlate: [
				this.vehicle.licensePlate,
				[
					Validators.required,
					Validators.minLength(2),
					Validators.maxLength(12),
					LegalCharacterFormValidator.validateForForbiddenChars,
				],
			],
			carmanufacturer: [this.vehicle.carmanufacturer],
			vehiclePhone: [this.vehicle.vehiclePhone],
			specialAttributes: [this.vehicle.specialAttributes],
			kmRate: [
				this.vehicle.kmRate ? this.vehicle.kmRate.toString().replace('.', ',') : '',
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			fixedDailyCosts: [
				this.vehicle.fixedDailyCosts ? this.vehicle.fixedDailyCosts.toString().replace('.', ',') : '',
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			vehicleProfile: [this.vehicle.vehicleProfile, [Validators.required]],
			chooseEmailBasedOnVehicle: [this.vehicle.chooseEmailBasedOnVehicle],
			axisNumber: [this.vehicle.axisNumber],
			emissionCategory: [this.vehicle.emissionCategory],
			vehicleWeight: [this.vehicle.vehicleWeight],
			carType: [this.vehicle.carType],
			type: [this.vehicle.type],
			isSpot: [this.vehicle.isSpot],
			isActivatedForPlanning: [
				this.dialogData.companySettings.isDriverDeactivated
					? this.vehicle.isActivatedForPlanning
					: hasScheduleForNow(this.vehicle.driverSchedules),
			],
			isDepotAddressBased: [this.vehicle.isDepotAddressBased],
			vehicleEmail: [this.vehicle.vehicleEmail, [Validators.email]],
		});
	}
}

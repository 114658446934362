export const WebsocketStates = {
	INITIALIZED: 'initialized',
	CONNECTING: 'connecting',
	CONNECTED: 'connected',
	DISCONNECTED: 'disconnected',
	SUSPENDED: 'suspended',
	CLOSING: 'closing',
	CLOSED: 'closed',
	FAILED: 'failed',
};

export interface WebsocketServerResponse {
	data: string;
}

export interface WebsocketResponseData<T> {
	status: string;
	typeName: string;
	storable: T;
	created: string;
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { BehaviorSubject } from 'rxjs';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { RoutingProfileSettings } from '@flutaro/package/lib/model/RoutingProfileSettings';

@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	$companySettings: BehaviorSubject<CompanySettings> = new BehaviorSubject<CompanySettings>(new CompanySettings());

	constructor(private firestore: AngularFirestore) {}

	listenToCompanySettings(company: string) {
		this.firestore
			.doc<CompanySettings>(`companyData/${company}`)
			.valueChanges()
			.subscribe((companySettings: CompanySettings) => {
				console.debug(`listenToCompanySettings, got companySettings for Company **${company}**:
        ${JSON.stringify(companySettings)}`);
				if (!companySettings.routingSettings) companySettings.routingSettings = new RoutingProfileSettings();
				this.$companySettings.next(companySettings);
			});
	}

	/**
	 * Simple wait mechanism for calls requiring companySettings on load of App
	 */
	async getCompanySettingsAsync(): Promise<CompanySettings> {
		let companySettings = this.$companySettings.getValue();
		while (!companySettings?.company) {
			await flutaroWait(50);
			companySettings = this.$companySettings.getValue();
		}
		return companySettings;
	}
}

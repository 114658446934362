import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FlutaroSidenavProvider } from '../sidenav/sidenav.provider';
import { FlutaroSearchService } from './toolbar/search.service';
import { FlutaroTimetableFilterService } from '../timetable/data/timetable.data.provider';
import { FormControl } from '@angular/forms';
import { APP_MENU } from './MenuClasses';

@Injectable()
export class MenuComponentProvider {
	showTimetableToolbar: boolean = false;
	public $showTimetableToolbar: BehaviorSubject<boolean> = new BehaviorSubject(false);
	// Tabs
	public $currentTabs: BehaviorSubject<APP_MENU[]> = new BehaviorSubject<APP_MENU[]>([]);
	public $currentTab: BehaviorSubject<APP_MENU> = new BehaviorSubject<APP_MENU>(null);
	selected = new FormControl(0);
	currentTabIndex = 0;
	// Active Attributes are being used to hide/show Menu-Buttons
	isInfoActive: boolean = false;
	isToolbarView: boolean = false;
	isNewJobsView: boolean = false;
	isSearchActive: boolean = false;
	showSearch: boolean = false;
	isChatActive: boolean = false;

	constructor(
		private sidenavProvider: FlutaroSidenavProvider,
		private searchService: FlutaroSearchService,
		public timetableProvider: FlutaroTimetableFilterService,
	) {}

	/**
	 * Set tabs-Parameters after Flutaro-Sidenav Menu change to display matching current Tab and content
	 * @param {string[]} tabs
	 * @param index
	 */
	initTabsAfterMenuChange(tabs: APP_MENU[], index?: number) {
		this.$currentTabs.next(tabs);
		this.changeTab(index ? index : 0);
		this.clearMenuAfterMenuChange();
	}

	changeTab(index: number) {
		this.currentTabIndex = index;
		const tab = this.$currentTabs.getValue()[index];
		this.setMenuComponentSettingsOnViewChange(tab);
		this.setCurrentTabName(tab);
		this.clearAfterTabChange();
	}

	setMenuComponentSettingsOnViewChange(view: APP_MENU) {
		switch (view) {
			case APP_MENU.DAY_PLANNING:
			case APP_MENU.WEEK_PLANNING:
				this.isInfoActive = true;
				this.isToolbarView = true;
				this.isNewJobsView = true;
				this.isSearchActive = true;
				this.isChatActive = true;
				break;
			case APP_MENU.MAP_PLANNING:
				this.isToolbarView = true;
				this.isNewJobsView = false;
				this.isChatActive = false;
				this.isInfoActive = false;
				this.isSearchActive = false;
				this.showSearch = false;
				break;
			default:
				this.showSearch = false;
				this.isInfoActive = false;
				this.isSearchActive = false;
				this.isToolbarView = false;
				this.isChatActive = false;
				this.isNewJobsView = false;
				break;
		}
	}

	showHideSearch() {
		this.showSearch = !this.showSearch;
		if (!this.showSearch && this.searchService.query.filter) {
			this.searchService.clearSearch();
			this.applyFilterInTimetable();
		}
	}

	public applyFilterInTimetable() {
		this.timetableProvider.createFilteredTimetableMap();
	}

	public setTimetableToolbarShow(showToolbar: boolean) {
		this.showTimetableToolbar = showToolbar;
		this.$showTimetableToolbar.next(showToolbar);
	}

	public clearMenuAfterMenuChange() {
		this.sidenavProvider.deactivateSidenavs();
	}

	private setCurrentTabName(tab) {
		this.$currentTab.next(tab);
		this.timetableProvider.currentTab = tab;
	}

	private clearAfterTabChange() {
		this.showSearch = false;
		this.searchService.clearSearch();
	}
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExternalAddressMapping } from '../ExternalAddressMapping';
import { FlutaroNotificationsProvider } from '../../../notifications/notification.snack.provider';
import { AppDialogSettings } from 'src/app/dialog/AppDialog';

@Component({
	selector: 'app-address-correction-dialog',
	templateUrl: './correction.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrectionDialogComponent implements OnInit {
	isNew: boolean;
	correction: ExternalAddressMapping;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: AppDialogSettings<ExternalAddressMapping>,
		private notifications: FlutaroNotificationsProvider,
		@Inject(MatDialogRef) public dialogRef: MatDialogRef<CorrectionDialogComponent>,
	) {}

	ngOnInit() {
		this.isNew = this.dialogData.isNew;
		if (this.isNew) {
			this.correction = new ExternalAddressMapping();
		} else {
			this.correction = this.dialogData.data;
		}
	}

	onSave() {
		if (!this.correction.sourceAddressLine || !this.correction.targetAddressLine) {
			this.notifications.showBasicSnackBar('Korrektur ungültig, bitte beheben.');
			return;
		}
		this.dialogRef.close(this.correction);
	}

	onAbort() {
		this.dialogRef.close(null);
	}
}

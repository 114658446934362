import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { calculateEventsFromJobs } from './FlutaroEventCalculation';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { CompanyService } from '../company/company.service';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	$events: BehaviorSubject<Map<string, FlutaroEvent[]>> = new BehaviorSubject<Map<string, FlutaroEvent[]>>(
		new Map<string, FlutaroEvent[]>(),
	);

	constructor(
		private jobService: FlutaroJobService,
		private vehicleService: FlutaroVehicleService,
		private driverService: FlutaroDriverService,
		private companyService: CompanyService,
	) {
		this.initProvider();
	}

	async initProvider() {
		await flutaroWait(2000);
		this.jobService.$data.subscribe(async (jobs) => {
			if (this.driverService.getData().length === 0) {
				await flutaroWait(2000);
			}
			this.$events.next(
				calculateEventsFromJobs(
					jobs,
					this.vehicleService.getData(),
					this.driverService.getData(),
					this.companyService.$companySettings.getValue(),
				),
			);
		});
	}
}

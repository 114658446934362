import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { TableDeleteDialogComponent } from '../table/table.delete.dialog.component';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { JobDialogData } from 'src/app/dialog/AppDialog';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { JobDialogComponent } from './form/job.dialog.component';
import { CompanyService } from '../company/company.service';
import { WebAuthService } from '../app/auth/web-auth.service';
import { IntegrationsPoseidonService } from '../integrations/poseidon/integrations.poseidon.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { ContractorDataProvider } from '../contractor/contractor.data.provider';
import { getScheduleForDate } from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { AtheneService } from '../athene/athene.service';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { ExternalJobInCorrection } from '../interfaces/ExternalJob/ExternalJobWeb';
import { TrailerSchedule } from '@flutaro/package/lib/model/AppScheduleClasses';
import { CommunicatorServerProvider } from '../communicatorApp/communicator.server.provider';
import { lastValueFrom } from 'rxjs';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { createDialogConfig } from '../form/FlutaroFormFunctions';
import { getJobsFirstPickupDate } from '@flutaro/package/lib/functions/job/DestinationFunctions';
import { isJobOverlappingWithBlock } from '@flutaro/package/lib/functions/job/JobOverlappingFunctions';
import { changeJobsAttributesForNewVehicle } from '@flutaro/package/lib/functions/job/JobDataFunctions';

@Injectable()
export class JobHelperService {
	constructor(
		private jobService: FlutaroJobService,
		private driverService: FlutaroDriverService,
		private vehicleService: FlutaroVehicleService,
		private dialog: MatDialog,
		private notifications: FlutaroNotificationsProvider,
		private userSettingsProvider: UserSettingsProvider,
		private companyService: CompanyService,
		private webAuth: WebAuthService,
		private poseidon: IntegrationsPoseidonService,
		private contractorService: ContractorDataProvider,
		private athene: AtheneService,
		private communicator: CommunicatorServerProvider,
	) {}

	public async createUniqueIdentifierJobsBulkAndAnnounce(jobs: JobWrapper[]) {
		for (const job of jobs) {
			// check for isUniqueIdentifier
			const isIdentifierUnique = await this.getIsJobIdentifierUnique(job.job.identifier);
			if (!isIdentifierUnique) {
				console.log(
					`handleImportedJobs, jobs identifier ${job.job.identifier} is not unique. Continuing without import`,
				);
				continue;
			}
			await this.jobService.store(job);
			await flutaroWait(500);
		}
		return true;
	}

	async getIsJobIdentifierUnique(identifier: string): Promise<boolean> {
		return this.athene.getIsJobIdentifierUnique(identifier);
	}

	public async getAndUpdateJobsDistances(job: JobWrapper): Promise<JobWrapper> {
		console.debug(`getAndUpdateJobsDistances, called for job ${job.job.identifier}`);
		return await this.poseidon.getDistanceForJob(job);
	}

	public async getAndUpdateExternalJobsDistances(
		externalJob: ExternalJobInCorrection,
	): Promise<ExternalJobInCorrection> {
		console.debug(`getAndUpdateJobsDistances, called for externalJob ${externalJob.identifier}`);
		const result = await this.poseidon.getDistanceForDestinations(externalJob.fixedJobDestinations);
		externalJob.totalTimeInMinutes = result.totalDuration;
		externalJob.totalDistance = result.totalDistance;
		return externalJob;
	}

	async openJobEditDialog(job: JobWrapper): Promise<JobWrapper> {
		let editConfig = createDialogConfig();
		editConfig.data = new JobDialogData(
			this.userSettingsProvider.$userSettings.getValue(),
			this.webAuth.$userProfile.getValue(),
			this.companyService.$companySettings.getValue(),
			job,
			[],
			this.vehicleService.getData(),
			this.driverService.getData(),
		);
		let dialogRef = this.dialog.open(JobDialogComponent, editConfig);
		return lastValueFrom(dialogRef.afterClosed());
	}

	async showBlockDeleteConfirm(jobWrapper: JobWrapper, driver: Driver) {
		let deleteConfig = new MatDialogConfig();
		deleteConfig.data = { dataType: 'Block', dataName: 'von Fahrer ' + driver.toString() };
		let dialogRef = this.dialog.open(TableDeleteDialogComponent, deleteConfig);
		return lastValueFrom(dialogRef.afterClosed());
	}

	async deleteBlockFromDriver(driver: Driver): Promise<boolean> {
		driver.block = null;
		await this.driverService.store(driver);
		let text = 'Block wurde  von ' + driver.toString() + ' entfernt.';
		this.notifications.showBasicSnackBar(text);
		return true;
	}

	async changeJobAttributesForNewVehicle(job: JobWrapper, vehicle: Vehicle): Promise<JobWrapper> {
		const refDate = getJobsFirstPickupDate(job);
		const driver = this.driverService.getVehiclesDriverByDate(vehicle, refDate);
		// Driver Block handling
		if (isJobOverlappingWithBlock(job, driver)) {
			let shouldDeleteBlock = await this.showBlockDeleteConfirm(job, driver);
			if (!shouldDeleteBlock) {
				// TODO: show error message to User, put job back to driver before dispatch
				throw new Error('Job change aborted due to Driver Block.');
			}
			await this.deleteBlockFromDriver(driver);
		}

		const contractor = this.contractorService.getContractorForVehicle(vehicle.backendId);
		const trailerSchedule = getScheduleForDate(vehicle.trailerSchedules, refDate) as TrailerSchedule;
		changeJobsAttributesForNewVehicle(job, vehicle, driver, contractor, trailerSchedule);
		job.resetCostCalculation();
		this.communicator.setAppRelatedJobAttributesOnDriverChange(job);
		return job;
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantComponent } from './tenant/tenant.component';
import { FlutaroCustomMaterialModule } from '../app.material2.module';
import { FormsModule } from '@angular/forms';
import { FlutaroAuditComponent } from './audit/flutaro-audit.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleEditComponent } from './schedule/edit/schedule-edit.component';
import { AppBasicComponent } from './app.components.basic.component';
import { RoutingProfileComponent } from './routing-profile/routing-profile.component';
import { ScheduleDisplayComponent } from './schedule/schedule-display/schedule-display.component';
import { VehicleTypeIconComponent } from './vehicle-type-icon/vehicle-type-icon.component';
import {
	EntitiesFollowingScheduleAfterDatePipe,
	GetSchedulesEntityNamePipe,
	ScheduleMaxDatePipe,
	ScheduleMinDatePipe,
	VehicleForScheduleIdPipe,
} from './schedule/schedule.pipes';
import {
	DriverByDatePipe,
	FilterForActivatedTrucksByDatePipe,
	FilterForActivatedVehiclesByDatePipe,
	FilterForActivatedVehiclesNowPipe,
	FilterForInactiveVehiclesNowPipe,
	GetVehicleByIdPipe,
	GetVehiclesDriverForTimetablePipe,
	GetVehiclesDriverNameNowPipe,
	GetVehiclesTrailerNameNowPipe,
	IsVehicleActivatedNowPipe,
	IsVehicleMatchingJobRequirementsPipe,
} from './vehicle.pipes';

@NgModule({
	declarations: [
		TenantComponent,
		FlutaroAuditComponent,
		ScheduleComponent,
		ScheduleEditComponent,
		AppBasicComponent,
		RoutingProfileComponent,
		ScheduleDisplayComponent,
		VehicleTypeIconComponent,
		ScheduleMinDatePipe,
		ScheduleMaxDatePipe,
		EntitiesFollowingScheduleAfterDatePipe,
		VehicleForScheduleIdPipe,
		GetSchedulesEntityNamePipe,
		GetVehicleByIdPipe,
		GetVehiclesDriverNameNowPipe,
		GetVehiclesTrailerNameNowPipe,
		FilterForActivatedTrucksByDatePipe,
		FilterForActivatedVehiclesByDatePipe,
		FilterForActivatedVehiclesNowPipe,
		FilterForInactiveVehiclesNowPipe,
		GetVehiclesDriverForTimetablePipe,
		DriverByDatePipe,
		IsVehicleActivatedNowPipe,
		IsVehicleMatchingJobRequirementsPipe,
	],
	imports: [CommonModule, FlutaroCustomMaterialModule, FormsModule],
	exports: [
		TenantComponent,
		FlutaroAuditComponent,
		ScheduleComponent,
		AppBasicComponent,
		RoutingProfileComponent,
		ScheduleDisplayComponent,
		VehicleTypeIconComponent,
		ScheduleMinDatePipe,
		ScheduleMaxDatePipe,
		EntitiesFollowingScheduleAfterDatePipe,
		VehicleForScheduleIdPipe,
		GetSchedulesEntityNamePipe,
		GetVehicleByIdPipe,
		GetVehiclesDriverNameNowPipe,
		GetVehiclesTrailerNameNowPipe,
		FilterForActivatedTrucksByDatePipe,
		FilterForActivatedVehiclesByDatePipe,
		FilterForActivatedVehiclesNowPipe,
		FilterForInactiveVehiclesNowPipe,
		GetVehiclesDriverForTimetablePipe,
		DriverByDatePipe,
		IsVehicleActivatedNowPipe,
		IsVehicleMatchingJobRequirementsPipe,
	],
})
export class ComponentsModule {}

import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { JobDestination } from '@flutaro/package/lib/model/Job';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Component({
	selector: 'app-job-destination',
	templateUrl: './job.destination.component.html',
	styleUrls: [
		'./job.destination.component.scss',
		'../../import/external-job-import-component/external-job-import-component.component.css',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDestinationComponent extends AppBasicComponent implements OnInit, AfterViewInit {
	@Input() type: string;
	@Input() isExternalJob: boolean = false;
	@Input() showLocationTypeLabel: boolean;
	@Input() isSidenav: boolean;
	@Input() destination: JobDestination;
	@Input() destinations: JobDestination[];
	@Input() destinationIndex: number;
	@Output() destinationChange = new EventEmitter<JobDestination>();
	@Output() locationChange = new EventEmitter<JobDestination>();
	destinationsDirection: string;
	originalDestination: JobDestination;
	destinationFormGroup;
	@ViewChild('autosize') autosize: CdkTextareaAutosize;

	constructor(private cd: ChangeDetectorRef) {
		super();
	}
	ngOnInit() {
		this.isSidenav ? (this.destinationsDirection = 'column') : (this.destinationsDirection = 'row');
		this.originalDestination = cloneDeep(this.destination);
		this.destinationFormGroup = new FormGroup({
			cargo: new FormControl(this.destination.cargo, Validators.compose([])),
		});
	}

	async ngAfterViewInit() {
		await flutaroWait(50);
		if (this.autosize) {
			console.log(`autosize.resizeToFitContent() - ${this.destinationIndex}`);
			this.autosize.resizeToFitContent(true);
			this.cd.detectChanges();
		}
	}

	updateDates() {
		this.destinationChange.emit(this.destination);
	}

	changeDestinationsPosition(position: Synonym) {
		this.destination.position = position;
		this.locationChange.emit(this.destination);
	}
}

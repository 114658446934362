<mat-toolbar
	class="timetableToolbar mat-hue-2 hidden-xs"
	fxFlex="100"
	fxLayout="row"
	fxLayoutAlign="start center"
	fxLayoutGap="20px"
	[@toolbarAnimation]
>
	<div class="map-toolbar-label">{{ 'SHOW' | translate }}</div>
	<button
		class="mat-raised mat-small mat-ink-ripple showMapOverviewButton"
		(click)="goToOverview()"
		matTooltip="{{ 'MAP_GO_TO_OVERVIEW' | translate }}"
		[matTooltipPosition]="'below'"
	>
		<i class="material-icons timetable-home-icon">home</i>
	</button>
	<app-position-pairing-select (pairingSelectChange)="jobPairingFilterChange($event)"></app-position-pairing-select>
</mat-toolbar>

<mat-toolbar fxLayoutAlign="center center" fxLayoutGap="20px">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground settingsDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">settings</i>
		{{ 'USER_SETTING' | translate }}
		<span class="dialogToolbarObjectName">{{ dialogData.userProfile.displayName }}</span>
	</h1>
</mat-toolbar>

<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
	<h4 class="dialogHeadlineContent">
		<i class="material-icons dialogIcon">person</i> {{ 'PROFILE_ACCOUNT_DATA' | translate }}
	</h4>
	<div fxLayout="column" fxLayoutGap="10px" style="padding-left: 30px">
		<mat-form-field fxFlex="100">
			<input
				[(ngModel)]="dialogData.userProfile.displayName"
				readonly
				disabled
				matInput
				placeholder="{{ 'PROFILE_FULLNAME' | translate }}"
				required
			/>
			<!--required message-->
		</mat-form-field>
		<mat-form-field fxFlex="100">
			<mat-select
				[(ngModel)]="userSettings.appLanguage"
				matTooltip="{{ 'APP_LANGUAGE_TT' | translate }}"
				placeholder="{{ 'APP_LANGUAGE' | translate }}"
				(ngModelChange)="changeAppLanguage($event)"
			>
				<mat-option [value]="'ger'">
					{{ 'PROFILE_LANGUAGE_GER' | translate }}
				</mat-option>
				<mat-option [value]="'en'">
					{{ 'PROFILE_LANGUAGE_ENG' | translate }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div fxLayout="column">
		<h4 class="dialogHeadlineContent">
			<i class="material-icons dialogIcon">assignment</i> {{ 'PROFILE_DISPOSITION_SETTINGS' | translate }}
		</h4>
		<div fxLayout="column" fxLayoutGap="10px" fxFlexFill style="padding-left: 30px">
			<mat-expansion-panel fxFlexFill>
				<mat-expansion-panel-header>
					<mat-panel-title> {{ 'TIMETABLE_SORTING_SETTING_SECTION_TITLE' | translate }} </mat-panel-title>
					<mat-panel-description>
						{{ 'TIMETABLE_SORTING_SETTING_SECTION_DESCRIPTION' | translate }}
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div fxLayout="row" fxLayoutGap="10px">
					<div>1.</div>
					<app-settings-timetable-ordering-select
						[(sorting)]="userSettings.planningSettings.timetableDataSortings[0].sorting"
						[(attribute)]="userSettings.planningSettings.timetableDataSortings[0].attribute"
						[companySettings]="dialogData.companySettings"
						fxFlex
					></app-settings-timetable-ordering-select>
				</div>
				<div fxLayout="row" fxLayoutGap="10px">
					<div>2.</div>
					<app-settings-timetable-ordering-select
						[(sorting)]="userSettings.planningSettings.timetableDataSortings[1].sorting"
						[(attribute)]="userSettings.planningSettings.timetableDataSortings[1].attribute"
						[companySettings]="dialogData.companySettings"
						fxFlex
					></app-settings-timetable-ordering-select>
				</div>
			</mat-expansion-panel>

			<mat-form-field fxFlexFill>
				<mat-select
					[(ngModel)]="userSettings.planningSettings.defaultTimetableView"
					matTooltip="{{ 'PROFILE_PLANNING_SETTING_DEFAULTTIMETABLEVIEW_TT' | translate }}"
					placeholder="{{ 'PROFILE_PLANNING_SETTING_DEFAULTTIMETABLEVIEW' | translate }}"
				>
					<mat-option [value]="PLANNING_VIEWS.DAY_PLANNING">
						{{ 'DAY_PLANNING' | translate }}
					</mat-option>
					<mat-option [value]="PLANNING_VIEWS.WEEK_PLANNING">
						{{ 'WEEK_PLANNING' | translate }}
					</mat-option>
					<mat-option *ngIf="dialogData.userProfile.isMapPlanningCompany" [value]="PLANNING_VIEWS.MAP_PLANNING">
						{{ 'MAP_PLANNING' | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field fxFlexFill>
				<mat-select
					[(ngModel)]="userSettings.planningSettings.standardVehicleSpeedProfile"
					matTooltip="{{ 'PROFILE_PLANNING_SETTING_STANDARDVEHICLESPEEDPROFILE_TT' | translate }}"
					placeholder="{{ 'PROFILE_PLANNING_SETTING_STANDARDVEHICLESPEEDPROFILE' | translate }}"
				>
					<mat-option *ngFor="let vehicleProfile of vehicleSpeedProfiles" [value]="vehicleProfile.value">
						{{ vehicleProfile.title }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.showSunday"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_SHOW_SUNDAY_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_SHOW_SUNDAY' | translate }}
			</mat-checkbox>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.weekplanningDayMode"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_WEEKPLANNINGDAYMODE_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_WEEKPLANNINGDAYMODE' | translate }}
			</mat-checkbox>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.openNotesOnStart"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_OPENNOTESONSTART_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_OPENNOTESONSTART' | translate }}
			</mat-checkbox>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.jobDatesAdjustmentOnlyTimes"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_JOBDATESADJUSTMENTONLYTIMES_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_JOBDATESADJUSTMENTONLYTIMES' | translate }}
			</mat-checkbox>
		</div>
	</div>
	<div fxLayout="column">
		<h4 class="dialogHeadlineContent">
			<i class="material-icons dialogIcon">attach_money</i> {{ 'COST_CALCULATION' | translate }}
		</h4>
		<div fxLayout="column" style="margin-left: 30px">
			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.activeCostCalculationMode"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_ACTIVATECOSTCALCULATIONMODE_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_ACTIVATECOSTCALCULATIONMODE' | translate }}
			</mat-checkbox>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.activateManualJobCostOnDrop"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_ACTIVATEMANUALJOBCOSTONDROP_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_ACTIVATEMANUALJOBCOSTONDROP' | translate }}
			</mat-checkbox>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.preventMinusPositionsInCostCalculation"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_PREVENTMINUSPOSITIONSINCOSTCALCULATION_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_PREVENTMINUSPOSITIONSINCOSTCALCULATION' | translate }}
			</mat-checkbox>
		</div>
	</div>
	<div fxLayout="column">
		<h4 class="dialogHeadlineContent">
			<i class="material-icons dialogIcon">assignment</i> {{ 'PROFILE_JOB_DISPLAY_MODE' | translate }}
		</h4>
		<div fxLayout="column" style="margin-left: 30px" fxLayoutGap="10px">
			<app-settings-timetable-job-titles
				[(settings)]="userSettings.planningSettings.timetableJobDisplaySettings"
			></app-settings-timetable-job-titles>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.customJobDisplay"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_CUSTOMJOBDISPLAY_TT' | translate }}"
				[matTooltipPosition]="'below'"
			>
				{{ 'PROFILE_PLANNING_SETTING_CUSTOMJOBDISPLAY' | translate }}
			</mat-checkbox>

			<mat-checkbox
				[(ngModel)]="userSettings.planningSettings.identifierJobDisplay"
				[disabled]="userSettings.planningSettings.customJobDisplay"
				[matTooltipPosition]="'below'"
				matTooltip="{{ 'PROFILE_PLANNING_SETTING_IDENTIFIERJOBDISPLAY_TT' | translate }}"
			>
				{{ 'PROFILE_PLANNING_SETTING_IDENTIFIERJOBDISPLAY' | translate }}
			</mat-checkbox>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" class="dialogFooterMD2" fxLayoutAlign="end none">
	<button mat-button class="buttonCancel mat-raised" (click)="abbortProfileChanges()">
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button mat-button class="buttonDone mat-primary_flutaro" (click)="saveProfileChanges()">
		<mat-icon>
			<i class="material-icons material-icons-white">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

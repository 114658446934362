import { TimetableFilteredEntry } from './TimetableFilterClasses';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { removeDuplicatesFromArray } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { isVehicleTypeMatchingJobsRequiredVehicleType } from '@flutaro/package/lib/functions/vehicle/VehicleValidationFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { isInvalidJobCostCalculation } from '@flutaro/package/lib/functions/job/JobDataFunctions';

/**
 * Validates each MapEntry for Job requirements and updates blocked attributes on mismatching requirements.
 * Current Validations in place:
 *  1. Jobs with Mega-Truck requirement cant be assigned to (regular) Trucks
 *  2. Vehicle needs to be activated (by driver/isActivated) for the whole Jobs TimeRange
 * @param job
 * @param entries
 * @param companySettings
 */
export function updateTimetableEntryActionAttributesForJobValidations(
	job: JobWrapper,
	entries: TimetableFilteredEntry[],
	companySettings: CompanySettings,
): TimetableFilteredEntry[] {
	return entries.map((entry) => {
		entry.updateVehicleJobStatus(job, companySettings);
		return entry;
	});
}

export function resetTimetableEntryActionAttributesAfterJobValidations(
	entries: TimetableFilteredEntry[],
): TimetableFilteredEntry[] {
	return entries.map((entry) => {
		entry.vehicleJobStatus.resetJobActionStatus();
		return entry;
	});
}

export function getInvalidJobs(jobs: JobWrapper[], vehicle: Vehicle): JobWrapper[] {
	const declinedJobs = jobs.filter((job) => job.job.status === JobStatus.DECLINED);
	const jobsWithDriverAndNoCosts = jobs.filter((job) => isInvalidJobCostCalculation(job));
	const vehicleTypeMismatchedJobs = getVehicleTypeMismatchedJobs(jobs, vehicle);
	return removeDuplicatesFromArray(declinedJobs.concat(vehicleTypeMismatchedJobs).concat(jobsWithDriverAndNoCosts));
}

export function getVehicleTypeMismatchedJobs(jobs: JobWrapper[], vehicle: Vehicle) {
	return jobs.filter((job) => !isVehicleTypeMatchingJobsRequiredVehicleType(job, vehicle));
}

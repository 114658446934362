import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';

export class WeekdayTimeSetting {
	minute: number;
	hour: number;
	weekDay: number;
}

@Component({
	selector: 'app-weekday-time-picker',
	templateUrl: 'dialog.weekdayAndTime.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWeekdayAndTimePickerComponent implements OnInit {
	@Input() weekdayTimeObject: WeekdayTimeSetting;
	@Output() weekdayTimeObjectChange = new EventEmitter<WeekdayTimeSetting>();
	days = [];
	weekDayDate: Date = new Date();

	ngOnInit() {
		this.createDaysArray();
		this.setDateFromWeekdayTime();
	}

	setWeekdayTimeFromDate(time: Date) {
		if (typeof time === 'object') {
			this.weekdayTimeObject.minute = getMinutes(time);
			this.weekdayTimeObject.hour = getHours(time);
			this.weekdayTimeObjectChange.emit(this.weekdayTimeObject);
		}
	}

	protected setDateFromWeekdayTime() {
		this.weekDayDate = setMinutes(
			setHours(this.weekDayDate, this.weekdayTimeObject.hour),
			this.weekdayTimeObject.minute,
		);
	}

	protected createDaysArray() {
		this.days.push({ value: 0, label: 'Montag' });
		this.days.push({ value: 1, label: 'Dienstag' });
		this.days.push({ value: 2, label: 'Mittwoch' });
		this.days.push({ value: 3, label: 'Donnerstag' });
		this.days.push({ value: 4, label: 'Freitag' });
		this.days.push({ value: 5, label: 'Samstag' });
		this.days.push({ value: 6, label: 'Sonntag' });
	}
}

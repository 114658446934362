<div class="channelHeader" fxLayout="row" fxLayoutAlign="space-between center">
	<button
		class="buttonChatBack"
		(click)="closeChatView()"
		[matTooltipPosition]="'below'"
		[matTooltipShowDelay]="1500"
		mat-button
		matTooltip="{{ 'CHAT_CHANNEL_BACK_TT' | translate }}"
	>
		<mat-icon class="buttonChatBackIcon">arrow_back</mat-icon>
	</button>
	<p class="channelHeaderText">
		{{ channel.toString() }}
	</p>
	<mat-slide-toggle
		[ngModel]="showAllFirstSeenInformation"
		(ngModelChange)="showAllFirstSeenInformation = !showAllFirstSeenInformation"
	>
		<mat-icon class="app-button-on-dark-background" [matTooltip]="'Show first read by information for all messages'"
			>youtube_searched_for</mat-icon
		>
	</mat-slide-toggle>
</div>
<div
	(scrolling)="scrollingHandler($event)"
	class="channelMessagesDiv"
	fxLayout="column"
	id="channelMessagesDiv"
	scrollable
>
	<app-loading-spinner *ngIf="channelMessagesProvider.$isLoadingMessages | async"></app-loading-spinner>
	<!-- messageLeft / messageRight -->
	<div
		*ngFor="let message of messages; let last = last; let i = index"
		[class.left]="message.senderId !== userProfile.uid"
		[class.right]="message.senderId === userProfile.uid"
		class="message"
		id="{{ 'messDiv' + i }}"
	>
		<img
			*ngIf="message.senderId === userProfile.uid"
			class="user-img"
			src="assets/pictures/Flutaro_Logo_white_iconOnly.svg"
		/>
		<img *ngIf="message.senderId !== userProfile.uid" class="user-img" src="assets/pictures/chat/user.jpg" />
		<div class="msg-detail">
			<div class="msg-info">
				<p>
					{{ message.senderName }}
				</p>
			</div>
			<div
				*ngIf="(message.firstReceiverId && last && message.senderId !== userProfile.uid) || showAllFirstSeenInformation"
				class="msg-info"
			>
				<p *ngIf="message.firstReceiverId !== userProfile.uid">
					{{ 'CHAT_CHANNEL_FIRST_RECEIVED_NAME' | translate }} {{ message.firstReceiverEmail }}
				</p>
				<p *ngIf="message.firstReceiverId === userProfile.uid">
					{{ 'CHAT_CHANNEL_FIRST_RECEIVER_IS_YOU' | translate }}
				</p>
			</div>
			<div *ngIf="message.messageType === JobRequestType" fxLayout="row">
				<p class="chatJobRequestMessageText">
					<i class="material-icons chatJobRequestMessageTextIcon">help_outline</i>
					{{ 'CHAT_CHANNEL_REQUEST_FOR_JOB_IDENTIFIER' | translate }}
				</p>
				<p (click)="openJob(message.messageJobId)" class="chatJobRequestMessageJobIdentifier">
					<i class="material-icons chatJobRequestMessageJobIcon">description</i>
					{{ message.messageJobIdentifier }}
				</p>
			</div>
			<div class="msg-content">
				<span class="triangle"></span>
				<p class="line-breaker wrap-text" [innerHTML]="message.message | appLinkify"></p>
				<p class="messageSendTime">{{ message.sendTime | relativeTime }}</p>
			</div>
		</div>
	</div>
	<!-- hide the new message indicator until we fix it.-->
	<!-- <button mat-button style="width: 10px; right: 271px; position: fixed; top: 442px;"
          class="mainToolbarButton effects"
          (click)="scrollToBottomInitialDown()"
          matTooltip="Sie haben neue Nachrichten erhalten"
          [matTooltipPosition]="'below'"
          *ngIf="countNewMessage > 0 && onTop">
      <i class="material-icons mainToolbarIcon" [ngClass]="{'newMessageIcon':countNewMessage > 0 }" style="color: gray">message</i>
      <div fxLayout="row" class="jobsNewButtonMenuRow">
      <span matTooltip="Die Anzahl der neuen Nachrichten." [matTooltipPosition]="'below'"

            class="notifications-badge"
            [class.showBadgeAnimation]="countNewMessage">{{countNewMessage}}</span>
      </div>
  </button> -->
</div>
<div class="channelSendDiv">
	<mat-form-field appearance="outline">
		<textarea
			(blur)="chatTxtAreafocusOut($event)"
			(click)="chatTxtAreafocusIn()"
			[(ngModel)]="messageInputText"
			id="chatTextArea"
			matInput
			placeholder="{{ 'ENTER_MESSAGE' | translate }}"
			type="text"
		>
		</textarea>
	</mat-form-field>
	<button
		(click)="sendMessageAction($event)"
		[class.chatFooterSendButtonActive]="messageInputText"
		[disabled]="!messageInputText"
		[matTooltipPosition]="'above'"
		[matTooltipShowDelay]="1500"
		aria-label="sendMessage"
		class="channelSendButton"
		id="channelSendButton"
		mat-button
		mat-icon-button
		matSuffix
		matTooltip="{{ 'ENTER_MESSAGE' | translate }}"
	>
		<mat-icon>send</mat-icon>
	</button>
</div>

import {
	TimetableBlockEntry,
	TimetableContractorEntry,
	TimetableDriverEntry,
	TimetableJobEntry,
	TimetableTimewindowEntry,
	TimetableTrailerEntry,
	TimetableVehicleEntry,
	TimetableVehicleStatus,
	timetableHeight,
} from '../TimetableClasses';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { TimetableNote } from '@flutaro/package/lib/model/TimetableNote';
import { TimetableEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { BehaviorSubject } from 'rxjs';
import { setJobsEntryOverlappingAndGetHighestValue } from '../TimetableFunctions';
import { isVehicleTypeMatchingJobsRequiredVehicleType } from '@flutaro/package/lib/functions/vehicle/VehicleValidationFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { isVehicleActivatedForCompanyInTimeRange } from '@flutaro/package/lib/functions/VehicleFunctions';
import {
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';

export enum JobFilterStatus {
	WITHOUT_COSTS = 'withoutCosts',
	INVALID = 'invalid',
	STATUS_NOT_SENT = 'job_notSentToAppUser',
	OVERLAPPING = 'job_overlapping',
}

export enum VehicleFilterStatus {
	VEHICLETYPE_MISMATCHING = 'vehicle_typeMismatching',
	VEHICLE_WITH_STATUS = 'vehicle_withStatus',
	VEHICLE_WITH_OVERLAPPING = 'vehicle_overlappingJobs',
}

export class TimetableFilterStore {
	jobIds: string[] = [];
	vehicleIds: string[] = [];
	vehicleStatus: VehicleFilterStatus | undefined;
	jobStatus: JobStatus;
	planningViewRemovedVehicles: string[] = [];
	$isActiveFilterMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	appJobStatus: JobFilterStatus | undefined;

	updateIsActivatedFilterState() {
		this.$isActiveFilterMode.next(
			!!this.jobIds.length ||
				!!this.vehicleIds.length ||
				!!this.jobStatus ||
				!!this.appJobStatus ||
				!!this.vehicleStatus ||
				!!this.planningViewRemovedVehicles.length,
		);
	}

	setVehicleStatus(status: VehicleFilterStatus) {
		this.vehicleStatus = status;
		this.updateIsActivatedFilterState();
	}

	isVehicleStatusFilterActive(): boolean {
		return !!this.vehicleStatus;
	}

	resetVehicleStatusFilter() {
		delete this.vehicleStatus;
		this.updateIsActivatedFilterState();
	}

	resetAllAppJobStatusFilter() {
		delete this.appJobStatus;
		delete this.jobStatus;
		this.updateIsActivatedFilterState();
	}

	setJobStatusFilter(status: JobStatus) {
		this.jobStatus = status;
		this.updateIsActivatedFilterState();
	}

	setAppJobStatusFilter(status: JobFilterStatus) {
		this.appJobStatus = status;
		this.updateIsActivatedFilterState();
	}

	isJobStatusFilterActive(): boolean {
		return !!this.jobStatus || !!this.appJobStatus;
	}
}

export class TimetableFilteredEntry {
	driverEntry: TimetableDriverEntry | undefined;
	vehicleEntry: TimetableVehicleEntry;
	vehicleJobStatus: TimetableVehicleJobStatus = new TimetableVehicleJobStatus();
	contractorEntry: TimetableContractorEntry | undefined;
	trailerEntries: TimetableTrailerEntry[];
	note: TimetableNote;
	jobsEntry: TimetableJobEntry[] = [];
	eventsEntry: TimetableEvent[] = [];
	blockEntry: TimetableBlockEntry;
	timewindowEntry: TimetableTimewindowEntry[] = [];
	vehicleStatuses: TimetableVehicleStatus[] = [];
	entryBackgroundClass: string;
	height: string = timetableHeight + 'px';

	constructor(vehicle: Vehicle, note: TimetableNote, jobs: JobWrapper[]) {
		this.vehicleEntry = new TimetableVehicleEntry(vehicle);
		jobs.forEach((job) => this.jobsEntry.push(new TimetableJobEntry(job)));
		Object.assign(this, { note, jobs });
		this.updateVehiclesIsValid();
	}

	getJobs(): JobWrapper[] {
		return this.jobsEntry.map((jobEntry) => jobEntry.jobWrapper);
	}

	getVehicle(): Vehicle {
		return this.vehicleEntry.vehicle;
	}

	updateVehicleJobStatus(job: JobWrapper, companySettings: CompanySettings) {
		this.vehicleJobStatus.updateVehicleJobStatus(this, job, companySettings);
	}

	updateVehiclesIsValid() {
		this.vehicleJobStatus.updateVehicleIsValid(this);
	}

	addContractor(contractor: Contractor) {
		this.contractorEntry = new TimetableContractorEntry(contractor);
	}

	addDriver(driver: Driver) {
		this.driverEntry = new TimetableDriverEntry(driver);
	}
}

export class TimetableVehicleJobStatus {
	isValid: boolean = true; // Vehicles current state
	isJobActionValid: boolean = true; // Vehicles state for job action
	isNotMatchingJobsRequiredVehicleType: boolean = false;
	isNotJobsWholeRangeActivated: boolean = false;
	isOverlapping: boolean = false;
	overlapping = 0;

	constructor() {}

	updateVehicleIsValid(entry: TimetableFilteredEntry) {
		this.overlapping = setJobsEntryOverlappingAndGetHighestValue(entry.jobsEntry);
		this.updateVehicleIsOverlapping();
		this.isValid = !this.isOverlapping;
	}

	/**
	 * Updates the variables related to a job action
	 * @param entry
	 * @param job
	 * @param companySettings
	 */
	updateVehicleJobStatus(entry: TimetableFilteredEntry, job: JobWrapper, companySettings: CompanySettings) {
		this.updateIsNotMatchingJobsRequiredVehicleType(
			!isVehicleTypeMatchingJobsRequiredVehicleType(job, entry.getVehicle()),
		);
		this.updateVehicleActivatedWholeRange(
			!isVehicleActivatedForCompanyInTimeRange(
				entry.vehicleEntry.vehicle,
				companySettings,
				getJobsFirstPickupDate(job),
				getJobsLastDeliveryDate(job),
			),
		);
		this.updateIsValidJobAction();
	}

	updateIsValidJobAction() {
		this.isJobActionValid = !this.isNotMatchingJobsRequiredVehicleType && !this.isNotJobsWholeRangeActivated;
	}

	updateVehicleActivatedWholeRange(activated: boolean) {
		this.isNotJobsWholeRangeActivated = activated;
	}

	updateVehicleIsOverlapping() {
		this.isOverlapping = !!this.overlapping;
	}

	updateIsNotMatchingJobsRequiredVehicleType(vehicleTypeMismatching: boolean) {
		this.isNotMatchingJobsRequiredVehicleType = vehicleTypeMismatching;
	}

	resetJobActionStatus() {
		this.isNotMatchingJobsRequiredVehicleType = false;
		this.isNotJobsWholeRangeActivated = false;
		this.isJobActionValid = true;
	}
}

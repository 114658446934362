import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlutaroTableComponent } from '../../../table/table.component';
import { FlutaroAddressCorrectionService } from '../../../data/data-services/data.address.corrections.service';
import { WebAuthService } from '../../../app/auth/web-auth.service';
import { CorrectionDialogComponent } from './correction.dialog.component';
import { ExternalAddressMapping, ExternalAddressState } from '../ExternalAddressMapping';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'address-correction-table',
	templateUrl: './correction.table.component.html',
	styles: ['.correctionApproved {color: #4db9a8;} .correctionPending {color: #b83a4c}'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrectionTableComponent extends FlutaroTableComponent {
	constructor(
		public correctionService: FlutaroAddressCorrectionService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public authFb: WebAuthService,
	) {
		super(dialog, correctionService, translate);
		this.elementTypeName = this.translate.instant('ADDRESS_CORRECTION');
		this.displayedColumns = ['state', 'targetAddressLine', 'sourceAddressLine', 'approve', 'delete'];
		this.dataDialog = CorrectionDialogComponent;
	}

	activateAddressCorrection(correction: ExternalAddressMapping) {
		console.log(`Activating correction: ${JSON.stringify(correction)}`);
		correction.state = ExternalAddressState.APPROVED;
		this.correctionService.update(correction);
	}
}

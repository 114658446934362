import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TimeTable } from '../TimeTable';

import { UserSettingsProvider } from '../../settings/settings.user.provider';
import { getISODay, isSunday } from 'date-fns';
import { APP_MENU } from '@flutaro/package/lib/model/WebAppClasses';
import { createDefaultTimetable } from '../TimetableFunctions';
import cloneDeep from 'lodash-es/cloneDeep';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';

@Injectable()
export class TimetableComponentProvider {
	$timetable: BehaviorSubject<TimeTable> = new BehaviorSubject<TimeTable>(null);
	$scrollLeft: BehaviorSubject<number> = new BehaviorSubject<number>(0); // Scroll action by User in weektable-View
	arrowRightSubject: Subject<boolean> = new Subject();
	arrowLeftSubject: Subject<boolean> = new Subject();
	$selectedDayOfWeek: BehaviorSubject<number> = new BehaviorSubject<number>(getISODay(new Date()));

	constructor(public userSettings: UserSettingsProvider) {
		this.initNowForSelectedDayOfWeek();
		userSettings.$userSettings.subscribe((userSettings) => {
			if (userSettings) this.setDefaultTimetable(userSettings);
		});
	}

	initNowForSelectedDayOfWeek() {
		if (!this.userSettings.$userSettings.getValue()?.planningSettings?.showSunday && isSunday(new Date())) {
			this.$selectedDayOfWeek.next(6);
		} else {
			this.$selectedDayOfWeek.next(getISODay(new Date()));
		}
		if (this.$timetable.getValue()?.isCustomRange) this.setDefaultTimetable(this.userSettings.$userSettings.getValue());
	}

	updateDayOfWeek(weekDay: number) {
		this.$selectedDayOfWeek.next(weekDay);
	}

	setDefaultTimetable(userSettings: UserSettings) {
		console.log(`setDefaultTimetable, for userSettings of ${userSettings.lastModifiedAt}`);
		const isDayTimetableDefault = userSettings.planningSettings.defaultTimetableView === APP_MENU.DAY_PLANNING;
		const isSundayTimetable = userSettings.planningSettings.showSunday;
		const defaultTimetable = createDefaultTimetable(isSundayTimetable, isDayTimetableDefault);
		this.setTimetable(defaultTimetable);
	}

	/**
	 * Update the current Timetable by a copy of the given Timetable and emit copy of updated Timetable.
	 * @param {TimeTable} timetable
	 */
	private setTimetable(timetable: TimeTable) {
		this.$timetable.next(timetable);
	}

	getTimetableClone(): TimeTable {
		return cloneDeep(this.$timetable.getValue());
	}

	setTimetablesSelectedDate(selectedDate: Date, force: boolean): TimeTable {
		let newTimetable = this.getTimetableClone();
		newTimetable.setSelectedDate(selectedDate, force);
		this.setTimetable(newTimetable);
		return newTimetable;
	}

	switchTimetableIsDayTable(isDayTable: boolean): TimeTable {
		let timetable: TimeTable = this.$timetable.getValue();
		const newTimetable = createDefaultTimetable(timetable.isSundayTable, isDayTable);
		newTimetable.setSelectedDate(newTimetable.selectedDate, false);
		this.setTimetable(newTimetable);
		return newTimetable;
	}

	/**
	 * This Function makes sure that when opening the week-View the scrollLeft is set correctly to display the current day
	 */
	public setScrollForTimetableByWeekday(weekday: number) {
		setTimeout(() => {
			let timetable = document.getElementById('timetableSection');
			if (!timetable) {
				return;
			}
			const scrollPxFactor = 576;
			switch (weekday) {
				case 1:
					timetable.scrollLeft = 0;
					break;
				default:
					const dayWidthInPx = scrollPxFactor * (weekday - 1);
					timetable.scrollLeft = dayWidthInPx;
			}
			if (this.userSettings.$userSettings.getValue().planningSettings.weekplanningDayMode)
				timetable.scrollLeft = timetable.scrollLeft - scrollPxFactor;
		}, 100);
	}
}

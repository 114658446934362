import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../data/FlutaroService';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { parseContractor } from '@flutaro/package/lib/functions/ContractorDataFunctions';
import { getContractorForVehicle } from '@flutaro/package/lib/functions/ContractorFunctions';
import { environment } from '../../environments/environment';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { migrate_SetDefaultCreatedAtAttribute } from '@flutaro/package/lib/functions/AppDataMigrations';

@Injectable({
	providedIn: 'root',
})
export class ContractorDataProvider extends FlutaroDataService<Contractor> {
	initProvider() {
		this.url = environment.routingApiURL + '/data/contractor';
		this.collectionName = FlutaroCollection.CONTRACTOR;
		this.websocketService.contractorSocket.subscribe((contractorData) => this.handleWebSocketAction(contractorData));
	}

	getContractorForVehicle(vehicleId: string): Contractor | undefined {
		return getContractorForVehicle(vehicleId, this.getData());
	}

	/**
	 * Special Case due to current implementation / limitations of infrastructure - contractor is leading object for filter action detection
	 * @param data
	 * @protected
	 */
	protected receive(data: Contractor[]): void {
		super.receive(data);
		this.setReadOnlyContractorsVehicleIdsFilter();
	}

	protected parseElement(newElement: Contractor): Contractor {
		if (migrate_SetDefaultCreatedAtAttribute(newElement)) console.info(`Contractor-Migration in previous log`);
		return parseContractor(newElement);
	}

	/**
	 * Special Case due to current implementation / limitations of infrastructure - contractor is leading object for filter action detection
	 * @private
	 */
	private setReadOnlyContractorsVehicleIdsFilter() {
		const contractors = this.$data.getValue();
		if (
			this.authFb.getUserProfile().hasReadOnlyRole &&
			contractors?.length === 1 &&
			contractors[0].filterEmail === this.authFb.getUserProfile().email
		) {
			console.log(
				`setReadOnlyContractorsVehicleIdsFilter(in ContractorService), detected readOnly-Filter User - setting filterEmail data ....`,
			);
			this.authFb.$vehicleIdsFilter.next(contractors[0].vehicles);
		}
	}
}

import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../FlutaroService';
import { Client } from '@flutaro/package/lib/model/Client';
import {
	parseElementsBasics,
	transformObjectIntoClientClass,
} from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { environment } from '../../../environments/environment';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Injectable({
	providedIn: 'root',
})
export class FlutaroClientService extends FlutaroDataService<Client> {
	initProvider() {
		this.url = environment.routingApiURL + '/data/clients';
		this.collectionName = FlutaroCollection.CLIENT;
		this.websocketService.clientSocket.subscribe((clientData) => this.handleWebSocketAction(clientData));
		this.websocketService.$websocketRecoveredFromFailingState.subscribe((isReconnected) => {
			if (!isReconnected) return;
			this.getDataFromServer();
		});
	}

	protected parseElement(newElement: Client): Client {
		return transformObjectIntoClientClass(parseElementsBasics(newElement));
	}
}

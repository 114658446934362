import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { AppChannelMessagesProvider } from '../app/app-channel-messages-provider.service';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { ChatJobRequestType, ChatMessage, NewMessageChange } from '@flutaro/package/lib/model/ChatClasses';
import { FlutaroChatChannel } from './chat';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { ScrollableEvent } from '../app/flutaroChat.directive';

@Component({
	selector: 'app-chat-channel',
	templateUrl: './chat.channel.component.html',
	styleUrls: ['./chat.channel.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatChannelComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
	@Input() public channel: FlutaroChatChannel;
	@Input() public messages: ChatMessage[];
	@Output() sendMessageChange: EventEmitter<NewMessageChange> = new EventEmitter<NewMessageChange>();
	@Input() public userProfile: FlutaroUserProfile;
	@Output() leave: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() openJobChange: EventEmitter<string> = new EventEmitter<string>();
	JobRequestType = ChatJobRequestType;
	messageInputText = '';
	channelMessagesDiv: HTMLElement;
	messageSend: boolean = false;
	chatTextArea: HTMLElement;
	lastScrollTop;
	showAllFirstSeenInformation = false;

	constructor(private changeRef: ChangeDetectorRef, public channelMessagesProvider: AppChannelMessagesProvider) {}

	ngOnInit() {
		this.channelMessagesDiv = document.getElementById('channelMessagesDiv');
		this.chatTextArea = document.getElementById('chatTextArea');
	}

	async ngOnChanges(changes) {
		if (!changes['messages'] || !this.messages?.length) return;
		await flutaroWait(50);
		// TODO: improve scrolling mechanism and put into service for usage in WebApp and App
		if (this.messages.length === 1) {
			this.channelMessagesDiv.scrollTop = this.channelMessagesDiv.scrollHeight;
		} else if (this.messages.length > 1) {
			let sumOfElemetensHeight = 0;
			for (let i = 0; i < this.messages.length - 1; i++) {
				sumOfElemetensHeight += document.getElementById('messDiv' + i).offsetHeight;
			}
			this.channelMessagesDiv.scrollTop = this.lastScrollTop ? this.lastScrollTop : sumOfElemetensHeight;
		}
	}

	ngAfterViewInit() {
		this.channelMessagesProvider.initAndSubscribeChannelMessages(this.channel);
	}

	ngOnDestroy() {
		this.channelMessagesProvider.reset();
	}

	sendMessageAction(event) {
		if (!this.messageInputText.trim()) return;
		const newMessageChange = new NewMessageChange(this.channel.id, this.messageInputText);
		this.sendMessageChange.emit(newMessageChange);
		this.messageInputText = '';
		this.chatTxtAreafocusOut(event);
		this.changeRef.markForCheck();
	}

	openJob(jobId: string) {
		this.openJobChange.emit(jobId);
	}

	closeChatView() {
		this.leave.emit(true);
	}

	scrollingHandler(event: ScrollableEvent) {
		// TODO: improve me and ngOnChanges sumOfHeight
		if (event.containerTop) {
			console.log(`scrollingHandler, reached containerTop`);
			this.lastScrollTop = this.channelMessagesDiv.scrollHeight;
			this.channelMessagesProvider.loadNextMessages(this.channel);
		} else if (event.containerEnd) {
			this.lastScrollTop = null;
		}
	}

	chatTxtAreafocusIn() {
		this.chatTextArea.style.height = '150px';
		const chatSidenavFixedHeader = document.getElementById('chatSidenavFixedHeader');
		chatSidenavFixedHeader.scrollTop = chatSidenavFixedHeader.scrollHeight;
		chatSidenavFixedHeader.style.overflowX = 'hidden';
		chatSidenavFixedHeader.style.overflowY = 'hidden';
		this.channelMessagesDiv.style.height = 'calc(100% - 242px)';
		this.channelMessagesDiv.scrollTop = this.channelMessagesDiv.scrollHeight - this.channelMessagesDiv.offsetHeight + 2;
	}

	chatTxtAreafocusOut(event) {
		if (event.relatedTarget) {
			if (event.relatedTarget.id === 'channelSendButton') return;
		}
		this.chatTextArea.style.height = 'auto';
		this.channelMessagesDiv.style.height = 'calc(100% - 131px)';
	}
}

import { Directive, HostListener, Input } from '@angular/core';
import normalizeWheel from 'normalize-wheel';
import { BehaviorSubject } from 'rxjs';

@Directive({
	selector: '[synchronScrolling]',
})
export class SynchronousScrollingDirective {
	@Input() scrollLeftEmitter: BehaviorSubject<number>;

	@HostListener('wheel', ['$event'])
	wheelEvent(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		let wheel = normalizeWheel(event);
		let driverSection = document.getElementById('driverSection');
		let timetableSection = document.getElementById('timetableSection');
		let roomTimeline = document.getElementById('roomTimeline');
		if (target.tagName.toUpperCase() !== 'TEXTAREA') {
			driverSection.scrollTop = driverSection.scrollTop + wheel.pixelY;
			roomTimeline.scrollTop = roomTimeline.scrollTop + wheel.pixelY;
			timetableSection.scrollLeft = timetableSection.scrollLeft + wheel.pixelX;
			if (timetableSection.scrollLeft > 0 && this.scrollLeftEmitter)
				this.scrollLeftEmitter.next(timetableSection.scrollLeft);
			event.preventDefault();
		}
	}

	@HostListener('mousewheel', ['$event'])
	mouseWheelEvent(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		let wheel = normalizeWheel(event);
		let driverSection = document.getElementById('driverSection');
		let timetableSection = document.getElementById('timetableSection');
		let roomTimeline = document.getElementById('roomTimeline');
		if (target.tagName.toUpperCase() !== 'TEXTAREA') {
			driverSection.scrollTop = driverSection.scrollTop + wheel.pixelY;
			roomTimeline.scrollTop = roomTimeline.scrollTop + wheel.pixelY;
			timetableSection.scrollLeft = timetableSection.scrollLeft + wheel.pixelX;
			if (timetableSection.scrollLeft > 0 && this.scrollLeftEmitter)
				this.scrollLeftEmitter.next(timetableSection.scrollLeft);
			event.preventDefault();
		}
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { FbStoreUserProfile, FlutaroUserProfile, ROLES } from '@flutaro/package/lib/model/AuthClasses';
import firebase from 'firebase/compat/app';
import { CompanyService } from '../../company/company.service';
import {
	hasAuthorizedWebAppAccess,
	isAuthorizedReadonlyAphroditeUser,
	parseUserProfileIntoWebProfile,
} from '@flutaro/package/lib/functions/auth/AppAuthFunctions';
import { flutaroWait, to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import UserCredential = firebase.auth.UserCredential;
import User = firebase.User;
import { UserSettingsProvider } from '../../settings/settings.user.provider';
import { Tenant } from '@flutaro/package/lib/model/IElement';

@Injectable()
export class WebAuthService {
	$userProfile: BehaviorSubject<FlutaroUserProfile> = new BehaviorSubject<FlutaroUserProfile>(null);
	$userIsAuthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	$user: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
	$userTenantChange: Subject<Tenant> = new Subject<Tenant>();
	// Data Filters - for Readonly Accounts
	$vehicleIdsFilter: BehaviorSubject<string[] | null> = new BehaviorSubject<string[]>(null);
	$driverIdsFilter: BehaviorSubject<string[] | null> = new BehaviorSubject<string[]>(null);
	// TODO: add Relations-Filter Option, but implement if User can choose Account for Filter and/or deactivate unknown relations for readonly / activate filter central?

	constructor(
		public afAuth: AngularFireAuth,
		private db: AngularFirestore,
		private router: Router,
		private companyProvider: CompanyService,
		private userSettingsProvider: UserSettingsProvider,
	) {
		afAuth.user.subscribe(this.$user);
	}

	async getIdToken(forceRefresh?: boolean): Promise<string> {
		return (await this.afAuth.currentUser).getIdToken(forceRefresh);
	}

	getUserProfile(): FlutaroUserProfile {
		return this.$userProfile.getValue();
	}

	/**
	 * Change a Users tenant without storing change in DB - only to update UI in current sessions. Change will be restored to default on reload of page.
	 * $userTenantChange emits an event of type new tenant, which is subscribed by data services to update filter of data
	 * @param tenant
	 */
	changeUsersTenant(tenant: Tenant) {
		console.log(`WebAuthService, changeUsersTenant to Tenant ${tenant?.name}(${tenant?.id})`);
		const userProfile = this.$userProfile.getValue();
		userProfile.tenant = tenant;
		this.$userProfile.next(userProfile);
		this.$userTenantChange.next(tenant);
	}

	signInWithEmail(credentials) {
		console.log('Sign in with email');
		return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
	}

	signUp(credentials): Promise<UserCredential> {
		return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password);
	}

	async signOut() {
		await this.afAuth.signOut();
		this.$userProfile.next(null);
		this.$userIsAuthorized.next(false);
		window.location.href = 'assets/auth/logout.html';
	}

	async getUserFromDB(user: User) {
		const doc = await lastValueFrom(this.db.collection('users').doc<FbStoreUserProfile>(user.uid).get());
		const userProfile = parseUserProfileIntoWebProfile(doc.data());
		this.setUserAuthorizationAndSetRoutes(userProfile);
	}

	async showUserNotAuthorizedView() {
		await this.signOutApp();
		window.location.href = 'assets/auth/errorPages/noWebAppAuthority.html';
	}

	async handleEmailNotVerifiedUser(fbAuth: firebase.User) {
		const [emailError, emailSuccess] = await to(fbAuth.sendEmailVerification());
		if (emailError) {
			console.log(`handleEmailNotVerifiedUser, error when sending email verification`);
			console.error(emailError);
		}
		await this.signOutApp();
		window.location.href = 'assets/auth/errorPages/emailVerification.html';
	}

	async signOutApp() {
		await this.afAuth.signOut();
		this.$user.next(null);
		this.$userIsAuthorized.next(false);

		await flutaroWait(100); // possibly required for signOut to work/clear caches before changing site and leaving angular app, TODO: refactor into component
		return true;
	}

	private async setUserAuthorizationAndSetRoutes(userProfile: FlutaroUserProfile) {
		if (!hasAuthorizedWebAppAccess(userProfile)) {
			this.showUserNotAuthorizedView();
			return;
		}
		userProfile.hasReadOnlyRole = isAuthorizedReadonlyAphroditeUser(userProfile);
		userProfile.hasDisableJobsCreateDeleteRole = userProfile.roles.indexOf(ROLES.DISABLE_DELETE_CREATE_JOBS) !== -1;
		userProfile.hasDisableAllCreateDeleteRole = userProfile.roles.indexOf(ROLES.DISABLE_DELETE_CREATE_ALL) !== -1;
		userProfile.hasApiRole = userProfile.roles.indexOf(ROLES.API_ACTIVATED_COMPANY) !== -1;
		userProfile.hasAppActivatedRole = userProfile.roles.indexOf(ROLES.APP_ACTIVATED_COMPANY) !== -1;
		// New Roles System based Roles
		userProfile.isMapPlanningCompany = userProfile.roles.indexOf(ROLES.MAP_PLANNING) !== -1;
		//userProfile.isMapPlanningCompany = true; // TODO: set me right before deploying :)
		this.companyProvider.listenToCompanySettings(userProfile.company);
		await this.userSettingsProvider.loadAndSetUserSettings();
		this.$userProfile.next(userProfile);
		this.$userIsAuthorized.next(true);
		this.navigateAfterLogin();
	}

	private navigateAfterLogin() {
		if (this.getUserProfile().hasReadOnlyRole) {
			this.router.navigate(['planning']);
		} else {
			this.router.navigate(['dashboard']);
		}
	}
}

<div fxLayout="column">
	<mat-toolbar fxLayoutAlign="center center">
		<h1 class="mat-toolbar-tools dialogHeadline">
			<i class="material-icons dialogIconBackground statusCreateDialogIconBackground"></i>
			<i class="material-icons statusIconToolbar">info_outline</i>
			<span *ngIf="isNew">{{ 'NEW' | translate }}</span> {{ 'VEHICLE_STATUS' | translate }}
		</h1>
	</mat-toolbar>
	<mat-dialog-content class="layout-margin" fxFlex="100" fxLayout="column">
		<div fxLayout="row">
			<p class="statusCreateVehicle">
				<i class="material-icons statusCreateSummaryIcon">local_shipping</i>
				<span class="fontWeight600">{{ vehicle.name }}</span>
				<span class="statusCreateVehicleName">{{ vehicle.licensePlate }}</span>
			</p>
		</div>

		<app-datetimepicker
			(isDateValid)="changeStatusOnDateValidState($event)"
			[(date)]="status.displayDate"
			[required]="true"
			[type]="'datetime'"
			placeholder="{{ 'DATE_OF_STATUS' | translate }}"
		>
		</app-datetimepicker>

		<div class="statusCreateDialogNotes" fxLayout="column">
			<mat-form-field>
				<textarea
					[(ngModel)]="status.description"
					[cdkAutosizeMaxRows]="5"
					[cdkAutosizeMinRows]="1"
					[cdkTextareaAutosize]="true"
					[matTooltipPosition]="'below'"
					matInput
					matTooltip="{{ 'VEHICLE_STATUS_DESCRIPTION_TT' | translate }}"
					placeholder="{{ 'VEHICLE_STATUS_DESCRIPTION' | translate }}"
				>
				</textarea>
			</mat-form-field>
		</div>
		<span *ngIf="!isNew" class="statusCreateDialogLastModified"
			>{{ 'CREATED_CHANGED_BY' | translate }}: <br />
			<span class="statusCreateDialogLastModifiedBy"
				><i class="material-icons updatePersonIcon">person</i>{{ status.user }}
				<i class="material-icons updateTimeIcon">access_time</i
				>{{ status.updatedOnDate | appFormatDate: 'datetime' }}</span
			></span
		>
	</mat-dialog-content>

	<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
		<button
			(click)="deleteStatus()"
			*ngIf="!isNew"
			[disabled]="userProfile | isReadonly"
			class="buttonDelete mat-raised"
			mat-button
		>
			<mat-icon>
				<i class="material-icons">delete</i>
			</mat-icon>
			{{ 'DELETE' | translate }}
		</button>
		<button (click)="onAbort()" class="buttonCancel mat-raised" mat-button>
			<mat-icon>
				<i class="material-icons">close</i>
			</mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button
			(click)="saveStatus()"
			[disabled]="(userProfile | isReadonly) || !isValidStatus"
			class="buttonDone mat-primary_flutaro"
			mat-button
		>
			<mat-icon>
				<i class="material-icons material-icons-white">done</i>
			</mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</div>

<mat-form-field class="routingProfilePicker mat-form-field-no-padding">
	<mat-select
		[(ngModel)]="routingProfile"
		(ngModelChange)="emitRoutingProfileChange()"
		placeholder="{{ 'ROUTING_PROFILE' | translate }}"
		matTooltip="{{ 'ROUTING_PROFILE_TT' | translate }}"
		[matTooltipPosition]="'below'"
	>
		<mat-option *ngFor="let profile of routingProfiles" [value]="profile">
			{{ profile | translateRoutingProfile }}
		</mat-option>
	</mat-select>
</mat-form-field>

<div fxLayout="column">
	<div id="jobPlanned">
		<div class="jobPlannedRelativeStart">
			<app-datetimepicker
				[(ngModel)]="destination.plannedDate"
				(ngModelChange)="emitChanges()"
				[disabled]="disabled"
				[max]="destination.latestDate"
				[min]="destination | getDestinationsMinDate: destinationIndex:job.destinations"
				[type]="'datetime'"
			>
			</app-datetimepicker>
		</div>
	</div>
	<div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
		<button
			(click)="openAdjustEndDateDialog()"
			*ngIf="destination.locationType === 'DELIVERY'"
			[disabled]="disabled"
			[matTooltipPosition]="'left'"
			class="mat-small"
			id="jobSidenavAdjustDates"
			mat-button
			matTooltip="{{ 'JOB_ADJUST_DATES_TT' | translate }}"
		>
			<i class="material-icons jobSidenav-adjustDates-icon">tune</i>
		</button>
		<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
			<span class="sideNavEarlyLatestLabel">
				<i class="material-icons sidenavEarlyLatestIcon">access_time</i>{{ 'EARLIESTDATE' | translate }}
			</span>
			<p class="margin0 fontWeight500">
				{{ destination.earliestDate | appFormatDate: 'datetime' }}
			</p>
		</div>
		<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
			<span class="sideNavEarlyLatestLabel">
				<i class="material-icons sidenavEarlyLatestIcon">alarm</i>{{ 'LATESTDATE' | translate }}
			</span>
			<p class="margin0 fontWeight500">
				{{ destination.latestDate | appFormatDate: 'datetime' }}
			</p>
		</div>
	</div>
</div>

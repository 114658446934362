import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { FlutaroDaterangeComponent } from '../dialog/dialog.daterange.component';
import { DialogModule } from '../dialog/dialog.module';
import { SynonymModule } from '../synonym/synonym.module';
import { TableModule } from '../table/table.module';
import { JobAdjustDatesDialogComponent } from './components/job.dates.adjust.dialog.component';
import { JobPlannedComponent } from './components/job.planned.component';
import { JobDestinationComponent } from './form/job.destination.component';
import { JobDialogComponent } from './form/job.dialog.component';
import { JobRepeatDeleteDialogComponent } from './form/job.repeat.delete.component';
import { JobRepeatingComponent } from './form/job.repeating.component';
import { JobRepeatingProvider } from './form/job.repeating.provider';
import { JobRequirementsComponent } from './form/job.requirements.component';
import { JobAddComponent } from './job.add.component';
import { JobHelperService } from './job.helper.service';
import {
	GetAllJobsMaximumDestinationsLength,
	GetDeliveryDestinationsPipe,
	GetFirstPickUpDatePipe,
	GetFirstPickUpPipe,
	GetIsDifferentJobCostsPipe,
	GetLastDeliveryDatePipe,
	GetLastDestinationPipe,
	GetPickupDestinationsPipe,
	NumberInPercentagePipe,
} from './job.pipes';
import { JobTableComponent } from './job.table.component';
import { JobAppHistoryComponent } from './job-app-history/job-app-history.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CargoComponent } from './destinations/cargo/cargo.component';
import { ChildCargoComponent } from './destinations/cargo/child-cargo/child-cargo.component';
import { VehicleModule } from '../vehicle/vehicle.module';
import { PipesModule } from '../app/pipes.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { AppFormModule } from '../form/form.module';
import { SelectVehicleForJobComponent } from './components/select-vehicle-for-job/select-vehicle-for-job.component';
import { FlutaroComponentsJobModule } from 'flutaro-lib-angular/lib/components/job/flutaro.components.job.module';

@NgModule({
	declarations: [
		JobDialogComponent,
		FlutaroDaterangeComponent,
		NumberInPercentagePipe,
		JobTableComponent,
		JobAddComponent,
		JobDestinationComponent,
		JobRequirementsComponent,
		JobPlannedComponent,
		JobRepeatingComponent,
		JobRepeatDeleteDialogComponent,
		JobAdjustDatesDialogComponent,
		JobAppHistoryComponent,
		DestinationsComponent,
		CargoComponent,
		ChildCargoComponent,
		GetFirstPickUpPipe,
		GetFirstPickUpDatePipe,
		GetLastDestinationPipe,
		GetLastDeliveryDatePipe,
		GetAllJobsMaximumDestinationsLength,
		GetPickupDestinationsPipe,
		GetDeliveryDestinationsPipe,
		SelectVehicleForJobComponent,
		GetIsDifferentJobCostsPipe,
	],
	exports: [
		FlutaroDaterangeComponent,
		JobDestinationComponent,
		JobAddComponent,
		JobTableComponent,
		NumberInPercentagePipe,
		JobRequirementsComponent,
		JobPlannedComponent,
		JobAdjustDatesDialogComponent,
		JobAppHistoryComponent,
		DestinationsComponent,
		GetFirstPickUpPipe,
		GetFirstPickUpDatePipe,
		GetLastDestinationPipe,
		GetLastDeliveryDatePipe,
		GetAllJobsMaximumDestinationsLength,
		GetPickupDestinationsPipe,
		GetDeliveryDestinationsPipe,
		SelectVehicleForJobComponent,
		GetIsDifferentJobCostsPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		SynonymModule,
		TableModule,
		DialogModule,
		MatFormFieldModule,
		AppFormModule,
		VehicleModule,
		PipesModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatSelectModule,
		FlutaroComponentsJobModule,
	],
	providers: [JobHelperService, JobRepeatingProvider],
})
export class JobModule {}

<div fxLayout="row" fxLayoutAlign="center center">
	<div class="weekPickerRowWithArrows" fxLayout="row" fxLayoutAlign="center center">
		<button
			*ngIf="!timetable.isDayTimetable"
			(click)="prevWeek()"
			[matTooltipPosition]="'below'"
			class="mat-small mat-ink-ripple"
			id="weekPickerBackButton"
			mat-button
			matTooltip="{{ 'PREVIOUS_KW' | translate }}"
		>
			<i class="material-icons weekPicker-button-icon">chevron_left</i>
		</button>
		<span class="weekPickerCalendarWeek">KW {{ timetable.startDate | appFormatDate: 'week-of-year' }}</span>
		<div
			[matTooltipPosition]="'below'"
			[class.weekPickerRowWithoutArrowsHeight]="timetable.isDayTimetable"
			matTooltip="{{ (timetable.isDayTimetable ? 'SELECT_DAY_IN_KW' : 'SELECT_CALENDAR_WEEK') | translate }}"
		>
			<app-datetimepicker
				(dateChange)="changeCalendarWeekFromPicker($event)"
				[date]="timetable.startDate"
				[hideDateInput]="true"
				[type]="'date'"
				[class.weekcalendarweek]="!timetable.isDayTimetable"
				[class.daycalendarweek]="timetable.isDayTimetable"
				id="weekDatePicker"
				[flutaroDatetimepickerFilter]="!timetable.isDayTimetable ? onlyMondayDateFilter : null"
			>
			</app-datetimepicker>
		</div>
		<button
			*ngIf="!timetable.isDayTimetable"
			(click)="nextWeek()"
			[matTooltipPosition]="'below'"
			class="mat-small mat-ink-ripple"
			id="weekPickerForwardButton"
			mat-button
			matTooltip="{{ 'NEXT_KW' | translate }}"
		>
			<i class="material-icons weekPicker-button-icon">chevron_right</i>
		</button>
	</div>
	<div class="weekPickerRowWithoutArrows" fxLayout="row" fxLayoutAlign="start center">
		<mat-radio-group
			(ngModelChange)="setDayInCurrentIntervalByNumber($event)"
			[ngModelOptions]="{ standalone: true }"
			[ngModel]="dayOfWeek"
			fxLayoutGap="5px"
		>
			<mat-radio-button *ngFor="let day of days" [value]="day.value">
				{{ day.date | appFormatDate: 'weekday-short' }}
			</mat-radio-button>
		</mat-radio-group>
	</div>
</div>

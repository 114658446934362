import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImportExcelFileProvider } from '../import.excel.file.provider';
import { ImportDataTableSettingsProvider } from '../import.table.settings.provider';
import { dataTypesDefinition } from '../import.excel.classes';
import { ImportExcelStorageProvider } from '../import.excel.store.provider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportValidationProvider } from '../import.validation.provider';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { HotTableRegisterer } from '@handsontable/angular';
import {
	checkTotalValidationStatus,
	validateExcelJobRow,
	validateImportJobExcelDates,
} from '../validation/ExcelImportValidationFunctions';
import { handleExcelRowInit } from './ImportExcelComponentFunctions';
import Handsontable from 'handsontable';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import CellChange = Handsontable.CellChange;

@Component({
	selector: 'app-import-excel',
	templateUrl: './import.excel.component.html',
	styleUrls: ['./import.excel.component.scss'],
})
export class ImportExcelComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() companySettings: CompanySettings;
	instance: string = 'hotInstance';
	public dataTypes: string[] = [dataTypesDefinition.job, dataTypesDefinition.driver, dataTypesDefinition.vehicle];
	// Table General
	settings: any;
	dataType: string = dataTypesDefinition.job;
	isInImportState: boolean = false;
	// Data specific
	tableHeaders;
	tableColumns: any[];
	tableColumnWidths: number[];
	private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

	constructor(
		public excelProvider: ImportExcelFileProvider,
		public tableSettingsProvider: ImportDataTableSettingsProvider,
		public excelStoreProvider: ImportExcelStorageProvider,
		public validationProvider: ImportValidationProvider,
		private notifications: FlutaroNotificationsProvider,
		private _hotRegisterer: HotTableRegisterer,
		private changeDetector: ChangeDetectorRef,
	) {}

	private static isCellDate(prop: string) {
		return (
			prop === 'earliestStartDate' ||
			prop === 'latestStartDate' ||
			prop === 'earliestEndDate' ||
			prop === 'latestEndDate'
		);
	}

	ngOnInit() {
		this.initTableOptions();
		this.initJobTableSettings();
	}

	ngAfterViewInit() {
		this.tableSettingsProvider.instance = this._hotRegisterer.getInstance(this.instance);
		this.tableSettingsProvider.instance.render();
		this.changeDetector.detectChanges();
		this.tableSettingsProvider.instance.addHook('afterChange', (changes: CellChange[]) => {
			if (changes && !changes.some((change) => change[3] !== change[2])) return; // Stop checks if no change happened at all
			if (changes) this.changeDataOnChanges(changes);
			this.validationProvider.calculateValidationStatus(this.dataType, this.excelProvider.excelImportData, null);
			this.changeDetector.detectChanges();
		});
		this.tableSettingsProvider.instance.addHook('afterRemoveRow', (changes) => {
			this.validationProvider.calculateValidationStatus(this.dataType, this.excelProvider.excelImportData, null);
			this.changeDetector.detectChanges();
		});
		this.excelProvider.isFinishedDataLoading.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isFinished) => {
			if (!isFinished) {
				return;
			}
			this.validationProvider.calculateValidationStatus(this.dataType, this.excelProvider.excelImportData, null);
			this.tableSettingsProvider.instance.render();
			this.excelProvider.showProgressCircle = false;
			this.changeDetector.detectChanges();
		});
	}

	changeDataOnChanges(changes: CellChange[]) {
		changes.forEach((change) => {
			if (change[3] === change[2]) return; // Stop checks if no change happened
			const newValue = change[3] && typeof change[3] === 'string' ? change[3].trimLeft().trimRight() : '';
			change[3] = newValue;
			const rowNumber = change[0];
			const editedCell = change[1];
			this.excelProvider.excelImportData[rowNumber][editedCell] = newValue; // Update change in Data-Object
			this.handleSingleAddressCellChange(change);
			this.handleDateCellChange(change);
			handleExcelRowInit(this.dataType, this.excelProvider.excelImportData[rowNumber]);
			validateExcelJobRow(this.excelProvider.excelImportData[rowNumber]);
		});
		this.tableSettingsProvider.instance.render();
		this.changeDetector.detectChanges();
	}

	public initJobTableSettings() {
		this.tableColumnWidths = this.tableSettingsProvider.initializeJobColumnWidths();
		this.tableColumns = this.tableSettingsProvider.initializeJobColumns();
		this.tableHeaders = this.tableSettingsProvider.initializeJobTableHeaders();
	}

	public initDriverTableSettings() {
		this.tableColumnWidths = this.tableSettingsProvider.initializeDriverColumnWidths();
		this.tableColumns = this.tableSettingsProvider.initializeDriverColumns();
		this.tableHeaders = this.tableSettingsProvider.initializeDriverTableHeaders();
	}

	public initVehicleTableSettings() {
		this.tableColumnWidths = this.tableSettingsProvider.initializeVehicleColumnWidths();
		this.tableColumns = this.tableSettingsProvider.initializeVehicleColumns();
		this.tableHeaders = this.tableSettingsProvider.initializeVehicleTableHeaders();
	}

	async saveData() {
		if (!checkTotalValidationStatus(this.validationProvider.currentValidationStatus)) {
			let text = 'Speichern nicht möglich: Nicht alle Daten sind gültig.';
			this.notifications.showBasicSnackBar(text);
			return;
		}
		this.isInImportState = true;
		this.excelStoreProvider.checkAndDeleteEmptyLastRows(<any>this.excelProvider.excelImportData);

		switch (this.dataType) {
			case dataTypesDefinition.job:
				await this.excelStoreProvider.saveJobs(<any>this.excelProvider.excelImportData);
				break;
			case dataTypesDefinition.driver:
				this.excelStoreProvider.saveDrivers(<any>this.excelProvider.excelImportData);
				break;
			case dataTypesDefinition.vehicle:
				this.excelStoreProvider.saveVehicles(<any>this.excelProvider.excelImportData);
				break;
			default:
				break;
		}
		this.changeDataType();
		this.isInImportState = false;
	}

	ngOnDestroy() {
		this._hotRegisterer.removeInstance(this.instance);
		this.tableSettingsProvider.instance = null;
		this.emptyData(true);
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
	}

	changeDataType($event?) {
		this.emptyData();
		if ($event) {
			this.tableSettingsProvider.dataType = $event;
			this.dataType = $event;
		}
		// Init options again to update Headers
		this.initTableOptions();
		this.changeTableSettingsForType();
		this.changeDetector.detectChanges();
	}

	/**
	 *
	 * @param stopChangeDetection - Prevent ViewDestroyedError: Attempt to use a destroyed view: detectChanges
	 */
	emptyData(stopChangeDetection?: boolean) {
		this.excelProvider.fileName = null;
		this.excelProvider.excelImportData = [{}];
		this.validationProvider.clearValidationData();
		if (!stopChangeDetection) this.changeDetector.detectChanges();
	}

	protected isCellSynonym(editedCell: string) {
		return editedCell === 'startAddress' || editedCell === 'endAddress';
	}

	protected changeTableSettingsForType() {
		switch (this.tableSettingsProvider.dataType) {
			case dataTypesDefinition.job:
				this.initJobTableSettings();
				break;
			case dataTypesDefinition.driver:
				this.initDriverTableSettings();
				break;
			case dataTypesDefinition.vehicle:
				this.initVehicleTableSettings();
				break;
			default:
				break;
		}
	}

	private initTableOptions() {
		this.settings = this.tableSettingsProvider.initializeTableSettings();
	}

	private handleDateCellChange(change: CellChange) {
		const column = <string>change[1];
		if (!ImportExcelComponent.isCellDate(column)) {
			return;
		}
		const rowNumber = change[0];
		validateImportJobExcelDates(this.excelProvider.excelImportData[rowNumber]);
	}

	private async handleSingleAddressCellChange(change: CellChange) {
		const editedCell = <string>change[1];
		if (!this.isCellSynonym(editedCell)) {
			return;
		}
		let rowNumber = change[0];
		this.excelProvider.showProgressCircle = true;
		this.excelProvider.increaseAwaitingAddressCounter();
		// Job Import
		if (editedCell.startsWith('start')) {
			await this.excelProvider.extractJobsAddress('start', this.excelProvider.excelImportData[rowNumber]);
		} else if (editedCell.startsWith('end')) {
			await this.excelProvider.extractJobsAddress('end', this.excelProvider.excelImportData[rowNumber]);
			// Synonym
		} else {
			await this.excelProvider.extractSynonymsAddress(this.excelProvider.excelImportData[rowNumber]);
		}
		this.excelProvider.isFinishedDataLoading.next(this.excelProvider.$awaitingAddressesCounter.getValue() === 0);
	}
}

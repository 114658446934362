import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import * as QuillNamespace from 'quill';
import { DashboardNews } from '@flutaro/package/lib/model/DashboardNews';

let Quill: any = QuillNamespace;
// override p with div tag
const Parchment = Quill.import('parchment');
let Block = Parchment.query('block');

Block.tagName = 'DIV';
// or class NewBlock extends Block {}; NewBlock.tagName = 'DIV';wid
Quill.register(Block /* or NewBlock */, true);

// Add fonts to whitelist
var Font = Quill.import('formats/font');
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ['mirza', 'aref', 'sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

const toolbarOptionsEditor = {
	container: [
		//['emoji'],
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		['blockquote', 'code-block'],

		[{ header: 1 }, { header: 2 }], // custom button values
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
		[{ direction: 'rtl' }], // text direction

		[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
		[{ header: [1, 2, 3, 4, 5, 6, false] }],

		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ font: [] }],
		[{ align: [] }],

		['clean'], // remove formatting button

		['link', 'image', 'video'], // link and image, video
	],
	handlers: {
		emoji: function () {},
	},
};

const toolbarOptionsReadonly = {
	container: [],
};

@Component({
	selector: 'app-news-dialog',
	templateUrl: './widget.news.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetNewsDialogComponent implements OnInit {
	news: DashboardNews;
	isEditor: boolean;
	quillModule;
	isDateValid: boolean = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: NewsDialogData,
		@Inject(MatDialogRef) public dialogRef: MatDialogRef<WidgetNewsDialogComponent>,
		@Inject(FlutaroNotificationsProvider) private notifications: FlutaroNotificationsProvider,
		@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.news = this.dialogData.news;
		this.isEditor = this.dialogData.editorMode && this.news.company !== 'Flutaro';
		this.initializeQuillOptions();
		this.changeDetectorRef.markForCheck();
	}

	initializeQuillOptions() {
		if (this.isEditor) {
			this.quillModule = {
				toolbar: toolbarOptionsEditor,
				//toolbar_emoji: true,
				//short_name_emoji: true,
			};
		} else {
			this.quillModule = {
				toolbar: toolbarOptionsReadonly,
			};
		}
	}

	saveNews() {
		if (!this.news.title) {
			this.notifications.showBasicSnackBar('Feld: Titel ist erforderlich.');
			return;
		}
		if (!this.news.shortDescription) {
			this.notifications.showBasicSnackBar('Feld: Kurzbeschreibung ist erforderlich.');
			return;
		}
		this.news.author = this.dialogData.author;
		// TODO: check if this is required
		//this.news.company = this.dialogData.company;
		this.dialogRef.close(this.news);
	}

	abbort() {
		this.dialogRef.close(false);
	}
}

export class NewsDialogData {
	news: DashboardNews;
	author: string;
	editorMode: boolean;
	company: string;
}

<form [formGroup]="childCargoForm">
	<mat-toolbar *ngIf="!disabled" fxLayout="row" style="height: 35px">
		<div class="mat-toolbar-tools">
			<i class="material-icons cargoSection">add_cargo</i><span>{{ 'CARGO_CONTENTS' | translate }}</span>
		</div>

		<button
			(click)="addCargo()"
			[matTooltipPosition]="'below'"
			class="buttonAddCargoButton mat-fab mat-flut"
			mat-fab
			matTooltip="{{ 'ADD_CARGO' | translate }}"
			name="addCargo"
		>
			<mat-icon class="material-icons fab-icon-add">
				<i class="material-icons addIconDialogButton"> add </i>
			</mat-icon>
		</button>
	</mat-toolbar>
	<mat-accordion>
		<div formArrayName="childCargoRows" style="margin-top: 5px">
			<div *ngFor="let cargoControl of childCargoRows.controls; let j = index" [formGroupName]="j">
				<mat-expansion-panel
					#cargo
					(closed)="setCargoInputstate(j, 'close')"
					(opened)="setCargoInputstate(j, 'open')"
					[expanded]="childCargoStep === j"
					style="margin-bottom: 10px"
				>
					<mat-expansion-panel-header class="matPanelCustom">
						<mat-panel-title>
							{{ cargoControl.get('contents').value }}
						</mat-panel-title>
					</mat-expansion-panel-header>

					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field fxFlex="100" fxFlex.gt-sm="100">
							<mat-label>{{ 'CARGO_CONTENT' | translate }}</mat-label>
							<input formControlName="contents" matInput />
						</mat-form-field>
					</div>

					<mat-action-row *ngIf="!disabled">
						<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" name="cargoActionButtons">
							<button (click)="prevCargo()" *ngIf="j > 0" class="destinationButtonsSmall" mat-flat-button>
								<mat-icon><i class="material-icons">arrow_back</i></mat-icon>
								{{ 'BACK' | translate }}
							</button>
							<button
								(click)="nextCargo()"
								*ngIf="!(j + 1 === childCargoRows.controls.length) && childCargoRows.controls.length > 1"
								class="destinationButtonsSmall"
								mat-flat-button
							>
								<mat-icon><i class="material-icons">arrow_forward</i></mat-icon>
								{{ 'NEXT' | translate }}
							</button>
							<button (click)="removeCargo(j)" class="destinationButtonsSmallDelete" mat-flat-button>
								<mat-icon><i class="material-icons">delete</i></mat-icon>
								{{ 'DELETE' | translate }}
							</button>
						</div>
					</mat-action-row>
				</mat-expansion-panel>
			</div>
		</div>
	</mat-accordion>
</form>

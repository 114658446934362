import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { AppPushProvider } from '../app/push/app.push.provider';
import { ErrorNotificationComponent } from './notification.error.component';
import { DataChange } from '@flutaro/package/lib/model/AppDataTypes';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FlutaroNotificationsProvider {
	constructor(
		private snackBar: MatSnackBar,
		private pushProvider: AppPushProvider,
		private translate: TranslateService,
	) {
		this.pushProvider.dataChangeSubject.subscribe((dataChange) => this.showWebSocketMessage(dataChange));
	}

	showBasicSnackBar(text: string) {
		let config = new MatSnackBarConfig();
		config.duration = 5000;
		this.snackBar.open(text, null, config);
	}

	showActionAbortedSnackBar() {
		let config = new MatSnackBarConfig();
		config.duration = 5000;
		this.snackBar.open(this.translate.instant('ABORTED'), null, config);
	}

	showInvalidDestinationsError() {
		const text =
			'Der Auftrag enthält Destinationen mit Datumsangaben, die ein invalides Zeitinterval erzeugen würden. Prüfe bitte, ob jedes geplante Datum zwischen dem frühesten und spätesten Datum liegt, das Zeitinverval jeder Destination positiv ist und jede Destination auf die vorherige folgt';
		let config = new MatSnackBarConfig();
		config.duration = 5000;
		this.snackBar.open(text, null, config);
	}

	showErrorMessage(text: string) {
		this.snackBar.openFromComponent(ErrorNotificationComponent, {
			data: text,
			duration: 0,
		});
	}

	showWebSocketMessage(dataChange: DataChange<any>) {
		const text = `${this.translate.instant(
			dataChange.dataType.toUpperCase(),
		)} ${dataChange.element.toString()} ${this.translate.instant('WAS')} ${this.translate.instant(
			dataChange.actionType + '__NFW',
		)}`;
		this.showBasicSnackBar(text);
	}
}

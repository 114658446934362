import { Injectable } from '@angular/core';
import {
	detectAndReturnFollowingJobCase,
	getEmptyKmAddress,
	getJobsStartingOnSameDayAsCalculationJob,
	prepareCostDataForSpotVehicle,
} from './CostsDataFunctions';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { ContractorDataProvider } from '../contractor/contractor.data.provider';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { CostCalculationData, CostCalculationVariableData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { getVehicleCostsPerKm } from '@flutaro/package/lib/functions/costs/CostsCalculationFunctions';
import {
	getCostCalculationErrorValidationObject,
	isReducedCostsCase,
} from '@flutaro/package/lib/functions/costs/CostsCheckFunctions';
import { isCustomContractorEmptyKmCalculation } from '@flutaro/package/lib/functions/costs/CostsCustomContractCalculations';
import { getContractorsRateForJobsDate } from '@flutaro/package/lib/functions/costs/CostsJobFunctions';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { CompanyService } from '../company/company.service';
import { getVehiclesDriverIdByDate } from '@flutaro/package/lib/functions/VehicleFunctions';
import { getRoutingProfileForCalculation } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { getJobsFirstPickupDate } from '@flutaro/package/lib/functions/job/DestinationFunctions';
import { flutaroCloneDeep } from '@flutaro/package/lib/functions/FlutaroDataFunctions';

@Injectable()
export class CostsDataService {
	constructor(
		private jobService: FlutaroJobService,
		private contractorService: ContractorDataProvider,
		private driverService: FlutaroDriverService,
		private vehicleService: FlutaroVehicleService,
		private userSettings: UserSettingsProvider,
		private authProvider: WebAuthService,
		private companyService: CompanyService,
	) {}

	/**
	 * Creates all Data required for Cost Calculation.
	 * Creates copies of jobs.
	 * @param newJob
	 * @param oldJob
	 */
	createJobCostData(newJob: JobWrapper, oldJob: JobWrapper): CostCalculationData | null {
		const companySettings = this.companyService.$companySettings.getValue();
		const vehicle = this.vehicleService.getElementById(newJob.vehicleId);
		const driver = this.driverService.getElementById(
			getVehiclesDriverIdByDate(vehicle, getJobsFirstPickupDate(newJob)),
		);
		if (!vehicle || (!companySettings.isDriverDeactivated && !driver)) {
			// TODO: add error handling if no CostData could be created - f.ex. if vehicle was deleted from system
			console.error(`createJobCostData, no vehicle or driver available - aborting, this should be handled before!`);
			return null;
		}

		const contractor = this.contractorService.getContractorForVehicle(vehicle.backendId);
		const contractorCostRate = getContractorsRateForJobsDate(newJob, contractor);
		const isCustomContractorCalculation: boolean = <boolean>(
			isCustomContractorEmptyKmCalculation(newJob, contractorCostRate)
		);
		const newJobCopy: JobWrapper = flutaroCloneDeep(newJob);
		const otherVehiclesJobs = this.jobService
			.getJobsForVehicle(newJobCopy.vehicleId)
			.filter((vehicleJob) => vehicleJob.backendId !== newJobCopy.backendId);

		newJobCopy.costCalculation.emptyKmAddress = getEmptyKmAddress(newJob, vehicle, driver, otherVehiclesJobs);
		newJobCopy.costCalculation.routingProfile = getRoutingProfileForCalculation(companySettings, contractorCostRate);
		newJobCopy.costCalculation.isReduced = isReducedCostsCase(newJob, otherVehiclesJobs);
		newJobCopy.costCalculation.vehicleKmRate = getVehicleCostsPerKm(newJob, vehicle, contractor);
		if (companySettings.routingSettings?.excludedCountries)
			newJobCopy.costCalculation.excludedCountries = companySettings.routingSettings.excludedCountries;

		// New calculation routing related settings End
		let adjustedVehicleCostData = new CostCalculationVariableData(
			newJobCopy.costCalculation.isReduced,
			getJobsStartingOnSameDayAsCalculationJob(newJob, otherVehiclesJobs),
			detectAndReturnFollowingJobCase(newJob, otherVehiclesJobs),
		);
		prepareCostDataForSpotVehicle(vehicle, adjustedVehicleCostData);
		const errors = getCostCalculationErrorValidationObject(
			newJob,
			contractor,
			vehicle,
			adjustedVehicleCostData.jobsStartingOnSameDay,
			otherVehiclesJobs,
			adjustedVehicleCostData.followingJob,
		);

		return new CostCalculationData(
			newJobCopy,
			oldJob,
			adjustedVehicleCostData.followingJob,
			this.userSettings.$userSettings.getValue(),
			companySettings,
			this.authProvider.$userProfile.getValue(),
			contractor,
			driver,
			vehicle,
			errors,
			adjustedVehicleCostData.jobsStartingOnSameDay,
			contractorCostRate,
			isCustomContractorCalculation,
			adjustedVehicleCostData.isReduced,
		);
	}
}

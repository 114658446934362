<div *ngIf="(menuProvider.$currentTab | async) === MENU.DRIVERS">
	<app-driver-table
		[companySettings]="companyService.$companySettings | async"
		[data]="driverService.$data | async"
		[vehicles]="vehicleService.$data | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettingsProvider.$userSettings | async"
	>
	</app-driver-table>
</div>
<div *ngIf="(menuProvider.$currentTab | async) === MENU.VEHICLES">
	<app-vehicle-table
		[companySettings]="companyService.$companySettings | async"
		[isDataLoading]="vehicleService.$isInReceiveState | async"
		[data]="vehicleService.$data | async"
		[drivers]="driverService.$data | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettingsProvider.$userSettings | async"
	>
	</app-vehicle-table>
</div>
<div *ngIf="(menuProvider.$currentTab | async) === MENU.CUSTOMERS">
	<client-table
		[companySettings]="companyService.$companySettings | async"
		[isDataLoading]="clientService.$isInReceiveState | async"
		[data]="clientService.$data | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettingsProvider.$userSettings | async"
	>
	</client-table>
</div>
<div *ngIf="(menuProvider.$currentTab | async) === MENU.CONTRACTOR">
	<app-contractor-table
		[companySettings]="companyService.$companySettings | async"
		[isDataLoading]="contractorProvider.$isInReceiveState | async"
		[data]="contractorProvider.$data | async"
		[drivers]="driverService.$data | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettingsProvider.$userSettings | async"
		[vehicles]="vehicleService.$data | async"
	>
	</app-contractor-table>
</div>

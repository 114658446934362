import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { appRoundNumber } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { CostCalculationData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { calculateProfit } from '@flutaro/package/lib/functions/costs/CostsCalculationFunctions';
import { JobCosts } from '@flutaro/package/lib/model/costs/Costs';
import { ContractorRate } from '@flutaro/package/lib/model/Contractor';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';

export function calculateFixCostsForJob(
	job: JobWrapper,
	jobsStartingOnSameDay: JobWrapper[],
	totalFixedCosts: number,
): number {
	if (!jobsStartingOnSameDay.length) return totalFixedCosts;

	const revenueJobs = jobsStartingOnSameDay.slice();
	revenueJobs.push(job);
	const revenueSum = revenueJobs.reduce((a, b) => a + b.revenue, 0);
	return appRoundNumber((job.revenue / revenueSum) * totalFixedCosts);
}

export function updateJobsProfitAndCostsForFixedCostChanges(costData: CostCalculationData) {
	costData.job.costCalculation.profit = appRoundNumber(
		costData.job.costCalculation.profit + costData.job.costCalculation.vehicleFixedCosts,
	);
	costData.job.costCalculation.totalCosts = appRoundNumber(
		costData.job.costCalculation.totalCosts - costData.job.costCalculation.vehicleFixedCosts,
	);
	calculateJobsCostCalculationFixedCosts(costData);
	costData.job.costCalculation.profit = appRoundNumber(
		costData.job.costCalculation.profit - costData.job.costCalculation.vehicleFixedCosts,
	);
	costData.job.costCalculation.totalCosts = appRoundNumber(
		costData.job.costCalculation.totalCosts + costData.job.costCalculation.vehicleFixedCosts,
	);
}

export function calculateJobsCostCalculationFixedCosts(costData: CostCalculationData) {
	costData.job.costCalculation.vehicleCostsFixedTotal = calculateVehicleCostsFixed(
		costData.contractorCostRate,
		costData.vehicle,
		costData.isReduced,
	);
	costData.job.costCalculation.vehicleFixedCosts = calculateFixCostsForJob(
		costData.job,
		costData.jobsStartingOnSameDay,
		costData.job.costCalculation.vehicleCostsFixedTotal,
	);
	console.log(
		`calculateJobsCostCalculationFixedCosts, calculated ${costData.job.costCalculation.vehicleFixedCosts} as fix costs for job ${costData.job.job.identifier} with ${costData.jobsStartingOnSameDay.length} jobsStartingOnSameDay and totalFixedCosts: ${costData.job.costCalculation.vehicleCostsFixedTotal}`,
	);
}

export function calculateDoubleFixedCosts(applyDoubleFixedCosts: boolean, job: JobWrapper) {
	if (applyDoubleFixedCosts) {
		addAddedFixCostsToProfile(job.costCalculation, job.costCalculation.vehicleCostsFixedTotal);
	} else {
		removeAddedFixCostsToProfiles(job);
	}
}

export function addAddedFixCostsToProfile(profile: JobCosts, addedFixCosts: number) {
	profile.addedFixCosts = addedFixCosts;
	profile.totalCosts = appRoundNumber(profile.totalCosts + profile.addedFixCosts);
	profile.profit = calculateProfit(profile.revenue, profile.totalCosts);
}

export function removeAddedFixCostsToProfiles(job: JobWrapper) {
	job.costCalculation.totalCosts = appRoundNumber(job.costCalculation.totalCosts - job.costCalculation.addedFixCosts);
	job.costCalculation.addedFixCosts = 0;
	job.costCalculation.profit = calculateProfit(job.costCalculation.revenue, job.costCalculation.totalCosts);
}

export function calculateVehicleCostsFixed(
	contractorCostRate: ContractorRate | undefined,
	vehicle: Vehicle,
	isReduced: boolean,
): number {
	let fixedCosts = 0;
	if (contractorCostRate) {
		if (contractorCostRate.dailyRate) fixedCosts = contractorCostRate.dailyRate;
		if (
			contractorCostRate.vehicleTypeWithSpecificRateFixedCosts &&
			contractorCostRate.vehicleTypeWithSpecificRate === vehicle.carType
		)
			fixedCosts = contractorCostRate.vehicleTypeWithSpecificRateFixedCosts;
	} else {
		if (vehicle.fixedDailyCosts) fixedCosts = vehicle.fixedDailyCosts;
	}

	// Reduced Costs Case - Applied last, independent from vehicle Type
	if (
		contractorCostRate &&
		contractorCostRate.useReducedDailyRate &&
		isReduced &&
		contractorCostRate.reducedDailyRate
	) {
		fixedCosts = contractorCostRate.reducedDailyRate;
	}

	return fixedCosts;
}

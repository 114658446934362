import { Injectable } from '@angular/core';
import { FlutaroJobService } from '../../data/data-services/data.job.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { addDays, addWeeks, differenceInDays, differenceInWeeks, getISODay, isSameISOWeek } from 'date-fns';
import { calculateNewJobDatesBasedOnRefDate } from './JobRepeatingFunctions';
import { JobRepeatingMode, JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { getJobsFirstPickupDate } from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Injectable()
export class JobRepeatingProvider {
	constructor(
		private jobService: FlutaroJobService,
		private notifications: FlutaroNotificationsProvider,
		public http: HttpClient,
	) {}

	createRepeatingJobsFromJob(job: JobWrapper) {
		let bulkImportJobs: JobWrapper[] = [];
		const jobsRepeating = job.job.jobsRepeating;
		let counter;
		let addFunction;

		switch (jobsRepeating.repeatingMode) {
			case JobRepeatingMode.DAILY:
				counter = differenceInDays(jobsRepeating.repeatUntilDay, jobsRepeating.firstDayOfRepeatingJob);
				addFunction = addDays;
				break;
			case JobRepeatingMode.WEEKLY:
				counter = differenceInWeeks(jobsRepeating.repeatUntilDay, jobsRepeating.firstDayOfRepeatingJob);
				addFunction = addWeeks;
				break;
			case JobRepeatingMode.INDIVIDUAL:
				counter = jobsRepeating.repeatingDates.length;
				break;
			default:
				break;
		}
		for (let i = 1; i < counter + 1; i++) {
			let newJob: JobWrapper = cloneDeep(job);
			newJob.job.identifier = `${newJob.job.identifier}_${i}`;
			const newJobsRefDate =
				jobsRepeating.repeatingMode === JobRepeatingMode.INDIVIDUAL
					? jobsRepeating.repeatingDates[i - 1]
					: addFunction(getJobsFirstPickupDate(job), i);
			if (jobsRepeating.repeatingMode !== JobRepeatingMode.INDIVIDUAL) {
				if (newJob.job.jobsRepeating.dailyExceptions.includes(getISODay(newJobsRefDate))) continue;
				if (
					newJob.job.jobsRepeating.weeklyExceptions.some((exceptionDate) =>
						isSameISOWeek(exceptionDate, newJobsRefDate),
					)
				)
					continue;
			}
			newJob = calculateNewJobDatesBasedOnRefDate(newJob, newJobsRefDate, job);
			bulkImportJobs.push(newJob);
		}
		this.jobService.updateBulk(bulkImportJobs);
	}

	/**
	 * Deletes all repeating Jobs by repeatId, which are not already done.
	 * @param {string} repeatId
	 */
	deleteRepeatingJobs(repeatId: string) {
		this.http.get<JobWrapper[]>(environment.routingApiURL + '/data/jobwrappers/repeating/' + repeatId).subscribe(
			(jobs) => {
				let filteredJobs: JobWrapper[] = jobs.filter((job) => job.job.status !== JobStatus.DONE);
				if (filteredJobs.length === 0) {
					let text = 'Löschen fehlgeschlagen: Keine zu löschenden Aufträge vorhanden.';
					this.notifications.showBasicSnackBar(text);
					return;
				}
				filteredJobs.forEach((job) => {
					this.jobService.remove(job);
				});
				let text = filteredJobs.length + ' Aufträge der Serie ' + filteredJobs[0].job.identifier + ' wurden gelöscht.';
				this.notifications.showBasicSnackBar(text);
			},
			(err) => console.log(err),
		);
	}
}

<div fxLayout="row" class="date-range-component" fxLayoutAlign="center center">
	<mat-form-field appearance="fill">
		<mat-label class="daterangelabel">{{ 'DATERANGE_ENTER' | translate }}</mat-label>
		<mat-date-range-input [rangePicker]="picker">
			<input
				class="input_flutaro"
				#startdate="ngModel"
				[(ngModel)]="startDate"
				matStartDate
				placeholder="{{ 'START' | translate }}"
			/>
			<input
				class="input_flutaro"
				#enddate="ngModel"
				[(ngModel)]="endDate"
				matEndDate
				placeholder="{{ 'END' | translate }}"
			/>
		</mat-date-range-input>
		<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
	</mat-form-field>

	<button
		mat-button
		class="mat-primary_flutaro date-range-button"
		(click)="searchDataForDateRange()"
		[disabled]="!startdate.valid || !enddate.valid"
	>
		<mat-icon><i class="material-icons">done</i></mat-icon>
		{{ 'START' | translate }}
	</button>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { APP_COUNTRIES, CountryToISO2 } from '@flutaro/package/lib/model/LocationConstants';

@Component({
	selector: 'app-country-select',
	templateUrl: './country-select.component.html',
	styleUrls: ['./country-select.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectComponent {
	@Input() isMultiple: boolean;
	@Input() country: CountryToISO2;
	@Input() countries: CountryToISO2[];
	@Input() placeholder: string;
	@Output() countriesChange: EventEmitter<CountryToISO2[]> = new EventEmitter<CountryToISO2[] | undefined>();
	@Output() countryChange: EventEmitter<CountryToISO2> = new EventEmitter<CountryToISO2>();

	APP_COUNTRIES = APP_COUNTRIES;

	clearCountries() {
		this.countriesChange.emit(undefined);
	}
}

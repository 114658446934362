import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { format, parse } from 'date-fns';
import { APP_DATE_FORMAT, APP_DATE_TIME_FORMAT } from './AppDateClasses';

@Injectable()
export class FlutaroDateAdapter extends NativeDateAdapter {
	parse(value: string | null): Date | null {
		if (value) {
			value = value.trim();
			return parse(value, value.includes(',') ? APP_DATE_TIME_FORMAT : APP_DATE_FORMAT, new Date());
		}
		return null;
	}

	format(date: Date, displayFormat: any): string {
		return format(date, displayFormat.hour ? APP_DATE_TIME_FORMAT : APP_DATE_FORMAT);
	}

	getFirstDayOfWeek(): number {
		return 1;
	}
}

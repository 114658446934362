<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxFlex="100" style="margin-top: -1px">
	<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
		<mat-select multiple [(ngModel)]="job.job.requirements" placeholder="{{ 'JOB_REQUIREMENTS' | translate }}">
			<mat-option *ngFor="let requirement of ALL_REQUIREMENTS" [value]="requirement">
				{{ requirement | translateRequirement }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
		<mat-select
			[(ngModel)]="job.job.requiredVehicle"
			placeholder="{{ 'VEHICLETYPE_REQUIREMENTS' | translate }}"
			(ngModelChange)="onChangedRequiredVehicle()"
		>
			<mat-option *ngFor="let vehicleType of VehicleTypes" [value]="vehicleType">
				{{ vehicleType | translateVehicleType }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>

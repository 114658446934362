import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-job-regulating-time',
	templateUrl: '../job/components/job.regulating.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogRegulatingtimeComponent {
	@Input() regulating: number;
	@Output() regulatingChange = new EventEmitter<number>();

	constructor() {}
}

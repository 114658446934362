<div
	class="jobSidenavDriverVehicle layout-padding fullHeight"
	[fxLayout]="componentLayout"
	[fxLayoutAlign]="componentLayout === 'column' ? 'center start' : 'start center'"
>
	<div fxLayout="row" fxLayoutAlign="start center" [class.fullWidth]="componentLayout === 'column'">
		<mat-icon class="marginRight10" *ngIf="!hideIcons">
			<i class="material-icons greyColorMediumSize">local_shipping</i>
		</mat-icon>
		<!-- vehicle needs adjustment-->
		<mat-form-field [class.fullWidth]="componentLayout === 'column'">
			<input
				#vehicleSearch
				[(ngModel)]="_vehicleLicensePlate"
				[disabled]="isDisabledSelect"
				[matAutocomplete]="vehicle"
				matInput
				placeholder="{{ 'VEHICLE' | translate }}"
				style="color: #505759"
				type="text"
			/>
			<button
				(click)="clearVehicleInput()"
				[disabled]="isDisabledSelect"
				aria-label="Clear"
				class="appClearButton"
				mat-button
				matSuffix
			>
				<i class="material-icons synonymClearButtonIcon">close</i>
			</button>
			<mat-autocomplete #vehicle="matAutocomplete" (optionSelected)="emitVehicle($event.option.value)">
				<mat-option *ngIf="!vehicleSearch.value && !job.vehicleId" [value]="undefined">
					<span>{{ 'NOT_PLANNED' | translate }}</span>
				</mat-option>
				<mat-option
					*ngFor="
						let vehicle of vehicles
							| filterForActivatedTrucksByDate: companySettings:selectedDate
							| filterElementsBy: vehicleSearch.value
					"
					[disabled]="!(vehicle | isVehicleMatchingJobRequirements: job)"
					[value]="vehicle"
				>
					<mat-icon
						fontSet="material-icons-two-tone"
						class="iconIsVehicleMatchingJobRequirements"
						*ngIf="!(vehicle | isVehicleMatchingJobRequirements: job)"
						[matTooltip]="'VEHICLE_JOB_MISMATCHED_REQUIREMENTS_MESSAGE' | translate"
					>
						no_transfer
					</mat-icon>
					<span>{{ vehicle.toString() }}</span>
				</mat-option>
			</mat-autocomplete>
			<mat-hint class="iconIsVehicleMatchingJobRequirements" *ngIf="vehicleSearch.value && !job.vehicleId">{{
				'LICENSEPLATE_UNKOWN' | translate
			}}</mat-hint>
		</mat-form-field>
	</div>
	<!-- only one of the input (driver OR vehicle) is editable: userSetting default=vehicle / other should be disabled -->
	<div fxLayout="row" *ngIf="!companySettings.isDriverDeactivated && !hideIcons" fxLayoutAlign="start center">
		<mat-icon class="marginRight10">
			<i class="material-icons sidenavIconMoreDriverIcon">person</i>
		</mat-icon>
		<!-- PLACEHOLDER FOR DRIVER -->
		<span class="jobSidenavDriverLabel">{{ job.driver | getDriversNameById: drivers }}</span>
	</div>
</div>

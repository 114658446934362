<div *ngIf="(menuProvider.$currentTab | async) === MENU.MAP_PLANNING" class="vh100">
	<app-map-planning-menu
		(loadJobsChange)="reloadJobs($event)"
		[class.mapPlanningMenuToolbarOpen]="menuProvider.$showTimetableToolbar | async"
		[drivers]="driverService.$data | async"
		[jobs]="jobsProvider.$data | async"
		[reloadedJobs]="$reloadedJobs | async"
		[showToolbar]="menuProvider.$showTimetableToolbar | async"
		[userProfile]="authFb.$userProfile | async"
		[vehicles]="vehicleProvider.$data | async"
		[companySettings]="companyService.$companySettings | async"
	>
	</app-map-planning-menu>
</div>
<div *ngIf="(menuProvider.$currentTab | async) !== MENU.MAP_PLANNING">
	<div
		*ngIf="
			(jobsProvider.$isInReceiveState | async) ||
			(vehicleProvider.$isInReceiveState | async) ||
			(timetableJobProvider.$isInCostCalculationOperation | async)
		"
		class="loadingIndicatorBackground"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="loadingIndicatorCircle"></div>
		<p translate *ngIf="timetableJobProvider.$isInCostCalculationOperation | async" class="loadingIndicatorTextStyle">
			COST_CALCULATION_OPERATION_IN_PROGRESS
		</p>
	</div>
	<div
		*ngIf="!authFb.getUserProfile().hasDisableJobsCreateDeleteRole && !(authFb.getUserProfile() | isReadonly)"
		fxLayoutAlign="end center"
	>
		<app-job-add
			[class.addButtonReduceOpacity]="sidenavProvider.$openSideNav | async"
			[companySettings]="companyService.$companySettings | async"
			[drivers]="driverService.$data | async"
			[userProfile]="authFb.$userProfile | async"
			[userSettings]="userSettings.$userSettings | async"
			[vehicles]="vehicleProvider.$data | async"
		>
		</app-job-add>
	</div>
	<div class="mainWrapperTimetableTableData">
		<app-timetable-toolbar
			(noteModeChange)="changeNoteMode($event)"
			(planningModeChange)="changePlanningMode($event)"
			(weekDayChange)="onWeekDayChange($event)"
			*ngIf="menuProvider.$showTimetableToolbar | async"
			[dayOfWeek]="timetableProvider.$selectedDayOfWeek | async"
			[vehicles]="vehicleProvider.$data | async"
			[driverStatistics]="driverStatistics"
			[drivers]="driverService.$data | async"
			[events]="eventService.$events | async"
			[jobs]="jobsProvider.$data | async"
			[noDriverJobs]="timetableFilterService.$referenceDateUnassignedJobs | async"
			[relations]="relationService.$data | async"
			[showPlanningMode]="showPlanningMode"
			[timetableData]="timetableFilterService.$filteredDriverJobsMap | async"
			[timetable]="timetableProvider.$timetable | async"
			[userProfile]="authFb.$userProfile | async"
			[userSettings]="userSettings.$userSettings | async"
			[companySettings]="companyService.$companySettings | async"
		>
		</app-timetable-toolbar>
		<div
			[style.height]="(menuProvider.$showTimetableToolbar | async) ? 'calc(100vh - 64px - 64px)' : 'calc(100vh - 64px)'"
			fxLayout="row"
		>
			<app-timetable
				(costPreviewRequest)="handleCostPreviewRequest($event)"
				(dragChange)="processJobDrag($event)"
				(planningModeExcludeChange)="filterStore.removeVehicleFromPlanningView($event)"
				(vehicleStatusStoreChange)="vehicleProvider.storeStatus($event)"
				[costPreview]="timetableService.$costPreview | async"
				[customJobDisplay]="(userSettings.$userSettings | async).planningSettings?.customJobDisplay"
				[timetableData]="timetableFilterService.$filteredDriverJobsMap | async"
				[drivers]="driverService.$data | async"
				[vehicles]="vehicleProvider.$data | async"
				[contractors]="contractorProvider.$data | async"
				[events]="eventService.$events | async"
				[filterStore]="filterStore.activeFilters | async"
				[jobs]="jobsProvider.$data | async"
				[locations]="locationProvider.$data | async"
				[noteMode]="$showNoteMode | async"
				[planningMode]="showPlanningMode"
				[scrollLeftSubject]="timetableProvider.$scrollLeft"
				[style.height]="
					(menuProvider.$showTimetableToolbar | async) ? 'calc(100vh - 64px - 64px)' : 'calc(100vh - 64px)'
				"
				[timetable]="timetableProvider.$timetable | async"
				[openSideNav]="sidenavProvider.$openSideNav | async"
				[userProfile]="authFb.$userProfile | async"
				[userSettings]="userSettings.$userSettings | async"
				[companySettings]="companyService.$companySettings | async"
			>
			</app-timetable>
			<app-job-sidenav
				*ngIf="(sidenavProvider.$openSideNav | async) === 'JOB'"
				[drivers]="driverService.$data | async"
				[job]="sidenavProvider.sideNavData.job"
				[jobIsEdited]="sidenavProvider.sideNavData.jobIsEdited"
				[timetable]="timetableProvider.$timetable | async"
				[userProfile]="authFb.$userProfile | async"
				[vehicles]="vehicleProvider.$data | async"
				[isOpenTimetableToolbar]="menuProvider.$showTimetableToolbar | async"
				[companySettings]="companyService.$companySettings | async"
			>
			</app-job-sidenav>
			<app-vehicle-sidenav
				*ngIf="(sidenavProvider.$openSideNav | async) === 'VEHICLE'"
				[currentTab]="menuProvider.$currentTab | async"
				[events]="eventService.$events | async"
				[jobs]="jobsProvider.$data | async"
				[readonly]="authFb.getUserProfile().hasReadOnlyRole"
				[timetable]="timetableProvider.$timetable | async"
				[userProfile]="authFb.$userProfile | async"
				[companySettings]="companyService.$companySettings | async"
				[vehicle]="sidenavProvider.sideNavData.vehicle"
				[drivers]="driverService.$data | async"
				[vehicles]="vehicleProvider.$data | async"
				[isOpenTimetableToolbar]="menuProvider.$showTimetableToolbar | async"
			>
			</app-vehicle-sidenav>
			<app-sidenav-statistics
				(closeChange)="closeCurrentSidenav()"
				*ngIf="(sidenavProvider.$openSideNav | async) === 'INFO'"
				[loading]="jobsProvider.$isInReceiveState | async"
				[companySettings]="companyService.$companySettings | async"
				[timetableData]="timetableFilterService.$filteredDriverJobsMap | async"
				[timetableJobs]="timetableFilterService.$currentTimetableJobsFiltered | async"
				[timetable]="timetableProvider.$timetable | async"
			>
			</app-sidenav-statistics>
			<app-newjobs-sidenav
				*ngIf="(sidenavProvider.$openSideNav | async) === 'NEW_JOBS' && !(authFb.getUserProfile() | isReadonly)"
				(closeChange)="closeCurrentSidenav()"
				(dragChange)="processJobDrag($event)"
				[isOpenTimetableToolbar]="menuProvider.$showTimetableToolbar | async"
				[locations]="locationProvider.$data | async"
				[referenceDateFilteredJobs]="timetableFilterService.$referenceDateUnassignedJobs | async"
				[timetable]="timetableProvider.$timetable | async"
				[userProfile]="authFb.$userProfile | async"
				[userSettings]="userSettings.$userSettings | async"
			>
			</app-newjobs-sidenav>
			<app-chat-sidenav
				*ngIf="(sidenavProvider.$openSideNav | async) === 'CHAT' && !(authFb.getUserProfile() | isReadonly)"
				[userProfile]="authFb.$userProfile | async"
				[isOpenTimetableToolbar]="menuProvider.$showTimetableToolbar | async"
			>
			</app-chat-sidenav>
		</div>
	</div>
</div>

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { JobRepeating, JobRepeatingMode } from '@flutaro/package/lib/model/Job';
import { onlyMondayDateFilter } from '@flutaro/package/lib/functions/DataDateFunctions';

@Component({
	selector: 'app-job-repeating',
	templateUrl: './job.repeating.component.html',
	styles: [
		'.repeatingIndividualExceptionsDatePicker {width: 205px;} .repeatingIndividualExceptionsDisplay {min-width: 208px;position: relative;top: -14px;}',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobRepeatingComponent implements OnChanges {
	@Input() minDate: Date;
	@Input() jobsRepeating: JobRepeating;
	@Input() disabled: boolean;
	@Output() jobsRepeatingChange = new EventEmitter<JobRepeating>();
	weeklyExceptions: number[] = [];
	onlyMondayDateFilter = onlyMondayDateFilter;
	dailyExceptions = [
		{ name: 'Montag', value: 1 },
		{ name: 'Dienstag', value: 2 },
		{ name: 'Mittwoch', value: 3 },
		{ name: 'Donnerstag', value: 4 },
		{ name: 'Freitag', value: 5 },
		{ name: 'Samstag', value: 6 },
		{ name: 'Sonntag', value: 7 },
	];
	repeatingMode = JobRepeatingMode;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['jobsRepeating']) {
			if (this.jobsRepeating && !this.jobsRepeating.firstDayOfRepeatingJob) {
				this.jobsRepeating.firstDayOfRepeatingJob = new Date();
			}
		}
	}

	removeWeeklyException(date: Date): void {
		this.jobsRepeating.weeklyExceptions = this.jobsRepeating.weeklyExceptions.filter(
			(weeklyException) => weeklyException.getTime() !== date.getTime(),
		);
		this.jobsRepeatingChange.emit(this.jobsRepeating);
	}

	removeRepeatingDate(date: Date): void {
		this.jobsRepeating.repeatingDates = this.jobsRepeating.repeatingDates.filter(
			(repeatingDate) => repeatingDate.getTime() !== date.getTime(),
		);
		this.jobsRepeatingChange.emit(this.jobsRepeating);
	}

	addWeeklyException(exceptionDate: Date) {
		console.debug(`addWeeklyException, adding date ${exceptionDate}`);
		this.jobsRepeating.weeklyExceptions.push(exceptionDate);
		this.jobsRepeatingChange.emit(this.jobsRepeating);
	}

	addRepeatingDate(repeatingDate: Date) {
		this.jobsRepeating.repeatingDates.push(repeatingDate);
		this.jobsRepeatingChange.emit(this.jobsRepeating);
	}

	emitDateChange() {
		this.jobsRepeatingChange.emit(this.jobsRepeating);
	}
}

<mat-form-field class="paddingLeft10 paddingRight5 fullWidth">
	<textarea
		(ngModelChange)="checkNoteChange($event)"
		[(ngModel)]="note"
		[cdkAutosizeMaxRows]="2"
		[cdkAutosizeMinRows]="2"
		[cdkTextareaAutosize]="true"
		matInput
	>
	</textarea>
	<mat-hint *ngIf="noteLatestEditDate">
		{{ noteLatestEditDate | relativeTime }}
	</mat-hint>
</mat-form-field>
<button
	(click)="emitNoteChange()"
	*ngIf="noteIsChanged"
	[matTooltipPosition]="'below'"
	aria-label="Clear"
	class="noteButton"
	mat-button
	mat-icon-button
	matSuffix
	matTooltip="{{ 'SAVE' | translate }}"
>
	<mat-icon>check</mat-icon>
</button>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { TableModule } from '../table/table.module';
import { DialogModule } from '../dialog/dialog.module';
import { ContractorDialogComponent } from './contractor.dialog.component';
import { ContractorTableComponent } from './contractor.table.component';
import { ContractorAddComponent } from './contractor.add.component';
import { ContractorRateComponent } from './contractor-rate/contractor-rate.component';
import { FlutaroDatetimepickerModule } from '../flutaro-datepicker/flutaro-datepicker.module';

@NgModule({
	declarations: [ContractorDialogComponent, ContractorTableComponent, ContractorAddComponent, ContractorRateComponent],
	exports: [ContractorTableComponent],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		TableModule,
		DialogModule,
		FlutaroDatetimepickerModule,
	],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class ContractorModule {}

import { TableModule } from '../table/table.module';
import { LocationTableComponent } from './location.table.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AddressSearchComponent } from './synonym.addressSearch.component';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../dialog/dialog.module';
import { LocationAddComponent } from './location.add.component';
import { LocationDialogComponent } from './location.dialog.component';
import { CorrectionModule } from '../interfaces/ExternalJob/correction/correction.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

/**
 * Angular 2 Synonym-Module
 */
@NgModule({
	declarations: [AddressSearchComponent, LocationTableComponent, LocationAddComponent, LocationDialogComponent],
	exports: [AddressSearchComponent, LocationTableComponent],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		TableModule,
		DialogModule,
		CorrectionModule,
		MatAutocompleteModule,
		MatAutocompleteModule,
		MatAutocompleteModule,
	],
})
export class SynonymModule {}

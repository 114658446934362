<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
	<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
		<mat-select
			[(ngModel)]="weekdayTimeObject.weekDay"
			(ngModelChange)="weekdayTimeObjectChange.emit(weekdayTimeObject)"
			placeholder="{{ 'WEEKDAY' | translate }}"
		>
			<mat-option *ngFor="let day of days" [value]="day.value">
				{{ day.label }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<app-datetimepicker
		id="weekday-time-datepicker"
		[(ngModel)]="weekDayDate"
		(ngModelChange)="setWeekdayTimeFromDate(weekDayDate)"
		[type]="'time'"
	>
	</app-datetimepicker>
</div>

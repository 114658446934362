import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressSearchProvider } from '../integrations/address/integration.address.service';
import { BehaviorSubject } from 'rxjs';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { validateFormFields } from '../form/FlutaroFormFunctions';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CrossFieldErrorMatcher } from './MaterialFormFunctions';
import { AddressFormValidator } from '../form/form.validators';
import QueryAutocompletePrediction = google.maps.places.QueryAutocompletePrediction;
import { isAddressSearchInAddressLine } from '@flutaro/package/lib/functions/LocationFunctions';

@Component({
	selector: 'app-address-search',
	templateUrl: './synonym.addressSearch.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent implements OnInit, OnChanges {
	@Input() synonym: Synonym = new Synonym();
	@Input() required: boolean = false;
	@Input() hint: string; // displays a mat-hint with the hint text
	@Output() synonymChange = new EventEmitter<Synonym>();
	@Output() synonymCleared = new EventEmitter<boolean>();
	@Output() $isInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() placeholder: string = this.translate.instant('ADDRESS');
	@Input() hidePlaceholder: boolean;
	// In case of Synonym-Dialog dont show Locations and Codes as Addresses
	@Input() enableLocations: boolean = true;
	@Input() isExternalJob: boolean;
	@Input() triggerValidation: boolean;

	localAddresses: Synonym[] = [];
	$filteredAddresses: BehaviorSubject<Synonym[]> = new BehaviorSubject<Synonym[]>([]);
	addressSearchForm: FormGroup;
	errorMatcher = new CrossFieldErrorMatcher();

	constructor(
		private addressService: AddressSearchProvider,
		private synonymService: FlutaroSynonymService,
		private fb: FormBuilder,
		private translate: TranslateService,
	) {
		this.initializeFormAndListeners();
	}

	ngOnInit() {
		this.localAddresses = this.synonymService.getData();
		if (this.enableLocations) this.$filteredAddresses.next(this.localAddresses);
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes['synonym']) {
			console.debug(`ngOnChanges, new synonym`);
			if (!this.synonym) this.synonym = new Synonym();
			this.patchFormForSynonymChange();
		}
		if (changes['showLocations']) {
			this.initFilteredAddresses();
		}
		if (changes['isExternalJob']) {
			await this.searchForExactAddressString();
			validateFormFields(this.addressSearchForm);
		}
		if (changes['required'] || changes['triggerValidation']) {
			this.updateAddressSearchInputValidator();
			validateFormFields(this.addressSearchForm);
		}
		this.emitIsInvalid();
	}

	clearLocation() {
		this.synonym = new Synonym();
		this.patchFormForSynonymChange();
		this.initFilteredAddresses();
		this.synonymCleared.emit(true);
		this.synonymChange.emit(this.synonym);
	}

	/**
	 * Resets addressSearchs input field to current synonym.
	 * Doesnt reset in case user selected an option from the autocomplete-list
	 * @param $event
	 */
	resetInputOnBlur($event) {
		if (
			!$event.relatedTarget?.id ||
			typeof $event.relatedTarget?.id !== 'string' ||
			!$event.relatedTarget.id.startsWith('mat-option')
		) {
			console.debug(`resetInputOnBlur for address ${this.synonym.toString()}, at ${new Date().toISOString()}`);
			validateFormFields(this.addressSearchForm);
			this.patchFormForSynonymChange();
		}
	}

	async searchForAddress(addressSearch: string) {
		console.debug(`searchForAddress, starting for address ${addressSearch}`);
		if (this.enableLocations) {
			const localeFiltered = this.localAddresses.filter((address) =>
				isAddressSearchInAddressLine(addressSearch, address.toString()),
			);
			if (localeFiltered.length) {
				this.$filteredAddresses.next(localeFiltered);
				return true;
			}
		}

		if (addressSearch.length < 5) {
			return false;
		}

		const [error, result] = await to(this.addressService.autocompleteAddressSearch(addressSearch));
		if (error) {
			this.$filteredAddresses.next([]);
			return false;
		}
		const synonymsFromResult = this.createSynonymsFromResult(result);
		this.$filteredAddresses.next(synonymsFromResult);
		console.debug(
			`filter for address ${addressSearch}, createSynonymsFromResult finished at ${new Date().toISOString()}`,
		);
		return true;
	}

	async emitLocationAsSynonym(synonym: Synonym) {
		console.debug(`emitLocationAsSynonym for address ${synonym.toString()}`);
		if (!synonym.latitude && synonym.addressLine?.length > 4) {
			console.log(`emitLocationAsSynonym, no lat/lon - requiring geocodeAddress`);
			synonym = await this.addressService.geocodeAddressAsSynonym(synonym.addressLine);
			if (!synonym) {
				return;
			}
		}
		this.synonymChange.emit(synonym);
	}

	protected createSynonymsFromResult(result: QueryAutocompletePrediction[]): Synonym[] {
		return result.map((prediction) => new Synonym(null, null, prediction.description));
	}

	private initFilteredAddresses() {
		this.$filteredAddresses.next(this.enableLocations ? this.localAddresses : []);
	}

	updateAddressSearchInputValidator() {
		let addressSearchValidators = [];
		if (this.required) addressSearchValidators = [Validators.required, Validators.minLength(5)];
		const adS = this.addressSearchForm.controls.addressSearch;
		adS.setValidators(addressSearchValidators);
		adS.updateValueAndValidity();
	}

	private initializeFormAndListeners() {
		this.addressSearchForm = this.fb.group({
			addressSearch: [this.synonym.toString()],
			address: [this.synonym, [AddressFormValidator.validateAddress]],
		});
		this.updateAddressSearchInputValidator();
		this.addressSearchForm.controls['addressSearch'].valueChanges
			.pipe(debounceTime(500), distinctUntilChanged())
			.subscribe((search) => {
				if (typeof search !== 'string') {
					console.debug(`addressSearch, address selected - aborting filter`);
					return;
				}
				this.searchForAddress(search);
				this.emitIsInvalid();
			});
	}

	private patchFormForSynonymChange() {
		this.addressSearchForm.patchValue(
			{
				addressSearch: this.synonym.toString(),
				address: this.synonym,
			},
			{ emitEvent: false }, // Attention: this is known, open angular bug (Aug2021, check: https://github.com/angular/angular/issues/20230) and doesnt work. We skip on lat/lon in filter-function
		);
		this.emitIsInvalid();
	}

	private emitIsInvalid() {
		console.log(`emitIsInvalid, emitting ${this.addressSearchForm.invalid}`);
		this.$isInvalid.emit(this.addressSearchForm.invalid);
	}

	private async searchForSynonymAddressWithoutLatLon() {
		if (!this.synonym.toString() || this.synonym.latitude) return;
		return this.searchForAddress(this.synonym.toString());
	}

	private async searchForExactAddressString() {
		console.log(`searchForExactAddressString, called for ${this.synonym.toString()}`);
		if (!this.synonym.toString() || this.synonym.latitude) return;
		let tempSynonym: Synonym = await this.addressService.geocodeAddressAsSynonym(this.synonym.toString());
		if (!tempSynonym) {
			console.debug(
				`searchForExactAddressString, no synonym returned for ${this.synonym.toString()}. Generating Auto-Complete list`,
			);
			this.searchForSynonymAddressWithoutLatLon();
			return;
		}
		return this.emitLocationAsSynonym(tempSynonym);
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountrySelectComponent } from './country-select/country-select.component';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { FormsModule } from '@angular/forms';
import { LocationCountryISO3Pipe } from './location.pipes';

@NgModule({
	declarations: [CountrySelectComponent, LocationCountryISO3Pipe],
	imports: [CommonModule, FlutaroCustomMaterialModule, FormsModule],
	exports: [CountrySelectComponent, LocationCountryISO3Pipe],
})
export class LocationModule {}

<div *ngIf="!userProfile.hasDisableAllCreateDeleteRole && !userProfile.hasReadOnlyRole" fxLayoutAlign="end center">
	<app-location-add [companySettings]="companySettings" [userProfile]="userProfile" [userSettings]="userSettings">
	</app-location-add>
</div>

<div class="table-container mat-elevation-z8">
	<div *ngIf="!data?.length" class="addNewObjectBackground" fxLayout="column" fxLayoutAlign="center center">
		<div class="addNewLocationImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'LOCATIONS' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'LOCATION_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_LOCATION' | translate }}
			</p>
		</div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_LOCATIONS' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="code">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">pin_drop</i>
				Name
			</th>
			<td *matCellDef="let synonym" mat-cell>{{ synonym.code }}</td>
		</ng-container>

		<ng-container matColumnDef="addressLine">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">home</i>
				{{ 'STREET' | translate }}
			</th>
			<td *matCellDef="let synonym" mat-cell>{{ synonym.addressLine }}</td>
		</ng-container>

		<ng-container matColumnDef="zip">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">home</i>
				{{ 'ZIP' | translate }}
			</th>
			<td *matCellDef="let synonym" mat-cell>{{ synonym.zip }}</td>
		</ng-container>

		<ng-container matColumnDef="city">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">home</i>
				{{ 'CITY' | translate }}
			</th>
			<td *matCellDef="let synonym" mat-cell>{{ synonym.city }}</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let synonym"
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(synonym, synonym.code)"
					[disabled]="userProfile.hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let element" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(element)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons></mat-paginator>
</div>

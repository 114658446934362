import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../FlutaroService';
import { Driver, RegularWorkweek } from '@flutaro/package/lib/model/Driver';
import { parseDriver } from '@flutaro/package/lib/functions/driver/DriverDataFunctions';
import { areStringsEqualIgnoringCaseAndSpaces } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { environment } from '../../../environments/environment';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { getDriverForVehicleByScheduleDate } from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { isAfter, isDate } from 'date-fns';
import { migrate_SetDefaultCreatedAtAttribute } from '@flutaro/package/lib/functions/AppDataMigrations';

@Injectable({
	providedIn: 'root',
})
export class FlutaroDriverService extends FlutaroDataService<Driver> {
	initProvider() {
		this.url = environment.routingApiURL + '/data/drivers';
		this.collectionName = FlutaroCollection.DRIVER;
		this.websocketService.driverSocket.subscribe((driverData) => this.handleWebSocketAction(driverData));
		this.websocketService.$websocketRecoveredFromFailingState.subscribe((isReconnected) => {
			if (!isReconnected) return;
			this.getDataFromServer();
		});
		this.authFb.$driverIdsFilter.subscribe(($driverIdsFilter) => {
			if (!$driverIdsFilter) return;
			this.$data.next(this.filterDataServiceSpecific(this.$data.getValue()));
		});
	}

	public async getDataFromServer() {
		const companySettings = await this.companyService.getCompanySettingsAsync();
		if (companySettings.isDriverDeactivated) {
			console.log(`FlutaroDriverService, getDataFromServer aborted - companySettings.isDriverDeactivated true `);
			return [];
		}
		return super.getDataFromServer();
	}

	protected filterDataServiceSpecific(data: Driver[]): Driver[] {
		const readOnlyDriverIdsFilter = this.authFb.$driverIdsFilter.getValue();
		if (!readOnlyDriverIdsFilter) return data;
		return data.filter((driver) => readOnlyDriverIdsFilter.includes(driver.backendId));
	}

	getData(): Driver[] {
		return this.$data.getValue();
	}

	store(element: Driver): Promise<Driver> {
		// TODO: delete me?
		if (element.appointments && element.appointments.length > 0) {
			element.appointments.forEach((appointment) => {
				if (!appointment.location.city) delete appointment.location.city;
			});
		}
		return super.store(element);
	}

	getVehiclesDriverByDate(vehicle: Vehicle, refDate: Date): Driver | undefined {
		return getDriverForVehicleByScheduleDate(vehicle, this.getData(), refDate);
	}

	getDriverByEmail(email: string): Driver | undefined {
		if (!email?.length || typeof email !== 'string') return undefined;
		return this.getData().find((driver) => areStringsEqualIgnoringCaseAndSpaces(driver.email, email));
	}

	protected receive(data: Driver[]): void {
		super.receive(data);
	}

	protected parseElement(driverObject: Driver): Driver {
		if (migrate_SetDefaultCreatedAtAttribute(driverObject)) console.info(`Driver-Migration in previous log`);
		driverObject = parseDriver(driverObject);
		if (!driverObject.regularWorkweek && !driverObject.alternatingWorkWeek) {
			console.error(`parseDriver, MIGRATION-ERROR in regularWorkweekDriver for driver ${driverObject.backendId} `);
			driverObject.regularWorkweekDriver = true;
			driverObject.regularWorkweek = new RegularWorkweek();
		}
		driverObject.appointments = driverObject.appointments.filter(
			(appointment) => !!appointment.startDate && !!appointment.endDate,
		);
		driverObject.appointments = driverObject.appointments.filter(
			(appointment) => !appointment.endDate || isAfter(appointment.endDate, appointment.startDate),
		);
		// Block Temp Fix
		if (
			driverObject.block &&
			(!isDate(driverObject.block.blockStartDate) ||
				isAfter(driverObject.block.blockStartDate, driverObject.block.blockEndDate))
		) {
			console.error(
				`parseDriver, MIGRATION-Error in Block for driver ${driverObject.backendId} - invalid interval (${driverObject.block.blockStartDate} - ${driverObject.block.blockEndDate}. Deleting block from Driver temporarily`,
			);
			driverObject.block = undefined;
		}
		return driverObject;
	}
}

import { VEHICLE_EQUIPMENT } from '@flutaro/package/lib/model/FlutaroRequirements';

export function translateRequirement(requirement: string): string {
	return translateVehicleEquipment(requirement);
}

export function translateVehicleEquipment(equipment: string): string {
	switch (equipment) {
		case VEHICLE_EQUIPMENT.STANDARD:
			return 'Standard';
		case VEHICLE_EQUIPMENT.ADR_SMALL:
			return 'ADR klein';
		case VEHICLE_EQUIPMENT.ADR_BIG:
			return 'ADR groß';
		case VEHICLE_EQUIPMENT.WASTE_TRANSPORT:
			return 'Abfalltransport';
		case VEHICLE_EQUIPMENT.FORK_LIFT:
			return 'Ladehilfe - Stapler';
		case VEHICLE_EQUIPMENT.RAMP:
			return 'Ladehilfe - Auffahrrampe';
		case VEHICLE_EQUIPMENT.LIFTING_PLATFORM:
			return 'Ladehilfe - Hebebühne';
		default:
			return null;
	}
}

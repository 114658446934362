import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';
import { REQUIREMENTS_ALL } from '@flutaro/package/lib/model/FlutaroRequirements';

@Component({
	selector: 'jobRequirements',
	templateUrl: './job.requirements.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobRequirementsComponent {
	@Input() job: JobWrapper;
	@Output() jobChange = new EventEmitter<JobWrapper>();
	VehicleTypes: VEHICLE_TYPE[] = Object.values(VEHICLE_TYPE);
	ALL_REQUIREMENTS = REQUIREMENTS_ALL;

	constructor() {}

	onChangedRequiredVehicle() {}
}

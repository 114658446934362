<mat-form-field
	[class.dialogDatePicker]="!hideDateInput"
	[class.date-picker-date-only]="type === 'date' && !hideDateInput && !allowDynamicWidth"
>
	<mat-datetimepicker-toggle [for]="flutaroDatetimepicker" matSuffix></mat-datetimepicker-toggle>
	<mat-datetimepicker #flutaroDatetimepicker [type]="type"></mat-datetimepicker>
	<input
		(dateChange)="onChange($event.target.value)"
		[formControl]="flutaroDatetimepickerControl"
		[matDatepickerFilter]="flutaroDatetimepickerFilter"
		[matDatetimepicker]="flutaroDatetimepicker"
		[max]="max"
		[min]="min"
		[placeholder]="placeholder"
		[required]="required"
		matInput
		matTooltip="{{ 'ACCEPTED_DATEFORMAT_TT' | translate }}: dd.MM.yyyy, HH:MM"
	/>
	<mat-error>
		<mat-error *ngIf="flutaroDatetimepickerControl.invalid" class="error-message">
			<div *ngFor="let error of listOfErrors(flutaroDatetimepickerControl)">
				{{ error }}
			</div>
		</mat-error>
	</mat-error>
</mat-form-field>

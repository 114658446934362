import { NgModule } from '@angular/core';
import {
	CalcualteAmountOfAllMinusPositionCorrectionsPipe,
	FilterVehicleTypesForType,
	FilterVehiclesForTrailersPipe,
	GetElementByIdPipe,
	TranslateDriverQualificationPipe,
	TranslateRequirementsPipe,
	TranslateRoutingProfile,
	TranslateVehicleTypePipe,
} from './app.pipes';
import { FlutaroPipesModule } from 'flutaro-lib-angular/lib/pipes/flutaro.pipes.module';

@NgModule({
	imports: [FlutaroPipesModule],
	declarations: [
		TranslateVehicleTypePipe,
		TranslateRequirementsPipe,
		TranslateDriverQualificationPipe,
		CalcualteAmountOfAllMinusPositionCorrectionsPipe,
		TranslateRoutingProfile,
		FilterVehicleTypesForType,
		FilterVehiclesForTrailersPipe,
		GetElementByIdPipe,
	],
	exports: [
		TranslateVehicleTypePipe,
		TranslateRequirementsPipe,
		TranslateDriverQualificationPipe,
		CalcualteAmountOfAllMinusPositionCorrectionsPipe,
		TranslateRoutingProfile,
		FlutaroPipesModule,
		FilterVehicleTypesForType,
		FilterVehiclesForTrailersPipe,
		GetElementByIdPipe,
	],
})
export class PipesModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractorDialogComponent } from './contractor.dialog.component';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { TableAddComponent } from '../table/table.add.component';
import { ContractorDialogData } from 'src/app/dialog/AppDialog';

@Component({
	selector: 'app-contractor-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorAddComponent extends TableAddComponent {
	@Input() vehicles: Vehicle[];
	@Input() drivers: Driver[];
	dialogComponent = ContractorDialogComponent;

	createDialogData(): ContractorDialogData {
		return new ContractorDialogData(
			this.userSettings,
			this.userProfile,
			this.companySettings,
			new Contractor(),
			this.vehicles,
			this.drivers,
		);
	}
}

export class JobStatisticsSummary {
	emptyDistance: number = 0; // in Meters
	emptyDriveCosts: number = 0; // in Cents
	loadedDistance: number = 0; // in Meters
	loadedDriveCosts: number = 0; // in Cents
	totalRevenue: number = 0; // in Cents
	totalCosts: number = 0; // in Cents
	totalProfit: number = 0;
}

export class JobSummary {
	unplanned: number = 0;
	planned: number = 0;
	invalidPlanned: number = 0;
	validPlanned: number = 0;
	sent: number = 0;
	received: number = 0;
	done: number = 0;
	total: number = 0; // Total number of Jobs from Event based JobStatisticsSummary
	withoutCosts: number = 0;
	started: number = 0;
	accepted: number = 0;
	declined: number = 0;
	aborted: number = 0;
}

export class DriverStatistics {
	id: string;
	dailyDrivingTime: number = 0; // in Minutes
	weekDrivingTime: number = 0; // in Minutes

	constructor(id?: string) {
		if (id) this.id = id;
	}
}

<div *ngIf="!userProfile.hasDisableAllCreateDeleteRole && !userProfile.hasReadOnlyRole" fxLayoutAlign="end center">
	<app-vehicle-add
		[companySettings]="companySettings"
		[drivers]="driverProvider.$data | async"
		[userProfile]="userProfile"
		[userSettings]="userSettings"
		[vehicles]="data"
	>
	</app-vehicle-add>
</div>

<div class="table-container mat-elevation-z8">
	<div *ngIf="!data?.length" class="addNewObjectBackground" fxLayout="column" fxLayoutAlign="center center">
		<div class="addNewVehicleImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'VEHICLES' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'VEHICLE_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_VEHICLE' | translate }}
			</p>
		</div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_VEHICLES' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">local_shipping</i>
				Name
			</th>
			<td *matCellDef="let element" mat-cell>
				<i
					[ngClass]="
						(element | isVehicleActivatedNow: data:companySettings)
							? 'dataTableActiveObject'
							: 'dataTableInactiveObject'
					"
					[matTooltipPosition]="'right'"
					matTooltip="{{
						((element | isVehicleActivatedNow: data:companySettings)
							? 'VEHICLE_TABLE_VEHICLE_ACTIVE_TT'
							: 'VEHICLE_TABLE_VEHICLE_INACTIVE_TT'
						) | translate
					}}"
				></i>
				{{ element.name }}
			</td>
		</ng-container>

		<ng-container matColumnDef="driver" *ngIf="!companySettings.isDriverDeactivated">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">person</i>
				{{ 'DRIVER' | translate }}
			</th>
			<td *matCellDef="let vehicle" mat-cell>{{ vehicle | getVehiclesDriverNameNow: drivers }}</td>
		</ng-container>

		<ng-container matColumnDef="type">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">assignment</i>
				{{ 'VEHICLE_BASIC_TYPE' | translate }}
			</th>
			<!-- TODO @Gerrit: can we use Icons (Car, Truck, Trailer) instead of text? :) -->
			<td *matCellDef="let element" mat-cell>{{ element.type | translate }}</td>
		</ng-container>

		<ng-container matColumnDef="carType">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">assignment</i>
				{{ 'VEHICLE_TYPE' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>{{ element.carType | translateVehicleType }}</td>
		</ng-container>

		<ng-container matColumnDef="licensePlate">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">label</i>
				{{ 'LICENSEPLATE' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>{{ element.licensePlate }}</td>
		</ng-container>

		<ng-container matColumnDef="specialAttributes">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">assignment_turned_in</i>
				{{ 'VEHICLE_EQUIPMENT' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>
				{{ element.specialAttributes.toString() }}
			</td>
		</ng-container>

		<ng-container matColumnDef="carmanufacturer">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">business</i>
				{{ 'VEHICLE_MANUFACTURER' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>
				{{ element.carmanufacturer }}
			</td>
		</ng-container>

		<ng-container matColumnDef="loadUnitCapacity">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">opacity</i>
				{{ 'LOADING_CAPACITY' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>{{ element.loadUnitCapacity }}</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let element"
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(element)"
					[disabled]="userProfile.hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let element" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(element)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons></mat-paginator>
</div>

<div *ngIf="!userProfile.hasReadOnlyRole" class="mainWrapperDashboard">
	<div class="dashboardInnerWrapperWidth" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
		<div class="mat-whiteframe-4dp dashboardWidget" fxFlex="100" fxFlex.gt-sm="30">
			<div class="row">
				<label class="dashboardUserName">{{ 'DASHBOARD_HI' | translate }} {{ userProfile.displayName }},</label>
			</div>
			<div class="row">
				<p class="dashboardWelcomeMessage">
					{{ 'DASHBOARD_OVERVIEW_TODAY' | translate }} {{ today | appFormatDate: 'weekday-date' }}
				</p>
			</div>
		</div>
		<news-widget
			[author]="userProfile.displayName"
			[editorMode]="userProfile.hasEditNewsRole"
			[news]="news"
			[usersCompany]="userProfile.company"
			fxFlex="100"
			fxFlex.gt-sm="70"
		>
		</news-widget>
	</div>

	<div class="dashboardInnerWrapperWidth" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
		<div class="mat-whiteframe-4dp dashboardWidget" fxFlex="100" fxFlex.gt-sm="30">
			<div *ngIf="loading" class="loadingIndicatorBackground">
				<div class="loadingIndicatorCircle"></div>
			</div>

			<div *ngIf="externalJobsProvider.$externalJobsCount | async" class="dashboardRowImportJobs" fxLayout="column">
				<div fxLayout="row" fxLayoutAlign="start center">
					<i class="material-icons dashboardIconWarning">cancel</i>
					<div class="dashboardDescriptionBackground" fxLayout="row" fxLayoutAlign="space-between center">
						<p class="dashboardDescriptionValue">{{ 'EXTERNAL_JOBS_CORRECTION' | translate }}</p>
						<button
							(click)="interfaceProvider.showExternalJobsDialog()"
							[matTooltipPosition]="'below'"
							class="mat-small goToExternalJobsImportButton"
							mat-button
							matTooltip="{{ 'EXTERNAL_JOBS_COTRRECTION_TT' | translate }}"
						>
							<i class="material-icons dashboard-go-to-import-icon">chevron_right</i>
						</button>
					</div>
					<div class="dashboardValueBackground dashboardValueWarningJobs">
						<p class="dashboardValue">{{ externalJobsProvider.$externalJobsCount | async }}</p>
					</div>
				</div>
				<div fxLayout="row">
					<p class="dividerDashboardDotted100"></p>
				</div>
			</div>

			<div [matTooltipPosition]="'above'" matTooltip="{{ 'JOBS_IN_SELECTED_TIMEFRAME_TT' | translate }}">
				<i class="material-icons dashboardIconBackground dashboardJobIconBackground"></i>
				<i class="material-icons dashboardIconHead">description</i>
				<div class="row dashboardHeadlineRow">
					<label class="dashboardHeadline">
						{{ jobSummary.total }}
						<span>{{ (jobSummary.total === 1 ? 'JOB' : 'JOBS') | translate }}</span>
					</label>
					<span *ngIf="activeStatistic === dashboardMode.TODAY" class="dashboardHeadlineTime"
						>({{ 'TODAY' | translate }} )</span
					>
					<span *ngIf="activeStatistic === dashboardMode.TOMORROW" class="dashboardHeadlineTime"
						>({{ 'TOMORROW' | translate }})</span
					>
					<span *ngIf="activeStatistic === dashboardMode.WEEK" class="dashboardHeadlineTime"
						>({{ 'WEEK' | translate }} {{ today | appFormatDate: 'week-of-year' }})</span
					>
					<span *ngIf="activeStatistic === dashboardMode.INDIVIDUAL" class="dashboardHeadlineTime"
						>({{ 'EXTERNAL_JOBS_CORRECTION' | translate }})</span
					>
				</div>
				<div fxLayout="column" fxLayoutGap="10px">
					<div fxLayout="row">
						<div class="dashboardJobIcon jobNewIcon"></div>
						<div class="dashboardDescriptionBackground">
							<p class="dashboardDescriptionValue">{{ 'JOB_STATUS_NEW' | translate }}</p>
						</div>
						<div class="dashboardValueBackground dashboardValueRed">
							<p class="dashboardValue">{{ jobSummary.unplanned }}</p>
						</div>
					</div>
					<div fxLayout="row">
						<div class="dashboardJobIcon jobPlannedIcon"></div>
						<div class="dashboardDescriptionBackground">
							<p class="dashboardDescriptionValue">{{ 'JOB_STATUS_PLANNED' | translate }}</p>
						</div>
						<div class="dashboardValueBackground dashboardValueYellow">
							<p class="dashboardValue">{{ jobSummary.planned }}</p>
						</div>
					</div>

					<div fxLayout="row">
						<div class="dashboardJobIcon jobSentIcon"></div>
						<div class="dashboardDescriptionBackground">
							<p class="dashboardDescriptionValue">{{ 'JOB_STATUS_SENT' | translate }}</p>
						</div>
						<div class="dashboardValueBackground dashboardValueSentJobs">
							<p class="dashboardValue">{{ jobSummary.sent }}</p>
						</div>
					</div>
					<div *ngIf="userProfile.hasAppActivatedRole" fxLayout="column" fxLayoutGap="10px">
						<div fxLayout="row">
							<div class="dashboardJobIcon jobReceivedIcon"></div>
							<div class="dashboardDescriptionBackground">
								<p class="dashboardDescriptionValue">
									{{ 'JOB_STATUS_RECEIVED' | translate }}
									<i class="material-icons sidenavIconSmallSmartphoneIcon">phone_iphone</i>
								</p>
							</div>
							<div class="dashboardValueBackground dashboardValueReceivedJobs">
								<p class="dashboardValue">{{ jobSummary.received }}</p>
							</div>
						</div>
						<div fxLayout="row">
							<div class="dashboardJobIcon jobAcceptIcon"></div>
							<div class="dashboardDescriptionBackground">
								<p class="dashboardDescriptionValue">
									{{ 'JOB_STATUS_ACCEPTED' | translate }}
									<i class="material-icons sidenavIconSmallSmartphoneIcon">phone_iphone</i>
								</p>
							</div>
							<div class="dashboardValueBackground dashboardValueAcceptedJobs">
								<p class="dashboardValue">{{ jobSummary.accepted }}</p>
							</div>
						</div>
						<div fxLayout="row">
							<div class="dashboardJobIcon jobStartedIcon"></div>
							<div class="dashboardDescriptionBackground">
								<p class="dashboardDescriptionValue">
									{{ 'JOB_STATUS_STARTED' | translate }}
									<i class="material-icons sidenavIconSmallSmartphoneIcon">phone_iphone</i>
								</p>
							</div>
							<div class="dashboardValueBackground dashboardValueStartedJobs">
								<p class="dashboardValue">{{ jobSummary.started }}</p>
							</div>
						</div>
						<div fxLayout="row">
							<div class="dashboardJobIcon jobDeclinedIcon"></div>
							<div class="dashboardDescriptionBackground">
								<p class="dashboardDescriptionValue">
									{{ 'JOB_STATUS_DECLINED' | translate }}
									<i class="material-icons sidenavIconSmallSmartphoneIcon">phone_iphone</i>
								</p>
							</div>
							<div class="dashboardValueBackground dashboardValueDeclinedJobs">
								<p class="dashboardValue">{{ jobSummary.declined }}</p>
							</div>
						</div>
					</div>
					<div fxLayout="row">
						<div class="dashboardJobIcon jobDoneIcon"></div>
						<div class="dashboardDescriptionBackground">
							<p class="dashboardDescriptionValue">{{ 'JOB_STATUS_DONE' | translate }}</p>
						</div>
						<div class="dashboardValueBackground dashboardValueDoneJobGreen">
							<p class="dashboardValue">{{ jobSummary.done }}</p>
						</div>
					</div>
					<div fxLayout="row">
						<div class="dashboardJobIcon jobCancelIcon"></div>
						<div class="dashboardDescriptionBackground">
							<p class="dashboardDescriptionValue">{{ 'JOB_STATUS_CANCELED' | translate }}</p>
						</div>
						<div class="dashboardValueBackground dashboardValueCanceledJobs">
							<p class="dashboardValue">{{ jobSummary.aborted }}</p>
						</div>
					</div>
					<p class="dividerDashboardDotted"></p>
					<div fxLayout="row">
						<div class="dashboardJobIcon jobWithoutCostsIcon"></div>
						<div class="dashboardDescriptionBackground">
							<p class="dashboardDescriptionValue">{{ 'WITHOUT_COSTS' | translate }}</p>
						</div>
						<div class="dashboardValueBackground dashboardValueLightRed">
							<p class="dashboardValue">{{ jobSummary.withoutCosts }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="dashboardDispatcher" fxFlex="100" fxFlex.gt-sm="70">
			<div
				class="dashboardWidgeStatisicButtonFrame"
				fxLayout="row"
				fxLayout.sm="column"
				fxLayout.xs="column"
				fxLayoutAlign="center center"
			>
				<div fxLayout="row">
					<i class="material-icons dashboardIconStatisticBackground"></i>
					<i class="material-icons dashboardIconStatisticHead">timeline</i>
					<p class="dashboardStatisticLabel">{{ 'STATISTICS' | translate }}</p>
				</div>
				<button
					(click)="showTodaysStatistics()"
					[class.disabledStatisticButton]="activeStatistic === dashboardMode.TODAY"
					[disabled]="activeStatistic === dashboardMode.TODAY"
					class="dashboardStatisticButton"
					mat-button
				>
					<mat-icon class="material-icons">today</mat-icon>
					<div class="mainToolbarLabel dashboardStatisticLabelButton">{{ 'TODAY' | translate }}</div>
				</button>
				<button
					(click)="showTomorrowsStatistics()"
					[class.disabledStatisticButton]="activeStatistic === dashboardMode.TOMORROW"
					[disabled]="activeStatistic === dashboardMode.TOMORROW"
					class="dashboardStatisticButton"
					mat-button
				>
					<img class="dashboardIconTomorrow" src="assets/pictures/dashboard/tomorrowIcon.svg" />
					<div class="mainToolbarLabel dashboardStatisticLabelButton">{{ 'TOMORROW' | translate }}</div>
				</button>
				<button
					(click)="showWeeksStatistics()"
					[class.disabledStatisticButton]="activeStatistic === dashboardMode.WEEK"
					[disabled]="activeStatistic === dashboardMode.WEEK"
					class="dashboardStatisticButton"
					mat-button
				>
					<mat-icon class="material-icons">view_week</mat-icon>
					<div class="mainToolbarLabel dashboardStatisticLabelButton">{{ 'WEEK' | translate }}</div>
				</button>
				<button
					(click)="showIndividualStatistics()"
					[class.disabledStatisticButton]="activeStatistic === dashboardMode.INDIVIDUAL"
					[disabled]="activeStatistic === dashboardMode.INDIVIDUAL"
					class="dashboardStatisticButton"
					mat-button
				>
					<mat-icon class="material-icons">filter_list</mat-icon>
					<div class="mainToolbarLabel dashboardStatisticLabelButton">{{ 'INDIVIDUAL_ONLY' | translate }}</div>
				</button>
				<div *ngIf="activeStatistic === dashboardMode.INDIVIDUAL" fxLayout="row" id="dashboardDaterange">
					<app-date-range
						(dateChange)="setDashboardDatesFromDateRangeComponent($event)"
						[minDate]="statisticsMinDate"
						[type]="'jobStatisticsSummary'"
					>
					</app-date-range>
				</div>
			</div>
			<div class="dashboardWidgetFrame" fxLayout="column">
				<div fxFill fxLayout="column">
					<!-- first row -->
					<div *ngIf="loading" class="loadingIndicatorBackground">
						<div class="loadingIndicatorCircle"></div>
					</div>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
						<div
							[matTooltipPosition]="'below'"
							class="dashboardWidgetMain"
							fxFlex
							matTooltip="{{ 'TOTALDISTANCE_DASHBOARD_TT' | translate }}"
						>
							<div class="info-box loadedDistanceDashboard">
								<i class="material-icons">linear_scale</i>
								<div class="count">
									{{ jobStatisticsSummary.loadedDistance + jobStatisticsSummary.emptyDistance | metersInKm }}
								</div>
								<div class="title">{{ 'TOTALDISTANCE' | translate }}</div>
							</div>
						</div>
						<div
							[matTooltipPosition]="'below'"
							class="dashboardWidgetMain"
							fxFlex
							matTooltip="{{ 'EMPTYDISTANCE_DASHBOARD_TT' | translate }}"
						>
							<div class="info-box emptyDistanceDashboard">
								<i class="material-icons">more_horiz</i>
								<div class="count">{{ jobStatisticsSummary.emptyDistance | metersInKm }}</div>
								<div class="title">{{ 'EMPTYDISTANCE' | translate }}</div>
								<div class="desc">
									({{
										jobStatisticsSummary.emptyDistance /
											(jobStatisticsSummary.emptyDistance + jobStatisticsSummary.loadedDistance) | numberInPercentage
									}}
									%)
								</div>
							</div>
						</div>
						<div
							[matTooltipPosition]="'below'"
							class="dashboardWidgetMain"
							fxFlex
							matTooltip="{{ 'NUMBER_OF_PLANNED_JOBS_DASHBOARD_TT' | translate }}"
						>
							<div class="info-box loadedDistanceDashboard">
								<i class="material-icons">description</i>
								<div class="count">
									{{ jobSummary.total - jobSummary.unplanned }}
								</div>
								<div class="title">
									{{ (jobSummary.total === 1 ? 'JOB' : 'JOBS') | translate }}
								</div>
							</div>
						</div>
					</div>

					<!-- second row -->
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
						<div
							[matTooltipPosition]="'below'"
							class="dashboardWidgetMain"
							fxFlex
							matTooltip="{{ 'REVENUE_OF_JOBS_DASHBOARD_TT' | translate }}"
						>
							<div class="info-box jobKPI">
								<i class="material-icons">euro_symbol</i>
								<div class="count">{{ jobStatisticsSummary.totalRevenue | currency: 'EUR' }}</div>
								<div class="title">{{ 'REVENUE' | translate }}</div>
							</div>
						</div>
						<div
							[matTooltipPosition]="'below'"
							class="dashboardWidgetMain"
							fxFlex
							matTooltip="{{ 'PROFIT_OF_JOBS_DASHBOARD_TT' | translate }}"
						>
							<div class="info-box jobKPI">
								<i class="material-icons">timeline</i>
								<div class="count">{{ jobStatisticsSummary.totalProfit | currency: 'EUR' }}</div>
								<div class="title">{{ 'PROFIT' | translate }}</div>
								<div class="desc">
									({{
										jobStatisticsSummary.totalProfit <= 0
											? 0
											: (jobStatisticsSummary.totalProfit /
													(jobStatisticsSummary.totalProfit + jobStatisticsSummary.totalCosts) | numberInPercentage)
									}}
									%)
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="jobService.$isInReceiveState | async" class="loadingIndicatorBackground">
						<div class="loadingIndicatorCircle"></div>
					</div>
				</div>

				<div fxFill fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
					<div class="dashboardWidget mat-whiteframe-4dp" fxLayout="column" fxLayoutGap="10px">
						<div
							[class.blurBackground]="
								activeStatistic === dashboardMode.WEEK || activeStatistic === dashboardMode.INDIVIDUAL
							"
							[matTooltipPosition]="'above'"
							class="dashboardCapacityDiv"
							matTooltip="{{ 'CAPACITY_BALANCING_NOT_AVAILABLE_2_TT' | translate }}"
						>
							<i class="material-icons dashboardIconBackground dashboardCapacityIconBackground"></i>
							<i class="material-icons dashboardIconHead">compare_arrows</i>
							<div class="row dashboardHeadlineRow">
								<label class="dashboardHeadline">{{ 'CAPACITY_BALANCING' | translate }}</label>
							</div>
							<div>
								<app-relations-capacity
									[driverStatistics]="driverStatistics"
									[drivers]="drivers"
									[vehicles]="vehicles"
									[isDashboard]="true"
									[isSundayTable]="userSettings.planningSettings.showSunday"
									[jobs]="jobs"
									[noDriverJobs]="unassignedJobs"
									[referenceDate]="startDate"
									[relations]="relations"
									[companySettings]="companySettings"
									fxFlex
								>
								</app-relations-capacity>
							</div>
						</div>
					</div>
					<div class="mat-whiteframe-4dp dashboardWidget" fxFlex>
						<i class="material-icons dashboardIconBackground dashboardVehicleIconBackground"></i>
						<i class="material-icons dashboardIconHead">local_shipping</i>
						<div class="row dashboardHeadlineRow">
							<label class="dashboardHeadline"
								>{{ 'VEHICLES' | translate
								}}{{ !companySettings.isDriverDeactivated ? '/' + ('DRIVERS' | translate) : '' }}</label
							>
						</div>
						<div fxLayout="column" fxLayoutGap="10px">
							<div
								[matTooltipPosition]="'below'"
								fxLayout="row"
								matTooltip="{{ 'ACTIVE_VEHICLES_DRIVERS_TT' | translate }}"
							>
								<i class="material-icons dashboardDriverVehicleIcons">airline_seat_recline_extra</i>
								<div class="dashboardDescriptionBackground">
									<p class="dashboardDescriptionValue">
										{{
											(!companySettings.isDriverDeactivated ? 'ACTIVE_VEHICLES_DRIVERS' : 'ACTIVE_VEHICLES') | translate
										}}
									</p>
								</div>
								<div class="dashboardValueBackground dashboardValueGreen">
									<p class="dashboardValue">{{ (vehicles | filterForActivatedVehiclesNow: companySettings).length }}</p>
								</div>
							</div>
							<div [matTooltipPosition]="'below'" fxLayout="row" matTooltip="{{ 'INACTIVE_VEHICLES_TT' | translate }}">
								<i class="material-icons dashboardDriverVehicleIcons">build</i>
								<div class="dashboardDescriptionBackground">
									<p class="dashboardDescriptionValue">{{ 'INACTIVE_VEHICLES' | translate }}</p>
								</div>
								<div class="dashboardValueBackground dashboardValueRed">
									<p class="dashboardValue">{{ (vehicles | filterForInactiveVehiclesNow: companySettings).length }}</p>
								</div>
							</div>
							<!-- Driver Section -->
							<div *ngIf="!companySettings.isDriverDeactivated">
								<div [matTooltipPosition]="'below'" fxLayout="row" matTooltip="{{ 'INACTIVE_DRIVERS_TT' | translate }}">
									<i class="material-icons dashboardDriverVehicleIcons">weekend</i>
									<div class="dashboardDescriptionBackground">
										<p class="dashboardDescriptionValue">{{ 'INACTIVE_DRIVERS' | translate }}</p>
									</div>
									<div class="dashboardValueBackground dashboardValueRed">
										<p class="dashboardValue">{{ (drivers | filterForInactiveDrivers: vehicles).length }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

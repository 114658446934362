import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'timetable-note-component',
	templateUrl: './timetable-note.component.html',
	styleUrls: ['./timetable-note.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableNoteComponent implements OnChanges {
	@Input() note: string;
	@Input() noteLatestEditDate: Date;
	noteIsChanged: boolean = false;
	@Output() noteChange: EventEmitter<string> = new EventEmitter<string>();
	private noteBackUp: string;

	constructor() {}

	ngOnChanges(changes) {
		this.noteIsChanged = false;
		this.noteBackUp = this.note;
	}

	emitNoteChange() {
		this.noteIsChanged = false;
		this.noteChange.emit(this.note);
	}

	checkNoteChange(noteText: string) {
		this.noteIsChanged = this.noteBackUp !== noteText;
	}
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { createMapMarkerOptionsForAppUserPosition } from '../MapFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { AppMapMarker, AppMapPosition, AppMarkerType } from '../WebMapClasses';
import { Firestore, collection, getDocs, limit, onSnapshot, orderBy, query, where } from '@angular/fire/firestore';
import { Unsubscribe } from '@firebase/firestore';
import { parseISODateStringAttributes } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { FlutaroGPSPosition, FlutaroLatLng, JobPairing } from '@flutaro/package/lib/model/Positiondata';

@Component({
	selector: 'app-map-planning',
	templateUrl: './map-planning.component.html',
	styleUrls: ['./map-planning.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPlanningComponent implements OnInit, OnChanges, OnDestroy {
	@Input() jobs: JobWrapper[];
	@Input() reloadedJobs: JobWrapper[];
	@Input() vehicles: Vehicle[];
	@Input() drivers: Driver[];
	@Input() user: FlutaroUserProfile;
	@Input() showOverview: { value: boolean };
	@Input() jobPairingFilter: JobPairing | null;
	@Output() loadJobsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() latestPositionsChange: EventEmitter<FlutaroGPSPosition[]> = new EventEmitter<FlutaroGPSPosition[]>();
	@Output() isPositionUpdateModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	$mapPolyline: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	$mapMarkers: BehaviorSubject<AppMapMarker[]> = new BehaviorSubject<AppMapMarker[]>([]);
	mapMarkersBackup: AppMapMarker[] = [];
	$mapJob: BehaviorSubject<JobWrapper> = new BehaviorSubject<JobWrapper>(null);
	$latestPositions: BehaviorSubject<FlutaroGPSPosition[]> = new BehaviorSubject<FlutaroGPSPosition[]>([]);
	$isLoadingPositions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	positionUpdateInterval: ReturnType<typeof setInterval>;
	_isPositionUpdateMode: boolean = true; // Internal State: should positions be updated every 5 min?
	jobPositionsSnapshotListener_Unsubscribe: Unsubscribe | undefined;

	constructor(private afs: Firestore) {}

	async ngOnInit() {
		if (!this.vehicles || !this.user || !this.drivers) {
			console.error(`MapPlanningMenuComponent, ngOnInit: not enough data provided. Aborting`);
			return;
		}
		await this.getLatestPositions();
		this.activatePositionRefresh();
		if (this.needsReloadForMissingJobs(this.$latestPositions.getValue())) {
			return;
		}
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes['reloadedJobs'] && this.reloadedJobs.length) {
			await this.initAppUserMarkers(this.$latestPositions.getValue());
		}
		if (changes['showOverview'] && this.showOverview?.value) {
			console.debug(`map-planning-component, showOverview triggered`);
			this.resetPlanningStateAndShowOverview();
		}
		if (changes['jobPairingFilter']) this.setMapMarkers();
	}

	ngOnDestroy() {
		clearInterval(this.positionUpdateInterval);
		if (this.jobPositionsSnapshotListener_Unsubscribe) this.jobPositionsSnapshotListener_Unsubscribe();
	}

	async getLatestPositions() {
		this.$isLoadingPositions.next(true);
		const latestPositions = await this.getLatestAppUserPositions();
		this.updateMapForPositions(latestPositions);
		this.$isLoadingPositions.next(false);
		return latestPositions;
	}

	updateMapForPositions(positions: FlutaroGPSPosition[], hideLabels?: boolean) {
		console.log(`updateMapForPositions, called for ${positions.length} positions`);
		this.$latestPositions.next(positions);
		this.latestPositionsChange.emit(positions);
		this.initAppUserMarkers(positions, hideLabels);
	}

	activatePositionRefresh() {
		this.positionUpdateInterval = setInterval(() => {
			if (!this._isPositionUpdateMode) {
				console.log(`activatePositionRefresh, DEACTIVATED update interval`);
				return;
			}
			console.log(`activatePositionRefresh, refreshing positions from DB`);
			this.getLatestPositions();
		}, 5 * 60 * 1000);
	}

	needsReloadForMissingJobs(latestPositions: FlutaroGPSPosition[]): boolean {
		const missingJobs = latestPositions.filter(
			(position) => !this.jobs.find((job) => job.backendId === position.jobId),
		);
		if (!missingJobs.length) return false;
		this.loadJobsChange.emit(missingJobs.map((position) => position.jobId));
		console.debug(`map-planning-component reloadMissingJobs, ${missingJobs.length} jobs need to be reloaded`);
		return true;
	}

	showJobOnMap(jobId: string): JobWrapper | undefined {
		console.debug(`showJobOnMap called`);
		let job = this.jobs.find((job) => job.backendId === jobId);
		// TODO: readd reloadedJobs functionality with Atlas Realm
		if (!job && this.reloadedJobs.length)
			job = this.reloadedJobs.find((reloadedJob) => reloadedJob.backendId === jobId);
		if (!job) {
			console.debug(`showJobOnMap, cant find job for jobId ${jobId}`);
			return;
		}
		this.$mapJob.next(job);
		return job;
	}

	showJobsPositionsOnMap(jobsMarker: AppMapMarker) {
		this.showJobOnMap(jobsMarker.data.jobId);
		const jobPositionsQuery = query(
			collection(this.afs, `companyData/${this.user.company}/drivers/${jobsMarker.data.driverUID}/positions`),
			where('jobId', '==', jobsMarker.data.jobId),
			orderBy('location.timestamp', 'desc'),
			limit(1500),
		);
		this._isPositionUpdateMode = false;
		this.jobPositionsSnapshotListener_Unsubscribe = onSnapshot(jobPositionsQuery, (querySnapshot) => {
			const latestPositions = [];
			querySnapshot.forEach((doc) => {
				const position = doc.data() as FlutaroGPSPosition;
				if (!position.location?.coords) {
					console.log(`showJobsPositionsOnMap, invalid GPS-Position`);
					return;
				}
				latestPositions.push(parseISODateStringAttributes(position));
			});
			console.log(`showJobsPositionsOnMap, received ${latestPositions.length} positions`);
			this.updateMapForPositions(latestPositions, true);
		});
	}

	resetPlanningStateAndShowOverview() {
		this.$mapJob.next(null);
		this.$mapPolyline.next(null);
		this.mapMarkersBackup = [];
		if (this.jobPositionsSnapshotListener_Unsubscribe) this.jobPositionsSnapshotListener_Unsubscribe();
		this.getLatestPositions();
		this._isPositionUpdateMode = true;
	}

	private async initAppUserMarkers(latestPositions: FlutaroGPSPosition[], hideLabel?: boolean) {
		this.mapMarkersBackup = latestPositions.map((position) => {
			let job = this.jobs.find((job) => job.backendId === position.jobId);
			const reloadedJob = this.reloadedJobs.find((job) => job.backendId === position.jobId);
			if (!job && !reloadedJob) {
				console.debug(`initAppUserMarkers, no job or reloaded job existing`);
				job = new JobWrapper();
			}
			return new AppMapMarker(
				createMapMarkerOptionsForAppUserPosition(position.licensePlate, position.location.extras.pairing, hideLabel),
				new FlutaroLatLng(position.location.coords.latitude, position.location.coords.longitude),
				new AppMapPosition(position, job ? job : reloadedJob, AppMarkerType.GPS_POSITION),
			);
		});
		this.setMapMarkers();
	}

	setMapMarkers() {
		this.$mapMarkers.next(
			this.mapMarkersBackup.filter((marker) =>
				this.jobPairingFilter ? marker.data.location.extras.pairing === this.jobPairingFilter : true,
			),
		);
	}

	private async getLatestAppUserPositions(): Promise<FlutaroGPSPosition[]> {
		const companyDriversCollection = collection(this.afs, `companyData/${this.user.company}/drivers`);
		const companyDriverDocuments = await getDocs(companyDriversCollection);
		const driverIDs = companyDriverDocuments.docs.map((companyDriver) => companyDriver.id);
		console.log(`getLatestAppUserPositions, received ${driverIDs.length} drivers in companyDatas collection`);
		const latestPositions: FlutaroGPSPosition[] = [];
		for (let collectionID of driverIDs) {
			const driversLatestPositionQuery = query(
				collection(this.afs, `companyData/${this.user.company}/drivers/${collectionID}/positions`),
				orderBy('location.timestamp', 'desc'),
				limit(1),
			);
			const querySnapshot = await getDocs(driversLatestPositionQuery);
			querySnapshot.forEach((doc) => {
				const position = doc.data() as FlutaroGPSPosition;
				if (!position.location?.coords) {
					console.log(`getLatestAppUserPositions, invalid GPS-Position`);
					return;
				}
				latestPositions.push(parseISODateStringAttributes(position));
			});
		}
		console.log(`getLatestAppUserPositions, received ${latestPositions.length} latest Positions`);
		console.log(latestPositions);
		return latestPositions;
	}
}

/**
 * Creates all JobWrapper dates based on plannedStartDate.
 * @param {JobWrapper} jobWrapper
 */
import {
	addSeconds,
	differenceInSeconds,
	getHours,
	getMinutes,
	getSeconds,
	setHours,
	setMinutes,
	setSeconds,
} from 'date-fns';
import { JobWrapper } from '@flutaro/package/lib/model/Job';

export function calculateNewJobDatesBasedOnRefDate(
	newJob: JobWrapper,
	newJobsRefDate: Date,
	originalJob: JobWrapper,
): JobWrapper {
	newJob.destinations.forEach((destination, index) => {
		const differencePlannedVsEarliest = differenceInSeconds(destination.earliestDate, destination.plannedDate);
		const differencePlannedVsLatest = differenceInSeconds(destination.latestDate, destination.plannedDate);
		if (index === 0) {
			const originalDestPlannedDate = new Date(destination.plannedDate);
			destination.plannedDate = setSeconds(
				setMinutes(setHours(newJobsRefDate, getHours(originalDestPlannedDate)), getMinutes(originalDestPlannedDate)),
				getSeconds(originalDestPlannedDate),
			);
		} else {
			const originalPreviousPlannedDate = originalJob.destinations[index - 1].plannedDate;
			const originalCurrentDestinationPlannedDate = originalJob.destinations[index].plannedDate;
			const plannedDateDiff = differenceInSeconds(originalCurrentDestinationPlannedDate, originalPreviousPlannedDate);
			const previousDestinationPlannedDate = newJob.destinations[index - 1].plannedDate;
			destination.plannedDate = addSeconds(previousDestinationPlannedDate, plannedDateDiff);
		}
		destination.earliestDate = addSeconds(destination.plannedDate, differencePlannedVsEarliest);
		destination.latestDate = addSeconds(destination.plannedDate, differencePlannedVsLatest);
	});
	return newJob;
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';

import { FlutaroClientService } from '../data/data-services/data.client.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';
import { Client } from '@flutaro/package/lib/model/Client';
import { DRIVER_QUALIFICATION } from '@flutaro/package/lib/model/FlutaroRequirements';
import { AppDialogSettings } from 'src/app/dialog/AppDialog';

export interface clientDialogData {
	client: Client;
	isNew: boolean;
}

@Component({
	selector: 'app-client-dialog',
	templateUrl: './client.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDialogComponent implements OnInit {
	isNew: boolean;
	client: Client;
	VehicleTypes = Object.values(VEHICLE_TYPE);
	DriverQualifications = Object.values(DRIVER_QUALIFICATION);

	constructor(
		public dialogRef: MatDialogRef<ClientDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: AppDialogSettings<Client>,
		private clientService: FlutaroClientService,
		private notifications: FlutaroNotificationsProvider,
	) {}

	ngOnInit() {
		this.isNew = this.dialogData.isNew;
		if (this.isNew) {
			this.client = new Client();
		} else {
			this.client = cloneDeep(this.dialogData.data);
		}
	}

	onSave() {
		if (!this.client.name) {
			this.notifications.showBasicSnackBar('Feld: Name ist erforderlich.');
			return;
		}
		this.clientService.store(this.client);
		this.dialogRef.close();
	}

	onAbort() {
		this.dialogRef.close();
	}
}

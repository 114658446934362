import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Tenant } from '@flutaro/package/lib/model/IElement';
import { AppBasicComponent } from '../app.components.basic.component';

@Component({
	selector: 'app-flutaro-audit',
	templateUrl: './flutaro-audit.component.html',
	styleUrls: ['./flutaro-audit.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroAuditComponent extends AppBasicComponent {
	@Input() createdAt: Date;
	@Input() lastModifiedBy: string;
	@Input() createdBy: string;
	@Input() lastModifiedAt: Date;
	@Input() tenant: Tenant;
	@Output() tenantChange: EventEmitter<Tenant> = new EventEmitter<Tenant>();
}

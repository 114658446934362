import { IElement } from '@flutaro/package/lib/model/IElement';

export class ExternalAddressMapping extends IElement {
	sourceCity: string;
	sourceCounty: string;
	sourceAddressLine: string;
	sourceZip: string;
	targetCounty: string;
	targetZip: string;
	targetCity: string;
	targetAddressLine: string;
	state: ExternalAddressState = ExternalAddressState.PENDING;
}

export enum ExternalAddressState {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
}

import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../FlutaroService';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import {
	parseElementsBasics,
	transformObjectIntoSynonymClass,
} from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { environment } from '../../../environments/environment';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Injectable({
	providedIn: 'root',
})
export class FlutaroSynonymService extends FlutaroDataService<Synonym> {
	protected initProvider() {
		this.url = environment.routingApiURL + '/data/synonyms';
		this.collectionName = FlutaroCollection.LOCATION;
		this.websocketService.synonymSocket.subscribe((synonymData) => this.handleWebSocketAction(synonymData));
	}

	protected parseElement(synonym: Synonym): Synonym {
		return transformObjectIntoSynonymClass(parseElementsBasics(synonym));
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';

@Component({
	selector: 'flutaro-job-status',
	templateUrl: './flutaro-job-status.component.html',
	styleUrls: ['./flutaro-job-status.component.css', '../../flutaroComponentStyles.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroJobStatusComponent implements OnInit {
	@Input() status: JobStatus | undefined;
	@Input() userProfile: FlutaroUserProfile | undefined;
	@Input() deactivateDisabled: boolean | undefined;
	@Input() deactivatePlannedStatus: boolean | undefined;
	@Input() activateEmptyStatus: boolean | undefined;
	@Input() vehicleId: string | undefined;
	@Output() statusChange: EventEmitter<JobStatus> = new EventEmitter<JobStatus>();

	constructor() {}

	ngOnInit(): void {}
}

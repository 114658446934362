import { endOfDay, isAfter, isBefore, subMinutes } from 'date-fns';
import { isBeforeOrSame } from '@flutaro/package/lib/functions/DataDateFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { areTwoJobsOverlapping } from '@flutaro/package/lib/functions/job/JobOverlappingFunctions';
import { getFirstPickUp } from '@flutaro/package/lib/functions/job/DestinationFunctions';

/**
 *
 * @param job
 * @param onlyChangeTime: if true no dates day will be changed, only the time
 */
export function setJobsPlannedDatesToLatest(job: JobWrapper, onlyChangeTime: boolean) {
	for (let i = job.destinations.length - 1; i >= 0; i--) {
		const dest = job.destinations[i];
		const nextDest = i !== job.destinations.length - 1 ? job.destinations[i + 1] : null;
		if (!onlyChangeTime) {
			dest.plannedDate = dest.latestDate;
		} else {
			const endOfDayDate = endOfDay(dest.plannedDate);
			dest.plannedDate = isAfter(dest.latestDate, endOfDayDate) ? endOfDayDate : dest.latestDate;
		}
		const minPlannedDate = nextDest ? subMinutes(nextDest.plannedDate, nextDest.timeInMinutes) : null;
		if (i === job.destinations.length - 1 || isBeforeOrSame(dest.plannedDate, minPlannedDate)) continue;
		dest.plannedDate = minPlannedDate;
	}
}

export function getOverlappingJobsCorrespondingJob(overlappingJob: JobWrapper, jobs: JobWrapper[]): JobWrapper {
	return jobs.find((job) => areTwoJobsOverlapping(job, overlappingJob));
}

export function getJobForEarliestAdjustment(job: JobWrapper, refJob: JobWrapper): JobWrapper {
	return isBefore(getFirstPickUp(job).earliestDate, getFirstPickUp(refJob).earliestDate) ? job : refJob;
}

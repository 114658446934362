<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground jobDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">description</i>
		{{ (dialogData.isNew ? 'NEW_JOB' : 'JOB') | translate }}
		<span class="dialogToolbarObjectName">{{ jobWrapper.job.identifier }}</span>
	</h1>
	<!-- dialogLockButton -->
	<mat-button-toggle
		[matTooltipPosition]="'below'"
		class="mat-raised mat-small mat-ink-ripple dialogLockButton"
		matTooltip="{{ 'JOB_DIALOG_LOCK_TT' | translate }}"
		*ngIf="false"
	>
		<!-- add here implementation:
      different css-class if button is active or not.
      also different icon if button is active or not :)
      something like *ngIf="showLockMode"
    -->
		<!-- NOT locked: show this icon -->
		<i class="material-icons lockModeToggleOffIcon">lock_open</i>
		<!-- Locked = READONLY: show the following icon -->
		<!-- <i class="material-icons lockModeToggleOnIcon">lock</i> -->
	</mat-button-toggle>
</mat-toolbar>

<mat-dialog-content>
	<div class="layout-margin" fxLayout="column">
		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
			<div class="marginRight0" fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="10px">
				<h4 class="dialogHeadlineContent">
					<i class="material-icons dialogIcon">assignment</i> {{ 'JOB_DATA' | translate }}
				</h4>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field fxFlex="100" fxFlex.gt-sm="30">
						<input
							[(ngModel)]="jobWrapper.job.identifier"
							(ngModelChange)="triggerComponentsCD()"
							matInput
							placeholder="{{ 'JOB_IDENTIFIER' | translate }}"
							required
						/>
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="35">
						<input [(ngModel)]="jobWrapper.revenue" matInput placeholder="{{ 'REVENUE' | translate }}" type="number" />
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="31">
						<input
							[(ngModel)]="jobWrapper.costCalculation.totalCosts"
							disabled
							matInput
							placeholder="{{ 'COSTS' | translate }}"
							type="number"
						/>
					</mat-form-field>
				</div>

				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<div fxFlex="100" fxLayout="row">
						<mat-checkbox
							(change)="changeRepeating()"
							[(ngModel)]="isRepeating"
							[disabled]="!isFirstRepeating"
							aria-label="Repeat Job"
							class="mat-primary mat-checkbox-small dialogMatCheckbox"
							mat-theme="flutaroGrey"
						>
							{{ 'REPEAT' | translate }}
						</mat-checkbox>
						<button
							(click)="showRepeating = !showRepeating"
							[disabled]="!isRepeating"
							class="jobRepeatButton mat-raised"
							mat-button
							matTooltip="{{ 'SHOW_REPEATING_TT' | translate }}"
						>
							<i *ngIf="!showRepeating" class="material-icons dialogIconRepeat">expand_more</i>
							<i *ngIf="showRepeating" class="material-icons dialogIconRepeat">expand_less</i>
						</button>
					</div>
					<mat-form-field fxFlex="100" id="clientSelect">
						<!-- TODO: add app-autocomplete component and use here to filter options displayed -->
						<mat-label>{{ 'CLIENT' | translate }}</mat-label>
						<input
							[(ngModel)]="jobWrapper.job.clientsName"
							[matAutocomplete]="auto"
							aria-label="Number"
							matInput
							placeholder="{{ 'JOB_CLIENT_NAME_CHOOSE' | translate }}"
							type="text"
						/>
						<mat-autocomplete #auto="matAutocomplete">
							<mat-option *ngFor="let client of clients" [value]="client.backendId">
								{{ client.name }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<div
					*ngIf="dialogData.userSettings.planningSettings.customJobDisplay"
					fxLayout="row"
					fxLayout.sm="column"
					fxLayout.xs="column"
					fxLayoutGap="10px"
				>
					<mat-form-field fxFlex="35">
						<mat-select
							(selectionChange)="onJobTypeChange($event)"
							[(ngModel)]="jobWrapper.jobLabel"
							id="job-display"
							placeholder="{{ 'JOB_LABEL_ATTRIBUTE_PLACEHOLDER' | translate }}"
						>
							<mat-option
								*ngFor="let JobAttribute of JobAttributes"
								[ngStyle]="{ 'background-color': JobAttribute.jobColor }"
								[value]="JobAttribute.jobLabel"
								id="job-display-options"
								style="margin-bottom: 5px"
							>
								{{ JobAttribute.jobLabel }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="35">
						<input
							(keyup)="onKey($event)"
							[(ngModel)]="jobWrapper.jobLabel"
							matInput
							placeholder="{{ 'JOB_LABEL_PLACEHOLDER' | translate }}"
						/>
					</mat-form-field>
				</div>
			</div>

			<mat-divider class="requirementsAndGeneralInfoDivider"></mat-divider>

			<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="10px">
				<h4 class="dialogHeadlineContent">
					<i class="material-icons dialogIcon">extension</i> {{ 'ADDITIONALLY' | translate }}
				</h4>
				<jobRequirements [(job)]="jobWrapper"></jobRequirements>
				<app-select-vehicle-for-job
					[vehicles]="dialogData.vehicles"
					[drivers]="dialogData.drivers"
					[job]="jobWrapper"
					[disabled]="true"
					[userProfile]="dialogData.userProfile"
					[componentLayout]="'row'"
					[companySettings]="dialogData.companySettings"
					[selectedDate]="(jobWrapper | getFirstPickUp).plannedDate"
				>
				</app-select-vehicle-for-job>
			</div>
		</div>

		<app-job-repeating
			*ngIf="showRepeating && isRepeating"
			[(jobsRepeating)]="jobWrapper.job.jobsRepeating"
			[disabled]="!isFirstRepeating"
			[minDate]="(jobWrapper | getFirstPickUp).plannedDate"
		>
		</app-job-repeating>

		<mat-divider class="jobDividerRepeatingMultiDestination"></mat-divider>

		<app-multi-destinations
			(jobWrapperChange)="triggerComponentsCD()"
			(resetCosts)="resetJobCosts()"
			[(jobWrapper)]="jobWrapper"
			[isNew]="dialogData.isNew"
		>
		</app-multi-destinations>
		<mat-divider class="jobDialogDivider"></mat-divider>
		<!-- customer specific row (dynamically) -->
		<div *ngIf="jobWrapper.attributes" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
			<mat-accordion fxFlex="100">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">more_vert</i> {{ 'JOB_MORE_ATTRIBUTES' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<div *ngFor="let attributeEntry of jobWrapper.attributes | keyValOfObject">
							<mat-form-field fxFlex="100">
								<input [ngModel]="attributeEntry.value" disabled matInput placeholder="{{ attributeEntry.key }}" />
							</mat-form-field>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</mat-dialog-content>

<app-flutaro-audit
	[(tenant)]="jobWrapper.tenant"
	[companySettings]="dialogData.companySettings"
	[createdAt]="jobWrapper.createdAt"
	[createdBy]="jobWrapper.createdBy"
	[lastModifiedAt]="jobWrapper.lastModifiedAt"
	[lastModifiedBy]="jobWrapper.lastModifiedBy"
	[userProfile]="dialogData.userProfile"
>
</app-flutaro-audit>

<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
	<button (click)="onAbort()" class="buttonCancel mat-raised" mat-button type="button">
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="onSave()"
		[disabled]="!(jobWrapper | isValidJob : triggerCD) || dialogData.userProfile.hasReadOnlyRole"
		class="buttonDone mat-primary_flutaro"
		mat-button
	>
		<mat-icon>
			<i class="material-icons material-icons-white">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

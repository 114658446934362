<app-job-table
	[clients]="clientProvider.$data | async"
	[companySettings]="companyProvider.$companySettings | async"
	[isDataLoading]="jobService.$isInReceiveState | async"
	[data]="jobService.$data | async"
	[drivers]="driverService.$data | async"
	[userProfile]="authFb.$userProfile | async"
	[userSettings]="userSettings.$userSettings | async"
	[vehicles]="vehicleService.$data | async"
>
</app-job-table>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { JobAdjustDatesDialogComponent } from './job.dates.adjust.dialog.component';
import { JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { DestinationDialogData } from 'src/app/dialog/AppDialog';
import { generateNewObjectReference } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'app-job-destination-planning',
	templateUrl: './job.planned.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobPlannedComponent {
	@Input() destination: JobDestination;
	@Input() destinationIndex: number;
	@Input() disabled: boolean;
	@Input() job: JobWrapper;
	@Output() destinationChange = new EventEmitter<JobDestination>();

	constructor(private dialog: MatDialog) {}

	async openAdjustEndDateDialog() {
		let dialogConfig = new MatDialogConfig();
		const destinationIndex = this.job.destinations.findIndex((dest) => dest === this.destination);
		const prevDestination = this.job.destinations[destinationIndex - 1];
		dialogConfig.data = new DestinationDialogData(this.destination, prevDestination, this.disabled);
		let dialogRef = this.dialog.open(JobAdjustDatesDialogComponent, dialogConfig);
		const adjustedDestination = await lastValueFrom(dialogRef.afterClosed());
		if (!adjustedDestination) return;
		this.destination = generateNewObjectReference(adjustedDestination);
		this.emitChanges();
	}

	emitChanges() {
		this.destinationChange.emit(this.destination);
	}
}

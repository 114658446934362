import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { AppPushProvider } from '../app/push/app.push.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { WebsocketResponseData } from '../app/push/WebsocketConstants';
import { FlutaroWebsocketService } from '../app/push/app.push.websocket';
import { Directive, NgZone } from '@angular/core';
import { IElement } from '@flutaro/package/lib/model/IElement';
import { DataChange } from '@flutaro/package/lib/model/AppDataTypes';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { CompanyService } from '../company/company.service';
import {
	filterUserDataForCompanyTenant,
	isCollectionPartOfCompaniesTenantManagedCollections,
} from '@flutaro/package/lib/functions/AppTenantFunctions';
import { TimetableDependentDataServices } from '../timetable/TimetableClasses';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Directive()
export class FlutaroDataService<T extends IElement> {
	$data: BehaviorSubject<T[]> = new BehaviorSubject([]);
	$isInReceiveState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	protected url: string;
	protected getUrl: string;
	protected collectionName: FlutaroCollection;

	constructor(
		public http: HttpClient,
		public pushProvider: AppPushProvider,
		public authFb: WebAuthService,
		public websocketService: FlutaroWebsocketService,
		public zone: NgZone,
		public companyService: CompanyService,
	) {
		this.initProvider();
		if (!TimetableDependentDataServices.includes(this.collectionName)) this.listenToAuthAndGetData();
		this.activateSubscriptions();
	}

	activateSubscriptions() {
		this.authFb.$userTenantChange.subscribe(async (newTenant) => {
			if (
				!isCollectionPartOfCompaniesTenantManagedCollections(
					this.collectionName,
					this.companyService.$companySettings.getValue(),
				)
			) {
				console.log(`FlutaroDataService of ${this.collectionName} NOT part of filtering for tenant - aborting`);
				return;
			}
			console.log(`FlutaroDataService of ${this.collectionName}, requesting data for new tenant ${newTenant?.name}`);
			this.$isInReceiveState.next(true); // Redundant but instant display of reload of data
			// We simply reload all data from Server and apply filtering based on new tenant value in UserProfile. Highly inefficient but displays the process once switched to Atlas Realm which rebuilds the query based on tenant changed
			// Even though inefficient this most important makes sure that all data displayed is up to date. To distribute the requests at least a little bit a random wait from 1-1000ms is implemented
			await flutaroWait(Math.floor(Math.random() * 1000) + 1);
			this.getDataFromServer();
		});
	}

	async getDataFromServer(): Promise<T[]> {
		this.$isInReceiveState.next(true);
		const data = await lastValueFrom(this.http.get<T[]>(this.getUrl ? this.getUrl : this.url));
		const filteredData = this.filterData(data);
		this.receive(filteredData);
		this.$isInReceiveState.next(false);
		return filteredData;
	}

	async getDataFromServerForTimeRange(start: Date, end: Date): Promise<T[]> {
		this.updateGetURL(start, end);
		const data = await this.getDataFromServer();
		console.debug(`getDataFromServerForTimeRange, received ${data.length} elements from backend`);
		return data;
	}

	updateGetURL(start: Date, end: Date) {
		// Implement me
	}

	public getData(): T[] {
		return this.$data.getValue();
	}

	/**
	 * Accesses $data directly without using any maybe existing filters on getData() to make sure to always return the requested object even if deprecated
	 * @param id
	 */
	getElementById(id: string): T | undefined {
		return this.$data.getValue().find((element) => element.backendId === id);
	}

	async store(element: T): Promise<T> {
		let storedElement = await lastValueFrom(this.http.post<T>(this.url, element));
		this.parseElement(storedElement);
		return storedElement;
	}

	public async remove(element: T): Promise<any> {
		return this.sendDelete(element);
	}

	/**
	 * Parse and store an element and announce via pushChange Subject
	 * @param element to be stored and announced
	 */
	parseAndStorePush(element: T) {
		this.parseAndStoreElement(element);
	}

	public parseAndStoreElement(element: T): void {
		element = this.parseElement(element);
		this.storeElement(element);
	}

	async sendDelete(deleteElement: T): Promise<any> {
		return lastValueFrom(this.http.delete<T>(this.url + '/' + deleteElement.backendId));
	}

	async getElementByIdFromServer(elementId: string): Promise<T> {
		// let getUrl = this.getUrl ? this.getUrl : this.url;
		const element = (await lastValueFrom(this.http.get(`${this.url}/${elementId}`))) as T;
		// Make sure tenant-filtered data only
		const filteredData = this.filterData([element]);
		if (!filteredData.length) return;
		this.parseAndStorePush(element);
		return element;
	}

	/**
	 * Sends a PUT-Request to routing.api to update the Element and updates internally on successful operation
	 * @param element
	 */
	async update(element: T) {
		if (!element.company || !element.backendId) {
			console.error(`Error in API-Update: Element didnt have company or backendId set. Aborting, needs fix.`);
			return;
		}
		return lastValueFrom(this.http.put<T>(this.url, element, { observe: 'response' }));
	}

	protected initProvider() {
		// Set get-URL
		// Activate Socket Listener
	}

	protected listenToAuthAndGetData() {
		this.authFb.$userIsAuthorized.subscribe((isAuthorized) => {
			if (!isAuthorized) return;
			this.getDataFromServer();
		});
	}

	protected updateElement(newElement: T): void {
		newElement = this.parseElement(newElement);
		const newData = this.$data
			.getValue()
			.filter((job) => job.backendId !== newElement.backendId)
			.slice();
		newData.push(newElement);
		this.setNextData(newData);
	}

	protected removeWithoutSending(deleteElement: T): void {
		const currentData = this.$data.getValue();
		const filteredData = currentData.filter((data) => data.backendId !== deleteElement.backendId);
		if (currentData.length === filteredData.length) return;
		this.setNextData(filteredData);
	}

	protected receive(data: T[]): void {
		const parsedData = data.map((element) => this.parseElement(element));
		this.setNextData(parsedData);
	}

	protected filterData(data: T[]): T[] {
		const companySettings = this.companyService.$companySettings.getValue();
		const userProfile = this.authFb.$userProfile.getValue();
		return this.filterDataServiceSpecific(
			filterUserDataForCompanyTenant(companySettings, userProfile, data, this.collectionName).filter(
				(element) => element.company === userProfile.company,
			) as T[],
		);
	}

	protected filterDataServiceSpecific(data: T[]): T[] {
		return data;
	}

	protected parseElement(newElement: T): T {
		return newElement;
	}

	protected storeElement(element: T) {
		const newData = this.$data.getValue().filter((dataElement) => element.backendId !== dataElement.backendId);
		newData.push(element);
		this.setNextData(newData);
	}

	protected setNextData(nextData: T[]) {
		this.zone.run(() => this.$data.next(this.prepareInternalData(nextData)));
	}

	protected prepareInternalData(data: T[]): T[] {
		return data;
	}

	protected handleWebSocketAction(data: WebsocketResponseData<T>) {
		let element = this.parseElement(data.storable);
		const filteredData = this.filterData([element]);
		if (!filteredData.length) return;

		let action = data.status;
		switch (action) {
			case 'UPDATED':
				let originElement = this.getElementById(element.backendId);
				// No need to update jobs, which are not loaded/not relevant for User
				if (!originElement) return;
				this.updateElement(element);
				break;
			case 'CREATED':
				this.parseAndStoreElement(element);
				break;
			case 'DELETED':
				this.removeWithoutSending(element);
				break;
			default:
				console.error('Websocket sent an Action we dont know: ' + action);
				break;
		}
		this.pushProvider.dataChangeSubject.next(new DataChange<T>(action, data.typeName, element.lastModifiedBy, element));
	}
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	forwardRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
	Validators,
} from '@angular/forms';
import { startOfDay } from 'date-fns';
import { isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';
import { MatDatetimepickerType } from '@mat-datetimepicker/core/datetimepicker/datetimepicker-type';

@Component({
	selector: 'app-datetimepicker',
	templateUrl: './flutaro-datepicker.component.html',
	styles: [
		'.date-picker-component {margin-bottom: 10px } .date-picker-date-only { width: 180px; } .dialogDatePicker {min-width: 145px; max-width: 300px}',
	],
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => FlutaroDatetimepickerComponent),
			multi: true,
		},
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FlutaroDatetimepickerComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroDatetimepickerComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
	flutaroDatetimepickerControl: FormControl;
	@Input() date: Date;
	@Output() dateChange: EventEmitter<Date | null> = new EventEmitter();
	@Input() noModel?: boolean; // no model => emit new dates by picker
	@Input() placeholder?: string;
	@Input() hideDateInput?: boolean;
	@Input() allowDynamicWidth?: boolean;
	@Input() required?: boolean;
	@Input() disabled?: boolean;
	@Input() type: MatDatetimepickerType;
	@Input() min?: Date;
	@Input() max?: Date;
	@Input() flutaroDatetimepickerFilter;
	@Output() isDateValid: EventEmitter<boolean> = new EventEmitter<boolean>();

	ERROR_MESSAGE = {
		required: () => `Dies ist ein Pflichtfeld.`,
		matDatepickerMin: (par) => `Wähle ein Datun nach dem minimalen Datum`,
		matDatepickerMax: (par) => `Wähle ein Datum vor dem maximalen Datum`,
		matDatepickerParse: () => `Ungültiges Datumsformat. Bitte geben Sie ein Datum im akzeptierten Datumsformat an`,
		matDatepickerFilter: () =>
			`Datum außerhalb der erlaubten Zeitfensters. Bitte wähle ein Datum zwischen dem minimalen und maximalen Datum`,
	};

	constructor() {}

	listOfErrors(ctrl: FormControl): string[] {
		// TODO: refactor error handling and translation handling
		return Object.keys(ctrl.errors).map((err) => this.ERROR_MESSAGE[err](ctrl.getError(err)));
	}

	onChange: any = () => {
		this.onDateChange();
	};

	onTouched: any = () => {
		this.onDateChange();
	};

	ngOnInit(date?: Date) {
		let dateValidators = this.required ? [Validators.required] : [];
		this.flutaroDatetimepickerControl = new FormControl(date ? date : this.date, dateValidators);
		this.flutaroDatetimepickerControl.markAllAsTouched();
		this.flutaroDatetimepickerControl.statusChanges.subscribe(() => {
			this.emitDateValidationState();
		});
	}

	onDateChange() {
		if (!this.flutaroDatetimepickerControl.value || !isValidDate(this.flutaroDatetimepickerControl.value)) {
			if (!this.required) this.dateChange.emit(this.flutaroDatetimepickerControl.value);
			return;
		}

		if (this.type === 'date') {
			this.dateChange.emit(startOfDay(this.flutaroDatetimepickerControl.value));
		} else {
			this.dateChange.emit(this.flutaroDatetimepickerControl.value);
		}
		if (this.noModel) {
			this.writeValue(null);
		}
	}

	ngOnChanges(change) {
		if (change['date']) {
			this.ngOnInit();
		}
	}

	writeValue(date: Date) {
		if (this.type === 'date' && isValidDate(date)) {
			this.flutaroDatetimepickerControl.setValue(startOfDay(date));
		} else {
			this.flutaroDatetimepickerControl.setValue(date);
		}
	}

	registerOnChange(fn: (value: any) => void) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.flutaroDatetimepickerControl.disable();
		} else {
			this.flutaroDatetimepickerControl.enable();
		}
	}

	validate(control: FormControl) {
		// TODO: check when/how this is used when refactoring components error / valiation handling
		this.emitDateValidationState();
		const errors = Object.assign({}, this.flutaroDatetimepickerControl.errors || {});
		return Object.keys(errors).length ? errors : null;
	}

	emitDateValidationState() {
		this.isDateValid.emit(this.flutaroDatetimepickerControl.valid);
	}
}

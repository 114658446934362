<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground jobDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">attach_money</i>
		{{ 'COST_CALCULATION_MAIN' | translate }}
		<span class="dialogToolbarObjectName">{{ dialogData.job.job.identifier }}</span>
	</h1>
</mat-toolbar>

<mat-dialog-content class="costDialog" fxLayout="column">
	<div *ngIf="!dialogData.errors.isValid" class="costCalculationIsInvalidDiv">
		<i class="material-icons isInvalidIcon">cancel</i>
		<p class="isInvalidErrorHeadline">{{ 'COST_CALCULATION_INVALID' | translate }}</p>

		<p *ngIf="dialogData.errors.vehicleRequiresDepotAddress" class="isInvalidErrorHeadline">
			{{ 'VEHICLE_REQUIRES_DEPOT_ADDRESS' | translate }}:
			<span class="fontWeight500">{{ 'VEHICLE_REQUIRES_DEPOT_ADDRESS_DETAIL' | translate }}</span>
		</p>
		<p *ngIf="dialogData.errors.isOverlapping" class="isInvalidErrorHeadline">
			{{ 'OVERLAPPING_JOBS' | translate }}:
			<span class="fontWeight500">{{ 'OVERLAPPING_JOBS_DETAIL' | translate }}</span>
		</p>
		<p *ngIf="dialogData.errors.notAllOtherJobsHaveRevenue" class="isInvalidErrorHeadline">
			{{ 'NOT_ALL_JOBS_HAVE_REVENUE' | translate }}:
			<span class="fontWeight500">{{ 'NOT_ALL_JOBS_HAVE_REVENUE_DETAIL' | translate }}</span>
		</p>
		<p *ngIf="dialogData.errors.jobNeedsRevenue" class="isInvalidErrorHeadline">
			{{ 'JOB_NEED_REVENUE' | translate }}:
			<span class="fontWeight500">{{ 'JOB_NEED_REVENUE_DETAIL' | translate }}</span>
		</p>
		<p *ngIf="dialogData.errors.contractorButNoRate" class="isInvalidErrorHeadline">
			{{ 'CONTRACTOR_BUT_NO_RATE' | translate }}:
			<span class="fontWeight500"> {{ 'CONTRACTOR_BUT_NO_RATE_DETAIL' | translate }}</span>
		</p>
		<p
			*ngIf="dialogData.errors.otherJobStartingOnSameDayHasNoCalculation && !dialogData.errors.isOverlapping"
			class="isInvalidErrorHeadline"
		>
			{{ 'JOB_WITHOUT_COST_CALCULATION_ON_SAME_DAY' | translate }}:
			<span class="fontWeight500">{{ 'JOB_WITHOUT_COST_CALCULATION_ON_SAME_DAY_DETAIL' | translate }}</span>
		</p>
		<p class="fontWeight500">{{ 'COST_CALCULATION_INVALID_SHOW_ALWAYS' | translate }}</p>
	</div>

	<div *ngIf="dialogData.errors.isValid" fxLayout="column" fxLayoutGap="10px">
		<div fxLayout="row" fxLayoutGap="10px">
			<p class="fontWeight500">
				<i class="material-icons jobCostProfileSummaryIcon">local_shipping</i>
				<span>{{ 'VEHICLE_DRIVER' | translate }}: </span>
				<span class="jobCostProfileSummaryName">{{ dialogData.vehicle.licensePlate }}</span>
				<span class="jobCostProfileDriverName" *ngIf="dialogData.driver">{{ dialogData.driver.toString() }}</span>
			</p>
			<p *ngIf="dialogData.contractor" class="fontWeight500">
				<i class="material-icons jobCostProfileSummaryIcon">work</i>
				<span>{{ 'SUBCONTRACTOR' | translate }}: </span>
				<span class="jobCostProfileSummaryName">{{ dialogData.contractor.name }}</span>
			</p>
		</div>
		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
			<p class="jobCostDialogTour">
				<i class="material-icons jobCostProfileSummaryIcon">directions</i>
				<span class="marginRight5">{{ 'TOUR' | translate }}: </span>
			</p>
			<app-address-search
				[placeholder]="'EMPTY_DRIVE_ADDRESS' | translate"
				(synonymChange)="changeEmptyKmAddress($event)"
				[hidePlaceholder]="false"
				[synonym]="dialogData.job.costCalculation.emptyKmAddress"
				style="padding-top: 15px; min-width: 270px"
			>
			</app-address-search>
			<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
				<span
					[class.manuallyChangedStart]="dialogData.job.costCalculation.isCustomEmptyKmAddress"
					class="jobCostTourAddressBackground"
				>
					<i
						*ngIf="emptyKmAddressIsHome"
						[matTooltipPosition]="'above'"
						class="material-icons jobCostTourAddressIcon"
						matTooltip="{{ 'EMPTY_DRIVE_HOME_ADDRESS' | translate }}"
					>
						home
					</i>
					<i
						*ngIf="!emptyKmAddressIsHome && !dialogData.job.costCalculation.isCustomEmptyKmAddress"
						[matTooltipPosition]="'above'"
						class="material-icons jobCostTourAddressIcon"
						matTooltip="{{ 'EMPTY_DRIVE_LAST_ADDRESS' | translate }}"
					>
						place
					</i>
					<i
						*ngIf="dialogData.job.costCalculation.isCustomEmptyKmAddress"
						[matTooltipPosition]="'above'"
						class="material-icons jobCostTourAddressIconManuallyChanged"
						matTooltip="{{ 'EMPTY_DRIVE_MANUALLY_CHANGED' | translate }}"
					>
						edit
					</i>
				</span>
				<i class="material-icons jobCostTourAddressArrowIcon">arrow_forward</i>
			</div>
			<div fxLayout="column" fxLayoutAlign="start start">
				<div class="jobCostDialog" fxLayout="row" fxLayoutAlign="start end">
					<div
						class="destinationLabelDisplay labelJobTimewindow"
						fxLayout="row"
						fxLayoutAlign="center center"
						fxLayoutGap="10px"
					>
						<span
							*ngFor="let destination of dialogData.job.destinations; let i = index"
							style="width: 100%"
							fxLayout="row"
							fxLayoutAlign="start end"
							fxLayoutGap="10px"
						>
							<div
								*ngIf="
									activateAddStop &&
									dialogData.job.costCalculation.addedStop.latitude &&
									i === addedStopDestinationIndex
								"
								fxLayout="row"
								fxLayoutAlign="start end"
								fxLayoutGap="10px"
							>
								<span *ngIf="i > 0">➝</span>
								<span
									[matTooltipPosition]="'above'"
									class="addedStopInJobMarker"
									matTooltip="{{ 'ADDED_STOP_ADDRESS_TT' | translate }}"
								>
								</span>
								<span>
									{{ dialogData.job.costCalculation.addedStop.zip }}
									{{ dialogData.job.costCalculation.addedStop.city }}
								</span>
							</div>
							<span *ngIf="i > 0">➝</span>
							<span
								[class.labelJobAlignLeft]="i !== 0"
								[class.labelJobAlignRight]="i === 0"
								class="SynonymOrNoCodeStart"
								fxFlex
							>
								{{ destination.position.zip }} {{ destination.position.city }}
							</span>
						</span>
					</div>
					<button
						(click)="toggleActivateAddStop()"
						[class.addStopButtonCostDialogDeactivated]="!activateAddStop"
						[class.addStopButtonCostDialog]="activateAddStop"
						[matTooltipPosition]="'above'"
						class="addAddedStopButton"
						mat-button
						matTooltip="{{ 'ADD_STOP_TT' | translate }}"
					>
						<i *ngIf="!activateAddStop" class="material-icons sidenavButtonNotesIcon"> add </i>
						<i *ngIf="activateAddStop" class="material-icons sidenavButtonNotesIcon"> remove </i>
					</button>
				</div>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="end center" style="width: 100%; height: 80px" *ngIf="activateAddStop">
			<div fxLayout="row" class="jobCostAddStopAddressDiv" fxLayoutAlign="center center">
				<i class="material-icons jobCostAddStopAddressIcon">add_location</i>
				<app-address-search
					(synonymChange)="addStopAddressesChanged($event)"
					(synonymCleared)="clearAddedStopAddress()"
					[(synonym)]="dialogData.job.costCalculation.addedStop"
					[placeholder]="'ADD_STOP_ADDRESS' | translate"
					style="width: 100%"
				>
				</app-address-search>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="calculationModeSwitchStyles">
			<mat-slide-toggle (ngModelChange)="changeManualCostsToggle($event)" [(ngModel)]="isManualCostMode">
				<span class="mat-checkbox-label">
					{{ (isManualCostMode ? 'MANUAL_COST_TOGGLE_MANUAL' : 'MANUAL_COST_TOGGLE_AUTOMATED') | translate }}
				</span>
			</mat-slide-toggle>

			<button
				(click)="recalculateCosts()"
				[disabled]="isRefreshDisabled()"
				class="mat-raised mat-small mat-ink-ripple costDialogRefreshButton"
				mat-button
			>
				<i class="material-icons costDialogRefreshIcon">refresh</i>
				Refresh
			</button>

			<app-routing-profile
				[routingProfile]="dialogData.job.costCalculation.routingProfile"
				(routingProfileChange)="updateJobsRoutingProfile($event)"
			></app-routing-profile>
			<app-country-select
				[countries]="dialogData.job.costCalculation.excludedCountries"
				(countriesChange)="updateJobsExcludedCountries($event)"
				[placeholder]="'EXCLUDED_COUNTRIES'"
				[isMultiple]="true"
				class="countrySelectStyle"
			></app-country-select>
		</div>

		<div fxLayout="row" fxLayoutAlign="center end" *ngIf="dialogData.errors.noRouteFound">
			<p class="isInvalidErrorHeadline">
				{{ 'NO_ROUTE_FOUND' | translate }}:
				<span class="fontWeight500">{{ 'NO_ROUTE_FOUND_DETAIL' | translate }}</span>
			</p>
		</div>

		<div
			(click)="isRecalculateEnabled() ? recalculateCosts() : true"
			[style.position]="'relative'"
			fxLayout="row"
			fxLayout.sm="column"
			fxLayout.xs="column"
			fxLayoutGap="10px"
		>
			<div *ngIf="$showCostRecalculateOption | async" class="refreshIndicatorBackground">
				<span class="refreshScreenText">{{ 'RECALCULATE_COSTS' | translate }}</span>
				<i class="material-icons jobCostAddStopAddressIcon refreshIndicatorIcon">refresh</i>
			</div>
			<div *ngIf="$showCostsCalculating | async" class="costDialogCalulatingBackground">
				<span class="recalculationScreenText">{{ 'RECALCULATE_NEW' | translate }}</span>
				<i class="material-icons recalculateIndicatorIcon">cached</i>
			</div>
			<!-- here profile -->
			<div
				[class.blurBackground]="($showCostRecalculateOption | async) || dialogData.errors.noRouteFound"
				[class.costsSelectedProfileBackground]="true"
				class="jobCostProfileDiv"
				fxLayout="column"
				style="width: 300px"
			>
				<div class="costDialogDistanceRow" fxLayout="row" fxLayoutAlign="start end">
					<div class="flutaroDescriptionBackground" fxLayout="row" fxLayoutAlign="center center">
						<p class="drivingTimeOfCostProfileDisplay">{{ 'DRIVING_TIME' | translate }}</p>
					</div>
					<div class="costDialogValueBackground" fxLayout="row" fxLayoutAlign="center center">
						<p class="costDialogValue">{{ dialogData.job.costCalculation.totalTime | minutesToDrivingTime }}</p>
					</div>
					<mat-divider></mat-divider>
					<div class="costDialogValueBackground" fxLayout="row" fxLayoutAlign="center center">
						<p class="costDialogValue">{{ dialogData.job.costCalculation.totalDistance | metersInKm }} km</p>
					</div>
					<div class="flutaroDescriptionBackground" fxLayout="row" fxLayoutAlign="center center">
						<p class="distanceOfCostProfileDisplay">{{ 'DISTANCE' | translate }}</p>
					</div>
				</div>
				<div fxLayout="column" style="width: 270px" fxLayoutAlign="center start">
					<div fxLayout="row" fxLayoutAlign="space-around center" class="jobCostProfileValueListRow" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">cloud_queue</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">
								{{ 'EMPTYDISTANCE' | translate }} {{ dialogData.job.costCalculation.emptyDistance | metersInKm }} km
							</p>
							<p class="jobCostProfileDescriptionValue">
								{{ dialogData.job.costCalculation.emptyDriveCosts | currency : 'EUR' }}
							</p>
						</div>
					</div>
					<div fxLayout="row" fxLayoutAlign="space-around center" class="jobCostProfileValueListRow" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">cloud</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">
								{{ 'LOADDISTANCE' | translate }} {{ dialogData.job.costCalculation.loadedDistance | metersInKm }} km
							</p>
							<p class="jobCostProfileDescriptionValue">
								{{ dialogData.job.costCalculation.loadedDriveCosts | currency : 'EUR' }}
							</p>
						</div>
					</div>
					<div fxLayout="row" fxLayoutAlign="space-around center" class="jobCostProfileValueListRow" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">functions</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">km-Rate</p>
							<p class="jobCostProfileDescriptionValue">
								{{ dialogData.job.costCalculation.vehicleKmRate | currency : 'EUR' }}
								/ km
							</p>
						</div>
					</div>
					<div fxLayout="row" fxLayoutAlign="space-around center" class="jobCostProfileValueListRow" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">map</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">{{ 'VEHICLE_VARIABLE_COSTS' | translate }}</p>
							<p class="jobCostProfileDescriptionValue">
								{{ dialogData.job.costCalculation.totalVehicleDynamicCosts | currency : 'EUR' }}
							</p>
						</div>
					</div>
					<div fxLayout="row" fxLayoutAlign="space-around center" class="jobCostProfileValueListRow" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">time_to_leave</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">{{ 'VEHICLE_FIXED_COSTS' | translate }}</p>
							<p class="jobCostProfileDescriptionValue">
								{{ dialogData.job.costCalculation.vehicleFixedCosts | currency : 'EUR' }}
							</p>
						</div>
					</div>
					<div
						*ngIf="applyDoubleFixedCosts"
						class="jobCostProfileValueListRow"
						fxLayout="row"
						fxLayoutAlign="space-around center"
						fxLayoutGap="10px"
					>
						<i class="material-icons jobCostProfileDivRowIcon">settings</i>
						<div class="jobCostProfileDivRow costTotalDiv" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">{{ 'ADDED_FIX_COSTS' | translate }}</p>
							<p class="jobCostProfileDescriptionValue costBorderBottomDashed">
								{{ dialogData.job.costCalculation.addedFixCosts }}€
							</p>
						</div>
					</div>
					<div fxLayout="row" class="jobCostProfileValueListRow" fxLayoutAlign="space-around center" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">remove_circle_outline</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">{{ 'TOLL_COSTS' | translate }}</p>
							<p class="jobCostProfileDescriptionValue">
								{{ dialogData.job.costCalculation.totalTollCosts | currency : 'EUR' }}
							</p>
							<i
								*ngIf="dialogData.contractorCostRate && dialogData.contractorCostRate.kmRateIncludesToll"
								[matTooltipPosition]="'below'"
								class="material-icons jobCostProfileDivRowInfoIcon"
								matTooltip="{{ 'TOLL_COSTS_INCLUDE_TT' | translate }}"
								>info_outline</i
							>
						</div>
					</div>
					<div fxLayout="row" class="jobCostProfileValueListRow" fxLayoutAlign="space-around center" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">attach_money</i>
						<div
							*ngIf="!isManualCostMode"
							class="jobCostProfileDivRow costTotalDiv"
							fxLayout="row"
							fxLayoutAlign="space-between center"
						>
							<p class="jobCostProfileDescriptionText">{{ 'COSTS_TOTAL' | translate }}</p>
							<p class="jobCostProfileDescriptionValue costBorderBottomDashed">
								{{ dialogData.job.costCalculation.totalCosts | currency : 'EUR' }}
							</p>
						</div>
						<div *ngIf="isManualCostMode" class="jobDialogTotalCostsDiv">
							<mat-form-field appearance="outline" class="jobDialogTotalCosts">
								<mat-label>{{ 'COSTS_TOTAL' | translate }}</mat-label>
								<input
									(blur)="finalFormatManualCostNumber()"
									(ngModelChange)="handleManualCostChange($event)"
									[(ngModel)]="dialogData.job.costCalculation.totalCosts"
									matInput
									name="costs"
									placeholder="{{ 'COSTS_HANDLE_MANUAL_COSTS' | translate }}"
									required
									type="number"
								/>
							</mat-form-field>
						</div>
					</div>
					<div fxLayout="row" class="jobCostProfileValueListRow" fxLayoutAlign="space-around center" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">euro_symbol</i>
						<div class="jobCostProfileDivRow" fxLayout="row" fxLayoutAlign="space-between center">
							<p class="jobCostProfileDescriptionText">{{ 'JOB_REVENUE' | translate }}</p>
							<p class="jobCostProfileDescriptionValue">{{ dialogData.job.revenue | currency : 'EUR' }}</p>
						</div>
					</div>
					<div fxLayout="row" class="jobCostProfileValueListRow" fxLayoutAlign="space-around center" fxLayoutGap="10px">
						<i class="material-icons jobCostProfileDivRowIcon">timeline</i>
						<div
							[ngClass]="{
								costDialogLoss: dialogData.job.costCalculation?.profit < 0,
								costDialogProfit: dialogData.job.costCalculation?.profit > 0
							}"
							class="jobCostProfileDivRow"
							fxLayout="row"
							fxLayoutAlign="space-between center"
						>
							<p class="jobCostProfileDescriptionText">{{ 'PROFIT' | translate }}</p>
							<p class="jobCostProfileDescriptionValue costBorderBottomDashed">
								{{ dialogData.job.costCalculation?.profit | currency : 'EUR' }}
							</p>
						</div>
					</div>
					<div
						*ngIf="dialogData.job.costCalculation.isCustomContractorEmptyKmCalculation"
						fxLayout="row"
						fxLayoutAlign="space-around center"
						class="jobCostProfileValueListRow"
					>
						<p class="jobCostSelectedProfileText">
							<i class="material-icons jobCostProfileOtherJobsInfoIcon">info_outline</i>
							<span class="marginLeft5">{{ 'COSTS_SPECIAL_CASE' | translate }}</span>
						</p>
					</div>
					<div
						*ngIf="dialogData.jobsStartingOnSameDay.length > 0"
						class="jobCostSelectedProfileRow jobCostProfileValueListRow"
					>
						<p class="dividerCostsDialogDotted100"></p>
						<p class="jobCostSelectedProfileText">
							<i class="material-icons jobCostProfileOtherJobsInfoIcon">info_outline</i>
							<span class="marginLeft5"
								>{{ dialogData.jobsStartingOnSameDay.length }} {{ 'MORE_1' | translate }}
								{{ (dialogData.jobsStartingOnSameDay.length === 1 ? 'PICKUP' : 'PICKUPS') | translate }}
								{{ 'ON_SAME_DAY' | translate }}.</span
							>
						</p>

						<p class="jobCostSelectedProfileText">
							<i class="material-icons jobCostProfileOtherJobsInfoIcon">info_outline</i>
							<span>{{ 'VEHICLE_FIX_COSTS_DISTRIBUTE' | translate }}</span>
						</p>
						<p
							*ngIf="dialogData.job.costCalculation.minusPositionCorrections?.length"
							class="jobCostSelectedProfileText"
						>
							<i class="material-icons jobCostProfileOtherJobsInfoIcon">info_outline</i>
							<span
								>{{ 'COSTS_ARE_ADJUSTED_TO_AVOID_NEGATIVE_POSITIONS' | translate }}
								<i class="material-icons jobCostMinusCorrectionsArrowIcon">arrow_forward</i>
							</span>
							<span
								>{{ 'COSTS_ARE_ADJUSTED_BY' | translate }}:
								{{
									-(
										dialogData.job.costCalculation.minusPositionCorrections
										| calculateTotalAmountMinusPositionCorrections
									)
								}}
								€</span
							>
						</p>
					</div>
				</div>
			</div>
			<!-- map: show only when not re-calculating -->
			<app-map
				*ngIf="($showCostsCalculating | async) === false"
				[class.blurBackground]="($showCostRecalculateOption | async) || dialogData.errors.noRouteFound"
				[job]="dialogData.job"
				[polylinePath]="$polylinePath | async"
				class="app-map"
			>
			</app-map>
		</div>
		<div
			*ngIf="dialogData.job.costCalculation.vehicleCostsFixedTotal"
			fxLayout="row"
			fxLayoutAlign="start center"
			fxLayoutGap="10px"
		>
			<p class="jobCostDialogSettingLabel">
				<i class="material-icons jobSidenavIconSmall">settings</i>{{ 'SETTINGS' | translate }}:
			</p>
			<mat-checkbox
				(ngModelChange)="changeDoubleFixedCosts($event)"
				[(ngModel)]="applyDoubleFixedCosts"
				[matTooltipPosition]="'below'"
				class="jobCostSecondDailyRateCheckbox"
				matTooltip="{{ 'COSTS_APPLY_DOUBLE_FIXED_COSTS_TT' | translate }}"
			>
				{{ 'COSTS_APPLY_DOUBLE_FIXED_COSTS' | translate }}
			</mat-checkbox>
		</div>
		<mat-accordion [style.margin-left]="'10px'" fxFlex="100">
			<mat-expansion-panel class="jobNotesSidenav">
				<mat-expansion-panel-header class="jobSidenavExpansionHeader">
					<mat-panel-title>
						<i class="material-icons dialogIconExpansionPanel">comment</i>
						{{ 'JOB_COMMENT_PICKUP_DELIVERY' | translate }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" style="position: relative">
					<mat-form-field fxFlex="50">
						<textarea
							[(ngModel)]="dialogData.job.destinations[0].notes"
							[cdkAutosizeMaxRows]="5"
							[cdkAutosizeMinRows]="1"
							[cdkTextareaAutosize]="true"
							matInput
							placeholder="{{ 'JOB_COMMENT_PICKUP' | translate }}"
						>
						</textarea>
					</mat-form-field>
					<mat-form-field fxFlex="50">
						<textarea
							[(ngModel)]="dialogData.job.destinations[dialogData.job.destinations.length - 1].notes"
							[cdkAutosizeMaxRows]="5"
							[cdkAutosizeMinRows]="1"
							[cdkTextareaAutosize]="true"
							matInput
							placeholder="{{ 'JOB_COMMENT_DELIVERY' | translate }}"
						>
						</textarea>
					</mat-form-field>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</mat-dialog-content>

<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
	<button (click)="onAbort()" class="importButton mat-raised" mat-button>
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="onSave()"
		[disabled]="
			!(dialogData.job | isDifferentJobCosts : jobBackup) ||
			!dialogData.errors.isValid ||
			($showCostsCalculating | async)
		"
		class="importButtonDone mat-primary_flutaro"
		mat-button
	>
		<mat-icon>
			<i class="material-icons">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VehicleDialogComponent } from './vehicle.dialog.component';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { VehicleDialogData } from 'src/app/dialog/AppDialog';
import { TableAddComponent } from '../table/table.add.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
	selector: 'app-vehicle-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleAddComponent extends TableAddComponent {
	@Input() drivers: Driver[];
	@Input() vehicles: Vehicle[];
	dialogComponent = VehicleDialogComponent;

	createDialogData(): VehicleDialogData {
		const vehicle = new Vehicle(this.userSettings.planningSettings.standardVehicleSpeedProfile);
		if (this.companySettings.isDriverDeactivated) vehicle.isDepotAddressBased = true;
		return new VehicleDialogData(
			this.userSettings,
			this.userProfile,
			this.companySettings,
			vehicle,
			this.vehicles,
			this.drivers,
		);
	}

	configureDialogAppearance(dialogConfig: MatDialogConfig) {
		dialogConfig.panelClass = 'full-width-dialog';
	}
}

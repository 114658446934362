import { TimetableFilteredEntry } from './TimetableFilterClasses';
import { areIntervalsOverlapping, endOfDay, isAfter, setISODay, startOfDay } from 'date-fns';
import { FlutaroEvent, TimetableEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { DateInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';

export function filterEventsForTimetableTimerange(
	events: FlutaroEvent[],
	start: Date,
	end: Date,
	isSundayTable: boolean,
): TimetableEvent[] {
	if (!events?.length) return [];

	try {
		let filteredEvents: TimetableEvent[] = events
			.filter(
				(event) =>
					isAfter(event.endTime, event.startTime) &&
					areIntervalsOverlapping(new DateInterval(event.startTime, event.endTime), new DateInterval(start, end)),
			)
			.map((event) => new TimetableEvent(event));

		// If Timetable isnt SundayTable, filter out Events starting and ending on Sunday
		if (!isSundayTable)
			filteredEvents = filteredEvents.filter(
				(event) =>
					!areIntervalsOverlapping(
						new DateInterval(event.startTime, event.endTime),
						new DateInterval(startOfDay(setISODay(start, 7)), endOfDay(setISODay(start, 7))),
					),
			);
		return filteredEvents;
	} catch (e) {
		console.log(`filterEventsForTimetableTimerange, error in calculation:`);
		console.log(e);
		return [];
	}
}

export function FilterDriverEntryForUninformedJobs(driverEntries: TimetableFilteredEntry[]) {
	let filteredArray = [];
	for (let i = driverEntries.length - 1; i >= 0; i--) {
		let tempDriverEntry: TimetableFilteredEntry = driverEntries[i];
		let tempUninformedJobs = tempDriverEntry.jobsEntry.filter((jobEntry) => jobEntry.jobWrapper.job.status === 2);
		if (tempUninformedJobs.length > 0) {
			filteredArray.push(tempDriverEntry);
		}
	}
	return filteredArray;
}

export function filterForOverlappingTimetableEntries(timetableFilteredEntries: TimetableFilteredEntry[]) {
	return timetableFilteredEntries.filter(
		(timetableFilteredEntry) => timetableFilteredEntry.vehicleJobStatus.overlapping,
	);
}

export function getTimetableEntriesVehiclesWithoutJobs(timetableFilteredEntries: TimetableFilteredEntry[]): Vehicle[] {
	return timetableFilteredEntries
		.filter((timetableFilteredEntry) => timetableFilteredEntry.jobsEntry.length)
		.map((timetableFilteredEntry) => timetableFilteredEntry.vehicleEntry.vehicle);
}

export function getVehiclesWithStatus(timetableData: TimetableFilteredEntry[]): TimetableFilteredEntry[] {
	return timetableData.filter((entry) => entry.vehicleStatuses.length > 0);
}

<div class="relationStatusRow" fxLayout="row" fxLayoutAlign="space-around center">
	<button
		class="buttonWithoutStyle relationStatusDrivers"
		[class.relationStatusActive]="exclusiveActivated"
		(click)="filterEntityForRelation()"
		[disabled]="isDashboard || capacity.entitiesIds.length === 0"
		matTooltip="{{ 'RELATION_CAPACITY_VEHICLES_TT' | translate }}"
		fxLayout="row"
		fxLayoutAlign="{{ isDashboard ? 'space-between' : 'start' }} center"
	>
		<div fxLayout="row">
			<div class="material-icons relationStatusIconVehicle" [class.showRelationAnimationForIcon]="exclusiveActivated">
				local_shipping
			</div>
			<p class="relationStatusDescriptionValueVehicle">{{ 'VEHICLES' | translate }}</p>
		</div>

		<div class="relationStatusValueLeft" [class.showRelationAnimationValue]="exclusiveActivated">
			{{ capacity.entitiesIds.length }}
		</div>
	</button>
	<i class="material-icons relationStatusCompareArrows" [style.color]="capacity.color">compare_arrows</i>
	<button
		class="buttonWithoutStyle relationStatusJobs"
		[class.relationStatusActive]="jobsExclusiveActivated"
		(click)="filterJobsForRelation()"
		[disabled]="isDashboard || capacity.jobIds.length === 0"
		matTooltip="{{ 'RELATION_CAPACITY_JOBS_TT' | translate }}"
		fxLayout="row"
		fxLayoutAlign="{{ isDashboard ? 'space-between' : 'end' }} center"
	>
		<div class="relationStatusValueRight" [class.showRelationAnimationValue]="jobsExclusiveActivated">
			{{ capacity.jobIds.length }}
		</div>
		<div fxLayout="row" fxLayoutAlign="center center">
			<p class="relationStatusDescriptionValueJobs">{{ 'JOBS' | translate }}</p>
			<div class="material-icons relationStatusIconJob" [class.showRelationAnimationForIcon]="jobsExclusiveActivated">
				description
			</div>
		</div>
	</button>
</div>

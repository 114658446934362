import { Injectable } from '@angular/core';
import assign from 'lodash-es/assign';
import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';
import values from 'lodash-es/values';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { JobHelperService } from '../job/job.helper.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { ImportedDriverTemplate, ImportedVehicleTemplate } from './import.excel.classes';
import { ImportExcelFileProvider } from './import.excel.file.provider';
import { createSynonymFromImportedData, parseExcelImportDate } from './ImportFunctions';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { addMinutes, isBefore } from 'date-fns';
import { IntegrationsPoseidonService } from '../integrations/poseidon/integrations.poseidon.service';
import { WebAuthService } from '../app/auth/web-auth.service';
import { DestinationType, JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { VEHICLE_TYPE, VEHICLE_TYPE_TRANSLATED } from '@flutaro/package/lib/model/VehicleConstants';
import { parseIntoVehicleTypeFromTranslation } from '@flutaro/package/lib/functions/VehicleFunctions';
import { ImportedJobTemplate } from '@flutaro/package/lib/model/import/ExcelImportClasses';
import { getTenantForTenantName } from '@flutaro/package/lib/functions/CompanyFunctions';
import { CompanyService } from '../company/company.service';
import { setJobAppInSyncAttributeOnVehicleChange } from '@flutaro/package/lib/functions/communicator/AppCommunicatorFunctions';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Injectable()
export class ImportExcelStorageProvider {
	constructor(
		private excelFileProvider: ImportExcelFileProvider,
		private vehicleService: FlutaroVehicleService,
		private driverService: FlutaroDriverService,
		private jobService: FlutaroJobService,
		private jobHelperService: JobHelperService,
		private synonymService: FlutaroSynonymService,
		private notifications: FlutaroNotificationsProvider,
		private userSettingsProvider: UserSettingsProvider,
		private poseidon: IntegrationsPoseidonService,
		private auth: WebAuthService,
		private companyService: CompanyService,
	) {}

	private static setVehicleTypeForAttribute(vehicleTypeAttribute: VEHICLE_TYPE_TRANSLATED): VEHICLE_TYPE {
		return parseIntoVehicleTypeFromTranslation(vehicleTypeAttribute);
	}

	public async saveJobs(jobsData: ImportedJobTemplate[]) {
		return this.handleImportedJobs(jobsData);
	}

	public saveDrivers(driverData: ImportedDriverTemplate[]) {
		this.handleImportedDrivers(driverData);
	}

	public saveVehicles(vehicleData: ImportedVehicleTemplate[]) {
		this.handleImportedVehicles(vehicleData);
	}

	/**
	 * Creates JobWrappers from imported Data and stores all Data
	 * @returns Count of imported Jobs
	 * @param importedJobs
	 */
	public async handleImportedJobs(importedJobs: ImportedJobTemplate[]) {
		console.debug(`handleImportedJobs, starting for ${importedJobs.length} imported jobs`);
		let jobsIdentifiers: string[] = [];
		const filteredImportedJobs = importedJobs.filter((importedJob) => {
			if (!jobsIdentifiers.includes(importedJob.identifier)) {
				jobsIdentifiers.push(importedJob.identifier);
				return true;
			} else {
				console.log(`handleImportedJobs, filtered out job ${importedJob.identifier} due to redundant identifier`);
				return false;
			}
		});

		let finalNewImportJobs: JobWrapper[] = [];
		for (const importedJob of filteredImportedJobs) {
			const job: JobWrapper = this.createJobObject(importedJob);
			await this.poseidon.getDistanceForJob(job);
			if (importedJob.tenantName) {
				const tenant = getTenantForTenantName(
					this.companyService.$companySettings.getValue().tenants,
					importedJob.tenantName,
				);
				if (tenant) job.tenant = tenant;
			}
			finalNewImportJobs.push(job);
		}
		if (!finalNewImportJobs.length) {
			this.notifications.showBasicSnackBar(
				'Es wurden keine Aufträge importiert, da alle Auftröge mittlerweile bereits existieren',
			);
			return true;
		} else {
			let text = finalNewImportJobs.length + ' Aufträge werden importiert';
			this.notifications.showBasicSnackBar(text);
		}
		return this.jobHelperService.createUniqueIdentifierJobsBulkAndAnnounce(finalNewImportJobs);
	}

	public async handleImportedDrivers(data: ImportedDriverTemplate[]) {
		let text = data.length + ' Fahrer werden importiert';
		this.notifications.showBasicSnackBar(text);
		data.map((impDriver: ImportedDriverTemplate) => {
			let driver: Driver = this.createDriverObject(impDriver);
			if (impDriver.tenantName) {
				const tenant = getTenantForTenantName(
					this.companyService.$companySettings.getValue().tenants,
					impDriver.tenantName,
				);
				if (tenant) driver.tenant = tenant;
			}
			return this.driverService.store(driver);
		});
	}

	public async handleImportedVehicles(data: ImportedVehicleTemplate[]) {
		let text = data.length + ' Fahrzeuge werden importiert';
		this.notifications.showBasicSnackBar(text);

		const vehicles = [];
		for (const impVehicle of data) {
			let vehicle: Vehicle = this.createVehicleObject(impVehicle);
			if (impVehicle.tenantName) {
				const tenant = getTenantForTenantName(
					this.companyService.$companySettings.getValue().tenants,
					impVehicle.tenantName,
				);
				if (tenant) vehicle.tenant = tenant;
			}
			vehicles.push(await this.vehicleService.store(vehicle));
		}
		console.log(`handleImportedVehicles, ${vehicles.length} vehicles successfully imported`);
	}

	/**
	 * Create proper Client, Address, Capacity and Dates Objects
	 * @param impJobWrapper Data of imported Job
	 * @returns {JobWrapper}
	 */
	public createJobObject(impJobWrapper: ImportedJobTemplate): JobWrapper {
		let job: JobWrapper = new JobWrapper();
		job.job.requiredVehicle = ImportExcelStorageProvider.setVehicleTypeForAttribute(impJobWrapper.requiredVehicle);
		job.job.clientsName = impJobWrapper.clientsName;
		job.destinations = [];
		const firstDestination = new JobDestination(
			this.excelFileProvider.createSynonymFromJobImportedData('start', impJobWrapper),
			DestinationType.PICKUP,
		);
		firstDestination.name = impJobWrapper.startCompanyName;
		job.destinations.push(firstDestination);
		const secondDestination = new JobDestination(
			this.excelFileProvider.createSynonymFromJobImportedData('end', impJobWrapper),
			DestinationType.DELIVERY,
		);
		secondDestination.name = impJobWrapper.endCompanyName;
		job.destinations.push(secondDestination);
		getFirstPickUp(job).earliestDate = parseExcelImportDate(impJobWrapper.earliestStartDate);
		getFirstPickUp(job).latestDate = impJobWrapper.latestStartDate
			? parseExcelImportDate(impJobWrapper.latestStartDate)
			: getFirstPickUp(job).earliestDate;
		getFirstPickUp(job).plannedDate = getFirstPickUp(job).earliestDate;

		getLastDestination(job).earliestDate = parseExcelImportDate(impJobWrapper.earliestEndDate);
		getLastDestination(job).latestDate = impJobWrapper.latestEndDate
			? parseExcelImportDate(impJobWrapper.latestEndDate)
			: getLastDestination(job).earliestDate;
		getLastDestination(job).plannedDate = getLastDestination(job).earliestDate;

		const endDateByPlannedDate = addMinutes(getFirstPickUp(job).plannedDate, job.totalTimeInMinutes);
		getLastDestination(job).plannedDate = isBefore(getLastDestination(job).earliestDate, endDateByPlannedDate)
			? endDateByPlannedDate
			: getLastDestination(job).earliestDate;
		job.job.identifier = impJobWrapper.identifier;
		job.revenue = impJobWrapper.revenue ? impJobWrapper.revenue : 0;

		this.setVehicleForJob(impJobWrapper, job);
		// TODO: this should use communicator-server-provider and set appUserEmail and Sync-Attributes
		setJobAppInSyncAttributeOnVehicleChange(job);
		return job;
	}

	public checkAndDeleteEmptyLastRows(importData) {
		let lastRowWasEmpty: boolean = true;
		while (lastRowWasEmpty && importData.length > 0) {
			let rowIsEmpty: boolean = !values(importData[importData.length - 1]).some(
				(x) => x !== undefined && x !== null && x !== '',
			);
			if (rowIsEmpty) {
				// Delete added empty Row through minSpareRows=1
				importData.pop();
			} else {
				lastRowWasEmpty = false;
			}
		}
	}

	protected createDriverObject(impDriver: ImportedDriverTemplate): Driver {
		let driver = new Driver();
		driver.homeAddress = createSynonymFromImportedData(
			impDriver.city,
			impDriver.zip,
			impDriver.street,
			impDriver.houseNumber,
		);
		assign(driver.homeAddress, pick(impDriver, keys(driver.homeAddress)));
		assign(driver, pick(impDriver, keys(driver)));
		return driver;
	}

	protected createVehicleObject(impVehicle: ImportedVehicleTemplate): Vehicle {
		let vehicle = new Vehicle();
		assign(vehicle, pick(impVehicle, keys(vehicle)));
		vehicle.carType = ImportExcelStorageProvider.setVehicleTypeForAttribute(
			<VEHICLE_TYPE_TRANSLATED>(<undefined>vehicle.carType),
		);
		return vehicle;
	}

	/**
	 * Sets the Driver-ID for a imported Job with licencePlate attribute set.
	 * For the Driver assignment to work the Driver needs to be assigned to a Vehicle before the Import.
	 * @param impJobWrapper
	 * @param jobWrapper
	 * @returns {JobWrapper}
	 */
	protected setVehicleForJob(impJobWrapper: ImportedJobTemplate, jobWrapper: JobWrapper): JobWrapper {
		if (!impJobWrapper.assignedDriverLicence) {
			return jobWrapper;
		}
		const vehicle = this.vehicleService.getVehicleByLicensePlate(impJobWrapper.assignedDriverLicence);

		this.jobHelperService.changeJobAttributesForNewVehicle(jobWrapper, vehicle);
		return jobWrapper;
	}
}

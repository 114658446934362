<app-map
	[polylinePath]="$mapPolyline | async"
	(showJobChange)="showJobOnMap($event)"
	(showJobsPositionsChange)="showJobsPositionsOnMap($event)"
	[providedMarkers]="$mapMarkers | async"
	[job]="$mapJob | async"
	fxFlexFill
>
</app-map>
<div *ngIf="$isLoadingPositions | async" class="loadingIndicatorBackground">
	<div class="loadingIndicatorCircle"></div>
</div>

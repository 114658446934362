import { MatDialog } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelationDialogComponent } from './relation.dialog.component';
import { RelationDialogData } from './RelationClasses';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { TableAddComponent } from '../table/table.add.component';

@Component({
	selector: 'app-relation-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationAddComponent extends TableAddComponent {
	@Input() vehicles: Vehicle[];
	dialogComponent = RelationDialogComponent;

	constructor(public dialog: MatDialog) {
		super(dialog);
	}

	createDialogData(): RelationDialogData {
		return new RelationDialogData(
			this.userSettings,
			this.userProfile,
			this.companySettings,
			new Relation(),
			this.vehicles,
		);
	}
}

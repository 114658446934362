import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FlutaroRelationsCapacityComponent } from './relation.status.component';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { RelationDialogComponent } from './relation.dialog.component';
import { CommonModule } from '@angular/common';
import { TableModule } from '../table/table.module';
import { DialogModule } from '../dialog/dialog.module';
import { RelationAddComponent } from './relation.add.component';
import { RelationTableComponent } from './relation.table.component';
import { RelationCapacityComponent } from './relation.capacity.component';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { LocationModule } from '../location/location.module';

@NgModule({
	declarations: [
		FlutaroRelationsCapacityComponent,
		RelationDialogComponent,
		RelationTableComponent,
		RelationAddComponent,
		RelationCapacityComponent,
	],
	exports: [FlutaroRelationsCapacityComponent, RelationTableComponent],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		TableModule,
		DialogModule,
		MccColorPickerModule.forRoot({}),
		MatRadioModule,
		MatRadioModule,
		MatSelectModule,
		LocationModule,
	],
	providers: [],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class RelationModule {}

import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { JobHelperService } from '../../job/job.helper.service';
import { TimetableJobStoreProvider } from './timetable.job.store.provider';
import { UserSettingsProvider } from '../../settings/settings.user.provider';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';

/**
 * Handles changes related to the timetable in order to keep displayed data in synch with serverside state.
 * Handles updated by calling store-provider and acting on changes.
 */
@Injectable()
export class TimetableFilterController {
	constructor(
		private vehicleService: FlutaroVehicleService,
		private jobHelperService: JobHelperService,
		private timetableJobStore: TimetableJobStoreProvider,
		private userSettingsProvider: UserSettingsProvider,
	) {}

	dispatchJobToUnassigned(oldJob: JobWrapper): Promise<boolean> {
		console.debug(`dispatchJobToUnassigned, called for ${oldJob.job.identifier}`);
		return this.timetableJobStore.dispatchJobToUnassigned(oldJob);
	}

	async dispatchJobToNewVehicle(job: JobWrapper, newVehicleId: string): Promise<boolean> {
		console.debug(`dispatchJobToNewVehicle, called for ${job.job.identifier} with newVehicleId ${newVehicleId}`);
		if (!newVehicleId || job.vehicleId === newVehicleId) {
			if (!newVehicleId) console.error(`dispatchJobToNewVehicle, error: job ${job.job.identifier} has no newVehicleId`);
			if (job.vehicleId === newVehicleId)
				console.error(
					`dispatchJobToNewVehicle, error: job ${job.job.identifier} has identical newVehicleId ${newVehicleId} and job.vehicleId`,
				);
			return;
		}
		const oldJob = cloneDeep(job);
		const newJob = cloneDeep(job);
		const newVehicle = this.vehicleService.getElementById(newVehicleId);
		await this.jobHelperService.changeJobAttributesForNewVehicle(newJob, newVehicle);
		return this.timetableJobStore.recalculateJobsCostsOnVehicleChange(
			newJob,
			oldJob,
			this.userSettingsProvider.$userSettings.getValue().planningSettings.activeCostCalculationMode,
		);
	}
}

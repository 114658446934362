import { Injectable } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import values from 'lodash-es/values';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import {
	DriverValidationAttributes,
	ExcelJob,
	ImportedDriverTemplate,
	ImportedSynonymTemplate,
	ImportedVehicleTemplate,
	JobWrapperValidationAttributes,
	LocationValidationAttributes,
	VehicleValidationAttributes,
	dataTypesDefinition,
} from './import.excel.classes';
import { jobsVehicleTypeMatchesExistingVehicleTypes } from './validation/ExcelImportValidationFunctions';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { VEHICLE_TYPE_TRANSLATED } from '@flutaro/package/lib/model/VehicleConstants';
import { ImportObject, ImportedJobTemplate } from '@flutaro/package/lib/model/import/ExcelImportClasses';
import { getVehiclesCurrentDriver } from '@flutaro/package/lib/functions/VehicleFunctions';

@Injectable()
export class ImportValidationProvider {
	currentValidationStatus: Map<number, any> = new Map();

	constructor(
		private vehicleService: FlutaroVehicleService,
		private driverService: FlutaroDriverService,
		private jobService: FlutaroJobService,
	) {}

	clearValidationData() {
		this.currentValidationStatus = new Map();
	}

	calculateValidationStatus(dataType: string, importData: ImportObject[], restrictedId: number) {
		// Empty Data
		if (importData.length === 1 && isEmpty(importData[0])) {
			return;
		}
		const emptyRowFiltered = importData.filter((data) => !this.isSkipValidationOfEmptyRow(data));
		this.setImportIdIfMissing(importData);
		switch (dataType) {
			case dataTypesDefinition.job:
				this.currentValidationStatus = this.validateJobImportData(<any>emptyRowFiltered, restrictedId);
				break;
			case dataTypesDefinition.driver:
				this.currentValidationStatus = this.validateDriverImportData(<any>emptyRowFiltered, restrictedId);
				break;
			case dataTypesDefinition.vehicle:
				this.currentValidationStatus = this.validateVehicleImportData(<any>emptyRowFiltered, restrictedId);
				break;
			default:
				break;
		}
	}

	/**
	 * Checks if the vehicle Licenceplates of an JobWrapper is valid.
	 * Valid means that the Driver of the licence plate is existing and assigned to the vehicle.
	 * @param impJobWrapper
	 * @returns {boolean}
	 */
	protected checkIfSetDriverInJobIsExisting(impJobWrapper: ImportedJobTemplate): boolean {
		// TODO
		return true;
	}

	private setImportIdIfMissing(importData: ImportObject[]) {
		importData.forEach((data) => {
			if (!data.importId && !this.isSkipValidationOfEmptyRow(data))
				data.importId = new Date().getMilliseconds() + Math.random();
		});
	}

	private isGeneralSkipRow(row, restrictedId?: number) {
		if (this.isSkipValidationOfEmptyRow(row)) return true;
		return !!restrictedId && row.importId !== restrictedId;
	}

	private validateDriverImportData(driverImportData: ImportedDriverTemplate[], restrictedId: number) {
		let driverValidationStatusMap: Map<number, DriverValidationAttributes> = new Map<
			number,
			DriverValidationAttributes
		>();
		const drivers = this.driverService.getData();
		driverImportData.forEach((driver) => {
			if (this.isGeneralSkipRow(driver, restrictedId)) return;
			let driverValidation: DriverValidationAttributes = new DriverValidationAttributes();
			driverValidation.hasValidAddress = !!driver.latitude && !!driver.zip;
			driverValidation.hasFullName = !!driver.lastName && !!driver.firstName;
			const vehicle = this.vehicleService.getVehicleByLicensePlate(driver.vehicleLicensePlate);
			if (driver.vehicleLicensePlate && !vehicle) {
				driverValidation.hasValidVehicle = false;
			}
			if (driver.vehicleLicensePlate && !getVehiclesCurrentDriver(vehicle, drivers)) {
				driverValidation.hasValidVehicleAssignment = false;
			}
			driverValidation.isValid =
				driverValidation.hasValidVehicle &&
				driverValidation.hasFullName &&
				driverValidation.hasValidAddress &&
				driverValidation.hasValidVehicleAssignment;
			driverValidationStatusMap.set(driver.importId, driverValidation);
		});
		return driverValidationStatusMap;
	}

	private validateJobImportData(jobImportData: ExcelJob[], restrictedId: number) {
		let jobValidationStatusMap: Map<number, JobWrapperValidationAttributes> = new Map<
			number,
			JobWrapperValidationAttributes
		>();
		jobImportData.forEach((job) => {
			if (this.isGeneralSkipRow(job, restrictedId)) return;
			let jobValidation: JobWrapperValidationAttributes = new JobWrapperValidationAttributes();
			jobValidation.hasValidPickUpAddresses = !!job.startLatitude;
			jobValidation.hasValidDeliveryAddresses = !!job.endLatitude;
			jobValidation.hasValidPickUpDates = !job.pickupDatesAreInvalid;
			jobValidation.hasValidDeliveryDates = !job.deliveryDatesAreInvalid;
			jobValidation.hasIdentifier = !!job.identifier;
			jobValidation.hasUniqueIdentifier = !this.jobService.getJobByIdentifier(job.identifier);
			if (job.assignedDriverLicence && !this.checkIfSetDriverInJobIsExisting(job)) {
				jobValidation.hasValidDriver = false;
			}
			if (job.assignedDriverLicence && !this.vehicleService.getVehicleByLicensePlate(job.assignedDriverLicence)) {
				jobValidation.hasValidVehicle = false;
			}

			if (
				job.requiredVehicle &&
				!jobsVehicleTypeMatchesExistingVehicleTypes(<VEHICLE_TYPE_TRANSLATED>job.requiredVehicle)
			) {
				jobValidation.hasValidVehicleType = false;
			}

			jobValidation.isValid =
				jobValidation.hasValidPickUpAddresses &&
				jobValidation.hasValidDeliveryAddresses &&
				jobValidation.hasIdentifier &&
				jobValidation.hasUniqueIdentifier &&
				jobValidation.hasValidVehicle &&
				jobValidation.hasValidVehicleType &&
				jobValidation.hasValidDriver &&
				jobValidation.hasValidPickUpDates &&
				jobValidation.hasValidDeliveryDates;
			jobValidationStatusMap.set(job.importId, jobValidation);
		});
		return jobValidationStatusMap;
	}

	private validateVehicleImportData(vehicleImportData: ImportedVehicleTemplate[], restrictedId) {
		let vehicleValidationStatusMap: Map<number, VehicleValidationAttributes> = new Map<
			number,
			VehicleValidationAttributes
		>();
		vehicleImportData.forEach((vehicle) => {
			if (this.isGeneralSkipRow(vehicle, restrictedId)) return;
			let vehicleValidation: VehicleValidationAttributes = new VehicleValidationAttributes();
			vehicleValidation.hasIdentifier = !!vehicle.name;
			vehicleValidation.hasLicensePlate = !!vehicle.licensePlate;
			vehicleValidation.isValid = vehicleValidation.hasIdentifier && vehicleValidation.hasLicensePlate;
			vehicleValidationStatusMap.set(vehicle.importId, vehicleValidation);
		});
		return vehicleValidationStatusMap;
	}

	private validateLocationImportData(locationImportData: ImportedSynonymTemplate[], restrictedId) {
		let locationValidationStatusMap: Map<number, LocationValidationAttributes> = new Map<
			number,
			LocationValidationAttributes
		>();
		locationImportData.forEach((location) => {
			if (this.isGeneralSkipRow(location, restrictedId)) return;
			let locationValidation: LocationValidationAttributes = new LocationValidationAttributes();
			locationValidation.hasIdentifier = !!location.code;
			locationValidation.hasValidAddress = !!location.latitude && !!location.zip;
			locationValidation.hasValidCode = location.code.length > 3;
			locationValidation.isValid =
				locationValidation.hasIdentifier && locationValidation.hasValidAddress && locationValidation.hasValidCode;
			locationValidationStatusMap.set(location.importId, locationValidation);
		});
		return locationValidationStatusMap;
	}

	private isSkipValidationOfEmptyRow(row) {
		return !values(row).some((x) => x !== undefined && x !== null && x !== '');
	}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Client } from '@flutaro/package/lib/model/Client';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { JobDialogData } from 'src/app/dialog/AppDialog';
import { TableAddComponent } from '../table/table.add.component';
import { JobDialogComponent } from './form/job.dialog.component';

@Component({
	selector: 'app-job-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobAddComponent extends TableAddComponent {
	@Input() clients: Client[] = [];
	@Input() drivers: Driver[];
	@Input() vehicles: Vehicle[];
	dialogComponent = JobDialogComponent;

	createDialogData(): JobDialogData {
		return new JobDialogData(
			this.userSettings,
			this.userProfile,
			this.companySettings,
			new JobWrapper(),
			this.clients,
			this.vehicles,
			this.drivers,
		);
	}

	configureDialogAppearance(dialogConfig: MatDialogConfig) {
		dialogConfig.panelClass = 'full-width-dialog';
	}
}

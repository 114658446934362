import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MenuComponentProvider } from './menu.component.provider';
import { FlutaroRelationsService } from '../data/data-services/data.relations.service';
import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { FlutaroAddressCorrectionService } from '../data/data-services/data.address.corrections.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { APP_MENU } from './MenuClasses';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { CompanyService } from '../company/company.service';
import { WebAuthService } from '../app/auth/web-auth.service';

@Component({
	selector: 'app-addresses-menu',
	templateUrl: './menu.addresses.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesMenuComponent implements OnInit {
	MENU = APP_MENU;
	constructor(
		public menuProvider: MenuComponentProvider,
		public authFb: WebAuthService,
		public relationService: FlutaroRelationsService,
		public locationService: FlutaroSynonymService,
		public correctionService: FlutaroAddressCorrectionService,
		public vehicleProvider: FlutaroVehicleService,
		public userSettings: UserSettingsProvider,
		public companyProvider: CompanyService,
	) {}

	ngOnInit() {
		this.menuProvider.initTabsAfterMenuChange([APP_MENU.LOCATIONS, APP_MENU.RELATIONS, APP_MENU.ADDRESS_CORRECTIONS]);
	}
}

import { Injectable } from '@angular/core';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { IntegrationsPoseidonService } from '../integrations/poseidon/integrations.poseidon.service';
import {
	getEmissionTypeForPoseidonRequest,
	getVehiclesTrailersCountForPoseidonRequest,
} from '../integrations/poseidon/PoseidonFunctions';
import { CostsDialogComponent } from './costs.dialog.component';
import { CostsDataService } from './costs.data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getAddedStopIndexInDestinations, updateJobsCostForPoseidonResponse } from './CostsDataFunctions';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { CostCalculationData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { PoseidonCostsRequest } from '@flutaro/package/lib/model/services/PoseidonClasses';
import { insertAt, to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import {
	addJobToRecalculatedJobsOnCostChange,
	postCostsResponseProcessing,
} from '@flutaro/package/lib/functions/costs/CostsCalculationFunctions';
import { lastValueFrom } from 'rxjs';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { handleCostCorrection } from './CostCorrectionFunctions';
import { isJobUnassigned } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import {
	calculateJobsCostCalculationFixedCosts,
	updateJobsProfitAndCostsForFixedCostChanges,
} from './CostCalculationFixedCosts';

@Injectable()
export class CostsCalculationProvider {
	constructor(
		private jobService: FlutaroJobService,
		private poseidon: IntegrationsPoseidonService,
		private costsData: CostsDataService,
		private userSettings: UserSettingsProvider,
		private auth: WebAuthService,
		private dialog: MatDialog,
	) {}

	async openCostDialog(costData: CostCalculationData): Promise<CostCalculationData> {
		console.debug(`openCostDialog called`);
		let jobCostsMatDialogConfig = new MatDialogConfig();
		jobCostsMatDialogConfig.data = costData;
		jobCostsMatDialogConfig.autoFocus = false;
		return lastValueFrom(this.dialog.open(CostsDialogComponent, jobCostsMatDialogConfig).afterClosed());
	}

	/**
	 * Currently only implemented in JobSidenav: Open Cost-Dialog and allow manipulation of jobCosts.totalCosts, jobCosts.addedCosts, jobCosts.changedEmptyKmAddress etc.
	 * @param job
	 */
	async openCostDialogAndStoreJobCostChanges(job: JobWrapper): Promise<JobWrapper> {
		console.debug(`openCostDialogAndStoreJobCostChanges called`);
		const jobsData = await this.openCostDialog(this.costsData.createJobCostData(job, job));
		if (!jobsData) {
			console.debug(`openCostDialogAndStoreJobCostChanges, action aborted by user. Resetting data`);
			return null;
		}
		await this.jobService.storeAndPublish(jobsData.job, job);
		return jobsData.job;
	}

	async calculateCostsWithoutDialog(costData: CostCalculationData): Promise<CostCalculationData> {
		console.debug(`calculateCostsWithoutDialog called`);
		await this.requestCostCalculationAndUpdateCostData(costData);
		return costData;
	}

	async calculatePreviewCosts(job: JobWrapper): Promise<CostCalculationData> {
		console.debug(`calculatePreviewCosts, called for job ${job.job.identifier}`);
		const previewCostData = this.costsData.createJobCostData(job, job);
		console.debug(
			`calculatePreviewCosts, costs for job ${job.job.identifier}: ${previewCostData.job.costCalculation.totalCosts}`,
		);
		return this.requestCostCalculationAndUpdateCostData(previewCostData);
	}

	async requestCostCalculationAndUpdateCostData(costData: CostCalculationData): Promise<CostCalculationData | null> {
		console.debug(`requestCostCalculationAndUpdateCostData, starting for job ${costData.job.job.identifier}`);
		const waypoints = [costData.job.costCalculation.emptyKmAddress].concat(
			costData.job.destinations.map((dest) => dest.position),
		);
		if (costData.job.costCalculation.addedStop)
			insertAt(
				waypoints,
				getAddedStopIndexInDestinations(costData.job.destinations) + 1,
				costData.job.costCalculation.addedStop,
			);
		const costRequest = new PoseidonCostsRequest(
			waypoints,
			costData.job.costCalculation.vehicleKmRate.toString(),
			costData.vehicle.axisNumber.toString(),
			getEmissionTypeForPoseidonRequest(costData),
			getVehiclesTrailersCountForPoseidonRequest(costData),
			costData.job.costCalculation.routingProfile,
		);
		if (costData.job.costCalculation.excludedCountries?.length)
			costRequest.setExcludeCountries(costData.job.costCalculation.excludedCountries);
		const [poseidonError, costResult] = await to(this.poseidon.getCostCalculationResult(costRequest));
		if (poseidonError || !costResult) {
			console.error(`requestCostCalculationAndUpdateCostData, poseidon error: ${JSON.stringify(poseidonError)}`);
			return null;
		}
		costData.routeShapePositions = costResult.routeShapePositions;
		updateJobsCostForPoseidonResponse(costData.job, this.auth.$userProfile.getValue().email, costResult);
		calculateJobsCostCalculationFixedCosts(costData);
		postCostsResponseProcessing(costData);
		this.handleCostCorrection(costData);
		console.debug(
			`requestCostCalculationAndUpdateCostData, before return: costs for job ${costData.job.job.identifier}: ${costData.job.costCalculation.totalCosts}`,
		);
		return costData;
	}

	recalculateJobsStartingOnSameDay(costData: CostCalculationData): void {
		// Start of check ups
		if (!costData.jobsStartingOnSameDay.length) return;
		console.debug(
			`recalculateJobsStartingOnSameDayFixedCosts, called for job ${costData.job.job.identifier} with ${costData.jobsStartingOnSameDay.length} jobsStartingOnSameDay and ${costData.recalculatedJobs.length} recalculatedJobs`,
		);
		const allJobsValid = costData.jobsStartingOnSameDay.every(
			(job) => this.costsData.createJobCostData(job, job).errors.isValid,
		);
		if (!allJobsValid) {
			console.debug(
				`recalculateJobsStartingOnSameDayFixedCosts, not all ${costData.jobsStartingOnSameDay.length} jobsStartingOnSameDay are valid after removal of job ${costData.job.job.identifier}. Aborting automatic recalculation`,
			);
			return;
		}
		// Start of fixedCosts calculation
		costData.jobsStartingOnSameDay.forEach((jobStartingSameDay) => {
			let jobStartingSameDayCostData = this.costsData.createJobCostData(jobStartingSameDay, jobStartingSameDay);
			updateJobsProfitAndCostsForFixedCostChanges(jobStartingSameDayCostData);
			addJobToRecalculatedJobsOnCostChange(jobStartingSameDayCostData, costData);
		});
		console.debug(
			`recalculateJobsStartingOnSameDayFixedCosts, result after recalculation for job ${costData.job.job.identifier}: ${costData.recalculatedJobs.length} recalculatedJobs `,
		);
	}

	/**
	 * Recalculate followingJob with excluded job to recalculate with changed emptyKmAddress (before change: job, after  change: job before job or vehicle.depotAddress
	 * @param costData
	 */
	async recalculateFollowingJob(costData: CostCalculationData): Promise<void> {
		if (!costData.followingJob) {
			console.debug(`recalculateFollowingJobAndUpdateInData, no following job. Aborting`);
			return;
		}
		console.debug(
			`recalculateFollowingJobAndUpdateInData, called for job ${costData.job.job.identifier} with costData.followingJob ${costData.followingJob?.job.identifier}`,
		);

		const followingJobCostData = this.costsData.createJobCostData(costData.followingJob, costData.followingJob);
		if (!followingJobCostData.errors.isValid) {
			console.debug(
				` recalculateFollowingJobAndUpdateInData, followingJob ${costData.followingJob.job.identifier} is invalid. Aborting`,
			);
			return;
		}
		await this.requestCostCalculationAndUpdateCostData(followingJobCostData);
		addJobToRecalculatedJobsOnCostChange(followingJobCostData, costData);
		console.debug(
			`recalculateFollowingJobAndUpdateInData, recalculatedJobs after re-calculation: ${costData.recalculatedJobs.length}`,
		);
	}

	async recalculateAndAddVehiclesJobsOnJobUnassigned(oldJob: JobWrapper): Promise<CostCalculationData> {
		console.debug(
			`recalculateVehiclesJobsCostsOnJobRemoveDispatch, called for job ${oldJob.job.identifier} before remove dispatch from vehicle ${oldJob.vehicleLicensePlate}/${oldJob.vehicleId}`,
		);
		if (isJobUnassigned(oldJob)) {
			throw new Error(`recalculateVehiclesJobsCostsOnJobRemoveDispatch, oldJobs driver is noDriver. Aborting`);
		}
		const oldJobCostData = this.costsData.createJobCostData(oldJob, oldJob);
		if (!oldJobCostData.errors.isValid) {
			console.debug(`recalculateVehiclesJobsCostsOnJobRemoveDispatch, oldJob / oldVehicle was invalid. Aborting`);
			return oldJobCostData;
		}
		this.recalculateJobsStartingOnSameDay(oldJobCostData);
		await this.recalculateFollowingJob(oldJobCostData);
		// Post processing - adjustment for minus positions
		if (
			oldJobCostData.recalculatedJobs.length &&
			this.userSettings.$userSettings.getValue().planningSettings.preventMinusPositionsInCostCalculation
		)
			handleCostCorrection(oldJobCostData, true);

		return oldJobCostData;
	}

	private handleCostCorrection(costData: CostCalculationData) {
		if (this.userSettings.$userSettings.getValue().planningSettings.preventMinusPositionsInCostCalculation) {
			handleCostCorrection(costData, false);
		} else if (costData.job.costCalculation.minusPositionCorrections?.length) {
			handleCostCorrection(costData, true);
		}
	}
}

import { JobPairing } from '@flutaro/package/lib/model/Positiondata';

export function createMapMarkerOptionsForDestination(isPickup: boolean) {
	const markerOptions: google.maps.MarkerOptions = {
		draggable: false,
		animation: google.maps.Animation.DROP,
		icon: isPickup
			? {
					url: 'assets/pictures/pickup.svg',
					scaledSize: new google.maps.Size(30, 40),
			  }
			: {
					url: 'assets/pictures/delivery.svg',
					scaledSize: new google.maps.Size(30, 40),
			  },
		zIndex: 10,
	};
	return markerOptions;
}

export function createMapMarkerOptionsEmptyKmAddress() {
	const markerOptions: google.maps.MarkerOptions = {
		draggable: false,
		animation: google.maps.Animation.DROP,
		icon: {
			url: 'assets/pictures/emptyDrive.svg',
			scaledSize: new google.maps.Size(30, 40),
		},
		zIndex: 9,
	};
	return markerOptions;
}

export function createMapMarkerOptionsAddedStop() {
	const markerOptions: google.maps.MarkerOptions = {
		draggable: false,
		animation: google.maps.Animation.DROP,
		icon: {
			url: 'assets/pictures/emptyDrive.svg',
			scaledSize: new google.maps.Size(30, 40),
		},
		zIndex: 8,
	};
	return markerOptions;
}

export function createMapMarkerOptionsForAppUserPosition(
	licensePlate: string,
	pairing: JobPairing,
	hideLabel?: boolean,
) {
	const markerOptions: google.maps.MarkerOptions = {
		draggable: false,
		animation: google.maps.Animation.DROP,
		icon: {
			url: pairing === JobPairing.ON ? 'assets/pictures/mapPinOnline.svg' : 'assets/pictures/mapPinOffline.svg',
			scaledSize: new google.maps.Size(hideLabel ? 20 : 30, hideLabel ? 30 : 40),
		},
		zIndex: hideLabel ? 6 : 7,
	};
	if (!hideLabel)
		markerOptions.label = {
			text: licensePlate,
			color: 'white',
			fontFamily: '',
			fontSize: '14px',
			fontWeight: '',
			className: 'flutaroMapPin', // Multiple space-separated CSS classes can be added. Default is no CSS class (an empty string). The font color, size, weight, and family can only be set via the other properties of MarkerLabel.
		};
	return markerOptions;
}

export function translatePositionActivity(activity: string) {
	switch (activity) {
		case 'still':
			return 'Steht';
		case 'in_vehicle':
			return 'Fährt';
		case 'on_bicycle':
			return 'Auf dem Fahrrad';
		default:
			return 'Unbekannt';
	}
}

export function translateJobPairingMode(pairing: string) {
	switch (pairing) {
		case 'ON':
			return 'Aktiv';
		case 'OFF':
			return 'Beendet';
		default:
			return 'Unbekannt';
	}
}

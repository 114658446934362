import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-company',
	templateUrl: './company.component.html',
	styleUrls: ['./company.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyComponent {
	constructor() {}
}

<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground locationDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">pin_drop</i>
		{{ 'ADDRESS_CORRECTION' | translate }}
		<span class="dialogToolbarObjectName">{{ correction.targetAddressLine }}</span>
	</h1>
</mat-toolbar>

<mat-dialog-content>
	<div class="layout-padding layout-margin" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
		<div fxFlex="100" fxLayout="column" fxLayoutGap="10px">
			<h4 class="dialogHeadlineContent">
				<i class="material-icons dialogIcon">assignment</i> {{ 'ADDRESS_DATA' | translate }}
			</h4>

			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field fxFlex="100">
					<input
						[(ngModel)]="correction.targetAddressLine"
						matInput
						name="code"
						ng-minlength="4"
						placeholder="Name"
						required
					/>
				</mat-form-field>
			</div>
		</div>
	</div>
</mat-dialog-content>
<app-flutaro-audit
	*ngIf="!isNew"
	[createdAt]="correction.createdAt"
	[createdBy]="correction.createdBy"
	[lastModifiedAt]="correction.lastModifiedAt"
	[lastModifiedBy]="correction.lastModifiedBy"
>
</app-flutaro-audit>
<!--SAVE AND CANCEL-->
<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
	<button (click)="onAbort()" class="importButton mat-raised" mat-button>
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button (click)="onSave()" class="importButtonDone mat-primary_flutaro" mat-button>
		<mat-icon>
			<i class="material-icons material-icons-white">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

<div class="custom-width" fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="10px">
	<mat-toolbar fxLayout="row" style="height: 35px">
		<div class="mat-toolbar-tools" fxLayout="row" fxLayoutAlign="start center">
			<mat-icon class="material-icons">add_location</mat-icon>
			<span>{{ 'JOB_DESTINATIONS' | translate }}</span>
		</div>
		<button
			(click)="addNewDestination()"
			[matTooltipPosition]="'below'"
			class="mat-fab mat-flut"
			id="addDestinationButton"
			mat-fab
			matTooltip="{{ 'JOB_DESTINATIONS_CREATE_TT' | translate }}"
			name="addDestination"
		>
			<mat-icon class="material-icons fab-icon-add">
				<i class="material-icons addIconDialogButton"> add </i>
			</mat-icon>
		</button>
	</mat-toolbar>
	<form [formGroup]="destinationForm">
		<mat-accordion>
			<div
				(cdkDropListDropped)="drop($event)"
				[cdkDropListSortPredicate]="canDropDestination"
				cdkDropList
				formArrayName="multiDestinationRows"
			>
				<div
					*ngFor="let destinationControl of getAllControlsOfDestinationFormGroup(); let destinationIndex = index"
					[formGroupName]="destinationIndex"
				>
					<mat-expansion-panel
						(closed)="setDestinationOpenState(false)"
						(opened)="setDestinationOpenState(true, destinationIndex)"
						[expanded]="destinationStep === destinationIndex"
						hideToggle
						class="marginBottom10"
					>
						<mat-expansion-panel-header [cdkDragData]="destinationControl" cdkDrag class="matPanelCustom">
							<mat-panel-title fxLayout="row" fxLayoutAlign=" center">
								<div
									[ngClass]="{
										pickupImage: destinationControl.value.locationType === 'PICKUP',
										deliveryImage: destinationControl.value.locationType === 'DELIVERY'
									}"
									class="jobDialogIconsPickupDelivery pickupImage"
								></div>
								<p class="destinationElementLabelText" fxFlex>
									{{
										destinationControl.value.position.latitude && destinationControl.value.position.longitude
											? destinationControl.value.position
											: ('JOB_DESTINATIONS_NEW' | translate)
									}}
								</p>
								<div *ngIf="destinationStep === destinationIndex" fxFlex="330px" fxLayout="row" fxLayoutGap="10px">
									<button
										(click)="prevDestination($event)"
										*ngIf="destinationIndex"
										class="destinationButtonsSmall"
										mat-flat-button
									>
										<mat-icon><i class="material-icons">arrow_back</i></mat-icon>
										{{ 'BACK' | translate }}
									</button>
									<button
										(click)="nextDestination($event)"
										*ngIf="
											!(destinationIndex + 1 === getCurrentNumberOfFormControls()) &&
											getCurrentNumberOfFormControls() > 1
										"
										class="destinationButtonsSmall"
										mat-flat-button
									>
										<mat-icon><i class="material-icons">arrow_forward</i></mat-icon>
										{{ 'NEXT' | translate }}
									</button>
									<button
										(click)="removeDestination(destinationIndex)"
										[disabled]="getCurrentNumberOfFormControls() < 3"
										class="destinationButtonsSmallDelete"
										mat-flat-button
									>
										<mat-icon><i class="material-icons">delete</i></mat-icon>
										{{ 'DELETE' | translate }}
									</button>
								</div>
								<span
									*ngIf="!(destinationControl.value | isValidDestination: destinationIndex:jobWrapper.destinations)"
									class="material-icons mat-error"
									>error_outline
								</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-divider></mat-divider>

						<div *ngIf="destinationIndex" class="jobDialogDistance marginTop10" fxLayout="row">
							<div class="jobDialogDistanceDescriptionBackground" fxLayout="row" fxLayoutAlign="end center">
								<p class="jobDialogDrivingTime">{{ 'DRIVING_TIME' | translate }}</p>
							</div>
							<div class="jobDialogValueBackground marginRight5">
								<p class="jobDialogDistanceValue">
									{{ destinationControl.value.timeInMinutes | minutesToDrivingTime }}
								</p>
							</div>
							<div class="jobDialogValueBackground marginLeft5">
								<p class="jobDialogDistanceValue">{{ destinationControl.value.distance | metersInKm }} km</p>
							</div>
							<div class="jobDialogDistanceDescriptionBackground" fxLayout="row" fxLayoutAlign="start center">
								<p class="jobDialogDrivingTime">{{ 'DISTANCE' | translate }}</p>
							</div>
						</div>
						<!-- address, actionType, actionTimeInMinutes and notes section -->
						<div
							fxLayout="row"
							fxLayout.sm="column"
							fxLayout.xs="column"
							fxLayoutGap="10px"
							fxLayoutAlign="start center"
							class="marginTop10"
						>
							<mat-form-field fxFlex="20">
								<mat-select formControlName="locationType" placeholder="{{ 'ACTION' | translate }}">
									<mat-option
										*ngFor="let locationType of locationTypes"
										[disabled]="destinationIndex === 0 || destinationIndex === getCurrentNumberOfFormControls() - 1"
										[value]="locationType"
									>
										{{ locationType | translate }}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<app-address-search
								(synonymChange)="updateDestinationsPosition(destinationIndex, $event)"
								[synonym]="destinationControl.value.position"
								fxFlex="50"
							>
							</app-address-search>

							<mat-form-field class="width60">
								<input
									formControlName="actionTimeInMinutes"
									matInput
									placeholder="{{ 'REGULATINGTIMEINMINUTES' | translate }}"
									type="number"
								/>
							</mat-form-field>

							<mat-form-field fxFlex="20">
								<input formControlName="description" matInput placeholder="{{ 'DESCRIPTION' | translate }}" />
							</mat-form-field>
						</div>
						<!-- end - name, locationType and description section -->

						<!-- Location Information -->
						<div
							*ngIf="destinationControl.value.position.__type === 'Location'"
							fxLayout="row"
							fxLayout.sm="column"
							fxLayout.xs="column"
							fxLayoutGap="10px"
						>
							<mat-form-field fxFlex="100" fxFlex.gt-sm="20">
								<input formControlName="name" matInput placeholder="Name" />
							</mat-form-field>
						</div>
						<!-- end of note and position-->

						<!-- References -->
						<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
							<mat-form-field fxFlex="100" fxFlex.gt-sm="100">
								<input formControlName="notes" matInput placeholder="{{ 'NOTES' | translate }}" />
							</mat-form-field>
						</div>

						<div
							fxLayout="row"
							fxLayoutAlign="start space-between"
							fxLayout.sm="column"
							fxLayout.xs="column"
							id="destinationDialogDatepicker"
						>
							<app-datetimepicker
								(ngModelChange)="updateDestinationsOnDateChange(destinationIndex, DestinationDateTypes.EARLIEST)"
								[required]="true"
								[type]="'datetime'"
								formControlName="earliestDate"
								fxFlex="100"
								fxFlex.gt-sm="50"
								placeholder="{{ 'EARLIEST_DATE' | translate }}"
							>
							</app-datetimepicker>
							<app-datetimepicker
								(ngModelChange)="updateDestinationsOnDateChange(destinationIndex, DestinationDateTypes.LATEST)"
								[min]="destinationControl.value | getDestinationsMinDate: destinationIndex:jobWrapper.destinations"
								[required]="true"
								[type]="'datetime'"
								formControlName="latestDate"
								fxFlex="100"
								fxFlex.gt-sm="50"
								placeholder="{{ 'LATEST_DATE' | translate }}"
							>
							</app-datetimepicker>
							<app-datetimepicker
								(ngModelChange)="updateDestinationsOnDateChange(destinationIndex, DestinationDateTypes.PLANNED)"
								[max]="destinationControl.value.latestDate"
								[min]="destinationControl.value | getDestinationsMinDate: destinationIndex:jobWrapper.destinations"
								[required]="true"
								[type]="'datetime'"
								formControlName="plannedDate"
								fxFlex="100"
								fxFlex.gt-sm="50"
								placeholder="{{ 'PLANNED_DATE' | translate }}"
							>
							</app-datetimepicker>
						</div>
						<!--end of earliest, latest and plaaned date-->
						<!-- References -->
						<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start end" fxLayoutGap="10px">
							<mat-form-field fxFlex="50">
								<mat-chip-list #ingoingReferences>
									<mat-chip
										(removed)="removeReference(ingReference, destinationControl, 'ingoingReferences')"
										*ngFor="let ingReference of destinationControl.get('ingoingReferences').value"
										[removable]="!isDisabled"
										[selectable]="!isDisabled"
									>
										{{ ingReference }}
										<mat-icon *ngIf="!isDisabled" matChipRemove>cancel</mat-icon>
									</mat-chip>
									<input
										(matChipInputTokenEnd)="addReference($event, destinationControl, 'ingoingReferences')"
										[matChipInputAddOnBlur]="true"
										[matChipInputFor]="ingoingReferences"
										[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										placeholder="{{ 'REFERENCE_INGOING' | translate }}"
									/>
								</mat-chip-list>
							</mat-form-field>

							<mat-form-field fxFlex="50">
								<mat-chip-list #outgoingReferences>
									<mat-chip
										(removed)="removeReference(outReference, destinationControl, 'outgoingReferences')"
										*ngFor="let outReference of destinationControl.get('outgoingReferences').value"
										[removable]="!isDisabled"
										[selectable]="!isDisabled"
									>
										{{ outReference }}
										<mat-icon *ngIf="!isDisabled" matChipRemove>cancel</mat-icon>
									</mat-chip>
									<input
										(matChipInputTokenEnd)="addReference($event, destinationControl, 'outgoingReferences')"
										[matChipInputAddOnBlur]="true"
										[matChipInputFor]="outgoingReferences"
										[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										placeholder="{{ 'REFERENCE_OUTGOING' | translate }}"
									/>
								</mat-chip-list>
							</mat-form-field>
						</div>
						<!--Cargo-->
						<app-cargo
							(cargoChange)="setCargo(destinationIndex, $event)"
							(isCargoInputChange)="setCargoInputStatus($event)"
							[cargo]="destinationControl.get('cargo').value"
							formControlName="cargo"
						>
						</app-cargo>
						<!--end of cargo-->
					</mat-expansion-panel>
				</div>
			</div>
		</mat-accordion>
	</form>
</div>

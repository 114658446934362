<div class="externalJob-dialog">
	<mat-toolbar fxLayoutAlign="center center">
		<h1 class="mat-toolbar-tools dialogHeadline">
			<i class="material-icons dialogIconBackground externalJobDialogIconBackground"></i>
			<i class="material-icons dialogIconToolbar">file_download</i>
			{{ 'JOB_IMPORT_MAIN' | translate }}
		</h1>
	</mat-toolbar>

	<mat-dialog-content>
		<app-external-job-import-component
			[externalJobs]="externalJobs"
			[companySettings]="dialogData.companySettings"
			[userProfile]="dialogData.userProfile"
			[vehicles]="dialogData.vehicles"
			[drivers]="dialogData.drivers"
			*ngIf="$isInitialized | async"
		></app-external-job-import-component>
	</mat-dialog-content>

	<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
		<button [mat-dialog-close]="false" class="importButton mat-raised" mat-button>
			<mat-icon>
				<i class="material-icons">close</i>
			</mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button (click)="onSubmit()" class="importButtonDone mat-primary_flutaro" mat-button>
			<mat-icon>
				<i class="material-icons">done</i>
			</mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</div>

import { ExcelJob } from '../import.excel.classes';
import { parseExcelImportDate } from '../ImportFunctions';
import { isAfter } from 'date-fns';
import { parseIntoVehicleTypeFromTranslation } from '@flutaro/package/lib/functions/VehicleFunctions';
import { VEHICLE_TYPE_TRANSLATED } from '@flutaro/package/lib/model/VehicleConstants';
import { isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';

export function checkTotalValidationStatus(currentValidationStatus: Map<number, any>) {
	let allDataValid: boolean = true;
	currentValidationStatus.forEach((value) => {
		if (!value.isValid) allDataValid = false;
	});
	return allDataValid;
}

export function jobsVehicleTypeMatchesExistingVehicleTypes(vehicleType: VEHICLE_TYPE_TRANSLATED) {
	return !!parseIntoVehicleTypeFromTranslation(vehicleType);
}

export function validateImportedJobTemplates(data: ExcelJob[]) {
	data.forEach((importJob) => {
		validateImportJobExcelDates(importJob);
	});
}

export function validateExcelJobRow(importJob: ExcelJob) {
	validateImportJobExcelDates(importJob);
}

export function validateImportJobExcelDates(importJob: ExcelJob) {
	const earliestStartDate = parseExcelImportDate(importJob.earliestStartDate);
	const earliestEndDate = parseExcelImportDate(importJob.earliestEndDate);
	const latestStartDate = importJob.latestStartDate ? parseExcelImportDate(importJob.latestStartDate) : null;
	const latestEndDate = importJob.latestEndDate ? parseExcelImportDate(importJob.latestEndDate) : null;

	importJob.pickupDatesAreInvalid =
		!isValidDate(earliestStartDate) || (!!latestStartDate && !isValidDate(latestStartDate));
	importJob.deliveryDatesAreInvalid = !isValidDate(earliestEndDate) || (!!latestEndDate && !isValidDate(latestEndDate));
	if (importJob.pickupDatesAreInvalid && importJob.deliveryDatesAreInvalid) {
		// Early return, no need to further investigate date ranges
		return;
	}
	if (latestStartDate && isAfter(earliestStartDate, latestStartDate)) importJob.pickupDatesAreInvalid = true;
	if (latestEndDate && isAfter(earliestEndDate, latestEndDate)) importJob.deliveryDatesAreInvalid = true;
	if (isAfter(earliestStartDate, earliestEndDate) && !isAfter(latestEndDate, earliestStartDate))
		importJob.deliveryDatesAreInvalid = true;
}

<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground contractorDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">work</i>
		{{ 'CONTRACTOR' | translate }}
		<span class="dialogToolbarObjectName">{{ contractor.name }}</span>
	</h1>
</mat-toolbar>
<form (ngSubmit)="onSubmit()" [formGroup]="contractorForm">
	<mat-dialog-content>
		<div
			class="layout-padding layout-margin"
			fxLayout="row"
			fxLayout.sm="column"
			fxLayout.xs="column"
			fxLayoutGap="10px"
		>
			<div class="contractorDialogMinDiv" fxFlex="100" fxFlex.gt-sm="50" fxLayout="column">
				<h4 class="dialogHeadlineContent">
					<i class="material-icons dialogIcon">assignment</i> {{ 'CONTRACTOR_DATA' | translate }}
				</h4>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input formControlName="name" matInput placeholder="Name" required type="text" />
						<div *ngIf="name.invalid && (name.dirty || name.touched)" class="requiredMessageDiv">
							<span class="requiredMessage"
								>* {{ 'CONTRACTOR_DATA' | translate }} <b>{{ 'REQUIRED_FIELD' | translate }}</b
								>.
							</span>
						</div>
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input
							formControlName="description"
							matInput
							placeholder="{{ 'DESCRIPTION' | translate }}"
							required
							type="text"
						/>
						<div *ngIf="description.invalid && (description.dirty || description.touched)" class="requiredMessageDiv">
							<span class="requiredMessage"
								>* {{ 'DESCRIPTION_IS' | translate }} <b>{{ 'REQUIRED_FIELD' | translate }}</b
								>.</span
							>
						</div>
					</mat-form-field>
				</div>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field fxFlex="50">
						<input
							[matTooltipPosition]="'below'"
							formControlName="contractorId"
							matInput
							matTooltip="{{ 'CONTRACTOR_ID_TT' | translate }}"
							placeholder="{{ 'CONTRACTOR_ID' | translate }}"
							type="text"
						/>
					</mat-form-field>
					<mat-form-field fxFlex="50">
						<input
							[matTooltipPosition]="'below'"
							formControlName="filterEmail"
							matInput
							matTooltip="{{ 'FILTER_EMAIL_TT' | translate }}"
							placeholder="{{ 'FILTER_EMAIL' | translate }}"
							type="email"
						/>
					</mat-form-field>
				</div>
				<mat-expansion-panel [expanded]="true" fxLayoutGap="10px">
					<mat-expansion-panel-header class="expansionPanelHeaderDark">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">playlist_add_check</i>
							{{ 'ASSIGNED_VEHICLE' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="marginTop10">
						<mat-form-field fxFlex="100">
							<mat-select
								formControlName="newAssignedVehicles"
								multiple
								placeholder="{{ 'ASSIGN_NEW_VEHICLES' | translate }}"
							>
								<mat-option *ngFor="let vehicle of vehiclesWithoutContractor" [value]="vehicle.backendId">
									{{ vehicle.licensePlate }} - {{ vehicle | getVehiclesDriverNameNow : dialogData.drivers }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-list class="dialogMdListContractor" fxFlex="100">
							<mat-list-item *ngFor="let vehicle of contractorVehicles" class="mat-2-line dialogMdListContractorItem">
								<i class="material-icons dialogMdListContractorIcon">local_shipping</i>
								<div class="mat-list-item-text" fxLayout="column">
									<h3 class="dialogMdListContractorTextH3" mat-line>{{ vehicle.licensePlate }}</h3>
									<h4 class="dialogMdListContractorTextH4" mat-line>
										{{ vehicle | getVehiclesDriverNameNow : dialogData.drivers }}
									</h4>
								</div>
								<button
									(click)="deleteVehicleFromContractor(vehicle.backendId)"
									class="deleteButtonContractorMdList mat-secondary"
									mat-button
									type="button"
								>
									<i class="dialogMdListContractorDeleteIcon material-icons">delete</i>
								</button>
								<mat-divider></mat-divider>
							</mat-list-item>
						</mat-list>
					</div>
				</mat-expansion-panel>
			</div>
			<mat-divider></mat-divider>
			<div class="contractorDialogMinDiv" fxFlex="100" fxFlex.gt-sm="50">
				<button
					(click)="addNewRate()"
					*ngIf="!isRateEdit"
					[matTooltipPosition]="'below'"
					class="mat-fab mat-flut"
					id="addContractorDialogRateButton"
					mat-fab
					matTooltip="{{ 'ADD_NEW_RATE_TT' | translate }}"
					name="addCost"
					type="button"
				>
					<mat-icon class="material-icons fab-icon-add">
						<i class="material-icons addIconDialogButton"> add </i>
					</mat-icon>
				</button>
				<mat-expansion-panel [expanded]="true" fxLayoutGap="10px">
					<mat-expansion-panel-header class="expansionPanelHeaderDark">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">euro_symbol</i>
							{{ 'COSTS_AND_DAILY_RATES' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>

					<mat-list class="dialogMdListContractor">
						<mat-list-item
							(click)="editRate(rate)"
							*ngFor="let rate of contractor.rates"
							class="mat-2-line dialogMdListContractorItem"
						>
							<i class="material-icons driverDialogAppointmentIcon"></i>
							<div class="mat-list-item-text" fxLayout="row">
								<h3 class="dialogMdListContractorTextH3" mat-line>{{ 'FROM_2' | translate }}:</h3>
								<h4 class="dialogMdListContractorTextH4" mat-line>{{ rate.date | relativeTime }}</h4>
							</div>
							<button (click)="deleteRate(rate)" class="deleteButtonContractorMdList mat-secondary" mat-button>
								<i class="dialogMdListContractorDeleteIcon material-icons">delete</i>
							</button>
						</mat-list-item>
					</mat-list>
				</mat-expansion-panel>
				<app-contractor-rate
					(rateSave)="saveContractorRate($event)"
					*ngIf="isRateEdit"
					[contractorRate]="currentRate"
					[isNew]="!currentRate.id"
				>
				</app-contractor-rate>
			</div>
		</div>
	</mat-dialog-content>
	<app-flutaro-audit
		[(tenant)]="contractor.tenant"
		[companySettings]="dialogData.companySettings"
		[createdAt]="contractor.createdAt"
		[createdBy]="contractor.createdBy"
		[lastModifiedAt]="contractor.lastModifiedAt"
		[lastModifiedBy]="contractor.lastModifiedBy"
		[userProfile]="dialogData.userProfile"
	>
	</app-flutaro-audit>
	<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
		<button (click)="onAbort()" [disabled]="isRateEdit" class="importButton mat-raised" mat-button type="button">
			<mat-icon>
				<i class="material-icons">close</i>
			</mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button
			[disabled]="contractorForm.invalid || isRateEdit || dialogData.userProfile.hasReadOnlyRole"
			class="importButtonDone mat-primary_flutaro"
			mat-button
			type="submit"
		>
			<mat-icon>
				<i class="material-icons">done</i>
			</mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</form>

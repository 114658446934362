import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { CompanyTenant, Tenant } from '@flutaro/package/lib/model/IElement';
import { getTenantForObjectFromCompanyTenants } from '@flutaro/package/lib/functions/CompanyFunctions';
import { AppBasicComponent } from '../app.components.basic.component';

@Component({
	selector: 'app-tenant',
	templateUrl: './tenant.component.html',
	styleUrls: ['./tenant.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantComponent extends AppBasicComponent implements OnChanges {
	@Input() tenant: Tenant | undefined;
	@Input() invalidTenantId: string;
	@Output() invalidTenantIdChange: EventEmitter<string> = new EventEmitter<string>();
	@Input() disabled: boolean;
	@Output() tenantChange: EventEmitter<Tenant> = new EventEmitter<Tenant>();
	@Input() isFlutaroHeader: boolean;
	@Input() isExternalJob: boolean;

	ngOnChanges(changes: SimpleChanges) {
		console.log(changes);
	}

	compareWithComponentContext = (obj1: any, obj2: any) => {
		// This way context of this in tenantComparisonFunction is TenantComponent and not MatSelect
		return this.tenantComparisonFunction(obj1, obj2);
	};

	tenantComparisonFunction(option: Tenant | string, value: Tenant): boolean {
		if (!option || (!value && !this.invalidTenantId)) return !value && !option;

		if (typeof option === 'string') {
			// Option is only string if invalidTenantId is set
			return option === this.invalidTenantId;
		} else if (!value) {
			console.error(
				`tenantComparisonFunction, value not existing after passing previous case checks. Something is wrong`,
			);
			return false;
		} else {
			return option.id === value.id;
		}
	}

	changeObjectsTenant(tenant: CompanyTenant | Tenant | number) {
		this.invalidTenantId = null;
		if (!tenant || typeof tenant === 'number') {
			this.tenantChange.emit(null);
			this.invalidTenantIdChange.emit(null);
			return;
		}
		this.tenantChange.emit(getTenantForObjectFromCompanyTenants(tenant));
		this.invalidTenantIdChange.emit(null);
	}
}

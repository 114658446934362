import { DriverStatistics, JobStatisticsSummary, JobSummary } from './Statistics';
import { endOfISOWeek, startOfISOWeek } from 'date-fns';
import { TimetableFilteredEntry } from '../timetable/data/TimetableFilterClasses';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';
import { EVENT_TYPE, FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { appRoundNumber } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { isJobUnassigned } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import {
	filterEventsStartingInTimeRange,
	filterJobsStartingInTimeRange,
} from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';

export function calculateStatisticsForTimeRange(
	jobs: JobWrapper[],
	intervalStart: Date,
	intervalEnd: Date,
): JobStatisticsSummary {
	const filteredJobs = jobs.filter((job) => !isJobUnassigned(job) && !!job.costCalculation);
	const intervalJobs = filterJobsStartingInTimeRange(filteredJobs, intervalStart, intervalEnd);
	let statistics = new JobStatisticsSummary();
	statistics.emptyDistance = appRoundNumber(
		intervalJobs.map((li) => li.costCalculation.emptyDistance).reduce((sum, val) => sum + val, 0),
	);
	statistics.emptyDriveCosts = appRoundNumber(
		intervalJobs.map((li) => li.costCalculation.emptyDriveCosts).reduce((sum, val) => sum + val, 0),
	);
	statistics.loadedDistance = appRoundNumber(
		intervalJobs.map((li) => li.costCalculation.loadedDistance).reduce((sum, val) => sum + val, 0),
	);
	statistics.loadedDriveCosts = appRoundNumber(
		intervalJobs.map((li) => li.costCalculation.loadedDriveCosts).reduce((sum, val) => sum + val, 0),
	);
	statistics.totalRevenue = appRoundNumber(intervalJobs.map((job) => job.revenue).reduce((sum, val) => sum + val, 0));
	statistics.totalCosts = appRoundNumber(
		intervalJobs.map((job) => job.costCalculation.totalCosts).reduce((sum, val) => sum + val, 0),
	);
	statistics.totalProfit = appRoundNumber(
		intervalJobs.map((job) => job.costCalculation.profit).reduce((sum, val) => sum + val, 0),
	);
	return statistics;
}

export function calculateJobsStatisticsForTimeRange(
	jobs: JobWrapper[],
	intervalStart: Date,
	intervalEnd: Date,
): JobSummary {
	const intervalJobs = filterJobsStartingInTimeRange(jobs, intervalStart, intervalEnd);
	let jobStatistics = new JobSummary();
	jobStatistics.total = intervalJobs.length;
	jobStatistics.unplanned = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.NEW).length;
	jobStatistics.planned = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.PLANNED).length;
	jobStatistics.sent = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.SENT).length;
	jobStatistics.received = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.RECEIVED).length;
	jobStatistics.done = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.DONE).length;
	jobStatistics.started = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.STARTED).length;
	jobStatistics.accepted = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.ACCEPTED).length;
	jobStatistics.declined = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.DECLINED).length;
	jobStatistics.aborted = intervalJobs.filter((jobWrapper) => jobWrapper.job.status === JobStatus.ABORTED).length;
	jobStatistics.withoutCosts = intervalJobs.filter(
		(jobWrapper) => !isJobUnassigned(jobWrapper) && jobWrapper.costCalculation.totalCosts < 1,
	).length;
	return jobStatistics;
}

export function getDriversWithMismatchingVehicleType(entries: TimetableFilteredEntry[]) {
	return entries.filter((entry) => {
		const vehicle = entry.vehicleEntry.vehicle;
		const jobs = entry.jobsEntry.map((job) => job.jobWrapper);
		if (vehicle.carType === VEHICLE_TYPE.MEGATRUCK) return false;
		return jobs.filter((job) => vehicle.carType !== job.job.requiredVehicle).length > 0;
	});
}

export function calculateStatisticsMapForDrivers(
	drivers: Driver[],
	events: Map<string, FlutaroEvent[]>,
	intervalStart: Date,
	intervalEnd: Date,
): Map<string, DriverStatistics> {
	const statisticsArray = drivers.map((driver) =>
		calculateDriverStatistics(driver.backendId, events.get(driver.backendId), intervalStart, intervalEnd),
	);
	return new Map(statisticsArray.map((obj): [string, any] => [obj.id, obj]));
}

/**
 * Calculates DriverStatistics based on Events for day and week (Mo-Sun)
 * @param driverId
 * @param events
 * @param intervalStart
 * @param intervalEnd
 */
export function calculateDriverStatistics(
	driverId: string,
	events: FlutaroEvent[],
	intervalStart: Date,
	intervalEnd: Date,
): DriverStatistics {
	let driverStatistic = new DriverStatistics(driverId);
	if (!events || events.length === 0) return driverStatistic;
	const calculationEvents = events.filter(
		(event) => event.type === EVENT_TYPE.EMPTY_DRIVE || event.type === EVENT_TYPE.LOADED_DRIVE,
	);
	if (calculationEvents.length === 0) return driverStatistic;
	const dayIntervalEvents = filterEventsStartingInTimeRange(calculationEvents, intervalStart, intervalEnd);
	const weekIntervalEvents = filterEventsStartingInTimeRange(
		calculationEvents,
		startOfISOWeek(intervalStart),
		endOfISOWeek(intervalStart),
	);
	if (weekIntervalEvents.length === 0) return driverStatistic;

	if (dayIntervalEvents.length > 0)
		driverStatistic.dailyDrivingTime =
			dayIntervalEvents.length > 1
				? dayIntervalEvents
						.map((event) => event.duration)
						.reduce((previousValue, currentValue) => previousValue + currentValue)
				: dayIntervalEvents[0].duration;
	driverStatistic.weekDrivingTime =
		weekIntervalEvents.length > 1
			? weekIntervalEvents
					.map((event) => event.duration)
					.reduce((previousValue, currentValue) => previousValue + currentValue)
			: weekIntervalEvents[0].duration;
	return driverStatistic;
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import { cleanManualOverwriteFromJobCosts, getAddedStopIndexInDestinations } from './CostsDataFunctions';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { BehaviorSubject } from 'rxjs';
import { calculateProfit } from '@flutaro/package/lib/functions/costs/CostsCalculationFunctions';
import { appRoundNumber } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { APP_ROUTING_PROFILE } from '@flutaro/package/lib/model/AppRoutingClasses';
import { CountryToISO2 } from '@flutaro/package/lib/model/LocationConstants';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { CostsCalculationProvider } from './costs.calculation.provider';
import { FlutaroLatLng } from '@flutaro/package/lib/model/Positiondata';
import { CostCalculationData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { calculateDoubleFixedCosts } from './CostCalculationFixedCosts';

@Component({
	templateUrl: './costs.dialog.component.html',
	styleUrls: ['./costs.dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostsDialogComponent implements OnInit {
	// User custom settings
	activateAddStop: boolean = false;
	applyDoubleFixedCosts: boolean;
	isManualCostMode: boolean = false;
	// Dialog internal only
	jobBackup: JobWrapper; // State of job after latest calculation to check if recalculation option should be shown to user; updated on recalculation by user
	emptyKmAddressBackup: Synonym; // used only to determine if emptyKmAddress is a user changed address and therefor different from the automatically determined one
	emptyKmAddressIsHome: boolean;
	$showCostRecalculateOption: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	$showCostsCalculating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	addedStopDestinationIndex: number; // Show added stop before first Delivery
	$polylinePath: BehaviorSubject<FlutaroLatLng[]> = new BehaviorSubject<FlutaroLatLng[]>([]);
	// Dialog States
	isInHasAddedStopIncludedCalculatedState: boolean = false;
	disableDialogSaveStatesList: Set<string> = new Set([]);

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: CostCalculationData,
		public dialogRef: MatDialogRef<CostsDialogComponent>,
		public costProvider: CostsCalculationProvider,
	) {}

	ngOnInit() {
		this.setCurrentEmptyDistanceJobIsBase();
		this.initJobCostCalculationValues();
		if (!this.dialogData.errors.isValid) {
			return;
		}
		const isNewCalculation = !this.dialogData.job.costCalculation.latestCalculationDate;
		this.setInitialManualOverwrite(isNewCalculation);
		if (isNewCalculation) {
			// Job hasnt been calculated yet, new dispatch - calculate costs on dialog open
			this.calculateCosts();
		}
	}

	updateJobBackup() {
		console.log(`updateJobBackup`);
		this.jobBackup = cloneDeep(this.dialogData.job);
	}

	initJobCostCalculationValues() {
		if (this.dialogData.job.costCalculation.addedFixCosts) this.applyDoubleFixedCosts = true;
		this.initAddedStop();
		this.initEmptyKmAddress();
		this.updateJobBackup();
	}

	initEmptyKmAddress() {
		// TODO: this should be determined by original "homeAddress" copy and comparing current jobs emptyKmAddress with homeAddress of vehicle or driver
		this.emptyKmAddressBackup = cloneDeep(this.dialogData.job.costCalculation.emptyKmAddress);
		this.setCurrentEmptyDistanceJobIsBase();
	}

	initAddedStop() {
		this.addedStopDestinationIndex = getAddedStopIndexInDestinations(this.dialogData.job.destinations);
		if (this.dialogData.job.costCalculation.addedStop?.latitude) {
			this.activateAddStop = true;
			this.isInHasAddedStopIncludedCalculatedState = true;
		}
	}

	onSave() {
		this.dialogRef.close(this.dialogData);
	}

	onAbort() {
		this.dialogRef.close(null);
	}

	updateJobsRoutingProfile(routingProfile: APP_ROUTING_PROFILE) {
		this.dialogData.job.costCalculation.routingProfile = routingProfile;
		this.updateShowCostRecalculationOption();
	}

	updateJobsExcludedCountries(excludedCountries: CountryToISO2[] | undefined) {
		this.dialogData.job.costCalculation.excludedCountries = excludedCountries;
		this.updateShowCostRecalculationOption();
	}

	updateShowCostRecalculationOption() {
		const isChangedRoutingProfile =
			this.jobBackup.costCalculation.routingProfile !== this.dialogData.job.costCalculation.routingProfile;
		const isChangedExcludedCountries =
			this.jobBackup.costCalculation.excludedCountries?.length !==
			this.dialogData.job.costCalculation.excludedCountries?.length;
		this.$showCostRecalculateOption.next(isChangedRoutingProfile || isChangedExcludedCountries);
	}

	isRecalculateEnabled(): boolean {
		return this.$showCostRecalculateOption.getValue();
	}

	isRefreshDisabled(): boolean {
		return this.$showCostsCalculating.getValue() || this.disableDialogSaveStatesList.has('INVALID_ADDED_STOP');
	}

	changeTotalCostsManually(manualCosts: number) {
		if (!this.dialogData.job.costCalculation.originalTotalCosts)
			this.dialogData.job.costCalculation.originalTotalCosts = this.dialogData.job.costCalculation.totalCosts;
		if (manualCosts === this.dialogData.job.costCalculation.originalTotalCosts) {
			cleanManualOverwriteFromJobCosts(this.dialogData.job.costCalculation);
		} else {
			this.dialogData.job.costCalculation.isManualOverwrite = true;
			if (!this.dialogData.recalculatedJobs.length) {
				this.dialogData.addRecalculatedJob(this.dialogData.job);
			}
		}
	}

	setInitialManualOverwrite(isNewCalculation: boolean) {
		this.isManualCostMode = isNewCalculation
			? this.dialogData.userSettings.planningSettings.activateManualJobCostOnDrop
			: this.dialogData.job.costCalculation.isManualOverwrite;
	}

	changeManualCostsToggle(isManualCostMode: boolean) {
		if (!isManualCostMode && this.dialogData.job.costCalculation.isManualOverwrite) {
			this.dialogData.job.costCalculation.totalCosts = this.dialogData.job.costCalculation.originalTotalCosts;
		}
	}

	async calculateCosts() {
		this.$showCostsCalculating.next(true);
		const successfulCalculation = await this.costProvider.requestCostCalculationAndUpdateCostData(this.dialogData);
		if (!successfulCalculation) {
			console.log(`calculateCosts, no route found - resetting routeShapePositions`);
			this.dialogData.routeShapePositions = [];
		}
		this.dialogData.errors.updateNoRouteFound(!successfulCalculation);
		this.isInHasAddedStopIncludedCalculatedState = !!this.dialogData.job.costCalculation.addedStop;
		this.$polylinePath.next(this.dialogData.routeShapePositions);
		this.$showCostsCalculating.next(false);
	}

	handleManualCostChange(costs: number) {
		this.dialogData.job.costCalculation.profit = calculateProfit(this.dialogData.job.revenue, costs);
		this.changeTotalCostsManually(costs);
	}

	finalFormatManualCostNumber() {
		if (!this.dialogData.job.costCalculation.isManualOverwrite) return;
		this.dialogData.job.costCalculation.totalCosts = appRoundNumber(this.dialogData.job.costCalculation.totalCosts);
		this.handleManualCostChange(this.dialogData.job.costCalculation.totalCosts);
	}

	toggleActivateAddStop() {
		this.activateAddStop = !this.activateAddStop;
		if (this.activateAddStop && !this.dialogData.job.costCalculation.addedStop) {
			this.dialogData.job.costCalculation.addedStop = new Synonym();
		} else {
			if (this.isInHasAddedStopIncludedCalculatedState) this.$showCostRecalculateOption.next(true);
			this.dialogData.job.costCalculation.addedStop = null;
		}
	}

	changeDoubleFixedCosts(applyDoubleFixedCosts: boolean) {
		calculateDoubleFixedCosts(applyDoubleFixedCosts, this.dialogData.job);
	}

	clearAddedStopAddress() {
		this.dialogData.job.costCalculation.addedStop = new Synonym();
		this.$showCostRecalculateOption.next(false);
	}

	addStopAddressesChanged(addStopLocation: Synonym) {
		console.debug(`addStopAddressesChanged, called for addStopAddressesChanged ${addStopLocation.toString()}`);
		if (!addStopLocation.latitude && !this.dialogData.job.costCalculation.addedStop) {
			this.disableDialogSaveStatesList.add('INVALID_ADDED_STOP');
			this.$showCostRecalculateOption.next(false);
		} else {
			this.$showCostRecalculateOption.next(true);
			this.disableDialogSaveStatesList.delete('INVALID_ADDED_STOP');
		}
		this.dialogData.job.costCalculation.addedStop = addStopLocation;
	}

	changeEmptyKmAddress(newEmptyKmAddress: Synonym) {
		this.dialogData.job.costCalculation.emptyKmAddress = newEmptyKmAddress;
		this.dialogData.job.costCalculation.isCustomEmptyKmAddress =
			this.emptyKmAddressBackup.toString() !== this.dialogData.job.costCalculation.emptyKmAddress.toString();
		if (
			this.dialogData.job.costCalculation.emptyKmAddress.toString() !==
			this.jobBackup.costCalculation.emptyKmAddress.toString()
		)
			this.$showCostRecalculateOption.next(true);
		this.setCurrentEmptyDistanceJobIsBase();
	}

	setCurrentEmptyDistanceJobIsBase() {
		this.emptyKmAddressIsHome =
			this.dialogData.job.costCalculation.emptyKmAddress.toString() ===
			(this.dialogData.driver && !this.dialogData.vehicle.isDepotAddressBased
				? this.dialogData.driver.homeAddress?.toString()
				: this.dialogData.vehicle.depotAddress?.toString());
	}

	recalculateCosts() {
		this.$showCostRecalculateOption.next(false);
		this.calculateCosts();
	}
}

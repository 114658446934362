import { Injectable } from '@angular/core';
import { AdvanceSearchOption, FlutaroSearchService } from '../../menu/toolbar/search.service';
import { BehaviorSubject } from 'rxjs';
import { FlutaroJobService } from '../../data/data-services/data.job.service';
import { TimeTable } from '../TimeTable';
import { FlutaroRelationsService } from '../../data/data-services/data.relations.service';
import { TimetableComponentProvider } from './timetable.component.service';
import { UserSettingsProvider } from '../../settings/settings.user.provider';
import { WebAuthService } from '../../app/auth/web-auth.service';
import { FlutaroDriverService } from '../../data/data-services/data.driver.service';
import {
	FilterDriverEntryForUninformedJobs,
	filterEventsForTimetableTimerange,
	filterForOverlappingTimetableEntries,
	getTimetableEntriesVehiclesWithoutJobs,
	getVehiclesWithStatus,
} from './TimetableFilterFunctions';

import { TimetableFilterStoreProvider } from './timetable.filter.store.provider';
import {
	JobFilterStatus,
	TimetableFilterStore,
	TimetableFilteredEntry,
	VehicleFilterStatus,
} from './TimetableFilterClasses';
import { TimetableNoteProvider } from '../notes/timetable-note-provider.service';
import {
	returnStyledTimetableEntries,
	setJobsTimetableColorByRelation,
	setTimetableEntriesOverlappingStyles,
	setTimetableEventsStyles,
} from './TimetableStyleFunctions';
import {
	getInvalidJobs,
	resetTimetableEntryActionAttributesAfterJobValidations,
	updateTimetableEntryActionAttributesForJobValidations,
} from './TimetableValidationFunctions';
import { getISODay } from 'date-fns';
import { getDriversWithMismatchingVehicleType } from '../../statistics/statistics.functions';
import { EventService } from '../../event/event.service';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { isDateInInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { APP_MENU } from '@flutaro/package/lib/model/WebAppClasses';
import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';
import { ContractorDataProvider } from '../../contractor/contractor.data.provider';
import { appSortByMultiple, replaceElementInArrayById } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { CompanyService } from '../../company/company.service';
import { setTimetableDriverEntryAttributes } from './TimetableDriverFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { setJobEntryStyles } from './TimetableJobFunctions';
import { setTimetableTimewindowEntry } from './TimetableDataFunctions';
import { RelationStatusFilterChange, TimetableFilterType, TimetableVehicleStatus } from '../TimetableClasses';
import { getVehiclesTimetableDisplayedDriver, isTruckActivatedForTimetableDate } from './TimetableVehicleFunctions';
import { isTruck } from '@flutaro/package/lib/functions/VehicleFunctions';
import {
	filterForAssignedJobs,
	filterJobsForStatus,
	filterJobsWithStartOnReferenceDate,
	getJobsInTimetableTimeRange,
} from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';
import { isJobUnassigned } from '@flutaro/package/lib/functions/job/JobDataFunctions';

@Injectable()
export class FlutaroTimetableFilterService {
	$filteredDriverJobsMap: BehaviorSubject<TimetableFilteredEntry[]> = new BehaviorSubject([]);
	// Jobs-Array filtered with all possible Filters
	$currentTimetableJobsFiltered: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]); // TODO: improve to represent latest timetable values without manual calculation
	$currentJobsToSend: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]);
	$referenceDateUnassignedJobs: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]);
	$unassignedJobs: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]);
	$referenceDateFilteredInvalidJobs: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]);
	// Filter
	showAllVehicles: boolean = true;
	showSentJobs: boolean = true;
	showDoneJobs: boolean = true;
	// State if a Driver-Filter is active
	filterStatusStore: TimetableFilterStore = new TimetableFilterStore(); // job and driver Filter Status-Store
	advanceSearchOption: AdvanceSearchOption = new AdvanceSearchOption();
	// Application States
	currentTab: APP_MENU;
	replacementJob: JobWrapper | undefined; // If set all timetableData-create executions will use this jobs version instead of the jobsServices

	constructor(
		private searchService: FlutaroSearchService,
		private jobService: FlutaroJobService,
		private relationService: FlutaroRelationsService,
		private eventService: EventService,
		private timetableProvider: TimetableComponentProvider,
		private userProvider: UserSettingsProvider,
		private authProvider: WebAuthService,
		private driverProvider: FlutaroDriverService,
		private vehicleProvider: FlutaroVehicleService,
		private filterStore: TimetableFilterStoreProvider,
		private timetableNoteProvider: TimetableNoteProvider,
		private contractorProvider: ContractorDataProvider,
		private companySettingsProvider: CompanyService,
	) {}

	setReplacementJob(job: JobWrapper) {
		this.replacementJob = job;
		this.createFilteredTimetableMap();
	}

	clearReplacementJob(): void {
		if (!this.replacementJob) return;
		this.replacementJob = undefined;
		this.createFilteredTimetableMap();
	}

	/**
	 * This function handles the Filter for a Driver-Status from the Info-Sidenav
	 * @param driverStatus
	 * @param tempFilteredMap
	 */
	private static filterDataForDriverStatus(driverStatus: VehicleFilterStatus | string, tempFilteredMap) {
		switch (driverStatus) {
			case 'Alle ok':
				break;
			case 'Uninformiert':
				tempFilteredMap = FilterDriverEntryForUninformedJobs(tempFilteredMap);
				tempFilteredMap = getTimetableEntriesVehiclesWithoutJobs(tempFilteredMap);
				break;
			case VehicleFilterStatus.VEHICLE_WITH_OVERLAPPING:
				tempFilteredMap = filterForOverlappingTimetableEntries(tempFilteredMap);
				tempFilteredMap = getTimetableEntriesVehiclesWithoutJobs(tempFilteredMap);
				break;
			case 'Erledigt':
				tempFilteredMap = getTimetableEntriesVehiclesWithoutJobs(tempFilteredMap);
				break;
			case VehicleFilterStatus.VEHICLE_WITH_STATUS:
				tempFilteredMap = getVehiclesWithStatus(tempFilteredMap);
				break;
			case VehicleFilterStatus.VEHICLETYPE_MISMATCHING:
				tempFilteredMap = getDriversWithMismatchingVehicleType(tempFilteredMap);
				break;
			default:
				console.log('Didnt find driver Status from driverStatus-PieChart. Couldnt use filter.');
				break;
		}
		return tempFilteredMap;
	}

	/**
	 * Creates new Timetable-Data. Filters the to be calculated Jobs for Date and Search-Query
	 * If excluseDrivers ID-Array is present, only entries for drivers with matching backendId will be created.
	 */
	public createFilteredTimetableMap() {
		const jobs = this.jobService.getData();
		const timetable = this.timetableProvider.$timetable.getValue();
		const start = timetable.startDate;
		const end = timetable.endDate;
		// Jobs Data preparation
		const timetableJobs = getJobsInTimetableTimeRange(jobs, start, end);
		if (this.replacementJob) replaceElementInArrayById(timetableJobs, this.replacementJob);
		const filteredJobs = this.filterJobsForTimetable(timetableJobs);
		this.createTimetableNoDriverJobs(timetableJobs, timetable);
		this.$currentTimetableJobsFiltered.next(filteredJobs);

		const companySettings = this.companySettingsProvider.$companySettings.getValue();
		// Vehicle Data preparation
		const filteredVehicles = this.filterVehiclesForTimetableCalculation(
			this.vehicleProvider.getData(),
			companySettings,
			timetable,
		);
		if (this.authProvider.getUserProfile().hasAppActivatedRole) this.createJobsToSent();
		setTimeout(() => {
			this.$filteredDriverJobsMap.next(
				returnStyledTimetableEntries(
					appSortByMultiple(
						this.createFilteredTimetableMapForJobs(
							timetable,
							filteredJobs,
							this.showAllVehicles,
							filteredVehicles,
							companySettings,
							this.userProvider.$userSettings.getValue(),
						),
						this.userProvider.$userSettings.getValue().planningSettings.timetableDataSortings,
					),
				),
			);
		});
	}

	/**
	 * Validates each MapEntry for Job requirements and updates blocked attributes on mismatching requirements.
	 * Current Validations in place:
	 *  1. Jobs with Mega-Truck requirement cant be assigned to (regular) Trucks
	 * @param job
	 * @param companySettings
	 */
	updateTimetableMapOnDragForJobValidations(job: JobWrapper, companySettings: CompanySettings) {
		this.$filteredDriverJobsMap.next(
			updateTimetableEntryActionAttributesForJobValidations(
				job,
				this.$filteredDriverJobsMap.getValue(),
				companySettings,
			),
		);
	}

	resetTimetableMapValidationsOnDragEnd() {
		this.$filteredDriverJobsMap.next(
			resetTimetableEntryActionAttributesAfterJobValidations(this.$filteredDriverJobsMap.getValue()),
		);
	}

	createJobsToSent() {
		this.$currentJobsToSend.next(
			this.$currentTimetableJobsFiltered
				.getValue()
				.filter((job) => !job.appSettings.inSyncWithApp && job.backendId !== this.replacementJob?.backendId),
		);
	}

	createTimetableNoDriverJobs(jobs: JobWrapper[], timetable: TimeTable) {
		const noDriverJobs = jobs.filter((job) => isJobUnassigned(job));
		const filteredJobs = this.filterStatusStore.jobIds.length
			? noDriverJobs.filter((job) => this.filterStatusStore.jobIds.indexOf(job.backendId) > -1)
			: noDriverJobs;
		this.$unassignedJobs.next(filteredJobs);
		const referenceDateFilteredJobs = filterJobsWithStartOnReferenceDate(
			filteredJobs,
			timetable.selectedDate,
			timetable.isSundayTable,
		);
		this.$referenceDateUnassignedJobs.next(referenceDateFilteredJobs);
	}

	updateFilterStore(filterStoreUpdate: RelationStatusFilterChange) {
		this.filterStatusStore[filterStoreUpdate.type === TimetableFilterType.vehicle ? 'vehicleIds' : 'jobIds'] =
			filterStoreUpdate.elementIds.length ? filterStoreUpdate.elementIds.slice() : [];
		this.filterStatusStore.updateIsActivatedFilterState();
		if (!this.filterStatusStore.$isActiveFilterMode.getValue()) {
			this.resetFilterStore(true);
		}
	}

	resetFilterStore(force?: boolean) {
		if (!this.filterStatusStore.$isActiveFilterMode.getValue() && this.showAllVehicles && !force) return;
		this.showAllVehicles = true;
		this.filterStore.resetFilters();
		this.filterStatusStore = this.filterStore.activeFilters.getValue();
	}

	setTimetableEntryStyles(
		entry: TimetableFilteredEntry,
		jobs: JobWrapper[],
		timetable: TimeTable,
		timetableWidthInPxl: number,
		userSettings: UserSettings,
	) {
		if (userSettings.planningSettings.customJobDisplay) {
			entry.jobsEntry.forEach((jobEntry) => {
				jobEntry.color = jobs[0].jobColor;
			});
		} else {
			setJobsTimetableColorByRelation(entry.jobsEntry, this.relationService.getData());
		}
		setJobEntryStyles(entry.jobsEntry, timetable, timetableWidthInPxl, userSettings);
		setTimetableEntriesOverlappingStyles(entry);
		setTimetableEventsStyles(entry.eventsEntry, timetable);
		setTimetableTimewindowEntry(entry, timetable);
	}

	filterJobsForTimetable(jobs: JobWrapper[]): JobWrapper[] {
		return this.filterJobsForInfoSidenavStatus(
			this.filterForMissionControlCheckboxFilter(
				filterForAssignedJobs(filterJobsForStatus(jobs, this.filterStatusStore.jobStatus)),
			),
			this.filterStatusStore.appJobStatus,
		);
	}

	/**
	 * Filters data for Job-Status
	 * @param status
	 */
	public filterViewDataForJobStatus(status: JobStatus) {
		this.filterStatusStore.setJobStatusFilter(status);
		this.showAllVehicles = false;
		this.createFilteredTimetableMap();
	}

	/**
	 * Filters data for Flutaro-Job-Status exclusively by infoSidenav (withoutCosts, invalid)
	 * @param status
	 */
	public filterViewDataForAppJobStatus(status: JobFilterStatus) {
		this.filterStatusStore.setAppJobStatusFilter(status);
		this.showAllVehicles = false;
		this.createFilteredTimetableMap();
	}

	/**
	 * This function filters data for  Job-Status from the Info-Sidenav
	 * @param status
	 */
	public filterViewDataForVehicleStatus(status: VehicleFilterStatus) {
		this.filterStatusStore.setVehicleStatus(status);
		this.showAllVehicles = false;
		this.createFilteredTimetableMap();
	}

	filterJobsForInfoSidenavStatus(jobs: JobWrapper[], status: JobFilterStatus | undefined) {
		if (!status) return jobs;
		switch (status) {
			case JobFilterStatus.WITHOUT_COSTS:
				jobs = this.filterForJobsWithoutCosts(jobs);
				break;
			case JobFilterStatus.INVALID:
				break;
			default:
				break;
		}
		return jobs;
	}

	filterForJobsWithoutCosts(jobs: JobWrapper[]) {
		return jobs.filter((job) => !job.costCalculation.totalCosts);
	}

	protected filterForMissionControlCheckboxFilter(jobs: JobWrapper[]) {
		if (this.showSentJobs && this.showDoneJobs) {
			return jobs;
		}
		if (!this.showSentJobs) {
			jobs = filterJobsForStatus(jobs, JobStatus.SENT, true);
		}
		if (!this.showDoneJobs) {
			jobs = filterJobsForStatus(jobs, JobStatus.DONE, true);
		}
		return jobs;
	}

	private createFilteredTimetableMapForJobs(
		timetable: TimeTable,
		jobsArray: JobWrapper[],
		showAllVehicles: boolean,
		filteredVehicles: Vehicle[],
		companySettings: CompanySettings,
		userSettings: UserSettings,
	): TimetableFilteredEntry[] {
		let tempFilteredMap: TimetableFilteredEntry[] = [];
		let referenceDateFilteredInvalidJobs = [];
		const timetableWidthInPxl = timetable.isDayTimetable
			? document.getElementById('timetableSection').offsetWidth
			: timetable.width;
		console.log('createFilteredTimetableMapForJobs');
		filteredVehicles.forEach((vehicle: Vehicle) => {
			const vehiclesContractor = this.contractorProvider.getContractorForVehicle(vehicle.backendId);
			const trailerEntries = this.vehicleProvider.getTrailerEntriesForVehicleByDate(vehicle, timetable.selectedDate); // TODO: delete me for vehicle.trailerEntries and stateless functions
			let vehiclesJobs = jobsArray.filter((job) => job.vehicleId === vehicle.backendId);
			let searchAppliesToVehicle: boolean = false;
			// Filters based on conditions
			// TODO: put me somewhere  better fitting
			if (
				!this.searchService.listOfAttributeToExclude.includes('homeAddress') &&
				userSettings.searchSettings.excludeDriversHomeaddress
			) {
				this.searchService.listOfAttributeToExclude.push('homeAddress');
			} else {
				if (
					!userSettings.searchSettings.excludeDriversHomeaddress &&
					this.searchService.listOfAttributeToExclude.includes('homeAddress')
				) {
					this.searchService.listOfAttributeToExclude.splice(
						this.searchService.listOfAttributeToExclude.indexOf('homeAddress'),
						1,
					);
				}
			}

			if (
				!this.searchService.listOfAttributeToExclude.includes('location') &&
				userSettings.searchSettings.excludeAppointmentAddress
			) {
				this.searchService.listOfAttributeToExclude.push('location');
			} else {
				if (
					!userSettings.searchSettings.excludeAppointmentAddress &&
					this.searchService.listOfAttributeToExclude.includes('location')
				)
					this.searchService.listOfAttributeToExclude.splice(
						this.searchService.listOfAttributeToExclude.indexOf('location'),
						1,
					);
			}
			this.advanceSearchOption.showAllJobsOfDriver = userSettings.searchSettings.showAllJobsOfDriver;
			this.advanceSearchOption.showJobsWithActionOnChoosenDay =
				userSettings.searchSettings.showJobsWithActionOnChoosenDay;
			this.advanceSearchOption.searchScope =
				userSettings.searchSettings.searchScope === null ? false : userSettings.searchSettings.searchScope;
			this.advanceSearchOption.currentTab = this.currentTab;
			this.advanceSearchOption.referenceDate = timetable.selectedDate;
			const driver = getVehiclesTimetableDisplayedDriver(vehicle, this.driverProvider.getData(), timetable);
			if (this.searchService.query.filter) {
				searchAppliesToVehicle = this.searchService.isSearchMatchingVehicleData(
					vehicle,
					vehiclesContractor,
					trailerEntries?.map((trailer) => trailer.trailer),
					driver,
					this.searchService.query.filter,
					this.advanceSearchOption,
				);
				if (!searchAppliesToVehicle)
					vehiclesJobs = this.searchService.searchInJobs(
						vehiclesJobs,
						this.searchService.query.filter,
						this.advanceSearchOption,
					);
			}
			if (
				(showAllVehicles && !this.searchService.query.filter) ||
				vehiclesJobs.length ||
				searchAppliesToVehicle ||
				this.filterStatusStore.vehicleStatus
			) {
				const vehiclesNote = this.timetableNoteProvider.getNoteForVehicle(vehicle, timetable.selectedDate);
				let filteredMapEntry = new TimetableFilteredEntry(vehicle, vehiclesNote, vehiclesJobs);
				if (trailerEntries) filteredMapEntry.trailerEntries = trailerEntries;
				if (vehiclesContractor) filteredMapEntry.addContractor(vehiclesContractor);

				filteredMapEntry.eventsEntry = filterEventsForTimetableTimerange(
					this.eventService.$events.getValue().get(vehicle.backendId),
					timetable.startDate,
					timetable.endDate,
					timetable.isSundayTable,
				);
				this.setTimetableEntryStyles(filteredMapEntry, vehiclesJobs, timetable, timetableWidthInPxl, userSettings);
				this.setVehiclesStatuses(filteredMapEntry, timetable);
				const vehiclesJobsOnSelectedDay = filterJobsWithStartOnReferenceDate(
					vehiclesJobs,
					timetable.selectedDate,
					timetable.isSundayTable,
				);
				referenceDateFilteredInvalidJobs = referenceDateFilteredInvalidJobs.concat(
					getInvalidJobs(vehiclesJobsOnSelectedDay, filteredMapEntry.vehicleEntry.vehicle),
				);
				setTimetableDriverEntryAttributes(
					filteredMapEntry,
					driver,
					timetable,
					companySettings,
					this.relationService.$data.getValue(),
				);
				tempFilteredMap.push(filteredMapEntry);
			}
		});
		this.$referenceDateFilteredInvalidJobs.next(referenceDateFilteredInvalidJobs);
		/**
		 * Post filtering - filtering based on Jobs
		 */
		if (this.filterStatusStore.vehicleStatus) {
			tempFilteredMap = FlutaroTimetableFilterService.filterDataForDriverStatus(
				this.filterStatusStore.vehicleStatus,
				tempFilteredMap,
			);
		}

		let currentTimetableJobsFiltered: JobWrapper[] = [];
		tempFilteredMap.forEach((entry) => {
			let jobs = entry.jobsEntry.map((jobEntry) => jobEntry.jobWrapper);
			currentTimetableJobsFiltered = currentTimetableJobsFiltered.concat(jobs);
		});
		this.$currentTimetableJobsFiltered.next(currentTimetableJobsFiltered);
		return tempFilteredMap;
	}

	private setVehiclesStatuses(mapEntry: TimetableFilteredEntry, timetable: TimeTable) {
		if (!mapEntry.vehicleEntry.vehicle.timetableSettings.statuses.length) return;
		let statusesInTimerange = mapEntry.vehicleEntry.vehicle.timetableSettings.statuses.filter((status) =>
			isDateInInterval(status.displayDate, timetable.startDate, timetable.endDate),
		);
		if (!statusesInTimerange.length) return;
		statusesInTimerange.forEach((status) => {
			if (!timetable.isSundayTable && getISODay(status.displayDate) === 7) return;
			let statusEntry = new TimetableVehicleStatus();
			statusEntry.marginLeft = timetable.getUnitForTimetable(
				timetable.getMarginByDateForObjectInTimetable(status.displayDate),
				null,
			);
			statusEntry.status = status;
			mapEntry.vehicleStatuses.push(statusEntry);
		});
	}

	private filterVehiclesForTimetableCalculation(
		vehicles: Vehicle[],
		companySettings: CompanySettings,
		timetable: TimeTable,
	): Vehicle[] {
		const activeTimetableFilter = this.filterStore.activeFilters.getValue();
		return vehicles.filter(
			(vehicle) =>
				isTruck(vehicle) &&
				isTruckActivatedForTimetableDate(vehicle, companySettings, timetable) &&
				(!this.filterStatusStore.vehicleIds.length ||
					this.filterStatusStore.vehicleIds.indexOf(vehicle.backendId) !== -1) &&
				(!activeTimetableFilter.planningViewRemovedVehicles.length ||
					!this.filterStore.isVehicleRemovedFromPlanningView(vehicle.backendId)),
		);
	}
}

import { AppDialogSettings, LocationDialogData } from 'src/app/dialog/AppDialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LocationDialogComponent } from './location.dialog.component';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { TableAddComponent } from '../table/table.add.component';

@Component({
	selector: 'app-location-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationAddComponent extends TableAddComponent {
	dialogComponent = LocationDialogComponent;

	createDialogData(): AppDialogSettings<Synonym> {
		return new LocationDialogData(this.userSettings, this.userProfile, this.companySettings, new Synonym());
	}
}

import { Injectable } from '@angular/core';
import { ImportExcelFileProvider } from './import.excel.file.provider';
import { dataTypesDefinition } from './import.excel.classes';
import Handsontable from 'handsontable';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { vehicleTypesTranslatedToString } from '@flutaro/package/lib/functions/VehicleFunctions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ImportDataTableSettingsProvider {
	// Component creates these values and keeps them synchronized
	instance: Handsontable;
	dataType: string = dataTypesDefinition.job;
	private addressValidationCircleRenderer;
	private jobDatesValidRenderer;
	private jobIdentifierRenderer;
	needsReloadOfApp: boolean = false;

	constructor(
		private excelFileProvider: ImportExcelFileProvider,
		private jobService: FlutaroJobService,
		private translate: TranslateService,
	) {
		this.addressValidationCircleRenderer = this.initializeAddressValidationCircleRenderer();
		this.jobIdentifierRenderer = this.jobIdentifierValidationCircleRenderer();
		// TODO: reactivate this.jobDatesValidRenderer = this.initializeJobDatesValidRenderer();
	}

	public initializeTableSettings() {
		let importTableWrapper: HTMLElement = <any>document.getElementsByClassName('importTableWrapper')[0];
		let width = importTableWrapper.offsetWidth;
		let height = importTableWrapper.offsetHeight;
		// Make Scrollbar visible
		height = height - 10;
		return {
			rowHeaders: true,
			stretchH: 'all',
			columnSorting: true,
			contextMenu: true,
			className: 'htLeft htMiddle',
			minSpareRows: '1',
			height: height,
			width: width,
			viewportRowRenderingOffset: 100,
			viewportColumnRenderingOffset: 100,
			cells: (row, col, prop) => {
				try {
					if (!this.instance) return;
					let visualColIndex = this.instance.toVisualColumn(col);
					let visualRowIndex = this.instance.toVisualRow(row);
					let cell = this.instance.getCell(visualRowIndex, visualColIndex);
					if (!cell) return;
					this.setCellBackgroundBasedOnDatatype(cell, visualColIndex);
				} catch (e) {
					this.needsReloadOfApp = true;
					// TODO: hehe, works by catching already. Needs Handsontable replacement ....
				}
			},
		};
	}

	public initializeJobTableHeaders() {
		return (column: number) => {
			switch (column) {
				case 0:
					return 'Auftragsnummer <span class="importRequired">*</span>';
				case 1:
					return 'Auftraggeber';
				case 2:
					return 'Umsatz';
				case 3:
					return 'Fahrzeugtypanforderung *';
				case 4:
					return "<div style='background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px'>Unternehmensname</div>";
				case 5:
					return "<div style='background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px'>Gültig</div>";
				case 6:
					return '<div style=\'background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px\'>Adresse <span class="importRequired">*</span></div></div>';
				case 7:
					return '<div style=\'background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px\'>Frühestes Datum <span class="importRequired">*</span></div>';
				case 8:
					return "<div style='background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px'>Spätestes Datum";
				case 9:
					return "<div style='background-color: #ce8c98;color: #813f4b;height:25px;position: relative;padding-top: 6px'>Unternehmensname</div>";
				case 10:
					return "<div style='background-color: #ce8c98;color: #813f4b;height:25px;position: relative;padding-top: 6px'>Gültig</div>";
				case 11:
					return '<div style=\'background-color: #ce8c98;color: #813f4b;height:25px;position: relative;padding-top: 6px\'>Adresse <span class="importRequired">*</span></div>';
				case 12:
					return '<div style=\'background-color: #ce8c98;color: #813f4b;height:25px;position: relative;padding-top: 6px\'>Frühestes Datum <span class="importRequired">*</span></div>';
				case 13:
					return "<div style='background-color: #ce8c98;color: #813f4b;height:25px;position: relative;padding-top: 6px'>Spätestes Datum";
				case 14:
					return 'Fahrzeug-Kennzeichen';
				case 15:
					return 'Mandant';
				default:
					return null;
			}
		};
	}

	initializeAddressValidationCircleRenderer() {
		return (instance: any, td: any, row: number, col: number, prop: string, value: number, cellProperties: any) => {
			const jobName = instance.getDataAtCell(row, 0);
			if (value > 0 && td.innerHTML !== '<i class="material-icons importValidIcon">check_circle</i>') {
				Handsontable.dom.fastInnerHTML(td, '<i class="material-icons importValidIcon">check_circle</i>');
			} else if (!value && jobName && td.innerHTML !== '<i class="material-icons importInvalidIcon">cancel</i>') {
				Handsontable.dom.fastInnerHTML(td, '<i class="material-icons importInvalidIcon">cancel</i>');
			} else if (!value && !jobName && td.innerHTML !== '') {
				Handsontable.dom.fastInnerHTML(td, '');
			}
		};
	}

	jobIdentifierValidationCircleRenderer() {
		return (instance: any, td: any, row: number, col: number, prop: string, value: number, cellProperties: any) => {
			const jobName = instance.getDataAtCell(row, 0);
			const existingJob = this.jobService.getJobByIdentifier(jobName);
			if (existingJob) {
				td.style.backgroundColor = '#DA4453';
			} else {
				td.style.backgroundColor = 'rgba(64,193,172,0.2)';
			}
			Handsontable.dom.fastInnerHTML(td, jobName);
		};
	}

	public initializeJobColumns() {
		return [
			{
				data: 'identifier',
				renderer: this.jobIdentifierRenderer,
			},
			{
				data: 'clientsName',
			},
			{
				data: 'revenue',
				type: 'numeric',
			},
			{
				data: 'requiredVehicle',
				type: 'autocomplete',
				source: vehicleTypesTranslatedToString(),
				strict: true,
				allowInvalid: false,
			},
			{
				data: 'startCompanyName',
			},
			{
				data: 'startLatitude',
				readOnly: true,
				renderer: this.addressValidationCircleRenderer,
				skipColumnOnPaste: true,
				copyable: false,
			},
			{
				data: 'startAddress',
			},
			{
				data: 'earliestStartDate',
				renderer: this.jobDatesValidRenderer,
			},
			{
				data: 'latestStartDate',
				renderer: this.jobDatesValidRenderer,
			},
			{
				data: 'endCompanyName',
			},
			{
				data: 'endLatitude',
				readOnly: true,
				renderer: this.addressValidationCircleRenderer,
				skipColumnOnPaste: true,
				copyable: false,
			},
			{
				data: 'endAddress',
			},
			{
				data: 'earliestEndDate',
				renderer: this.jobDatesValidRenderer,
			},
			{
				data: 'latestEndDate',
				renderer: this.jobDatesValidRenderer,
			},
			{
				data: 'assignedDriverLicence',
			},
			{
				data: 'tenantName',
				readOnly: true,
			},
		];
	}

	public initializeDriverTableHeaders() {
		return (column: number) => {
			switch (column) {
				case 0:
					return 'Vorname <span class="importRequired">*</span>';
				case 1:
					return 'Nachname <span class="importRequired">*</span>';
				case 2:
					return 'Fahrzeug (Kennzeichen)';
				case 3:
					return 'Telefon';
				case 4:
					return 'Email';
				case 5:
					return 'Führerschein';
				case 6:
					return "<div style='background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px'>Gültig</div>";
				case 7:
					return "<div style='background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px'>Straße</div>";
				case 8:
					return "<div style='background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px'>Nr.</div>";
				case 9:
					return '<div style=\'background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px\'>PLZ <span class="importRequired">*</span></div>';
				case 10:
					return '<div style=\'background-color: #79d3c4;color: #2c8778;height:25px;position: relative;padding-top: 6px\'>Stadt <span class="importRequired">*</span></div>';
				case 11:
					return 'Notizen';
				default:
					return null;
			}
		};
	}

	public initializeVehicleTableHeaders() {
		return (column: number) => {
			switch (column) {
				case 0:
					return 'Name <span class="importRequired">*</span>';
				case 1:
					return 'Kennzeichen <span class="importRequired">*</span>';
				case 2:
					return 'Hersteller';
				case 3:
					return 'Fahrzeugtyp';
				case 4:
					return 'Telefon (Fahrzeug)';
				case 5:
					return 'Email (Fahrzeug)';
				case 6:
					return 'Depot Adresse';
				case 7:
					return 'Mandant';
				default:
					return null;
			}
		};
	}

	public initializeJobColumnWidths() {
		return [120, 120, 70, 160, 160, 50, 160, 160, 160, 160, 50, 160, 160, 160, 160, 120];
	}

	public initializeDriverColumns() {
		return [
			{
				data: 'firstName',
			},
			{
				data: 'lastName',
			},
			{
				data: 'vehicleLicensePlate',
			},
			{
				data: 'phone',
			},
			{
				data: 'email',
			},
			{
				data: 'license',
			},
			{
				data: 'latitude',
				readOnly: true,
				renderer: this.addressValidationCircleRenderer,
				skipColumnOnPaste: true,
			},
			{
				data: 'street',
			},
			{
				data: 'houseNumber',
			},
			{
				data: 'zip',
			},
			{
				data: 'city',
			},
			{
				data: 'notes',
			},
		];
	}

	public initializeVehicleColumns() {
		return [
			{
				data: 'name',
			},
			{
				data: 'licensePlate',
			},
			{
				data: 'carmanufacturer',
			},
			{
				data: 'carType',
			},
			{
				data: 'vehiclePhone',
			},
			{
				data: 'vehicleEmail',
			},
			{
				data: 'depotAddress',
			},
			{
				data: 'tenantName',
			},
		];
	}

	public initializeDriverColumnWidths() {
		return [130, 130, 150, 120, 120, 120, 45, 150, 40, 55, 110, 200];
	}

	public initializeVehicleColumnWidths() {
		return [150, 115, 150, 170, 170, 170, 170, 120];
	}

	private setCellBackgroundBasedOnDatatype(cell, visualColIndex) {
		switch (this.dataType) {
			case dataTypesDefinition.job:
				if (visualColIndex > 3 && visualColIndex < 9) {
					cell.style.backgroundColor = 'rgba(64,193,172,0.2)';
				}
				if (visualColIndex > 8 && visualColIndex < 14) {
					cell.style.backgroundColor = 'rgba(185,91,108,0.2)';
				}
				return;
			case dataTypesDefinition.synonym:
				if (visualColIndex > 1 && visualColIndex < 6) {
					cell.style.backgroundColor = 'rgba(64,193,172,0.2)';
				}
				return;
			case dataTypesDefinition.driver:
				if (visualColIndex > 6 && visualColIndex < 11) {
					cell.style.backgroundColor = 'rgba(64,193,172,0.2)';
				}
				return;
			default:
				return;
		}
	}
}

<app-dashboard-dispatcher
	[companySettings]="companyService.$companySettings | async"
	[drivers]="driverService.$data | async"
	[events]="events.$events | async"
	[jobs]="jobService.$data | async"
	[loading]="jobService.$isInReceiveState | async"
	[news]="newsProvider.$data | async"
	[relations]="relationsService.$data | async"
	[userProfile]="fbAuth.$userProfile | async"
	[userSettings]="settingsProvider.$userSettings | async"
	[vehicles]="vehicleService.$data | async"
>
</app-dashboard-dispatcher>

import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { WebAuthService } from '../app/auth/web-auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { FlutaroChatChannel, parseAphroditeChannel } from './chat';
import { ChatMessage, FBChatChannel } from '@flutaro/package/lib/model/ChatClasses';
import {
	Firestore,
	addDoc,
	collection,
	collectionData,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from '@angular/fire/firestore';
import { appSortBy, flutaroWait, to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { AppSorting } from '@flutaro/package/lib/model/AppClasses';
import { FlutaroApplication } from '@flutaro/package/lib/model/FlutaroConstants';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { CollectionReference } from '@firebase/firestore';
import { transformChatMessageForFirestore } from '@flutaro/package/lib/functions/AppChatFunctions';

@Injectable()
export class ChatDataProvider {
	$channels: BehaviorSubject<FlutaroChatChannel[]> = new BehaviorSubject<FlutaroChatChannel[]>([]);
	$openedChannel: BehaviorSubject<FlutaroChatChannel | null> = new BehaviorSubject<FlutaroChatChannel>(null);

	constructor(
		private firestore: Firestore,
		private authProvider: WebAuthService,
		private http: HttpClient,
		private driverProvider: FlutaroDriverService,
		private vehicleProvider: FlutaroVehicleService,
	) {
		this.listenToAuthAndInit();
	}

	openChannel(channel: FlutaroChatChannel) {
		this.$openedChannel.next(channel);
	}

	closeChannel() {
		console.log(`ChatDataProvider, closeChannel`);
		this.$openedChannel.next(null);
	}

	async listenToAuthAndInit() {
		await flutaroWait(3000);
		if (!this.driverProvider.getData().length) {
			this.listenToAuthAndInit();
			return;
		}
		this.authProvider.$userIsAuthorized.subscribe((isAuthorized) => {
			if (!isAuthorized || this.authProvider.getUserProfile().hasReadOnlyRole) return;
			this.listenToChatChannels();
		});
	}

	async sendMessage(
		message: string,
		channelId: string,
		sender: FbStoreUserProfile,
		senderApplication: FlutaroApplication,
	) {
		let newFbMessage = new ChatMessage(
			senderApplication,
			sender.uid,
			sender.email,
			sender.displayName,
			sender.photoURL,
			message,
		);
		const channelMessagesCollection = collection(
			this.firestore,
			`chat/${this.authProvider.getUserProfile().company}/channels/${channelId}/messages`,
		);
		const channelDoc = doc(this.firestore, `chat/${this.authProvider.getUserProfile().company}/channels/${channelId}`);
		await addDoc(channelMessagesCollection, transformChatMessageForFirestore(newFbMessage));
		console.log(`sendMessage, message doc added`);
		const [sendError, sendSuccess] = await to(this.sendPushNotificationForNewMessage(channelId));
		if (sendError) {
			console.error(sendError);
			return;
		}
		const updateMap = {
			lastModified: new Date(),
			newMessageForAppUser: true,
		};
		return updateDoc(channelDoc, updateMap);
	}

	listenToChatChannels() {
		const companyChannelsCollection = collection(
			this.firestore,
			`chat/${this.authProvider.getUserProfile().company}/channels`,
		);
		collectionData(companyChannelsCollection).subscribe((channels) => {
			this.$channels.next(
				appSortBy(
					channels.map((fbChannel) => {
						const driver = this.driverProvider.getDriverByEmail(fbChannel.email);
						const vehicle = this.vehicleProvider.getVehicleByEmail(fbChannel.email);
						if (!driver && !vehicle) {
							console.log(`listenToChatChannels, no vehicle or driver for App-User ${fbChannel.email}`);
						}
						const channelMessages = collection(
							this.firestore,
							`chat/${this.authProvider.getUserProfile().company}/channels/${fbChannel.id}/messages`,
						);
						return parseAphroditeChannel(
							fbChannel as FBChatChannel,
							channelMessages as CollectionReference<ChatMessage>,
							vehicle,
							driver,
						);
					}),
					'lastModified',
					AppSorting.DESCENDING,
				),
			);
		});
	}

	updateChannelsHasNewMessagesForCompanyAttribute(channelId: string, hasNewCompanyMessage: boolean) {
		const channelDoc = doc(this.firestore, `chat/${this.authProvider.getUserProfile().company}/channels/${channelId}`);
		let updateMap = { newCompanyMessage: hasNewCompanyMessage };
		console.log(
			`updateChannelsHasNewMessagesForCompanyAttribute, update of hasNewCompanyMessage ${hasNewCompanyMessage}`,
		);
		return updateDoc(channelDoc, updateMap);
	}

	/**
	 * Mark all messages of channel as read
	 * @param channel
	 */
	async updateUnreadChannelMessagesAsReceived(channel: FlutaroChatChannel) {
		const userProfile = this.authProvider.getUserProfile();
		// TODO: refactor this for AuthUser (Firebase Authentication) with claim access and refactor into single service for WebApp and App
		let updateMap = {
			firstReceiverId: userProfile.uid,
			firstReceiverEmail: userProfile.email,
			firstReceiverName: userProfile.displayName,
		};

		const unreadMessagesQuery = query(
			collection(this.firestore, `chat/${userProfile.company}/channels/${channel.id}/messages`),
			where('firstReceiverId', '==', null),
			where('senderId', '!=', userProfile.uid),
			where('senderApplication', '==', FlutaroApplication.HERMES),
		);
		const unreadChannelMessages = await getDocs(unreadMessagesQuery);
		console.log(`markChannelAsRead, received ${unreadChannelMessages.size} unread messages`);
		unreadChannelMessages.forEach((unreadChannelMessage) => {
			updateDoc(unreadChannelMessage.ref, updateMap);
		});
	}

	private async sendPushNotificationForNewMessage(receiverUID: string) {
		let Params = new HttpParams();
		Params = Params.append('message', 'Neue Nachricht').append('receiver', receiverUID);
		return lastValueFrom(
			this.http.get(environment.routingAppApiUrl + '/pushMessage', {
				params: Params,
				responseType: 'text',
			}),
		);
	}
}

import { TableModule } from '../table/table.module';
import { ClientTableComponent } from './client.table.component';
import { SynonymModule } from '../synonym/synonym.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../dialog/dialog.module';
import { ClientAddComponent } from './client.add.component';
import { ClientDialogComponent } from './client.dialog.component';
import { JobModule } from '../job/job.module';

@NgModule({
	declarations: [ClientTableComponent, ClientAddComponent, ClientDialogComponent],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		SynonymModule,
		TableModule,
		DialogModule,
		JobModule,
	],
	exports: [ClientTableComponent],
	providers: [],
})
export class ClientModule {}

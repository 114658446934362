<div
	[class.timetableDay]="timetable?.isDayTimetable"
	[class.timetableWeek]="!timetable?.isDayTimetable"
	[scrollLeftEmitter]="scrollLeftSubject"
	[style.width]="openSideNav ? 'calc(100vw - 340px)' : '100vw'"
	arrowScrolling
	class="timetable"
	closeOpenSidenavOnEsc
	id="timetableCompleteSection"
	synchronScrolling
>
	<div
		*ngIf="!timetableData?.length"
		class="timetableAddDriverVehicleBackground"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="addVehicleDriverImage"></div>
		<div class="timetableAddDriverVehicleDiv">
			<h4 class="dialogHeadlineContent">
				{{ 'VEHICLEANDDRIVER' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'TIMETABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">{{ 'TIMETABLE_NO_DATA_CREATE_NEW' | translate }}</p>
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
				<button [routerLink]="['/setup']" class="buttonDone mat-primary_flutaro" mat-button>
					<mat-icon>
						<i class="material-icons">add</i>
					</mat-icon>
					{{ 'CREATE' | translate }}
				</button>
				<button [routerLink]="['/import']" class="buttonDone mat-primary_flutaro" mat-button>
					<mat-icon>
						<i class="material-icons">file_download</i>
					</mat-icon>
					{{ 'IMPORT' | translate }}
				</button>
			</div>
		</div>
	</div>
	<div
		*ngIf="(searchService.query.filter || !timetableFilterService.showAllVehicles) && !timetableData.length"
		class="timetableAddDriverVehicleBackground"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div *ngIf="searchService.query.filter" class="timetableObjectNotFoundImage"></div>
		<div
			*ngIf="!timetableFilterService.showAllVehicles && !searchService.query.filter"
			class="timetableObjectFilterNotFoundimage"
		></div>
		<div class="timetableObjectNotFoundDiv">
			<h4 class="dialogHeadlineContent">
				<span *ngIf="searchService.query.filter">{{ 'SEARCH' | translate }}</span>
				<span *ngIf="!timetableFilterService.showAllVehicles && !searchService.query.filter"
					>{{ 'FILTER' | translate }} : <i>{{ 'DRIVER_WITOUHT_JOBS' | translate }}</i></span
				>
			</h4>
			<p *ngIf="searchService.query.filter" class="fontWeight500">
				{{ 'FILTER_NO_RESULT_1' | translate }}
				<b>"{{ searchService.query.filter }}"</b>
				{{ 'FILTER_NO_RESULT_2' | translate }}<br />
				{{ 'FILTER_NO_RESULT_3' | translate }}
			</p>
			<p *ngIf="!timetableFilterService.showAllVehicles && !searchService.query.filter" class="fontWeight500">
				{{ 'FILTER_NO_RESULT_4' | translate }}
			</p>
		</div>
	</div>

	<aside
		[class.timetableAsideDefault]="!noteMode && !planningMode"
		[class.timetableAsideNoteActiveWidth]="noteMode"
		id="driverSectionAside"
		*ngIf="timetableData?.length"
	>
		<ul fxFlexFill>
			<cdk-virtual-scroll-viewport fxFlexFill id="driverSection" itemSize="50" synchronDriverScrolling>
				<li
					*cdkVirtualFor="let mapEntry of timetableData; trackBy: trackByVehicleId"
					[class.blockedForActionDriver]="!mapEntry.vehicleJobStatus.isJobActionValid"
					[class.invalidTimetableEntry]="!mapEntry.vehicleJobStatus.isValid"
					[ngClass]="mapEntry.entryBackgroundClass"
					[style.height]="mapEntry.height"
					[class.timetableAsideNoteActiveWidth]="noteMode"
					[class.timetableAsideDefault]="!noteMode && !planningMode"
					fxLayout="row"
					fxLayoutAlign="start center"
				>
					<button
						(click)="planningModeExcludeChange.emit(mapEntry.vehicleEntry.vehicle.backendId)"
						*ngIf="planningMode"
						[matTooltipPosition]="'right'"
						class="mat-raised mat-ink-ripple timetableVehiclePlanningModeButton"
						id="timetableVehiclePlanningModeButton"
						matTooltip="{{ 'PLANNINGMODEEXCLUDECHANGE_TT' | translate }}"
					>
						<i class="material-icons timetableVehiclePlanningModeIcon">visibility_off</i>
					</button>
					<app-timetable-vehicle-list-element
						[vehicle]="mapEntry.vehicleEntry.vehicle"
						[driver]="mapEntry.vehicleEntry.vehicle | driverForTimetable : drivers : timetable"
						[drivers]="drivers"
						[timetableEntry]="mapEntry"
						[timetable]="timetable"
						[companySettings]="companySettings"
						class="timetableAsideDefault"
					></app-timetable-vehicle-list-element>
					<timetable-note-component
						(noteChange)="storeNoteEntryForVehicle($event, mapEntry)"
						*ngIf="noteMode"
						[noteLatestEditDate]="mapEntry.note.latestEditDate"
						[note]="mapEntry.note.note"
						matTooltip="{{ mapEntry.note.note }}"
						class="fullWidth"
					>
					</timetable-note-component>
					<div
						class="costDriverPreviewWrapper"
						*ngIf="isDrag && costPreview?.vehicleId === mapEntry.vehicleEntry.vehicle.backendId"
					>
						<div class="currentDriverCost">
							<span>
								{{ costPreview.totalCosts | currency : 'EUR' }}
							</span>
						</div>
						<div class="currentDriverCostEmptyKm">
							<span class="currentDriverCostEmptyKmText">
								<i class="material-icons currentDriverCostEmptyKmIcon">more_horiz</i>
								{{ costPreview.emptyDistance | metersInKm }} km
							</span>
						</div>
					</div>
				</li>
			</cdk-virtual-scroll-viewport>
		</ul>
	</aside>

	<section
		(contextmenu)="onContextMenu($event, 'bla')"
		*ngIf="timetable"
		[class.timetableSectionDriverNoteActiveWidth]="noteMode"
		id="timetableSection"
	>
		<time [style.width]="timetable.timetableWidth">
			<header class="repeatingContent fullWidth" id="timetableHeader">
				<div
					*ngFor="let date of timetable.dates"
					[style.width]="date.width"
					class="timeInformation timetableDate timetableDateHours"
				>
					<div class="date">{{ date.date | appFormatDate : 'weekday-date-short' | appLinkify }}</div>
				</div>
				<div class="timeHeader timetableDateHours">
					<!--time and date-->
					<div
						*ngFor="let hour of timetable.hours"
						[style.width]="hour.width"
						class="timeInformation timetableDateHours"
					>
						<span class="time-label">{{ hour.date | appFormatDate : 'time' }}</span>
					</div>
				</div>
			</header>

			<div *ngIf="showTimelineBar" [style.margin-left]="timelineBarMargin" class="timelineHead"></div>

			<div [class.timetableTopMinusWithoutTimeline]="showTimelineBar" class="room-timeline">
				<cdk-virtual-scroll-viewport fxFlexFill id="roomTimeline" itemSize="50">
					<div
						*cdkVirtualFor="let mapEntry of timetableData; trackBy: trackByVehicleId"
						[class.repeatingJobInformationDay]="timetable.isDayTimetable"
						[class.repeatingJobInformationWeek]="!timetable.isDayTimetable"
						[ngClass]="
							!(
								mapEntry.vehicleEntry.vehicle
								| driverForTimetable : drivers : timetable
								| isWeekEndOutsideCurrentWeek : timetable.startDate
							) && mapEntry.entryBackgroundClass
						"
						[style.height]="mapEntry.height"
						class="repeatingJobInformation timetableElementHeight"
						id="timetable_vehicle_row_{{ mapEntry.vehicleEntry.vehicle.backendId }}"
					>
						<div
							*ngIf="!mapEntry.vehicleJobStatus.isJobActionValid"
							[style.height]="mapEntry.height"
							class="refreshJobRowBackground donotdrag"
						>
							<span
								*ngIf="mapEntry.vehicleJobStatus.isNotJobsWholeRangeActivated"
								[style.margin-left]="null | timetableScrollLeft"
								class="refreshJobRowScreenText"
								>{{ 'VEHICLE_JOB_NOT_ACTIVATED' | translate }}</span
							>
							<span
								*ngIf="mapEntry.vehicleJobStatus.isNotMatchingJobsRequiredVehicleType"
								class="refreshJobRowScreenText"
								[style.margin-left]="null | timetableScrollLeft"
								>{{ 'VEHICLE_JOB_MISMATCHED_REQUIREMENTS_MESSAGE' | translate }}</span
							>
						</div>

						<div *ngIf="showTimelineBar" [style.margin-left]="timelineBarMargin" class="timelineBar donotdrag"></div>

						<div
							[class.jobRowBackgroundBlur]="!mapEntry.vehicleJobStatus.isJobActionValid"
							[dragula]="'bag-jobs'"
							class="driverContent fullWidth"
							[style.height]="mapEntry.height"
							fxLayout="column"
							id="vehicle_{{ mapEntry.vehicleEntry.vehicle.backendId }}"
						>
							<app-timetable-driver-entry
								[driver]="mapEntry.driverEntry?.driver"
								[timetableEntry]="mapEntry"
								[timetable]="timetable"
								class="fullHeight donotdrag"
							></app-timetable-driver-entry>

							<span
								#jobEntrySpan
								(click)="openJobSidenav($event, jobEntry.jobWrapper)"
								*ngFor="let jobEntry of mapEntry.jobsEntry; trackBy: trackJobEntry"
								fxLayout="column"
								[class.donotdrag]="userProfile.hasReadOnlyRole"
								[style.margin-left]="jobEntry.marginLeft"
								[style.margin-top]="jobEntry.marginTop"
								[style.width]="jobEntry.wholeJobWidth"
								class="wholeJob"
								id="timetable_job_{{ jobEntry.jobWrapper.backendId }}"
							>
								<span *ngIf="jobEntry.jobWrapper.isLocked" class="isLockedDiv">
									<i class="material-icons isLockedIcon">lock</i>
								</span>
								<app-timetable-job
									(jobSelected)="jobSelected.emit($event)"
									[customJobDisplay]="customJobDisplay"
									[hideJob]="jobEntrySpan.classList.contains('hideTimetableJobAfterDrop')"
									[hideMenu]="userProfile.hasReadOnlyRole"
									[jobEntry]="jobEntry"
									[locations]="locations"
									[userProfile]="userProfile"
									[userSettings]="userSettings"
								>
								</app-timetable-job>
							</span>
							<!-- Span for showing earliest/latest-Border for Job  -->
							<span
								*ngFor="let timewindowEntry of mapEntry.timewindowEntry; trackBy: trackByTimewindowProperties"
								[class.timetableJobTimeWindowStartsTheDayBefore]="
									timewindowEntry.marginLeft === '0%' || timewindowEntry.marginLeft === '0px'
								"
								[style.background]="timewindowEntry.color"
								[style.margin-left]="timewindowEntry.marginLeft"
								[style.marginTop]="timewindowEntry.marginTop"
								[style.width]="timewindowEntry.width"
								class="jobTimeWindow donotdrag"
							>
							</span>
							<span
								*ngFor="let eventEntry of mapEntry.eventsEntry; trackBy: trackByEventProperties"
								[class.eventBreakStateCSS]="
									eventEntry.type === EVENT_TYPES.BREAK || eventEntry.type === EVENT_TYPES.DAILY_RECOVERY_BREAK
								"
								[class.eventDeliveryCSS]="eventEntry.type === EVENT_TYPES.DELIVERY"
								[class.eventDriveToAbsenceCSS]="eventEntry.type === EVENT_TYPES.DRIVE_TO_ABSENCE"
								[class.eventDrivingBanCSS]="
									eventEntry.type === EVENT_TYPES.DRIVING_BAN_WITH_CARGO || eventEntry.type === EVENT_TYPES.DRIVING_BAN
								"
								[class.eventEmptyCSS]="eventEntry.type === EVENT_TYPES.EMPTY_DRIVE"
								[class.eventLoadedCSS]="eventEntry.type === EVENT_TYPES.LOADED_DRIVE"
								[class.eventPickupCSS]="eventEntry.type === EVENT_TYPES.PICKUP"
								[class.eventWaitCSS]="eventEntry.type === EVENT_TYPES.WAITING"
								[style.margin-left]="eventEntry.marginLeft"
								[style.width]="eventEntry.width"
								class="eventsGeneral donotdrag"
							>
							</span>
						</div>
						<app-timetable-vehicle-entry
							[userProfile]="userProfile"
							[mapEntry]="mapEntry"
							[vehicle]="mapEntry.vehicleEntry.vehicle"
							[timetable]="timetable"
							(vehicleStatusStoreChange)="vehicleStatusStoreChange.emit($event)"
							class="donotdrag"
						></app-timetable-vehicle-entry>
					</div>
				</cdk-virtual-scroll-viewport>
			</div>
		</time>
	</section>
</div>
<div
	[matMenuTriggerFor]="contextMenu"
	[style.left]="contextMenuPosition.x"
	[style.top]="contextMenuPosition.y"
	style="visibility: hidden; position: fixed"
></div>
<mat-menu #contextMenu="matMenu" class="flutaroMatMenu">
	<ng-template let-item="item" matMenuContent>
		<button (click)="sidenavService.openSideNavType('NEW_JOBS')" mat-menu-item>
			<i class="material-icons">notifications_none</i>
			<span class="contextMenuText">{{ 'OPEN_JOBS_NEW_SIDENAV' | translate }}</span>
		</button>
		<button (click)="sidenavService.openSideNavType('INFO')" mat-menu-item>
			<i class="material-icons">timeline</i>
			<span class="contextMenuText">{{ 'OPEN_INFO_SIDENAV' | translate }}</span>
		</button>
		<button (click)="menuProvider.showHideSearch()" mat-menu-item>
			<i class="material-icons">search</i>
			<span class="contextMenuText">{{ 'OPEN_SEARCH' | translate }}</span>
		</button>
	</ng-template>
</mat-menu>

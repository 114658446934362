<div
	(click)="openStatusDialog(statusEntry.status, mapEntry.vehicleEntry.vehicle)"
	*ngFor="let statusEntry of mapEntry.vehicleStatuses; trackBy: trackByStatusesProperties"
	[class.hideElement]="userProfile.hasReadOnlyRole"
	[matTooltipPosition]="'right'"
	[style.margin-left]="statusEntry.marginLeft"
	class="driverVehicleStatus"
	matTooltip="{{ 'VEHICLEDRIVERSTATUS_TT' | translate }}"
>
	<i class="material-icons driverVehicleStatusIcon">info_outline</i>
</div>
<div *ngIf="vehicle.driverSchedules?.length" fxLayout="row" fxLayoutAlign="start center">
	<div
		*ngFor="let driverScheduleBlocks of vehicle.driverSchedules | scheduleNotActiveBlocks: timetable"
		[class.timetableAppointmentStartsTheDayBefore]="!(driverScheduleBlocks.startDate | marginInTimetable: timetable)"
		[style.margin-left]="
			(driverScheduleBlocks.endDate | diffInMin: driverScheduleBlocks.startDate) > 15
				? (driverScheduleBlocks.startDate | marginInTimetable: timetable)
				: 'calc(' + (driverScheduleBlocks.startDate | marginInTimetable: timetable) + ' - 10px)'
		"
		[style.width]="driverScheduleBlocks.startDate | widthInTimetable: driverScheduleBlocks.endDate:timetable"
		class="vehicleNotActiveBlock inTimetableElementMaxHeight donotdrag"
		fxLayout="row"
		fxLayoutAlign="start center"
	>
		<mat-icon svgIcon="driver_swap" class="appIconSize16"></mat-icon>
		<span
			class="vehicleNotActiveBlockLabel"
			*ngIf="(driverScheduleBlocks.endDate | diffInMin: driverScheduleBlocks.startDate) > 15"
			>{{ 'NO_DRIVER_SCHEDULE' | translate }}</span
		>
	</div>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { TimeTable } from '../../timetable/TimeTable';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { Client } from '@flutaro/package/lib/model/Client';

@Component({
	selector: 'app-basic-component',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBasicComponent {
	@Input() userProfile: FlutaroUserProfile;
	@Input() userSettings: UserSettings;
	@Input() companySettings: CompanySettings;
	@Input() timetable: TimeTable;
	@Input() jobs: JobWrapper[];
	@Input() drivers: Driver[];
	@Input() vehicles: Vehicle[];
	@Input() contractors: Contractor[];
	@Input() clients: Client[];
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as XLSX from 'xlsx';

import { AddressSearchProvider } from '../integrations/address/integration.address.service';
import {
	ExcelJob,
	ImportedDriverTemplate,
	ImportedSynonymTemplate,
	ImportedVehicleTemplate,
	dataTypesDefinition,
} from './import.excel.classes';
import { ImportValidationProvider } from './import.validation.provider';
import { createSynonymFromImportedData } from './ImportFunctions';
import { validateImportedJobTemplates } from './validation/ExcelImportValidationFunctions';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { ImportedJobTemplate } from '@flutaro/package/lib/model/import/ExcelImportClasses';
import { ExternalJobInCorrection } from '../interfaces/ExternalJob/ExternalJobWeb';
import assign from 'lodash-es/assign';
import pick from 'lodash-es/pick';
import keys from 'lodash-es/keys';
import { parseElementsBasics } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Injectable()
export class ImportExcelFileProvider {
	excelImportData: ImportedJobTemplate[] | any[] = [];
	externalJobImportData: ExternalJobInCorrection[] = [];
	isFinishedDataLoading: Subject<boolean> = new Subject();
	fileName: string;
	$awaitingAddressesCounter: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	showProgressCircle = false;

	constructor(
		private addressService: AddressSearchProvider,
		private validationProvider: ImportValidationProvider,
	) {}

	/**
	 * Imports(File-reads) Excel Data from File
	 * @param event
	 * @param dataType
	 */
	public async importExcel(event: any, dataType: string) {
		const files = event.srcElement.files;
		if (files.length === 0) {
			return;
		}
		this.showProgressCircle = true;
		const fileData = files[0];
		/** TODO: put in athene as csv-import-endpoint const jsonArray = await csv().fromString(fileData);
		console.log(jsonArray); **/
		this.fileName = fileData.name;
		const reader = new FileReader();
		reader.readAsBinaryString(fileData);
		reader.onload = (e) => {
			const target: any = e.target;
			const data: any = target.result;
			const workbook = XLSX.read(data, { type: 'binary' });
			this.readWorkbook(workbook, dataType);
		};
		// Clear internal FileList
		event.target.value = null;
	}

	/**
	 * Reads an imported Workbook to create a data Array
	 * @param workbook
	 * @param dataType
	 */
	public async readWorkbook(workbook: any, dataType: string) {
		let sheetName = workbook.SheetNames[0];
		let worksheet = workbook.Sheets[sheetName];
		let headers = {};
		let data = [];
		for (let z in worksheet) {
			if (z[0] === '!') {
				continue;
			}
			let tt = 0;
			for (let i = 0; i < z.length; i++) {
				if (!isNaN(parseInt(z[i], 10))) {
					tt = i;
					break;
				}
			}
			let col = z.substring(0, tt);
			let row = parseInt(z.substring(tt), 10);
			let value = worksheet[z].v;

			// i store header names
			// TODO: after start of refactor change to: if (row === 1 && value && dataType !== dataTypesDefinition.job) {
			if (row === 1 && value) {
				headers[col] = this.translateSheetHeadersIntoClassesAttributes(value);
				continue;
			}
			if (!data[row]) {
				data[row] = {};
			}
			data[row][headers[col]] = value;
		}
		// Shit two times so that data-Index starts from index 0 instead of 2
		data.shift();
		data.shift();
		// Give each Data Object unique identifier
		let importId = 0;
		data.forEach((data) => (data.importId = ++importId));
		switch (dataType) {
			case dataTypesDefinition.job:
				//this.transformCSVIntoExternalJobInCorrection(data);
				await this.processAndSetJobsDataFromImport(data);
				break;
			case dataTypesDefinition.driver:
				await this.processAndSetDriverDataFromImport(data);
				break;
			case dataTypesDefinition.vehicle:
				await this.processAndSetVehicleDataFromImport(data);
				break;
			default:
				break;
		}
		this.validationProvider.calculateValidationStatus(dataType, this.excelImportData, null);
	}

	transformCSVIntoExternalJobInCorrection(jobsDataImport: any[]) {
		const externalJobs = jobsDataImport.map((csvJob) => {
			let extJob = new ExternalJobInCorrection();
			assign(extJob, pick(csvJob, keys(csvJob)));
			// Destinations
			const firstPickUp = getFirstPickUp(extJob as unknown as JobWrapper);
			const lastDelivery = getLastDestination(extJob as unknown as JobWrapper);
			firstPickUp.position = new Synonym();
			firstPickUp.position.addressLine = csvJob['Destination1Address'];
			firstPickUp.earliestDate = csvJob['Destination1EarliestDate'];
			firstPickUp.latestDate = csvJob['Destination1LatestDate'];
			lastDelivery.position = new Synonym();
			lastDelivery.position.addressLine = csvJob['Destination2Address'];
			lastDelivery.earliestDate = csvJob['Destination2EarliestDate'];
			lastDelivery.latestDate = csvJob['Destination2LatestDate'];
			parseElementsBasics(extJob);
		});
	}

	async processAndSetJobsDataFromImport(jobsDataImport: ImportedJobTemplate[]) {
		let jobsData: ExcelJob[] = <ExcelJob[]>jobsDataImport;
		await this.extractJobsAddressesFromData(jobsData);
		validateImportedJobTemplates(jobsData);
		// TODO: this.externalJobImportData = jobsDataImport;
		this.excelImportData = jobsData;
		this.isFinishedDataLoading.next(true);
		return true;
	}

	async processAndSetDriverDataFromImport(driverDataImport: ImportedDriverTemplate[]) {
		await this.extractSynonymsFromData(<any>driverDataImport);
		this.excelImportData = driverDataImport;
		this.isFinishedDataLoading.next(true);
		return true;
	}

	async processAndSetVehicleDataFromImport(vehicleDataImport: ImportedVehicleTemplate[]) {
		this.excelImportData = vehicleDataImport;
		this.isFinishedDataLoading.next(true);
		return true;
	}

	async processAndSetLocationDataFromImport(synonymDataImport: ImportedSynonymTemplate[]) {
		await this.extractSynonymsFromData(synonymDataImport);
		this.excelImportData = synonymDataImport;
		this.isFinishedDataLoading.next(true);
		return true;
	}

	/**
	 * Create Synonym-Objects from Excel-Data
	 * Use this for address-Validation and when storing the Jobs
	 */
	async extractJobsAddressesFromData(data: ExcelJob[]) {
		this.$awaitingAddressesCounter.next(data.length * 2);
		let currCounter = this.$awaitingAddressesCounter.getValue();
		console.log(
			`extractJobsAddressesFromData, $awaitingAddressesCounter initial: ${currCounter} for data with length ${data.length}`,
		);
		for (const currentJob of data) {
			console.log(
				`extractJobsAddressesFromData, $awaitingAddressesCounter before job ${currentJob.identifier}: ${currCounter} for data with length ${data.length}`,
			);
			await this.extractJobsAddress('start', currentJob);
			currCounter = this.$awaitingAddressesCounter.getValue();
			await this.extractJobsAddress('end', currentJob);
			currCounter = this.$awaitingAddressesCounter.getValue();
			console.log(
				`extractJobsAddressesFromData, $awaitingAddressesCounter after job ${currentJob.identifier}: ${currCounter} for data with length ${data.length}`,
			);
		}
		currCounter = this.$awaitingAddressesCounter.getValue();
		console.log(`extractJobsAddressesFromData, $awaitingAddressesCounter after complete: ${currCounter}`);
		return true;
	}

	public async extractJobsAddress(startOrEnd: string, jobImportData: ImportedJobTemplate) {
		let tempSynonym: Synonym = await this.addressService.geocodeAddressAsSynonym(
			`${jobImportData[startOrEnd + 'Address']}`,
		);
		this.decreaseAwaitingAddressCounter();
		if (!tempSynonym) {
			console.error(`extractJobsAddress, no synonym returned for ${jobImportData[startOrEnd + 'Address']}. Aborting.`);
			return;
		}
		if (tempSynonym.longitude && tempSynonym.latitude) {
			jobImportData[startOrEnd + 'Address'] = tempSynonym.toString();
			jobImportData[startOrEnd + 'Longitude'] = tempSynonym.longitude;
			jobImportData[startOrEnd + 'Latitude'] = tempSynonym.latitude;
		} else {
			jobImportData[startOrEnd + 'Longitude'] = null;
			jobImportData[startOrEnd + 'Latitude'] = null;
		}

		if (tempSynonym.city) jobImportData[startOrEnd + 'City'] = tempSynonym.city;
		if (tempSynonym.zip) jobImportData[startOrEnd + 'Zip'] = tempSynonym.zip;
		if (tempSynonym.addressLine) jobImportData[startOrEnd + 'Street'] = tempSynonym.addressLine;
		if (tempSynonym.country) jobImportData[startOrEnd + 'Country'] = tempSynonym.country;
		return tempSynonym;
	}

	/**
	 * This function creates Synonyms for evaluation and calculating lat/long for the complete importedd Data.
	 * The given Data will be manipulated through setting lat/long Information for each Entry.
	 * @param data
	 */
	public async extractSynonymsFromData(data: ImportedSynonymTemplate[]) {
		this.$awaitingAddressesCounter.next(data.length);
		for (const currentData of data) {
			await this.extractSynonymsAddress(currentData);
		}
	}

	/**
	 * This creates a Synonym based of an importedData-Object. The created Synonym will be used to evaluate the address.
	 * The importedData-Object´ lat/long values will be set based on the evaluations result.
	 * The result will be displayed to the user through setting the data-Objects value and displaying the value in the Table.
	 * @param currentData
	 */
	public async extractSynonymsAddress(currentData: ImportedSynonymTemplate) {
		let tempSynonym: Synonym = await this.addressService.geocodeAddressAsSynonym(
			`${currentData.street ? currentData.street : ''} ${currentData.houseNumber ? currentData.houseNumber : ''} ${
				currentData.zip ? currentData.zip : ''
			} ${currentData.city ? currentData.city : ''}`,
		);
		this.decreaseAwaitingAddressCounter();
		if (tempSynonym) {
			currentData.longitude = tempSynonym.longitude ? tempSynonym.longitude : null;
			currentData.latitude = tempSynonym.latitude ? tempSynonym.latitude : null;
			if (tempSynonym.city) currentData.city = tempSynonym.city;
			if (tempSynonym.zip) currentData.zip = tempSynonym.zip;
			if (tempSynonym.addressLine) currentData.street = tempSynonym.addressLine;
		}
		return tempSynonym;
	}

	decreaseAwaitingAddressCounter() {
		this.$awaitingAddressesCounter.next(this.$awaitingAddressesCounter.getValue() - 1);
	}

	increaseAwaitingAddressCounter() {
		this.$awaitingAddressesCounter.next(this.$awaitingAddressesCounter.getValue() + 1);
	}

	/**
	 * Create a Synonym Object from a given Data-imported Job-Object
	 * This is used when loading the imported Data and on storing the imported Data
	 * @param type
	 * @param jobDataObject
	 * @returns {Synonym}
	 */
	public createSynonymFromJobImportedData(type: string, jobDataObject: ImportedJobTemplate) {
		let tempSynonym: Synonym = createSynonymFromImportedData(
			jobDataObject[type + 'City'],
			jobDataObject[type + 'Zip'],
			jobDataObject[type + 'Street'],
			jobDataObject[type + 'Number'],
			jobDataObject[type + 'Country'],
		);
		if (jobDataObject[type + 'Latitude'] && jobDataObject[type + 'Longitude']) {
			tempSynonym.latitude = jobDataObject[type + 'Latitude'];
			tempSynonym.longitude = jobDataObject[type + 'Longitude'];
		}
		return tempSynonym;
	}

	public translateSheetHeadersIntoClassesAttributes(value: string) {
		switch (value) {
			// All Elements
			case 'Mandant':
				return 'tenantName';
			// Synonym
			case 'Abkürzung *':
				return 'code';
			// Synonym Attributes also apply for drivers Homeaddress
			case 'Straße':
				return 'street';
			case 'Nr.':
				return 'houseNumber';
			case 'PLZ *':
				return 'zip';
			case 'Stadt *':
				return 'city';
			// Job
			case 'Auftragsnummer*':
				return 'identifier';
			case 'Auftraggeber':
				return 'client';
			case 'Umsatz':
				return 'revenue';
			case 'Fahrzeugtypanforderung*':
				return 'requiredVehicle';
			case 'UnternehmensnameBeladung':
				return 'startCompanyName';
			case 'AdresseBeladung*':
				return 'startAddress';
			case 'FrühesteBeladung*':
				return 'earliestStartDate';
			case 'SpätesteBeladung':
				return 'earliestStartTime';
			case 'UnternehmensnameEntladung':
				return 'endCompanyName';
			case 'AdresseEntladung*':
				return 'endAddress';
			case 'FrühesteEntladung*':
				return 'earliestEndDate';
			case 'SpätesteEntladung':
				return 'latestEndDate';
			case 'Fahrzeugzuordnung':
				return 'assignedDriverLicence';
			// Driver
			case 'Vorname *':
				return 'firstName';
			case 'Nachname *':
				return 'lastName';
			case 'Fahrzeug (Kennzeichen)':
				return 'vehicleLicensePlate';
			case 'Telefon':
				return 'phone';
			case 'Email':
				return 'email';
			case 'Führerschein(B,BE,C1,C1E,C,CE)':
				return 'license';
			case 'Notizen':
				return 'notes';
			// Vehicle
			case 'Name *':
				return 'name';
			case 'Kennzeichen *':
				return 'licensePlate';
			case 'Hersteller':
				return 'carmanufacturer';
			case 'Ladekapazität':
				return 'loadUnitCapacity';
			case 'Ladeeinheit':
				return 'loadUnit';
			case 'Fahrzeugtyp':
				return 'carType';
			case 'Telefon (Fahrzeug)':
				return 'vehiclePhone';
			case 'Email (Fahrzeug)':
				return 'vehicleEmail';
			default:
				return value;
		}
	}
}

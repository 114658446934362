import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ErrorNotificationComponent } from './notification.error.component';
import { FlutaroNotificationsProvider } from './notification.snack.provider';
import { NotificationsErrorProvider } from './notifications.error.provider';

@NgModule({
	declarations: [ErrorNotificationComponent],
	imports: [CommonModule],
	providers: [FlutaroNotificationsProvider, NotificationsErrorProvider],
	exports: [ErrorNotificationComponent],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class NotificationModule {}

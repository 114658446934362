<div fxLayoutAlign="end center">
	<app-contractor-add
		[companySettings]="companySettings"
		[drivers]="drivers"
		[userProfile]="userProfile"
		[userSettings]="userSettings"
		[vehicles]="vehicles"
	>
	</app-contractor-add>
</div>

<div class="table-container mat-elevation-z8">
	<div
		*ngIf="!isDataLoading && !data.length"
		class="addNewObjectBackground"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="addNewContractorImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'SUBCONTRACTOR' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'CONTRACTOR_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_CONTRACTOR' | translate }}
			</p>
		</div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_CONTRACTORS' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">work</i>
				Name
			</th>
			<td *matCellDef="let contractor" mat-cell>{{ contractor.toString() }}</td>
		</ng-container>

		<ng-container matColumnDef="description">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">comment</i>
				{{ 'DESCRIPTION' | translate }}
			</th>
			<td *matCellDef="let contractor" mat-cell>{{ contractor.description }}</td>
		</ng-container>

		<ng-container matColumnDef="contractorId">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">label</i>
				{{ 'CONTRACTOR_ID' | translate }}
			</th>
			<td *matCellDef="let contractor" mat-cell>{{ contractor.contractorId }}</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let contractor"
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(contractor)"
					[disabled]="userProfile.hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let element" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(element)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons> </mat-paginator>
</div>

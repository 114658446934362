import { Injectable } from '@angular/core';

import { FlutaroJobService } from '../data/data-services/data.job.service';
import { TimetableFilterController } from '../timetable/data/timetable.filter.controller';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { AppSideNav, LocationLabel, SidenavData } from './SidenavClasses';
import { getLastElement } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { AppPushProvider } from '../app/push/app.push.provider';
import { BehaviorSubject } from 'rxjs';
import { FlutaroTimetableFilterService } from '../timetable/data/timetable.data.provider';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import {
	isCostCalculationJobUpdate,
	isNoteChangeJobUpdate,
	isPlannedDateChangeJobUpdate,
	isVehicleChangeJobUpdate,
} from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { jobDestinationsAreValid } from '@flutaro/package/lib/functions/job/JobValidationFunctions';
import { getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Injectable()
export class FlutaroSidenavProvider {
	$openSideNav: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
	$openSideNavs: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	flutaroSidenavRef: any;
	sideNavData: SidenavData = new SidenavData();

	constructor(
		private jobService: FlutaroJobService,
		private timetableDataController: TimetableFilterController,
		private notifications: FlutaroNotificationsProvider,
		private pushProvider: AppPushProvider,
		private timetableDataProvider: FlutaroTimetableFilterService,
	) {
		this.listenToJobDataChanges();
	}

	private listenToJobDataChanges() {
		// TODO: Replace with date-based recognition of changed data and implement for all Sidenav-Data Services
		console.log(`FlutaroSidenavProvider: listenToJobDataChanges activated`);
		this.pushProvider.dataChangeSubject.subscribe((dataChange) => {
			if (this.sideNavData.job?.backendId !== dataChange?.element?.backendId) return;
			this.handleUpdateOfSidenavJob(dataChange.element);
		});
	}

	private handleUpdateOfSidenavJob(updatedJob: JobWrapper) {
		// Reset to now updated job state
		this.timetableDataProvider.clearReplacementJob();
		this.isOpenedJobSidenav() ? this.showJobNav(updatedJob) : this.sideNavData.setJobSideNavData(updatedJob);
	}

	public deactivateSidenavs() {
		this.sideNavData = new SidenavData();
		this.$openSideNavs.next([]);
		this.$openSideNav.next(null);
		this.timetableDataProvider.clearReplacementJob();
	}

	public openFlutaroSidenav() {
		this.flutaroSidenavRef.toggle();
	}

	isOpenedJobSidenav(): boolean {
		return this.$openSideNav.getValue() === AppSideNav.JOB;
	}

	openSideNavType(sidenavType: AppSideNav | string): void {
		if (this.$openSideNav.getValue() === sidenavType) return;
		let openSideNavs = this.$openSideNavs.getValue();
		if (openSideNavs.includes(sidenavType))
			// Make sure opened Sidenav is latest and only one Sidenav per type is open
			openSideNavs = openSideNavs.filter((sidenav) => sidenav !== sidenavType);
		openSideNavs.push(sidenavType);
		this.$openSideNav.next(sidenavType);
		this.$openSideNavs.next(openSideNavs);
	}

	sidenavPop() {
		const openSideNavs = this.$openSideNavs.getValue();
		const currentOpenSidenav = openSideNavs.pop();
		this.$openSideNavs.next(openSideNavs);
		switch (currentOpenSidenav) {
			case AppSideNav.JOB:
				this.resetJobSideNavData();
				break;
			case AppSideNav.VEHICLE:
				this.resetVehicleSideNavData();
				break;
		}
		const openSideNav = getLastElement(openSideNavs);
		this.$openSideNav.next(openSideNav);
	}

	public showVehicleSidenav(vehicle: Vehicle) {
		this.sideNavData.setVehicleSideNavData(vehicle);
		this.openSideNavType(AppSideNav.VEHICLE);
	}

	showJobNavByJobId(jobId: string) {
		const job = this.jobService.getElementById(jobId);
		this.showJobNav(job);
	}

	public showJobNav(job: JobWrapper): void {
		console.log(`showJobNav: ${job.job.identifier}`);
		this.sideNavData.setJobSideNavData(job);
		this.openSideNavType(AppSideNav.JOB);
	}

	private resetJobSideNavData(): void {
		this.timetableDataProvider.clearReplacementJob();
		this.sideNavData.jobIsEdited = false;
		this.sideNavData.job = null;
		this.sideNavData.originalJob = null;
	}

	private resetVehicleSideNavData(): void {
		this.sideNavData.vehicle = null;
		this.sideNavData.originalVehicle = null;
	}

	updateSidenavIsJobEdited(updatedJob: JobWrapper) {
		const isVehicleChangeEdit = isVehicleChangeJobUpdate(updatedJob, this.sideNavData.originalJob);
		this.sideNavData.jobIsEdited =
			isVehicleChangeEdit ||
			isCostCalculationJobUpdate(updatedJob, this.sideNavData.originalJob) ||
			isPlannedDateChangeJobUpdate(updatedJob, this.sideNavData.originalJob) ||
			isNoteChangeJobUpdate(updatedJob, this.sideNavData.originalJob) ||
			this.sideNavData.originalJob.job.status !== updatedJob.job.status;
		isVehicleChangeEdit
			? this.timetableDataProvider.setReplacementJob(updatedJob)
			: this.timetableDataProvider.clearReplacementJob();
	}

	public async storeJobNavChanges(updatedJob: JobWrapper) {
		if (!jobDestinationsAreValid(updatedJob)) {
			console.log(`PlannedDates not correct - Aborting. `);
			this.notifications.showInvalidDestinationsError();
			return;
		}
		if (isVehicleChangeJobUpdate(updatedJob, this.sideNavData.originalJob)) {
			// Update original for any possible other changes in Sidenav from User (notes f.ex.)
			const originalJob = this.sideNavData.originalJob;
			originalJob.destinations = updatedJob.destinations;
			this.timetableDataController.dispatchJobToNewVehicle(originalJob, updatedJob.vehicleId);
		} else {
			//setJobAppInSyncAttributeOnJobChange(updatedJob);
			this.jobService.store(updatedJob);
		}
		this.sidenavPop();
	}

	setLocationTypeLabel(locations: Synonym[], job: JobWrapper): LocationLabel {
		let locationLabel: LocationLabel = new LocationLabel();
		if (!locations) return;
		locations.some((location) => {
			if (!location.location || !location.location.type) return false;
			let locationAddress = location.toString().toLowerCase().replace(/\s/g, '');
			locationAddress = locationAddress.substring(locationAddress.indexOf(':') + 1);
			if (!locationLabel.startLocationLabel) {
				let startAddress = job.destinations[0].position.toString().toLowerCase().replace(/\s/g, '');
				if (startAddress.indexOf(':') !== -1) startAddress = startAddress.substring(startAddress.indexOf(':') + 1);
				if (startAddress == locationAddress) locationLabel.startLocationLabel = location.location.type;
			}
			if (!locationLabel.endLocationLabel) {
				let endAddress = getLastDestination(job).position.toString().toLowerCase().replace(/\s/g, '');
				if (endAddress.indexOf(':') !== -1) endAddress = endAddress.substring(endAddress.indexOf(':') + 1);
				if (endAddress === locationAddress) locationLabel.endLocationLabel = location.location.type;
			}
			return !!locationLabel.endLocationLabel && !!locationLabel.startLocationLabel;
		});
		return locationLabel;
	}
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import remove from 'lodash-es/remove';
import { Subject } from 'rxjs';

import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { updateFormValues, validateFormFields } from '../form/FlutaroFormFunctions';
import { ContractorDataProvider } from './contractor.data.provider';
import { orderBy } from 'lodash-es';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Contractor, ContractorRate } from '@flutaro/package/lib/model/Contractor';
import { generateContractorRateId } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { ContractorDialogData } from 'src/app/dialog/AppDialog';

@Component({
	selector: 'app-contractor-dialog',
	templateUrl: './contractor.dialog.component.html',
	styles: ['.ratesList {min-width:112px;}'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorDialogComponent implements OnInit, OnDestroy {
	contractorForm: FormGroup;
	// FormControl for Validation-Fields
	name: FormControl;
	description: FormControl;
	contractor: Contractor = new Contractor();
	contractorVehicles: Vehicle[] = [];
	vehiclesWithoutContractor: Vehicle[] = [];
	vehiclesToBeRemoved: string[] = [];
	// rate-component related
	isRateEdit: boolean = false;
	currentRate: ContractorRate;
	userProfile: FlutaroUserProfile;
	companySettings: CompanySettings;
	private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

	constructor(
		public contractorProvider: ContractorDataProvider,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dialogData: ContractorDialogData,
		public dialogRef: MatDialogRef<Contractor>,
		public vehicleProvider: FlutaroVehicleService,
	) {}

	ngOnInit() {
		this.userProfile = this.dialogData.userProfile;
		this.companySettings = this.dialogData.companySettings;
		this.contractor = cloneDeep(this.dialogData.data);
		this.sortRates();
		this.initializeFormFields();
	}

	deleteRate(deleteRate: ContractorRate) {
		this.contractor.rates = this.contractor.rates.filter((rate) => rate.id !== deleteRate.id);
		this.sortRates();
	}

	deleteVehicleFromContractor(vehicleId: string) {
		this.vehiclesToBeRemoved.push(vehicleId);
		this.contractorVehicles = this.contractorVehicles.filter((vehicle) => vehicle.backendId !== vehicleId);
	}

	editRate(rate: ContractorRate) {
		this.currentRate = rate;
		this.isRateEdit = true;
	}

	addNewRate() {
		this.currentRate = new ContractorRate();
		this.isRateEdit = true;
	}

	saveContractorRate(saveRate: ContractorRate) {
		this.isRateEdit = false;
		if (!saveRate) return;

		if (saveRate.id) {
			this.contractor.rates = this.contractor.rates.filter((rate) => rate.id !== saveRate.id);
		} else {
			saveRate.id = generateContractorRateId(this.contractor);
		}
		this.contractor.rates.push(saveRate);
		this.sortRates();
	}

	onSubmit() {
		if (!this.contractorForm.valid) {
			validateFormFields(this.contractorForm);
			return;
		}
		this.updateVehicles();
		updateFormValues(
			//check me
			this.contractorForm,
			this.contractor,
			null,
			new Contractor(),
		);
		this.contractorProvider.store(this.contractor);
		this.dialogRef.close();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
	}

	onAbort() {
		this.dialogRef.close();
	}

	private sortRates() {
		if (!this.contractor.rates) this.contractor.rates = [];
		this.contractor.rates = orderBy(this.contractor.rates, ['date'], ['desc']);
	}

	private initializeFormFields() {
		this.name = this.fb.control(this.contractor.name, Validators.required);
		this.description = this.fb.control(this.contractor.description, Validators.required);
		this.contractorVehicles = this.vehicleProvider
			.getData()
			.filter((vehicle) => this.contractor.vehicles.indexOf(vehicle.backendId) !== -1);
		this.vehiclesWithoutContractor = this.vehicleProvider
			.getData()
			.filter((vehicle) => !this.contractorProvider.getContractorForVehicle(vehicle.backendId));
		this.contractorForm = this.fb.group({
			name: this.name,
			description: this.description,
			contractorId: this.contractor.contractorId,
			newAssignedVehicles: '',
			filterEmail: this.contractor.filterEmail,
		});
	}

	private updateVehicles() {
		if (this.vehiclesToBeRemoved.length > 0)
			this.vehiclesToBeRemoved.forEach((vehicle) => remove(this.contractor.vehicles, (value) => value === vehicle));
		if (this.contractorForm.value.newAssignedVehicles.length > 0)
			this.contractorForm.value.newAssignedVehicles.forEach((vehicle) => this.contractor.vehicles.push(vehicle));
	}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FlutaroTimetableFilterService } from '../timetable/data/timetable.data.provider';
import { InfoSidenavProvider } from './sidenav.info.provider';
import { JobFilterStatus, TimetableFilteredEntry, VehicleFilterStatus } from '../timetable/data/TimetableFilterClasses';
import { JobStatisticsSummary, JobSummary } from '../statistics/Statistics';
import {
	calculateJobsStatisticsForTimeRange,
	calculateStatisticsForTimeRange,
} from '../statistics/statistics.functions';
import { CUSTOM_COLORS, ChartStatusData } from './SidenavClasses';
import { SidenavBasicComponent } from './sidenav.basic.component';
import { endOfDay } from 'date-fns';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';

@Component({
	selector: 'app-sidenav-statistics',
	templateUrl: './sidenav.info.component.html',
	styleUrls: ['./sidenav.info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoSidenavComponent extends SidenavBasicComponent implements OnChanges {
	@Input() timetableJobs: JobWrapper[];
	@Input() timetableData: TimetableFilteredEntry[];
	customColors = CUSTOM_COLORS;
	jobStatisticsSummary: JobStatisticsSummary = new JobStatisticsSummary();
	jobSummary: JobSummary = new JobSummary();

	constructor(
		public timetableFilterService: FlutaroTimetableFilterService,
		public infoProvider: InfoSidenavProvider,
		protected cd: ChangeDetectorRef,
	) {
		super(cd);
	}

	ngOnChanges(changes) {
		if (!this.timetableJobs) return;
		if (changes['timetableJobs']) this.calculateInfoStatistics();
	}

	resetDataOnClose() {
		this.infoProvider.resetFilteredData();
	}

	resetFilteredData() {
		this.infoProvider.resetFilteredData();
	}

	onVehicleChartSelect(event: ChartStatusData) {
		this.timetableFilterService.filterStatusStore.isVehicleStatusFilterActive()
			? this.resetVehicleFilterOnElementClick()
			: this.infoProvider.filterDriverStatusData(event.extra.key as VehicleFilterStatus);
	}

	onJobChartSelect(event: ChartStatusData) {
		this.timetableFilterService.filterStatusStore.isJobStatusFilterActive()
			? this.resetJobFilterOnElementClick()
			: this.infoProvider.filterJobStatusData(event.extra.key as JobFilterStatus | JobStatus);
	}

	protected resetVehicleFilterOnElementClick() {
		this.timetableFilterService.filterStatusStore.resetVehicleStatusFilter();
		this.timetableFilterService.showAllVehicles = true;
		this.timetableFilterService.createFilteredTimetableMap();
	}

	protected resetJobFilterOnElementClick() {
		this.timetableFilterService.filterStatusStore.resetAllAppJobStatusFilter();
		this.timetableFilterService.showAllVehicles = true;
		this.timetableFilterService.createFilteredTimetableMap();
	}

	private calculateInfoStatistics() {
		const endDate: Date = endOfDay(this.timetable.selectedDate);
		this.infoProvider.calculateInfoSidenavData(this.timetableJobs, this.timetable.selectedDate, endDate);
		this.jobStatisticsSummary = calculateStatisticsForTimeRange(
			this.timetableJobs,
			this.timetable.selectedDate,
			endDate,
		);
		this.jobSummary = calculateJobsStatisticsForTimeRange(this.timetableJobs, this.timetable.selectedDate, endDate);
	}
}

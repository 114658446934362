import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { AppBasicComponent } from '../../../app/components/app.components.basic.component';
import { TimetableFilteredEntry } from '../../data/TimetableFilterClasses';
import { FlutaroSidenavProvider } from '../../../sidenav/sidenav.provider';

@Component({
	selector: 'app-timetable-vehicle-list-element',
	templateUrl: './timetable-vehicle-list-element.component.html',
	styleUrls: ['./timetable-vehicle-list-element.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableVehicleListElementComponent extends AppBasicComponent {
	@Input() timetableEntry: TimetableFilteredEntry;
	@Input() vehicle: Vehicle;
	@Input() driver: Driver;

	constructor(public sidenavService: FlutaroSidenavProvider) {
		super();
	}
	openVehicleSidenav($event) {
		$event.stopPropagation();
		this.sidenavService.showVehicleSidenav(this.vehicle);
	}
}

import { ImportObject, ImportedJobTemplate } from '@flutaro/package/lib/model/import/ExcelImportClasses';

export const dataTypesDefinition = {
	driver: 'DRIVER',
	vehicle: 'VEHICLE',
	job: 'JOB',
	synonym: 'LOCATION',
};

export interface DriverTemplate extends ImportedSynonym {
	firstName: string;
	lastName: string;
	phone: string;
	license: string;
	notes: string;
}

export interface ImportedDriverTemplate extends DriverTemplate {
	startDate: string;
	vehicleLicensePlate: string;
}

export interface ImportedVehicleTemplate extends ImportObject {
	name: string;
	licensePlate: string;
	carmanufacturer: string;
	loadUnitCapacity: string;
	loadUnit: string;
	carType: string;
	depotAddress: string;
}

export interface ImportedSynonymTemplate extends ImportedSynonym {
	code: string;
}

export interface ExcelJob extends ImportedJobTemplate {
	pickupDatesAreInvalid: boolean;
	deliveryDatesAreInvalid: boolean;
	identifierNotUnique: boolean;
}

export interface ImportedSynonym extends ImportObject {
	street: string;
	houseNumber: string;
	zip: string;
	city: string;
	country: string;
	latitude: number;
	longitude: number;
}

export class ValidationGeneralAttributes {
	isValid: boolean;
	hasIdentifier: boolean;
	hasUniqueIdentifier: boolean;
	hasValidAddress: boolean;
}

export class DriverValidationAttributes extends ValidationGeneralAttributes {
	hasIdentifier = true;
	hasFullName: boolean;
	// Vehicle exists
	hasValidVehicle: boolean = true;
	// Vehicle doesnt already have a Driver
	hasValidVehicleAssignment: boolean = true;
	hasValidAddress: boolean;
}

export class VehicleValidationAttributes extends ValidationGeneralAttributes {
	// Vehicle exists
	hasLicensePlate: boolean = true;
}

export class JobWrapperValidationAttributes extends ValidationGeneralAttributes {
	hasValidPickUpDates: boolean;
	hasValidPickUpAddresses: boolean;
	hasValidDeliveryDates: boolean;
	hasValidDeliveryAddresses: boolean;
	hasValidDriver: boolean = true;
	hasValidVehicle: boolean = true;
	hasValidVehicleType: boolean = true;
}

export class LocationValidationAttributes extends ValidationGeneralAttributes {
	// At least 4 Characters
	hasValidCode = true;
}

export const EXCEL_DATE_FORMAT_STRING_SHORT: string = 'dd.MM.yy HH:mm';
export const EXCEL_DATE_FORMAT_STRING_SHORT_SLASH_SEPARATOR: string = 'dd/MM/yy HH:mm';
export const EXCEL_DATE_FORMAT_STRING_LONG: string = 'dd.MM.yyyy HH:mm';
export const EXCEL_DATE_FORMAT_STRING_LONG_SLASH_SEPARATOR: string = 'dd/MM/yyyy HH:mm';

export const SERVER_STATUS = {
	CREATED: 'CREATED',
	UPDATED: 'UPDATED',
	LOCKED: 'LOCKED',
	UNLOCKED: 'UNLOCKED',
};

export class ServerLog {
	name: string;
	relativeId: string;
	status: string;
	orderingNumber: number;
}

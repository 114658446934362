import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { SidenavDriverBlockComponent } from './sidenav.driver.block.component';
import { VehicleStatusDialogComponent } from '../vehicle/status/vehicle.status.dialog.component';
import { FlutaroSidenavProvider } from './sidenav.provider';
import { calculateDriverStatistics } from '../statistics/statistics.functions';
import { DriverStatistics } from '../statistics/Statistics';
import { APP_MENU } from '../menu/MenuClasses';
import { endOfDay, isSameISOWeek, startOfISOWeek } from 'date-fns';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { VehicleStatusStoreChange } from '../timetable/component/TimetableComponentClasses';
import { FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { VehicleStatus, VehicleStatusDialogData } from '@flutaro/package/lib/model/VehicleStatus';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { SidenavBasicComponent } from './sidenav.basic.component';
import { SCHEDULE_TYPES } from '@flutaro/package/lib/model/AppScheduleClasses';
import { getDriverForVehicleByScheduleDate } from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { changeDriversWeekEndOutSide, deleteWeekEndOutSide } from '../driver/DriverWeekEndOutsideFunctions';

@Component({
	selector: 'app-vehicle-sidenav',
	templateUrl: './sidenav.vehicle.component.html',
	styleUrls: ['./sidenav.vehicle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavVehicleComponent extends SidenavBasicComponent implements OnChanges {
	@Input() vehicle: Vehicle;
	@Input() readonly: boolean;
	@Input() events: Map<string, FlutaroEvent[]>;
	@Input() currentTab: string;
	driver: Driver;
	_notesIsEdited = false; // Calculated by comparing _noteBackup with current driver.notes
	_noteBackup = ''; // Use to restore notes on abort action and to determine if the current note is different to the original
	driverStatistic: DriverStatistics = new DriverStatistics();
	TABS = APP_MENU;
	isWeekEndOutsideWeek: boolean = false;
	TYPES = SCHEDULE_TYPES;

	constructor(
		public sidenavProvider: FlutaroSidenavProvider,
		private dialog: MatDialog,
		private driverService: FlutaroDriverService,
		public vehicleProvider: FlutaroVehicleService,
		protected cd: ChangeDetectorRef,
	) {
		super(cd);
	}

	ngOnChanges() {
		if (this.vehicle?.driverSchedules?.length) {
			this.driver = getDriverForVehicleByScheduleDate(this.vehicle, this.drivers, this.timetable.selectedDate);
			if (this.driver) {
				this.calculateDriverStatistic();
				this.initIsWeekEndOutsideWeek();
				this._noteBackup = this.driver.notes;
				this._notesIsEdited = false;
			} else {
				this._noteBackup = '';
			}
		}
		this.cd.markForCheck();
	}

	changeIsWeekEndOutsideWeek() {
		console.log(
			`changeIsWeekEndOutsideWeek, isWeekEndOutsideWeek is now ${
				this.isWeekEndOutsideWeek ? 'activated for current week' : 'deactivated for current week'
			}`,
		);
		const outsideDate = startOfISOWeek(this.timetable.startDate);
		this.isWeekEndOutsideWeek
			? changeDriversWeekEndOutSide(this.driver, false, outsideDate, this.jobs)
			: deleteWeekEndOutSide(this.driver, outsideDate);
		this.driverService.store(this.driver);
	}

	onNoteChange() {
		this._notesIsEdited = !(!this.driver.notes && !this._noteBackup) && this.driver.notes != this._noteBackup;
		this.cd.markForCheck();
	}

	onNoteSave() {
		this.driverService.store(this.driver);
		this._notesIsEdited = false;
		this.cd.markForCheck();
	}

	onNoteAbort() {
		this.driver.notes = this._noteBackup;
		this._notesIsEdited = false;
		this.cd.markForCheck();
	}

	public openBlockDialog() {
		let dialogConfig = new MatDialogConfig();
		dialogConfig.data = { driver: this.driver };
		let dialogRef = this.dialog.open(SidenavDriverBlockComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(() => {
			this.cd.markForCheck();
		});
	}

	public createStatusDialog() {
		let dialogConfig = new MatDialogConfig();
		dialogConfig.data = new VehicleStatusDialogData(true, new VehicleStatus(), this.vehicle, this.userProfile);
		let dialogRef = this.dialog.open(VehicleStatusDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((result) => {
			if (result) this.vehicleProvider.storeStatus(new VehicleStatusStoreChange(result, true, this.vehicle));
		});
	}

	protected calculateDriverStatistic() {
		this.driverStatistic = calculateDriverStatistics(
			this.driver.backendId,
			this.events.get(this.driver.backendId),
			this.timetable.startDate,
			endOfDay(this.timetable.startDate),
		);
	}

	private initIsWeekEndOutsideWeek() {
		this.isWeekEndOutsideWeek = this.driver.weekEndOutsides.some((weekEndOutside) =>
			isSameISOWeek(weekEndOutside.date, this.timetable.startDate),
		);
	}
}

<mat-sidenav-container>
	<mat-sidenav #flutaroSidenav class="sidenavMain">
		<div class="sidenavMainHeader">
			<img class="toolbarLogo" src="assets/pictures/Flutaro_Logo_white.svg" alt="flutaro_logo" />
		</div>
		<mat-nav-list>
			<mat-list-item routerLink="dashboard" (click)="flutaroSidenav.close()">
				<i class="material-icons nav-icon">dashboard</i>
				<span class="nav-text">{{ 'DASHBOARD' | translate }}</span>
			</mat-list-item>
			<mat-list-item [routerLink]="['/planning']" (click)="flutaroSidenav.close()">
				<i class="material-icons nav-icon">schedule</i>
				<span class="nav-text">{{ 'TOUR_PLANNING' | translate }}</span>
			</mat-list-item>
			<mat-list-item [routerLink]="['/jobs']" (click)="flutaroSidenav.close()">
				<i class="material-icons nav-icon">description</i>
				<span class="nav-text">{{ 'JOBS' | translate }}</span>
			</mat-list-item>
			<mat-list-item [routerLink]="['/setup']" (click)="flutaroSidenav.close()">
				<i class="material-icons nav-icon">storage</i>
				<span class="nav-text">{{ 'SETUP' | translate }}</span>
			</mat-list-item>
			<mat-list-item routerLink="/addresses" (click)="flutaroSidenav.close()">
				<i class="material-icons nav-icon">place</i>
				<span class="nav-text">{{ 'ADDRESSES' | translate }}</span>
			</mat-list-item>
			<mat-divider></mat-divider>
			<mat-list-item
				(click)="flutaroSidenav.close()"
				[disabled]="(companyService.$companySettings | async)?.isInSheetImportMode"
				[routerLink]="['/import']"
			>
				<i class="material-icons nav-icon">file_download</i>
				<span class="nav-text">{{ 'DATA_IMPORT' | translate }}</span>
			</mat-list-item>
			<mat-list-item (click)="authFb.signOut()">
				<i class="material-icons nav-icon">exit_to_app</i>
				<span class="nav-text">{{ 'LOGOUT' | translate }}</span>
			</mat-list-item>

			<!-- for later: version number & link for change-log -->
			<!-- <a href="_">
              <mat-list-item class="navlistItemversion">
                  <span class="navTextVersion">Version 1.0</span>
              </mat-list-item>
          </a> -->
		</mat-nav-list>
	</mat-sidenav>
	<app-header
		[companySettings]="companyService.$companySettings | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettingsProvider.$userSettings | async"
		[timetable]="timetableProvider.$timetable | async"
	>
	</app-header>
	<app-interface-import-notification
		*ngIf="
			(interfaceProvider.showImportMessage && (externalJobsProvider.$externalJobsCount | async)) ||
			(interfaceProvider.$validImportLogs | async).length
		"
	></app-interface-import-notification>
	<router-outlet></router-outlet>
</mat-sidenav-container>

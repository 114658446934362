<div [dragula]='"bag-jobs"' class="jobPlanningSidenavAccordionRow" fxLayout="row" id="unplanned_job">
	<div class="jobPlanningSidenavLabelTimewindowdonotdrag donotdrag" fxLayout="column">
		<span>{{(job | getFirstPickUp).earliestDate | appFormatDate:'time'}}</span>
		<div *ngIf="startLocationLabel" class="startLocationLabel">{{startLocationLabel}}</div>
		<span>{{(job | getFirstPickUp).latestDate | appFormatDate:'time'}}</span>
	</div>
	<div [style.background]="color" class="jobsNewJobPlanningSidenav" id="unplanned_job_{{job.backendId}}">
		<div
			(click)="sidenavService.showJobNav(job)"
			*ngIf="!userSettings.planningSettings.customJobDisplay"
			[class.labelJobDark]="showDarkText"
			class="labelJob"
		>
			<div
				*ngIf="!userSettings.planningSettings.identifierJobDisplay"
				class="labelJobPickupAndDeliveryPlaces"
				fxLayout="row"
			>
				<div class="labelJobAlignRight" fxFlex>
					<span> {{(job | getFirstPickUp).position.city}} </span>
				</div>
				<div class="labelJobSeperator">
					<span>➝</span>
				</div>
				<div class="labelJobAlignLeft" fxFlex>
					<span name="SynonymOrNoCodeEnd"> {{(job | getLastDestination).position.city}} </span>
				</div>
			</div>
			<div
				*ngIf="userSettings.planningSettings.identifierJobDisplay"
				class="labelJobPickupAndDeliveryPlaces"
				fxLayout="row"
			>
				<div fxFlex fxLayoutAlign="center center">{{ job.job.identifier}}</div>
			</div>
			<div [class.labelJobDark]="showDarkText" class="labelJobTimewindow" fxLayout="row">
				<div class="labelJobAlignRight" fxFlex>
					<span
						*ngIf="userSettings.planningSettings.timetableJobDisplaySettings.newJobsSubtitle === TIMETABLE_JOB_DISPLAY.DURATION_DISTANCE"
					>
						{{job.totalDistance | metersInKm}} Km
					</span>
					<span
						*ngIf="userSettings.planningSettings.timetableJobDisplaySettings.newJobsSubtitle === TIMETABLE_JOB_DISPLAY.CITIES"
					>
						{{(job | getFirstPickUp).position.city}}
					</span>
				</div>
				<div class="labelJobSeperator">
					<span>-</span>
				</div>
				<div class="labelJobAlignLeft" fxFlex>
					<span
						*ngIf="userSettings.planningSettings.timetableJobDisplaySettings.newJobsSubtitle === TIMETABLE_JOB_DISPLAY.DURATION_DISTANCE"
					>
						{{job.totalTimeInMinutes | minutesToDrivingTime}}
					</span>
					<span
						*ngIf="userSettings.planningSettings.timetableJobDisplaySettings.newJobsSubtitle === TIMETABLE_JOB_DISPLAY.CITIES"
					>
						{{(job | getLastDestination).position.city}}
					</span>
				</div>
			</div>
		</div>
		<div
			(click)="sidenavService.showJobNav(job)"
			*ngIf="userSettings.planningSettings.customJobDisplay"
			[class.labelJobDark]="showDarkText"
			class="labelJob"
		>
			<div class="labelJobAlignRight" fxFlex style="margin-top: 5px">
				<span name="iata-or-synonym-start"> {{job.jobLabel}} </span>
			</div>
		</div>
	</div>
	<div
		*ngIf="job.destinations.length > 2"
		[matTooltipPosition]="'below'"
		class="jobPlanningSidenavMoreDestinationsIconDiv"
		matTooltip="Weitere Orte (Abholung/Lieferung) vorhanden"
	>
		<i class="material-icons">add_location</i>
	</div>
	<div class="jobPlanningSidenavLabelTimewindowdonotdrag donotdrag" fxLayout="column">
		<span>{{(job | getLastDestination).earliestDate | appFormatDate:'time'}}</span>
		<div *ngIf="endLocationLabel" class="endLocationLabel">{{endLocationLabel}}</div>
		<span>{{(job | getLastDestination).latestDate | appFormatDate:'time'}}</span>
	</div>
</div>

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { CommonModule } from '@angular/common';
import { ChatMenuComponent } from './chat.menu.component';
import { ChatDataProvider } from './chat.data.provider';
import { ChatChannelComponent } from './chat.channel.component';
import { LoadingSpinnerComponent } from '../app/loading-spinner/loading-spinner.component';
import { FlutaroFocusDirective, FlutaroScrollableDirective } from '../app/flutaroChat.directive';
import { PipesModule } from '../app/pipes.module';

@NgModule({
	declarations: [
		ChatMenuComponent,
		ChatChannelComponent,
		LoadingSpinnerComponent,
		FlutaroScrollableDirective,
		FlutaroFocusDirective,
	],
	imports: [CommonModule, FormsModule, FlutaroCustomMaterialModule, PipesModule],
	exports: [
		ChatMenuComponent,
		ChatChannelComponent,
		LoadingSpinnerComponent,
		FlutaroScrollableDirective,
		FlutaroFocusDirective,
	],
	providers: [ChatDataProvider],
})
export class ChatModule {}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
	selector: 'app-error-notification',
	templateUrl: 'notification.error.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorNotificationComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string) {}
}

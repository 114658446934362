<form class="constractorDialogAddRateDiv" [formGroup]="contractorRateForm" (ngSubmit)="onSubmit()">
	<p class="driverDialogAppointmentLabel" *ngIf="isNew">{{ 'NEW_RATE' | translate }}</p>
	<p class="driverDialogAppointmentLabel" *ngIf="!isNew">{{ 'EDIT_RATE' | translate }}</p>

	<mat-card-content fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center space-between">
		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between end">
			<app-datetimepicker
				[(date)]="contractorRate.date"
				placeholder="{{ 'VALID_FROM' | translate }}"
				[type]="'date'"
				class="dateTimePickerInRate"
				[allowDynamicWidth]="true"
			>
			</app-datetimepicker>
			<app-routing-profile [(routingProfile)]="contractorRate.routingProfile"></app-routing-profile>
		</div>

		<p class="dividerContractorDialogDotted100"></p>
		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
			<!-- for all vehicleTypes -->
			<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
				<!-- TODO: max. two decimals -->
				<input
					matInput
					placeholder="€ / km"
					type="text"
					formControlName="kmRate"
					matTooltip="{{ 'KM_RATE_TT' | translate }}"
					[matTooltipPosition]="'below'"
				/>
				<div
					*ngIf="
						contractorRateForm.controls['kmRate'].invalid &&
						(contractorRateForm.controls['kmRate'].dirty || contractorRateForm.controls['kmRate'].touched)
					"
				>
					<div class="requiredMessageDiv" *ngIf="contractorRateForm.controls['kmRate'].errors">
						<span class="requiredMessageContractorCosts"
							>{{ 'RATE_VALIDATION_MESSAGE_1' | translate }} ({{ 'FOR_EXAMLE' | translate }}: 1,25)</span
						>
					</div>
				</div>
			</mat-form-field>
			<!-- for all vehicleTypes -->
			<mat-checkbox
				mat-theme="flutaroGrey"
				fxFlex="100"
				fxFlex.gt-sm="50"
				class="mat-primary mat-checkbox-small"
				aria-label="dailyRate Contractor"
				formControlName="kmRateIncludesToll"
				matTooltip="{{ 'KM_RATE_INCLUDES_TOLL_TT' | translate }}"
				[matTooltipPosition]="'below'"
			>
				{{ 'KM_RATE_INCLUDES_TOLL' | translate }}
			</mat-checkbox>
		</div>

		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
			<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
				<input
					matInput
					placeholder="{{ 'DAILY_RATE' | translate }}"
					type="text"
					formControlName="dailyRate"
					matTooltip="{{ 'DAILY_RATE_TT' | translate }}"
					[matTooltipPosition]="'below'"
				/>
				<div
					*ngIf="
						contractorRateForm.controls['dailyRate'].invalid &&
						(contractorRateForm.controls['dailyRate'].dirty || contractorRateForm.controls['dailyRate'].touched)
					"
				>
					<div class="requiredMessageDiv" *ngIf="contractorRateForm.controls['dailyRate'].errors">
						<span class="requiredMessageContractorCosts"
							>{{ 'RATE_VALIDATION_MESSAGE_1' | translate }} ({{ 'FOR_EXAMLE' | translate }}: 1,25)</span
						>
					</div>
				</div>
			</mat-form-field>
			<mat-checkbox
				mat-theme="flutaroGrey"
				fxFlex="100"
				class="mat-primary mat-checkbox-small"
				aria-label="dailyRate Contractor"
				formControlName="alwaysApplyDailyRate"
				matTooltip="{{ 'ALWAYS_APPLY_DAILY_RATE_TT' | translate }}"
				[matTooltipPosition]="'below'"
			>
				{{ 'ALWAYS_APPLY' | translate }}
			</mat-checkbox>
		</div>

		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
			<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
				<input
					matInput
					placeholder="{{ 'DAILY_RATE_REDUCED' | translate }}"
					type="text"
					formControlName="reducedDailyRate"
					matTooltip="{{ 'DAILY_RATE_REDUCED_TT' | translate }}"
					[matTooltipPosition]="'below'"
				/>
				<div
					*ngIf="
						contractorRateForm.controls['reducedDailyRate'].invalid &&
						(contractorRateForm.controls['reducedDailyRate'].dirty || contractorRateForm.controls['kmRate'].touched)
					"
				>
					<div class="requiredMessageDiv" *ngIf="contractorRateForm.controls['reducedDailyRate'].errors">
						<span class="requiredMessageContractorCosts"
							>{{ 'RATE_VALIDATION_MESSAGE_1' | translate }} ({{ 'FOR_EXAMLE' | translate }}: 1,25)</span
						>
					</div>
				</div>
			</mat-form-field>
			<mat-checkbox
				mat-theme="flutaroGrey"
				fxFlex="100"
				class="mat-primary mat-checkbox-small"
				aria-label="dailyRate weekend Contractor"
				matTooltip="{{ 'USE_REDUCED_DAILY_RATE_TT' | translate }}"
				[matTooltipPosition]="'below'"
				formControlName="useReducedDailyRate"
			>
				{{ 'ACTIVATE' | translate }}
			</mat-checkbox>
		</div>

		<!-- Exception for fixedCosts by vehicleType -->
		<mat-expansion-panel class="marginTop10 marginBottom20" fxLayoutGap="10px">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<i class="material-icons dialogIconExpansionPanel">euro_symbol</i> {{ 'DAILY_RATE_EXCEPTION' | translate }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
					<mat-select formControlName="vehicleTypeWithSpecificRate" placeholder="{{ 'VEHICLE_TYPE' | translate }}">
						<mat-option *ngFor="let requiredVehicle of VehicleTypes" [value]="requiredVehicle">
							{{ requiredVehicle | translateVehicleType }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
					<input
						matInput
						placeholder="{{ 'DAILY_RATE_EXCEPTION' | translate }}"
						type="text"
						formControlName="vehicleTypeWithSpecificRateFixedCosts"
						matTooltip="{{ 'DAILY_RATE_EXCEPTION_TT' | translate }}"
						[matTooltipPosition]="'below'"
					/>
					<div
						class="requiredMessageDiv"
						*ngIf="
							contractorRateForm.controls['vehicleTypeWithSpecificRateFixedCosts'].invalid &&
							(contractorRateForm.controls['vehicleTypeWithSpecificRateFixedCosts'].dirty ||
								contractorRateForm.controls['vehicleTypeWithSpecificRateFixedCosts'].touched)
						"
					>
						<span class="requiredMessage"
							>{{ 'RATE_VALIDATION_MESSAGE_1' | translate }} ({{ 'FOR_EXAMLE' | translate }}: 1,25)</span
						>
					</div>
				</mat-form-field>
			</div>
		</mat-expansion-panel>
		<!-- Special Contractor-Rules Implementation -->
		<mat-expansion-panel class="marginTop10 marginBottom20" fxLayoutGap="10px">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<i class="material-icons dialogIconExpansionPanel">euro_symbol</i> {{ 'SPECIAL_CASE_CLIENT' | translate }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
				<mat-checkbox
					[matTooltipPosition]="'below'"
					class="mat-primary mat-checkbox-small"
					formControlName="activateCustomerEmptyKmRuleCalculation"
					fxFlex="100"
					fxFlex.gt-sm="50"
					mat-theme="flutaroGrey"
					matTooltip="{{ 'SPECIAL_CASE_CLIENT_TT' | translate }}"
				>
					{{ 'ACTIVATE' | translate }}
				</mat-checkbox>
				<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
					<input
						[matTooltipPosition]="'below'"
						formControlName="includedEmptyKmForCustomerEmptyKmRuleCalculation"
						matInput
						matTooltip="{{ 'INCLUDED_EMPTY_KM_TT' | translate }}"
						placeholder="{{ 'INCLUDED_EMPTY_KM' | translate }}"
						type="text"
					/>
					<div
						*ngIf="
							contractorRateForm.controls['includedEmptyKmForCustomerEmptyKmRuleCalculation'].invalid &&
							(contractorRateForm.controls['includedEmptyKmForCustomerEmptyKmRuleCalculation'].dirty ||
								contractorRateForm.controls['includedEmptyKmForCustomerEmptyKmRuleCalculation'].touched)
						"
						class="requiredMessageDiv"
					>
						<span class="requiredMessage"
							>{{ 'RATE_VALIDATION_MESSAGE_1' | translate }} ({{ 'FOR_EXAMLE' | translate }}: 1,25)</span
						>
					</div>
				</mat-form-field>
				<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
					<input
						[matTooltipPosition]="'below'"
						formControlName="costForKmForCustomerEmptyKmRuleCalculation"
						matInput
						matTooltip="{{ 'KM_COSTS_TT' | translate }}"
						placeholder="{{ 'KM_COSTS' | translate }}"
						type="text"
					/>
					<div
						*ngIf="
							contractorRateForm.controls['costForKmForCustomerEmptyKmRuleCalculation'].invalid &&
							(contractorRateForm.controls['costForKmForCustomerEmptyKmRuleCalculation'].dirty ||
								contractorRateForm.controls['costForKmForCustomerEmptyKmRuleCalculation'].touched)
						"
						class="requiredMessageDiv"
					>
						<span class="requiredMessage"
							>{{ 'RATE_VALIDATION_MESSAGE_1' | translate }} ({{ 'FOR_EXAMLE' | translate }}: 1,25)</span
						>
					</div>
				</mat-form-field>
			</div>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field class="chipListCustomerIDsEmptyKmRule">
					<mat-chip-list #customerIDsEmptyKmRule>
						<mat-chip
							(removed)="removeCustomerIDEmptyKmRule(customerId)"
							*ngFor="let customerId of contractorRateForm.get('customerIDsForCustomerEmptyKmRuleCalculation').value"
						>
							{{ customerId }}
							<mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
						</mat-chip>
						<input
							(matChipInputTokenEnd)="addCustomerIDEmptyKmRule($event)"
							[matChipInputAddOnBlur]="addOnBlur"
							[matChipInputFor]="customerIDsEmptyKmRule"
							[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							placeholder="{{ 'CLIENT_ID_FOR_EMPTY_RULE' | translate }}"
						/>
					</mat-chip-list>
				</mat-form-field>
			</div>
		</mat-expansion-panel>
	</mat-card-content>
	<mat-card-actions fxLayout="row" fxLayoutAlign="end none">
		<button mat-button class="importButton mat-raised" (click)="onAbort()" type="button">
			<mat-icon>
				<i class="material-icons">close</i>
			</mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button mat-button class="importButtonDone mat-primary_flutaro" type="submit">
			<mat-icon>
				<i class="material-icons">done</i>
			</mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-card-actions>
</form>

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';

import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { DistributionCenterSettings, Synonym } from '@flutaro/package/lib/model/Synonym';
import { AppDialogSettings } from 'src/app/dialog/AppDialog';

@Component({
	selector: 'app-location-dialog',
	templateUrl: './location.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDialogComponent implements OnInit {
	isNew: boolean;
	location: Synonym;
	minutes: number[];
	hours: number[];
	days = [];
	locationBackup: Synonym;

	constructor(
		public synoynmService: FlutaroSynonymService,
		@Inject(MAT_DIALOG_DATA) public dialogData: AppDialogSettings<Synonym>,
		private notifications: FlutaroNotificationsProvider,
		public dialogRef: MatDialogRef<LocationDialogComponent>,
	) {}

	ngOnInit() {
		this.isNew = this.dialogData.isNew;
		this.location = cloneDeep(this.dialogData.data);
		this.locationBackup = cloneDeep(this.location);
		this.minutes = this.arr(12).map((x) => x * 5);
		this.hours = this.arr(24);
		this.createDaysArray();
	}

	/**
	 * Creates or destroys the Distribution Center Object on changed distributionCenter Attribute
	 */
	changeDistributionCenterForLocation() {
		if (this.location.distributionCenter) {
			this.location.distributionCenterSettings = new DistributionCenterSettings();
		} else {
			this.location.distributionCenterSettings = null;
		}
	}

	changeSynonymTimewindow() {
		this.location.location.timewindowInMinutes =
			(this.location.location.closingDaily.hour - this.location.location.openingDaily.hour) * 60;
		this.location.location.timewindowInMinutes =
			this.location.location.timewindowInMinutes -
			this.location.location.openingDaily.minute +
			this.location.location.closingDaily.minute;
	}

	/**
	 * This is a fix until synonym and location are seperated.
	 * The synonym object of addressSearch will be overwritten when choosing an address.
	 * To prevent reset of all location Data, use a different synonym just for the address.
	 */
	applySynonymFromAdressSearch() {
		this.location.addressLine = this.locationBackup.addressLine;
		this.location.zip = this.locationBackup.zip;
		this.location.city = this.locationBackup.city;
		this.location.country = this.locationBackup.country;
		this.location.toString = this.locationBackup.toString;
		this.location.latitude = this.locationBackup.latitude;
		this.location.longitude = this.locationBackup.longitude;
	}

	onSave() {
		if (!this.locationBackup.latitude) {
			this.notifications.showBasicSnackBar('Feld: Adresse ist ungültig.');
			return;
		}
		if (!this.location.code) {
			this.notifications.showBasicSnackBar('Feld: Name ist erforderlich.');
			return;
		}
		this.applySynonymFromAdressSearch();
		this.synoynmService.store(this.location);
		this.dialogRef.close();
	}

	onAbort() {
		this.dialogRef.close();
	}

	protected createDaysArray() {
		this.days.push({ value: 0, label: 'MONDAY' });
		this.days.push({ value: 1, label: 'TUESDAY' });
		this.days.push({ value: 2, label: 'WEDNESDAY' });
		this.days.push({ value: 3, label: 'THURSDAY' });
		this.days.push({ value: 4, label: 'FRIDAY' });
		this.days.push({ value: 5, label: 'SATURDAY' });
		this.days.push({ value: 6, label: 'SUNDAY' });
	}

	private arr(x: number) {
		let res = [];
		for (let i = 0; i < x; i++) {
			res.push(i);
		}
		return res;
	}
}

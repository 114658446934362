import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';
import { TimetableFilteredEntry } from '../data/TimetableFilterClasses';
import { Driver } from '@flutaro/package/lib/model/Driver';

@Component({
	selector: 'app-timetable-driver-entry',
	templateUrl: './timetable-driver-entry.component.html',
	styleUrls: ['./timetable-driver-entry.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableDriverEntryComponent extends AppBasicComponent {
	@Input() driver: Driver;
	@Input() timetableEntry: TimetableFilteredEntry;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import { RelationCapacityChange, RelationCapacityEntry, TimetableFilterType } from '../timetable/TimetableClasses';

@Component({
	selector: 'app-relation-capacities',
	templateUrl: './relation.capacity.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationCapacityComponent implements OnChanges {
	@Input() public capacity: RelationCapacityEntry;
	@Input() public isDashboard: boolean;
	@Input() public resetFilterStatus: boolean;
	@Output() relationFilterChange: EventEmitter<RelationCapacityChange> = new EventEmitter();
	exclusiveActivated: boolean = false;
	jobsExclusiveActivated: boolean = false;
	lastCapacityBackup: RelationCapacityEntry;

	constructor() {}

	ngOnChanges(changes) {
		if (changes['resetFilterStatus']) this.resetFilter();
		if (changes['capacity']) {
			if (
				this.exclusiveActivated &&
				this.capacity.entitiesIds.length === 0 &&
				this.lastCapacityBackup.entitiesIds.length > 0
			)
				this.filterEntityForRelation(true);
			if (
				this.jobsExclusiveActivated &&
				this.capacity.jobIds.length === 0 &&
				this.lastCapacityBackup.jobIds.length > 0
			) {
				this.filterJobsForRelation(true);
			}
			if (this.exclusiveActivated && this.capacity.entitiesIds.length !== this.lastCapacityBackup.entitiesIds.length) {
				if (this.capacity.entitiesIds.length > this.lastCapacityBackup.entitiesIds.length) {
					let addedFilterEntities = this.capacity.entitiesIds.filter(
						(x) => !this.lastCapacityBackup.entitiesIds.includes(x),
					);
					this.addEntitiesToFilter(addedFilterEntities, true);
				} else {
					let removedFilterEntities = this.lastCapacityBackup.entitiesIds.filter(
						(x) => !this.capacity.entitiesIds.includes(x),
					);
					this.addEntitiesToFilter(removedFilterEntities, false);
				}
			}
			if (this.jobsExclusiveActivated && this.capacity.jobIds.length !== this.lastCapacityBackup.jobIds.length) {
				if (this.capacity.jobIds.length > this.lastCapacityBackup.jobIds.length) {
					let addedFilterEntities = this.capacity.jobIds.filter((x) => !this.lastCapacityBackup.jobIds.includes(x));
					this.removeAddJobsFromFilter(addedFilterEntities, true);
				} else {
					let removedFilterEntities = this.lastCapacityBackup.jobIds.filter((x) => !this.capacity.jobIds.includes(x));
					this.removeAddJobsFromFilter(removedFilterEntities, false);
				}
			}
			this.lastCapacityBackup = cloneDeep(this.capacity);
		}
	}

	addEntitiesToFilter(entities, add: boolean) {
		this.relationFilterChange.emit(new RelationCapacityChange(TimetableFilterType.vehicle, add, entities));
	}

	removeAddJobsFromFilter(jobs, add: boolean) {
		this.relationFilterChange.emit(new RelationCapacityChange(TimetableFilterType.jobs, add, jobs));
	}

	resetFilter() {
		this.exclusiveActivated = false;
		this.jobsExclusiveActivated = false;
	}

	filterEntityForRelation(useBackup?: boolean) {
		this.exclusiveActivated = !this.exclusiveActivated;
		this.relationFilterChange.emit(
			new RelationCapacityChange(
				TimetableFilterType.vehicle,
				this.exclusiveActivated,
				useBackup ? this.lastCapacityBackup.entitiesIds : this.capacity.entitiesIds,
			),
		);
	}

	filterJobsForRelation(useBackup?: boolean) {
		this.jobsExclusiveActivated = !this.jobsExclusiveActivated;
		this.relationFilterChange.emit(
			new RelationCapacityChange(
				TimetableFilterType.jobs,
				this.jobsExclusiveActivated,
				useBackup ? this.lastCapacityBackup.jobIds : this.capacity.jobIds,
			),
		);
	}
}

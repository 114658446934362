import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	getVehiclesCurrentDriver,
	isTruck,
	isVehicleActivatedForCompanyNow,
} from '@flutaro/package/lib/functions/VehicleFunctions';
import { FlutaroGPSPosition, JobPairing } from '@flutaro/package/lib/model/Positiondata';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';

@Component({
	selector: 'app-map-planning-menu',
	templateUrl: './map-planning-menu.component.html',
	styleUrls: ['./map-planning-menu.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPlanningMenuComponent extends AppBasicComponent {
	@Input() reloadedJobs: JobWrapper[];
	@Input() showToolbar: boolean;
	@Output() loadJobsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
	$appUsers: BehaviorSubject<(Vehicle | Driver)[]> = new BehaviorSubject<(Vehicle | Driver)[]>([]);
	$showOverview: BehaviorSubject<{ value }> = new BehaviorSubject<{ value }>({ value: false });
	$jobPairingFilter: BehaviorSubject<JobPairing | null> = new BehaviorSubject<JobPairing | null>(null);

	/**
	 * Active GPS-App-Users are determined by the existence of GPS positions
	 * @param positions
	 */
	initAppUser(positions: FlutaroGPSPosition[]) {
		const vehiclesWithPositions = this.vehicles.filter(
			(vehicle) =>
				isTruck(vehicle) &&
				isVehicleActivatedForCompanyNow(vehicle, this.companySettings) &&
				positions.some((position) => vehicle.licensePlate === position.licensePlate),
		);
		const appUsers = vehiclesWithPositions.map((vehicle) =>
			vehicle.chooseEmailBasedOnVehicle ? vehicle : getVehiclesCurrentDriver(vehicle, this.drivers),
		);
		this.$appUsers.next(appUsers);
	}

	async showOverview() {
		this.$showOverview.next({ value: true });
	}

	loadMissingJobs(jobs: string[]) {
		console.debug(`map-planning-menu component loadMissingJobs`);
		this.loadJobsChange.emit(jobs);
	}

	setJobPairingFilter(pairingFilter: JobPairing | null) {
		console.log(`setJobPairingFilter, new pairing filter: ${pairingFilter}`);
		this.$jobPairingFilter.next(pairingFilter);
	}
}

<div fxLayout="row" fxLayoutAlign="start center" *ngIf="companySettings.isTenantActivated" fxLayoutGap="15px">
	<mat-icon>business</mat-icon>
	<mat-form-field class="tenantSelect">
		<mat-select
			(valueChange)="changeObjectsTenant($event)"
			[(value)]="tenant"
			[compareWith]="compareWithComponentContext"
			[disabled]="disabled"
			placeholder="{{ 'TENANT' | translate }}"
		>
			<mat-select-trigger *ngIf="invalidTenantId" class="invalidTenantIdIcon">
				<mat-icon>warning</mat-icon> {{ invalidTenantId }}
			</mat-select-trigger>
			<mat-option *ngIf="invalidTenantId" [value]="invalidTenantId" class="invalidOption" disabled>
				<mat-icon>warning</mat-icon>&nbsp;{{ invalidTenantId }}
			</mat-option>
			<mat-option [value]="0" [disabled]="isExternalJob">
				{{ 'TENANT_NO' | translate }}
			</mat-option>
			<mat-option *ngFor="let companyTenant of companySettings.tenants" [value]="companyTenant"
				>{{ companyTenant.name }}
				{{ companyTenant.id === tenant?.id && companyTenant.name !== tenant?.name ? '(' + tenant.name + ')' : '' }}
				<!-- Show name before migration, backuped -->
			</mat-option>
		</mat-select>
		<mat-error *ngIf="invalidTenantId">
			{{ 'TENANT_UNKNOWN' | translate }}
		</mat-error>
	</mat-form-field>
</div>

import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver, DriverBlock } from '@flutaro/package/lib/model/Driver';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { VehicleStatus } from '@flutaro/package/lib/model/VehicleStatus';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { TrailerSchedule } from '@flutaro/package/lib/model/AppScheduleClasses';
import { Synonym } from '@flutaro/package/lib/model/Synonym';

export class TimetableDateElement {
	width: string;
	date: Date;

	constructor(date: Date, width: string) {
		Object.assign(this, { date, width });
	}
}

export class TimetableJobEntry {
	jobWrapper: JobWrapper;
	wholeJobWidth: string;
	marginLeft: string;
	textLabel: string;
	// marginTop for Overlapping Jobs
	marginTop: string = '1px';
	width: string;
	color: string = '#40c1ac';
	backgroundSize: string;
	// Allow maximum of 3 capacity colors - keep each color to set background/color accordingly
	capacityColor1: string;
	capacityColor2: string;
	capacityColor3: string;
	showDarkText: boolean = false;
	hideTimewindow: boolean = false;
	overlapping = 0;

	constructor(job: JobWrapper) {
		this.jobWrapper = job;
	}
}

export class TimetableTimewindowEntry {
	marginLeft: string;
	marginTop: string = '1px';
	width: string;
	color: string = '#40c1ac';

	constructor(jobEntry: TimetableJobEntry) {
		this.marginTop = jobEntry.marginTop;
	}
}

export class TimetableBlockEntry {
	block: DriverBlock;
	width: string;
	marginLeft: string;
	isOverlapping: boolean;
	capacityColor1: string;
	capacityColor2: string; // Maximum of 2 matching Relations for now for Blocks
}

export enum TimetableFilterType {
	jobs = 'jobs',
	vehicle = 'vehicle',
}

export class RelationCapacityChange {
	source: string;
	value: boolean;
	elementIds: string[] = [];

	constructor(source: string, value: boolean, elementIds: string[]) {
		this.source = source;
		this.value = value;
		this.elementIds = elementIds;
	}
}

export class RelationStatusFilterChange {
	// Ids of all elements, that should be included after filtering for type
	elementIds: string[] | undefined;
	// Type of elements that should be filtered
	type: TimetableFilterType;

	constructor(type: TimetableFilterType) {
		this.type = type;
	}
}

export class RelationCapacityEntry {
	id = '';
	color = '';
	jobIds: string[] = [];
	entitiesIds: string[] = [];
}

export class TimetableVehicleStatus {
	status: VehicleStatus | undefined;
	marginLeft: string | undefined;
}

export class TimetableDriverEntry {
	driver: Driver = new Driver();
	workweekStart: TimetableWorkweekEntry | undefined;
	workweekEnd: TimetableWorkweekEntry | undefined;
	appointments: TimetableAppointmentEntry[] = [];
	isWeekEndOutsideCurrentWeek = false;

	constructor(driver: Driver) {
		this.driver = driver;
	}
}

export class TimetableVehicleEntry {
	vehicle: Vehicle;

	constructor(vehicle: Vehicle) {
		this.vehicle = vehicle;
	}
}

export class TimetableContractorEntry {
	contractor: Contractor;

	constructor(contractor: Contractor) {
		this.contractor = contractor;
	}
}

export class TimetableTrailerEntry {
	trailer: Vehicle;
	schedule: TrailerSchedule;

	constructor(trailer: Vehicle, schedule: TrailerSchedule) {
		this.trailer = trailer;
		this.schedule = schedule;
	}
}

export class TimetableWorkweekEntry {
	marginLeft = '';
	width = '';
}

export class TimetableAppointmentEntry extends TimetableWorkweekEntry {
	color = '';
	appointmentNote = '';
	appointmentLocation: Synonym | undefined;
}

export class PreviewCostRequest {
	jobId: string;
	vehicleId: string;

	constructor(jobId: string, vehicleId: string) {
		Object.assign(this, { jobId, vehicleId });
	}
}

export const TimetableDependentDataServices = [FlutaroCollection.JOB, FlutaroCollection.NOTE];
export const timetableHeight = 47;

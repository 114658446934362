import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppElementAutofocusDirective } from './form.directives';

@NgModule({
	declarations: [AppElementAutofocusDirective],
	exports: [AppElementAutofocusDirective],
	imports: [CommonModule],
})
export class AppFormModule {}

<mat-toolbar class="toolbarDriverVehicleSchedule">
	<button
		(click)="createSchedule()"
		[disabled]="isDisabledCreate || ($isEdit | async)"
		[matTooltipPosition]="'below'"
		class="mat-fab mat-flut addDriverScheduleButton"
		mat-fab
		matTooltip="{{ 'SCHEDULE_CREATE_TOOLTIP' | translate }}"
		type="button"
	>
		<mat-icon class="material-icons fab-icon-add">
			<i class="material-icons addIconDialogButton"> add </i>
		</mat-icon>
	</button>
</mat-toolbar>
<mat-list *ngIf="($isEdit | async) === false" class="dialogMdListContractor">
	<mat-list-item
		(click)="editSchedule(schedule)"
		*ngFor="let schedule of schedules | appOrderBy: '-startDate'"
		class="mat-2-line dialogMdListContractorItem"
	>
		<i class="material-icons driverVehicleChangeIconDialog">rv_hookup</i>
		<div class="mat-list-item-text" fxLayout="row">
			<h3 class="dialogMdListContractorTextH3" mat-line>
				{{ schedule.entityId | scheduleEntityName: type:vehicles:drivers }}:
			</h3>
			<h4 class="dialogListDriverVehicleScheduleComponentTextH4" mat-line>
				{{ schedule.startDate | relativeTime }} <span class="divider">-</span>
				<span [class.infinity]="!schedule.endDate">{{
					schedule.endDate ? (schedule.endDate | relativeTime) : '∞'
				}}</span>
			</h4>
		</div>
		<button
			(click)="deleteSchedule($event, schedule)"
			class="deleteButtonContractorMdList mat-secondary"
			mat-button
			type="button"
		>
			<i class="dialogMdListContractorDeleteIcon material-icons">delete</i>
		</button>
	</mat-list-item>
</mat-list>
<app-schedule-edit
	*ngIf="$isEdit | async"
	[type]="type"
	(isAbort)="abortScheduleChange()"
	(scheduleChange)="emitScheduleChange()"
	[isNew]="$isNew | async"
	[schedule]="scheduleInEdit"
	[schedules]="schedules"
	[vehicle]="vehicle"
	[vehicles]="vehicles"
	[drivers]="drivers"
>
</app-schedule-edit>

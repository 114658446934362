import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FlutaroChatChannel } from './chat';
import { NewMessageChange, NewMessagesForCompanyAttributeChange } from '@flutaro/package/lib/model/ChatClasses';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { AppChannelMessagesProvider } from '../app/app-channel-messages-provider.service';
import { ChatDataProvider } from './chat.data.provider';

@Component({
	selector: 'app-chat-menu',
	templateUrl: './chat.menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMenuComponent implements OnChanges, OnDestroy {
	@Input() userProfile: FlutaroUserProfile;
	@Input() channels: FlutaroChatChannel[];
	@Output() newMessagChange: EventEmitter<NewMessageChange> = new EventEmitter<NewMessageChange>();
	@Output() openJobChange: EventEmitter<string> = new EventEmitter<string>();
	channelSearchText: string;

	constructor(public chatProvider: ChatDataProvider, public channelMessagesProvider: AppChannelMessagesProvider) {}

	ngOnChanges(changes) {
		if (changes['channels']) {
			if (this.chatProvider.$openedChannel.getValue()) {
				const currentChannelUpdate = this.channels.find(
					(channel) => channel.id === this.chatProvider.$openedChannel.getValue().id,
				);
				if (currentChannelUpdate && currentChannelUpdate.newCompanyMessage) {
					this.createNewCompanyMessagesAttributeChange(this.chatProvider.$openedChannel.getValue().id, false);
				}
			}
		}
	}

	ngOnDestroy() {
		this.closeChannel();
	}

	openChannel(channel: FlutaroChatChannel) {
		this.chatProvider.openChannel(channel);
		if (channel.newCompanyMessage) {
			this.createNewCompanyMessagesAttributeChange(channel.id, false);
			this.chatProvider.updateUnreadChannelMessagesAsReceived(channel);
		}
	}

	closeChannel() {
		this.chatProvider.closeChannel();
	}

	private createNewCompanyMessagesAttributeChange(channelId, newCompanyMessageAttribute) {
		const newCompanyMessageAttributeChange = new NewMessagesForCompanyAttributeChange(
			channelId,
			newCompanyMessageAttribute,
		);
		this.chatProvider.updateChannelsHasNewMessagesForCompanyAttribute(
			newCompanyMessageAttributeChange.channelId,
			newCompanyMessageAttributeChange.newMessagesForCompanyAttribute,
		);
	}
}

<div
	(contextmenu)="onContextMenu($event, 'job')"
	[@hideJobComponent]="hideJob"
	[class.jobTimetableDeclinedStatusBorder]="jobEntry.jobWrapper.job.status === 6"
	[class.jobTimetableStartedStatusBorder]="jobEntry.jobWrapper.job.status === 7"
	[class.jobTimetableStatusDone]="jobEntry.jobWrapper.job.status === 8"
	[class.timetableJobStartsTheDayBefore]="jobEntry.marginLeft === '0%' || jobEntry.marginLeft === '0px'"
	[ngStyle]="customJobDisplay ? customStyle() : defaultStyle()"
	[style.width]="jobEntry.width"
	class="jobsNew"
>
	<app-vehicle-type-icon
		[vehicleType]="jobEntry.jobWrapper.job.requiredVehicle"
		[type]="'job'"
		[customJobDisplay]="customJobDisplay"
	></app-vehicle-type-icon>
	<div *ngIf="startLocationLabel" class="startLocationLabelTimetable">{{ startLocationLabel }}</div>
	<div *ngIf="!customJobDisplay" class="labelJob">
		<div [class.labelJobDark]="jobEntry.showDarkText" class="labelJobPickupAndDeliveryPlaces" fxLayout="row">
			<div fxFlex>
				<span>
					{{ jobEntry.textLabel }}
				</span>
			</div>
		</div>
		<div
			*ngIf="!jobEntry.hideTimewindow"
			[class.labelJobTimewindowDark]="jobEntry.showDarkText"
			class="labelJobTimewindow"
			fxLayout="row"
		>
			<div class="labelJobAlignRight" fxFlex>
				<span
					*ngIf="
						(jobEntry.jobWrapper | getFirstPickUp).earliestDate.getTime() !==
						(jobEntry.jobWrapper | getFirstPickUp).latestDate.getTime()
					"
				>
					{{ (jobEntry.jobWrapper | getFirstPickUp).earliestDate | appFormatDate: 'time' }}
					- {{ (jobEntry.jobWrapper | getFirstPickUp).latestDate | appFormatDate: 'time' }}
				</span>
				<span
					*ngIf="
						(jobEntry.jobWrapper | getFirstPickUp).earliestDate.getTime() ===
						(jobEntry.jobWrapper | getFirstPickUp).latestDate.getTime()
					"
					name="iata-or-synonym-start"
				>
					{{ (jobEntry.jobWrapper | getFirstPickUp).earliestDate | appFormatDate: 'time' }}
				</span>
			</div>
			<div class="labelJobSeperator">
				<span>➝</span>
			</div>
			<div class="labelJobAlignLeft" fxFlex>
				<span
					*ngIf="
						(jobEntry.jobWrapper | getLastDestination).earliestDate.getTime() !==
						(jobEntry.jobWrapper | getLastDestination).latestDate.getTime()
					"
				>
					{{ (jobEntry.jobWrapper | getLastDestination).earliestDate | appFormatDate: 'time' }}
					- {{ (jobEntry.jobWrapper | getLastDestination).latestDate | appFormatDate: 'time' }}
				</span>
				<span
					*ngIf="
						(jobEntry.jobWrapper | getLastDestination).earliestDate.getTime() ===
						(jobEntry.jobWrapper | getLastDestination).latestDate.getTime()
					"
				>
					{{ (jobEntry.jobWrapper | getLastDestination).earliestDate | appFormatDate: 'time' }}
				</span>
			</div>
		</div>
	</div>
	<div *ngIf="endLocationLabel" class="endLocationLabelTimetable">{{ endLocationLabel }}</div>
	<div *ngIf="customJobDisplay" class="labelJob">
		<div class="labelJobAlignRight" fxFlex style="margin-top: 5px; text-align: center">
			<span>
				{{ jobEntry.jobWrapper.jobLabel }}
			</span>
		</div>
	</div>
	<div
		*ngIf="jobEntry.jobWrapper.destinations && jobEntry.jobWrapper.destinations.length > 2"
		[matTooltipPosition]="'below'"
		[matTooltipShowDelay]="1500"
		class="jobTimetableMoreDestinationsIconDiv"
		matTooltip="{{ 'TIMETABLE_JOB_TT' | translate }}"
	>
		<i class="material-icons jobTimetableMoreDestinationsIcon">add_location</i>
	</div>
	<div *ngIf="jobEntry.jobWrapper.job.status === 6" class="jobTimetableStatusIcon jobDeclinedIcon"></div>
	<div *ngIf="jobEntry.jobWrapper.job.status === 7" class="jobTimetableStatusIcon jobStartedIcon"></div>
	<div *ngIf="jobEntry.jobWrapper.job.status === 8" class="jobTimetableStatusIcon jobDoneIcon"></div>
</div>
<div
	[matMenuTriggerFor]="jobsMenu"
	[style.left]="contextMenuPosition.x"
	[style.top]="contextMenuPosition.y"
	style="visibility: hidden; position: relative"
></div>
<mat-menu #jobsMenu="matMenu" [hasBackdrop]="true" class="flutaroMatMenu">
	<ng-template let-item="item" matMenuContent>
		<button (click)="sendJobToNoVehicle($event)" mat-menu-item>
			<i class="material-icons">notifications_none</i>
			<span class="contextMenuText">{{ 'TIMETABLE_JOB_SEND_JOB_TO_NO_DRIVER' | translate }}</span>
		</button>
	</ng-template>
</mat-menu>

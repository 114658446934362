import { Directive, Input } from '@angular/core';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { createDialogConfig } from '../form/FlutaroFormFunctions';
import { setTenantForNewElement } from '@flutaro/package/lib/functions/AppTenantFunctions';

@Directive()
export class TableAddComponent {
	@Input() userSettings: UserSettings;
	@Input() userProfile: FlutaroUserProfile;
	@Input() companySettings: CompanySettings;
	dialogComponent: any;

	constructor(public dialog: MatDialog) {}

	openAddDialog() {
		let dialogConfig = createDialogConfig();
		this.configureDialogAppearance(dialogConfig);
		dialogConfig.data = this.createDialogData();
		setTenantForNewElement(
			dialogConfig.data.data,
			dialogConfig.data.collectionName,
			this.companySettings,
			this.userProfile,
		);
		this.dialog.open(this.dialogComponent, dialogConfig);
	}

	createDialogData() {
		// Implement in Add-Components
		return null;
	}

	configureDialogAppearance(dialogConfig: MatDialogConfig) {
		return null;
	}
}

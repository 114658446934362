import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { TimeTable } from '../TimeTable';
import { addWeeks, getISODay, setISODay, subWeeks } from 'date-fns';
import { WeekDayChange } from './TimetableComponentClasses';
import { onlyMondayDateFilter } from '@flutaro/package/lib/functions/DataDateFunctions';

@Component({
	selector: 'app-timetable-weekday',
	templateUrl: './timetable.weekday.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [''],
})
export class TimetableWeekdayComponent implements OnChanges {
	@Input() timetable: TimeTable;
	@Input() dayOfWeek: number;
	@Output() weekDayChange: EventEmitter<WeekDayChange> = new EventEmitter<WeekDayChange>();
	public days: { value: number; date: Date }[] = [];
	onlyMondayDateFilter = onlyMondayDateFilter;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['timetable']) {
			console.log(`TimetableWeekdayComponent, create days due to timetable change`);
			this.createDaysArray();
		}
	}

	changeCalendarWeekFromPicker(selectedDate: Date) {
		if (selectedDate.getTime() === this.timetable.startDate.getTime()) {
			console.log(`changeCalendarWeekFromPicker, no changes in selected date - aborting`);
			return;
		}
		this.setDayOfWeekAndAnnounce(selectedDate, getISODay(selectedDate));
	}

	public prevWeek() {
		const newDate = subWeeks(this.timetable.startDate, 1);
		this.dayOfWeek = 1;
		this.weekDayChange.emit(new WeekDayChange(newDate, this.dayOfWeek, true));
	}

	public nextWeek() {
		this.dayOfWeek = 1;
		const newDate = addWeeks(this.timetable.startDate, 1);
		this.weekDayChange.emit(new WeekDayChange(newDate, this.dayOfWeek, true));
	}

	setDayInCurrentIntervalByNumber(newDay: number) {
		let timetableStartDate = setISODay(this.timetable.startDate, newDay);
		// Handle 8th day (Monday) in noSunday-Timetable in day-Timetable
		if (this.timetable.isDayTimetable && this.dayOfWeek === 8 && !(this.dayOfWeek === newDay))
			timetableStartDate = subWeeks(timetableStartDate, 1);
		this.setDayOfWeekAndAnnounce(timetableStartDate, newDay);
		this.changeDetectorRef.markForCheck();
	}

	private setDayOfWeekAndAnnounce(date: Date, day: number) {
		this.dayOfWeek = day;
		this.weekDayChange.emit(new WeekDayChange(date, this.dayOfWeek, false));
	}

	private createDaysArray() {
		this.days = [];
		for (let i = 1; i <= 8; i++) {
			if ((i === 7 && !this.timetable.isSundayTable) || (i === 8 && this.timetable.isSundayTable)) continue;
			this.days.push({ value: i, date: setISODay(new Date(), i) });
		}
	}
}

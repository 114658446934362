import { Pipe, PipeTransform } from '@angular/core';
import { countryCodeISO2ToISO3 } from '@flutaro/package/lib/model/LocationConstants';

@Pipe({
	name: 'locationCountryISO3',
})
export class LocationCountryISO3Pipe implements PipeTransform {
	transform(countryISO2: keyof typeof countryCodeISO2ToISO3): string {
		return countryCodeISO2ToISO3[countryISO2];
	}
}

import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	forwardRef,
} from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
	Validators,
} from '@angular/forms';
import { Cargo, FLUT_LoadingEquipmentTypes, LoadingEquipmentType } from '@flutaro/package/lib/model/CargoClasses';

@Component({
	selector: 'app-cargo',
	templateUrl: './cargo.component.html',
	styleUrls: ['./cargo.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CargoComponent),
			multi: true,
		},
	],
})
export class CargoComponent implements AfterContentInit, OnChanges {
	@Input() cargo: Cargo[];
	@Input() disabled: boolean;
	@Output() cargoChange = new EventEmitter<FormArray>();
	@Output() isCargoInputChange = new EventEmitter<boolean>();
	cargoForm: FormGroup;
	cargoControls: FormGroup;
	cargoStep: number;
	LoadingEquipmentTypes = FLUT_LoadingEquipmentTypes;
	isChildCargoInput: boolean = false;

	constructor(private fb: FormBuilder) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['cargo'] && this.cargo) {
			if (this.cargo.length === 0) {
				console.log('No Cargo in Job. Closing Input.');
				this.isCargoInputChange.next(false);
			}
		}
	}

	ngAfterContentInit() {
		this.initializeFormFields();
		this.cargoControls = <FormGroup>this.cargoForm.controls.cargoRows;
	}

	getCurrentNumberOfFormControls(): number {
		return Object.keys(this.cargoControls.controls).length;
	}

	getAllControlsOfFormGroup(): AbstractControl[] {
		return Object.keys(this.cargoControls.controls).map((key) => this.cargoControls.get(key));
	}

	writeValue(value: any) {
		this.cargo = value;
	}

	propagateChange = (_: any) => {};

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {}

	addCargo(): void {
		const control = <FormArray>(<FormArray>this.cargoForm.get('cargoRows'));
		control.push(this.initCargo(Array(new Cargo()))[0]);
	}

	removeCargo(cargoIndex: number): void {
		const control = <FormArray>(<FormArray>this.cargoForm.get('cargoRows'));
		control.removeAt(cargoIndex);
		this.isCargoInputChange.next(false);
	}

	setCargoInputstate(index: number, state: string): void {
		this.cargoStep = index;
		if (state === 'open') this.isCargoInputChange.emit(true);
		if (state === 'close') {
			this.cargoChange.emit(<FormArray>this.cargoForm.controls.cargoRows);
			this.isCargoInputChange.emit(false);
		}
	}

	nextCargo(): void {
		this.cargoStep++;
	}

	prevCargo(): void {
		this.cargoStep--;
	}

	compareLoadingEquipmentType(t1: LoadingEquipmentType, t2: LoadingEquipmentType): boolean {
		return t1 && t2 ? t1.shortDesc === t2.shortDesc : t1 === t2;
	}

	setChildCargo(index: number, event: FormArray): void {
		(<FormArray>this.cargoForm.controls['cargoRows']).at(index).patchValue({
			childCargo: event.value,
		});
	}

	setChildCargoInputStatus(event: boolean) {
		this.isChildCargoInput = event;
	}

	private initializeFormFields(): void {
		this.cargoForm = this.fb.group({
			cargoRows: this.fb.array(this.initCargo(this.cargo)),
		});
	}

	private initCargo(cargos: Cargo[]): FormGroup[] {
		let fbGroup = [];
		if (!cargos) return fbGroup;
		cargos.forEach((cargo) => {
			fbGroup.push(
				this.fb.group({
					amount: new FormControl({ value: cargo.amount, disabled: this.disabled }, Validators.compose([])),
					loadingEquipmentType: new FormControl(
						{
							value: cargo.loadingEquipmentType,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					childCargo: new FormControl({ value: cargo.childCargo, disabled: this.disabled }, Validators.compose([])),
					netWeight: new FormControl({ value: cargo.netWeight, disabled: this.disabled }, Validators.compose([])),
					netWeightUnit: new FormControl(
						{
							value: cargo.netWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					grossWeight: new FormControl(
						{
							value: cargo.grossWeight,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					grossWeightUnit: new FormControl(
						{
							value: cargo.grossWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					exchangeLoadingEquipment: new FormControl(
						{
							value: cargo.exchangeLoadingEquipment,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					contents: new FormControl(
						{
							value: cargo.contents,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					action: new FormControl(
						{
							value: cargo.action,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					references: new FormControl(
						{
							value: cargo.references,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					tareWeight: new FormControl(
						{
							value: cargo.tareWeight,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					tareWeightUnit: new FormControl(
						{
							value: cargo.tareWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					payableWeight: new FormControl(
						{
							value: cargo.payableWeight,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					payableWeightUnit: new FormControl(
						{
							value: cargo.payableWeightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					valueOfGoods: new FormControl(
						{
							value: cargo.valueOfGoods,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					valueOfGoodsUnit: new FormControl(
						{
							value: cargo.valueOfGoodsUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					insuranceValue: new FormControl(
						{
							value: cargo.insuranceValue,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					insuranceValueUnit: new FormControl(
						{
							value: cargo.insuranceValueUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					cubage: new FormControl(
						{
							value: cargo.cubage,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					cubageUnit: new FormControl(
						{
							value: cargo.cubageUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					length: new FormControl(
						{
							value: cargo.length,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					lengthUnit: new FormControl(
						{
							value: cargo.lengthUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					width: new FormControl(
						{
							value: cargo.width,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					widthUnit: new FormControl(
						{
							value: cargo.widthUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					height: new FormControl(
						{
							value: cargo.height,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					heightUnit: new FormControl(
						{
							value: cargo.heightUnit,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					loadingMeters: new FormControl(
						{
							value: cargo.loadingMeters,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					amountLoadingEquipmentDelivered: new FormControl(
						{
							value: cargo.amountLoadingEquipmentDelivered,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					amountLoadingEquipmentTaken: new FormControl(
						{
							value: cargo.amountLoadingEquipmentTaken,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					customerRemarks: new FormControl(
						{
							value: cargo.customerRemarks,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					hazardIndicator: new FormControl(
						{
							value: cargo.hazardIndicator,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					wasteIndicator: new FormControl(
						{
							value: cargo.wasteIndicator,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
					freezeIndicator: new FormControl(
						{
							value: cargo.freezeIndicator,
							disabled: this.disabled,
						},
						Validators.compose([]),
					),
				}),
			);
		});
		return fbGroup;
	}
}

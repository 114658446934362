import { Injectable } from '@angular/core';
import { FlutaroJobService } from '../../data/data-services/data.job.service';
import { CostsCalculationProvider } from '../../costs/costs.calculation.provider';
import { CommunicatorServerProvider } from '../../communicatorApp/communicator.server.provider';
import { cloneDeep } from 'lodash-es';
import { FlutaroDriverService } from '../../data/data-services/data.driver.service';
import { getISODay, isSameISOWeek } from 'date-fns';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { CostCalculationData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { changeDriversWeekEndOutSide, getDriversOutsideCurrentWeek } from '../../driver/DriverWeekEndOutsideFunctions';
import {
	changeJobsAttributesToUnassigned,
	isDriverChangeJobUpdate,
	isJobUnassigned,
} from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { getJobsLastDeliveryDate } from '@flutaro/package/lib/functions/job/DestinationFunctions';
import { CostsDataService } from '../../costs/costs.data.service';
import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';
import { BehaviorSubject } from 'rxjs';

/**
 * Handles changes related to jobs-data changes initiated by timetable drag/drop/changeDriver-Sidenav events.
 * 1) Calculates all job changes related to a Driver change in the jobs-data
 * 2) Stores the updated
 * 3) Announces the serverside updated version as Promise to calling method/component
 */
@Injectable()
export class TimetableJobStoreProvider {
	$isInCostCalculationOperation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	constructor(
		private jobService: FlutaroJobService,
		private costsProvider: CostsCalculationProvider,
		private communicator: CommunicatorServerProvider,
		private driverService: FlutaroDriverService,
		private costsData: CostsDataService,
		private vehicleService: FlutaroVehicleService,
	) {}

	/**
	 * @param job
	 * @param oldJob
	 * @param updateImmediately
	 */
	async storeJobAndHandleJobIntegration(
		job: JobWrapper,
		oldJob: JobWrapper,
		updateImmediately?: boolean,
	): Promise<boolean> {
		console.log('storeJobsAndHandleJobIntegration');
		const [jobUpdateError, jobUpdateSuccess] = await to(
			this.jobService.storeAndPublish(job, oldJob, updateImmediately),
		);
		if (jobUpdateError) {
			// TODO: handle me
			return jobUpdateError;
		}
		const [jobIntegrationError, jobIntegrationSuccess] = await to(
			this.handleDataIntegrationsOnJobsDriverChange(job, oldJob),
		);
		if (jobIntegrationError) {
			return jobIntegrationError;
		}
		return true;
	}

	async handleDataIntegrationsOnJobsDriverChange(newJob: JobWrapper, oldJob: JobWrapper) {
		// App related Actions
		if (oldJob.appSettings.driverUID)
			await this.communicator.deleteJobFromAppUserByDriverChange(oldJob, !newJob.appSettings?.appUserEmail);
		// Driver Flutaro Integration
		await this.updateDriverDataOnJobChange(oldJob, newJob);
		return true;
	}

	async updateDriverDataOnJobChange(oldJob: JobWrapper, newJob: JobWrapper): Promise<void> {
		console.log('updateDriverDataOnJobChange');
		if (isDriverChangeJobUpdate(oldJob, newJob)) return;
		console.debug(`updateDriverDataOnJobChange, driver change detected`);
		if (!oldJob.driver) {
			const oldDriver = this.driverService.getElementById(oldJob.driver);
			let oldDriverChanged = this.changeDriversWeekEndOutSideOnJobChange(oldDriver, oldJob, true);
			oldDriverChanged = oldDriverChanged || this.changeDriversWorkingOnWeekEndOnJobChange(oldDriver, oldJob, true);
			if (oldDriverChanged) await this.driverService.store(oldDriver);
		}

		if (!newJob.driver) {
			const newDriver = this.driverService.getElementById(newJob.driver);
			let newDriverChanged = this.changeDriversWeekEndOutSideOnJobChange(newDriver, newJob, false);
			newDriverChanged = newDriverChanged || this.changeDriversWorkingOnWeekEndOnJobChange(newDriver, newJob, false);
			if (newDriverChanged) await this.driverService.store(newDriver);
		}
	}

	openCostDialogForSidenavJob(job: JobWrapper) {
		console.debug(
			`openCostDialogForSidenavJob called for job ${job.job.identifier} with latestCalculationDate ${job.costCalculation.latestCalculationDate}`,
		);
		// Check for errors - show CostDialog on errors to display errors to user
		if (job.costCalculation.latestCalculationDate || !this.costsData.createJobCostData(job, job).errors.isValid) {
			console.debug(`openCostDialogForSidenavJob, job has latestCalculationDate or errors, opening cost dialog`);
			this.costsProvider.openCostDialogAndStoreJobCostChanges(job);
		} else {
			this.recalculateJobsCostsOnVehicleChange(job, job, true);
		}
	}

	async recalculateJobsCostsOnVehicleChange(
		job: JobWrapper,
		oldJob: JobWrapper,
		showCostDialog: boolean,
	): Promise<boolean> {
		const newVehicle = this.vehicleService.getElementById(job.vehicleId);
		const jobsCostData = this.costsData.createJobCostData(job, oldJob);
		console.debug(
			`recalculateJobsCostsOnVehicleChange, called for ${job.job.identifier} with newVehicleId ${newVehicle.backendId}`,
		);
		if (!newVehicle.isSpot && !jobsCostData.errors.isValid) {
			console.debug(
				`recalculateJobsCostsOnVehicleChange, error for cost calculation: jobs are overlapping, storing without cost calculation`,
			);
			return await this.storeJobAndHandleJobIntegration(job, oldJob, false);
		}

		const jobsCostCalculationData = showCostDialog
			? await this.costsProvider.openCostDialog(jobsCostData)
			: await this.costsProvider.calculateCostsWithoutDialog(jobsCostData);
		if (!jobsCostCalculationData) {
			console.debug(`recalculateJobsCostsOnVehicleChange, action aborted by user. Resetting data`);
			await this.jobService.resetJobsDataInternallyOnly(oldJob);
			return null;
		}
		return await this.storeAllJobsCostDataOnJobsDriverChange(jobsCostCalculationData);
	}

	async storeAllJobsCostDataOnJobsDriverChange(costData: CostCalculationData): Promise<boolean> {
		this.$isInCostCalculationOperation.next(true);
		// 1. Store calculatedJob first, creating new state for job and all vehicles
		await this.storeJobAndHandleJobIntegration(costData.job, costData.oldJobBackup, true);
		// 2. Store recalculatedJobs, using new state for vehicle of job
		// 2.1 Updated Fixed Costs can be taken directly from existing calculation
		this.costsProvider.recalculateJobsStartingOnSameDay(costData);
		// 2.2 Following Job Costs need to be recalculated with updated emptyKmAddress
		await this.costsProvider.recalculateFollowingJob(costData);
		let oldJobCostData;
		// 3. Check if job was removed from a vehicle which now requires a recalculation
		if (!isJobUnassigned(costData.oldJobBackup)) {
			console.debug(
				`storeAllJobsCostDataOnJobsDriverChange, oldJob was not unassigned, recalculating oldJobs vehicle ${costData.oldJobBackup.vehicleLicensePlate} costs`,
			);
			oldJobCostData = await this.costsProvider.recalculateAndAddVehiclesJobsOnJobUnassigned(costData.oldJobBackup);
			costData.addRecalculatedJobs(oldJobCostData.recalculatedJobs);
			costData.addBackupJobs(oldJobCostData.jobsBackups);
		}
		if (costData.recalculatedJobs.length) {
			console.log(
				`storeAllJobsCostDataOnJobsDriverChange, storing ${costData.recalculatedJobs.length} totalRecalculatedJobs.
				Vehicle ${costData.job.vehicleLicensePlate} (new job):
				  - ${costData.jobsStartingOnSameDay.length} jobsStartingOnSameDay,
				  - ${costData.followingJob ? 1 : 0} followingJob,
				Vehicle ${costData.oldJobBackup.vehicleLicensePlate} (old job):
				  -	${oldJobCostData?.jobsStartingOnSameDay?.length} jobsStartingOnSameDay,
				  - ${oldJobCostData?.followingJob ? 1 : 0} followingJob`,
			);
			await this.jobService.bulkStoreAndPublish(costData.recalculatedJobs, costData.jobsBackups);
		}
		this.$isInCostCalculationOperation.next(false);
		return true;
	}

	async dispatchJobToUnassigned(job: JobWrapper): Promise<boolean> {
		this.$isInCostCalculationOperation.next(true);
		console.debug(
			`dispatchJobToUnassigned, called for ${job.job.identifier} from vehicle ${job.vehicleLicensePlate}/${job.vehicleId}`,
		);
		const oldJob = cloneDeep(job);
		changeJobsAttributesToUnassigned(job);
		await this.storeJobAndHandleJobIntegration(job, oldJob, true);
		// Vehicles others jobs recalculations
		const oldJobsJobData = await this.costsProvider.recalculateAndAddVehiclesJobsOnJobUnassigned(oldJob);
		console.debug(
			`dispatchJobToUnassigned, recalculated ${oldJobsJobData.recalculatedJobs.length} jobs excluding oldJob`,
		);
		if (oldJobsJobData.recalculatedJobs.length)
			await this.jobService.bulkStoreAndPublish(oldJobsJobData.recalculatedJobs, oldJobsJobData.jobsBackups);
		this.$isInCostCalculationOperation.next(false);
		return true;
	}

	/**
	 * Changes drivers weekEndOutSide if driver is activated for the jobs date.
	 * Either deletes weekEndOutside for job as oldJob or replaces weekEndOutSide for new Job.
	 * @param driver
	 * @param job
	 * @param isJobRemove - if true no jobs weekEndOutside will be added thus a delete only action
	 */
	private changeDriversWeekEndOutSideOnJobChange(driver: Driver, job: JobWrapper, isJobRemove: boolean): boolean {
		if ((isJobRemove && !driver.weekEndOutsides.length) || !job) {
			return false;
		}
		console.debug(
			`changeDriversWeekEndOutSideOnJobChange, called for ${job.job.identifier} with isJobRemove ${isJobRemove}`,
		);

		const referenceDate = getJobsLastDeliveryDate(job);
		const driverIsWeekEndOutsideCurrentWeek = getDriversOutsideCurrentWeek(driver, referenceDate);
		if (!driverIsWeekEndOutsideCurrentWeek && !driver.isAlwaysOutside) return false;

		// Delete on noDriver change or replacement on job change
		return changeDriversWeekEndOutSide(driver, isJobRemove, referenceDate, this.jobService.getData(), job);
	}

	private changeDriversWorkingOnWeekEndOnJobChange(
		driver: Driver | null,
		job: JobWrapper,
		isJobRemove: boolean,
	): boolean {
		if (!driver || !job) {
			return false;
		}
		// Only act on jobs with delivery on saturday
		const jobsDate = getJobsLastDeliveryDate(job);
		if (getISODay(jobsDate) !== 6) return false;

		driver.weekEndWorkingDates = driver.weekEndWorkingDates.filter((date) => !isSameISOWeek(date, jobsDate));
		if (!isJobRemove) {
			driver.weekEndWorkingDates.push(jobsDate);
		} else {
			// Check if a second saturday starting and ending job activates weekEndWorking for the jobs weekend
			const driversJobs = this.jobService
				.getJobsForDriver(driver.backendId)
				.filter((driverJob) => driverJob.backendId !== job.backendId);
			const saturdayEndingJobs = driversJobs.filter((driversJob) => {
				const driverJobsWorkingWeekEndDate = getJobsLastDeliveryDate(driversJob);
				return isSameISOWeek(jobsDate, driverJobsWorkingWeekEndDate) && getISODay(driverJobsWorkingWeekEndDate) === 6;
			});
			if (saturdayEndingJobs.length > 0)
				driver.weekEndWorkingDates.push(getJobsLastDeliveryDate(saturdayEndingJobs[0]));
		}
		return true;
	}
}

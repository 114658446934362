import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { SettingsUserComponent } from './settings.user.component';
import { UserSettingsProvider } from './settings.user.provider';
import { MenuModule } from '../menu/menu.module';
import { SettingsTimetableOrderingSelectComponent } from './settings-timetable-ordering-select/settings-timetable-ordering-select.component';
import { SettingsTimetableJobTitlesComponent } from './settings-timetable-job-titles/settings-timetable-job-titles.component';

@NgModule({
	declarations: [SettingsUserComponent, SettingsTimetableOrderingSelectComponent, SettingsTimetableJobTitlesComponent],
	imports: [CommonModule, FormsModule, FlutaroCustomMaterialModule, MenuModule],
	exports: [],
	providers: [UserSettingsProvider],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class SettingsModule {}

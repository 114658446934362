<div fxLayout="row" fxLayoutGap="20px">
	<mat-select
		[(ngModel)]="attribute"
		[placeholder]="'TIMETABLE_SORTING_ATTRIBUTE_SELECT_PLACEHOLDER' | translate"
		fxFlex="60"
		(valueChange)="changeAttribute($event)"
	>
		<mat-option [value]="APP_SORTING_ATTRIBUTE.VehicleCarType">
			{{ 'VEHICLE_TYPE' | translate }}
		</mat-option>
		<mat-option [value]="APP_SORTING_ATTRIBUTE.VehicleLicensePlate">
			{{ 'LICENSEPLATE' | translate }}
		</mat-option>
		<mat-option [value]="APP_SORTING_ATTRIBUTE.ContractorName">
			{{ 'CONTRACTOR_NAME' | translate }}
		</mat-option>
		<mat-option [value]="APP_SORTING_ATTRIBUTE.DriverName" *ngIf="!companySettings.isDriverDeactivated">
			{{ 'DRIVER_NAME' | translate }}
		</mat-option>
	</mat-select>
	<mat-select
		[(ngModel)]="sorting"
		[placeholder]="'TIMETABLE_SORTING_DIRECTION_SELECT_PLACEHOLDER' | translate"
		fxFlex="40"
		(valueChange)="changeSorting($event)"
	>
		<mat-option [value]="APP_SORTING.ASCENDING">
			{{ 'ASCENDING' | translate }}
		</mat-option>
		<mat-option [value]="APP_SORTING.DESCENDING">
			{{ 'DESCENDING' | translate }}
		</mat-option>
	</mat-select>
</div>

<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground locationDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">pin_drop</i>
		{{ 'LOCATION' | translate }}
		<span class="dialogToolbarObjectName">{{ location.code }}</span>
	</h1>
</mat-toolbar>

<mat-dialog-content>
	<div class="layout-padding layout-margin" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
		<div fxLayout="column" fxFlex="100" fxLayoutGap="10px">
			<h4 class="dialogHeadlineContent">
				<i class="material-icons dialogIcon">assignment</i> {{ 'LOCATION_DATA' | translate }}
			</h4>

			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field fxFlex="100">
					<input matInput required placeholder="Name" name="code" [(ngModel)]="location.code" ng-minlength="4" />
				</mat-form-field>
			</div>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field class="openClosedLabel" fxFlex="100" fxFlex.gt-sm="50">
					<mat-select placeholder="{{ 'TYPE' | translate }}" [(ngModel)]="location.location.type">
						<mat-option [value]="''"></mat-option>
						<mat-option [value]="'STO'">Store</mat-option>
						<mat-option [value]="'LSC'">Local Service Center</mat-option>
						<mat-option [value]="'PU'">Parcel Unit</mat-option>
						<mat-option [value]="'M-CDS'">Multi-Consumer Distribution Center</mat-option>
						<mat-option [value]="'sonst.'">{{ 'OTHER' | translate }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-checkbox
					fxFlex="100"
					fxFlex.gt-sm="50"
					[(ngModel)]="location.distributionCenter"
					(change)="changeDistributionCenterForLocation()"
					matTooltip="{{ 'DISTRIBUTION_CENTER_TT' | translate }}"
					[matTooltipPosition]="'below'"
					class="locationDialogDCCheckbox"
					style="margin-top: 10px"
				>
					Distribution Center (DC)
					<i class="material-icons dialogValueIcon">store_mall_directory</i>
				</mat-checkbox>
			</div>
			<div fxLayout="row">
				<app-address-search
					[(synonym)]="locationBackup"
					[enableLocations]="false"
					(synonymChange)="applySynonymFromAdressSearch()"
					fxFlex="100"
				>
				</app-address-search>
			</div>
			<div fxLayout="column" *ngIf="location.distributionCenter" fxLayoutGap="20px">
				<h4 class="dialogHeadlineContentNotFirst">
					<i class="material-icons dialogIcon">access_time</i> {{ 'DISTRIBUTION_CENTER_CLOSING_TIME' | translate }}
				</h4>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field class="openClosedLabel">
							<mat-select
								placeholder="{{ 'CLOSED_FROM' | translate }}"
								[(ngModel)]="location.distributionCenterSettings.closingStartWeekday"
							>
								<mat-option *ngFor="let day of days" [value]="day.value">
									{{ day.label | translate }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								placeholder="{{ 'HOUR' | translate }}"
								[(ngModel)]="location.distributionCenterSettings.closingStartTime.hour"
							>
								<mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }} </mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								placeholder="{{ 'MINUTE' | translate }}"
								[(ngModel)]="location.distributionCenterSettings.closingStartTime.minute"
							>
								<mat-option *ngFor="let minute of minutes" [value]="minute">
									{{ minute }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field class="openClosedLabel">
							<mat-select
								placeholder="{{ 'CLOSED_TILL' | translate }}"
								[(ngModel)]="location.distributionCenterSettings.closingEndWeekday"
							>
								<mat-option *ngFor="let day of days" [value]="day.value">
									{{ day.label | translate }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								placeholder="{{ 'HOUR' | translate }}"
								[(ngModel)]="location.distributionCenterSettings.closingEndTime.hour"
							>
								<mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }} </mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								placeholder="{{ 'MINUTE' | translate }}"
								[(ngModel)]="location.distributionCenterSettings.closingEndTime.minute"
							>
								<mat-option *ngFor="let minute of minutes" [value]="minute">
									{{ minute }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>

			<div fxLayout="column" *ngIf="!location.distributionCenter" fxLayoutGap="20px">
				<h4 class="dialogHeadlineContentNotFirst">
					<i class="material-icons dialogIcon">access_time</i> {{ 'LOCATION_OPENING_TIME' | translate }}
				</h4>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field class="openClosedLabel">
							<mat-select [(ngModel)]="location.location.openingWeekday" placeholder="{{ 'OPEN_FROM' | translate }}">
								<mat-option *ngFor="let day of days" [value]="day.value">
									{{ day.label | translate }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								[(ngModel)]="location.location.openingDaily.hour"
								(change)="changeSynonymTimewindow()"
								placeholder="{{ 'HOUR' | translate }}"
							>
								<mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }} </mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								[(ngModel)]="location.location.openingDaily.minute"
								(change)="changeSynonymTimewindow()"
								placeholder="{{ 'MINUTE' | translate }}"
							>
								<mat-option *ngFor="let minute of minutes" [value]="minute">
									{{ minute }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field class="openClosedLabel">
							<mat-select [(ngModel)]="location.location.closingWeekday" placeholder="{{ 'OPEN_TILL' | translate }}">
								<mat-option *ngFor="let day of days" [value]="day.value">
									{{ day.label | translate }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="hourMinuteFormField">
							<mat-select
								[(ngModel)]="location.location.closingDaily.hour"
								(change)="changeSynonymTimewindow()"
								placeholder="{{ 'HOUR' | translate }}"
							>
								<mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }} </mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="hourMinuteFormField">
							<mat-select
								[(ngModel)]="location.location.closingDaily.minute"
								(change)="changeSynonymTimewindow()"
								placeholder="{{ 'MINUTE' | translate }}"
							>
								<mat-option *ngFor="let minute of minutes" [value]="minute">
									{{ minute }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
				<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
					<h4>
						<div class="jobDialogIcons pickupImage"></div>
						{{ 'PICKUP' | translate }}
					</h4>
					<app-job-regulating-time
						[(regulating)]="location.location.pickupRegulatingTimeInMinutes"
					></app-job-regulating-time>
					<mat-checkbox [(ngModel)]="location.location.pickupIsFixed" class="locationDialogCheckbox">
						{{ 'PICKUP_IS_FIXED' | translate }}
					</mat-checkbox>
				</div>
				<mat-divider></mat-divider>
				<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
					<h4 class="dialogHeadlineDelivery">
						<div class="jobDialogIcons deliveryImage"></div>
						{{ 'DELIVERY' | translate }}
					</h4>
					<app-job-regulating-time
						[(regulating)]="location.location.deliveryRegulatingTimeInMinutes"
					></app-job-regulating-time>
					<mat-checkbox [(ngModel)]="location.location.deliveryIsFixed" class="locationDialogCheckbox">
						{{ 'DELIVERY_IS_FIXED' | translate }}
					</mat-checkbox>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<app-flutaro-audit
	*ngIf="!isNew"
	[createdAt]="location.createdAt"
	[lastModifiedBy]="location.lastModifiedBy"
	[createdBy]="location.createdBy"
	[lastModifiedAt]="location.lastModifiedAt"
>
</app-flutaro-audit>
<!--SAVE AND CANCEL-->
<mat-dialog-actions fxLayout="row" class="dialogFooterMD2" fxLayoutAlign="end none">
	<button mat-button class="importButton mat-raised" (click)="onAbort()">
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button mat-button class="importButtonDone mat-primary_flutaro" (click)="onSave()">
		<mat-icon>
			<i class="material-icons material-icons-white">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { ClientModule } from '../client/client.module';
import { ContractorModule } from '../contractor/contractor.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DriverModule } from '../driver/driver.module';
import { ImportModule } from '../import/import.module';
import { JobModule } from '../job/job.module';
import { RelationModule } from '../relation/relations.module';
import { SynonymModule } from '../synonym/synonym.module';
import { VehicleModule } from '../vehicle/vehicle.module';
import { AddressesMenuComponent } from './menu.addresses.component';
import { MenuComponent } from './menu.component';
import { MenuComponentProvider } from './menu.component.provider';
import { DashboardMenuComponent } from './menu.dashboard.component';
import { SettingsMenuComponent } from './menu.import.component';
import { AppMenuJobsComponent } from './menu.jobs.component';
import { SetupMenuComponent } from './menu.setup.component';
import { FlutaroSearchService } from './toolbar/search.service';
import { CorrectionModule } from '../interfaces/ExternalJob/correction/correction.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ComponentsModule } from '../app/components/components.module';
import { DialogModule } from '../dialog/dialog.module';

@NgModule({
	declarations: [
		MenuComponent,
		SetupMenuComponent,
		AddressesMenuComponent,
		AppMenuJobsComponent,
		SettingsMenuComponent,
		DashboardMenuComponent,
	],
	exports: [
		MenuComponent,
		SetupMenuComponent,
		AddressesMenuComponent,
		AppMenuJobsComponent,
		SettingsMenuComponent,
		DashboardMenuComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		VehicleModule,
		ClientModule,
		DriverModule,
		RelationModule,
		SynonymModule,
		JobModule,
		ImportModule,
		DashboardModule,
		ContractorModule,
		DialogModule,
		CorrectionModule,
		MatTabsModule,
		MatTabsModule,
		MatTabsModule,
		ComponentsModule,
	],
	providers: [FlutaroSearchService, MenuComponentProvider],
})
export class MenuModule {}

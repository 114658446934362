<div class="mainWrapperImportView" fxLayout="column">
	<div class="importMainRow" fxLayout="row" fxLayoutGap="20px">
		<div class="dataImportTableInputContainer" fxLayout="column">
			<label>{{ 'IMPORT_MAIN_LABEL' | translate }}</label>
			<mat-form-field>
				<mat-select (ngModelChange)="changeDataType($event)" [(ngModel)]="dataType">
					<mat-option *ngFor="let type of dataTypes" [value]="type" class="importSelection">
						<div *ngIf="type === 'DRIVER'" class="importIcon driverObjectIcon" disabled></div>
						<div *ngIf="type === 'VEHICLE'" class="importIcon vehicleObjectIcon" disabled></div>
						<div *ngIf="type === 'JOB'" class="importIcon jobObjectIcon"></div>
						{{ type | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div fxLayout="row" fxLayoutGap="20px">
			<mat-divider></mat-divider>
			<div>
				<input
					(change)="excelProvider.importExcel($event, dataType)"
					accept=".csv,.xlsx,.xls"
					class="inputfile"
					id="file"
					name="file"
					type="file"
				/>
				<label for="file">
					<i class="material-icons importTableUploadIcon">file_upload</i>
					<span *ngIf="!excelProvider.fileName">{{ 'IMPORT_FILE_NAME' | translate }}</span>
					<span *ngIf="excelProvider.fileName">{{ excelProvider.fileName }}</span>
				</label>
				<p class="importTableCopyPasteMessage">
					{{ 'IMPORT_COPY_PASTE_MESSAGE_1' | translate }}
					<a *ngIf="dataType === 'JOB'" class="importTableSampleFile" href="assets/docs/Auftrag.import.sample.xls"
						><i class="material-icons importTableDownloadIcon">file_download</i>
						{{ 'IMPORT_COPY_PASTE_MESSAGE_2' | translate }}</a
					>
					<a *ngIf="dataType === 'DRIVER'" class="importTableSampleFile" href="assets/docs/Fahrer.import.sample.xls"
						><i class="material-icons importTableDownloadIcon">file_download</i>
						{{ 'IMPORT_COPY_PASTE_MESSAGE_2' | translate }}</a
					>
					<a *ngIf="dataType === 'VEHICLE'" class="importTableSampleFile" href="assets/docs/Fahrzeug.import.sample.xls"
						><i class="material-icons importTableDownloadIcon">file_download</i>
						{{ 'IMPORT_COPY_PASTE_MESSAGE_2' | translate }}</a
					>
					{{ 'IMPORT_COPY_PASTE_MESSAGE_3' | translate }} (Copy&amp;Paste)
				</p>
			</div>
			<mat-divider></mat-divider>
			<app-excel-import-status
				[currentValidationStatus]="validationProvider.currentValidationStatus"
				[importType]="dataType"
			>
			</app-excel-import-status>
			<mat-divider></mat-divider>
		</div>
	</div>
	<div class="loadingIndicatorWrapper">
		<div *ngIf="excelProvider.showProgressCircle" class="loadingIndicatorBackgroundFixed">
			<div class="loadingIndicatorCircle"></div>
			<span class="loadingIndicatorText">{{ 'IMPORT_LOADING_INDICATOR_1' | translate }}</span>
			<span class="loadingIndicatorText amountOfDataBeingProcessedIndicator"
				>({{ 'STILL' | translate }} {{ excelProvider.$awaitingAddressesCounter | async }}
				{{ 'RECORDS' | translate }})</span
			>
		</div>
		<div class="importTableWrapper">
			<hot-table
				[colHeaders]="tableHeaders"
				[colWidths]="tableColumnWidths"
				[columns]="tableColumns"
				[data]="excelProvider.excelImportData"
				[settings]="settings"
				hotId="hotInstance"
			>
			</hot-table>
		</div>
		<div class="tableActionButtons formFooter" fxLayout="row" fxLayoutAlign="start end">
			<button (click)="emptyData()" class="importButton mat-raised" mat-button>
				<mat-icon><i class="material-icons">clear_all</i></mat-icon>
				{{ 'IMPORT_CLEAR_TABLE' | translate }}
			</button>
			<button
				(click)="saveData()"
				[disabled]="isInImportState"
				class="importButtonDone mat-primary_flutaro"
				mat-button
				style="margin-right: 20px"
			>
				<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
				{{ 'SAVE_DATA' | translate }}
			</button>
		</div>
	</div>
</div>

import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { FlutaroGPSPosition } from '@flutaro/package/lib/model/Positiondata';

declare var google: any;

export class AppMapMarker {
	position: google.maps.LatLngLiteral;
	options: google.maps.MarkerOptions;
	data: AppMapPosition;

	constructor(options: google.maps.MarkerOptions, position: google.maps.LatLngLiteral, data?: AppMapPosition) {
		Object.assign(this, { position, options, data });
	}
}

export class FlutaroDirectionResult {
	// implements min-version of DirectionsResult
	request;
	routes: DirectionResultRoute[] = [];
	geocoded_waypoints: google.maps.DirectionsGeocodedWaypoint[] = [];

	constructor(request) {
		Object.assign(this, { request });
	}
}

export class DirectionResultRoute {
	copyrights: string;
	legs: DirectionResultLeg[] = [];
}

export class DirectionResultLeg {
	start_location: google.maps.LatLngLiteral;
	end_location: google.maps.LatLngLiteral;
	steps: DirectionResultStep[];
}

export class DirectionResultStep {
	travel_mode: google.maps.TravelMode = google.maps.TravelMode.DRIVING;
	path: google.maps.LatLngLiteral[] = [];
}

export class AppMapPosition extends FlutaroGPSPosition {
	job: JobWrapper;
	type: AppMarkerType;
	destinationIndex: number;

	constructor(position: FlutaroGPSPosition, job: JobWrapper, type: AppMarkerType, destinationIndex?: number) {
		super();
		Object.assign(this, position);
		Object.assign(this, {
			job,
			type,
			destinationIndex,
		});
	}
}

export enum AppMarkerType {
	EMPTY_KM_ADDRESS = 'EmptyKmAddress',
	DESTINATION = 'Destination',
	ADDED_STOP = 'AddedStop',
	GPS_POSITION = 'GPSPosition',
}

import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[synchronDriverScrolling]',
})
export class SynchronousDriversScrollingDirective {
	@HostListener('scroll', ['$event'])
	onScroll(event: KeyboardEvent) {
		let driverSection = document.getElementById('driverSection');
		let timetableSection = document.getElementById('roomTimeline');
		timetableSection.scrollTop = driverSection.scrollTop;
		event.preventDefault();
	}
}

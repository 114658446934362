import { FormControl, Validators } from '@angular/forms';
import { Synonym } from '@flutaro/package/lib/model/Synonym';

const REGEXP_containsValidCurrencyCharacters = new RegExp('^d+([,]d{1,2})?$');

// create your class that extends the angular validator class
export class CurrencyFormValidator extends Validators {
	static validateCurrencyNumber(control: FormControl) {
		// first check if the control has a value
		if (control.value && control.value.length > 0) {
			const validChars = !REGEXP_containsValidCurrencyCharacters.test(control.value);
			return validChars ? null : { invalid_characters: control.value };
		} else {
			return null;
		}
	}
}

export class AddressFormValidator extends Validators {
	// Validation of an address input being valid by lat/lon. No input/address is also valid, the required validator determines if input is required
	static validateAddress(control: FormControl) {
		if (!control.value?.toString()) return null;
		const address: Synonym = control.value;
		const isValid = !!address.latitude && !!address.longitude;
		return isValid ? null : { invalid_address: true };
	}
}

export class LegalCharacterFormValidator extends Validators {
	static validateForForbiddenChars(control: FormControl) {
		// Allows Characters and Space, other Symbols are forbidden
		const FORBIDDEN_CHARS = /[`!@#$%^&*()+={};':"\\|,.<>\/?~]/;
		// first check if the control has a value
		if (!control.value?.length) return null;
		const containsForbiddenChars = FORBIDDEN_CHARS.test(control.value);
		const startsWithEmptySpace = control.value.startsWith(' ');
		const isValid = !containsForbiddenChars && !startsWithEmptySpace;
		// TODO: add explanation which characters are invalid and show to user
		// if there are matches return an object, else return null.
		return isValid ? null : { forbidden_characters: control.value };
	}
}

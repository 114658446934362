import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { CompanyService } from '../company/company.service';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { transformObjectIntoSynonymClass } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { isJobStatusExternalPushJobChange } from '@flutaro/package/lib/functions/integrations/JobExternalSystemIntegrationFunctions';

@Injectable({
	providedIn: 'root',
})
export class AtheneService {
	constructor(
		private http: HttpClient,
		private companyService: CompanyService,
	) {}

	async getIsJobIdentifierUnique(identifier: string): Promise<boolean> {
		console.log(`getIsJobIdentifierUnique, called for ${identifier}`);
		let [atheneError, isJobIdentifierUnique] = await to(
			lastValueFrom(this.http.get<Promise<boolean>>(`${environment.ATHENE_URL}/jobs/isUniqueIdentifier/${identifier}`)),
		);
		if (atheneError) {
			console.debug(`getIsJobIdentifierUnique, ERROR from Athene: ${atheneError}`);
			return null;
		}
		return isJobIdentifierUnique;
	}

	async requestSynonymForAddress(addressLine: string): Promise<Synonym> {
		if (!addressLine || addressLine.length < 5) {
			const location = new Synonym();
			location.addressLine = addressLine;
			return location;
		}
		const addressLineRequestString = addressLine.replace(/\//g, ' ');
		let [addressError, address] = await to(
			lastValueFrom(this.http.get<Promise<Synonym>>(`${environment.ATHENE_URL}/address/${addressLineRequestString}`)),
		);
		if (addressError) {
			console.debug(`requestSynonymForAddress, ERROR: ${addressError}`);
			return null;
		}
		return transformObjectIntoSynonymClass(address);
	}

	async handleJobStatusExternalSystemIntegration(newJob: JobWrapper, oldJob: JobWrapper) {
		const companySettings: CompanySettings = this.companyService.$companySettings.getValue();
		// External System Integration
		if (!companySettings?.isJobStatusPushAPIActivated) return;
		console.log(`handleJobStatusExternalSystemIntegration, checking for JobStatus push customer integration`);
		if (!isJobStatusExternalPushJobChange(newJob, oldJob)) return;
		console.log(
			`handleJobStatusExternalSystemIntegration, detected job change with JobStatus push action - sending message to customer system`,
		);
		return await this.sendJobStatusUpdateToExternal(newJob);
	}

	private async sendJobStatusUpdateToExternal(job: JobWrapper) {
		let [atheneError, jobStatusPushSuccess] = await to(
			lastValueFrom(this.http.post<Promise<boolean>>(`${environment.ATHENE_URL}/jobs/status`, job)),
		);
		if (atheneError) {
			console.error(`sendJobStatusUpdate, ERROR from Athene: ${atheneError}`);
			return null;
		}
		console.debug(`sendJobStatusUpdate, successfully sent status update to athene`);
		return true;
	}
}

<div fxLayout="row" fxLayoutAlign="start center" class="app-position-pairing-select-component-style">
	<img
		*ngIf="pairing"
		[src]="pairing === 'ON' ? 'assets/pictures/mapPinOnline.svg' : 'assets/pictures/mapPinOffline.svg'"
	/>
	<mat-form-field class="position-pairing-select-form-style">
		<mat-select (valueChange)="selectPairing($event)" class="app-select-label" placeholder="Status Filter">
			<mat-option [value]="null" class="app-select-label"> </mat-option>
			<mat-option [value]="'ON'" class="app-select-label">
				<img src="assets/pictures/mapPinOnline.svg" />
				ON
			</mat-option>
			<mat-option [value]="'OFF'" class="app-select-label">
				<img src="assets/pictures/mapPinOffline.svg" />
				OFF
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>

import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { IElement } from '@flutaro/package/lib/model/IElement';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { Client } from '@flutaro/package/lib/model/Client';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

export class AppDialogBasicData {
	userSettings: UserSettings;
	userProfile: FlutaroUserProfile;
	companySettings: CompanySettings;

	constructor(userSettings: UserSettings, userProfile: FlutaroUserProfile, companySettings: CompanySettings) {
		this.userSettings = userSettings;
		this.userProfile = userProfile;
		this.companySettings = companySettings;
	}
}

export class AppDialogSettings<T extends IElement> extends AppDialogBasicData {
	data: T;
	collectionName: FlutaroCollection;
	isNew: boolean;

	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		data: T,
		collectionName: FlutaroCollection,
	) {
		super(userSettings, userProfile, companySettings);
		// TODO: check if updating typescript can fix these stupid old-mechanism and support the new one with assign
		this.data = data;
		this.collectionName = collectionName;
		this.isNew = !data.backendId || !data.company;
	}
}

export class JobDialogData extends AppDialogSettings<JobWrapper> {
	vehicles: Vehicle[] = [];
	drivers: Driver[] = [];
	clients: Client[] = [];

	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		job: JobWrapper,
		clients: Client[],
		vehicles: Vehicle[],
		drivers: Driver[],
	) {
		super(userSettings, userProfile, companySettings, job, FlutaroCollection.JOB);
		Object.assign(this, { clients, vehicles, drivers });
	}
}

export class DestinationDialogData {
	destination: JobDestination;
	prevDestination: JobDestination;
	disabled = false;

	constructor(destination: JobDestination, prevDestination: JobDestination, disabled = false) {
		// TODO: check if updating typescript can fix these stupid old-mechanism and support the new one with assign
		this.destination = destination;
		this.prevDestination = prevDestination;
		Object.assign(this, { destination, prevDestination, disabled });
	}
}

export class VehicleDialogData extends AppDialogSettings<Vehicle> {
	vehicles: Vehicle[] = [];
	drivers: Driver[] = [];
	contractor: Contractor | undefined;

	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		vehicle: Vehicle,
		vehicles: Vehicle[],
		drivers: Driver[],
		contractor?: Contractor,
	) {
		super(userSettings, userProfile, companySettings, vehicle, FlutaroCollection.VEHICLE);
		Object.assign(this, { vehicles, drivers, contractor });
	}
}

export class ContractorDialogData extends AppDialogSettings<Contractor> {
	drivers: Driver[] = [];
	vehicles: Vehicle[] = [];

	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		contractor: Contractor,
		vehicles: Vehicle[],
		drivers: Driver[],
	) {
		super(userSettings, userProfile, companySettings, contractor, FlutaroCollection.CONTRACTOR);
		Object.assign(this, { drivers, vehicles });
	}
}

export class DriverDialogData extends AppDialogSettings<Driver> {
	vehicle: Vehicle | undefined;

	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		driver: Driver,
		vehicle?: Vehicle,
	) {
		super(userSettings, userProfile, companySettings, driver, FlutaroCollection.DRIVER);
		Object.assign(this, { vehicle });
	}
}

export class ClientDialogData extends AppDialogSettings<Client> {
	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		client: Client,
	) {
		super(userSettings, userProfile, companySettings, client, FlutaroCollection.CLIENT);
	}
}

export class RelationDialogData extends AppDialogSettings<Relation> {
	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		relation: Relation,
	) {
		super(userSettings, userProfile, companySettings, relation, FlutaroCollection.RELATION);
	}
}

export class LocationDialogData extends AppDialogSettings<Synonym> {
	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		location: Synonym,
	) {
		super(userSettings, userProfile, companySettings, location, FlutaroCollection.LOCATION);
	}
}

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

export class ScrollableEvent {
	event: Event;
	containerTop: boolean;
	containerEnd: boolean;
	onTop;
}

export class FocusEvent {
	event: Event;
	focus: boolean;
}

@Directive({
	selector: '[scrollable]',
})
export class FlutaroScrollableDirective {
	@Output() scrollPosition = new EventEmitter();
	@Output() scrolling = new EventEmitter();
	private scrollableEvent: ScrollableEvent = new ScrollableEvent();

	constructor(public el: ElementRef) {}

	@HostListener('scroll', ['$event'])
	onScroll(event) {
		try {
			this.scrollableEvent.event = event;
			const top = event.target.scrollTop;
			const height = this.el.nativeElement.scrollHeight;
			const offset = this.el.nativeElement.offsetHeight;
			const lastElement = this.el.nativeElement.lastElementChild as HTMLElement;
			if (!lastElement) return;

			if (top > height - offset - 1) {
				this.scrollPosition.emit('bottom');
			}

			if (top === 0) {
				this.scrollableEvent.containerTop = true;
				this.scrollPosition.emit('top');
			} else {
				this.scrollableEvent.containerTop = false;
			}
			if (top + offset === height) {
				this.scrollableEvent.containerEnd = true;
			}

			const scrollHeight = height - (offset + 2) - lastElement.offsetHeight;
			if (event.currentTarget.scrollTop < scrollHeight) {
				this.scrollableEvent.onTop = true;
			} else {
				this.scrollableEvent.onTop = false;
			}

			this.scrolling.emit(this.scrollableEvent);
		} catch (err) {
			console.error('Error in Flutaro Custom Scroll Directive ' + err);
		}
	}
}

@Directive({
	selector: '[focus]',
})
export class FlutaroFocusDirective {
	@Output() focusEvent = new EventEmitter();
	private focusEvents: FocusEvent = new FocusEvent();

	constructor(public el: ElementRef) {}

	@HostListener('focusin', ['$event'])
	onFocusIn(event) {
		try {
			this.focusEvents.event = event;
			this.focusEvents.focus = true;
			this.focusEvent.emit(this.focusEvents);
		} catch (err) {
			console.error('Error in Flutaro Custom chat text area focusin Directive ' + err);
		}
	}

	@HostListener('focusout', ['$event'])
	onFocusOut(event) {
		try {
			this.focusEvents.event = event;
			this.focusEvents.focus = false;
			this.focusEvent.emit(this.focusEvents);
		} catch (err) {
			console.error('Error in Flutaro Custom chat text area focusout Directive ' + err);
		}
	}
}

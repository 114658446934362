export const DriverAppointmentIllness = 'Krankheit';
export const DriverAppointmentAppointment = 'Privattermin';
export const DriverAppointmentVacation = 'Urlaub';
export const DriverAppointmentIndividualStart = 'Individueller Arbeitsbeginn';
export const DriverAppointmentIndividualEnd = 'Individuelles Arbeitsende';
// @TODO: Add translations (ger/en)

export const DriverAppointments: DriverAppointment[] = [
	{ value: null, label: null },
	{ value: 'illness', label: DriverAppointmentIllness },
	{ value: 'appointment', label: DriverAppointmentAppointment },
	{ value: 'vacation', label: DriverAppointmentVacation },
	{ value: 'individualWorkStart', label: DriverAppointmentIndividualStart },
	{ value: 'individualWorkEnd', label: DriverAppointmentIndividualEnd },
];

export class DriverAppointment {
	value: string;
	label: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorrectionTableComponent } from './correction.table.component';
import { CorrectionDialogComponent } from './correction.dialog.component';
import { FormsModule } from '@angular/forms';
import { FlutaroCustomMaterialModule } from '../../../app/app.material2.module';
import { TableModule } from '../../../table/table.module';
import { DialogModule } from '../../../dialog/dialog.module';

@NgModule({
	declarations: [CorrectionTableComponent, CorrectionDialogComponent],
	exports: [CorrectionTableComponent],
	imports: [CommonModule, FormsModule, FlutaroCustomMaterialModule, TableModule, DialogModule],
})
export class CorrectionModule {}

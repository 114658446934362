import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-job-timewindow',
	templateUrl: './job.timewindow.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTimewindowComponent {
	@Input() timewindow: number;
	@Output() timewindowChange = new EventEmitter<number>();

	constructor() {}
}

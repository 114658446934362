import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { JobWrapper } from '@flutaro/package/lib/model/Job';

@Component({
	selector: 'app-sidenav-job-destination',
	templateUrl: './sidenav-job-destination.component.html',
	styleUrls: ['./sidenav-job-destination.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavJobDestinationComponent {
	@Input() userProfile: FlutaroUserProfile;
	@Input() jobWrapper: JobWrapper;
	@Output() jobWrapperChange = new EventEmitter<JobWrapper>();

	constructor() {}

	emitJobChange() {
		this.jobWrapperChange.emit(this.jobWrapper);
	}
}

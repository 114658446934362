import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import { FlutaroClientService } from '../../data/data-services/data.client.service';
import { FlutaroJobService } from '../../data/data-services/data.job.service';
import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { DestinationsComponent } from '../destinations/destinations.component';
import { JobRepeatingProvider } from './job.repeating.provider';
import { JobLabelAttributes } from './JobFormClasses';
import { JobRepeating, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Client } from '@flutaro/package/lib/model/Client';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { updateJobsRevenueAndProfitOnJobChange } from '@flutaro/package/lib/functions/costs/CostsCheckFunctions';
import { JobDialogData } from 'src/app/dialog/AppDialog';
import { AtheneService } from '../../athene/athene.service';
import { getJobsFirstPickupDate } from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Component({
	selector: 'app-job-dialog',
	templateUrl: './job.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDialogComponent implements OnInit, AfterViewInit {
	@ViewChild(DestinationsComponent, { static: true }) destinationsComponent: DestinationsComponent;
	showRepeating: boolean;
	isRepeating: boolean;
	// Once created repeating settings cant be updated; forbid actions by isFirstReating
	isFirstRepeating: boolean;
	public jobWrapper: JobWrapper;
	public clients: Client[];
	// Data Inputs
	count = 0;
	JobAttributes = JobLabelAttributes;
	// Dialog State
	triggerCD: number = 0;

	constructor(
		private clientService: FlutaroClientService,
		private jobService: FlutaroJobService,
		@Inject(MAT_DIALOG_DATA) public dialogData: JobDialogData,
		private notifications: FlutaroNotificationsProvider,
		public dialogRef: MatDialogRef<JobDialogComponent>,
		public vehicleProvider: FlutaroVehicleService,
		private repeatingProvider: JobRepeatingProvider,
		private athene: AtheneService,
		private cd: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.clients = this.clientService.getData();
		let isJobAdditionAttributePresent: boolean = true;
		this.jobWrapper = cloneDeep(this.dialogData.data);
		for (let index = 0; index < this.JobAttributes.length; index++) {
			if (this.JobAttributes[index].jobLabel === this.jobWrapper.jobLabel) {
				isJobAdditionAttributePresent = false;
			}
			if (this.jobWrapper.jobLabel && isJobAdditionAttributePresent) {
				this.JobAttributes.push({ jobLabel: this.jobWrapper.jobLabel, jobColor: this.jobWrapper.jobColor });
			}
		}
		this.isRepeating = !!this.jobWrapper.job.jobsRepeating;
		this.isFirstRepeating = !this.jobWrapper.job.jobsRepeating;
	}

	triggerComponentsCD() {
		++this.triggerCD;
		this.cd.detectChanges();
	}

	async ngAfterViewInit() {
		if (!this.dialogData.isNew) {
			if (this.dialogData.userSettings.planningSettings.customJobDisplay) {
				let element = document.getElementById('job-display');
				let firstInnerChild = <HTMLElement>element.children[0].children[0];
				firstInnerChild.style.backgroundColor = this.jobWrapper.jobColor;
				firstInnerChild.style.textAlign = 'center';
			}
			await flutaroWait(100);
			this.destinationsComponent.markFormGroupTouched(this.destinationsComponent.destinationForm);
		}
	}

	changeRepeating() {
		if (this.isRepeating) {
			this.jobWrapper.job.jobsRepeating = new JobRepeating();
			this.showRepeating = true;
		} else {
			this.jobWrapper.job.jobsRepeating = null;
		}
	}

	resetJobCosts() {
		console.debug(`resetJobCosts called`);
		this.jobWrapper.resetCostCalculation();
		this.triggerComponentsCD();
	}

	async onSave() {
		if (!(await this.isValidJobForm())) return;

		// Repeating
		if (this.jobWrapper.job.jobsRepeating && this.isFirstRepeating) {
			this.jobWrapper.job.jobsRepeating.firstDayOfRepeatingJob = getJobsFirstPickupDate(this.jobWrapper);
			this.repeatingProvider.createRepeatingJobsFromJob(this.jobWrapper);
		}
		if (this.jobWrapper.backendId) {
			updateJobsRevenueAndProfitOnJobChange(this.jobWrapper, this.dialogData.data);
		}
		this.jobService.store(this.jobWrapper);
		this.dialogRef.close();
	}

	onAbort() {
		this.dialogRef.close();
	}

	onJobTypeChange(JobAttribute?) {
		// TODO: refactor me
		for (let i = 0; i < this.JobAttributes.length; i++) {
			if (JobAttribute.value === this.JobAttributes[i].jobLabel) {
				this.jobWrapper.jobColor = this.JobAttributes[i].jobColor;
			}
		}
		let element = document.getElementById('job-display');
		let firstInnerChild = <HTMLElement>element.children[0].children[0];
		firstInnerChild.style.backgroundColor = this.jobWrapper.jobColor;
		firstInnerChild.style.textAlign = 'center';
	}

	onKey(event) {
		// TODO: refactor me
		this.jobWrapper.jobLabel = event.target.value;
		let isJobAdditionAttributePresent = true;
		if (this.count > 0 && isJobAdditionAttributePresent && this.JobAttributes.length > 14) {
			this.JobAttributes.pop();
		}
		for (let index = 0; index < this.JobAttributes.length; index++) {
			if (this.JobAttributes[index].jobLabel === this.jobWrapper.jobLabel) {
				isJobAdditionAttributePresent = false;
			}
		}
		if (this.jobWrapper.jobLabel !== null && this.jobWrapper.jobLabel !== undefined && isJobAdditionAttributePresent) {
			this.JobAttributes.push({ jobLabel: this.jobWrapper.jobLabel, jobColor: this.jobWrapper.jobColor });
		}
		this.count++;
	}

	private async isValidJobForm(): Promise<boolean> {
		if (!this.destinationsComponent.checkformAndDatesValidation()) {
			return false;
		}
		if (
			!this.dialogData.companySettings.deactivateUniqueJobIdentifierMode &&
			this.dialogData.data.toString() !== this.jobWrapper.toString()
		) {
			const isUniqueIdentifier = await this.athene.getIsJobIdentifierUnique(this.jobWrapper.toString());
			if (!isUniqueIdentifier) {
				// Identifier must not already exist
				this.notifications.showBasicSnackBar(
					`Fehler: Auftragsname bereits vorhanden. Bitte wähle einen eindeutigen Auftragsbezeichner`,
				);
				return false;
			}
		}
		return true;
	}
}

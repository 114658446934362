import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuComponentProvider } from './menu.component.provider';
import { APP_MENU } from './MenuClasses';
import { CompanyService } from '../company/company.service';

@Component({
	selector: 'import-menu',
	template: '<app-import-excel [companySettings]="companyService.$companySettings| async"></app-import-excel>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMenuComponent implements OnInit {
	constructor(private menuProvider: MenuComponentProvider, public companyService: CompanyService) {}

	ngOnInit() {
		this.menuProvider.initTabsAfterMenuChange([APP_MENU.DATA_IMPORT]);
	}
}

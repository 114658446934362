<div *ngIf="!userProfile.hasDisableAllCreateDeleteRole" fxLayoutAlign="end center">
	<app-driver-add [companySettings]="companySettings" [userProfile]="userProfile" [userSettings]="userSettings">
	</app-driver-add>
</div>

<div class="table-container mat-elevation-z8">
	<div
		*ngIf="!isDataLoading && !data.length"
		class="addNewObjectBackground"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="addNewDriverImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'DRIVER' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'DRIVER_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_DRIVER' | translate }}
			</p>
		</div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_DRIVER' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="lastName">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">person</i>
				Name
			</th>
			<td *matCellDef="let element" mat-cell>
				<i
					*ngIf="element | isDriverActivated: vehicles"
					[matTooltipPosition]="'right'"
					class="dataTableActiveObject"
					matTooltip="{{ 'DRIVER_TABLE_DRIVER_ACTIVE_TT' | translate }}"
				></i>
				<i
					*ngIf="!(element | isDriverActivated: vehicles)"
					[matTooltipPosition]="'right'"
					class="dataTableInactiveObject"
					matTooltip="{{ 'DRIVER_TABLE_DRIVER_INACTIVE_TT' | translate }}"
				></i>
				{{ element.toString() }}
			</td>
		</ng-container>

		<ng-container matColumnDef="license">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">import_contacts</i>
				{{ 'DRIVERS_LICENSE' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>{{ element.license }}</td>
		</ng-container>

		<ng-container matColumnDef="phone">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">phone</i>
				{{ 'TELEPHONE' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>{{ element.phone }}</td>
		</ng-container>

		<ng-container matColumnDef="trainings">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">school</i>
				{{ 'TRAININGS' | translate }}
			</th>
			<td *matCellDef="let element" mat-cell>{{ element.trainings }}</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let element"
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(element)"
					[disabled]="userProfile.hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let element" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(element)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons></mat-paginator>
</div>

import { firebase, firebaseui } from 'firebaseui-angular';

export const firebaseUiAuthConfig: firebaseui.auth.Config = {
	signInFlow: 'redirect',
	signInOptions: [
		{
			requireDisplayName: true,
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
		},
	],
	tosUrl: 'https://app.flutaro.de/public/terms-and-conditions/index.html',
	privacyPolicyUrl: 'https://www.flutaro.de/privacy/',
	credentialHelper: firebaseui.auth.CredentialHelper.NONE,
	siteName: 'Flutaro Login',
};

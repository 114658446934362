<div fxLayout="column" class="newsDialogWidget">
	<mat-toolbar fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="20px">
		<h1 class="mat-toolbar-tools dialogHeadline">
			<i class="material-icons dialogIconBackground dashboardNewsIconBackground"></i>
			<i class="material-icons dialogIconToolbar">message</i>
			<span>Neuigkeit</span>
			<span *ngIf="isEditor" class="dashboardNewsDialogItalic">erstellen/bearbeiten</span>
		</h1>
	</mat-toolbar>
	<mat-dialog-content class="layout-padding layout-margin" fxFlex="100" fxLayoutGap="20px">
		<div name="DisplayEditorMode">
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" *ngIf="isEditor">
				<mat-form-field fxFlex="25">
					<input matInput required placeholder="Titel" [(ngModel)]="news.title" />
				</mat-form-field>
				<mat-form-field fxFlex="50">
					<input matInput required placeholder="Kurzbeschreibung" [(ngModel)]="news.shortDescription" />
				</mat-form-field>
				<app-datetimepicker
					id="news_date_picker"
					[placeholder]="'Anzeigen ab'"
					[(ngModel)]="news.date"
					[type]="'date'"
					(isDateValid)="isDateValid = $event"
				>
				</app-datetimepicker>
			</div>
			<quill-editor
				[modules]="quillModule"
				[(ngModel)]="news.page"
				placeholder="Text hier eingeben..."
				[readOnly]="!isEditor"
				[class.hideQuillToolbar]="!isEditor"
			></quill-editor>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions fxLayout="row" class="dialogFooterMD2" fxLayoutAlign="end none">
		<button mat-button class="buttonCancel mat-raised" (click)="abbort()">
			<mat-icon>
				<i class="material-icons">close</i>
			</mat-icon>
			Schließen
		</button>
		<button
			mat-button
			class="buttonDone mat-primary_flutaro"
			(click)="saveNews()"
			*ngIf="isEditor"
			[disabled]="!isDateValid"
		>
			<mat-icon>
				<i class="material-icons material-icons-white">done</i>
			</mat-icon>
			Speichern
		</button>
	</mat-dialog-actions>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicatorServerProvider } from './communicator.server.provider';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	exports: [],
	imports: [CommonModule, HttpClientModule],
	providers: [CommunicatorServerProvider],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class CommunicatorModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { environment } from '../../../environments/environment';
import { ExternalJob, ExternalJobResponseStatus } from '@flutaro/package/lib/model/ExternalJob';
import { transformObjectIntoSynonymClass } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { WebAuthService } from '../../app/auth/web-auth.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ExternalJobsProvider {
	protected currentExternalJobsCountUrl: string = environment.routingApiURL + '/data/external/jobs/invalid/count';
	protected externalJobsUrl: string = environment.routingApiURL + '/data/external/jobs/invalid';
	$externalJobsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	constructor(
		private http: HttpClient,
		private notifications: FlutaroNotificationsProvider,
		public authFb: WebAuthService,
		private translate: TranslateService,
	) {}

	initExternalJobsProvider() {
		this.updateExternalJobsCounterState();
		setInterval(() => this.updateExternalJobsCounterState(), 30 * 1000);
	}

	resetExternalJobsCounter() {
		this.$externalJobsCount.next(0);
	}

	async updateExternalJobsCounterState() {
		this.$externalJobsCount.next(await this.requestExternalJobsCounterWithoutLock());
	}

	private async requestExternalJobsCounterWithoutLock(): Promise<number> {
		return await lastValueFrom(this.http.get<number>(this.currentExternalJobsCountUrl));
	}

	async requestExternalJobsAndLock(): Promise<ExternalJob[]> {
		const externalJobs = await lastValueFrom(this.http.get<ExternalJob[]>(this.externalJobsUrl));
		if (!externalJobs?.length) {
			console.debug(`requestExternalJobs, no jobs for correction. Finished`);
			this.resetExternalJobsCounter();
			return [];
		}
		for (const extJob of externalJobs) {
			if (extJob.fixedJobDestinations?.length) {
				extJob.fixedJobDestinations.forEach((fixedJobDestination) => {
					fixedJobDestination.position = transformObjectIntoSynonymClass(fixedJobDestination.position);
				});
			}
		}
		return externalJobs;
	}

	async ctrlExternalJobsSending(externalJobs: ExternalJob[]) {
		const externalJobStatusReponses = await this.sendExternalJobs(externalJobs);
		return this.analayseExternalJobPut(externalJobStatusReponses);
	}

	async analayseExternalJobPut(externalJobStatusReponses: any[]) {
		let sucessfullImported: number = 0;
		let invalidImported: number = 0;
		let deletedWhileImported: number = 0;
		externalJobStatusReponses.forEach((response) => {
			if (response.state === ExternalJobResponseStatus.SUCSESS) sucessfullImported++;
			if (response.state === ExternalJobResponseStatus.FAILURE) invalidImported++;
			if (response.deletedSinceLastLoading) deletedWhileImported++;
		});
		const importMessage = `${sucessfullImported} ${this.translate.instant('EXTERNALJOBS_SUCCESSFUL_IMPORTED')},
     ${invalidImported} ${this.translate.instant('EXTERNALJOBS_STILL_AVAILABLE_AFTER_IMPORT')},
     ${deletedWhileImported} ${this.translate.instant('EXTERNALJOBS_DELETED_DURING_IMPORT')}`;
		this.notifications.showBasicSnackBar(importMessage);
		return true;
	}

	private sendExternalJobs(externalJobs: ExternalJob[]) {
		let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8;');
		return this.http.put<ExternalJob[]>(this.externalJobsUrl, externalJobs, { headers: headers }).toPromise();
	}
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import { DriverStatistics } from '../statistics/Statistics';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { calculateRelationCapacities } from './RelationFunctions';
import { filterForActivatedVehiclesOfCompanyNow } from '@flutaro/package/lib/functions/VehicleFunctions';
import { AppBasicComponent } from '../app/components/app.components.basic.component';
import { BehaviorSubject } from 'rxjs';
import {
	RelationCapacityChange,
	RelationCapacityEntry,
	RelationStatusFilterChange,
	TimetableFilterType,
} from '../timetable/TimetableClasses';

@Component({
	selector: 'app-relations-capacity',
	templateUrl: './relation.status.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroRelationsCapacityComponent extends AppBasicComponent implements OnChanges, OnInit {
	@Input() public referenceDate: Date; // We only care about the day
	@Input() public noDriverJobs: JobWrapper[];
	@Input() public relations: Relation[];
	@Input() public isDashboard: boolean;
	@Input() public isSundayTable: boolean;
	@Input() driverStatistics: Map<string, DriverStatistics>;
	@Output() relationFilterChange: EventEmitter<RelationStatusFilterChange> = new EventEmitter();
	$relationCapacities: BehaviorSubject<RelationCapacityEntry[]> = new BehaviorSubject<RelationCapacityEntry[]>([]);
	relationDirection: string = 'column';
	activatedRelationEntities: string[] = [];
	activatedRelationJobs: string[] = [];
	resetFilterStatus: boolean = false;

	ngOnInit() {
		this.isDashboard ? (this.relationDirection = 'column') : (this.relationDirection = 'row');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.referenceDate || !this.jobs || !this.relations || !this.noDriverJobs || !this.vehicles?.length) return;
		if (changes['weekdayDate']) {
			this.resetFilter();
		}
		this.setRelationCapacities();
	}

	/**
	 * Immutability will trigger components to be constantly re-created. To avoid losing internal state, use trackBy to avoid re-creations
	 * @param index
	 * @param item
	 */
	trackByFn(index, item) {
		return item.id; // or item.id
	}

	relationFilterChangeEmit(event: RelationCapacityChange) {
		let relationStatusEmit: RelationStatusFilterChange = new RelationStatusFilterChange(
			<TimetableFilterType>event.source,
		);
		if (relationStatusEmit.type === TimetableFilterType.vehicle) {
			if (event.value) {
				this.activatedRelationEntities = this.activatedRelationEntities.concat(event.elementIds);
			} else {
				event.elementIds.forEach((id) => {
					let idIndex = this.activatedRelationEntities.indexOf(id);
					this.activatedRelationEntities.splice(idIndex, 1);
				});
			}
			relationStatusEmit.elementIds = this.activatedRelationEntities;
		}
		if (relationStatusEmit.type === TimetableFilterType.jobs) {
			if (event.value) {
				this.activatedRelationJobs = this.activatedRelationJobs.concat(event.elementIds);
			} else {
				event.elementIds.forEach((id) => {
					let idIndex = this.activatedRelationJobs.indexOf(id);
					this.activatedRelationJobs.splice(idIndex, 1);
				});
			}
			relationStatusEmit.elementIds = this.activatedRelationJobs;
		}
		// If element is now empty because of dispatching
		if (event.elementIds.length === 0) this.resetFilter();
		this.relationFilterChange.emit(relationStatusEmit);
	}

	/**
	 * Calculates the statistics of relations
	 * The first entry is the entry for jobs without
	 */
	protected setRelationCapacities() {
		this.resetFilterStatus = false;
		if (
			!this.referenceDate ||
			!this.driverStatistics ||
			(!this.drivers.length && !this.companySettings.isDriverDeactivated)
		)
			return;
		this.$relationCapacities.next(
			calculateRelationCapacities(
				this.noDriverJobs,
				this.jobs,
				this.referenceDate,
				this.relations,
				this.isSundayTable,
				this.drivers,
				filterForActivatedVehiclesOfCompanyNow(this.vehicles, this.companySettings),
				this.driverStatistics,
			),
		);
	}

	private resetFilter() {
		this.activatedRelationEntities = [];
		this.activatedRelationJobs = [];
		this.resetFilterStatus = !this.resetFilterStatus;
	}
}

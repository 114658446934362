import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';

@Component({
	selector: 'app-vehicle-type-icon',
	templateUrl: './vehicle-type-icon.component.html',
	styleUrls: ['./vehicle-type-icon.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTypeIconComponent {
	@Input() vehicleType: VEHICLE_TYPE;
	@Input() type: 'job' | 'vehicle';
	@Input() customJobDisplay: boolean;
}

import { Injectable } from '@angular/core';
import { FlutaroNotificationsProvider } from './notification.snack.provider';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Injectable()
export class NotificationsErrorProvider {
	websocketHasError: boolean;

	constructor(private notifications: FlutaroNotificationsProvider) {}

	noInternetConnectionErrorDisplay() {
		this.notifications.showErrorMessage(
			'Keine Internetverbindung! Bitte behebe die Verbindungsprobleme und lade dann die Seite neu',
		);
	}

	async temporaryWebSocketLostLostMessage() {
		this.websocketHasError = true;
		await flutaroWait(500);
		this.notifications.showErrorMessage(
			'Das System hat die Verbindung zur Synchronisierung verloren. Stelle sicher, dass du mit dem Internet verbunden bist. Das System versucht automatisch die Verbindung wiederherzustellen und synchronisiert die Daten, sobald die Verbindung wiederhergestellt wurde. Bitte warte dazu kurz oder lade die Seite neu',
		);
	}

	hideNoWebSocketConnectionErrorDisplay() {
		if (this.websocketHasError) {
			this.notifications.showBasicSnackBar(
				'Die WebSocket-Verbindung wurde erfolgreich wiederhergestellt, die Daten werden jetzt synchronisiert ...',
			);
		}
		this.websocketHasError = false;
	}

	noServerConnectionErrorDisplay() {
		this.notifications.showErrorMessage(
			'Es kann keine Verbindung zum Server hergestellt werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support, falls das Problem bestehen bleibt...',
		);
	}

	noLoggedInAnymoreReloadDisplay() {
		this.notifications.showErrorMessage('Sie wurden abgemeldet. Bitte laden Sie die Seite neu.');
	}
}

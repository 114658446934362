import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { AppBasicComponent } from '../../../app/components/app.components.basic.component';
import { getVehicleById } from '@flutaro/package/lib/functions/VehicleDataFunctions';
import { ExternalJob } from '@flutaro/package/lib/model/ExternalJob';
import { JobHelperService } from '../../job.helper.service';
import { changeJobsAttributesToUnassigned } from '@flutaro/package/lib/functions/job/JobDataFunctions';

@Component({
	selector: 'app-select-vehicle-for-job',
	templateUrl: './select-vehicle-for-job.component.html',
	styleUrls: ['./select-vehicle-for-job.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectVehicleForJobComponent extends AppBasicComponent implements OnChanges {
	@Input() job: JobWrapper | ExternalJob;
	@Input() disabled: boolean;
	@Input() componentLayout: 'row' | 'column';
	@Input() selectedDate: Date;
	@Input() hideIcons: boolean;
	@Output() vehicle = new EventEmitter<Vehicle>();
	@Output() jobChange = new EventEmitter<JobWrapper | ExternalJob>();
	isDisabledSelect: boolean;
	_vehicleLicensePlate: string;
	_userNotInField = true;

	constructor(public jobHelperService: JobHelperService) {
		super();
	}

	ngOnChanges() {
		this._vehicleLicensePlate = this.job.vehicleId
			? getVehicleById(this.job.vehicleId, this.vehicles)?.licensePlate
			: this.job.vehicleLicensePlate;
		this.isDisabledSelect = this.disabled || this.userProfile.hasReadOnlyRole || (this.job as JobWrapper)?.isLocked;
	}

	clearVehicleInput() {
		this._vehicleLicensePlate = '';
		this.emitVehicle(null);
	}

	emitVehicle(vehicle: Vehicle | null) {
		this.setJobsVehicleAttributes(vehicle);
		this.vehicle.emit(vehicle);
		this.jobChange.emit(this.job);
	}

	private setJobsVehicleAttributes(vehicle: Vehicle) {
		vehicle
			? this.jobHelperService.changeJobAttributesForNewVehicle(this.job as JobWrapper, vehicle)
			: changeJobsAttributesToUnassigned(this.job as JobWrapper);
	}
}

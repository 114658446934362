import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ServerLog } from './ServerLog';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class FlutaroDataServerlogService {
	public latestOrderingNumber: number = 0;
	// This Subject is used to announce serverLog changes for all Services/Components who need to react on this Event
	public $newServerEventsSubject: Subject<ServerLog[]> = new Subject();
	protected dataPathAfter: string = '/data/serverEvents/after/';
	protected dataPathCurrent: string = '/data/serverEvents/current';
	protected afterUrl: string = environment.routingApiURL + this.dataPathAfter;
	protected currentUrl: string = environment.routingApiURL + this.dataPathCurrent;

	constructor(private http: HttpClient) {}

	public activateServerLogService() {
		// Check for new Server-Events every 30 seconds
		setInterval(() => this.requestAndSetCurrentOrderingNumber(false), 30 * 1000);
		this.initializeServerEvents();
	}

	protected initializeServerEvents() {
		this.requestAndSetCurrentOrderingNumber(true);
	}

	protected requestAndSetCurrentOrderingNumber(isInitialize: boolean) {
		this.http
			.get<number>(this.currentUrl)
			.toPromise()
			.then((data) => this.setCurrentOrderingNumber(data, isInitialize));
	}

	/**
	 * This Function is only called when the servers orderingNumber is bigger than the currentOrderingNumber
	 */
	protected requestEventsFromOrderingNumber(orderingNumer: number) {
		this.http
			.get<ServerLog[]>(this.afterUrl + orderingNumer.toString())
			.toPromise()
			.then((data) => this.updateServerEvents(data));
	}

	protected setCurrentOrderingNumber(currentOrderingNumber: number, isInitialize: boolean) {
		if (isInitialize) {
			this.latestOrderingNumber = currentOrderingNumber;
			return;
		}
		if (this.latestOrderingNumber === currentOrderingNumber) {
			return;
		} else {
			this.requestEventsFromOrderingNumber(this.latestOrderingNumber);
			this.latestOrderingNumber = currentOrderingNumber;
		}
	}

	/**
	 * This Function is called to update the internal serverEvents List on changes
	 * @param serverEvents
	 */
	protected updateServerEvents(serverEvents: ServerLog[]) {
		this.$newServerEventsSubject.next(serverEvents);
	}
}

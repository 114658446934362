import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../FlutaroService';
import { ExternalAddressMapping } from '../../interfaces/ExternalJob/ExternalAddressMapping';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class FlutaroAddressCorrectionService extends FlutaroDataService<ExternalAddressMapping> {
	url = environment.routingApiURL + '/data/external/mapping';

	async sendDelete(deleteElement: ExternalAddressMapping): Promise<ExternalAddressMapping> {
		await super.sendDelete(deleteElement);
		this.removeWithoutSending(deleteElement);
		return deleteElement;
	}
}

<form [formGroup]="cargoForm" class="jobCargoBackground" [style.width]="'100%'">
	<mat-toolbar *ngIf="!disabled" fxLayout="row" style="height: 35px" class="mat-toolbar-tools">
		<span>Cargo</span>

		<button
			(click)="addCargo()"
			[matTooltipPosition]="'below'"
			class="buttonAddCargoButton mat-fab mat-flut"
			mat-fab
			matTooltip="{{ 'ADD_CARGO' | translate }}"
			name="addCargo"
		>
			<mat-icon class="material-icons fab-icon-add">
				<i class="material-icons addIconDialogButton"> add </i>
			</mat-icon>
		</button>
	</mat-toolbar>
	<mat-accordion>
		<div formArrayName="cargoRows">
			<div *ngFor="let cargoControl of getAllControlsOfFormGroup(); let j = index" [formGroupName]="j">
				<mat-expansion-panel
					#cargo
					(closed)="setCargoInputstate(j, 'close')"
					(opened)="setCargoInputstate(j, 'open')"
					[expanded]="cargoStep === j"
				>
					<mat-expansion-panel-header class="matPanelCustom">
						<mat-panel-title>
							<i class="material-icons dialogToolBarAdd">add_location</i>
							Cargo
						</mat-panel-title>
					</mat-expansion-panel-header>
					<!-- name, amount and type start-->
					<div fxLayout="column" fxLayoutGap="10px">
						<div fxLayout="column">
							<mat-form-field>
								<mat-label>{{ 'CARGO_CONTENT' | translate }}</mat-label>
								<input formControlName="contents" matInput />
							</mat-form-field>

							<div fxLayout="row" fxLayoutGap="10px">
								<mat-form-field>
									<mat-label>{{ 'AMOUNT' | translate }}</mat-label>
									<input formControlName="amount" matInput />
								</mat-form-field>
								<mat-form-field class="loadingEquipmentType">
									<mat-label>{{ 'CARGO_LOADING_EQUIPMENT' | translate }}</mat-label>
									<mat-select [compareWith]="compareLoadingEquipmentType" formControlName="loadingEquipmentType">
										<mat-option *ngFor="let loadingType of LoadingEquipmentTypes" [value]="loadingType">
											{{ loadingType.shortDesc }} / {{ loadingType.description }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>

						<!-- Weight and Unit-->
						<div fxLayout="column" fxLayoutGap="10px">
							<div fxLayout="row" fxLayoutGap="10px">
								<mat-form-field fxFlex="50">
									<mat-label>{{ 'WEIGHT_NET' | translate }}</mat-label>
									<input formControlName="netWeight" matInput />
								</mat-form-field>
								<mat-form-field fxFlex="50">
									<mat-label>{{ 'UNIT' | translate }}</mat-label>
									<input formControlName="netWeightUnit" matInput />
								</mat-form-field>
							</div>
							<div fxLayout="row" fxLayoutGap="10px">
								<mat-form-field fxFlex="50">
									<mat-label>{{ 'WEIGHT_GROSS' | translate }}</mat-label>
									<input formControlName="grossWeight" matInput />
								</mat-form-field>
								<mat-form-field fxFlex="50">
									<mat-label>{{ 'UNIT' | translate }}</mat-label>
									<input formControlName="grossWeightUnit" matInput />
								</mat-form-field>
							</div>
							<mat-form-field>
								<mat-label>{{ 'LOADING_METERS' | translate }}</mat-label>
								<input formControlName="loadingMeters" matInput />
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayoutGap="10px">
							<mat-slide-toggle formControlName="exchangeLoadingEquipment">
								<span class="mat-checkbox-label">
									{{ 'EXCHANGE_LOADING_EQUIPMENT' | translate }}
								</span>
							</mat-slide-toggle>
							<mat-form-field *ngIf="cargoControl.value.exchangeLoadingEquipment">
								<mat-label>{{ 'AMOUNT' | translate }}</mat-label>
								<input
									formControlName="amountLoadingEquipmentTaken"
									matInput
									matTooltip="{{ 'EXCHANGE_LOADING_EQUIPMENT_NUMBER_TT' | translate }}"
									type="number"
								/>
							</mat-form-field>
						</div>

						<app-child-cargo
							(childCargoChange)="setChildCargo(j, $event)"
							(isChildCargoInputChange)="setChildCargoInputStatus($event)"
							[childCargo]="cargoControl.value.childCargo"
							[disabled]="disabled"
							formControlName="childCargo"
						>
						</app-child-cargo>
					</div>

					<mat-action-row *ngIf="!disabled">
						<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
							<button (click)="prevCargo()" *ngIf="j > 0" class="destinationButtonsSmall" mat-flat-button>
								<mat-icon><i class="material-icons">arrow_back</i></mat-icon>
								{{ 'BACK' | translate }}
							</button>
							<button
								(click)="nextCargo()"
								*ngIf="!(j + 1 === getCurrentNumberOfFormControls()) && getCurrentNumberOfFormControls() > 1"
								class="destinationButtonsSmall"
								mat-flat-button
							>
								<mat-icon><i class="material-icons">arrow_forward</i></mat-icon>
								{{ 'NEXT' | translate }}
							</button>
							<button (click)="removeCargo(j)" class="destinationButtonsSmallDelete" mat-flat-button>
								<mat-icon><i class="material-icons">delete</i></mat-icon>
								{{ 'DELETE' | translate }}
							</button>
						</div>
					</mat-action-row>
				</mat-expansion-panel>
			</div>
		</div>
	</mat-accordion>
</form>

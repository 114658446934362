import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../FlutaroService';
import { isZipInRelation } from '../../relation/RelationFunctions';
import { Relation } from '@flutaro/package/lib/model/Relation';
import {
	parseElementsBasics,
	transformObjectIntoRelationClass,
} from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { environment } from '../../../environments/environment';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { CountryToISO2 } from '@flutaro/package/lib/model/LocationConstants';

@Injectable({
	providedIn: 'root',
})
export class FlutaroRelationsService extends FlutaroDataService<Relation> {
	public getZipsRelation(jobZip: string, jobCountry: string): Relation {
		let jobsRelation = null;
		this.getData().forEach((relation) => {
			if (isZipInRelation(jobZip, jobCountry, relation)) jobsRelation = relation;
		});
		return jobsRelation;
	}

	protected initProvider() {
		this.url = environment.routingApiURL + '/data/relations';
		this.collectionName = FlutaroCollection.RELATION;
		this.websocketService.relationSocket.subscribe((relationData) => this.handleWebSocketAction(relationData));
		this.websocketService.$websocketRecoveredFromFailingState.subscribe((isReconnected) => {
			if (!isReconnected) return;
			this.getDataFromServer();
		});
	}

	protected parseElement(newElement: Relation): Relation {
		// Zips are now pickUpZips
		if (newElement.zips.length && !newElement.pickUpZips?.length) {
			newElement.pickUpZips = newElement.zips;
			if (!newElement.pickUpCountry) newElement.pickUpCountry = CountryToISO2.Germany;
		}
		return transformObjectIntoRelationClass(parseElementsBasics(newElement));
	}
}

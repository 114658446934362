import {
	EXCEL_DATE_FORMAT_STRING_LONG,
	EXCEL_DATE_FORMAT_STRING_LONG_SLASH_SEPARATOR,
	EXCEL_DATE_FORMAT_STRING_SHORT,
	EXCEL_DATE_FORMAT_STRING_SHORT_SLASH_SEPARATOR,
} from './import.excel.classes';
import { parse } from 'date-fns';
import { Synonym } from '@flutaro/package/lib/model/Synonym';

export function createSynonymFromImportedData(city, zip, street?, houseNumber?, country?: string) {
	let tempSynonym: Synonym = new Synonym();
	if (street) {
		tempSynonym.addressLine = street;
		if (houseNumber) tempSynonym.addressLine = street + ' ' + houseNumber;
	}
	tempSynonym.zip = zip;
	tempSynonym.city = city;
	if (country) tempSynonym.country = country;
	return tempSynonym;
}

export function parseExcelImportDate(dateString: string): Date {
	if (!dateString) {
		console.debug(`parseExcelImportDate, empty dateString. Aborting`);
		return null;
	}

	let parsedDate: Date;
	if (dateString.length > 14 && dateString.includes('/')) {
		parsedDate = parse(dateString, EXCEL_DATE_FORMAT_STRING_LONG_SLASH_SEPARATOR, new Date());
	} else if (dateString.length <= 14 && dateString.includes('/')) {
		parsedDate = parse(dateString, EXCEL_DATE_FORMAT_STRING_SHORT_SLASH_SEPARATOR, new Date());
	} else if (dateString.length <= 14 && !dateString.includes('/')) {
		parsedDate = parse(dateString, EXCEL_DATE_FORMAT_STRING_SHORT, new Date());
	} else {
		parsedDate = parse(dateString, EXCEL_DATE_FORMAT_STRING_LONG, new Date());
	}
	return parsedDate;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { JobModule } from '../job/job.module';
import { RelationModule } from '../relation/relations.module';
import { SidenavModule } from '../sidenav/sidenav.module';
import { TableModule } from '../table/table.module';
import { AppRoutingModule } from '../app/app.routing.module';
import { TimetableArrowScrollingDirective } from './component/timetable.arrow.directive';
import { FlutaroTimetableComponent } from './component/timetable.component';
import { SynchronousScrollingDirective } from './component/timetable.directive.scrolling';
import { CloseSidenavOnEscDirective } from './component/timetable.directives';
import { SynchronousDriversScrollingDirective } from './component/timetable.drivers.scrolling.directive';
import { TimetableJobComponent } from './component/timetable.job.component';
import { TimetableToolbarComponent } from './component/timetable.toolbar.component';
import { TimetableWeekdayComponent } from './component/timetable.weekday.component';
import { TimetableComponentProvider } from './data/timetable.component.service';
import { TimetableFilterController } from './data/timetable.filter.controller';
import { TimetableMenuComponent } from './timetable.menu.component';
import { DialogModule } from '../dialog/dialog.module';
import { FlutaroTimetableFilterService } from './data/timetable.data.provider';
import { TimetableFilterStoreProvider } from './data/timetable.filter.store.provider';
import { TimetableNoteComponent } from './notes/timetable-note-component/timetable-note.component';
import { TimetableNoteProvider } from './notes/timetable-note-provider.service';
import { ChatModule } from '../chat/chat.module';
import { TimetableJobStoreProvider } from './data/timetable.job.store.provider';
import { TimetableService } from './data/timetable.service';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { MatRadioModule } from '@angular/material/radio';
import { VehicleModule } from '../vehicle/vehicle.module';
import { MapModule } from '../map/map.module';
import { TimetableVehicleListElementComponent } from './component/timetable-vehicle-list-element/timetable-vehicle-list-element.component';
import {
	GetDriversAppointmentsInTimetablePipe,
	GetDriversWeekendWorkingStatusPipe,
	GetDriversWorkweekStartPipe,
	GetObjectsMarginInTimetablePipe,
	GetObjectsWidthInTimetablePipe,
	GetTimetablesScrollLeftPipe,
	GetVehiclesDriverSchedulesInTimetablePipe,
	IsScheduleChangeForVehicleInTimetableRange,
} from './timetable.pipes';
import { TimetableDriverEntryComponent } from './driver-entry/timetable-driver-entry.component';
import { TimetableVehicleEntryComponent } from './vehicle-entry/timetable-vehicle-entry.component';
import { DriverModule } from '../driver/driver.module';

/**
 * Angular2 Timetable Module
 */
@NgModule({
	declarations: [
		FlutaroTimetableComponent,
		CloseSidenavOnEscDirective,
		SynchronousScrollingDirective,
		TimetableArrowScrollingDirective,
		TimetableMenuComponent,
		TimetableToolbarComponent,
		TimetableWeekdayComponent,
		SynchronousDriversScrollingDirective,
		TimetableJobComponent,
		TimetableNoteComponent,
		TimetableVehicleListElementComponent,
		GetDriversWeekendWorkingStatusPipe,
		GetDriversWorkweekStartPipe,
		GetDriversAppointmentsInTimetablePipe,
		GetVehiclesDriverSchedulesInTimetablePipe,
		IsScheduleChangeForVehicleInTimetableRange,
		GetObjectsWidthInTimetablePipe,
		GetObjectsMarginInTimetablePipe,
		GetTimetablesScrollLeftPipe,
		TimetableDriverEntryComponent,
		TimetableVehicleEntryComponent,
	],
	imports: [
		CommonModule,
		DragulaModule,
		FlutaroCustomMaterialModule,
		FormsModule,
		TableModule,
		RelationModule,
		SidenavModule,
		JobModule,
		AppRoutingModule,
		DialogModule,
		ChatModule,
		MatRadioModule,
		VehicleModule,
		MapModule,
		DriverModule,
	],
	providers: [
		FlutaroTimetableFilterService,
		TimetableComponentProvider,
		DragulaService,
		TimetableFilterController,
		TimetableFilterStoreProvider,
		TimetableNoteProvider,
		TimetableJobStoreProvider,
		TimetableService,
	],
	exports: [CloseSidenavOnEscDirective],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class TimetableModule {}

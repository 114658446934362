import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobAppSettings } from '@flutaro/package/lib/model/AppClasses';

@Component({
	selector: 'app-job-app-history',
	templateUrl: './job-app-history.component.html',
	styleUrls: ['./job-app-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobAppHistoryComponent {
	@Input() appSettings: JobAppSettings;

	constructor() {}
}

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { NotificationsErrorProvider } from '../notifications/notifications.error.provider';

@Injectable()
export class FlutaroErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {}

	handleError(error: Error | HttpErrorResponse) {
		const notificationService = this.injector.get(NotificationsErrorProvider);
		//const appUserProvider = this.injector.get(GlobalAuthService);
		// Log the error anyway
		console.error(error);
		if (error instanceof HttpErrorResponse) {
			console.error(`handleError, got HttpErrorResponse with status ${error.status}`);
			// Server or connection error happened
			if (!navigator.onLine) {
				// Handle offline error
				notificationService.noInternetConnectionErrorDisplay();
			} else {
				if (error.status === 401 || error.status === 403) {
					notificationService.noLoggedInAnymoreReloadDisplay();
				} else if (error.status === 0) notificationService.noServerConnectionErrorDisplay();
			}
		}
	}
}

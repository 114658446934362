<div fxLayout="column">
	<div *ngIf="disabled" fxLayout="row" fxFlex>
		<p class="repeatingSinceLabel">
			{{ 'REPEAT_FIRST_DAY_OF_REPEATING_JOB' | translate }}
			{{ jobsRepeating.firstDayOfRepeatingJob | date: 'longDate' }}
		</p>
	</div>
	<div
		fxLayout="row"
		fxLayout.sm="column"
		fxLayout.xs="column"
		class="marginTop20"
		fxLayoutGap="10px"
		fxFlex
		id="job_repeating"
	>
		<mat-form-field fxFlex="100" fxFlex.gt-sm="20">
			<mat-select
				[(ngModel)]="jobsRepeating.repeatingMode"
				[disabled]="disabled"
				[ngModelOptions]="{ standalone: true }"
				placeholder="{{ 'REPEAT' | translate }}"
			>
				<mat-option [value]="repeatingMode.DAILY">
					{{ 'DAILY' | translate }}
				</mat-option>
				<mat-option [value]="repeatingMode.WEEKLY">
					{{ 'WEEKLY' | translate }}
				</mat-option>
				<mat-option [value]="repeatingMode.INDIVIDUAL">
					{{ 'INDIVIDUAL' | translate }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field *ngIf="jobsRepeating.repeatingMode === repeatingMode.DAILY" fxFlex="100" fxFlex.gt-sm="40">
			<mat-select
				[(ngModel)]="jobsRepeating.dailyExceptions"
				[disabled]="disabled"
				[ngModelOptions]="{ standalone: true }"
				multiple
				placeholder="{{ 'DAILY_EXCEPTIONS' | translate }}"
			>
				<mat-option *ngFor="let dailyException of dailyExceptions" [value]="dailyException.value">
					{{ dailyException.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<div
			*ngIf="jobsRepeating.repeatingMode === repeatingMode.WEEKLY"
			fxLayout="row"
			fxLayout.sm="column"
			fxLayout.xs="column"
			fxLayoutGap="10px"
		>
			<mat-form-field fxFlex>
				<mat-chip-list matTooltip="{{ 'WEEKLY_EXCEPTIONS_TT' | translate }}">
					<mat-chip
						(removed)="removeWeeklyException(weeklyException)"
						*ngFor="let weeklyException of jobsRepeating.weeklyExceptions"
						[disabled]="true"
						[removable]="!disabled"
					>
						{{ weeklyException | date: 'longDate' }}
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip>
				</mat-chip-list>
			</mat-form-field>
			<div class="repeatingIndividualExceptionsDatePicker" matTooltip="{{ 'ADD_WEEKLY_EXCEPTIONS_TT' | translate }}">
				<app-datetimepicker
					[max]="jobsRepeating.repeatUntilDay"
					[min]="jobsRepeating.firstDayOfRepeatingJob"
					[noModel]="true"
					(dateChange)="addWeeklyException($event)"
					placeholder="{{ 'ADD_WEEKLY_EXCEPTIONS' | translate }}"
					[flutaroDatetimepickerFilter]="onlyMondayDateFilter"
					[required]="false"
					[type]="'date'"
					[disabled]="disabled"
				>
				</app-datetimepicker>
			</div>
		</div>
		<div
			name="IndividualRepeatingExceptions"
			*ngIf="jobsRepeating.repeatingMode === repeatingMode.INDIVIDUAL"
			fxLayout="row"
			fxLayout.sm="column"
			fxLayout.xs="column"
			fxLayoutGap="10px"
		>
			<mat-form-field
				*ngIf="jobsRepeating.repeatingDates.length > 0"
				class="repeatingIndividualExceptionsDisplay"
				fxFlex
			>
				<mat-chip-list>
					<mat-chip
						(removed)="removeRepeatingDate(repeatingDate)"
						*ngFor="let repeatingDate of jobsRepeating.repeatingDates"
						[disabled]="disabled"
						[removable]="!disabled"
					>
						{{ repeatingDate | date: 'longDate' }}
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip>
				</mat-chip-list>
			</mat-form-field>
			<div class="repeatingIndividualExceptionsDatePicker" matTooltip="{{ 'ADD_REPEATING_DATE_TT' | translate }}">
				<app-datetimepicker
					[min]="minDate"
					[noModel]="true"
					[allowDynamicWidth]="true"
					placeholder="{{ 'ADD_REPEATING_DATE' | translate }}"
					(dateChange)="addRepeatingDate($event)"
					[type]="'date'"
					[disabled]="disabled"
				>
				</app-datetimepicker>
			</div>
		</div>
		<div *ngIf="jobsRepeating.repeatingMode !== repeatingMode.INDIVIDUAL" matTooltip="{{ 'TILL_TT' | translate }}">
			<app-datetimepicker
				[min]="minDate"
				(dateChange)="emitDateChange()"
				placeholder="{{ 'TILL' | translate }}"
				[(date)]="jobsRepeating.repeatUntilDay"
				[type]="'date'"
				[disabled]="disabled"
			>
			</app-datetimepicker>
		</div>
	</div>
</div>

import { AppDialogSettings } from 'src/app/dialog/AppDialog';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

export class RelationDialogData extends AppDialogSettings<Relation> {
	vehicles: Vehicle[];

	constructor(
		userSettings: UserSettings,
		userProfile: FlutaroUserProfile,
		companySettings: CompanySettings,
		relation: Relation,
		vehicles: Vehicle[],
	) {
		super(userSettings, userProfile, companySettings, relation, FlutaroCollection.RELATION);
		Object.assign(this, {
			vehicles,
		});
	}
}

<div class="flutaro-google-map">
	<google-map [options]="mapOptions" height="100%" width="100%">
		<map-polyline [options]="polylineOptions" [path]="polylinePath"> </map-polyline>
		<map-marker
			#marker="mapMarker"
			*ngFor="let flutaroMarker of markers; let markerIndex = index"
			(mapClick)="openInfoWindow(marker, markerIndex, flutaroMarker)"
			[options]="flutaroMarker.options"
			[position]="flutaroMarker.position"
		>
		</map-marker>

		<map-info-window>
			<div [innerHTML]="infoWindowContent?.content | safeHtml"></div>
		</map-info-window>
	</google-map>
</div>

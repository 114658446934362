import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppSorting, TimetableDataSortAttribute } from '@flutaro/package/lib/model/AppClasses';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';

@Component({
	selector: 'app-settings-timetable-ordering-select',
	templateUrl: './settings-timetable-ordering-select.component.html',
	styleUrls: ['./settings-timetable-ordering-select.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsTimetableOrderingSelectComponent {
	@Input() sorting: AppSorting;
	@Output() sortingChange: EventEmitter<AppSorting> = new EventEmitter<AppSorting>();
	@Input() attribute: TimetableDataSortAttribute | string;
	@Input() companySettings: CompanySettings;
	@Output() attributeChange: EventEmitter<TimetableDataSortAttribute> = new EventEmitter<TimetableDataSortAttribute>();

	APP_SORTING = AppSorting;
	APP_SORTING_ATTRIBUTE = TimetableDataSortAttribute;

	changeSorting(newSorting: AppSorting) {
		this.sorting = newSorting;
		this.sortingChange.emit(this.sorting);
	}

	changeAttribute(attribute: TimetableDataSortAttribute) {
		this.attribute = attribute;
		this.attributeChange.emit(this.attribute as TimetableDataSortAttribute);
	}
}

<div (click)="openVehicleSidenav($event)" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
	<div
		fxLayout="row"
		fxLayoutAlign="start center"
		class="row-heading fullWidth"
		fxLayoutGap="10px"
		[style.margin-left]="'10px'"
		[matTooltipPosition]="'right'"
		matTooltip="{{ vehicle.isDepotAddressBased ? vehicle.depotAddress : driver?.homeAddress }}"
	>
		<i
			[class.timetableDriverWeekendOutsideStatus]="driver | isWeekEndOutsideCurrentWeek : timetable.startDate"
			[class.timetableDriverWeekendStatusGreen]="(driver | driverWeekendWorkCounter : timetable.startDate) === 0"
			[class.timetableDriverWeekendStatusRed]="(driver | driverWeekendWorkCounter : timetable.startDate) === 2"
			[class.timetableDriverWeekendStatusYellow]="(driver | driverWeekendWorkCounter : timetable.startDate) === 1"
			[matTooltipPosition]="'right'"
			class="material-icons timetableDriverWeekendStatusIcon timetableDriverWeekendStatusGreen"
			matTooltip="{{ 'DRIVERWEEKENDSTATUS_TT' | translate }}"
			*ngIf="!companySettings.isDriverDeactivated && driver"
		>
			brightness_1
		</i>
		<app-vehicle-type-icon [vehicleType]="vehicle.carType" [type]="'vehicle'"></app-vehicle-type-icon>
		<div class="timetableVehiclelicensePlate fullWidth" fxLayout="column" fxLayoutAlign="space-evenly start">
			<div fxLayout="row" fxLayoutAlign="space-between start" class="fullWidth">
				<span class="timetableVehiclelicensePlate">{{ vehicle.licensePlate }}</span>
				<mat-icon
					svgIcon="driver_swap"
					class="appIconSize16 app-svg-color-warn"
					*ngIf="vehicle.driverSchedules | scheduleChangeInTimetable : timetable"
				></mat-icon>
			</div>
			<span class="timetableDriverName">{{
				!companySettings.isDriverDeactivated && driver ? driver?.toString() : vehicle.name
			}}</span>
		</div>
	</div>
	<div
		*ngIf="!timetableEntry.vehicleJobStatus.isJobActionValid || !timetableEntry.vehicleJobStatus.isValid"
		class="driverWarning"
	>
		<span>
			<i class="material-icons currentDriverCostEmptyKmIcon">warning</i>
		</span>
	</div>
</div>

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { WebAuthService } from './web-auth.service';

@Injectable()
export class WebAuthInterceptor implements HttpInterceptor {
	constructor(private auth: WebAuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		let headers = new HttpHeaders();
		const token = await this.auth.getIdToken();
		headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization', `Bearer ${token}`);
		request = request.clone({ headers: headers });
		return await lastValueFrom(next.handle(request));
	}
}

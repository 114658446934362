import { animate, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewChild,
} from '@angular/core';

import { TimetableFilterController } from '../data/timetable.filter.controller';
import { TimetableJobEntry } from '../TimetableClasses';
import { FlutaroSidenavProvider } from 'src/app/sidenav/sidenav.provider';
import { MatMenuTrigger } from '@angular/material/menu';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { LocationLabel } from '../../sidenav/SidenavClasses';

@Component({
	selector: 'app-timetable-job',
	templateUrl: './timetable.job.component.html',
	animations: [trigger('hideJobComponent', [transition('* => true', animate('250ms ease-out'))])],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableJobComponent implements OnChanges {
	@Input() jobEntry: TimetableJobEntry;
	@Input() hideMenu: boolean;
	@Input() hideJob: boolean;
	@Input() customJobDisplay;
	@Input() locations: Synonym[];
	@Input() userProfile: FlutaroUserProfile;
	@Input() userSettings: UserSettings;
	@Output() jobSelected: EventEmitter<JobWrapper> = new EventEmitter();
	@ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
	startLocationLabel: string;
	endLocationLabel: string;
	contextMenuPosition = { x: '0px', y: '0px' };

	@ViewChild('jobMenu') public jobMenu;

	constructor(
		private timetableFilterController: TimetableFilterController,
		public sidenavService: FlutaroSidenavProvider,
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnChanges(changes) {
		if (!this.locations) {
			console.log('Error: No Locations. Aborting.');
			return;
		}
		let locationLabel: LocationLabel = this.sidenavService.setLocationTypeLabel(
			this.locations,
			this.jobEntry.jobWrapper,
		);
		this.startLocationLabel = locationLabel.startLocationLabel;
		this.endLocationLabel = locationLabel.endLocationLabel;
		this.changeDetectorRef.markForCheck();
	}

	onContextMenu(event: MouseEvent, menu: string) {
		event.preventDefault();
		/** TODO: Improve
     this.contextMenuPosition.x = event.clientX + 'px';
     this.contextMenuPosition.y = event.clientY + 'px';
     */
		this.contextMenu.menu.focusFirstItem('mouse');
		this.contextMenu.openMenu();
	}

	customStyle() {
		let styles = {
			'background-color': this.customJobDisplay ? this.jobEntry.jobWrapper.jobColor : '#40c1ac',
		};
		return styles;
	}

	defaultStyle() {
		let styles = {
			'background-color': this.jobEntry.color ? this.jobEntry.color : '#40c1ac',
			background: this.jobEntry.color ? this.jobEntry.color : '#40c1ac',
		};
		return styles;
	}

	// Received from contextmenu #jobsMenu
	sendJobToNoVehicle(event) {
		this.timetableFilterController.dispatchJobToUnassigned(this.jobEntry.jobWrapper);
	}
}

<mat-toolbar
	[@toolbarAnimation]
	class="timetableToolbar mat-hue-2 hidden-xs"
	fxFlex="100"
	fxLayout="row"
	fxLayoutAlign="start center"
	fxLayoutGap="10px"
>
	<div fxLayout="column" fxLayoutAlign="center center">
		<div fxLayout="row" class="halfToolbarHeight" fxLayoutAlign="center center" fxLayoutGap="10px">
			<p class="timetableLabel toolbarShowLabel">{{ 'SHOW' | translate }}</p>
			<button
				(click)="goToToday()"
				[matTooltipPosition]="'below'"
				class="mat-raised mat-small mat-ink-ripple"
				id="timetableHomeButton"
				matTooltip="{{ 'GOTOHOME_TT' | translate }}"
			>
				<i class="material-icons timetable-home-icon">home</i>
			</button>
			<mat-checkbox
				(change)="timetableFilterService.createFilteredTimetableMap()"
				[(ngModel)]="timetableFilterService.showSentJobs"
				aria-label="Checkbox 1"
				class="mat-primary mat-checkbox-small halfToolbarHeight"
			>
				<mat-label class="statusCheckbox-label">{{ 'JOB_STATUS_SENT' | translate }}</mat-label>
			</mat-checkbox>
			<mat-checkbox
				(change)="timetableFilterService.createFilteredTimetableMap()"
				[(ngModel)]="timetableFilterService.showDoneJobs"
				aria-label="Checkbox 2"
				class="mat-primary mat-checkbox-small halfToolbarHeight"
			>
				<mat-label class="statusCheckbox-label">{{ 'JOB_STATUS_DONE' | translate }}</mat-label>
			</mat-checkbox>
			<mat-checkbox
				(change)="timetableFilterService.createFilteredTimetableMap()"
				[(ngModel)]="timetableFilterService.showAllVehicles"
				aria-label="Minimize drivers"
				class="mat-primary mat-checkbox-small halfToolbarHeight"
			>
				<mat-label class="statusCheckbox-label">{{ 'DRIVERWITHOUTJOB' | translate }}</mat-label>
			</mat-checkbox>
		</div>
		<mat-divider class="timetableShowDividerHorizontal"></mat-divider>
		<app-timetable-weekday
			(weekDayChange)="weekDayChange.emit($event)"
			[dayOfWeek]="dayOfWeek"
			[timetable]="timetable"
			class="halfToolbarHeight"
		>
		</app-timetable-weekday>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center">
		<mat-button-toggle
			(click)="changePlanningMode()"
			[checked]="showPlanningMode"
			[matTooltipPosition]="'below'"
			class="mat-raised mat-small mat-ink-ripple"
			id="timetablePlanningModeButton"
			matTooltip="{{ 'PLANNINGMODEBUTTON_TT' | translate }}"
		>
			<i *ngIf="!showPlanningMode" class="material-icons planningModeToggleOffIcon">remove_red_eye</i>
			<div *ngIf="showPlanningMode">
				<i class="material-icons planningModeToggleOnIcon">remove_red_eye</i>
				<span *ngIf="showPlanningMode" class="planningModeToggleSpan">On</span>
			</div>
		</mat-button-toggle>
		<mat-button-toggle
			(click)="changeNoteMode()"
			[checked]="showNotes"
			[matTooltipPosition]="'below'"
			class="mat-raised mat-small mat-ink-ripple"
			id="timetableNoteModeButton"
			matTooltip="{{ 'NOTEMODE_TT' | translate }}"
		>
			<i *ngIf="!showNotes" class="material-icons noteModeToggleOffIcon">comment</i>
			<div *ngIf="showNotes">
				<i class="material-icons noteModeToggleOnIcon">comment</i>
				<span class="noteModeToggleSpan">On</span>
			</div>
		</mat-button-toggle>
		<button
			(click)="adjustOverlappingJobs()"
			[matTooltipPosition]="'below'"
			class="mat-raised mat-small mat-ink-ripple"
			id="timetableJobPlannedDateAdjustmentButton"
			matTooltip="{{ 'ADJUSTOVERLAPPINGJOBS_TT' | translate }}"
		>
			<i class="material-icons plannedDateModeToggleOffIcon">schedule</i>
		</button>
	</div>

	<app-relations-capacity
		(relationFilterChange)="filterForRelation($event)"
		[class.marginLeft2]="!timetable.isDayTimetable"
		[driverStatistics]="driverStatistics"
		[drivers]="drivers"
		[vehicles]="vehicles"
		[isDashboard]="false"
		[isSundayTable]="timetable.isSundayTable"
		[jobs]="jobs"
		[noDriverJobs]="noDriverJobs"
		[referenceDate]="timetable.selectedDate"
		[relations]="relations"
		[companySettings]="companySettings"
		fxFlex="45"
	>
	</app-relations-capacity>
	<div *ngIf="userProfile.hasAppActivatedRole && !userProfile.hasReadOnlyRole" class="sendJobsToAppUsersButton">
		<button
			(click)="sendJobsToAppUser()"
			[class.dispatchToAllDriversActive]="(timetableFilterService.$currentJobsToSend | async).length > 0"
			[matTooltipPosition]="'left'"
			class="mat-raised mat-small mat-ink-ripple"
			id="dispatchToAllDrivers"
			matTooltip="{{ 'DISPATCHTOALLDRIVERS_TT' | translate }}"
		>
			<div class="jobIconSidenav dispatchToDriverLogo"></div>
			<span
				*ngIf="(timetableFilterService.$currentJobsToSend | async).length"
				[class.showBadgeAnimation]=""
				[matTooltipPosition]="'below'"
				class="dispatchToDrivers-badge"
				matTooltip="{{ 'DISPATCHTOALLDRIVERS_CURRENTJOBS_TT' | translate }}"
			>
				{{ (timetableFilterService.$currentJobsToSend | async).length }}
			</span>
		</button>
	</div>
</mat-toolbar>

<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline" name="tableDeleteFormHeader">
		<i class="material-icons dialogIconBackground deleteDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">delete</i>
		<span>{{ dataType }} {{ 'DELETE_SMALL' | translate }}</span>
	</h1>
</mat-toolbar>
<mat-dialog-content>
	<p class="deleteConfirmMessage">
		{{ 'DELETE_CONFIRM_1' | translate }} <span class="fontWeight600">{{ dataType }} {{ dataName }}</span>
		{{ 'DELETE_CONFIRM_2' | translate }}
	</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end none">
	<button mat-button class="buttonCancel mat-raised" [mat-dialog-close]="false">
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button mat-button class="buttonDelete mat-raised" [mat-dialog-close]="true">
		<mat-icon>
			<i class="material-icons">delete</i>
		</mat-icon>
		{{ 'DELETE' | translate }}
	</button>
</mat-dialog-actions>

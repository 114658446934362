import { Appointment, Driver, ProfileDate } from '@flutaro/package/lib/model/Driver';
import { getDriverWeekEndWorkingStatus } from './TimetableFunctions';
import { Pipe, PipeTransform } from '@angular/core';
import { TimeTable } from './TimeTable';
import { appointmentIsInCurrentDateRange } from './data/TimetableDriverFunctions';
import { getISOWeek, isBefore, setHours, setISODay, setMinutes, subWeeks } from 'date-fns';
import { AppScheduleBasics } from '@flutaro/package/lib/model/AppScheduleClasses';
import { getSchedulesInTimeRange } from '@flutaro/package/lib/functions/AppScheduleFunctions';

@Pipe({
	name: 'driverWeekendWorkCounter',
})
export class GetDriversWeekendWorkingStatusPipe implements PipeTransform {
	transform(driver: Driver, refDate: Date): number {
		if (!driver || !refDate) return 0;
		return getDriverWeekEndWorkingStatus(driver, refDate);
	}
}

@Pipe({
	name: 'driverWorkweekStart',
})
export class GetDriversWorkweekStartPipe implements PipeTransform {
	transform(driver: Driver, timetable: TimeTable): Date {
		// TODO: test me and replace timetableDataService implementation
		let workweekStart: ProfileDate;
		let workweekEnd: ProfileDate;
		const isEventWeek = getISOWeek(timetable.startDate) % 2 === 0;
		// Set workweekStart and workweekEnd for this view/week
		if (driver.regularWorkweekDriver) {
			workweekStart = driver.regularWorkweek.regularWorkweekStart;
			workweekEnd = driver.regularWorkweek.regularWorkweekEnd;
		} else {
			// Even-Week
			if (isEventWeek) {
				workweekStart = driver.alternatingWorkWeek.evenWorkweekStart;
				workweekEnd = driver.alternatingWorkWeek.evenWorkweekEnd;
			} else {
				// Odd-Week
				workweekStart = driver.alternatingWorkWeek.oddWorkweekStart;
				workweekEnd = driver.alternatingWorkWeek.oddWorkweekEnd;
			}
		}
		// ISO-Weekday is 1-7 (Mo-Sun), we use 0 - 6 for workweek Settings currently. This converts Monday day 0 to 1 etc
		let workweekStartDate = setMinutes(
			setHours(setISODay(timetable.startDate, workweekStart.weekDay + 1), workweekStart.hour),
			workweekStart.minute,
		);
		let workweekEndDate = setMinutes(
			setHours(setISODay(timetable.startDate, workweekEnd.weekDay + 1), workweekEnd.hour),
			workweekEnd.minute,
		);

		if (!isBefore(workweekStartDate, workweekEndDate)) {
			workweekStartDate = subWeeks(workweekStartDate, 1);
		}
		if (workweekStartDate.getTime() === workweekEndDate.getTime()) {
			// Fallback if no workweek settings are set / driver is driving all time. Same for endDate
			return timetable.startDate;
		}
		return workweekStartDate;
	}
}

@Pipe({
	name: 'driverAppointmentsInTimetable',
})
export class GetDriversAppointmentsInTimetablePipe implements PipeTransform {
	transform(appointments: Appointment[], timetable: TimeTable): Appointment[] {
		return appointments.filter((appointment) => appointmentIsInCurrentDateRange(appointment, timetable));
	}
}

@Pipe({
	name: 'scheduleNotActiveBlocks',
})
export class GetVehiclesDriverSchedulesInTimetablePipe implements PipeTransform {
	transform(schedules: AppScheduleBasics[], timetable: TimeTable): AppScheduleBasics[] {
		const scheduleBlocks = [];
		const schedulesInTimeRange = getSchedulesInTimeRange(schedules, timetable.startDate, timetable.endDate);
		if (!schedulesInTimeRange?.length) return []; // Should not happen, vehicle would be filtered out
		const firstScheduleStartDate = schedulesInTimeRange[0].startDate;
		// Case 1: Blocked at timetable start
		if (firstScheduleStartDate > timetable.startDate) {
			const timetableStartBlock = new AppScheduleBasics();
			timetableStartBlock.startDate = timetable.startDate;
			timetableStartBlock.endDate = firstScheduleStartDate;
			scheduleBlocks.push(timetableStartBlock);
		}
		const lastScheduleEndDate = schedulesInTimeRange[schedulesInTimeRange.length - 1].endDate;
		// Case 2: Blocked at timetable end
		if (lastScheduleEndDate && lastScheduleEndDate < timetable.endDate) {
			const timetableEndBlock = new AppScheduleBasics();
			timetableEndBlock.startDate = lastScheduleEndDate;
			timetableEndBlock.endDate = timetable.endDate;
			scheduleBlocks.push(timetableEndBlock);
		}
		// Case3: Blocks in between timetables range
		if (schedulesInTimeRange.length > 1) {
			schedulesInTimeRange.forEach((schedule, index) => {
				if (!index) return;
				const prevSchedule = schedulesInTimeRange[index - 1];
				const timetableInBetweenBlock = new AppScheduleBasics();
				timetableInBetweenBlock.startDate = prevSchedule.endDate;
				timetableInBetweenBlock.endDate = schedule.startDate;
				console.log(
					`Detected schedule-block between ${timetableInBetweenBlock.startDate} and ${timetableInBetweenBlock.endDate}`,
				);
				scheduleBlocks.push(timetableInBetweenBlock);
			});
		}
		return scheduleBlocks;
	}
}

@Pipe({
	name: 'scheduleChangeInTimetable',
})
export class IsScheduleChangeForVehicleInTimetableRange implements PipeTransform {
	transform(schedules: AppScheduleBasics[], timetable: TimeTable): boolean {
		if (!schedules?.length) return false;
		const schedulesInTimeRange = getSchedulesInTimeRange(schedules, timetable.startDate, timetable.endDate);
		if (!schedulesInTimeRange?.length) return false; // Should not happen, vehicle would be filtered out
		// Case 1: multiple schedules in timetable view exists - is relevant schedule change
		if (schedulesInTimeRange.length > 1) return true;
		// Case 2: If a schedule starts after timetable start or ends before timetable end - is relevant schedule change
		const firstScheduleStartDate = schedulesInTimeRange[0].startDate;
		const lastScheduleEndDate = schedulesInTimeRange[schedulesInTimeRange.length - 1].endDate;
		return (
			firstScheduleStartDate > timetable.startDate || (lastScheduleEndDate && lastScheduleEndDate < timetable.endDate)
		);
	}
}

@Pipe({
	name: 'widthInTimetable',
})
export class GetObjectsWidthInTimetablePipe implements PipeTransform {
	transform(startDate: Date, endDate: Date, timetable: TimeTable): string {
		if (!startDate || !endDate) return '0';
		return timetable.getUnitForTimetable(timetable.calculateObjectsWidth(startDate, endDate));
	}
}

@Pipe({
	name: 'marginInTimetable',
})
export class GetObjectsMarginInTimetablePipe implements PipeTransform {
	transform(startDate: Date, timetable: TimeTable): string {
		if (!startDate) return '0';
		return timetable.getUnitForTimetable(timetable.getMarginByDateForObjectInTimetable(startDate), null);
	}
}

@Pipe({
	name: 'timetableScrollLeft',
})
export class GetTimetablesScrollLeftPipe implements PipeTransform {
	transform(any: any): string {
		const timetableScrollLeft = document.getElementById('timetableSection').scrollLeft;
		return `${timetableScrollLeft}px`;
	}
}

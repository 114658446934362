<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
	<div class="externalJobDialogTableHeaderRow fullWidth" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
		<div class="externalJobActivateCheckbox" fxLayout="row" fxLayoutAlign="center center">
			<mat-checkbox
				(change)="changeAllCheckedStatus()"
				[(indeterminate)]="indeterminateState"
				[(ngModel)]="allJobsSelected"
				[matTooltipPosition]="'right'"
				matTooltip="{{ 'JOB_IMPORT_SHOW_HIDE_TT' | translate }}"
			></mat-checkbox>
		</div>
		<div class="externalJobVerticalDivider"></div>

		<div>
			<p class="externalJobTableHeader externalJobTableStatus" *ngIf="false">
				<i class="material-icons externalJobDialogTableHeaderIcon">task_alt</i> Status
			</p>
		</div>
		<p class="externalJobTableHeader externalJobTableIdentifier">
			<i class="material-icons externalJobDialogTableHeaderIcon">description</i> {{ 'JOB_IDENTIFIER' | translate }}
		</p>
		<div class="externalJobVerticalDivider"></div>
		<p class="externalJobTableHeader externalJobLicensePlateWidth">
			<i class="material-icons externalJobDialogTableHeaderIcon">label</i> {{ 'LICENSEPLATE' | translate }}
		</p>

		<div
			*ngFor="let destination of [1, 2]; let first = first; let last = last"
			fxLayout="row"
			fxLayoutAlign="start center"
			fxLayoutGap="10px"
		>
			<div class="externalJobVerticalDivider"></div>
			<p
				class="externalJobTableHeader externalJobTableAddress"
				[ngClass]="{ jobPickupColor: first, jobDeliveryColor: last }"
			>
				<i class="material-icons externalJobDialogTableHeaderIcon">place</i> {{ 'ADDRESS' | translate }}
			</p>

			<p
				class="externalJobTableHeader externalJobTableDate"
				[ngClass]="{ jobPickupColor: first, jobDeliveryColor: last }"
			>
				<i class="material-icons externalJobDialogTableHeaderIcon">date_range</i> {{ 'EARLIEST_DATE' | translate }}
			</p>

			<p
				class="externalJobTableHeader externalJobTableDate"
				[ngClass]="{ jobPickupColor: first, jobDeliveryColor: last }"
			>
				<i class="material-icons externalJobDialogTableHeaderIcon">date_range</i> {{ 'LATEST_DATE' | translate }}
			</p>

			<p
				class="externalJobTableHeader jobDestinationNoteField"
				[ngClass]="{ jobPickupColor: first, jobDeliveryColor: last }"
			>
				<i class="material-icons externalJobDialogTableHeaderIcon">comment</i> {{ 'JOB_COMMENT' | translate }}
			</p>

			<p
				class="externalJobTableHeader externalJobTableCargo marginRight5 marginLeft5"
				[ngClass]="{ jobPickupColor: first, jobDeliveryColor: last }"
			>
				<i class="material-icons externalJobDialogTableHeaderIcon">inbox</i> {{ 'JOB_CARGO' | translate }}
			</p>
		</div>
		<div class="externalJobVerticalDivider"></div>
		<p class="externalJobTableHeader externalJobTableAttributes">
			<i class="material-icons externalJobDialogTableHeaderIcon">list</i> {{ 'JOB_MORE' | translate }}
		</p>
		<div class="externalJobVerticalDivider"></div>
		<p *ngIf="companySettings.isTenantActivated" class="externalJobTableHeader externalJobTenant">
			<i class="material-icons externalJobDialogTableHeaderIcon">business</i> {{ 'TENANT' | translate }}
		</p>
	</div>

	<mat-divider class="externalJobImportComponentTotalWidth"></mat-divider>
	<div
		*ngFor="let externalJob of externalJobs"
		class="externalJobRow"
		fxLayout="row"
		fxLayoutGap="5px"
		fxLayoutAlign="center center"
	>
		<div class="externalJobActivateCheckbox" fxLayout="row" fxLayoutAlign="center center">
			<mat-checkbox (change)="indeterminateState = true" [(ngModel)]="externalJob.isChecked"> </mat-checkbox>
		</div>
		<mat-divider class="externalJobVerticalDivider"></mat-divider>

		<div fxLayout="row" fxLayoutAlign="start start" *ngIf="false">
			<i *ngIf="true" class="material-icons importStatusIconValid">check_circle</i>
			<i *ngIf="false" class="material-icons importStatusInvalidIcon">cancel</i>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center" class="externalJobInputTableStyle externalJobTableIdentifier">
			<mat-form-field class="fullWidth">
				<input [(ngModel)]="externalJob.identifier" disabled matInput type="text" />
			</mat-form-field>
		</div>
		<mat-divider class="externalJobVerticalDivider"></mat-divider>
		<app-select-vehicle-for-job
			[job]="externalJob"
			[companySettings]="companySettings"
			[userProfile]="userProfile"
			[vehicles]="vehicles"
			[drivers]="drivers"
			[hideIcons]="true"
			[selectedDate]="externalJob.fixedJobDestinations[0].earliestDate"
			class="externalJobLicensePlateWidth externalJobLicensePlateSearchComponent externalJobInputTableStyle"
		></app-select-vehicle-for-job>
		<mat-divider class="externalJobVerticalDivider"></mat-divider>
		<div class="externalJobRowDestinations externalJobPickupDestinations" fxLayout="column">
			<div *ngFor="let pickupDestination of externalJob.fixedJobDestinations; let i = index" fxLayout="column">
				<app-job-destination
					(destinationChange)="triggerJobsComponentsCD(externalJob)"
					(locationChange)="updateJobOnChangedDestinationPosition($event, i, externalJob)"
					[destination]="pickupDestination"
					[destinationIndex]="i"
					[destinations]="externalJob.fixedJobDestinations"
					[isExternalJob]="true"
					[type]="'PICKUP'"
					*ngIf="pickupDestination.locationType === 'PICKUP'"
				>
				</app-job-destination>
			</div>
		</div>
		<mat-divider class="externalJobVerticalDivider marginLeft5"></mat-divider>
		<div class="externalJobRowDestinations" fxLayout="column">
			<div
				*ngFor="let deliveryDestination of externalJob.fixedJobDestinations; let destinationIndex = index"
				fxLayout="column"
			>
				<app-job-destination
					(destinationChange)="triggerJobsComponentsCD(externalJob)"
					(locationChange)="updateJobOnChangedDestinationPosition($event, destinationIndex, externalJob)"
					[destination]="deliveryDestination"
					[destinationIndex]="destinationIndex"
					[destinations]="externalJob.fixedJobDestinations"
					[isExternalJob]="true"
					[type]="'DELIVERY'"
					*ngIf="deliveryDestination.locationType === 'DELIVERY'"
					class="externalJobDeliveryDestinations"
				>
				</app-job-destination>
			</div>
		</div>
		<mat-divider class="externalJobVerticalDivider marginLeft5"></mat-divider>
		<div class="externalJobRowDestinations" fxLayout="row">
			<div class="externalJobInputTableStyle" fxLayout="column">
				<p class="externalJobDialogMoreAttributesText">
					{{ attributesToString(externalJob.attributes) }}
				</p>
			</div>
		</div>
		<mat-divider class="externalJobVerticalDivider"></mat-divider>
		<div
			*ngIf="companySettings.isTenantActivated"
			class="externalJobRowDestinations externalJobRowLastElement"
			fxFlex
			fxLayout="row"
		>
			<app-tenant
				[(invalidTenantId)]="externalJob.invalidTenantId"
				[(tenant)]="externalJob.tenant"
				[companySettings]="companySettings"
				[disabled]="externalJob.tenant && !externalJob.invalidTenantId && !(userProfile | isTenantAdmin)"
				[isExternalJob]="true"
				class="externalJobInputTableStyle"
			>
			</app-tenant>
		</div>
	</div>
</div>

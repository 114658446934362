import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { DialogWeekdayAndTimePickerComponent } from './dialog.weekdayAndTime.component';
import { CommonModule } from '@angular/common';
import { DialogRegulatingtimeComponent } from './dialog.regulating.component';
import { JobTimewindowComponent } from '../job/components/job.timewindow.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ComponentsModule } from '../app/components/components.module';
import { AppFormModule } from '../form/form.module';

@NgModule({
	declarations: [
		DialogWeekdayAndTimePickerComponent,
		DialogRegulatingtimeComponent,
		JobTimewindowComponent,
		ConfirmDialogComponent,
	],
	imports: [FormsModule, CommonModule, FlutaroCustomMaterialModule, ComponentsModule, AppFormModule],
	exports: [
		ComponentsModule,
		DialogWeekdayAndTimePickerComponent,
		DialogRegulatingtimeComponent,
		JobTimewindowComponent,
		AppFormModule,
	],
	providers: [],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class DialogModule {}

import { CostCalculationData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { appRoundNumber } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { MinusPositionCorrection } from '@flutaro/package/lib/model/costs/Costs';

export function handleCostCorrection(costData: CostCalculationData, isRemove: boolean) {
	undoMinusPositionCorrectionsForJob(costData);
	if (isRemove) {
		console.debug(`handleCostCorrection, removed minusCorrections for job ${costData.job.job.identifier}`);
		return;
	}
	distributeCostsEvenly(costData);
}

export function adjustJobCostsForMinusCorrectionAmount(job: JobWrapper, amount: number) {
	if (!amount) return;
	job.costCalculation.totalCosts = appRoundNumber(job.costCalculation.totalCosts - amount);
	job.costCalculation.profit = appRoundNumber(job.costCalculation.profit + amount);
}

export function undoMinusPositionCorrectionsForJob(costData: CostCalculationData) {
	console.debug(`undoMinusPositionCorrectionsForJob, called for job ${costData.job.job.identifier}`);
	// 1) Resetting jobs minusCorrections
	costData.job.costCalculation.minusPositionCorrections = [];
	// 2) Resetting other jobs minusCorrections
	const affectedJobs = costData.jobsStartingOnSameDay.filter((job) =>
		job.costCalculation.minusPositionCorrections?.find(
			(correction: MinusPositionCorrection) => correction.jobId === costData.job.backendId,
		),
	);

	if (!affectedJobs?.length) {
		console.debug(
			`undoMinusPositionCorrectionsForJob, no other jobs affected for job ${costData.job.job.identifier} - finished`,
		);
		return;
	}
	affectedJobs.forEach((affectedJob) => {
		const correctionAmount = affectedJob.costCalculation.minusPositionCorrections?.find(
			(corr: MinusPositionCorrection) => corr.jobId === costData.job.backendId,
		)?.amount;
		if (correctionAmount) adjustJobCostsForMinusCorrectionAmount(affectedJob, -correctionAmount);
		affectedJob.costCalculation.minusPositionCorrections = affectedJob.costCalculation.minusPositionCorrections.filter(
			(corr: MinusPositionCorrection) => corr.jobId !== costData.job.backendId,
		);
		costData.addRecalculatedJob(affectedJob);
	});
}

/**
 * Eliminate minus-positions for cost calculation if possible
 * Procedure:
 *
 * @param costData
 */
export function distributeCostsEvenly(costData: CostCalculationData) {
	const jobs = costData.jobsStartingOnSameDay.concat([costData.job]);
	const minusProfitJobs = jobs.filter((job) => job.costCalculation.profit < 0);
	const plusProfitJobs = jobs.filter((job) => job.costCalculation.profit > 0);
	if (!minusProfitJobs.length || !plusProfitJobs.length) return;
	console.debug(
		`distributeCostsEvenly, ${minusProfitJobs.length} jobs with minus profit, ${plusProfitJobs.length} jobs with plus profit.`,
	);
	minusProfitJobs.forEach((minusJob) => {
		plusProfitJobs.forEach((plusJob) => {
			if (plusJob.costCalculation.profit <= 0) {
				console.debug(`distributeCostsEvenly, profitJob doesnt have profit left. Aborting current run`);
				return;
			}
			if (!minusJob.costCalculation.minusPositionCorrections) minusJob.costCalculation.minusPositionCorrections = [];
			if (!plusJob.costCalculation.minusPositionCorrections) plusJob.costCalculation.minusPositionCorrections = [];
			let missingProfit = Math.abs(minusJob.costCalculation.profit);
			console.debug(
				`distributeCostsEvenly, ${plusJob.job.identifier} gives ${missingProfit} to ${minusJob.job.identifier} as minus position prevention`,
			);
			minusJob.costCalculation.minusPositionCorrections.push(
				new MinusPositionCorrection(
					plusJob.backendId,
					plusJob.costCalculation.profit > missingProfit ? missingProfit : plusJob.costCalculation.profit,
				),
			);
			adjustJobCostsForMinusCorrectionAmount(minusJob, missingProfit);
			plusJob.costCalculation.minusPositionCorrections.push(
				new MinusPositionCorrection(
					minusJob.backendId,
					plusJob.costCalculation.profit > missingProfit ? -missingProfit : -plusJob.costCalculation.profit,
				),
			);
			adjustJobCostsForMinusCorrectionAmount(plusJob, -missingProfit);
			costData.addRecalculatedJobs([minusJob, plusJob]);
		});
	});
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FlutaroJobService } from '../data/data-services/data.job.service';
import { MenuComponentProvider } from './menu.component.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { APP_MENU } from './MenuClasses';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { CompanyService } from '../company/company.service';
import { FlutaroClientService } from '../data/data-services/data.client.service';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';

@Component({
	selector: 'app-menu-jobs',
	templateUrl: './menu.jobs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuJobsComponent implements OnInit {
	constructor(
		private menuProvider: MenuComponentProvider,
		public jobService: FlutaroJobService,
		public authFb: WebAuthService,
		public userSettings: UserSettingsProvider,
		public companyProvider: CompanyService,
		public clientProvider: FlutaroClientService,
		public driverService: FlutaroDriverService,
		public vehicleService: FlutaroVehicleService,
	) {}

	ngOnInit() {
		this.menuProvider.initTabsAfterMenuChange([APP_MENU.JOBS]);
	}
}

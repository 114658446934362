/**
 * This function Creates the jobs timewindow Timetable-Entries.
 * Filters out timewindows which dont need to be displayed.
 * @param entry
 * @param timetable
 */
import { TimetableFilteredEntry } from './TimetableFilterClasses';
import { TimeTable } from '../TimeTable';
import { calculateTimewindowEntryStyles } from './TimetableStyleFunctions';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

export function setTimetableTimewindowEntry(entry: TimetableFilteredEntry, timetable: TimeTable) {
	entry.jobsEntry.forEach((jobEntry) => {
		// Filter out Timewindows with the exact same Size as the Job, which wouldnt be seen by the User
		if (
			getFirstPickUp(jobEntry.jobWrapper).earliestDate.getTime() ===
				getFirstPickUp(jobEntry.jobWrapper).plannedDate.getTime() &&
			getLastDestination(jobEntry.jobWrapper).latestDate.getTime() ===
				getLastDestination(jobEntry.jobWrapper).plannedDate.getTime()
		) {
			return;
		}
		entry.timewindowEntry.push(calculateTimewindowEntryStyles(jobEntry, timetable));
	});
}

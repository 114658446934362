import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlutaroJobService } from '../../data/data-services/data.job.service';
import { MenuComponentProvider } from '../../menu/menu.component.provider';
import { FlutaroSidenavProvider } from '../../sidenav/sidenav.provider';
import { TimetableFilterController } from '../data/timetable.filter.controller';
import { hideDroppedElementInTimetable } from '../TimetableFunctions';
import { FlutaroTimetableFilterService } from '../data/timetable.data.provider';
import { FlutaroSearchService } from '../../menu/toolbar/search.service';
import { TimetableFilterStore, TimetableFilteredEntry } from '../data/TimetableFilterClasses';
import { PreviewCostRequest, TimetableJobEntry, TimetableTimewindowEntry } from '../TimetableClasses';
import { TimetableNoteProvider } from '../notes/timetable-note-provider.service';
import { FlutaroNotificationsProvider } from 'src/app/notifications/notification.snack.provider';
import { DragulaService } from 'ng2-dragula';
import { MatMenuTrigger } from '@angular/material/menu';
import {
	getJobIdFromDragAndDropEvent,
	getVehicleIdFromDropEvent,
	getVehicledFromDragEvent,
} from './TimetableComponentFunctions';
import { VehicleStatusStoreChange } from './TimetableComponentClasses';
import { isSameDay } from 'date-fns';
import { JobsCostCalculationPreview } from '@flutaro/package/lib/model/costs/CostCalculation';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { TimetableNote } from '@flutaro/package/lib/model/TimetableNote';
import { EVENT_TYPE, FlutaroEvent, TimetableEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { isDateInInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';

@Component({
	selector: 'app-timetable',
	templateUrl: './timetable.component.html',
	styleUrls: ['./timetable.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroTimetableComponent extends AppBasicComponent implements AfterViewInit, OnDestroy, OnChanges {
	@Input() timetableData: TimetableFilteredEntry[] = [];
	@Input() notes: TimetableNote[];
	@Input() events: Map<string, FlutaroEvent[]>;
	@Input() locations: Synonym[];
	@Input() planningMode: boolean;
	@Input() noteMode: boolean;
	@Input() filterStore: TimetableFilterStore;
	@Input() customJobDisplay;
	@Input() costPreview: JobsCostCalculationPreview;
	@Input() scrollLeftSubject: BehaviorSubject<number>;
	@Input() openSideNav: string | null;
	@Output() jobSelected: EventEmitter<JobWrapper> = new EventEmitter();
	@Output() planningModeExcludeChange: EventEmitter<string> = new EventEmitter();
	@Output() costPreviewRequest: EventEmitter<PreviewCostRequest> = new EventEmitter();
	@Output() dragChange: EventEmitter<string> = new EventEmitter();
	@Output() vehicleStatusStoreChange: EventEmitter<VehicleStatusStoreChange> = new EventEmitter();
	@ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
	contextMenuPosition = { x: '0px', y: '0px' };
	timelineBarMargin: string;
	timelineInterval: ReturnType<typeof setInterval>;
	showTimelineBar: boolean = true;
	EVENT_TYPES = EVENT_TYPE;
	isDrag: boolean = false;
	dragMarginTopBackup: string;
	private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

	constructor(
		public sidenavService: FlutaroSidenavProvider,
		public menuProvider: MenuComponentProvider,
		private changeDetectorRef: ChangeDetectorRef,
		private dragulaService: DragulaService,
		public jobService: FlutaroJobService,
		public timetableDataController: TimetableFilterController,
		public searchService: FlutaroSearchService,
		public timetableFilterService: FlutaroTimetableFilterService,
		public noteService: TimetableNoteProvider,
		public notifications: FlutaroNotificationsProvider,
	) {
		super();
	}

	ngAfterViewInit() {
		this.initializeDragular();
		this.subscribeToDragularChanges();
		this.activateTimelineRefresher();
		this.updateTimelineBarMargin();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
		// Find an maybe already existing Dragular-Bag
		let jobsBag: any = this.dragulaService.find('bag-jobs');
		if (jobsBag) {
			// Destroy the Bag to initialize with new Options
			this.dragulaService.destroy('bag-jobs');
		}
		clearInterval(this.timelineInterval);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.timetable) return;
		if (changes['timetable']) {
			this.updateTimelineBarMargin();
		}

		if (
			changes['jobs'] ||
			changes['vehicles'] ||
			changes['drivers'] ||
			changes['filterStore'] ||
			changes['notes'] ||
			changes['events'] ||
			changes['contractors'] ||
			changes['timetable']
		) {
			this.timetableFilterService.createFilteredTimetableMap();
		}
	}

	subscribeToDragularChanges() {
		this.dragulaService
			.drag()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((drag) => {
				this.isDrag = true;
				const source = <HTMLElement>drag.source;
				const jobElement = <HTMLElement>drag.el;
				if (source.id === 'unplanned_job') return;
				// Remove marginTop so that an job with former overlapping will be shown in correct column when droped and draged
				this.dragMarginTopBackup = jobElement.style.marginTop;
				this.changeDetectorRef.markForCheck();
				const jobsId = getJobIdFromDragAndDropEvent(drag);
				this.dragChange.emit(jobsId);
			});
		this.dragulaService
			.drop()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async (drop) => {
				console.debug(`drop`);
				const source = <HTMLElement>drop.source;
				const destinationRow = drop.target;
				const element = <HTMLElement>drop.el;
				if (source === destinationRow || destinationRow.id === 'unplannedDropZone') return;
				const jobsId = getJobIdFromDragAndDropEvent(drop);
				const job = this.jobs.find((job) => job.backendId === jobsId);
				const vehicleId = getVehicleIdFromDropEvent(drop);

				const [dispatchAbort, updatedJob] = await to(
					this.timetableDataController.dispatchJobToNewVehicle(job, vehicleId),
				);
				hideDroppedElementInTimetable(element);
				if (source.id === 'unplanned_job') {
					hideDroppedElementInTimetable(source);
				}
				this.dragChange.emit(null);
				this.costPreviewRequest.emit(null);
				this.isDrag = false;
				if (dispatchAbort || !updatedJob) {
					await this.jobService.resetJobsDataInternallyOnly(job);
					return;
				}
			});
		this.dragulaService
			.dragend()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((dragend) => {
				// TODO: this needs a refactor for two separate job bags to only react in one of the components
				console.debug(`dragend`);
				this.dragChange.emit(null);
				this.costPreviewRequest.emit(null);
				this.isDrag = false;
				const jobElement = <HTMLElement>dragend.el;
				jobElement.style.marginTop = this.dragMarginTopBackup;
				this.changeDetectorRef.markForCheck();
			});
		this.dragulaService
			.over()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((over) => {
				if (!this.isDrag) return;
				console.debug(`dragulaService.over called`);
				const destinationRow = over.container;
				const sourceRow = <HTMLElement>over.source;
				const jobElement = <HTMLElement>over.el;
				if (destinationRow === sourceRow) {
					jobElement.style.marginTop = this.dragMarginTopBackup;
					return;
				}
				jobElement.style.marginTop = '0px';
				const jobId = getJobIdFromDragAndDropEvent(over);
				const vehicleId = getVehicledFromDragEvent(over);
				console.debug(`dragulaService.over called for vehicleId ${vehicleId}`);
				// Early abort if same row and abort if action in unplannedJobs-Sidenav
				if (destinationRow === sourceRow || destinationRow.id === 'unplannedDropZone') return;
				// Jobs-Row Hover Class
				if (!destinationRow.classList.contains('timetableDriverJobsHoverBorder'))
					destinationRow.className += ' timetableDriverJobsHoverBorder';
				this.costPreviewRequest.emit(new PreviewCostRequest(jobId, vehicleId));
			});
		this.dragulaService
			.out()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((out) => {
				const destinationRow = out.container;
				if (destinationRow.id === 'unplannedDropZone') return;

				if (destinationRow.classList.contains('timetableDriverJobsHoverBorder'))
					destinationRow.classList.remove('timetableDriverJobsHoverBorder');
			});
	}

	onContextMenu(event: MouseEvent, menu?: string) {
		if (menu) return;
		event.preventDefault();
		this.contextMenuPosition.x = event.clientX + 'px';
		this.contextMenuPosition.y = event.clientY + 'px';
		this.contextMenu.menu.focusFirstItem('mouse');
		this.contextMenu.menu.setElevation(1);
		this.contextMenu.openMenu();
	}

	trackByVehicleId(index, mapEntry: TimetableFilteredEntry) {
		return mapEntry.vehicleEntry.vehicle.backendId;
	}

	trackJobEntry(index, jobEntry: TimetableJobEntry) {
		return jobEntry.jobWrapper?.backendId;
	}

	trackByEventProperties(index, eventEntry: TimetableEvent) {
		return eventEntry?.eventId;
	}

	trackByTimewindowProperties(index, timewindowEntry: TimetableTimewindowEntry) {
		return timewindowEntry
			? timewindowEntry.color + timewindowEntry.marginLeft + timewindowEntry.marginTop + timewindowEntry.width
			: undefined;
	}

	storeNoteEntryForVehicle(noteText: string, mapEntry: TimetableFilteredEntry) {
		return this.noteService.storeEditNote(mapEntry.note, noteText, mapEntry.vehicleEntry.vehicle.backendId);
	}

	public openJobSidenav($event, job: JobWrapper) {
		$event.stopPropagation();
		this.sidenavService.showJobNav(job);
	}

	/**
	 * Initializes the Dragular-Bag with the move-Setting set to true for timetable and false for Merge-Tool
	 */
	private initializeDragular() {
		this.dragulaService.createGroup('bag-jobs', {
			revertOnSpill: true,
			direction: 'vertical',
			invalid: (el) => el.classList.contains('donotdrag'),
		});
	}

	private activateTimelineRefresher() {
		this.timelineInterval = setInterval(
			() => {
				this.updateTimelineBarMargin();
			},
			5 * 60 * 1000,
		);
	}

	private updateTimelineBarMargin() {
		if (
			!this.timetable ||
			!isDateInInterval(new Date(), this.timetable.rangeStart, this.timetable.rangeEnd) ||
			(this.timetable.isDayTimetable && !isSameDay(this.timetable.startDate, new Date()))
		) {
			this.showTimelineBar = false;
			this.changeDetectorRef.detectChanges();
			return;
		}
		this.showTimelineBar = true;
		this.timelineBarMargin = this.timetable.getUnitForTimetable(
			this.timetable.getMarginByDateForObjectInTimetable(new Date()),
		);
		this.changeDetectorRef.detectChanges();
	}
}

import { PipeTransform, Injectable, Pipe } from '@angular/core';
import { transformSearchString } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';

@Pipe({
	name: 'transformObjectIntoArray'
})
@Injectable()
export class TransformObjectIntoArray implements PipeTransform {
	transform(item: any): any {
		return item ? [item] : [];
	}
}

@Pipe({
	name: 'filterElementsBy'
})
@Injectable()
export class FilterElementsByPipe implements PipeTransform {
	transform(items: any[], query: string, searchKey?: string): any[] {
		if (!items?.length) return [];
		if (!query?.length) return items;
		query = transformSearchString(query);
		return items.filter((item) => transformSearchString(searchKey ? item[searchKey] : item.toString()).includes(query));
	}
}

@Pipe({ name: 'appOrderBy' })
export class FlutaroOrderByPipe implements PipeTransform {
	static compare(reverse: boolean, a: any, b: any): number {
		if (a < b && !reverse) {
			return -1;
		}
		if (a > b && !reverse) {
			return 1;
		}
		if (a < b && reverse) {
			return 1;
		}
		if (a > b && reverse) {
			return -1;
		}
		return 0;
	}

	transform(input: any[], config: string | string[]): any {
		if (!input) {
			return input;
		}

		if (config === '+' || config === '-') {
			return config === '+' ? input.sort() : input.sort().reverse();
		}

		if (Array.isArray(config) === false) {
			config = <string[]>[config];
		}

		// As soon as a or b is smaller/greater than the other, we can immediately return
		return input.sort((a: any, b: any): number => {
			for (let fullProp of config) {
				let reverse = fullProp[0] === '-';
				let prop = fullProp.substr(1);

				// Is it a subobject?
				if (prop.indexOf('.') > 0) {
					let first = prop.split('.')[0];
					let last = prop.split('.')[1];

					let result = FlutaroOrderByPipe.compare(reverse, a[first][last], b[first][last]);
					if (result) return result;
					continue;
				}

				let result = FlutaroOrderByPipe.compare(reverse, a[prop], b[prop]);
				if (result) return result;
			}
			return 0;
		});
	}
}

@Pipe({ name: 'keyValOfObject' })
export class ObjectKeysValPipe implements PipeTransform {
	transform(value: any): any {
		let keys = [];
		for (let key in value) {
			keys.push({ key: key, value: value[key] });
		}
		return keys;
	}
}

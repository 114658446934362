import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InterfaceService } from '../data/data-services/data.interface.service';
import { ExternalJobsProvider } from './ExternalJob/externalJobs.provider';

@Component({
	selector: 'app-interface-import-notification',
	templateUrl: './interfaces.notification.component.html',
	styleUrls: ['interfaces.notification.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterfaceNotificationComponent {
	constructor(public interfaceService: InterfaceService, public externalJobsProvider: ExternalJobsProvider) {}
}

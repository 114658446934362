export enum APP_MENU {
	DASHBOARD = 'DASHBOARD',
	DATA_IMPORT = 'DATA_IMPORT',
	JOBS = 'JOBS',
	LOCATIONS = 'LOCATIONS',
	RELATIONS = 'RELATIONS',
	ADDRESS_CORRECTIONS = 'ADDRESS_CORRECTIONS',
	DAY_PLANNING = 'DAY_PLANNING',
	WEEK_PLANNING = 'WEEK_PLANNING',
	MAP_PLANNING = 'MAP_PLANNING',
	DRIVERS = 'DRIVERS',
	VEHICLES = 'VEHICLES',
	CUSTOMERS = 'CUSTOMERS',
	CONTRACTOR = 'CONTRACTOR',
}

import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	isVehicleActivatedForCompanyByDate,
	isVehicleActivatedForCompanyNow,
} from '@flutaro/package/lib/functions/VehicleFunctions';
import { VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';
import {
	getAllSchedulesForElement,
	getCurrentSchedule,
	getDriverForVehicleByScheduleDate,
	getScheduleForDate,
} from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { isVehicleTypeMatchingJobsRequiredVehicleType } from '@flutaro/package/lib/functions/vehicle/VehicleValidationFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { SCHEDULE_TYPES } from '@flutaro/package/lib/model/AppScheduleClasses';
import { getDriverNameByDriverId } from '@flutaro/package/lib/functions/driver/DriverFunctions';
import { getVehiclesTimetableDisplayedDriver } from '../../timetable/data/TimetableVehicleFunctions';
import { TimeTable } from '../../timetable/TimeTable';

@Pipe({
	name: 'vehicleById',
})
export class GetVehicleByIdPipe implements PipeTransform {
	transform(vehicleId: string, vehicles: Vehicle[]): Vehicle | undefined {
		return vehicles.find((vehicle) => vehicle.backendId === vehicleId);
	}
}

@Pipe({
	name: 'getVehiclesDriverNameNow',
})
export class GetVehiclesDriverNameNowPipe implements PipeTransform {
	transform(vehicle: Vehicle, drivers: Driver[]): string {
		const vehiclesDriverSchedule = getCurrentSchedule(vehicle.driverSchedules);
		return vehiclesDriverSchedule ? getDriverNameByDriverId(vehiclesDriverSchedule.entityId, drivers) : '';
	}
}

@Pipe({
	name: 'getVehiclesTrailerNameNow',
})
export class GetVehiclesTrailerNameNowPipe implements PipeTransform {
	transform(vehicle: Vehicle): string {
		const vehiclesTrailerSchedule = getCurrentSchedule(vehicle.trailerSchedules);
		return vehiclesTrailerSchedule ? vehiclesTrailerSchedule.entityName : '';
	}
}

@Pipe({
	name: 'isVehicleActivatedNow',
})
export class IsVehicleActivatedNowPipe implements PipeTransform {
	transform(vehicle: Vehicle, vehicles: Vehicle[], companySettings: CompanySettings): boolean {
		if (vehicle.type === VEHICLE_TYPE.TRUCK || vehicle.type === VEHICLE_TYPE.CAR) {
			return isVehicleActivatedForCompanyNow(vehicle, companySettings);
		} else {
			const trailersSchedules = getAllSchedulesForElement(vehicle.backendId, vehicles, SCHEDULE_TYPES.TRAILER_SCHEDULE);
			return !!getCurrentSchedule(trailersSchedules);
		}
	}
}

@Pipe({
	name: 'filterForActivatedVehiclesNow',
})
export class FilterForActivatedVehiclesNowPipe implements PipeTransform {
	transform(vehicles: Vehicle[], companySettings: CompanySettings): Vehicle[] {
		return vehicles.filter((vehicle) => isVehicleActivatedForCompanyNow(vehicle, companySettings));
	}
}

@Pipe({
	name: 'filterForActivatedTrucksByDate',
})
export class FilterForActivatedTrucksByDatePipe implements PipeTransform {
	transform(vehicles: Vehicle[], companySettings: CompanySettings, refDate: Date): Vehicle[] {
		return vehicles.filter((vehicle) => !!getScheduleForDate(vehicle.driverSchedules, refDate));
	}
}

@Pipe({
	name: 'filterForActivatedVehiclesByDate',
})
export class FilterForActivatedVehiclesByDatePipe implements PipeTransform {
	transform(vehicles: Vehicle[], companySettings: CompanySettings, refDate: Date): Vehicle[] {
		return vehicles.filter((vehicle) => isVehicleActivatedForCompanyByDate(vehicle, companySettings, refDate));
	}
}

@Pipe({
	name: 'filterForInactiveVehiclesNow',
})
export class FilterForInactiveVehiclesNowPipe implements PipeTransform {
	transform(vehicles: Vehicle[], companySettings: CompanySettings): Vehicle[] {
		return vehicles.filter((vehicle) => !isVehicleActivatedForCompanyNow(vehicle, companySettings));
	}
}

@Pipe({
	name: 'isVehicleMatchingJobRequirements',
})
export class IsVehicleMatchingJobRequirementsPipe implements PipeTransform {
	transform(vehicle: Vehicle, job: JobWrapper): boolean {
		return isVehicleTypeMatchingJobsRequiredVehicleType(job, vehicle);
	}
}

@Pipe({
	name: 'driverByDate',
})
export class DriverByDatePipe implements PipeTransform {
	transform(vehicle: Vehicle, drivers: Driver[], refDate: Date): Driver | undefined {
		return getDriverForVehicleByScheduleDate(vehicle, drivers, refDate);
	}
}

@Pipe({
	name: 'driverForTimetable',
})
export class GetVehiclesDriverForTimetablePipe implements PipeTransform {
	transform(vehicle: Vehicle, drivers: Driver[], timetable: TimeTable): Driver | undefined {
		return getVehiclesTimetableDisplayedDriver(vehicle, drivers, timetable);
	}
}

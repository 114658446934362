import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimetableFilterStore } from './TimetableFilterClasses';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class TimetableFilterStoreProvider {
	activeFilters: BehaviorSubject<TimetableFilterStore> = new BehaviorSubject<TimetableFilterStore>(
		new TimetableFilterStore(),
	);

	resetFilters() {
		// TODO: Investigate and fix why this is so crucial to be called everytime timetable data changes
		this.activeFilters.next(new TimetableFilterStore());
	}

	removeVehicleFromPlanningView(vehicleId: string) {
		let currentFilterStore = cloneDeep(this.activeFilters.getValue());
		currentFilterStore.planningViewRemovedVehicles.push(vehicleId);
		this.activeFilters.next(currentFilterStore);
	}

	isVehicleRemovedFromPlanningView(vehicleId: string): boolean {
		let currentFilterStore = this.activeFilters.getValue();
		return currentFilterStore.planningViewRemovedVehicles.indexOf(vehicleId) !== -1;
	}

	resetPlanningViewRemovedFilter() {
		let currentFilterStore = cloneDeep(this.activeFilters.getValue());
		currentFilterStore.planningViewRemovedVehicles = [];
		this.activeFilters.next(currentFilterStore);
	}
}

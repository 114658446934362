import { AfterViewInit, ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { WebAuthService } from './auth/web-auth.service';
import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { AtheneService } from '../athene/athene.service';
import { AppInternationalizationService } from './app.internationalization.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_NAME, APP_VERSION } from './app.meta';

(window as any).global = window;

@Component({
	selector: 'app-flutaro-component',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [
		'../../assets/stylings/timetable.css',
		'../../assets/stylings/default.css',
		'../../assets/stylings/buttons.css',
		'../../assets/stylings/flutaro-dialog.css',
		'../../assets/stylings/flutaro-material.css',
		'../../assets/auth/logoutStyle.css',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroAppComponent implements AfterViewInit {
	userIsInitialized: boolean = false; // First time a user logs in we initialize the App for the user, which only must happen once. Currently we dont react in the App for User changes like custom claims

	constructor(
		public auth: WebAuthService,
		public synonym: FlutaroSynonymService,
		private athene: AtheneService,
		private languageService: AppInternationalizationService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
	) {
		this.initAphrodite();
		this.addFlutaroIcons();
	}

	initAphrodite() {
		this.auth.$user.subscribe((user) => {
			if (!user) {
				return;
			}
			if (!user.emailVerified) {
				console.log(`initAphrodite, email not verified`);
				this.auth.handleEmailNotVerifiedUser(user);
				return;
			}
			if (this.userIsInitialized) {
				console.debug(`initAphrodite, already true userIsInitialized - stopping`);
				return;
			}
			this.userIsInitialized = true;
			this.auth.getUserFromDB(user);
		});
	}

	printLog() {
		let flutaroMainStyle = 'font-size:26px; font-weight:200; font-family:helvetica,arial; color:#40c1ac;';
		let flutaroMessageStyle =
			'font-size:16px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial; color:#505759;';
		console.log(`%cFlutaro ${APP_NAME} v${APP_VERSION}`, flutaroMainStyle);
		console.log('%cTourenoptimierung', flutaroMessageStyle);
		console.log('%c', 'font-size:22px; line-height:12px;');
		console.log('%cBug gefunden? support@flutaro.de', 'color: #40c1ac; font-size: 14px');
		console.log('%c', 'font-size:30px; line-height:1.0em;');
		console.log('%cACHTUNG!', 'color: #ff6666; font-size: 14px');
		console.log('Dieses Flutaro-Feature ist nur für IT-Experten... 😄');
	}

	ngAfterViewInit() {
		this.printLog();
	}

	addFlutaroIcons() {
		this.matIconRegistry.addSvgIcon(
			`driver_swap`,
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/driverSwap.svg'),
		);
	}
}

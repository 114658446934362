import { Pipe, PipeTransform } from '@angular/core';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import {
	isAuthorizedAphroditeUser,
	isAuthorizedReadonlyAphroditeUser,
	isCompanyAdmin,
	isTenantAdmin,
	isUserAdmin,
} from '@flutaro/package/lib/functions/auth/AppAuthFunctions';

@Pipe({
	name: 'isCompanyAdmin',
})
export class UserIsCompanyAdminAccessPipe implements PipeTransform {
	transform(user: FbStoreUserProfile): boolean {
		return isCompanyAdmin(user);
	}
}

@Pipe({
	name: 'isUserAdmin',
})
export class UserIsUserAdminAccessPipe implements PipeTransform {
	transform(user: FbStoreUserProfile): boolean {
		return isUserAdmin(user) || isCompanyAdmin(user);
	}
}

@Pipe({
	name: 'isTenantAdmin',
})
export class UserIsTenantAdminAccessPipe implements PipeTransform {
	transform(user: FbStoreUserProfile): boolean {
		return isTenantAdmin(user) || isCompanyAdmin(user);
	}
}

@Pipe({
	name: 'hasAphroditeAccess',
})
export class UserHasFullAphroditeAccessPipe implements PipeTransform {
	transform(user: FbStoreUserProfile): boolean {
		return isAuthorizedAphroditeUser(user);
	}
}

@Pipe({
	name: 'isReadonly',
})
export class UserHasReadonlyAphroditeAccessPipe implements PipeTransform {
	transform(user: FbStoreUserProfile): boolean {
		return isAuthorizedReadonlyAphroditeUser(user);
	}
}

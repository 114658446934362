import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FlutaroChatChannel } from '../chat/chat';
import { appSortBy } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { AppSorting } from '@flutaro/package/lib/model/AppClasses';
import { query } from 'firebase/firestore';
import { ChatMessage } from '@flutaro/package/lib/model/ChatClasses';
import { collectionSnapshots, getDocs, limit, orderBy, startAt } from '@angular/fire/firestore';

import { QueryDocumentSnapshot } from 'rxfire/firestore/interfaces';
import { appParseMessage } from '@flutaro/package/lib/functions/AppChatFunctions';
import { QuerySnapshot } from '@firebase/firestore';

@Injectable({ providedIn: 'root' })
export class AppChannelMessagesProvider {
	public $isLoadingMessages = new BehaviorSubject<boolean>(false);
	$channelMessages = new BehaviorSubject<ChatMessage[]>([]);
	messagesUnsubscribe: Subscription;
	paginatedMessages: ChatMessage[] = [];
	lastMessageDoc: QueryDocumentSnapshot<ChatMessage> | null = null;

	constructor() {}

	async initAndSubscribeChannelMessages(channel: FlutaroChatChannel) {
		const first = query<ChatMessage>(channel.messagesCollection, orderBy('sendTime', AppSorting.DESCENDING), limit(25));
		this.messagesUnsubscribe = collectionSnapshots(first).subscribe(async (messagesSnapshots) => {
			console.log(
				'initAndSubscribeChannelMessages, received messages update on latest messages. Recevied messages: ',
				messagesSnapshots.length,
			);
			this.setLastMessageDoc(messagesSnapshots[messagesSnapshots.length - 1]);
			this.setChannelMessagesOnCollectionChange(this.parseFirestoreQueryMessages(messagesSnapshots));
		});
	}

	setLastMessageDoc(lastMessageDoc: QueryDocumentSnapshot<ChatMessage>) {
		this.lastMessageDoc = lastMessageDoc;
	}

	setChannelMessagesOnCollectionChange(updatedMessages: ChatMessage[]) {
		let newChannelMessages = this.paginatedMessages.length
			? this.paginatedMessages.concat(updatedMessages)
			: updatedMessages;
		const missingMessages = this.$channelMessages
			.getValue()
			.filter(
				(currentChannelMessage) =>
					!newChannelMessages.find(
						(newChannelMessage) => newChannelMessage.sendTime.getTime() === currentChannelMessage.sendTime.getTime(),
					),
			);
		newChannelMessages = newChannelMessages.concat(missingMessages);
		this.setChannelMessages(newChannelMessages);
	}

	parseFirestoreQueryMessages(
		messagesSnapshots: QueryDocumentSnapshot<ChatMessage>[] | QuerySnapshot<ChatMessage>,
	): ChatMessage[] {
		let messages = [];
		messagesSnapshots.forEach((messageDoc) => messages.push(appParseMessage(messageDoc.data())));
		return messages;
	}

	setChannelMessages(totalMessages: ChatMessage[]) {
		this.$channelMessages.next(appSortBy(totalMessages, 'sendTime', AppSorting.ASCENDING));
	}

	async loadNextMessages(channel: FlutaroChatChannel) {
		let nextMessagesSnap = await getDocs(
			query<ChatMessage>(
				channel.messagesCollection,
				orderBy('sendTime', AppSorting.DESCENDING),
				startAt(this.lastMessageDoc),
				limit(25),
			),
		);
		this.setLastMessageDoc(nextMessagesSnap.docs[nextMessagesSnap.docs.length - 1]);
		this.addPaginatedMessages(this.parseFirestoreQueryMessages(nextMessagesSnap));
	}

	addPaginatedMessages(nextPaginatedMessages: ChatMessage[]) {
		this.paginatedMessages = this.paginatedMessages.concat(nextPaginatedMessages);
		let allMessages = this.$channelMessages.getValue().concat(nextPaginatedMessages);
		this.setChannelMessages(allMessages);
		console.log(
			`addPaginatedMessages, new size of $channelMessages: ${allMessages.length} (${this.paginatedMessages.length} paginatedMessages)`,
		);
	}

	reset() {
		console.log(`AppChannelMessagesProvider, reset`);
		this.$channelMessages.next([]);
		this.paginatedMessages = [];
		this.messagesUnsubscribe.unsubscribe();
		this.lastMessageDoc = null;
	}
}

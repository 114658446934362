import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	AppScheduleBasics,
	DriverSchedule,
	SCHEDULE_TYPES,
	TrailerSchedule,
} from '@flutaro/package/lib/model/AppScheduleClasses';
import { DriverAppointmentSchedule } from '@flutaro/package/lib/model/DriverAppointmentSchedule';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
	selector: 'app-schedule',
	templateUrl: './schedule.component.html',
	styleUrls: ['./schedule.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements OnChanges {
	@Input() type: SCHEDULE_TYPES;
	@Input() schedules: TrailerSchedule[] | DriverSchedule[] | DriverAppointmentSchedule[];
	@Input() vehicle: Vehicle;
	@Input() vehicles: Vehicle[];
	@Input() drivers: Driver[];
	@Output() vehicleChange: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
	$isEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	scheduleInEdit: TrailerSchedule | DriverSchedule;
	$isNew: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isDisabledCreate: boolean = false;
	TYPES = SCHEDULE_TYPES;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['schedules'] && this.schedules?.length) {
			this.isDisabledCreate = !!this.schedules.find((schedule) => !schedule.endDate);
		}
	}

	createSchedule() {
		switch (this.type) {
			case SCHEDULE_TYPES.TRAILER_SCHEDULE:
				this.scheduleInEdit = new TrailerSchedule();
				break;
			case SCHEDULE_TYPES.DRIVER_SCHEDULE:
				this.scheduleInEdit = new DriverSchedule();
				break;
			case SCHEDULE_TYPES.DRIVER_APPOINTMENT:
				this.scheduleInEdit = new DriverAppointmentSchedule();
				break;
		}
		console.debug(`createSchedule, for type ${this.type}`);
		this.$isNew.next(true);
		this.$isEdit.next(true);
	}

	editSchedule(schedule: AppScheduleBasics) {
		console.debug(`editSchedule`);
		this.scheduleInEdit = cloneDeep(schedule);
		this.$isNew.next(false);
		this.$isEdit.next(true);
	}

	deleteSchedule($event: MouseEvent, deleteSchedule: AppScheduleBasics) {
		$event.stopPropagation();
		console.debug(`deleteDriverSchedule, called for schedule ${deleteSchedule.id}`);
		const scheduleKey = this.type === SCHEDULE_TYPES.DRIVER_SCHEDULE ? 'driverSchedules' : 'trailerSchedules';
		this.vehicle[scheduleKey] = this.vehicle[scheduleKey].filter(
			(schedule) => schedule.id !== deleteSchedule.id,
		) as any;
		if (this.vehicle.driver && this.vehicle.driver === deleteSchedule.entityId) {
			console.info(`deleteSchedule, deleting vehicle.driver as well to schedule delete`);
			delete this.vehicle.driver;
			delete this.vehicle.driverName;
		}
		this.vehicleChange.emit(this.vehicle);
	}

	emitScheduleChange() {
		// TODO: get rid of "any" type-casting
		if (this.type === SCHEDULE_TYPES.TRAILER_SCHEDULE || this.type === SCHEDULE_TYPES.DRIVER_SCHEDULE) {
			const scheduleKey = this.type === SCHEDULE_TYPES.DRIVER_SCHEDULE ? 'driverSchedules' : 'trailerSchedules';
			this.vehicle[scheduleKey] = this.vehicle[scheduleKey].filter(
				(vehicleSchedule) => vehicleSchedule.id !== this.scheduleInEdit.id,
			) as any;
			this.vehicle[scheduleKey].push(this.scheduleInEdit as any);
			console.debug(
				`emitScheduleChange, emitting vehicleChange vor vehicle with ${this.vehicle.driverSchedules?.length} driverSchedules and ${this.vehicle.trailerSchedules?.length} trailerSchedules`,
			);
			this.vehicleChange.emit(this.vehicle);
		}
		this.$isEdit.next(false);
	}

	abortScheduleChange() {
		console.debug(`abortScheduleChange called`);
		this.$isEdit.next(false);
		this.$isNew.next(false);
	}
}

<app-map-planning-toolbar
	(jobPairingFilter)="setJobPairingFilter($event)"
	(showOverview)="showOverview()"
	*ngIf="showToolbar"
>
</app-map-planning-toolbar>
<div class="mapPlanningView" fxFlexFill fxLayout="row">
	<mat-list>
		<mat-list-item *ngFor="let appUser of $appUsers | async">
			<span class="timetableJobDriverIcon trailerIcon"></span>
			<p class="timetableVehicleDriverAppUser">
				{{ appUser.toString() }}
			</p>
		</mat-list-item>
	</mat-list>
	<app-map-planning
		(latestPositionsChange)="initAppUser($event)"
		(loadJobsChange)="loadMissingJobs($event)"
		[drivers]="drivers"
		[jobs]="jobs"
		[reloadedJobs]="reloadedJobs"
		[showOverview]="$showOverview | async"
		[user]="userProfile"
		[vehicles]="vehicles"
		[jobPairingFilter]="$jobPairingFilter | async"
		fxFlexFill
	>
	</app-map-planning>
</div>

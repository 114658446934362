import { NgModule } from '@angular/core';
import { FlutaroWebsocketService } from './app.push.websocket';
import { CommonModule } from '@angular/common';
import { AppPushProvider } from './app.push.provider';

/**
 * Angular 2 Push-Module
 */
@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [FlutaroWebsocketService, AppPushProvider],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class WebsocketModule {}

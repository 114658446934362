import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { FlutaroSidenavProvider } from './sidenav.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { CompanyService } from '../company/company.service';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { ExternalJobsProvider } from '../interfaces/ExternalJob/externalJobs.provider';
import { InterfaceService } from '../data/data-services/data.interface.service';
import { TimetableComponentProvider } from '../timetable/data/timetable.component.service';

@Component({
	selector: 'app-sidenav-flutaro',
	templateUrl: './sidenav.flutaro.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavFlutaroComponent implements OnInit {
	@ViewChild('flutaroSidenav', { static: true }) public flutaroSidenav: MatSidenav;

	constructor(
		public sidenavProvider: FlutaroSidenavProvider,
		public authFb: WebAuthService,
		public companyService: CompanyService,
		public userSettingsProvider: UserSettingsProvider,
		public externalJobsProvider: ExternalJobsProvider,
		public interfaceProvider: InterfaceService,
		public timetableProvider: TimetableComponentProvider,
	) {}

	ngOnInit() {
		this.sidenavProvider.flutaroSidenavRef = this.flutaroSidenav;
	}
}

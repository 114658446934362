import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { JobModule } from '../job/job.module';
import { RelationModule } from '../relation/relations.module';
import { DashboardDispatcherComponent } from './dashboard.dispatcher.component';
import { DashboardNewsProvider } from './widget/dashboard.news.provider';
import { DashboardNewsWidget } from './widget/dashboard.widget.news';
import { WidgetNewsDialogComponent } from './widget/widget.news.dialog.component';
import { ComponentsModule } from '../app/components/components.module';

@NgModule({
	declarations: [DashboardDispatcherComponent, DashboardNewsWidget, WidgetNewsDialogComponent],
	exports: [DashboardDispatcherComponent, DashboardNewsWidget],
	imports: [
		CommonModule,
		FormsModule,
		FlexLayoutModule,
		FlutaroCustomMaterialModule,
		JobModule,
		RelationModule,
		QuillModule,
		ComponentsModule,
	],
	providers: [DashboardNewsProvider],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class DashboardModule {}

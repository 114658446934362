import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';

import { FlutaroSidenavProvider } from '../sidenav/sidenav.provider';
import { FlutaroTimetableFilterService } from '../timetable/data/timetable.data.provider';
import { SettingsUserComponent } from '../settings/settings.user.component';
import { MenuComponentProvider } from './menu.component.provider';
import { FlutaroSearchService } from './toolbar/search.service';
import { WebAuthService } from '../app/auth/web-auth.service';
import { ChatDataProvider } from '../chat/chat.data.provider';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { APP_MENU } from './MenuClasses';
import { Tenant } from '@flutaro/package/lib/model/IElement';
import { replaceCyrillicWithLatinLettersInString } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { AppDialogBasicData } from 'src/app/dialog/AppDialog';
import { AppBasicComponent } from '../app/components/app.components.basic.component';
import { MatTabGroup, MatTabHeader } from '@angular/material/tabs';

@Component({
	selector: 'app-header',
	templateUrl: './menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends AppBasicComponent {
	MENU = APP_MENU;
	showTimetableToolbar: boolean = false;
	@ViewChild('flutaroSidenav') public flutaroSidenav: MatSidenav;
	@ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

	constructor(
		public searchService: FlutaroSearchService,
		public timetableProvider: FlutaroTimetableFilterService,
		public menuProvider: MenuComponentProvider,
		public sidenavProvider: FlutaroSidenavProvider,
		private cd: ChangeDetectorRef,
		private dialog: MatDialog,
		public authFb: WebAuthService,
		public chatProvider: ChatDataProvider,
		public userSettingsProvider: UserSettingsProvider,
	) {
		super();
	}

	changeTab() {
		// TODO: this could be refactored as component input here with ngOnChange Behavior on newTabs-init
		(this.tabGroup?._tabHeader as MatTabHeader).updatePagination();
		this.menuProvider.changeTab(this.menuProvider.currentTabIndex);
	}

	showHideSearch() {
		this.menuProvider.showHideSearch();
		this.cd.detectChanges();
	}

	/**
	 * Opens or closes the SideNav-Type based on if its the current open SideNav
	 * @param sideNavType
	 */
	showHideSidenav(sideNavType: string): void {
		this.sidenavProvider.$openSideNav.getValue() === sideNavType
			? this.sidenavProvider.sidenavPop()
			: this.sidenavProvider.openSideNavType(sideNavType);
	}

	clearSearch() {
		this.searchService.query.filter = '';
		this.menuProvider.applyFilterInTimetable();
	}

	searchInputKeydown(event) {
		if (event.keyCode == 13) {
			this.menuProvider.applyFilterInTimetable();
		}
	}

	public clickMissionControlButton() {
		this.showTimetableToolbar = !this.showTimetableToolbar;
		this.menuProvider.setTimetableToolbarShow(this.showTimetableToolbar);
	}

	openUserSettings() {
		let dialogConfig = new MatDialogConfig();
		dialogConfig.data = new AppDialogBasicData(this.userSettings, this.userProfile, this.companySettings);
		this.dialog.open(SettingsUserComponent, dialogConfig);
	}

	saveFilter() {
		this.userSettingsProvider.updateUserSettings(this.userSettings);
	}

	changeAppTenant(tenant: Tenant) {
		console.log(`changeAppTenant, changing to tenant ${tenant?.name}`);
		this.authFb.changeUsersTenant(tenant);
	}

	setQueryFilter(search: string) {
		let transformedSearch = search;
		if (this.companySettings.translateCyrillicIntoLatinLetters)
			transformedSearch = replaceCyrillicWithLatinLettersInString(transformedSearch);
		this.searchService.setQueryFilter(transformedSearch);
	}
}

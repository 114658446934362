import {
	createBreakEventAfterEmptyDrive,
	createEmptyDriveEventForJob,
	createLoadedDriveEventForJob,
	isValidEventsForJob,
} from './EventDataFunctions';
import { environment } from '../../environments/environment';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { AppEventCounter, FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { appSortBy } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import {
	filterForActivatedVehiclesOfCompanyNow,
	getVehiclesCurrentDriver,
} from '@flutaro/package/lib/functions/VehicleFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { sortJobsByFirstPickupDateAsc } from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';

export const IS_EVENTS_DEBUG: boolean = false;
//export const EVENTS_DEBUG_JOB_ID: string = '609d54e006f39514403fd48a';
export const EVENTS_DEBUG_JOB_ID: string = '609d53d506f39514403fd486';

/**
 * Creates events from firstJob until lastJob for each Driver regarding drivers Workweek Start/End Settings
 * @param jobs
 * @param vehicles
 * @param drivers
 * @param companySettings
 */
export function calculateEventsFromJobs(
	jobs: JobWrapper[],
	vehicles: Vehicle[],
	drivers: Driver[],
	companySettings: CompanySettings,
): Map<string, FlutaroEvent[]> {
	// Step 0: Pre-Filter
	const eventJobs = sortJobsByFirstPickupDateAsc(
		jobs.filter(
			(job) =>
				job.costCalculation &&
				!!(job.costCalculation.emptyDistanceTravelTime || job.costCalculation.loadedDistanceTravelTime),
		),
	);
	const eventVehicles = filterForActivatedVehiclesOfCompanyNow(vehicles, companySettings);
	// Step 2: Create Job related Events, starting from last absenceEvent (emptyKm-Address/Absence at driver home) before job start
	const eventMap = new Map<string, FlutaroEvent[]>();
	eventVehicles.forEach((vehicle) => {
		const vehiclesJobs = eventJobs.filter((job) => job.vehicleId === vehicle.backendId);
		if (!vehiclesJobs.length) {
			eventMap.set(vehicle.backendId, []);
			return;
		}
		let vehiclesEvents: FlutaroEvent[] = [];
		const counter = new AppEventCounter();
		const driver = getVehiclesCurrentDriver(vehicle, drivers);

		vehiclesJobs.forEach((job, jobIndex) => {
			if (IS_EVENTS_DEBUG && EVENTS_DEBUG_JOB_ID && job.backendId === EVENTS_DEBUG_JOB_ID && environment.debug) {
				console.debug(`calculateEventsFromJobs, test case detected`);
			}

			let jobsEvents = createEmptyDriveEventForJob(job, vehiclesEvents, counter, driver);
			const emptyDriveBreakEvent = createBreakEventAfterEmptyDrive(job, jobsEvents, counter);
			if (emptyDriveBreakEvent && emptyDriveBreakEvent.length) jobsEvents = jobsEvents.concat(emptyDriveBreakEvent);
			const loadedDriveEvents = createLoadedDriveEventForJob(job, jobsEvents, counter);
			jobsEvents = jobsEvents.concat(loadedDriveEvents);

			if (!isValidEventsForJob(job, jobsEvents)) {
				//console.debug(`calculateEventsFromJobs, invalid Events for driver ${driver.firstName} and job ${job.job.identifier}/${job.backendId} - Events:`,);
				//console.debug(driverEvents);
			} else {
				vehiclesEvents = vehiclesEvents.concat(jobsEvents);
			}

			if (jobIndex === vehiclesJobs.length - 1) {
				vehiclesEvents = appSortBy(vehiclesEvents, 'endTime');
				eventMap.set(vehicle.backendId, vehiclesEvents);
			}
		});
	});
	if (IS_EVENTS_DEBUG && environment.debug) {
		console.debug(`IS_EVENTS_DEBUG activated, showing debug information for Job ${EVENTS_DEBUG_JOB_ID}`);
		let jobsEvents;
		eventMap.forEach((entry) => {
			const entryJobsEvents = entry.filter((event) => event.jobId === EVENTS_DEBUG_JOB_ID);
			if (entryJobsEvents.length) jobsEvents = entryJobsEvents;
		});
		console.debug(jobsEvents);
	}
	return eventMap;
}

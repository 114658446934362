import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { JobStatus } from '@flutaro/package/lib/model/Job';

@Component({
	selector: 'flutaro-job-status-icon',
	templateUrl: './job-status-icon.component.html',
	styleUrls: ['./job-status-icon.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobStatusIconComponent implements OnInit {
	@Input() status: JobStatus | undefined;
	constructor() {}

	ngOnInit(): void {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { FlutaroSidenavProvider } from './sidenav.provider';
import { ChatDataProvider } from '../chat/chat.data.provider';
import { NewMessageChange } from '@flutaro/package/lib/model/ChatClasses';
import { FlutaroApplication } from '@flutaro/package/lib/model/FlutaroConstants';
import { SidenavBasicComponent } from './sidenav.basic.component';

@Component({
	selector: 'app-chat-sidenav',
	templateUrl: './sidenav.chat.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatSidenavComponent extends SidenavBasicComponent {
	constructor(
		public sidenavProvider: FlutaroSidenavProvider,
		public chatProvider: ChatDataProvider,
		protected cd: ChangeDetectorRef,
	) {
		super(cd);
	}

	openJob(jobId: string) {
		this.sidenavProvider.showJobNavByJobId(jobId);
	}

	sendChatMessage(newMessageChange: NewMessageChange) {
		this.chatProvider.sendMessage(
			newMessageChange.message,
			newMessageChange.channelId,
			this.userProfile,
			FlutaroApplication.APHRODITE,
		);
	}
}

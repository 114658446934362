<span
	[class.busIcon]="vehicleType === 'Bus'"
	[class.megaTrailerIcon]="vehicleType === 'MegaTruck' || vehicleType === 'Jumbo'"
	[class.trailerIcon]="
		(vehicleType === 'Truck' ||
			vehicleType === 'Curtainsider' ||
			vehicleType === 'RoadTrain' ||
			vehicleType === 'Lorry') &&
		!customJobDisplay
	"
	[class.transporterIcon]="vehicleType === 'Transporter'"
	[class.timetableJobVehicleIcon]="type === 'job'"
	[class.timetableJobDriverIcon]="type === 'vehicle'"
>
</span>

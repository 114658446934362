<div *ngIf="!userProfile.hasDisableAllCreateDeleteRole && !userProfile.hasReadOnlyRole" fxLayoutAlign="end center">
	<app-relation-add
		[companySettings]="companySettings"
		[userProfile]="userProfile"
		[userSettings]="userSettings"
		[vehicles]="vehicles"
	>
	</app-relation-add>
</div>

<div class="table-container mat-elevation-z8">
	<div *ngIf="!data?.length" class="addNewObjectBackground" fxLayout="column" fxLayoutAlign="center center">
		<div class="addNewRelationImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'RELATIONS' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'RELATION_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_RELATION' | translate }}
			</p>
		</div>
	</div>

	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_RELATIONS' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">grain</i>
				Name
			</th>
			<td *matCellDef="let relation" mat-cell>{{ relation.name }}</td>
		</ng-container>

		<ng-container matColumnDef="color">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">color_lens</i>
				{{ 'COLOR' | translate }}
			</th>
			<td *matCellDef="let relation" [style.color]="relation.color" mat-cell>{{ relation.color }}</td>
		</ng-container>

		<ng-container matColumnDef="zips">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">home</i>
				{{ 'ZIPS' | translate }}
			</th>
			<td *matCellDef="let relation" mat-cell>
				{{ relation.zips.length ? relation.zips.toString() : '' }}
				{{ relation.pickUpZips.length ? relation.pickUpZips.toString() : '' }}
				{{ relation.pickUpZips.length && relation.deliveryZips.length ? ', ' : '' }}
				{{ relation.deliveryZips.length ? relation.deliveryZips.toString() : '' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let relation"
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(relation, relation.name)"
					[disabled]="userProfile.hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let element" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(element)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons></mat-paginator>
</div>

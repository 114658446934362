/**
 * Sets styles to display current Drivers Workweek. Takes account of regular and alternating Workweek.
 * @param driverEntry
 */
import { TimeTable } from '../TimeTable';
import { addWeeks, getISOWeek, isAfter, isBefore, isSunday, setHours, setISODay, setMinutes, subWeeks } from 'date-fns';
import { Appointment, Driver, DriverBlock, ProfileDate } from '@flutaro/package/lib/model/Driver';
import { TimetableFilteredEntry } from './TimetableFilterClasses';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { areDateIntervalsOverlapping, isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';
import {
	TimetableAppointmentEntry,
	TimetableBlockEntry,
	TimetableDriverEntry,
	TimetableWorkweekEntry,
} from '../TimetableClasses';
import { setBlockEntryColor } from './TimetableStyleFunctions';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { updateDriverEntriesIsWeekEndOutside } from '../TimetableFunctions';
import {
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';

export function setTimetableDriverEntryAttributes(
	entry: TimetableFilteredEntry,
	driver: Driver,
	timetable: TimeTable,
	companySettings: CompanySettings,
	relations: Relation[],
) {
	if (companySettings.isDriverDeactivated || !driver) return;
	entry.addDriver(driver);
	setDriversBlockTimetableEntry(entry, timetable, relations);
	updateDriverEntryForTimetable(entry.driverEntry, timetable);
}

export function updateDriverEntryForTimetable(driverEntry: TimetableDriverEntry, timetable: TimeTable) {
	setDriverEntriesWorkWeekSettings(driverEntry, timetable);
	updateDriverEntriesIsWeekEndOutside(driverEntry, timetable.startDate);
	setDriverAppointmentsEntry(driverEntry, timetable);
}

export function setDriverEntriesWorkWeekSettings(driverEntry: TimetableDriverEntry, timetable: TimeTable) {
	let workweekStart: ProfileDate;
	let workweekEnd: ProfileDate;
	const isEventWeek = getISOWeek(timetable.startDate) % 2 === 0;
	// Set workweekStart and workweekEnd for this view/week
	if (driverEntry.driver.regularWorkweekDriver) {
		workweekStart = driverEntry.driver.regularWorkweek.regularWorkweekStart;
		workweekEnd = driverEntry.driver.regularWorkweek.regularWorkweekEnd;
	} else {
		// Even-Week
		if (isEventWeek) {
			workweekStart = driverEntry.driver.alternatingWorkWeek.evenWorkweekStart;
			workweekEnd = driverEntry.driver.alternatingWorkWeek.evenWorkweekEnd;
		} else {
			// Odd-Week
			workweekStart = driverEntry.driver.alternatingWorkWeek.oddWorkweekStart;
			workweekEnd = driverEntry.driver.alternatingWorkWeek.oddWorkweekEnd;
		}
	}
	// ISO-Weekday is 1-7 (Mo-Sun), we use 0 - 6 for workweek Settings currently. This converts Monday day 0 to 1 etc
	let workweekStartDate = setMinutes(
		setHours(setISODay(timetable.startDate, workweekStart.weekDay + 1), workweekStart.hour),
		workweekStart.minute,
	);
	let workweekEndDate = setMinutes(
		setHours(setISODay(timetable.startDate, workweekEnd.weekDay + 1), workweekEnd.hour),
		workweekEnd.minute,
	);

	if (!isBefore(workweekStartDate, workweekEndDate)) {
		workweekStartDate = subWeeks(workweekStartDate, 1);
	}

	if (workweekStartDate.getTime() === workweekEndDate.getTime()) {
		return;
	}

	if (isBefore(timetable.startDate, workweekStartDate)) {
		const workweekStart = new TimetableWorkweekEntry();
		workweekStart.width = timetable.getUnitForTimetable(
			timetable.calculateObjectsWidth(timetable.startDate, workweekStartDate),
		);
		if (workweekStart.width !== '0%' && workweekStart.width !== '0px') driverEntry.workweekStart = workweekStart;
	}

	if (isAfter(workweekEndDate, timetable.endDate)) return;
	const workweekEndEntry = new TimetableWorkweekEntry();
	workweekEndEntry.marginLeft = timetable.getUnitForTimetable(
		timetable.getMarginByDateForObjectInTimetable(workweekEndDate),
	);
	let nextWorkweekStart = addWeeks(workweekStartDate, 1);
	if (isAfter(nextWorkweekStart, timetable.endDate)) nextWorkweekStart = timetable.endDate;

	workweekEndEntry.width = timetable.getUnitForTimetable(
		timetable.calculateObjectsWidth(workweekEndDate, nextWorkweekStart),
	);
	if (workweekEndEntry.width !== '0%' && workweekEndEntry.width !== '0px') driverEntry.workweekEnd = workweekEndEntry;
}

export function setDriverAppointmentsEntry(driverEntry: TimetableDriverEntry, timetable: TimeTable) {
	if (!driverEntry.driver.appointments || driverEntry.driver.appointments.length === 0) {
		return;
	}
	driverEntry.appointments = [];
	driverEntry.driver.appointments.forEach((appointment) => {
		if (!appointmentIsInCurrentDateRange(appointment, timetable)) return;
		// No Sunday Table early return for sunday only appoimtmemts
		if (!timetable.isSundayTable && isSunday(appointment.startDate) && isSunday(appointment.endDate)) return;
		let appointmentEntry: TimetableAppointmentEntry = new TimetableAppointmentEntry();
		appointmentEntry.marginLeft = timetable.getUnitForTimetable(
			timetable.getMarginByDateForObjectInTimetable(appointment.startDate),
			null,
		);
		appointmentEntry.width = timetable.getUnitForTimetable(
			timetable.calculateObjectsWidth(appointment.startDate, appointment.endDate),
		);
		appointmentEntry.appointmentNote = appointment.note;
		appointmentEntry.appointmentLocation = appointment.location;
		if (appointmentEntry.width !== '0px' && appointmentEntry.width !== '0%') {
			driverEntry.appointments.push(appointmentEntry);
		}
	});
}

/**
 * Checks if an Appointment should be considered to the current Timetable View
 * @param appointment
 * @param timetable
 * @returns {boolean}
 */
export function appointmentIsInCurrentDateRange(appointment: Appointment, timetable: TimeTable): boolean {
	if (
		appointment.appointmentType === 'Individueller Arbeitsbeginn' ||
		appointment.appointmentType === 'Individuelles Arbeitsende'
	) {
		return false;
	}
	return areDateIntervalsOverlapping(
		appointment.startDate,
		appointment.endDate,
		timetable.startDate,
		timetable.endDate,
	);
}

/**
 * Checks if a Driver is blocked and if the block Dates are within the current timetable Dates.
 * Creates a JobEntry with jobWrapper = null for the Block with stylings set correctly.
 * The Timetable Component checks if the JobEntry is a Block by nullchecking the JobWrapper (which currently is only the case for Blocks)
 * @param mapEntry
 * @param timetable
 * @param relations
 */
export function setDriversBlockTimetableEntry(
	mapEntry: TimetableFilteredEntry,
	timetable: TimeTable,
	relations: Relation[],
) {
	let driver = mapEntry.driverEntry.driver;
	if (!driver.block || !driver.block.blocked || !isValidDate(driver.block.blockStartDate)) return;
	if (
		!areDateIntervalsOverlapping(
			driver.block.blockStartDate,
			driver.block.blockEndDate,
			timetable.startDate,
			timetable.endDate,
		)
	)
		return;
	if (!timetable.isSundayTable && isSunday(driver.block.blockEndDate) && isSunday(driver.block.blockStartDate)) return;

	let blockEntry: TimetableBlockEntry = new TimetableBlockEntry();
	blockEntry.marginLeft = timetable.getUnitForTimetable(
		timetable.getMarginByDateForObjectInTimetable(driver.block.blockStartDate),
	);
	blockEntry.width = timetable.getUnitForTimetable(
		timetable.calculateObjectsWidth(driver.block.blockStartDate, driver.block.blockEndDate),
	);
	blockEntry.block = driver.block;
	blockEntry.isOverlapping = checkIfBlockIsOverlapping(mapEntry);
	if (blockEntry.isOverlapping) ++mapEntry.vehicleJobStatus.overlapping;
	setBlockEntryColor(blockEntry, relations);
	mapEntry.blockEntry = blockEntry;
}

export function checkIfBlockIsOverlapping(mapEntry: TimetableFilteredEntry): boolean {
	if (!mapEntry.blockEntry || !mapEntry.jobsEntry.length) {
		return false;
	}
	let block: DriverBlock = mapEntry.blockEntry.block;
	let isOverlapping: boolean = false;
	mapEntry.jobsEntry.forEach((jobEntry) => {
		let jobWrapper = jobEntry.jobWrapper;
		if (
			areDateIntervalsOverlapping(
				getJobsFirstPickupDate(jobWrapper),
				getJobsLastDeliveryDate(jobWrapper),
				block.blockStartDate,
				block.blockEndDate,
			)
		) {
			isOverlapping = true;
		}
	});
	return isOverlapping;
}

import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../../data/FlutaroService';
import { DashboardNews } from '@flutaro/package/lib/model/DashboardNews';
import { environment } from '../../../environments/environment';
import { parseNews } from '@flutaro/package/lib/functions/NewsDataFunctions';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Injectable()
export class DashboardNewsProvider extends FlutaroDataService<DashboardNews> {
	protected initProvider() {
		this.url = environment.routingApiURL + '/data/news/dashboard';
		this.collectionName = FlutaroCollection.NEWS;
	}

	// (currently only new views/apis use DELETE, others use POST with delete path)
	protected parseElement(newElement: DashboardNews) {
		return parseNews(newElement);
	}
}

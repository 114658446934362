import { MatDialog } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClientDialogComponent } from './client.dialog.component';
import { TableAddComponent } from '../table/table.add.component';
import { ClientDialogData } from 'src/app/dialog/AppDialog';
import { Client } from '@flutaro/package/lib/model/Client';

@Component({
	selector: 'app-client-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAddComponent extends TableAddComponent {
	dialogComponent = ClientDialogComponent;

	constructor(public dialog: MatDialog) {
		super(dialog);
	}

	createDialogData(): ClientDialogData {
		return new ClientDialogData(this.userSettings, this.userProfile, this.companySettings, new Client());
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { APP_ROUTING_PROFILE } from '@flutaro/package/lib/model/AppRoutingClasses';

@Component({
	selector: 'app-routing-profile',
	templateUrl: './routing-profile.component.html',
	styleUrls: ['./routing-profile.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutingProfileComponent {
	@Input() routingProfile: APP_ROUTING_PROFILE;
	@Output() routingProfileChange = new EventEmitter<APP_ROUTING_PROFILE>();
	routingProfiles: APP_ROUTING_PROFILE[] = Object.values(APP_ROUTING_PROFILE);

	emitRoutingProfileChange() {
		console.log(`RoutingProfileComponent, emitting new routingProfile ${this.routingProfile}`);
		this.routingProfileChange.emit(this.routingProfile);
	}
}

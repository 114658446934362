<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground confirmDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">schedule</i>
		<span>{{ 'CONFIRM_DIALOG_JOB_ADJUSTMENT' | translate }}</span>
	</h1>
</mat-toolbar>
<mat-dialog-content>
	<p class="confirmDialogMessage">{{ confirmMessage }}</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" class="dialogFooterMD2" fxLayoutAlign="end none">
	<button mat-button class="buttonCancel mat-raised" [mat-dialog-close]="false">
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button mat-button class="buttonDone mat-primary_flutaro" [mat-dialog-close]="true">
		<mat-icon>
			<i class="material-icons material-icons-white">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

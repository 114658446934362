import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { JobPairing } from '@flutaro/package/lib/model/Positiondata';

@Component({
	selector: 'app-position-pairing-select',
	templateUrl: './position-pairing-select.component.html',
	styleUrls: ['./position-pairing-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionPairingSelectComponent {
	@Output() pairingSelectChange: EventEmitter<JobPairing | null> = new EventEmitter<JobPairing | null>();
	pairing: JobPairing | null = null;

	constructor() {}

	selectPairing(pairing: JobPairing | undefined) {
		this.pairing = pairing;
		this.pairingSelectChange.emit(this.pairing);
	}
}

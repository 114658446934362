import { HttpParams } from '@angular/common/http';

export function transformURLSearchParamIntoHttpParams(obj: URLSearchParams): HttpParams {
	let httpParams = new HttpParams();
	obj.forEach((keyValue, key) => {
		if (!keyValue) return;
		httpParams = httpParams.append(key, keyValue);
	});
	return httpParams;
}

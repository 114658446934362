<div
	class="flutaro-sidenav"
	[@sidenavAnimation]
	[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px)' : 'calc(100vh - 64px)'"
>
	<mat-toolbar class="flutaroSidenavHeader">
		<button
			mat-button
			class="buttonSidenavMain closeButtonSidenavs"
			matTooltip="{{ 'CLOSE_TT' | translate }}"
			(click)="closeSidenav()"
			[matTooltipPosition]="'below'"
		>
			<i class="material-icons sidenavButtonIcon"> close </i>
		</button>
		<h1 class="mat-toolbar-tools sideNavheadlineDriverLicensePlate">
			<div class="sidenavToolbarHeadlineUnderline">
				<i class="material-icons sidenavToolbarHeadlineIcon">notifications_none</i>
				{{ referenceDateFilteredJobs.length }}
				<span *ngIf="referenceDateFilteredJobs.length === 1">{{ 'NEW_JOB_NEW' | translate }}</span>
				<span *ngIf="referenceDateFilteredJobs.length > 1 || referenceDateFilteredJobs.length === 0">{{
					'NEW_JOBS_NEW' | translate
				}}</span>
			</div>
		</h1>
	</mat-toolbar>
	<div
		*ngIf="$isDrag | async"
		[dragula]="'bag-jobs'"
		class="sidenavFixedHeader layout-padding dragAndDropBackground"
		id="unplannedDropZone"
		[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px - 54px)' : 'calc(100vh - 64px - 54px)'"
	>
		<div class="sidenavNewJobsDragIcon newJobsDragAndDropIcon"></div>
		<p>{{ 'DRAG_JOB_HERE' | translate }}</p>
	</div>
	<div
		*ngIf="($isDrag | async) === false"
		class="sidenavFixedHeader layout-padding flutaro-sidenav-inner"
		[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px - 54px)' : 'calc(100vh - 64px - 54px)'"
	>
		<div
			class="addNewObjectBackgroundnewJobsSidenav"
			*ngIf="referenceDateFilteredJobs.length === 0"
			fxLayout="column"
			fxLayoutAlign="center center"
		>
			<div class="addNewJobImage"></div>
			<div class="addNewObjectDiv">
				<h4>
					{{ 'JOBS' | translate }}
				</h4>
				<p class="fontWeight500">{{ 'NO_NEW_JOBS_AVAILABLE' | translate }}</p>
			</div>
		</div>
		<div fxLayout="column" *ngIf="referenceDateFilteredJobs.length">
			<mat-accordion fxFlex="100" class="jobPlanningSidenavSearchAccordion">
				<mat-expansion-panel [expanded]="true">
					<mat-expansion-panel-header class="flutaroPanelHeaderBackground">
						<mat-panel-title>
							<p class="sidenavMatPanelTitle">
								{{ 'SEARCH' | translate }}
								<i class="material-icons sidenavNewJobsSearchIcon">search</i>
								/ {{ 'GROUPING' | translate }}
								<i class="material-icons sidenavNewJobsSearchIcon">web_asset</i>
							</p>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxLayout="column" fxLayoutGap="10px">
						<div
							fxLayout="row"
							fxLayoutGap="10px"
							matTooltip="{{ 'NEWJOBS_SIDENAV_SEARCH_TT' | translate }}"
							[matTooltipPosition]="'below'"
						>
							<mat-form-field style="max-width: 140px">
								<input
									[(ngModel)]="filter.pickup"
									matInput
									placeholder="{{ 'PICKUP' | translate }}"
									type="text"
									(ngModelChange)="filterData()"
								/>
							</mat-form-field>
							<mat-form-field style="max-width: 140px">
								<input
									[(ngModel)]="filter.delivery"
									matInput
									placeholder="{{ 'DELIVERY' | translate }}"
									type="text"
									(ngModelChange)="filterData()"
								/>
							</mat-form-field>
						</div>
					</div>
					<div class="sidenavGroupDiv" fxLayout="column" fxLayoutGap="10px">
						<p class="sidenavRadioLabel">{{ 'GROUPING_BY' | translate }}:</p>
						<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
							<mat-radio-group
								[(ngModel)]="groupByStartOrEnd"
								(ngModelChange)="calculateSidenavData()"
								fxLayout="row"
								fxLayoutAlign="space-between none"
								fxLayoutGap="10px"
							>
								<mat-radio-button [value]="'start'">
									{{ 'PICKUP' | translate }}
								</mat-radio-button>
								<mat-radio-button [value]="'end'">
									{{ 'DELIVERY' | translate }}
								</mat-radio-button>
							</mat-radio-group>
							<mat-checkbox
								class="mat-checkbox-small"
								aria-label="Group for relations"
								[(ngModel)]="groupByRelations"
								(change)="calculateSidenavData()"
							>
								<p>{{ 'RELATIONS' | translate }}</p>
							</mat-checkbox>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>

		<div fxLayout="column" style="margin-top: 20px">
			<mat-accordion multi="true" fxFlex="100" class="jobPlanningSidenavAccordion">
				<div *ngFor="let group of jobsInArea">
					<mat-expansion-panel
						style="border-left: 5px solid"
						[style.color]="group.color"
						[expanded]="currentExpanded === group.label"
						(opened)="currentExpanded = group.label"
						*ngIf="group.jobs.length > 0"
					>
						<mat-expansion-panel-header class="flutaroPanelHeaderBackground">
							<mat-panel-title>
								<div fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
									<p class="sidenavValue">{{ group.jobs.length }}</p>
									<p class="sidenavNewJobsDescription">{{ group.label }}</p>
								</div>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<sidenavComponentsJob
							id="unplannedJob"
							class="sidenavComponentsAccordionJob"
							*ngFor="let job of group.jobs"
							[color]="group.color"
							[job]="job"
							[locations]="locations"
							[showDarkText]="group.showDarkText"
							[userSettings]="userSettings"
						>
						</sidenavComponentsJob>
					</mat-expansion-panel>
				</div>
			</mat-accordion>
			<sidenavComponentsJob
				*ngFor="let singleJob of singleJobs"
				[job]="singleJob"
				[color]="'#40c1ac'"
				[locations]="locations"
				[userSettings]="userSettings"
				class="sidenavComponentsJob"
			>
			</sidenavComponentsJob>
		</div>
	</div>
</div>

import { TableModule } from '../table/table.module';
import { VehicleTableComponent } from './vehicle.table.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { CommonModule } from '@angular/common';
import { VehicleAddComponent } from './vehicle.add.component';
import { VehicleDialogComponent } from './vehicle.dialog.component';
import { PipesModule } from '../app/pipes.module';
import { VehicleStatusDialogComponent } from './status/vehicle.status.dialog.component';
import { SynonymModule } from '../synonym/synonym.module';
import { DialogModule } from '../dialog/dialog.module';

@NgModule({
	declarations: [VehicleTableComponent, VehicleAddComponent, VehicleDialogComponent, VehicleStatusDialogComponent],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		TableModule,
		DialogModule,
		PipesModule,
		SynonymModule,
	],
	providers: [],
	exports: [VehicleTableComponent, VehicleStatusDialogComponent],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class VehicleModule {}

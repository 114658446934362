import { DestinationType, JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { PoseidonCostsResponse } from '@flutaro/package/lib/model/services/PoseidonClasses';
import assign from 'lodash-es/assign';
import pick from 'lodash-es/pick';
import keys from 'lodash-es/keys';

import { CostCalculationVariableData } from '@flutaro/package/lib/model/costs/CostCalculation';
import { JobCosts } from '@flutaro/package/lib/model/costs/Costs';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import {
	getClosestJobAfterDateByLastDestinationsPlannedDate,
	getClosestJobBeforeDateByLastDestinationsPlannedDate,
} from '@flutaro/package/lib/functions/costs/CostsJobFunctions';
import { createSynonymFromWeekEndOutside } from '@flutaro/package/lib/functions/SynonymFunctions';
import { checkForSaturdayMondayMondayStartCase } from '@flutaro/package/lib/functions/costs/CostsCheckFunctions';
import { isSameWeek } from 'date-fns';
import { getDriversOutsideLastWeekEndForReferenceDate } from '../driver/DriverWeekEndOutsideFunctions';
import { getJobsFirstPickupDate, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';
import {
	getJobsWithSameStartDateAsDate,
	jobIsInWeekBeforeDate,
} from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';
import { flutaroCloneDeep } from '@flutaro/package/lib/functions/FlutaroDataFunctions';

export function updateJobsCostForPoseidonResponse(
	job: JobWrapper,
	userOfCalculation: string,
	costsResponse: PoseidonCostsResponse,
) {
	const costsAttributes = flutaroCloneDeep(costsResponse);
	delete costsAttributes.routeShapePositions;
	cleanManualOverwriteFromJobCosts(job.costCalculation);
	assign(job.costCalculation, pick(costsAttributes, keys(costsAttributes)));
	job.updateCostCalculationDate();
	const revenue = job.revenue;
	Object.assign(job.costCalculation, { userOfCalculation, revenue });
}

export function getAddedStopIndexInDestinations(destinations: JobDestination[]): number {
	return destinations.findIndex((destination) => destination.locationType === DestinationType.DELIVERY);
}

export function prepareCostDataForSpotVehicle(vehicle: Vehicle, costVariableData: CostCalculationVariableData) {
	if (!vehicle.isSpot) return;
	costVariableData.jobsStartingOnSameDay = [];
	costVariableData.followingJob = null;
	costVariableData.isReduced = false;
}

export function cleanManualOverwriteFromJobCosts(jobCosts: JobCosts) {
	delete jobCosts.originalTotalCosts;
	jobCosts.isManualOverwrite = false;
}

export function getEmptyKmAddress(
	calculationJob: JobWrapper,
	vehicle: Vehicle,
	driver: Driver,
	vehiclesJobs: JobWrapper[],
): Synonym {
	console.log(`getEmptyKmAddress, for calculationJob: ${calculationJob.job.identifier}`);
	if (calculationJob.costCalculation.latestCalculationDate && calculationJob.costCalculation.isCustomEmptyKmAddress) {
		console.log(`getEmptyKmAddress, isCustomEmptyKmAddress`);
		return calculationJob.costCalculation.emptyKmAddress;
	}
	let emptyKmAddress: Synonym;
	const lastJob = getClosestJobBeforeDateByLastDestinationsPlannedDate(
		vehiclesJobs,
		getJobsFirstPickupDate(calculationJob),
	);
	const lastJobIsInWeekOfCalculationJob =
		lastJob && !jobIsInWeekBeforeDate(lastJob, getJobsFirstPickupDate(calculationJob));
	if (lastJobIsInWeekOfCalculationJob) {
		console.log(`getEmptyKmAddress, lastJob emptyKmAddress - lastJobEndsInWeekOfCalculationJob`);
		emptyKmAddress = getLastDestination(lastJob).position;
	} else {
		emptyKmAddress = driver && !vehicle.isDepotAddressBased ? driver.homeAddress : vehicle.depotAddress;
		if (!emptyKmAddress) {
			console.error(`getEmptyKmAddress, no emptyKmAddress found for calculationJob: ${calculationJob.job.identifier}`);
		}
		console.log(`getEmptyKmAddress, ${vehicle.isDepotAddressBased ? 'depot' : 'home'}Address emptyKmAddress`);
		if (driver) {
			const driverWeekEndOutside = getDriversOutsideLastWeekEndForReferenceDate(
				driver,
				getJobsFirstPickupDate(calculationJob),
			);
			if (driverWeekEndOutside && driverWeekEndOutside.latitude) {
				console.log(`getEmptyKmAddress, isDriverWeekEndOutsideForReferenceDate`);
				emptyKmAddress = createSynonymFromWeekEndOutside(driverWeekEndOutside);
			}
			if (!driverWeekEndOutside && driver.isAlwaysOutside) {
				// TODO: handle me: get latest driverWeekEndOutside as emptyKmAddress
			}
		}
	}

	// Case 3 - if a Driver has a Job from Saturday - Monday, the Driver shouldn't be calculated by homeAddress for the first job of the week
	const case3Job = checkForSaturdayMondayMondayStartCase(calculationJob, vehiclesJobs);
	if (lastJob && case3Job && !isSameWeek(getJobsFirstPickupDate(lastJob), getJobsFirstPickupDate(calculationJob))) {
		console.log(`getEmptyKmAddress, case 3: checkForSaturdayMondayMondayStartCase emptyKmAddress`);
		emptyKmAddress = getLastDestination(case3Job).position;
	}

	return emptyKmAddress;
}

export function getJobsStartingOnSameDayAsCalculationJob(
	calculationJob: JobWrapper,
	vehiclesJobs: JobWrapper[],
): JobWrapper[] | [] {
	if (!vehiclesJobs.length) return [];
	return getJobsWithSameStartDateAsDate(vehiclesJobs, getJobsFirstPickupDate(calculationJob));
}

/**
 * Calculates the correct following job for passed calculation job.
 * Only returns a following job if the job should be recalculated with new emptyKmAddress of Job.
 * @param job
 * @param vehiclesJobs
 * @param isJobOnlyRecalculation
 */
export function detectAndReturnFollowingJobCase(job: JobWrapper, vehiclesJobs: JobWrapper[]): JobWrapper | null {
	if (!vehiclesJobs.length) return null;
	const followingJob = getClosestJobAfterDateByLastDestinationsPlannedDate(vehiclesJobs, getJobsFirstPickupDate(job));
	if (!followingJob) return null;
	const isDisableEmptyKmAddressUpdateCase =
		followingJob.costCalculation.isCustomEmptyKmAddress || followingJob.costCalculation.isManualOverwrite;
	return isDisableEmptyKmAddressUpdateCase ? null : followingJob;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges } from '@angular/core';
import { FlutaroSidenavProvider } from '../sidenav.provider';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { TimetableJobDisplayTitle, UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { LocationLabel } from '../SidenavClasses';

@Component({
	selector: 'sidenavComponentsJob',
	templateUrl: './sidenav.components.job.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponentsJob implements OnChanges {
	@Input() public job: JobWrapper;
	@Input() public locations: Synonym[];
	@Input() public color: string;
	@Input() public showDarkText: boolean;
	@Input() userProfile: FlutaroUserProfile;
	@Input() userSettings: UserSettings;
	startLocationLabel: string;
	endLocationLabel: string;
	TIMETABLE_JOB_DISPLAY = TimetableJobDisplayTitle;

	constructor(
		public sidenavService: FlutaroSidenavProvider,
		@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnChanges(changes) {
		if (!this.locations) {
			console.log(`Missing Locations in SidenavComponentsJob.`);
			return;
		}
		let locationLabel: LocationLabel = this.sidenavService.setLocationTypeLabel(this.locations, this.job);
		this.startLocationLabel = locationLabel.startLocationLabel;
		this.endLocationLabel = locationLabel.endLocationLabel;
		this.changeDetectorRef.markForCheck();
	}
}

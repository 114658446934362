<div *ngIf="destination.locationType === type || type === 'ALL'" fxLayout="column">
	<span *ngIf="showLocationTypeLabel && destination.locationType === 'PICKUP'" class="jobMoreDestinationPickup">{{
		'PICKUP' | translate
	}}</span>
	<span *ngIf="showLocationTypeLabel && destination.locationType === 'DELIVERY'" class="jobMoreDestinationDelivery">{{
		'DELIVERY' | translate
	}}</span>
	<div
		[fxLayout]="destinationsDirection"
		fxLayoutGap="10px"
		fxLayoutAlign="{{ destinationsDirection === 'row' ? 'start center' : 'center start' }}"
		class="flutaroTextInput"
	>
		<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
			<app-address-search
				(synonymChange)="changeDestinationsPosition($event)"
				*ngIf="!isSidenav"
				[(synonym)]="destination.position"
				[enableLocations]="true"
				[hidePlaceholder]="true"
				[isExternalJob]="isExternalJob"
				[hint]="
					originalDestination.position.toString() !== destination.position.toString()
						? '← ' + originalDestination.position.toString()
						: null
				"
				class="jobDestinationInputTableStyle destinationAddressComponent"
			>
			</app-address-search>
			<span *ngIf="originalDestination.position.name" class="externalJobAddressInput">{{
				originalDestination.position.name
			}}</span>
			<span *ngIf="originalDestination.position.description" class="externalJobAddressInput">{{
				originalDestination.position.description
			}}</span>
		</div>
		<app-datetimepicker
			(ngModelChange)="updateDates()"
			[(ngModel)]="destination.earliestDate"
			[type]="'datetime'"
			class="jobDestinationInputTableStyle"
			*ngIf="!isSidenav"
		>
		</app-datetimepicker>
		<app-datetimepicker
			(ngModelChange)="updateDates()"
			[(ngModel)]="destination.latestDate"
			[min]="destination | getDestinationsMinDate : destinationIndex : destinations"
			[type]="'datetime'"
			class="jobDestinationInputTableStyle"
			*ngIf="!isSidenav"
		>
		</app-datetimepicker>

		<mat-form-field class="jobDestinationNoteField jobDestinationInputTableStyle">
			<mat-label>{{ 'NOTES' | translate }}</mat-label>
			<textarea
				matInput
				cdkTextareaAutosize
				#autosize="cdkTextareaAutosize"
				[cdkAutosizeMinRows]="1"
				[cdkAutosizeMaxRows]="destination.notes?.length ? 3 : 2"
				[(ngModel)]="destination.notes"
				[disabled]="!isSidenav || (userProfile | isReadonly)"
				(ngModelChange)="destinationChange.emit(this.destination)"
				class="jobDestinationInputTableStyle"
			>
			</textarea>
		</mat-form-field>

		<div
			[formGroup]="destinationFormGroup"
			fxLayout="row"
			fxLayoutAlign="start center"
			class="jobDestinationInputTableStyle externalJobTableCargo"
		>
			<app-cargo [cargo]="destination.cargo" [disabled]="true" formControlName="cargo"> </app-cargo>
		</div>
	</div>
</div>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppDeleteDialogData } from './TableClasses';

@Component({
	selector: 'table-delete',
	templateUrl: './table.delete.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDeleteDialogComponent {
	dataType: string;
	dataName: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: AppDeleteDialogData) {
		if (data) {
			this.dataType = data.dataType;
			this.dataName = data.dataName;
		}
	}
}

import { Directive, HostListener } from '@angular/core';
import { TimetableComponentProvider } from '../data/timetable.component.service';

@Directive({
	selector: '[arrowScrolling]',
})
export class TimetableArrowScrollingDirective {
	constructor(private ttComponentService: TimetableComponentProvider) {}

	@HostListener('document:keydown.ArrowRight', ['$event'])
	ArrowRight(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		// If not triggered from Timetable (e.g. some Input) delegate Event
		if (target.tagName.toUpperCase() !== 'BODY' || target.tagName.toUpperCase() === 'TEXTAREA') return;
		event.preventDefault();
		this.ttComponentService.arrowRightSubject.next(true);
	}

	@HostListener('document:keydown.ArrowLeft', ['$event'])
	ArrowLeft(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		// If not triggered from Timetable (e.g. some Input) delegate Event
		if (target.tagName.toUpperCase() !== 'BODY' || target.tagName.toUpperCase() === 'TEXTAREA') return;
		event.preventDefault();
		this.ttComponentService.arrowLeftSubject.next(true);
	}

	@HostListener('document:keydown.ArrowDown', ['$event'])
	ArrowDown(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		if (target.tagName.toUpperCase() !== 'TEXTAREA') {
			event.preventDefault();
			let driverSection = document.getElementById('driverSection');
			let timetableSection = document.getElementById('roomTimeline');
			timetableSection.scrollTop = timetableSection.scrollTop + 300;
			driverSection.scrollTop = driverSection.scrollTop + 300;
		}
	}

	@HostListener('document:keydown.ArrowUp', ['$event'])
	ArrowUp(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		if (target.tagName.toUpperCase() !== 'TEXTAREA') {
			event.preventDefault();
			let driverSection = document.getElementById('driverSection');
			let timetableSection = document.getElementById('roomTimeline');
			timetableSection.scrollTop = timetableSection.scrollTop - 300;
			driverSection.scrollTop = driverSection.scrollTop - 300;
		}
	}
}

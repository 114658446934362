<div fxFlex fxLayout="column" style="width: 100%; min-width: 270px" class="flutaroTextInput">
	<form [formGroup]="addressSearchForm">
		<mat-form-field fxFlex="100" style="width: 100%" [appearance]="'outline'">
			<input
				(blur)="resetInputOnBlur($event)"
				[errorStateMatcher]="errorMatcher"
				[matAutocomplete]="search"
				[placeholder]="hidePlaceholder ? null : placeholder"
				[required]="required"
				formControlName="addressSearch"
				matInput
				type="text"
			/>
			<button (click)="clearLocation()" aria-label="Clear" class="appClearButton" mat-button matSuffix>
				<i class="material-icons synonymClearButtonIcon">close</i>
			</button>

			<mat-error *ngIf="this.required && addressSearchForm.get('addressSearch').hasError('required')"
				>{{ 'ADDRESS_SEARCH_REQUIRED' | translate }}
			</mat-error>
			<mat-error *ngIf="addressSearchForm.get('addressSearch').hasError('minlength')"
				>{{ 'ADDRESS_SEARCH_MIN_LENGTH' | translate }}
			</mat-error>
			<mat-error
				*ngIf="
					addressSearchForm.get('addressSearch').value?.length > 5 &&
					addressSearchForm.get('address').hasError('invalid_address')
				"
				>{{ 'ADDRESS_SEARCH_INVALID' | translate }}
			</mat-error>
			<mat-hint class="flutaroTextInput" *ngIf="hint?.length">{{ hint }}</mat-hint>
		</mat-form-field>
	</form>
	<mat-autocomplete #search="matAutocomplete" (optionSelected)="emitLocationAsSynonym($event.option.value)">
		<mat-option *ngFor="let address of $filteredAddresses | async" [value]="address">
			{{ address.toString() }}
		</mat-option>
	</mat-autocomplete>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { AppRoutingModule } from '../app/app.routing.module';
import { InterfaceModule } from '../interfaces/interfaces.module';
import { JobModule } from '../job/job.module';
import { MenuModule } from '../menu/menu.module';
import { SidenavComponentsJob } from './components/sidenav.components.job';
import { SidenavDriverBlockComponent } from './sidenav.driver.block.component';
import { SidenavFlutaroComponent } from './sidenav.flutaro.component';
import { InfoSidenavComponent } from './sidenav.info.component';
import { InfoSidenavProvider } from './sidenav.info.provider';
import { JobSidenavComponent } from './sidenav.job.component';
import { ChatSidenavComponent } from './sidenav.chat.component';
import { SidenavNewjobsComponent } from './sidenav.newjobs.component';
import { FlutaroSidenavProvider } from './sidenav.provider';
import { ChatModule } from '../chat/chat.module';
import { SidenavJobDestinationComponent } from './sidenav-job-destination/sidenav-job-destination.component';
import { PipesModule } from '../app/pipes.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { SidenavVehicleComponent } from './sidenav.vehicle.component';
import { FlutaroComponentsJobModule } from 'flutaro-lib-angular/lib/components/job/flutaro.components.job.module';
import { ComponentsModule } from '../app/components/components.module';

@NgModule({
	declarations: [
		JobSidenavComponent,
		ChatSidenavComponent,
		SidenavVehicleComponent,
		InfoSidenavComponent,
		SidenavFlutaroComponent,
		SidenavDriverBlockComponent,
		SidenavNewjobsComponent,
		SidenavComponentsJob,
		SidenavJobDestinationComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		MatSidenavModule,
		MenuModule,
		AppRoutingModule,
		JobModule,
		DragulaModule,
		InterfaceModule,
		NgxChartsModule,
		ChatModule,
		PipesModule,
		MatAutocompleteModule,
		MatRadioModule,
		FlutaroComponentsJobModule,
		ComponentsModule,
	],
	exports: [
		JobSidenavComponent,
		ChatSidenavComponent,
		SidenavVehicleComponent,
		InfoSidenavComponent,
		SidenavFlutaroComponent,
		SidenavNewjobsComponent,
		SidenavComponentsJob,
	],
	providers: [FlutaroSidenavProvider, InfoSidenavProvider, DragulaService],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class SidenavModule {}

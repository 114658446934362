import {
	addDays,
	addHours,
	differenceInHours,
	differenceInMinutes,
	isBefore,
	isSameISOWeek,
	isSaturday,
	isSunday,
	min,
	startOfISOWeek,
	subDays,
} from 'date-fns';
import {
	calculateMarginForObjectInTimetable,
	calculateWidthForObjectInTimetable,
	createTimetableEndDate,
} from './TimetableFunctions';
import { isDateInInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { TimetableDateElement } from './TimetableClasses';

export class TimeTable {
	rangeStart: Date;
	rangeEnd: Date;
	isCustomRange: boolean = false;
	startDate: Date;
	endDate: Date;
	selectedDate: Date;
	dates: TimetableDateElement[];
	hours: TimetableDateElement[];
	contentWidth: number;
	timetableWidth: string;
	width: number;
	hourStep: number;
	hourWidth: number;
	spanInMinutes: number;
	isDayTimetable: boolean;
	isSundayTable: boolean;

	constructor(selectedDate: Date, hourWidth = 100, hourStep = 1, isDayTimetable: boolean, isSundayTable: boolean) {
		this.isDayTimetable = isDayTimetable;
		this.isSundayTable = isSundayTable;
		this.hourWidth = hourWidth;
		this.hourStep = hourStep;
		this.setSelectedDate(selectedDate);
	}

	private initSpanInMinutes() {
		if (!this.isSundayTable && (isSunday(this.startDate) || !this.isDayTimetable)) {
			this.spanInMinutes = differenceInMinutes(this.startDate, this.endDate) - 1440;
		} else {
			this.spanInMinutes = differenceInMinutes(this.startDate, this.endDate);
		}
	}

	setSelectedDate(selectedDate: Date, force?: boolean) {
		this.selectedDate = selectedDate;
		this.setTimetableRangeBasedOnSelectedDate(selectedDate, force);
		this.startDate = this.isDayTimetable ? selectedDate : this.rangeStart;
		this.endDate = createTimetableEndDate(this.startDate, this.isDayTimetable, this.isSundayTable);
		this.setTimetableProperties();
	}

	setTimetableRangeBasedOnSelectedDate(selectedDate: Date, force: boolean) {
		if (this.rangeStart && this.rangeEnd && isDateInInterval(selectedDate, this.rangeStart, this.rangeEnd) && !force)
			return;
		if (!this.rangeStart && !this.rangeEnd) {
			console.log(`setTimetableRangeBasedOnSelectedDate, first time init`);
		} else if (!isDateInInterval(selectedDate, this.rangeStart, this.rangeEnd))
			console.log(
				`setTimetableRangeBasedOnSelectedDate, date ${selectedDate} is not in timetables range ${this.rangeStart.toISOString()} - ${this.rangeEnd.toISOString()}`,
			);
		if (force) console.log(`setTimetableRangeBasedOnSelectedDate, forced new creation`);
		this.rangeStart = startOfISOWeek(selectedDate);
		this.rangeEnd = createTimetableEndDate(this.rangeStart, false, this.isSundayTable);
		this.isCustomRange = !isSameISOWeek(this.rangeStart, new Date());
	}

	public getUnitForTimetable(width: number, refWidth?: number) {
		if (this.isDayTimetable) {
			if (refWidth == null) {
				refWidth = this.contentWidth;
			}
			return (width / refWidth) * 100 + '%';
		} else {
			return width + 'px';
		}
	}

	private setTimetableProperties() {
		this.initSpanInMinutes();
		this.setContentWidth();
		this.width = 100 + this.contentWidth;
		this.dates = this.createTimetableDateElements(true);
		this.hours = this.createTimetableDateElements(false);
		this.timetableWidth = this.getUnitForTimetable(this.contentWidth, null);
	}

	/**
	 * Creates an array of headline elements (one per day) in the form: width of the element,
	 * Date, that the element represents, (with time set to 0:0:0)
	 */
	createTimetableDateElements(isDate: boolean): TimetableDateElement[] {
		let result: TimetableDateElement[] = [];
		for (let i = this.startDate; isBefore(i, this.endDate); i = isDate ? addDays(i, 1) : addHours(i, this.hourStep)) {
			const next = isDate ? addDays(i, 1) : addHours(i, this.hourStep);
			const end = min([next, this.endDate]);
			const widthWithoutUnit = this.hourWidth * differenceInHours(end, i);
			const width = this.getUnitForTimetable(widthWithoutUnit);
			if (this.isSundayTable || !isSunday(i)) result.push({ width: width, date: i });
		}
		return result;
	}

	calculateObjectsWidth(startDate: Date, endDate: Date): number {
		return calculateWidthForObjectInTimetable(
			startDate,
			endDate,
			this.startDate,
			this.endDate,
			this.isSundayTable,
			this.hourWidth,
		);
	}

	/**
	 * Calculates the margin-left that the given job should have in the current timetable, to represent the correct amount of time.
	 * return: width without unit
	 */
	getMarginByDateForObjectInTimetable(startDate: Date): number {
		return calculateMarginForObjectInTimetable(startDate, this.startDate, this.isSundayTable, this.hourWidth);
	}

	private setContentWidth() {
		if (this.isSundayTable || (this.isDayTimetable && !isSaturday(this.startDate))) {
			this.contentWidth = this.hourWidth * differenceInHours(this.endDate, this.startDate);
		} else {
			this.contentWidth = this.hourWidth * differenceInHours(subDays(this.endDate, 1), this.startDate);
		}
	}
}

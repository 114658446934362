import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlutaroCustomMaterialModule } from '../../app/app.material2.module';
import { ExternalJobDialogComponent } from './externalJob.dialog.component';
import { ExternalJobsProvider } from './externalJobs.provider';
import { FormsModule } from '@angular/forms';
import { JobModule } from '../../job/job.module';
import { ComponentsModule } from '../../app/components/components.module';
import { ImportModule } from '../../import/import.module';

@NgModule({
	declarations: [ExternalJobDialogComponent],
	imports: [CommonModule, FormsModule, FlutaroCustomMaterialModule, JobModule, ComponentsModule, ImportModule],
	providers: [ExternalJobsProvider],
})
export class ExternalJobModule {}

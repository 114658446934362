import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { JobHelperService } from '../job/job.helper.service';
import { FlutaroSidenavProvider } from './sidenav.provider';
import { CostsCalculationProvider } from '../costs/costs.calculation.provider';
import { CommunicatorServerProvider } from '../communicatorApp/communicator.server.provider';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { SidenavBasicComponent } from './sidenav.basic.component';
import { TimetableJobStoreProvider } from '../timetable/data/timetable.job.store.provider';

@Component({
	selector: 'app-job-sidenav',
	templateUrl: './sidenav.job.component.html',
	styleUrls: ['sidenav.job.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobSidenavComponent extends SidenavBasicComponent {
	@Input() public job: JobWrapper;
	@Input() public jobIsEdited: boolean;

	constructor(
		public sidenavProvider: FlutaroSidenavProvider,
		public jobHelperService: JobHelperService,
		private communicator: CommunicatorServerProvider,
		private costsProvider: CostsCalculationProvider,
		private jobStoreProvider: TimetableJobStoreProvider,
		protected cd: ChangeDetectorRef,
	) {
		super(cd);
	}

	sendJobToApp() {
		this.communicator.sendJobChangesToDriver([this.job]);
	}

	deleteJobFromAppUser() {
		this.communicator.deleteJobFromAppUserBySidenavAction(this.job);
	}

	showCostsDialog() {
		this.jobStoreProvider.openCostDialogForSidenavJob(this.job);
	}

	public closeSideNav($event) {
		$event.stopPropagation();
		this.sidenavProvider.sidenavPop();
	}

	public saveJob($event) {
		$event.stopPropagation(); // TODO: should this be used for all buttons or can it be removed?
		this.sidenavProvider.storeJobNavChanges(this.job);
	}

	changeJobsStatus(newStatus: JobStatus) {
		console.log(`changeJobsStatus, changing to status ${newStatus}`);
		this.job.job.status = newStatus;
		this.updateJobIsEdited(this.job);
	}

	updateJobIsEdited(job: JobWrapper) {
		this.sidenavProvider.updateSidenavIsJobEdited(job);
	}

	public changeVehicleAndPreview(vehicle: Vehicle) {
		this.updateJobIsEdited(this.job);
	}
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { DashboardNewsProvider } from '../dashboard/widget/dashboard.news.provider';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { FlutaroRelationsService } from '../data/data-services/data.relations.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { MenuComponentProvider } from './menu.component.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { APP_MENU } from './MenuClasses';
import { EventService } from '../event/event.service';
import { CompanyService } from '../company/company.service';

@Component({
	selector: 'app-menu-dashboard',
	templateUrl: './menu.dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardMenuComponent implements OnInit {
	constructor(
		private menuProvider: MenuComponentProvider,
		public driverService: FlutaroDriverService,
		public jobService: FlutaroJobService,
		public vehicleService: FlutaroVehicleService,
		public relationsService: FlutaroRelationsService,
		public newsProvider: DashboardNewsProvider,
		public fbAuth: WebAuthService,
		public settingsProvider: UserSettingsProvider,
		public events: EventService,
		public companyService: CompanyService,
	) {}

	ngOnInit() {
		this.menuProvider.initTabsAfterMenuChange([APP_MENU.DASHBOARD]);
	}
}

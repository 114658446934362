import { Directive, HostListener } from '@angular/core';
import { FlutaroSidenavProvider } from '../../sidenav/sidenav.provider';

@Directive({
	selector: '[closeOpenSidenavOnEsc]',
})
export class CloseSidenavOnEscDirective {
	constructor(private sidenavService: FlutaroSidenavProvider) {}

	@HostListener('window:keydown', ['$event'])
	closeSideNav(event: KeyboardEvent) {
		if (event.key !== 'Esc' && event.key !== 'Escape') {
			return;
		}
		event.preventDefault();
		this.sidenavService.sidenavPop();
	}
}

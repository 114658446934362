import { FormControl, FormGroup } from '@angular/forms';
import assign from 'lodash-es/assign';
import pick from 'lodash-es/pick';
import keys from 'lodash-es/keys';
import { MatDialogConfig } from '@angular/material/dialog';

export function validateFormFields(form: FormGroup) {
	Object.keys(form.controls).forEach((field) => {
		const control = form.get(field);
		// made changes so that the method also works for dynamic form fields.
		if (control instanceof FormControl) {
			control.markAsTouched({ onlySelf: true });
			control.markAsDirty();
		} else if (control instanceof FormGroup) {
			validateFormFields(control);
		}
	});
}

export function clearFormValidation(formControl: FormControl) {
	formControl.clearValidators();
	formControl.updateValueAndValidity();
}

export function updateFormValues(form: FormGroup, updateObject, parseFloatValues?: string[], newObject?) {
	// Convert non-String Form-Values into their actual format, for example if string values are used for regex Input-Validations
	if (parseFloatValues) {
		parseFloatValues.forEach((patchValue) => {
			let patchValueParsed;
			form.value[patchValue]
				? (patchValueParsed = parseFloat(form.value[patchValue].replace(',', '.')))
				: (patchValueParsed = form.value[patchValue]);
			if (!updateObject[patchValue]) updateObject[patchValue] = 0;
			form.controls[patchValue].patchValue(patchValueParsed);
		});
	}
	newObject
		? assign(updateObject, pick(form.value, keys(newObject)))
		: assign(updateObject, pick(form.value, keys(updateObject)));
}

export function createDialogConfig(): MatDialogConfig {
	const dialogConfig = new MatDialogConfig();
	dialogConfig.width = '100vw';
	dialogConfig.maxWidth = '1200px';
	return dialogConfig;
}

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { validateFormFields } from 'src/app/form/FlutaroFormFunctions';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { IntegrationsPoseidonService } from '../../integrations/poseidon/integrations.poseidon.service';
import { adjustDestinationsFormDatesOnDateChange } from '../form/JobFormFunctions';
import { DestinationDateType, DestinationType, JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { JobHelperService } from '../job.helper.service';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Component({
	selector: 'app-multi-destinations',
	templateUrl: './destinations.component.html',
	styleUrls: ['./destinations.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DestinationsComponent implements OnInit {
	@Input() jobWrapper: JobWrapper;
	@Output() jobWrapperChange: EventEmitter<JobWrapper> = new EventEmitter<JobWrapper>();
	@Input() isNew: boolean;
	@Output() isDestinationInputChange = new EventEmitter<boolean>();
	@Output() resetCosts = new EventEmitter<boolean>();
	isCargoInput: boolean = false;
	destinationForm: FormGroup;
	locationTypes = [DestinationType.PICKUP, DestinationType.DELIVERY];
	isOpen: boolean = false;
	destinationStep: number;
	isDisabled: boolean = false;
	// Form only Attributes
	DestinationDateTypes = DestinationDateType;
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	isDisabledFormUpdates: boolean = false;

	constructor(
		private fb: FormBuilder,
		private notifications: FlutaroNotificationsProvider,
		private poseidon: IntegrationsPoseidonService,
		private cd: ChangeDetectorRef,
		private jobHelperService: JobHelperService,
	) {}

	ngOnInit() {
		// TODO: add Form native error message implementation, using mat-error and form fieldss (see synonym.addressSearch.component as reference)
		this.initializeFormFields();
	}

	private initializeFormFields(): void {
		this.destinationForm = this.fb.group({
			multiDestinationRows: this.fb.array(this.multiDestinationRows(this.jobWrapper.destinations)),
		});
		this.destinationForm.valueChanges.subscribe((value) => {
			this.assignFormValueToJobDestination();
		});
	}

	private updateAllFormFields(): void {
		this.destinationForm.disable();
		this.destinationForm.controls['multiDestinationRows'].patchValue(this.jobWrapper.destinations);
		this.destinationForm.enable();
	}

	async drop(event: CdkDragDrop<string[]>) {
		console.debug(
			`drop, called with event.currentIndex: ${event.currentIndex}, previousIndex: ${event.previousIndex} and form valid: ${this.destinationForm.valid}`,
		);
		if (event.previousIndex === event.currentIndex) return;
		this.isDisabledFormUpdates = true;
		moveItemInArray(this.jobWrapper.destinations, event.previousIndex, event.currentIndex);
		this.updateAllFormFields();
		this.assignFormValueToJobDestination(); // TODO: check if needed
		await flutaroWait(100);
		await this.updateJobsDistanceAttributes();
		this.isDisabledFormUpdates = false;
		this.resetCosts.emit(true);
	}

	canDropDestination(index: number, item: CdkDrag<FormGroup>, list: CdkDropList<FormGroup[]>) {
		const destination = item.data;
		const destinations = list.getSortedItems();
		const pickUpDestinations = destinations.filter((dest) => dest.data.value.locationType === DestinationType.PICKUP);
		const deliveryDestinations = destinations.filter(
			(dest) => dest.data.value.locationType === DestinationType.DELIVERY,
		);

		const isValidPickUpDrop =
			destination.value.locationType !== DestinationType.PICKUP ||
			(index !== destinations.length - 1 && pickUpDestinations.length > 1);
		const isValidDeliveryDrop =
			destination.value.locationType !== DestinationType.DELIVERY || (index !== 0 && deliveryDestinations.length > 1);
		return isValidPickUpDrop && isValidDeliveryDrop;
	}

	getAllControlsOfDestinationFormGroup(): AbstractControl[] {
		return Object.keys((this.destinationForm.controls['multiDestinationRows'] as FormGroup).controls).map((key) =>
			(this.destinationForm.controls['multiDestinationRows'] as FormGroup).get(key),
		);
	}

	getCurrentNumberOfFormControls(): number {
		return Object.keys((this.destinationForm.controls['multiDestinationRows'] as FormGroup).controls).length;
	}

	updateDestinationsOnDateChange(index: number, type: DestinationDateType) {
		if (this.isDisabledFormUpdates) return;
		switch (type) {
			case DestinationDateType.EARLIEST:
				adjustDestinationsFormDatesOnDateChange(
					<FormArray>this.destinationForm.get('multiDestinationRows'),
					index,
					true,
				);
				break;
			case DestinationDateType.LATEST:
				adjustDestinationsFormDatesOnDateChange(
					<FormArray>this.destinationForm.get('multiDestinationRows'),
					index,
					false,
				);
				break;
			case DestinationDateType.PLANNED:
				break;
		}
	}

	multiDestinationRows(destinations: JobDestination[]): FormGroup[] {
		let fbGroup = [];
		destinations.forEach((destination) => {
			const form = this.fb.group({
				position: new FormControl(
					{
						value: destination.position,
						disabled: this.isDisabled,
					},
					Validators.compose([Validators.required]),
				),
				earliestDate: new FormControl(destination.earliestDate, Validators.compose([Validators.required])),
				latestDate: new FormControl(destination.latestDate, Validators.compose([Validators.required])),
				locationType: new FormControl(destination.locationType, Validators.compose([Validators.required])),
				notes: new FormControl(destination.notes, Validators.compose([])),
				name: new FormControl(destination.name, Validators.compose([])),
				description: new FormControl(destination.description, Validators.compose([])),
				plannedDate: new FormControl(destination.plannedDate, Validators.compose([Validators.required])),
				timeInMinutes: new FormControl(destination.timeInMinutes, Validators.compose([])),
				distance: new FormControl(destination.distance, Validators.compose([])),
				cargo: new FormControl(destination.cargo, Validators.compose([])),
				actionTimeInMinutes: new FormControl(destination.actionTimeInMinutes, Validators.compose([])),
				ingoingReferences: new FormControl(destination.ingoingReferences, Validators.compose([])),
				outgoingReferences: new FormControl(destination.outgoingReferences, Validators.compose([])),
			});
			fbGroup.push(form);
		});
		return fbGroup;
	}

	setCargo(index: number, event: FormArray): void {
		(<FormArray>this.destinationForm.controls['multiDestinationRows']).at(index).patchValue({
			cargo: event.value,
		});
	}

	addNewDestination(): void {
		const control = <FormArray>this.destinationForm.controls['multiDestinationRows'];
		const jobDestination: JobDestination[] = Array(new JobDestination());
		if (this.isOpen) {
			console.debug(`addNewDestination, adding destination after index ${this.destinationStep}`);
			control.insert(this.destinationStep + 1, this.multiDestinationRows(jobDestination)[0]);
		} else {
			control.push(this.multiDestinationRows(jobDestination)[0]);
		}
	}

	removeDestination(index: number): void {
		const control = <FormArray>this.destinationForm.controls['multiDestinationRows'];
		control.removeAt(index);
	}

	nextDestination($event): void {
		$event.stopPropagation();
		this.destinationStep++;
	}

	prevDestination($event): void {
		$event.stopPropagation();
		this.destinationStep--;
	}

	setDestinationOpenState(isOpen: boolean, index?: number): void {
		if (isOpen) this.destinationStep = index;
		this.isOpen = isOpen;
		this.isDestinationInputChange.emit(isOpen);
	}

	async updateDestinationsPosition(destinationIndex: number, position: Synonym): Promise<boolean> {
		(<FormArray>this.destinationForm.controls['multiDestinationRows']).at(destinationIndex).patchValue({
			position: position,
		});
		return await this.updateJobsDistanceAttributes();
	}

	assignFormValueToJobDestination(): void {
		this.jobWrapper.destinations = (<FormArray>this.destinationForm.get('multiDestinationRows')).value;
		this.jobWrapperChange.emit(this.jobWrapper);
	}

	assignJobsDistanceValuesToDestCtrl() {
		const destinationControls: FormArray = <FormArray>this.destinationForm.controls['multiDestinationRows'];
		destinationControls.value.forEach((destination, index) => {
			destinationControls.at(index).patchValue({
				timeInMinutes: this.jobWrapper.destinations[index].timeInMinutes,
				distance: this.jobWrapper.destinations[index].distance,
			});
		});
		this.cd.markForCheck();
	}

	setCargoInputStatus(event: boolean) {
		this.isCargoInput = event;
	}

	/**
	 * Marks all controls in a form group as touched
	 * @param formGroup - The form group to touch
	 */
	markFormGroupTouched(formGroup: FormGroup) {
		(<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsTouched();
			if (control.controls) {
				this.markFormGroupTouched(control);
			}
		});
	}

	checkformAndDatesValidation(index?: number): boolean {
		this.markFormGroupTouched(this.destinationForm);
		// TODO: fix me once material datepicker bug is fixed showing latestDate being invalid:
		let isValid: boolean = this.destinationForm.valid;
		if (this.isCargoInput) {
			this.notifications.showBasicSnackBar('Bitte beende die aktuelle Frachtaktion vor dem Speichern');
			isValid = false;
		}
		if (index && !(<FormArray>this.destinationForm.get('multiDestinationRows')).controls[index].valid) {
			validateFormFields(<FormGroup>(<FormArray>this.destinationForm.get('multiDestinationRows')).controls[index]);
			this.notifications.showBasicSnackBar(
				'Bitte fülle alle erforderlichen Felder so aus wie in der Feldbeschreibung angegeben',
			);
			isValid = false;
		}
		return isValid;
	}

	// TODO: refactor for new component, check reference implementation on relation.dialog.component
	removeReference(
		reference: string,
		formControl: AbstractControl,
		attributeName: 'ingoingReferences' | 'outgoingReferences',
	) {
		console.log(`Removing reference: ${reference}`);
	}

	addReference(
		reference: MatChipInputEvent,
		formControl: AbstractControl,
		attributeName: 'ingoingReferences' | 'outgoingReferences',
	) {
		const newReference = reference.value;
		console.log(`Adding reference: ${newReference}`);
		const referenceValue = formControl.get(attributeName).value;
		referenceValue.push(newReference);
		formControl.get(attributeName).patchValue(referenceValue);
		if (reference.input) {
			reference.input.value = '';
		}
	}

	/*
     Returns an array of invalid control/group names, or a zero-length array if
     no invalid controls/groups where found
  */
	public findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
		var invalidControls: string[] = [];
		let recursiveFunc = (form: FormGroup | FormArray) => {
			Object.keys(form.controls).forEach((field) => {
				const control = form.get(field);
				if (control.invalid) invalidControls.push(field);
				if (control instanceof FormGroup) {
					recursiveFunc(control);
				} else if (control instanceof FormArray) {
					recursiveFunc(control);
				}
			});
		};
		recursiveFunc(formToInvestigate);
		console.log(invalidControls);
		return invalidControls;
	}

	private async updateJobsDistanceAttributes() {
		if (!this.jobWrapper.destinations.every((dest) => !!dest.position.latitude)) {
			console.debug(`onUpdateOnJobsAddresses, not all destinations are valid positions.`);
			return false;
		}
		await this.jobHelperService.getAndUpdateJobsDistances(this.jobWrapper);
		this.assignJobsDistanceValuesToDestCtrl();
		return true;
	}
}

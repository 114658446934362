import { TimeTable } from './TimeTable';
import {
	addDays,
	addHours,
	addWeeks,
	differenceInMinutes,
	endOfDay,
	isAfter,
	isSameISOWeek,
	isSaturday,
	isSunday,
	max,
	min,
	startOfDay,
	subDays,
	subWeeks,
} from 'date-fns';
import { TimetableFilteredEntry } from './data/TimetableFilterClasses';
import { TimetableDriverEntry, TimetableJobEntry, TimetableTrailerEntry } from './TimetableClasses';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { getSchedulesInTimeRange } from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { TrailerSchedule } from '@flutaro/package/lib/model/AppScheduleClasses';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { getDriversOutsideCurrentWeek } from '../driver/DriverWeekEndOutsideFunctions';
import { areBothDatesAfterDate, getSundayForDate } from '@flutaro/package/lib/functions/DataDateFunctions';
import { areJobsOverlapping } from '@flutaro/package/lib/functions/job/JobOverlappingFunctions';

export function createTimetableEndDate(date: Date, isDayTable: boolean, isSundayTable: boolean): Date {
	let endDateOfTable = addHours(isDayTable ? addDays(date, 1) : addWeeks(date, 1), 8);
	if (!isSundayTable && (!isDayTable || isSaturday(date))) endDateOfTable = addDays(endDateOfTable, 1);
	return endDateOfTable;
}

/**
 * Creates a timetable for the current day and week
 * @param isSundayTable
 * @param isDay
 */
export function createDefaultTimetable(isSundayTable: boolean, isDay: boolean): TimeTable {
	let today = startOfDay(new Date());
	if (!isSundayTable && isSunday(today)) today = subDays(today, 1); // subDays to stay in same calendarWeek
	console.log(`createDefaultTimetable ${isDay ? 'for today' : 'for this week'}`);
	return new TimeTable(today, 24, 8, isDay, isSundayTable);
}

/**
 * To avoid duplicates, delete the dropped Element, which will be generated again by timetable calculating function, but with correct ids/driver set.
 * @param {HTMLElement} element
 */
export function hideDroppedElementInTimetable(element: HTMLElement) {
	if (!element.classList.contains('hideTimetableJobAfterDrop')) element.className += ' hideTimetableJobAfterDrop';
}

export function setTimetableEntriesAndJobsOverlapping(filteredMapEntry: TimetableFilteredEntry) {
	filteredMapEntry.vehicleJobStatus.overlapping = setJobsEntryOverlappingAndGetHighestValue(filteredMapEntry.jobsEntry);
}

export function calculateWidthForObjectInTimetable(
	startDate: Date,
	endDate: Date,
	timetableStartDate: Date,
	timetableEndDate: Date,
	isSundayTable: boolean,
	hourWidth: number,
): number {
	let calcEndDate = min([timetableEndDate, endDate]);
	let calcStartDate = max([timetableStartDate, startDate]);
	if (!isSundayTable) {
		const timetablesSundayDate = getSundayForDate(timetableStartDate);
		if (isSunday(calcStartDate) && isSunday(calcEndDate)) {
			calcStartDate = calcEndDate;
		} else if (isSunday(calcEndDate)) {
			calcEndDate = startOfDay(calcEndDate);
		} else if (isSunday(calcStartDate)) {
			calcStartDate = endOfDay(calcStartDate);
		} else if (
			!isSameISOWeek(startDate, endDate) &&
			!isSameISOWeek(timetableStartDate, endDate) &&
			isAfter(timetableEndDate, timetablesSundayDate) &&
			!areBothDatesAfterDate(startDate, endDate, timetablesSundayDate)
		) {
			// Subtract one day for not-displayed sunday - decided to go with endDate
			calcEndDate = subDays(calcEndDate, 1);
		}
	}
	const calcTimetableObjectOffsetInMinutes = differenceInMinutes(calcEndDate, calcStartDate);
	return Math.floor((calcTimetableObjectOffsetInMinutes * hourWidth) / 60);
}

export function calculateMarginForObjectInTimetable(
	startDate: Date,
	timetableStartDate: Date,
	isSundayTable: boolean,
	hourWidth: number,
): number {
	const calcStartDate = max([timetableStartDate, startDate]);
	let calcDate = calcStartDate;
	// This matches the case when margin needs to be set after timetables sunday in a no-sunday timetable
	if (!isSundayTable && !isSameISOWeek(timetableStartDate, calcDate)) calcDate = subDays(calcDate, 1);
	if (!isSundayTable && isSunday(calcStartDate)) calcDate = startOfDay(calcDate);
	const calcTimetableObjectOffsetInMinutes = differenceInMinutes(calcDate, timetableStartDate);
	return Math.floor((calcTimetableObjectOffsetInMinutes * hourWidth) / 60);
}

export function setJobsEntryOverlappingAndGetHighestValue(jobsEntry: TimetableJobEntry[]): number {
	if (!jobsEntry.length) return 0;
	// Set all jobs to imaginary overlapping level -1 as start position
	jobsEntry.forEach((jobEntry) => (jobEntry.overlapping = -1));
	jobsEntry.forEach((jobEntry) => {
		const job = jobEntry.jobWrapper;
		let overlappingLevel = 0;
		let isOverlapping = true;
		while (isOverlapping) {
			const jobsOnLevel = jobsEntry
				.filter((jobEntry) => jobEntry.overlapping === overlappingLevel)
				.map((jobEntry) => jobEntry.jobWrapper);
			isOverlapping = areJobsOverlapping(jobsOnLevel.concat(job));
			if (isOverlapping) ++overlappingLevel;
		}
		jobEntry.overlapping = overlappingLevel;
	});
	return Math.max(...jobsEntry.map((jobEntry) => jobEntry.overlapping));
}

export function createVehicleTrailerEntriesForDay(
	vehicle: Vehicle,
	trailers: Vehicle[],
	refDate: Date,
): TimetableTrailerEntry[] | undefined {
	if (!vehicle.trailerSchedules?.length || !trailers?.length || !refDate) return undefined;
	const trailerSchedulesForDay = getSchedulesInTimeRange(vehicle.trailerSchedules, refDate, endOfDay(refDate))?.filter(
		(trailerSchedule) => trailers.find((trailer) => trailerSchedule.entityId === trailer.backendId),
	) as TrailerSchedule[];
	if (!trailerSchedulesForDay?.length) return undefined;

	return trailerSchedulesForDay.map((trailerSchedule) => {
		const trailer = trailers.find((trailer) => trailerSchedule.entityId === trailer.backendId) as Vehicle;
		return new TimetableTrailerEntry(trailer, trailerSchedule);
	});
}

export function updateDriverEntriesIsWeekEndOutside(driverEntry: TimetableDriverEntry, startDate: Date) {
	driverEntry.isWeekEndOutsideCurrentWeek = !!getDriversOutsideCurrentWeek(driverEntry.driver, startDate);
}

export function getDriverWeekEndWorkingStatus(driver: Driver, startDate: Date): number {
	const driverWorkedLastWeekEnd = driver.weekEndWorkingDates.filter((date) =>
		isSameISOWeek(date, subWeeks(startDate, 1)),
	);
	if (!driverWorkedLastWeekEnd.length) return 0;
	const driverWorkedWeekEndBefore = driver.weekEndWorkingDates.filter((date) =>
		isSameISOWeek(date, subWeeks(startDate, 2)),
	);
	return driverWorkedWeekEndBefore.length ? 2 : 1;
}

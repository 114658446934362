import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { AppInternationalizationService } from '../app/app.internationalization.service';
import {
	parseElementsBasics,
	transformObjectIntoUserSettingsClass,
} from '@flutaro/package/lib/functions/FlutaroDataFunctions';

@Injectable()
export class UserSettingsProvider {
	userPath: string = '/data/user/settings';
	$userSettings: BehaviorSubject<UserSettings> = new BehaviorSubject<UserSettings>(null);

	constructor(private http: HttpClient, private languageService: AppInternationalizationService) {}

	setUserSettings(userSettings: UserSettings) {
		this.$userSettings.next(transformObjectIntoUserSettingsClass(parseElementsBasics(userSettings)));
		if (userSettings.appLanguage) {
			this.languageService.changeAppLanguage(userSettings.appLanguage);
		}
	}

	updateUserSettings(newUserSettings: UserSettings) {
		this.$userSettings.next(transformObjectIntoUserSettingsClass(newUserSettings));
		return lastValueFrom(this.http.put(environment.routingApiURL + this.userPath, newUserSettings));
	}

	async loadAndSetUserSettings() {
		const userSettings = await lastValueFrom(this.http.get<UserSettings>(environment.routingApiURL + this.userPath));
		this.setUserSettings(userSettings);
		return userSettings;
	}
}

import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	getClosestScheduleAfterDate,
	getScheduleForDate,
	getSchedulesInTimeRange,
} from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { getDriverById } from '@flutaro/package/lib/functions/driver/DriverDataFunctions';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';
import { TimeTable } from '../TimeTable';

/**
 * Timetable specific function to get a vehicles driver respecting the timetables day span of 36hours.
 * The function selects a driver within the timerange of the displayed day + 8 hours to respect driver changes after startOfDay.
 * @param vehicle
 * @param companySettings
 * @param timetable
 */

export function isTruckActivatedForTimetableDate(
	vehicle: Vehicle,
	companySettings: CompanySettings,
	timetable: TimeTable,
): boolean {
	if (companySettings.isDriverDeactivated) return vehicle.isActivatedForPlanning;
	if (!vehicle.driverSchedules?.length) return false;
	return !!getVehiclesDriverSchedulesForTimetable(vehicle, timetable)?.length;
}

export function getVehiclesDriverSchedulesForTimetable(vehicle: Vehicle, timetable: TimeTable) {
	return getSchedulesInTimeRange(vehicle.driverSchedules, timetable.startDate, timetable.endDate);
}

export function getVehiclesTimetableDisplayedDriver(
	vehicle: Vehicle,
	drivers: Driver[],
	timetable: TimeTable,
): Driver | null {
	const driverSchedules = getVehiclesDriverSchedulesForTimetable(vehicle, timetable);
	if (!driverSchedules?.length) return null;
	if (driverSchedules.length === 1) return getDriverById(driverSchedules[0].entityId, drivers);
	// Case1: Schedule activate at selected Date
	const scheduleActivateAtDate = getScheduleForDate(driverSchedules, timetable.selectedDate);
	if (scheduleActivateAtDate) return getDriverById(scheduleActivateAtDate.entityId, drivers);
	// Case2: No schedule activate at selected Date --> choose closest after selectedDay
	return getDriverById(getClosestScheduleAfterDate(driverSchedules, timetable.selectedDate).entityId, drivers);
}

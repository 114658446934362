import { PipeTransform, Pipe } from "@angular/core";
import { appRoundNumber } from "@flutaro/package/lib/functions/FlutaroStringNumberFunctions";

@Pipe({
  name: 'minutesToDrivingTime',
})
export class MinutesToDrivingTimePipe implements PipeTransform {
  transform(value: number): string {
    let hour: number = Math.floor(value / 60);
    let hourValue: string = hour.toString();
    let minute: number = value % 60;
    let minueValue: string = minute.toString();
    if (hour < 10) {
      hourValue = '0' + hour;
    }
    if (minute < 10) {
      minueValue = '0' + minute;
    }
    return hourValue + ':' + minueValue + ' h';
  }
}

@Pipe({
  name: 'distanceAsKm',
})
export class DistanceAsKmDisplayedPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return '0 Km';
    const parsedValue = appRoundNumber(value / 1000).toString().replace('.', ',');
    return `${parsedValue} Km`
  }
}

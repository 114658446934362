import { VehicleStatus } from '@flutaro/package/lib/model/VehicleStatus';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';

export class WeekDayChange {
	selectedDate: Date;
	selectedWeekday: number;
	forceTimetableRangeRecreate: boolean;

	constructor(selectedDate: Date, selectedWeekday: number, forceTimetableRangeRecreate: boolean) {
		Object.assign(this, {
			selectedDate,
			selectedWeekday,
			forceTimetableRangeRecreate,
		});
	}
}

export class VehicleStatusStoreChange {
	status: VehicleStatus;
	isNew: boolean;
	vehicle: Vehicle;

	constructor(status: VehicleStatus, isNew: boolean, vehicle: Vehicle) {
		Object.assign(this, {
			status,
			isNew,
			vehicle,
		});
	}
}

<div fxLayout="column">
	<p>{{ 'JOB_APP_HISTORY_LAST_ACTIVITY' | translate }}: {{ appSettings.latestUpdateDate | relativeTime }}</p>

	<mat-accordion fxFlex="100">
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					<i class="material-icons dialogIconExpansionPanel">phonelink </i> App-History
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-list fxFlex="100">
				<mat-list-item
					*ngFor="let history of appSettings.history | appOrderBy: ['-date']; let i = index"
					class="historyEntryItem"
					fxLayout="row"
				>
					<mat-icon class="historyEntryStatusIcon">
						<div
							[class.jobAcceptIcon]="history.status === 5"
							[class.jobCancelIcon]="history.status === 16 || history.status === 10"
							[class.jobDeclinedIcon]="history.status === 6"
							[class.jobDoneIcon]="history.status === 8"
							[class.jobNewIcon]="history.status === 1"
							[class.jobPlannedIcon]="history.status === 2"
							[class.jobReceivedIcon]="history.status === 4"
							[class.jobSentIcon]="history.status === 3"
							[class.jobStartedIcon]="history.status === 7"
							class="jobStatusIconSidenav"
						></div>
					</mat-icon>

					<div fxLayout="column">
						<p>{{ history.date | relativeTime }}</p>
						<p>{{ history.lastModifiedBy }}</p>
					</div>

					<mat-divider *ngIf="i !== appSettings.history.length - 1" class="historyEntryDivider"></mat-divider>
				</mat-list-item>
			</mat-list>
		</mat-expansion-panel>
	</mat-accordion>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationsPoseidonService } from './poseidon/integrations.poseidon.service';
import { AddressSearchProvider } from './address/integration.address.service';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [IntegrationsPoseidonService, AddressSearchProvider],
	exports: [],
})
export class IntegrationsModule {}

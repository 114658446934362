<div fxLayout="column" fxLayoutAlign="center start">
	<div fxLayout="row" fxLayoutAlign="start start">
		<i *ngIf="isAllCorrect" class="material-icons importStatusIconValid">check_circle</i>
		<i *ngIf="!isAllCorrect" class="material-icons importStatusInvalidIcon">cancel</i>
		<span class="importSpan">Status</span>
		<p class="importStatus" *ngIf="isAllCorrect">OK</p>
		<p class="importStatus" *ngIf="!isAllCorrect">Error</p>
	</div>

	<div *ngIf="importType === dataTypesDefinition.job && jobSummary" fxLayout="row">
		<div fxLayout="column">
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_MISSING_JOB_IDENTIFIER' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidIdentifier !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidIdentifier }}</p>
				</div>
			</div>
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_IDENTIFIER_NOT_UNIQUE' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.identifierNotUnique !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.identifierNotUnique }}</p>
				</div>
			</div>
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_VEHICLE_JOBSUMMARY' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidVehicle !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidVehicle }}</p>
				</div>
			</div>
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_VEHICLE_TYPE' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidVehicleType !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidVehicleType }}</p>
				</div>
			</div>
		</div>

		<div fxLayout="column">
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_PICKUP_ADDRESSES' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidPickUpAddresses !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidPickUpAddresses }}</p>
				</div>
			</div>
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_PICKUP_DATES' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidPickUpDates !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidPickUpDates }}</p>
				</div>
			</div>
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_DELIVERY_ADDRESSES' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidDeliveryAddresses !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidDeliveryAddresses }}</p>
				</div>
			</div>
			<div class="importStatusRow" fxLayout="row">
				<div class="importDescriptionBackground">
					<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_DELIVERY_DATES' | translate }}</p>
				</div>
				<div [class.importStatusError]="jobSummary.invalidDeliveryDates !== 0" class="importStatusValueBackground">
					<p class="importStatusValue">{{ jobSummary.invalidDeliveryDates }}</p>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="importType === dataTypesDefinition.driver && driverSummary" fxLayout="column">
		<div class="importStatusRow" fxLayout="row">
			<div class="importDescriptionBackground">
				<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_NAME' | translate }}</p>
			</div>
			<div class="importStatusValueBackground" [class.importStatusError]="driverSummary.invalidName !== 0">
				<p class="importStatusValue">{{ driverSummary.invalidName }}</p>
			</div>
		</div>
		<div class="importStatusRow" fxLayout="row">
			<div class="importDescriptionBackground">
				<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_ADDRESS' | translate }}</p>
			</div>
			<div class="importStatusValueBackground" [class.importStatusError]="driverSummary.invalidAddress !== 0">
				<p class="importStatusValue">{{ driverSummary.invalidAddress }}</p>
			</div>
		</div>
		<div class="importStatusRow" fxLayout="row">
			<div class="importDescriptionBackground">
				<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_VEHICLE_DRIVERSUMMARY' | translate }}</p>
			</div>
			<div class="importStatusValueBackground" [class.importStatusError]="driverSummary.invalidVehicle !== 0">
				<p class="importStatusValue">{{ driverSummary.invalidVehicle }}</p>
			</div>
		</div>
		<div class="importStatusRow" fxLayout="row">
			<div class="importDescriptionBackground">
				<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_VEHICLE_ASSIGNMENT' | translate }}</p>
			</div>
			<div class="importStatusValueBackground" [class.importStatusError]="driverSummary.invalidVehicleAssignment !== 0">
				<p class="importStatusValue">{{ driverSummary.invalidVehicleAssignment }}</p>
			</div>
		</div>
	</div>

	<div *ngIf="importType === dataTypesDefinition.vehicle && vehicleSummary" fxLayout="column">
		<div class="importStatusRow" fxLayout="row">
			<div class="importDescriptionBackground">
				<p class="importDescriptionValue">{{ 'IMPORT_STATUS_INVALID_LICENSEPLATE' | translate }}</p>
			</div>
			<div class="importStatusValueBackground" [class.importStatusError]="vehicleSummary.invalidLicensePlate !== 0">
				<p class="importStatusValue">{{ vehicleSummary.invalidLicensePlate }}</p>
			</div>
		</div>
	</div>
</div>

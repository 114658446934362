<div class="interfaceImportNotificationDiv">
	<div *ngIf="(interfaceService.$validImportLogs | async).length > 0" fxLayout="column" class="notificationRow">
		<div fxLayout="row">
			<div class="notificationRowValueBackground notificationGreen" fxLayout="row" fxLayoutAlign="center center">
				<p class="notificationValue">{{ (interfaceService.$validImportLogs | async).length }}</p>
			</div>
			<span class="importNotificatitionMessageText importNotificatitionMessageTextGreen">
				<span *ngIf="(interfaceService.$validImportLogs | async).length === 1">{{
					'VALID_IMPORT_LOGS_JOB_SUCCESS' | translate
				}}</span>
				<span *ngIf="(interfaceService.$validImportLogs | async).length > 1">{{
					'VALID_IMPORT_LOGS_JOBS_SUCCESS' | translate
				}}</span>
				{{ 'VALID_IMPORT_LOGS_SUCCESS' | translate }}
			</span>
		</div>
	</div>
	<div *ngIf="externalJobsProvider.$externalJobsCount | async" fxLayout="column" class="notificationRow">
		<div fxLayout="row">
			<div class="notificationRowValueBackground notificationRed" fxLayout="row" fxLayoutAlign="center center">
				<p class="notificationValue">{{ externalJobsProvider.$externalJobsCount | async }}</p>
			</div>
			<span class="importNotificatitionMessageText importNotificatitionMessageTextRed">
				<span *ngIf="(externalJobsProvider.$externalJobsCount | async) === 1">
					{{ 'VALID_IMPORT_LOGS_JOB_NOT_SUCCESS' | translate }}
				</span>
				<span *ngIf="(externalJobsProvider.$externalJobsCount | async) > 1">
					{{ 'VALID_IMPORT_LOGS_JOBS_NOT_SUCCESS' | translate }}
				</span>
				{{ 'VALID_IMPORT_LOGS_NOT_SUCCESS' | translate }}
			</span>
		</div>
		<div class="notificationButtonRow" fxLayout="row" fxLayoutAlign="center center">
			<button mat-button class="flatCancel" (click)="interfaceService.hideInterfaceNotificationDisplay()">
				{{ 'HIDE' | translate }}
			</button>
			<button mat-button class="flatOk" (click)="interfaceService.showExternalJobsDialog()">
				{{ 'CORRECT' | translate }}
			</button>
		</div>
	</div>
</div>

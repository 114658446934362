<div *ngIf="(menuProvider.$currentTab | async) === MENU.LOCATIONS">
	<app-location-table
		[isDataLoading]="locationService.$isInReceiveState | async"
		[companySettings]="companyProvider.$companySettings | async"
		[data]="locationService.$data | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettings.$userSettings | async"
	>
	</app-location-table>
</div>
<div *ngIf="(menuProvider.$currentTab | async) === MENU.RELATIONS">
	<app-relation-table
		[isDataLoading]="relationService.$isInReceiveState | async"
		[data]="relationService.$data | async"
		[companySettings]="companyProvider.$companySettings | async"
		[userProfile]="authFb.$userProfile | async"
		[userSettings]="userSettings.$userSettings | async"
		[vehicles]="vehicleProvider.$data | async"
	>
	</app-relation-table>
</div>
<div *ngIf="(menuProvider.$currentTab | async) === MENU.ADDRESS_CORRECTIONS">
	<address-correction-table
		[isDataLoading]="correctionService.$isInReceiveState | async"
		[data]="correctionService.$data | async"
		[userProfile]="authFb.$userProfile | async"
	>
	</address-correction-table>
</div>

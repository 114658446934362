import { TimetableJobEntry } from '../TimetableClasses';
import { TimeTable } from '../TimeTable';
import { setJobsTimetableBasicStyles } from './TimetableStyleFunctions';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

export function setJobEntryStyles(
	jobsEntries: TimetableJobEntry[],
	timetable: TimeTable,
	timetableWidth: number,
	userSettings: UserSettings,
) {
	setJobsTimetableBasicStyles(jobsEntries, timetable, timetableWidth);
	setJobEntriesTextLabels(jobsEntries, timetableWidth, userSettings);
}

export function setJobEntriesTextLabels(
	jobsEntries: TimetableJobEntry[],
	timetableWidth: number,
	userSettings: UserSettings,
) {
	jobsEntries.forEach((jobsEntry) => {
		try {
			const widthInPxl = timetableWidth * (parseInt(jobsEntry.wholeJobWidth) / 100);
			const firstPickUp = getFirstPickUp(jobsEntry.jobWrapper);
			let start = firstPickUp.position.city ? firstPickUp.position.city : firstPickUp.position.zip;
			let startPxl = start.length * 7.5;
			const lastDelivery = getLastDestination(jobsEntry.jobWrapper);
			let end = lastDelivery.position.city ? lastDelivery.position.city : lastDelivery.position.zip;
			if (!end) {
				console.error(
					`setJobEntriesTextLabels, missing city or zip for label. Aborting further settings and  setting default display name`,
				);
				end = '';
			}
			let endPxl = end.length * 7.5;
			const pxlDifference = startPxl + endPxl + 15 - widthInPxl;
			if (pxlDifference > 0) {
				const textCuts = Math.round(pxlDifference / 15);
				start = start.substring(0, start.length - textCuts);
				end = end.substring(0, end.length - textCuts);
			}
			jobsEntry.textLabel = `${start} ➝ ${end}`;
			if (userSettings.planningSettings.identifierJobDisplay) {
				jobsEntry.textLabel = `${
					jobsEntry.jobWrapper.job.identifier
				}, ${firstPickUp.position.toString()} --> ${lastDelivery.position.toString()}`;
			}
		} catch (e) {
			console.error(
				`setJobEntriesTextLabels, caught error in setting display name. Setting job label to identifier. Error: ${e}`,
			);
			jobsEntry.textLabel = `${jobsEntry.jobWrapper.job.identifier}`;
		}
	});
}

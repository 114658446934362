import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { AppBasicComponent } from '../app/components/app.components.basic.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-basic-sidenav',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('sidenavAnimation', [
			transition(':enter', [style({ width: 0, opacity: 0 }), animate('300ms', style({ width: 320, opacity: 1 }))]),
			transition(':leave', [style({ width: 320, opacity: 1 }), animate('300ms', style({ width: 0, opacity: 0 }))]),
		]),
	],
})
export class SidenavBasicComponent extends AppBasicComponent implements OnDestroy, OnChanges, AfterViewInit {
	@Input() isOpenTimetableToolbar: boolean;
	@Input() loading: boolean;
	@Output() closeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	protected ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

	constructor(protected cd: ChangeDetectorRef) {
		super();
	}

	ngAfterViewInit() {
		console.log(`SidenavBasicComponent OPENING :)`);
		this.cd.markForCheck();
	}

	ngOnDestroy() {
		console.log(`SidenavBasicComponent CLOSING :)`);
		this.resetDataOnClose();
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log(`SidenavBasicComponent, changes detected: ${Object.keys(changes)}`);
		this.calculateSidenavData();
	}

	closeSidenav() {
		this.closeChange.emit(true);
	}

	resetDataOnClose() {
		// Implement me in specific Component
	}

	calculateSidenavData() {
		// Implement me in specific Component
	}
}

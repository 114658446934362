import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlutaroTimetableFilterService } from '../data/timetable.data.provider';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommunicatorServerProvider } from '../../communicatorApp/communicator.server.provider';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { TimetableFilteredEntry } from '../data/TimetableFilterClasses';
import { JobAdjustmentService } from '../../job/job.adjustment.service';
import { getISODay, startOfDay } from 'date-fns';
import { DriverStatistics } from '../../statistics/Statistics';
import { WeekDayChange } from './TimetableComponentClasses';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';
import { RelationStatusFilterChange } from '../TimetableClasses';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-timetable-toolbar',
	templateUrl: './timetable.toolbar.component.html',
	styleUrls: ['./timetable.toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('toolbarAnimation', [
			transition(':enter', [style({ top: 0, opacity: 0 }), animate('500ms', style({ top: 64, opacity: 1 }))]),
			transition(':leave', [style({ top: 64, opacity: 1 }), animate('500ms', style({ top: 0, opacity: 0 }))]),
		]),
	],
})
export class TimetableToolbarComponent extends AppBasicComponent implements OnInit {
	@Input() timetableData: TimetableFilteredEntry[];
	@Input() driverStatistics: Map<string, DriverStatistics>;
	@Input() noDriverJobs: JobWrapper[];
	@Input() relations: Relation[];
	@Input() showPlanningMode: boolean;
	@Input() events: Map<string, FlutaroEvent[]>;
	@Input() dayOfWeek: number;
	@Output() planningModeChange: EventEmitter<boolean> = new EventEmitter();
	@Output() noteModeChange: EventEmitter<boolean> = new EventEmitter();
	@Output() weekDayChange: EventEmitter<WeekDayChange> = new EventEmitter<WeekDayChange>();
	showNotes: boolean;

	constructor(
		public timetableFilterService: FlutaroTimetableFilterService,
		private communicator: CommunicatorServerProvider,
		private notifications: FlutaroNotificationsProvider,
		private jobAdjustProvider: JobAdjustmentService,
		private translate: TranslateService,
	) {
		super();
	}

	ngOnInit() {
		this.showNotes = this.userSettings?.planningSettings.openNotesOnStart;
	}

	changePlanningMode() {
		this.showPlanningMode = !this.showPlanningMode;
		this.planningModeChange.emit(this.showPlanningMode);
	}

	changeNoteMode() {
		this.showNotes = !this.showNotes;
		this.noteModeChange.emit(this.showNotes);
	}

	filterForRelation(filterChange: RelationStatusFilterChange) {
		this.timetableFilterService.updateFilterStore(filterChange);
		this.timetableFilterService.createFilteredTimetableMap();
	}

	async sendJobsToAppUser() {
		this.communicator.sendJobChangesToDriver(this.timetableFilterService.$currentJobsToSend.getValue());
	}

	public goToToday() {
		const weekDayChange = new WeekDayChange(startOfDay(new Date()), getISODay(new Date()), true);
		this.weekDayChange.emit(weekDayChange);
	}

	public adjustOverlappingJobs() {
		const overlappingVehicles = this.timetableData.filter((entry) => entry.vehicleJobStatus.overlapping);
		if (!overlappingVehicles?.length) {
			this.notifications.showBasicSnackBar(this.translate.instant('JOB_OVERLAPPING_ADJUST_ERROR_NO-OVERLAPPING'));
			return;
		}
		this.jobAdjustProvider.adjustOverlappingJobs(this.timetableData, this.userSettings);
	}
}

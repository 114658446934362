<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground driverDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">person</i>
		{{ 'DRIVER' | translate }} <span class="dialogToolbarObjectName">{{ driver.firstName }} {{ driver.lastName }}</span>
	</h1>
</mat-toolbar>

<form (ngSubmit)="onSave()" [formGroup]="driverForm">
	<mat-dialog-content>
		<div class="layout-padding layout-margin" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
			<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column">
				<h4 class="dialogHeadlineContent">
					<i class="material-icons dialogIcon">assignment</i> {{ 'DRIVER_DATA' | translate }}
				</h4>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field fxFlex="100" fxFlex.gt-sm="30">
						<input formControlName="firstName" matInput name="firstName" placeholder="{{ 'FIRSTNAME' | translate }}" />
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="35">
						<input formControlName="lastName" matInput name="lastName" placeholder="{{ 'LASTNAME' | translate }}" />
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="30">
						<input formControlName="phone" matInput placeholder="{{ 'TELEPHONE' | translate }}" type="tel" />
					</mat-form-field>
					<div *ngIf="driver.isAppActivated">App-User</div>
				</div>
				<div fxLayout="row">
					<app-address-search [(synonym)]="driver.homeAddress" [required]="true" fxFlex="100"> </app-address-search>
				</div>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field class="matSelectDriverJobDialog" fxFlex="100" fxFlex.gt-sm="50">
						<mat-select
							[(ngModel)]="driver.license"
							[ngModelOptions]="{ standalone: true }"
							placeholder="{{ 'DRIVERS_LICENSE' | translate }}"
						>
							<mat-option *ngFor="let license of DriverLicenses" [value]="license">
								{{ license }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="matSelectDriverJobDialog" fxFlex="100" fxFlex.gt-sm="50">
						<mat-select
							[(ngModel)]="driver.trainings"
							[ngModelOptions]="{ standalone: true }"
							multiple
							placeholder="{{ 'TRAININGS' | translate }}"
						>
							<mat-option *ngFor="let driverQualification of DriverQualifications" [value]="driverQualification"
								>{{ driverQualification | translateDriverQualification }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input formControlName="email" matInput placeholder="{{ 'EMAIL_DRIVER' | translate }}" />
						<mat-error *ngIf="driverForm.controls['email'].errors?.email">{{
							'EMAIL_ENTER_VALID' | translate
						}}</mat-error>
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input
							[(ngModel)]="driver.emergencyPhone"
							[ngModelOptions]="{ standalone: true }"
							matInput
							placeholder="{{ 'TELEPHONE_EMERGENCY' | translate }}"
							type="tel"
						/>
					</mat-form-field>
				</div>
				<div fxLayout="row" fxLayoutGap="10px">
					<mat-form-field fxFlex="100">
						<input
							[(ngModel)]="driver.notes"
							[ngModelOptions]="{ standalone: true }"
							class="driverDialogNotes"
							matInput
							placeholder="{{ 'DRIVER_NOTE' | translate }}"
						/>
					</mat-form-field>
				</div>

				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<i class="material-icons dialogIconExpansionPanel">more_horiz</i>
								{{ 'DRIVER_ADVANCED_SETTINGS' | translate }}
							</mat-panel-title>
						</mat-expansion-panel-header>

						<div fxLayout="column">
							<mat-checkbox
								formControlName="keepCargoDuringAbsence"
								matTooltip="{{ 'DRIVERDIALOG_KEEPCARGODURINGABSENCE_TT' | translate }}"
							>
								{{ 'DRIVERDIALOG_KEEPCARGODURINGABSENCE' | translate }}
							</mat-checkbox>
							<div fxLayout="row">
								<p class="dividerDriverDialogDotted100"></p>
							</div>
							<mat-checkbox
								formControlName="goHomeEveryDay"
								matTooltip="{{ 'DRIVERDIALOG_GOHOMEEVERYDAY_TT' | translate }}"
							>
								{{ 'DRIVERDIALOG_GOHOMEEVERYDAY' | translate }}
							</mat-checkbox>
							<div fxLayout="row">
								<p class="dividerDriverDialogDotted100"></p>
							</div>
							<mat-checkbox
								[matTooltipPosition]="'below'"
								aria-label="driver isAlwaysOutside"
								class="mat-primary mat-checkbox-small"
								formControlName="isAlwaysOutside"
								fxFlex="100"
								fxFlex.gt-sm="50"
								mat-theme="flutaroGrey"
								matTooltip="{{ 'ISALWAYSOUTSIDE_DRIVER_TT' | translate }}"
							>
								{{ 'ISALWAYSOUTSIDE_DRIVER' | translate }}
							</mat-checkbox>
						</div>
					</mat-expansion-panel>

					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<i class="material-icons dialogIconExpansionPanel">work</i> {{ 'DRIVER_WORKWEEK_MAIN' | translate }}
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div fxLayout="row" fxLayoutGap="10px">
							<mat-slide-toggle
								(ngModelChange)="resetWorkweekSettings($event)"
								[(ngModel)]="driver.regularWorkweekDriver"
								[ngModelOptions]="{ standalone: true }"
							>
								<h5 *ngIf="driver.regularWorkweekDriver">
									{{ 'WORKWEEK_REGULAR' | translate }} <i class="material-icons driverDialogWorkweekIcon"></i>
								</h5>
								<h5 *ngIf="!driver.regularWorkweekDriver">
									{{ 'WORKWEEK_ALTERNATING' | translate }} <i class="material-icons driverDialogWorkweekIcon"></i>
								</h5>
							</mat-slide-toggle>
						</div>
						<div
							*ngIf="driver.regularWorkweekDriver"
							class="marginTop20 driverDialogWeekTimePicker"
							fxLayout="column"
							name="workweek-regular"
						>
							<div fxLayout="row" fxLayoutGap="10px">
								<span class="driverDateLabel">{{ 'FROM' | translate }}:</span>
								<app-weekday-time-picker
									[weekdayTimeObject]="driver.regularWorkweek.regularWorkweekStart"
								></app-weekday-time-picker>
							</div>
							<div fxLayout="row" fxLayoutGap="10px">
								<span class="driverDateLabel">{{ 'TILL' | translate }}:</span>
								<app-weekday-time-picker
									[weekdayTimeObject]="driver.regularWorkweek.regularWorkweekEnd"
								></app-weekday-time-picker>
							</div>
						</div>
						<div
							*ngIf="!driver.regularWorkweekDriver"
							class="marginTop20 driverDialogWeekTimePicker"
							fxLayout="column"
							fxLayoutGap="10px"
							name="alternatingWorkweek"
						>
							<div fxLayout="column" fxLayoutGap="10px">
								<span style="color: #505759">{{ 'WORKWEEK_ODD' | translate }}:</span>
								<div fxLayout="row">
									<span class="driverDateLabel">{{ 'FROM' | translate }}:</span>
									<app-weekday-time-picker
										[weekdayTimeObject]="driver.alternatingWorkWeek.oddWorkweekStart"
									></app-weekday-time-picker>
								</div>
								<div fxLayout="row">
									<span class="driverDateLabel">{{ 'TILL' | translate }}:</span>
									<app-weekday-time-picker
										[weekdayTimeObject]="driver.alternatingWorkWeek.oddWorkweekEnd"
									></app-weekday-time-picker>
								</div>
							</div>
							<div fxLayout="column" fxLayoutGap="10px">
								<span style="color: #505759">{{ 'WORKWEEK_EVEN' | translate }}:</span>
								<div fxLayout="row">
									<span class="driverDateLabel">{{ 'FROM' | translate }}:</span>
									<app-weekday-time-picker
										[weekdayTimeObject]="driver.alternatingWorkWeek.evenWorkweekStart"
									></app-weekday-time-picker>
								</div>
								<div fxLayout="row">
									<span class="driverDateLabel">{{ 'TILL' | translate }}:</span>
									<app-weekday-time-picker
										[weekdayTimeObject]="driver.alternatingWorkWeek.evenWorkweekEnd"
									></app-weekday-time-picker>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>

			<mat-divider></mat-divider>

			<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="10px" name="driverAppointments">
				<mat-toolbar fxLayout="row">
					<div class="mat-toolbar-tools">
						<i class="material-icons dialogToolBarAdd">today</i><span>{{ 'DRIVER_APPOINTMENTS' | translate }}</span>
					</div>
					<button
						(click)="createNewAppointment()"
						[matTooltipPosition]="'below'"
						class="mat-fab mat-flut"
						id="addDriverDialogAppointmentButton"
						mat-fab
						matTooltip="{{ 'DRIVER_APPOINTMENTS_CREATE_TT' | translate }}"
						name="addDriverAppointment"
					>
						<mat-icon class="material-icons fab-icon-add">
							<i class="material-icons addIconDialogButton"> add </i>
						</mat-icon>
					</button>
				</mat-toolbar>
				<div
					*ngIf="isAppointmentInput"
					class="driverDialogAppointmentDiv"
					fxLayout="column"
					fxLayoutGap="10px"
					name="newDriverAppointment"
				>
					<p *ngIf="!isAppointmentEdit" class="driverDialogAppointmentLabel">
						{{ 'DRIVER_APPOINTMENTS_NEW' | translate }}
					</p>
					<p *ngIf="isAppointmentEdit" class="driverDialogAppointmentLabel">
						{{ 'DRIVER_APPOINTMENTS_EDIT' | translate }}
					</p>
					<mat-form-field>
						<mat-select
							[(ngModel)]="inputAppointment.appointmentType"
							[ngModelOptions]="{ standalone: true }"
							fxFlex="100"
							placeholder="{{ 'APPOINTMENT_TYPE' | translate }}"
						>
							<mat-option *ngFor="let appointmentType of appointmentTypes" [value]="appointmentType.label">
								{{ appointmentType.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field fxFlex="100">
						<input
							[(ngModel)]="inputAppointment.note"
							[ngModelOptions]="{ standalone: true }"
							class="driverDialogNotes"
							matInput
							placeholder="{{ 'NOTES' | translate }}"
						/>
					</mat-form-field>
					<div *ngIf="inputAppointment.appointmentType === 'Privattermin'" fxLayout="row">
						<app-address-search [(synonym)]="inputAppointment.location" fxFlex="100"> </app-address-search>
					</div>
					<div
						*ngIf="
							inputAppointment.appointmentType !== 'Individueller Arbeitsbeginn' &&
							inputAppointment.appointmentType !== 'Individuelles Arbeitsende'
						"
						class="layout-padding"
						fxLayout="row"
						fxLayout.sm="column"
						fxLayout.xs="column"
						id="driverDialogDatepicker"
					>
						<app-datetimepicker
							(isDateValid)="isDateValid = $event"
							[(ngModel)]="inputAppointment.startDate"
							[ngModelOptions]="{ standalone: true }"
							[type]="'datetime'"
							placeholder="{{ 'BEGINNING' | translate }}"
						>
						</app-datetimepicker>

						<app-datetimepicker
							(isDateValid)="isDateValid = $event"
							[(ngModel)]="inputAppointment.endDate"
							[ngModelOptions]="{ standalone: true }"
							[type]="'datetime'"
							placeholder="{{ 'END' | translate }}"
						>
						</app-datetimepicker>
					</div>
					<div
						*ngIf="inputAppointment.appointmentType === 'Individueller Arbeitsbeginn'"
						class="layout-padding"
						fxLayout="row"
					>
						<span class="driverDateLabelAppointment">
							in <b>KW {{ inputAppointment.startDate | appFormatDate : 'week-of-year' }} </b>
						</span>

						<app-datetimepicker
							(isDateValid)="isDateValid = $event"
							[(ngModel)]="inputAppointment.startDate"
							[ngModelOptions]="{ standalone: true }"
							[type]="'datetime'"
							placeholder="{{ 'START_OF_WORK' | translate }}"
						>
						</app-datetimepicker>
					</div>
					<div
						*ngIf="inputAppointment.appointmentType === 'Individuelles Arbeitsende'"
						class="layout-padding"
						fxLayout="row"
					>
						<span class="driverDateLabelAppointment">
							in <b>KW {{ inputAppointment.startDate | appFormatDate : 'week-of-year' }} </b>
						</span>

						<app-datetimepicker
							(isDateValid)="isDateValid = $event"
							[(ngModel)]="inputAppointment.startDate"
							[ngModelOptions]="{ standalone: true }"
							[type]="'datetime'"
							placeholder="{{ 'END_OF_WORK' | translate }}"
						>
						</app-datetimepicker>
					</div>
					<div fxLayout="row" fxLayoutGap="10px" name="appointmentActionButtons">
						<div *ngIf="showAppointmentActionErrorMessage" class="errorMessage" fxLayoutGap="10px">
							{{ 'APPOINTMENT_ACTION_ERROR_MESSAGE' | translate }}
						</div>
						<div *ngIf="showAppointmentTypeErrorMessage" class="errorMessage" fxLayoutGap="10px">
							{{ 'APPOINTMENT_TYPE_ERROR_MESSAGE' | translate }}
						</div>
					</div>
					<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" name="appointmentActionButtons">
						<button (click)="cancelAppointmentInputs()" class="importButton mat-raised" mat-button>
							<mat-icon><i class="material-icons">close</i></mat-icon>
							{{ 'CANCEL' | translate }}
						</button>
						<button
							(click)="saveAppointmentInputs()"
							[disabled]="!isDateValid"
							class="importButtonDone mat-primary_flutaro"
							mat-button
						>
							<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
							{{ 'SAVE' | translate }}
						</button>
					</div>
				</div>
				<mat-list *ngIf="!isAppointmentInput" class="dialogMdList">
					<mat-list-item
						(click)="editAppointment(appointment)"
						*ngFor="let appointment of driver.appointments"
						class="mat-2-line dialogMdListItem"
					>
						<i class="material-icons driverDialogAppointmentIcon"></i>
						<div
							*ngIf="
								appointment.appointmentType === 'Individueller Arbeitsbeginn' ||
								appointment.appointmentType === 'Individuelles Arbeitsende'
							"
							class="mat-list-item-text"
						>
							<h3 class="dialogMdListTextH3">
								{{ appointment.appointmentType }} in
								<b>KW {{ appointment.startDate | appFormatDate : 'week-of-year' }}</b>
							</h3>
							<h4 class="dialogMdListTextH4">{{ appointment.startDate | appFormatDate : 'datetime' }}</h4>
						</div>
						<div
							*ngIf="
								appointment.appointmentType !== 'Individueller Arbeitsbeginn' &&
								appointment.appointmentType !== 'Individuelles Arbeitsende'
							"
							class="mat-list-item-text"
						>
							<h3 class="dialogMdListTextH3">{{ appointment.appointmentType }}</h3>
							<h4 class="dialogMdListTextH4">
								{{ appointment.startDate | appFormatDate : 'datetime' }} bis
								{{ appointment.endDate | appFormatDate : 'datetime' }}
							</h4>
						</div>
						<button (click)="deleteAppointment(appointment)" class="deleteButtonMdList mat-secondary" mat-button>
							<i class="dialogMdListDeleteIcon material-icons">delete</i>
						</button>
					</mat-list-item>
				</mat-list>
			</div>
		</div>
	</mat-dialog-content>
	<app-flutaro-audit
		[(tenant)]="driver.tenant"
		[companySettings]="dialogData.companySettings"
		[createdAt]="driver.createdAt"
		[createdBy]="driver.createdBy"
		[lastModifiedAt]="driver.lastModifiedAt"
		[lastModifiedBy]="driver.lastModifiedBy"
		[userProfile]="dialogData.userProfile"
	>
	</app-flutaro-audit>
	<!--SAVE AND CANCEL-->
	<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
		<button (click)="onAbort()" class="importButton mat-raised" mat-button type="button">
			<mat-icon><i class="material-icons">close</i></mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button
			class="importButtonDone mat-primary_flutaro"
			mat-button
			type="submit"
			[disabled]="driverForm.invalid || dialogData.userProfile.hasReadOnlyRole"
		>
			<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</form>

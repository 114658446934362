import { VEHICLE_EMISSION_TYPES } from '@flutaro/package/lib/model/VehicleConstants';
import { CostCalculationData } from '@flutaro/package/lib/model/costs/CostCalculation';

export function getVehiclesTrailersCountForPoseidonRequest(costData: CostCalculationData): number {
	if (costData.vehicle.trailerCount) {
		return costData.vehicle.trailerCount;
	} else {
		return 0;
	}
}

export function getEmissionTypeForPoseidonRequest(costData: CostCalculationData): VEHICLE_EMISSION_TYPES {
	if (costData.vehicle.emissionCategory && !isNaN(Number(costData.vehicle.emissionCategory))) {
		return costData.vehicle.emissionCategory;
	} else {
		console.debug(
			`getEmissionTypeForPoseidonRequest, error: vehicles emissionCategory is not valid. Setting default value`,
		);
		return VEHICLE_EMISSION_TYPES.EURO_VI;
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { AppPushProvider } from '../../app/push/app.push.provider';
import { FlutaroWebsocketService } from '../../app/push/app.push.websocket';
import { filter, lastValueFrom } from 'rxjs';
import { FlutaroDataService } from '../FlutaroService';
import { WebAuthService } from '../../app/auth/web-auth.service';
import { environment } from '../../../environments/environment';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { AtheneService } from '../../athene/athene.service';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { CompanyService } from '../../company/company.service';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { TimetableComponentProvider } from '../../timetable/data/timetable.component.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { TimeTable } from '../../timetable/TimeTable';
import { parseJob } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { sortJobsByFirstPickupDateAsc } from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';

@Injectable({
	providedIn: 'root',
})
export class FlutaroJobService extends FlutaroDataService<JobWrapper> {
	private bulkImportUrl: string = environment.routingApiURL + '/data/jobwrappers/bulk';

	constructor(
		public http: HttpClient,
		public websocketService: FlutaroWebsocketService,
		public pushProvider: AppPushProvider,
		public authFb: WebAuthService,
		private athene: AtheneService,
		public zone: NgZone,
		public companyService: CompanyService,
		private timetableProvider: TimetableComponentProvider,
	) {
		super(http, pushProvider, authFb, websocketService, zone, companyService);
		this.subscribeToTimetableRangeChanges();
	}

	protected initProvider() {
		this.url = environment.routingApiURL + '/data/jobwrappers';
		this.collectionName = FlutaroCollection.JOB;
		this.websocketService.jobSocket.subscribe((jobWrapperData) => {
			this.handleWebSocketAction(jobWrapperData);
		});
		this.websocketService.$websocketRecoveredFromFailingState.subscribe((isReconnected) => {
			if (!isReconnected) return;
			this.getDataFromServer();
		});
		this.authFb.$vehicleIdsFilter.subscribe(($vehicleIdsFilter) => {
			if (!$vehicleIdsFilter) return;
			this.$data.next(this.filterDataServiceSpecific(this.$data.getValue()));
		});
	}

	private subscribeToTimetableRangeChanges() {
		this.timetableProvider.$timetable
			.pipe(
				filter((timetable) => !!timetable),
				distinctUntilChanged((prev, curr) => {
					return prev?.rangeStart?.getTime() === curr.rangeStart.getTime();
				}),
			)
			.subscribe((timetable) => {
				console.log(
					`FlutaroJobService, loading data for new timetable range ${timetable.rangeStart} - ${timetable.rangeEnd}`,
				);
				this.getAndSetDataForTimetableRange(timetable);
			});
	}

	getAndSetDataForCurrentTimetable() {
		this.getAndSetDataForTimetableRange(this.timetableProvider.$timetable.getValue());
	}

	private getAndSetDataForTimetableRange(timetable: TimeTable) {
		this.updateGetURL(timetable.rangeStart, timetable.rangeEnd);
		this.getDataFromServer();
	}

	protected filterDataServiceSpecific(data: JobWrapper[]): JobWrapper[] {
		const readOnlyVehicleIdsFilter = this.authFb.$vehicleIdsFilter.getValue();
		if (!readOnlyVehicleIdsFilter) return data;
		console.log(
			`filterDataServiceSpecific, job filter for readOnly with ${readOnlyVehicleIdsFilter.length} readOnlyVehicleIdsFilter of ${readOnlyVehicleIdsFilter}`,
		);
		return data.filter((job) => readOnlyVehicleIdsFilter.includes(job.vehicleId));
	}

	async updateBulk(jobs: JobWrapper[]): Promise<boolean> {
		return lastValueFrom(this.http.put<boolean>(this.bulkImportUrl, jobs));
	}

	async storeAndPublish(job: JobWrapper, oldJob: JobWrapper, updateImmediately?: boolean): Promise<JobWrapper> {
		await super.store(job);
		await this.athene.handleJobStatusExternalSystemIntegration(job, oldJob);
		if (updateImmediately) {
			console.debug(`storeAndPublish, updateImmediately`);
			this.updateElement(job);
		}
		return job;
	}

	async bulkStoreAndPublish(jobs: JobWrapper[], oldJobs: JobWrapper[]): Promise<boolean> {
		let [updateBulkResult, publishBulkResult] = await Promise.allSettled([
			this.updateBulk(jobs),
			this.publishJobsBulk(jobs, oldJobs),
		]);
		if (updateBulkResult.status === 'rejected' || publishBulkResult.status === 'rejected') {
			console.error(
				`bulkStoreAndPublish in data.job.service, ERROR: ${JSON.stringify(updateBulkResult)} / ${JSON.stringify(
					publishBulkResult,
				)}`,
			);
			return false;
		}
		return true;
	}

	private async publishJobsBulk(jobs: JobWrapper[], oldJobs: JobWrapper[]): Promise<boolean> {
		for (let job of jobs) {
			const oldJob = oldJobs.find((oldJob) => oldJob.backendId === job.backendId);
			await this.athene.handleJobStatusExternalSystemIntegration(job, oldJob);
		}
		return true;
	}

	/**
	 * clear the internal data and update the query urls
	 */
	updateGetURL(start: Date, end: Date) {
		this.getUrl = `${environment.routingApiURL}/data/jobwrappers?start=${start.toISOString()}&end=${end.toISOString()}`;
	}

	async resetJobsDataInternallyOnly(job: JobWrapper) {
		this.removeWithoutSending(job);
		await flutaroWait(100);
		this.storeElement(job);
	}

	public parseElement(jobWrapper: JobWrapper): JobWrapper {
		return parseJob(jobWrapper);
	}

	protected prepareInternalData(jobs: JobWrapper[]): JobWrapper[] {
		return sortJobsByFirstPickupDateAsc(jobs);
	}

	public getJobsForVehicle(vehicleId: string): JobWrapper[] {
		return this.getData().filter((job) => job.vehicleId === vehicleId);
	}

	public getJobsForDriver(driverId: string): JobWrapper[] {
		return this.getData().filter((job) => job.driver === driverId);
	}

	getJobByIdentifier(identifier: string): JobWrapper {
		return this.$data.getValue().find((job) => job.job.identifier === identifier);
	}
}

<div
	class="flutaro-sidenav info-sidenav"
	[@sidenavAnimation]
	[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px)' : 'calc(100vh - 64px)'"
>
	<div *ngIf="loading" class="loadingIndicatorBackground">
		<div class="loadingIndicatorCircle"></div>
	</div>
	<mat-toolbar class="flutaroSidenavHeader">
		<div fxLayout="row">
			<button
				mat-button
				class="buttonSidenavMain closeButtonSidenavs"
				(click)="closeSidenav()"
				matTooltip="{{ 'CLOSE_TT' | translate }}"
				[matTooltipPosition]="'below'"
			>
				<i class="material-icons sidenavButtonIcon"> close </i>
			</button>
		</div>

		<h1 class="mat-toolbar-tools sidenavHeadlineInfo">
			<div class="sidenavToolbarHeadlineUnderline">
				<i class="material-icons sidenavToolbarHeadlineIcon">timeline</i>
				{{ 'STATISTICS' | translate }}
				<span *ngIf="!timetable.isDayTimetable" class="infoSidenavStatusValue">
					(KW {{ timetable.startDate | appFormatDate: 'week-of-year' }})
				</span>
				<span *ngIf="timetable.isDayTimetable" class="infoSidenavStatusValue">
					({{ timetable.startDate | appFormatDate: 'weekday-short' }})
				</span>
			</div>
		</h1>
	</mat-toolbar>
	<div
		class="sidenavFixedHeader layout-padding flutaro-sidenav-inner"
		fxLayout="column"
		[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px - 64px)' : 'calc(100vh - 64px - 64px)'"
	>
		<div class="info-box-sidenav loadedDistanceDashboard" fxLayout="row" fxLayoutAlign="start center">
			<i class="material-icons">linear_scale</i>
			<div fxLayout="column">
				<div class="count">
					{{ jobStatisticsSummary.emptyDistance + jobStatisticsSummary.loadedDistance | metersInKm }}
				</div>
				<div class="title">{{ 'TOTALDISTANCE' | translate }}</div>
			</div>
		</div>

		<div class="info-box-sidenav emptyDistanceDashboard" fxLayout="row" fxLayoutAlign="start center">
			<i class="material-icons">more_horiz</i>
			<div fxLayout="column">
				<div class="count">{{ jobStatisticsSummary.emptyDistance | metersInKm }}</div>
				<div class="title">
					{{ 'EMPTYDISTANCE' | translate }} ({{
						jobStatisticsSummary.emptyDistance /
							(jobStatisticsSummary.emptyDistance + jobStatisticsSummary.loadedDistance) | numberInPercentage
					}}
					%)
				</div>
			</div>
		</div>

		<div class="info-box-sidenav jobKPI" fxLayout="row" fxLayoutAlign="start center">
			<i class="material-icons">euro_symbol</i>
			<div fxLayout="column">
				<div class="count">
					{{ jobStatisticsSummary.totalRevenue | currency: 'EUR' }}
				</div>
				<div class="title">{{ 'REVENUE' | translate }}</div>
			</div>
		</div>

		<div class="info-box-sidenav jobKPI" fxLayout="row" fxLayoutAlign="start center">
			<i class="material-icons">timeline</i>
			<div fxLayout="column">
				<div class="count">
					{{ jobStatisticsSummary.totalProfit | currency: 'EUR' }}
				</div>
				<div class="title">
					{{ 'PROFIT' | translate }} ({{
						jobStatisticsSummary.totalProfit <= 0
							? 0
							: (jobStatisticsSummary.totalProfit / (jobStatisticsSummary.totalProfit + jobStatisticsSummary.totalCosts)
							  | numberInPercentage)
					}}
					%)
				</div>
			</div>
		</div>

		<div class="infoSidenavJobStatusDiv">
			<div
				fxLayout="row"
				class="infoSidenavLabelForTooltip"
				matTooltip="{{ 'INFO_SIDENAV_JOBSTATUSSTATISTICS_TT' | translate }}"
				[matTooltipPosition]="'below'"
			>
				<i class="material-icons infoSidenavIconJobBackground"></i>
				<i class="material-icons infoSidenavIconStatisticHead">description</i>
				<p
					class="infoSidenavStatisticLabel"
					fxLayout="row"
					fxLayoutAlign="center center"
					[class.infoSidenavFilterActive]="timetableFilterService.filterStatusStore.isJobStatusFilterActive()"
				>
					{{ 'JOB_STATUS_LABEL' | translate }}
					<span class="infoSidenavFilter" *ngIf="timetableFilterService.filterStatusStore.isJobStatusFilterActive()">
						<i class="material-icons infoSidenavFilterIcon">filter_list</i>{{ 'FILTER_IS_ACTIVE' | translate }}</span
					>
				</p>
			</div>

			<div fxLayout="column" class="infoSidenavNgxChartDiv">
				<ngx-charts-advanced-pie-chart
					[view]="[450, 250]"
					[customColors]="customColors"
					[results]="infoProvider.jobStatusStatistics"
					(select)="onJobChartSelect($event)"
				>
				</ngx-charts-advanced-pie-chart>
				<span class="chartLabelTotalLabel">{{ 'TOTAL' | translate }}</span>
			</div>
		</div>

		<div class="infoSidenavDriverStatusDiv">
			<div
				fxLayout="row"
				class="infoSidenavLabelForTooltip"
				matTooltip="{{ 'INFO_SIDENAV_DRIVERSTATUSSTATISTICS_TT' | translate }}"
				[matTooltipPosition]="'below'"
			>
				<i class="material-icons infoSidenavIconDriverBackground"></i>
				<i class="material-icons infoSidenavIconStatisticHead">local_shipping</i>
				<p
					class="infoSidenavStatisticLabel"
					fxLayoutAlign="center center"
					[class.infoSidenavFilterActive]="timetableFilterService.filterStatusStore.vehicleStatus"
				>
					{{ 'VEHICLE_STATUS' | translate }}
					<span class="infoSidenavFilter" *ngIf="timetableFilterService.filterStatusStore.vehicleStatus">
						<i class="material-icons infoSidenavFilterIcon">filter_list</i>{{ 'FILTER_IS_ACTIVE' | translate }}
					</span>
				</p>
			</div>

			<div fxLayout="column" class="infoSidenavNgxChartDiv">
				<ngx-charts-advanced-pie-chart
					[view]="[450, 250]"
					[customColors]="customColors"
					[results]="infoProvider.truckStatusStatistics"
					(select)="onVehicleChartSelect($event)"
				>
				</ngx-charts-advanced-pie-chart>
				<span class="chartLabelTotalLabel">{{ 'TOTAL' | translate }}</span>
			</div>
		</div>
		<div class="text-center">
			<button
				mat-button
				id="infoSidenavFilterResetButton"
				(click)="resetFilteredData()"
				[disabled]="(timetableFilterService.filterStatusStore.$isActiveFilterMode | async) === false"
			>
				<mat-icon>
					<i class="material-icons infoSidenavFilterResetIcon">replay</i>
				</mat-icon>
				{{ 'FILTER_RESET' | translate }}
			</button>
		</div>
	</div>
</div>

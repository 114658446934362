import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlutaroJobStatusComponent } from './flutaro-job-status/flutaro-job-status.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlutaroPipesModule } from '../../pipes/flutaro.pipes.module';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { JobStatusIconComponent } from './job-status-icon/job-status-icon.component';

@NgModule({
	declarations: [FlutaroJobStatusComponent, JobStatusIconComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatIconModule,
		TranslateModule,
		TranslateModule,
		FlutaroPipesModule,
		MatOptionModule,
		MatOptionModule,
		MatOptionModule,
		MatSelectModule,
		FormsModule,
		FlexModule,
	],
	exports: [FlutaroJobStatusComponent, JobStatusIconComponent],
})
export class FlutaroComponentsJobModule {}

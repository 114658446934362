import { Pipe, PipeTransform } from '@angular/core';
import { translateRequirement } from './AppTranslatePipeFunctions';
import {
	VEHICLE_CAR_TYPES,
	VEHICLE_TRAILER_TYPES,
	VEHICLE_TRUCK_TYPES,
	VEHICLE_TYPE,
	VEHICLE_TYPE_TRANSLATED,
} from '@flutaro/package/lib/model/VehicleConstants';
import { MinusPositionCorrection } from '@flutaro/package/lib/model/costs/Costs';
import { APP_ROUTING_PROFILE } from '@flutaro/package/lib/model/AppRoutingClasses';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { TranslateService } from '@ngx-translate/core';
import { IElement } from '@flutaro/package/lib/model/IElement';

@Pipe({
	name: 'translateVehicleType',
})
export class TranslateVehicleTypePipe implements PipeTransform {
	constructor(public translate: TranslateService) {}

	transform(vehicleType: VEHICLE_TYPE | string): VEHICLE_TYPE_TRANSLATED {
		return vehicleType?.length ? this.translate.instant(vehicleType) : '';
	}
}

@Pipe({
	name: 'translateRequirement',
})
export class TranslateRequirementsPipe implements PipeTransform {
	transform(requirement: string): string {
		return translateRequirement(requirement);
	}
}

@Pipe({
	name: 'translateDriverQualification',
})
export class TranslateDriverQualificationPipe implements PipeTransform {
	transform(requirement: string): string {
		return translateRequirement(requirement);
	}
}

@Pipe({
	name: 'calculateTotalAmountMinusPositionCorrections',
})
export class CalcualteAmountOfAllMinusPositionCorrectionsPipe implements PipeTransform {
	transform(corrections: MinusPositionCorrection[]): number {
		let totalAmount = 0;
		corrections.forEach((correction) => (totalAmount += correction.amount));
		return totalAmount;
	}
}

@Pipe({
	name: 'translateRoutingProfile',
})
export class TranslateRoutingProfile implements PipeTransform {
	constructor(public translate: TranslateService) {}

	transform(profile: APP_ROUTING_PROFILE): string {
		return this.translate.instant(`ROUTING_PROFILE_${profile.toUpperCase()}`);
	}
}

@Pipe({
	name: 'filterVehicleTypesForBasicType',
})
export class FilterVehicleTypesForType implements PipeTransform {
	transform(carTypes: VEHICLE_TYPE[], type: VEHICLE_TYPE): VEHICLE_TYPE[] {
		switch (type) {
			case VEHICLE_TYPE.CAR:
				return VEHICLE_CAR_TYPES;
			case VEHICLE_TYPE.TRUCK:
				return VEHICLE_TRUCK_TYPES;
			case VEHICLE_TYPE.TRAILER:
				return VEHICLE_TRAILER_TYPES;
		}
	}
}

@Pipe({
	name: 'filterVehiclesForTrailer',
})
export class FilterVehiclesForTrailersPipe implements PipeTransform {
	transform(vehicles: Vehicle[]): Vehicle[] {
		return vehicles.filter((vehicle) => VEHICLE_TRAILER_TYPES.includes(vehicle.carType));
	}
}

@Pipe({
	name: 'elementById',
})
export class GetElementByIdPipe implements PipeTransform {
	transform(elementId: string, elements: IElement[]): IElement {
		return elements.find((element) => element.backendId === elementId);
	}
}

<div
	[@sidenavAnimation]
	[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px)' : 'calc(100vh - 64px)'"
	class="flutaro-sidenav chat-sidenav"
>
	<mat-toolbar class="newJobsSidenavHeader">
		<button
			(click)="sidenavProvider.sidenavPop()"
			[matTooltipPosition]="'below'"
			class="buttonSidenavMain closeButtonSidenavs"
			mat-button
			matTooltip="{{ 'CLOSE_TT' | translate }}"
		>
			<i class="material-icons sidenavButtonIcon"> close </i>
		</button>
		<h1 class="mat-toolbar-tools sidenavHeadlineInfo">
			<div class="sidenavToolbarHeadlineUnderline">
				<i class="material-icons sidenavToolbarHeadlineIcon">chat_bubble_outline</i>
				Chat
			</div>
		</h1>
	</mat-toolbar>
	<div
		[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px - 64px)' : 'calc(100vh - 64px - 64px)'"
		class="sidenavFixedHeaderChat"
		id="chatSidenavFixedHeader"
	>
		<app-chat-menu
			(newMessagChange)="sendChatMessage($event)"
			(openJobChange)="openJob($event)"
			[channels]="chatProvider.$channels | async"
			[userProfile]="userProfile"
		>
		</app-chat-menu>
	</div>
</div>

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import localeDe from '@angular/common/locales/de';
import { AppModule } from './app/app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import { setPrototypeToStringFunctions } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { deactivateConsoleLogging } from '@flutaro/package/lib/functions/AppApplicationFunctions';
import { FlutaroChatChannel } from './app/chat/chat';

if (environment.production) {
	enableProdMode();
	deactivateConsoleLogging();
}

registerLocaleData(localeDe);

setPrototypeToStringFunctions();
FlutaroChatChannel.prototype.toString = function channelToString() {
	if (this.vehicle) {
		return this.vehicle.licensePlate;
	} else if (this.driver) {
		return this.driver.toString();
	} else if (this.email) {
		return this.email;
	} else {
		return this.name ? this.name : 'No-Channel-Name';
	}
};

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));

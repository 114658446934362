<div fxFlex="100%" fxLayout="row">
	<flutaro-job-status-icon [status]="status"></flutaro-job-status-icon>
	<mat-form-field fxFlex>
		<mat-select
			(ngModelChange)="statusChange.emit($event)"
			[(ngModel)]="status"
			[disabled]="!(userProfile | hasAphroditeAccess)"
			class="sideNavLabel"
			name="jobStatusSelect"
			placeholder="Status Filter"
		>
			<mat-option [value]="0" *ngIf="activateEmptyStatus"> </mat-option>
			<mat-option [value]="1" [disabled]="!deactivateDisabled">
				<flutaro-job-status-icon [status]="1"></flutaro-job-status-icon>
				{{ 'JOB_STATUS_NEW' | translate }}
			</mat-option>
			<mat-option [disabled]="!deactivateDisabled && !deactivatePlannedStatus" [value]="2">
				<flutaro-job-status-icon [status]="2"></flutaro-job-status-icon>
				{{ 'JOB_STATUS_PLANNED' | translate }}
			</mat-option>
			<div *ngIf="userProfile.hasAppActivatedRole">
				<mat-option [disabled]="!deactivateDisabled" [value]="3">
					<flutaro-job-status-icon [status]="3"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_SENT' | translate }}
					<div class="material-icons jobStatusSmartphoneIcon"></div>
				</mat-option>
				<mat-option [disabled]="!deactivateDisabled" [value]="4">
					<flutaro-job-status-icon [status]="4"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_RECEIVED' | translate }}
					<div class="material-icons jobStatusSmartphoneIcon"></div>
				</mat-option>
				<mat-option [disabled]="!deactivateDisabled" [value]="5">
					<flutaro-job-status-icon [status]="5"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_ACCEPTED' | translate }}
					<div class="material-icons jobStatusSmartphoneIcon"></div>
				</mat-option>
				<mat-option [disabled]="!deactivateDisabled" [value]="7">
					<flutaro-job-status-icon [status]="7"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_STARTED' | translate }}
					<div class="material-icons jobStatusSmartphoneIcon"></div>
				</mat-option>
				<mat-option [disabled]="!deactivateDisabled" [value]="6">
					<flutaro-job-status-icon [status]="6"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_DECLINED' | translate }}
					<div class="material-icons jobStatusSmartphoneIcon"></div>
				</mat-option>
			</div>
			<mat-option [disabled]="!deactivateDisabled && !vehicleId" [value]="8">
				<flutaro-job-status-icon [status]="8"></flutaro-job-status-icon>
				{{ 'JOB_STATUS_DONE' | translate }}
			</mat-option>
			<mat-option [disabled]="!deactivateDisabled && !vehicleId" [value]="16">
				<flutaro-job-status-icon [status]="16"></flutaro-job-status-icon>
				{{ 'JOB_STATUS_CANCELED' | translate }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>

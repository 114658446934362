import { Injectable, NgZone } from '@angular/core';
import { FlutaroDataService } from '../../data/FlutaroService';
import { environment } from '../../../environments/environment';
import { TimetableNote } from '@flutaro/package/lib/model/TimetableNote';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { getVehiclesNoteForReferenceDate, parseNote } from '@flutaro/package/lib/functions/NoteFunctions';
import { filter } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FlutaroWebsocketService } from '../../app/push/app.push.websocket';
import { AppPushProvider } from '../../app/push/app.push.provider';
import { WebAuthService } from '../../app/auth/web-auth.service';
import { AtheneService } from '../../athene/athene.service';
import { CompanyService } from '../../company/company.service';
import { TimetableComponentProvider } from '../data/timetable.component.service';
import { TimeTable } from '../TimeTable';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Injectable()
export class TimetableNoteProvider extends FlutaroDataService<TimetableNote> {
	constructor(
		public http: HttpClient,
		public websocketService: FlutaroWebsocketService,
		public pushProvider: AppPushProvider,
		public authFb: WebAuthService,
		private athene: AtheneService,
		public zone: NgZone,
		public companyService: CompanyService,
		private timetableProvider: TimetableComponentProvider,
	) {
		super(http, pushProvider, authFb, websocketService, zone, companyService);
		this.subscribeToTimetableRangeChanges();
	}

	async storeEditNote(note: TimetableNote, noteText: string, vehicleId: string) {
		note.latestEditDate = new Date();
		note.note = noteText;
		if (!note.backendId) {
			note.vehicleId = vehicleId;
		}
		return this.store(note);
	}

	initProvider() {
		this.url = environment.routingApiURL + '/data/notes';
		this.collectionName = FlutaroCollection.NOTE;
		this.websocketService.noteSocket.subscribe((relationData) => this.handleWebSocketAction(relationData));
		this.websocketService.$websocketRecoveredFromFailingState.subscribe((isReconnected) => {
			if (!isReconnected) return;
			console.log(
				`$websocketRecoveredFromFailingState, ${new Date().toISOString()} Notes Data reloaded. Triggered by $websocketRecoveredFromFailingState Subject.`,
			);
			this.getDataFromServer();
		});
	}

	private subscribeToTimetableRangeChanges() {
		this.timetableProvider.$timetable
			.pipe(
				filter((timetable) => !!timetable),
				distinctUntilChanged((prev, curr) => {
					return prev?.rangeStart?.getTime() === curr.rangeStart.getTime();
				}),
			)
			.subscribe((timetable) => {
				console.log(
					`TimetableNoteProvider, loading notes for new timetable range ${timetable.rangeStart} - ${timetable.rangeEnd}`,
				);
				this.getAndSetDataForTimetableRange(timetable);
			});
	}

	private getAndSetDataForTimetableRange(timetable: TimeTable) {
		this.updateGetURL(timetable.rangeStart, timetable.rangeEnd);
		this.getDataFromServer();
	}

	getNoteForVehicle(vehicle: Vehicle, calendarWeekDate: Date): TimetableNote {
		return getVehiclesNoteForReferenceDate(vehicle, calendarWeekDate, this.getData());
	}

	updateGetURL(start: Date, end: Date) {
		this.getUrl = `${environment.routingApiURL}/data/notes?cwsDate=${start.toISOString()}&cweDate=${end.toISOString()}`;
	}

	protected parseElement(note: TimetableNote): TimetableNote {
		return parseNote(note);
	}
}

import { Driver } from '@flutaro/package/lib/model/Driver';
import { Pipe, PipeTransform } from '@angular/core';
import {
	getDriversOutsideCurrentWeek,
	getDriversOutsideLastWeekEndForReferenceDate,
} from './DriverWeekEndOutsideFunctions';

@Pipe({
	name: 'isWeekEndOutsideLastWeek',
})
export class IsDriverWeekEndOutSideLastWeekPipe implements PipeTransform {
	transform(driver: Driver, refDate: Date): boolean {
		if (!driver) return false;
		return !!getDriversOutsideLastWeekEndForReferenceDate(driver, refDate);
	}
}

@Pipe({
	name: 'isWeekEndOutsideCurrentWeek',
})
export class IsDriverWeekEndOutSideCurrentWeekPipe implements PipeTransform {
	transform(driver: Driver, refDate: Date): boolean {
		if (!driver) return false;
		return !!getDriversOutsideCurrentWeek(driver, refDate);
	}
}

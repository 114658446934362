<div [@sidenavAnimation] class="flutaro-sidenav">
	<mat-toolbar class="driverSidenavHeader">
		<button
			(click)="sidenavProvider.sidenavPop()"
			[matTooltipPosition]="'below'"
			class="buttonSidenavMain closeButtonSidenavs"
			mat-button
			matTooltip="{{ 'CLOSE_TT' | translate }}"
		>
			<i class="material-icons sidenavButtonIcon"> close </i>
		</button>
		<h1 class="mat-toolbar-tools sideNavheadlineDriverLicensePlate">
			<div class="sidenavToolbarHeadlineUnderline">
				<i class="material-icons sidenavToolbarHeadlineIcon">local_shipping</i>
				{{ vehicle.toString() }}
				<span class="sideNavheadlineDriverName" *ngIf="!companySettings.isDriverDeactivated">
					{{
						(
							(vehicle.driverSchedules | scheduleForDate: timetable.selectedDate)?.entityId | elementById: drivers
						)?.toString()
					}}
				</span>
			</div>
		</h1>
		<div *ngIf="driver?.isAppActivated">App-User</div>
	</mat-toolbar>
	<div
		[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px - 64px)' : 'calc(100vh - 64px - 64px)'"
		class="sidenavFixedHeader marginTop5 flutaro-sidenav-inner"
		fxLayout="column"
		fxLayoutAlign="start center"
		fxLayoutGap="5px"
	>
		<div
			class="sidenavStatusAndActionsContainer"
			fxLayout="row"
			fxLayoutAlign="space-around center"
			*ngIf="!companySettings.isDriverDeactivated"
		>
			<div fxLayout="column" fxLayoutAlign="start center">
				<p class="driverSidenavStatusLabel">Status</p>
				<i *ngIf="true" [style.color]="'#44b599'" class="material-icons driverSidenavStatusIcon"> check_circle </i>
				<i *ngIf="false" [style.color]="'#ED6363'" class="material-icons driverSidenavStatusIcon"> cancel </i>
			</div>
			<div fxLayout="column">
				<div class="sidenavDriverWidget">
					<div class="info-box-driverSidenav drivingTimeDay">
						<i class="material-icons">timer</i>
						<div class="count">
							{{ driverStatistic.dailyDrivingTime | minutesToDrivingTime }}
						</div>
						<div class="title">{{ 'DRIVER_SIDENAV_DAILYDRIVINGTIME' | translate }}</div>
					</div>
				</div>
				<div class="sidenavDriverWidget">
					<div class="info-box-driverSidenav">
						<i class="material-icons">timer</i>
						<div class="count">
							{{ driverStatistic.weekDrivingTime | minutesToDrivingTime }}
						</div>
						<div class="title">{{ 'DRIVER_SIDENAV_WEEKDRIVINGTIME' | translate }}</div>
					</div>
				</div>
			</div>
		</div>

		<mat-divider></mat-divider>

		<div class="sidenavStatusAndActionsContainer" fxLayout="column" *ngIf="driver" [class.height40]="!driver?.block">
			<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center" class="height40">
				<i class="material-icons driverSidenavBlockIcon"></i>
				<p class="driverSidenavDescriptionValue">{{ 'BLOCK_DIALOG' | translate }}</p>
				<button
					(click)="openBlockDialog()"
					[disabled]="readonly"
					[matTooltipPosition]="'left'"
					class="mat-small"
					id="driverSidenavBlock"
					mat-button
					matTooltip="{{ 'BLOCK_DIALOG' | translate }}"
				>
					<i class="material-icons driverSidenav-block-icon">chevron_right</i>
				</button>
			</div>
			<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" class="marginLeft20">
				<div *ngIf="driver.block" class="driverBlocked" fxLayout="row" fxLayoutGap="10px">
					<i class="material-icons driverSidenavIcon">date_range</i>
					<span class="driverSidenavLabel">{{ 'BLOCK_ENDDATE_AVAILABLE' | translate }}:</span>
					<div>{{ driver.block.blockEndDate | appFormatDate: 'datetime' }}</div>
				</div>
				<div
					*ngIf="driver.block && driver.block.blockZip"
					class="driverBlocked"
					fxLayout="row"
					fxLayoutAlign="start center"
					fxLayoutGap="10px"
				>
					<i class="material-icons driverSidenavIcon">pin_drop</i>
					<span class="driverSidenavLabel">{{ 'BLOCK_BLOCKZIP_AVAILABLE' | translate }}:</span>
					<div>{{ driver.block.blockZip }}</div>
				</div>
				<div
					*ngIf="driver.block && driver.block.blockReasonDescription"
					class="driverBlocked"
					fxLayout="row"
					fxLayoutAlign="start start"
					fxLayoutGap="10px"
				>
					<i class="material-icons driverSidenavIcon">description</i>
					<span class="driverSidenavLabel">{{ 'BLOCK_REASONDESCRIPTION' | translate }}:</span>
					<div>{{ driver.block.blockReasonDescription }}</div>
				</div>
			</div>
		</div>
		<div class="sidenavStatusAndActionsContainer height40" fxLayout="row" fxLayoutAlign="space-around center">
			<i class="material-icons driverSidenavDriverVehicleStatusIcon">info_outline</i>
			<p class="driverSidenavStatusDescriptionValue">
				{{ 'VEHICLE_DRIVER_STATUS_CREATE' | translate }}
			</p>
			<button
				(click)="createStatusDialog()"
				[disabled]="readonly"
				[matTooltipPosition]="'left'"
				class="statusAdd"
				mat-button
				matTooltip="{{ 'VEHICLE_DRIVER_STATUS_CREATE' | translate }}"
			>
				<i class="material-icons sidenavStatusButtonIcon"> add </i>
			</button>
		</div>
		<div
			class="sidenavStatusAndActionsContainer height40"
			fxLayout="row"
			fxLayoutAlign="space-around center"
			*ngIf="driver"
		>
			<mat-slide-toggle
				[(ngModel)]="isWeekEndOutsideWeek"
				(ngModelChange)="changeIsWeekEndOutsideWeek()"
				[disabled]="driver.isAlwaysOutside"
				class="height40"
			>
				<span *ngIf="!driver.isAlwaysOutside" class="mat-checkbox-label">
					{{ isWeekEndOutsideWeek ? ('ISWEEKENDOUTSIDE_YES' | translate) : ('ISWEEKENDOUTSIDE_NO' | translate) }}
				</span>
				<span *ngIf="driver.isAlwaysOutside" class="mat-checkbox-label">
					{{ 'ISALWAYSOUTSIDE' | translate }}
				</span>
			</mat-slide-toggle>
		</div>

		<mat-divider></mat-divider>

		<!-- // TODO: add short driverDialog (only appointments) -->
		<!-- <div fxLayout="column" class="driverSidenavStatusDescriptionBackground">
        <div fxLayout="row" fxFlex="100">
            <p class="driverSidenavStatusDescriptionValue">
                <i class="material-icons driverSidenavAppointmentIcon"></i>Termin erstellen
            </p>
            <button mat-button class="statusAdd" (click)="createStatusDialog()"
                    matTooltip="Termin erstellen"
                    [matTooltipPosition]="'left'" >
                <i class="material-icons sidenavStatusButtonIcon">
                    add
                </i>
            </button>
        </div>
    </div> -->

		<mat-card class="flutaroSideNavComponentBasicStyle">
			<mat-card-content fxLayout="column" fxLayoutGap="10px">
				<div *ngIf="driver" fxLayout="column" fxLayoutGap="10px">
					<div class="driverHomeAddress" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">home</i>
							<span class="driverSidenavLabel">{{ 'DRIVER_ADDRESS' | translate }}:</span>
						</div>
						{{ driver.homeAddress.toString() }}
					</div>
					<div
						*ngIf="driver.email"
						class="driverSidenavPhone"
						fxLayout="row"
						fxLayoutAlign="start start"
						fxLayoutGap="10px"
					>
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">mail</i>
							<span class="driverSidenavLabel">{{ 'EMAIL_DRIVER' | translate }}:</span>
						</div>
						{{ driver.email }}
					</div>
					<div
						*ngIf="driver.phone"
						class="driverSidenavPhone"
						fxLayout="row"
						fxLayoutAlign="start start"
						fxLayoutGap="10px"
					>
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">phone_iphone</i>
							<span class="driverSidenavLabel">{{ 'TELEPHONE_DRIVER' | translate }}:</span>
						</div>
						{{ driver.phone }}
					</div>
				</div>

				<div fxLayout="column" fxLayoutGap="10px">
					<div
						class="driverHomeAddress"
						*ngIf="vehicle.depotAddress"
						fxLayout="row"
						fxLayoutAlign="start start"
						fxLayoutGap="10px"
					>
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">home</i>
							<span class="driverSidenavLabel">{{ 'VEHICLE_DEPOT_ADDRESS' | translate }}:</span>
						</div>
						{{ vehicle.depotAddress.toString() }}
					</div>
					<div
						*ngIf="vehicle.vehiclePhone"
						class="vehiclePhone"
						fxLayout="row"
						fxLayoutAlign="start start"
						fxLayoutGap="10px"
					>
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">phone</i>
							<span class="driverSidenavLabel">{{ 'TELEPHONE_VEHICLE' | translate }}:</span>
						</div>
						{{ vehicle.vehiclePhone }}
					</div>
					<div
						*ngIf="vehicle.vehicleEmail"
						class="vehicleEmail"
						fxLayout="row"
						fxLayoutAlign="start start"
						fxLayoutGap="10px"
					>
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">mail</i>
							<span class="driverSidenavLabel">{{ 'EMAIL_VEHICLE' | translate }}:</span>
						</div>
						{{ vehicle.vehicleEmail }}
					</div>
					<div class="driverSidenavVehicleType" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<i class="material-icons driverSidenavIcon">assignment</i>
							<span class="driverSidenavLabel">{{ 'VEHICLE_TYPE' | translate }}:</span>
						</div>
						{{ vehicle.carType | translateVehicleType }}
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<div
			class="driverNotesSidenav flutaroSideNavComponentBasicStyle"
			fxLayout="column"
			fxLayoutGap="10px"
			*ngIf="driver"
		>
			<div fxLayout="row" fxLayoutAlign="space-between center">
				<div fxLayout="row" fxLayoutAlign="start start">
					<i class="material-icons driverSidenavNotesIcon">comment</i>
					<span class="driverSidenavLabel">{{ 'NOTES' | translate }}:</span>
				</div>
				<div *ngIf="_notesIsEdited">
					<button
						(click)="onNoteSave()"
						[disabled]="readonly"
						[matTooltipPosition]="'left'"
						class="buttonSidenavNotes saveButtonNotesSidenavs"
						mat-button
						matTooltip="{{ 'SAVE' | translate }}"
					>
						<i class="material-icons sidenavButtonNotesIcon"> done </i>
					</button>
					<button
						(click)="onNoteAbort()"
						[matTooltipPosition]="'left'"
						class="buttonSidenavNotes cancelButtonNotesSidenavs"
						mat-button
						matTooltip="{{ 'CANCEL' | translate }}"
					>
						<i class="material-icons sidenavButtonNotesIcon"> close </i>
					</button>
				</div>
			</div>
			<mat-form-field style="width: 100%">
				<textarea [(ngModel)]="driver.notes" (ngModelChange)="onNoteChange()" [readonly]="readonly" matInput>
				</textarea>
			</mat-form-field>
		</div>
		<app-schedule-display
			[schedules]="vehicle.trailerSchedules"
			[type]="TYPES.TRAILER_SCHEDULE"
			[startDate]="timetable.startDate"
			[endDate]="timetable.endDate"
			[drivers]="drivers"
			[vehicles]="vehicles"
			*ngIf="vehicle.trailerSchedules?.length"
			class="flutaroSideNavComponentBasicStyle"
		></app-schedule-display>

		<app-schedule-display
			[schedules]="vehicle.driverSchedules"
			[type]="TYPES.DRIVER_SCHEDULE"
			[startDate]="timetable.startDate"
			[endDate]="timetable.endDate"
			[drivers]="drivers"
			[vehicles]="vehicles"
			*ngIf="vehicle.driverSchedules?.length"
			class="flutaroSideNavComponentBasicStyle"
		></app-schedule-display>

		<div *ngIf="vehicle.specialAttributes.length" class="driverSidenavRequirements flutaroSideNavComponentBasicStyle">
			<i class="material-icons driverSidenavIcon">assignment_turned_in</i>
			<span class="driverSidenavLabel">{{ 'VEHICLE_EQUIPMENT' | translate }}/{{ 'TRAININGS' | translate }}:</span>
			{{ vehicle.specialAttributes }}
		</div>
	</div>
</div>

<div *ngIf="!userProfile.hasDisableAllCreateDeleteRole && !userProfile.hasReadOnlyRole" fxLayoutAlign="end center">
	<app-job-add
		[clients]="clients"
		[companySettings]="companySettings"
		[drivers]="drivers"
		[userProfile]="userProfile"
		[userSettings]="userSettings"
		[vehicles]="vehicles"
	>
	</app-job-add>
</div>
<mat-toolbar
	class="mat-hue-2"
	fxLayout="row"
	fxLayoutAlign="start center"
	fxLayoutGap="10px"
	id="jobTableDataFirstToolbar"
	style="z-index: auto"
>
	<p class="timetableLabel margin-bottom-zero">{{ 'SHOW' | translate }}</p>

	<button
		(click)="showTodaysJobs()"
		[class.disabledStatisticButton]="currentTableDateRangeType === dashboardMode.TODAY"
		[disabled]="currentTableDateRangeType === dashboardMode.TODAY"
		[matTooltipPosition]="'below'"
		class="jobTableToolbarButton"
		mat-button
		matTooltip="{{ 'SHOW_JOBS_START_TODAY' | translate }}"
	>
		<mat-icon class="material-icons jobTableToolbarIcon">today</mat-icon>
		<div class="mainToolbarLabel">{{ 'TODAY' | translate }}</div>
	</button>

	<button
		(click)="showTomorrowsJobs()"
		[class.disabledStatisticButton]="currentTableDateRangeType === dashboardMode.TOMORROW"
		[disabled]="currentTableDateRangeType === dashboardMode.TOMORROW"
		[matTooltipPosition]="'below'"
		class="jobTableToolbarButton"
		mat-button
		matTooltip="{{ 'SHOW_JOBS_START_TOMORROW' | translate }}"
	>
		<img
			class="jobTableToolbarIconTomorrow jobTableToolbarIcon"
			src="assets/pictures/dataTables/jobTable/tomorrowIcon.svg"
		/>
		<div class="mainToolbarLabel">{{ 'TOMORROW' | translate }}</div>
	</button>

	<button
		(click)="showWeeksJobs()"
		[class.disabledStatisticButton]="currentTableDateRangeType === dashboardMode.WEEK"
		[disabled]="currentTableDateRangeType === dashboardMode.WEEK"
		[matTooltipPosition]="'below'"
		class="jobTableToolbarButton"
		mat-button
		matTooltip="{{ 'SHOW_JOBS_START_WEEK' | translate }}"
	>
		<mat-icon class="material-icons jobTableToolbarIcon">view_week</mat-icon>
		<div class="mainToolbarLabel">{{ 'WEEK' | translate }}</div>
	</button>

	<button
		(click)="showIndividualJobs()"
		[class.disabledStatisticButton]="currentTableDateRangeType === dashboardMode.INDIVIDUAL"
		[disabled]="currentTableDateRangeType === dashboardMode.INDIVIDUAL"
		[matTooltipPosition]="'below'"
		class="jobTableToolbarButton"
		mat-button
		matTooltip="{{ 'SHOW_JOBS_START_INDIVIDUAL' | translate }}"
	>
		<mat-icon class="material-icons jobTableToolbarIcon">filter_list</mat-icon>
		<div class="mainToolbarLabel">{{ 'INDIVIDUAL_ONLY' | translate }}</div>
	</button>
	<div fxLayout="row" id="jobTableDaterange">
		<app-date-range
			(dateChange)="getJobsDataForTimerange($event)"
			*ngIf="currentTableDateRangeType === dashboardMode.INDIVIDUAL"
			[type]="'job'"
		>
		</app-date-range>
	</div>
	<mat-divider class="flutaro-divider-table-menu"></mat-divider>
	<flutaro-job-status
		[activateEmptyStatus]="true"
		[userProfile]="userProfile"
		[deactivateDisabled]="true"
		(statusChange)="filterJobsForStatus($event)"
		class="width150"
	></flutaro-job-status>
</mat-toolbar>

<div class="wrapperJobTable table-container mat-elevation-z8">
	<div
		*ngIf="!isDataLoading && data.length === 0"
		class="addNewObjectBackground"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="addNewJobImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'JOBS' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'JOB_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_JOBS' | translate }}
			</p>
		</div>
	</div>
	<div *ngIf="isDataLoading" class="loadingIndicatorBackground">
		<div class="loadingIndicatorCircle"></div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_JOBS' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="job.status">
			<th *matHeaderCellDef mat-header-cell mat-sort-header [width]="'140px'">
				<i class="material-icons dataTableHeaderIcon">traffic</i>
				Status
			</th>
			<td *matCellDef="let jobWrapper" mat-cell [width]="'140px'">
				<div *ngIf="jobWrapper.job.status === 1" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_NEW' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 2" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_PLANNED' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 3" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_SENT' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 4" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_RECEIVED' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 5" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_ACCEPTED' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 6" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_DECLINED' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 7" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_STARTED' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 8" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_DONE' | translate }}
				</div>
				<div *ngIf="jobWrapper.job.status === 16" class="jobStatus-shorten-long-text">
					<flutaro-job-status-icon [status]="jobWrapper.job.status"></flutaro-job-status-icon>
					{{ 'JOB_STATUS_CANCELED' | translate }}
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="job.identifier">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">description</i> {{ 'JOB_IDENTIFIER' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>{{ jobWrapper.toString() }}</td>
		</ng-container>

		<ng-container matColumnDef="job.clientsName">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">business</i> {{ 'CLIENT' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>{{ jobWrapper.job.clientsName }}</td>
		</ng-container>

		<ng-container matColumnDef="vehicleLicensePlate">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">local_shipping</i>
				{{ 'VEHICLE' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>{{ jobWrapper.vehicleLicensePlate }}</td>
		</ng-container>

		<ng-container matColumnDef="destinations[0].position">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">place</i>
				{{ 'PICKUP' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>
				<span> {{ (jobWrapper | getFirstPickUp).position.toString(true) }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="destinations[0].plannedDate">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">date_range</i> {{ 'PICKUP_DATE' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>
				{{ (jobWrapper | getFirstPickUp).plannedDate | appFormatDate: 'weekday-date-short' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="destinations[#last].position">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">place</i>
				{{ 'DELIVERY' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>
				<span> {{ (jobWrapper | getLastDestination).position.toString(true) }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="destinations[#last].plannedDate">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">date_range</i> {{ 'DELIVERY_DATE' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" mat-cell>
				{{ (jobWrapper | getLastDestination).plannedDate | appFormatDate: 'weekday-date-short' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableJobsCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let jobWrapper"
				[class.hideElement]="userProfile.hasDisableJobsCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="
						jobWrapper.job.jobsRepeating
							? showRepeatingDeleteDialog(jobWrapper)
							: showDeleteDialogAndDeleteOnConfirm(jobWrapper, jobWrapper.job.identifier)
					"
					[disabled]="userProfile.hasDisableJobsCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let jobWrapper" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(jobWrapper)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons> </mat-paginator>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { DriverSchedule, SCHEDULE_TYPES, TrailerSchedule } from '@flutaro/package/lib/model/AppScheduleClasses';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	filterForTrailersMatchingTruckType,
	getVehiclesCurrentDriver,
} from '@flutaro/package/lib/functions/VehicleFunctions';
import { IElement } from '@flutaro/package/lib/model/IElement';
import {
	getAllSchedulesForElement,
	getScheduleForDate,
	getSchedulesInTimeRange,
} from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { isDateAfterOrInInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { isAfter } from 'date-fns';

@Pipe({
	name: 'availableInIntervalForSchedule',
	pure: false, // detect schedule.startDate changes and update list
})
export class AvailableInIntervalForSchedulePipe implements PipeTransform {
	transform(
		vehicles: Vehicle[],
		type: SCHEDULE_TYPES,
		schedule: DriverSchedule | TrailerSchedule,
		vehicle: Vehicle,
		drivers?: Driver[],
	): Driver[] | Vehicle[] {
		if (type === SCHEDULE_TYPES.DRIVER_SCHEDULE && !drivers?.length) return [];
		return (
			type === SCHEDULE_TYPES.DRIVER_SCHEDULE
				? (drivers as any[])
				: (filterForTrailersMatchingTruckType(vehicles, vehicle) as IElement[])
		).filter((element) => {
			const schedules = getAllSchedulesForElement(element.backendId, vehicles, type);
			if (!schedules?.length) return true;
			const blockingSchedules = schedules.filter((blockingSchedule) =>
				schedule.id !== blockingSchedule.id && blockingSchedule.endDate
					? isDateAfterOrInInterval(schedule.startDate, blockingSchedule.startDate, blockingSchedule.endDate)
					: isAfter(schedule.startDate, blockingSchedule.startDate),
			);
			return !blockingSchedules.length;
		});
	}
}

@Pipe({
	name: 'schedulesInRange',
})
export class FilterSchedulesForRangePipe implements PipeTransform {
	transform(
		schedules: DriverSchedule[] | TrailerSchedule[],
		startDate: Date,
		endDate: Date,
	): DriverSchedule[] | TrailerSchedule[] {
		if (!schedules?.length) return [];
		return getSchedulesInTimeRange(schedules, startDate, endDate);
	}
}

@Pipe({
	name: 'scheduleForDate',
})
export class GetScheduleForDatePipe implements PipeTransform {
	transform(schedules: DriverSchedule[] | TrailerSchedule[], date: Date): DriverSchedule | TrailerSchedule | undefined {
		console.log(`scheduleForDate for ${schedules?.length} schedules and date ${date}`);
		if (!schedules?.length) return undefined;
		return getScheduleForDate(schedules, date);
	}
}

@Pipe({
	name: 'currentDriver',
})
export class GetVehiclesCurrentDriverPipe implements PipeTransform {
	transform(vehicle: Vehicle, drivers: Driver[]): Driver | undefined {
		if (!vehicle.driverSchedules?.length) return undefined;
		return getVehiclesCurrentDriver(vehicle, drivers);
	}
}

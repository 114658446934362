<div
	*ngIf="status"
	class="jobStatusIcon"
	[class.jobAcceptIcon]="status === 5"
	[class.jobCancelIcon]="status === 16"
	[class.jobDeclinedIcon]="status === 6"
	[class.jobDoneIcon]="status === 8"
	[class.jobNewIcon]="status === 1"
	[class.jobPlannedIcon]="status === 2"
	[class.jobReceivedIcon]="status === 4"
	[class.jobSentIcon]="status === 3"
	[class.jobStartedIcon]="status === 7"
></div>

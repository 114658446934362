<mat-accordion class="sidenavDestination">
	<mat-expansion-panel
		*ngFor="let destination of jobWrapper.destinations; let destinationIndex = index"
		[class.margin-bottom10]="destinationIndex < jobWrapper.destinations.length - 1"
	>
		<mat-expansion-panel-header class="matPanelCustom" [class.height70]="destination.position.code">
			<mat-panel-title class="sidenavDestinationPanelTitle">
				<div
					[ngClass]="{
						pickupImage: destination.locationType === 'PICKUP',
						deliveryImage: destination.locationType === 'DELIVERY'
					}"
					class="jobDialogIconsPickupDeliverySidenav pickupImage"
				></div>
			</mat-panel-title>

			<mat-panel-description fxLayout="row" fxLayoutAlign="start center">
				{{ destination.toString() }}
			</mat-panel-description>
		</mat-expansion-panel-header>

		<ng-container *ngIf="destination.timeInMinutes > 0 && destination.distance > 0">
			<div class="jobEditSidenavRow" fxLayout="row" fxLayoutAlign="start center">
				<div class="flutaroDescriptionBackground width70" fxLayout="row" fxLayoutAlign="center center">
					<p class="jobEditSidenavDrivingTime">{{ 'DRIVING_TIME' | translate }}</p>
				</div>
				<div class="jobEditValueBackground marginRight5" fxLayout="row" fxLayoutAlign="center center">
					<p class="jobEditValue">{{ destination.timeInMinutes | minutesToDrivingTime }}</p>
				</div>
				<div class="jobEditValueBackground marginLeft5" fxLayout="row" fxLayoutAlign="center center">
					<p class="jobEditValue">{{ destination.distance | metersInKm }}</p>
				</div>
				<div class="flutaroDescriptionBackground width70" fxLayout="row" fxLayoutAlign="center center">
					<p class="jobEditSidenavDistance">{{ 'DISTANCE' | translate }}</p>
				</div>
			</div>
		</ng-container>
		<div
			[ngClass]="destination.locationType === 'PICKUP' ? 'sideNavPickupRow' : 'sideNavDeliveryRow'"
			fxLayout="column"
			fxLayoutGap="10px"
		>
			<app-job-destination-planning
				(destinationChange)="emitJobChange()"
				[destinationIndex]="destinationIndex"
				[destination]="destination"
				[disabled]="userProfile.hasReadOnlyRole"
				[job]="jobWrapper"
				class="margin0"
			>
			</app-job-destination-planning>
			<p *ngIf="destination.actionTimeInMinutes > 0" class="sideNavDestinationLabel">
				<mat-label class="actTimeInMin-mat-label">{{ 'REGULATINGTIMEINMINUTES' | translate }}</mat-label>
				-
				{{ destination.actionTimeInMinutes }}
			</p>

			<app-job-destination
				[destination]="destination"
				[isSidenav]="true"
				[userProfile]="userProfile"
				[showLocationTypeLabel]="false"
				[type]="'ALL'"
				[destinationIndex]="destinationIndex"
				(destinationChange)="emitJobChange()"
			>
			</app-job-destination>

			<p class="sideNavReferenceLabel">
				{{ 'REFERENCES' | translate }}
				: {{ destination.ingoingReferences.concat(destination.outgoingReferences).toString() }}
			</p>
		</div>
	</mat-expansion-panel>
</mat-accordion>

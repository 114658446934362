import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { FlutaroTableComponent } from '../table/table.component';
import { JobDialogComponent } from './form/job.dialog.component';
import { JobRepeatDeleteDialogComponent } from './form/job.repeat.delete.component';
import { JobRepeatingProvider } from './form/job.repeating.provider';
import {
	endOfISOWeek,
	endOfToday,
	endOfTomorrow,
	isWithinInterval,
	startOfISOWeek,
	startOfToday,
	startOfTomorrow,
} from 'date-fns';
import { CommunicatorServerProvider } from '../communicatorApp/communicator.server.provider';
import { DashboardStatisticsModes } from '../dashboard/DashboardClasses';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { DateInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { getDriverNameByDriverId } from '@flutaro/package/lib/functions/driver/DriverFunctions';
import { JobHelperService } from './job.helper.service';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Component({
	selector: 'app-job-table',
	templateUrl: './job.table.component.html',
	styles: ['.disabledStatisticButton {background: #707372; border: 2px solid #505759;}'],
	styleUrls: ['./../table/table.styles.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTableComponent extends FlutaroTableComponent implements OnDestroy {
	dashboardMode = DashboardStatisticsModes;
	currentTableDateRangeType: string = JobTableDateRangeTypes.today;
	startDateFilter: Date;
	endDateFilter: Date;
	jobStatusFilter: JobStatus | undefined;
	isChangedJobsData = false;

	constructor(
		public jobService: FlutaroJobService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public notifications: FlutaroNotificationsProvider,
		private repeatingProvider: JobRepeatingProvider,
		private appProvider: CommunicatorServerProvider,
		private jobHelperService: JobHelperService,
	) {
		super(dialog, jobService, translate);
	}

	initTableSettings() {
		this.elementTypeName = this.translate.instant('JOB');
		this.collectionName = FlutaroCollection.JOB;
		this.displayedColumns = [
			'job.status',
			'job.identifier',
			'job.clientsName',
			'vehicleLicensePlate',
			'destinations[0].position',
			'destinations[0].plannedDate',
			'destinations[#last].position',
			'destinations[#last].plannedDate',
			'edit',
			'delete',
		];
		this.showTodaysJobs();
		this.dataDialog = JobDialogComponent;
	}

	ngOnDestroy() {
		if (this.isChangedJobsData) {
			this.jobService.getAndSetDataForCurrentTimetable();
		}
	}

	async showDeleteDialogAndDeleteOnConfirm(data: JobWrapper, dataIdentifier: string) {
		const isDeleted = await super.showDeleteDialogAndDeleteOnConfirm(data, dataIdentifier);
		if (!isDeleted) return false;
		if (data.appSettings.driverUID) await this.appProvider.deleteJobFromAppUserBySystemDelete(data);
		return true;
	}

	getJobsDataForTimerange(interval: DateInterval) {
		this.startDateFilter = interval.start;
		this.endDateFilter = interval.end;
		this.isChangedJobsData = true;
		this.jobService.getDataFromServerForTimeRange(this.startDateFilter, this.endDateFilter);
	}

	filterJobsForStatus(status: JobStatus) {
		console.log(`filterJobsForStatus, status: ${status}`);
		this.jobStatusFilter = status;
		this.initDataSource(this.data);
	}

	showEditDialog(data: JobWrapper): void {
		this.jobHelperService.openJobEditDialog(data);
	}

	showRepeatingDeleteDialog(jobWrapper: JobWrapper) {
		let deleteConfig = new MatDialogConfig();
		deleteConfig.data = {
			dataType: 'Wiederholenden Auftrag',
			dataName: jobWrapper.job.identifier,
		};
		let dialogRef = this.dialog.open(JobRepeatDeleteDialogComponent, deleteConfig);
		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'abort') {
				return;
			}
			if (result === 'job') {
				this.showDeleteDialogAndDeleteOnConfirm(jobWrapper, jobWrapper.toString());
				return;
			}
			if (result === 'series') {
				deleteConfig.data = {
					dataType: 'Wiederholende Aufträge',
					dataName: jobWrapper.job.identifier,
				};
				let dialogRef = this.dialog.open(JobRepeatDeleteDialogComponent, deleteConfig);
				dialogRef.afterClosed().subscribe((result) => {
					if (!result) {
						return;
					}
					this.repeatingProvider.deleteRepeatingJobs(jobWrapper.job.jobsRepeating.repeatingID);
				});
			}
		});
	}

	async showTodaysJobs() {
		await this.checkAndLoadCurrentJobs();
		this.currentTableDateRangeType = JobTableDateRangeTypes.today;
		this.startDateFilter = startOfToday();
		this.endDateFilter = endOfToday();
		this.initDataSource(this.data);
	}

	showTomorrowsJobs() {
		this.checkAndLoadCurrentJobs();
		this.currentTableDateRangeType = JobTableDateRangeTypes.tomorrow;
		this.startDateFilter = startOfTomorrow();
		this.endDateFilter = endOfTomorrow();
		this.initDataSource(this.data);
	}

	showWeeksJobs() {
		this.checkAndLoadCurrentJobs();
		this.currentTableDateRangeType = JobTableDateRangeTypes.week;
		this.startDateFilter = startOfISOWeek(new Date());
		this.endDateFilter = endOfISOWeek(new Date());
		this.initDataSource(this.data);
	}

	showIndividualJobs() {
		this.currentTableDateRangeType = JobTableDateRangeTypes.individual;
	}

	async checkAndLoadCurrentJobs() {
		if (!this.isChangedJobsData) return;
		await this.jobService.getAndSetDataForCurrentTimetable();
		this.isChangedJobsData = false;
	}

	protected dataFilter(data: JobWrapper[]) {
		if (!data) return [];
		return data.filter(
			(job) =>
				isWithinInterval(getFirstPickUp(job).plannedDate, new DateInterval(this.startDateFilter, this.endDateFilter)) &&
				(!this.jobStatusFilter || job.job.status === this.jobStatusFilter),
		);
	}

	protected buildDataSearchString(jobWrapper: JobWrapper) {
		return (
			jobWrapper.toString() +
			JSON.stringify(jobWrapper.attributes) +
			jobWrapper.job.clientsName +
			getFirstPickUp(jobWrapper).position.toString() +
			getLastDestination(jobWrapper).position.toString() +
			getDriverNameByDriverId(jobWrapper.driver, this.drivers)
		);
	}
}

const JobTableDateRangeTypes = {
	today: 'today',
	tomorrow: 'tomorrow',
	week: 'week',
	individual: 'individual',
};

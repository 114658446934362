<div class="table-container mat-elevation-z8">
	<div *ngIf="!data.length" class="addNewObjectBackground" fxLayout="column" fxLayoutAlign="center center">
		<div class="addNewLocationImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'ADDRESS_CORRECTIONS' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'ADDRESS_CORRECTION_NO_DATA' | translate }}</p>
		</div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_CORRECTIONS' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="state">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">pin_drop</i>
				Status
			</th>
			<td *matCellDef="let correction" [width]="'125px'" mat-cell>
				<i
					[ngClass]="{
						correctionApproved: correction.state !== 'PENDING',
						correctionPending: correction.state === 'PENDING'
					}"
					class="material-icons dataTableHeaderIcon"
				>
					{{ correction.state === 'PENDING' ? 'help' : 'check_circle_outline' }}</i
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="targetAddressLine">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">pin_drop</i>
				{{ 'ADDRESS_CORRECTION_CORRECTED' | translate }}
			</th>
			<td *matCellDef="let correction" mat-cell>
				{{ correction.targetAddressLine }} , {{ correction.targetZip }} {{ correction.targetCity }}
			</td>
		</ng-container>

		<ng-container matColumnDef="sourceAddressLine">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">home</i>
				{{ 'ADDRESS_CORRECTION_BEFORE' | translate }}
			</th>
			<td *matCellDef="let correction" mat-cell>
				{{ correction.sourceAddressLine }} , {{ correction.sourceZip }} {{ correction.sourceCity }}
			</td>
		</ng-container>

		<ng-container matColumnDef="approve">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIconApprove">thumb_up_alt</i> {{ 'ACTIVATE' | translate }}
			</th>
			<td *matCellDef="let correction" [width]="'125px'" mat-cell>
				<button
					(click)="activateAddressCorrection(correction)"
					[disabled]="correction.state === 'APPROVED'"
					class="approveButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">thumb_up_alt</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="authFb.getUserProfile().hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i>
				{{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let correction"
				[class.hideElement]="authFb.getUserProfile().hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(correction, correction.sourceAddressLine)"
					[disabled]="authFb.getUserProfile().hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>
	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons></mat-paginator>
</div>

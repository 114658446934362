import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlutaroDatetimepickerComponent } from './flutaro-datepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { DateAdapter } from '@angular/material/core';
import { FlutaroDateAdapter } from './FlutaroDateAdapter';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [FlutaroDatetimepickerComponent],
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		MatTooltipModule,
		MatDatetimepickerModule,
		ReactiveFormsModule,
		TranslateModule,
	],
	exports: [FlutaroDatetimepickerComponent],
	providers: [{ provide: DateAdapter, useClass: FlutaroDateAdapter }],
})
export class FlutaroDatetimepickerModule {}

<mat-expansion-panel fxLayout="column" fxFlexFill>
	<mat-expansion-panel-header>
		<mat-panel-title>New-Job title settings</mat-panel-title>
		<mat-panel-description>
			{{ 'Adjust the displayed information of new jobs' | translate }}
		</mat-panel-description>
	</mat-expansion-panel-header>
	<div fxLayout="column" fxLayoutGap="10px">
		<mat-form-field *ngIf="false">
			<!-- TODO: replace userSettings.identifierJobDisplay with settings.newJobsTitle-->
			<!-- <mat-label>{{ 'TIMETABLE_NEWJOB_TITLE_LABEL' | translate }}</mat-label>
      <mat-select
        [(ngModel)]="settings.newJobsTitle"
        fxFlexFill
        (valueChange)="changeJobsTitle($event, 'newJobsTitle')"
      >
        <mat-option [value]="JOB_DISPLAY_TITLE.IDENTIFIER">
          {{ 'JOB_IDENTIFIER' | translate }}
        </mat-option>
        <mat-option [value]="JOB_DISPLAY_TITLE.CITIES">
          {{ 'DESTINATION_CITIES' | translate }}
        </mat-option>
        <mat-option [value]="JOB_DISPLAY_TITLE.DURATION_DISTANCE">
          {{ 'TIME_AND_DISTANCE' | translate }}
        </mat-option>
			</mat-select> -->
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ 'TIMETABLE_NEWJOB_SUBTITLE_LABEL' | translate }}</mat-label>
			<mat-select
				[(ngModel)]="settings.newJobsSubtitle"
				fxFlexFill
				(valueChange)="changeJobsTitle($event, 'newJobsSubtitle')"
			>
				<!-- <mat-option [value]="JOB_DISPLAY_TITLE.IDENTIFIER">
          {{ 'JOB_IDENTIFIER' | translate }}
        </mat-option> -->
				<mat-option [value]="JOB_DISPLAY_TITLE.CITIES">
					{{ 'DESTINATION_CITIES' | translate }}
				</mat-option>
				<mat-option [value]="JOB_DISPLAY_TITLE.DURATION_DISTANCE">
					{{ 'TIME_AND_DISTANCE' | translate }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</mat-expansion-panel>

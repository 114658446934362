import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { DialogModule } from '../dialog/dialog.module';
import { TableModule } from '../table/table.module';
import { SynonymModule } from '../synonym/synonym.module';
import { DriverAddComponent } from './driver.add.component';
import { DriverDialogComponent } from './driver.dialog.component';
import { DriverTableComponent } from './driver.table.component';
import { PipesModule } from '../app/pipes.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IsDriverWeekEndOutSideCurrentWeekPipe, IsDriverWeekEndOutSideLastWeekPipe } from './driver.pipes';

@NgModule({
	declarations: [
		DriverDialogComponent,
		DriverTableComponent,
		DriverAddComponent,
		IsDriverWeekEndOutSideLastWeekPipe,
		IsDriverWeekEndOutSideCurrentWeekPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		TableModule,
		SynonymModule,
		DialogModule,
		PipesModule,
		MatTableModule,
		MatPaginatorModule,
	],
	exports: [
		DriverDialogComponent,
		DriverTableComponent,
		DriverAddComponent,
		IsDriverWeekEndOutSideLastWeekPipe,
		IsDriverWeekEndOutSideCurrentWeekPipe,
	],
	providers: [],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class DriverModule {}

import { Injectable } from '@angular/core';

import {
	areIntervalsOverlapping,
	endOfDay,
	endOfISOWeek,
	isWithinInterval,
	startOfDay,
	startOfISOWeek,
} from 'date-fns';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { APP_MENU } from '../MenuClasses';
import { isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { transformSearchString } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';

export class AdvanceSearchOption {
	showJobsWithActionOnChoosenDay: boolean;
	showAllJobsOfDriver: boolean;
	referenceDate: Date;
	searchScope: boolean;
	currentTab: APP_MENU;
}

@Injectable()
export class FlutaroSearchService {
	listOfAttributeToExclude: string[] = [
		'backendId',
		'__type',
		'driver',
		'license',
		'emissionCategory',
		'version',
		'lastModifiedBy',
		'company',
		'timetableSettings',
		'attributes',
		'appUserEmail',
		'createdAt',
		'lastModifiedAt',
		'createdBy',
		'vehicleProfile',
		'costCalculation',
		'appSettings',
		'tenant',
		'trailerSchedules',
		'driverSchedules',
		'cargo',
	];

	public query = {
		filter: '',
	};

	setQueryFilter(query: string) {
		this.query.filter = query;
	}

	public clearSearch(): void {
		this.query.filter = '';
	}

	public isSearchMatchingVehicleData(
		vehicle: Vehicle,
		contractor: Contractor,
		trailer: Vehicle[] | undefined,
		driver: Driver | undefined,
		search: string,
		advanceFilterOpts?: AdvanceSearchOption,
	): boolean {
		let isSearchMatch = this.searchForQueryInObject(vehicle, search, advanceFilterOpts);
		if (contractor) isSearchMatch = isSearchMatch || this.searchForQueryInObject(contractor, search, advanceFilterOpts);
		if (trailer?.length)
			isSearchMatch = isSearchMatch || this.searchForQueryInObject(trailer, search, advanceFilterOpts);
		if (driver) isSearchMatch = isSearchMatch || this.searchForQueryInObject(driver, search, advanceFilterOpts);
		return isSearchMatch;
	}

	public searchInJobs(jobs: JobWrapper[], keyword, advanceFilterOpts?: AdvanceSearchOption): JobWrapper[] {
		let jobForSearch = jobs.filter((x) => this.searchForQueryInObject(x, keyword, advanceFilterOpts));
		return jobForSearch.length && advanceFilterOpts.showAllJobsOfDriver ? jobs : jobForSearch;
	}

	protected searchForQueryInObject(dataElement: any, query: string, advanceFilterOpts?: AdvanceSearchOption): boolean {
		if (!dataElement || !query?.length) return false;
		const keyword = transformSearchString(query);
		let keepElement = false;
		const elementsSearchKeys = Object.keys(dataElement).filter(
			(elementsSearchKey) => !this.listOfAttributeToExclude.includes(elementsSearchKey),
		);
		elementsSearchKeys.forEach((key) => {
			let keysValue = dataElement[key];
			if (!keysValue || (typeof keysValue !== 'object' && typeof keysValue !== 'string')) return;
			if (typeof keysValue === 'string') {
				if (transformSearchString(keysValue).includes(keyword)) keepElement = true;
				return;
			}

			if (key === 'destinations') {
				if (
					(advanceFilterOpts.showJobsWithActionOnChoosenDay || !advanceFilterOpts.searchScope) &&
					!isWithinInterval(advanceFilterOpts.referenceDate, {
						start: startOfDay(getFirstPickUp(dataElement).plannedDate),
						end: endOfDay(getLastDestination(dataElement).plannedDate),
					})
				) {
					return;
				}
				keysValue.forEach((destination) => {
					if (this.searchForQueryInObject(destination, keyword, advanceFilterOpts)) {
						keepElement = true;
					}
				});
				return;
			}

			// Run the search recursively again for Objects within Objects and check if they match the keyword
			if (typeof keysValue === 'object') {
				if (isValidDate(keysValue)) return;
				if (this.searchForQueryInObject(keysValue, keyword, advanceFilterOpts)) {
					if (
						'appointmentType' in dataElement &&
						(advanceFilterOpts.showJobsWithActionOnChoosenDay ||
							advanceFilterOpts.currentTab === APP_MENU.WEEK_PLANNING)
					) {
						if (advanceFilterOpts.currentTab === APP_MENU.DAY_PLANNING) advanceFilterOpts.searchScope = false;
						if (!advanceFilterOpts.searchScope) {
							keepElement = isWithinInterval(advanceFilterOpts.referenceDate, {
								start: startOfDay(dataElement.startDate),
								end: endOfDay(dataElement.endDate),
							});
						} else if (advanceFilterOpts.searchScope && advanceFilterOpts.currentTab === APP_MENU.WEEK_PLANNING) {
							keepElement = areIntervalsOverlapping(
								{
									start: startOfDay(dataElement.startDate),
									end: endOfDay(dataElement.endDate),
								},
								{
									start: startOfISOWeek(advanceFilterOpts.referenceDate),
									end: endOfISOWeek(advanceFilterOpts.referenceDate),
								},
							);
						}
					} else {
						keepElement = true;
					}
				}
			}
		});
		return keepElement;
	}
}

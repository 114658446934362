import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { WebAuthInterceptor } from './web-auth-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WebAuthService } from './web-auth.service';
import { AuthLoginComponent } from './auth.login.component';
import { FirebaseUIModule } from 'firebaseui-angular';

@NgModule({
	declarations: [AuthLoginComponent],
	imports: [BrowserModule, FirebaseUIModule],
	exports: [AuthLoginComponent],
	providers: [
		WebAuthService,
		WebAuthInterceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: WebAuthInterceptor,
			multi: true,
		},
	],
})
export class AuthFbModule {}

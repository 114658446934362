import { isAfter, isSameISOWeek, startOfISOWeek, subWeeks } from 'date-fns';
import { Driver, WeekEndOutside } from '@flutaro/package/lib/model/Driver';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { getJobsLastDeliveryDate, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';
import { getDriversLastJobOfWeekForDate } from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';

export function getDriversOutsideLastWeekEndForReferenceDate(
	driver: Driver,
	referenceDate: Date,
): WeekEndOutside | undefined {
	if (!driver?.weekEndOutsides?.length) return undefined;
	return driver.weekEndOutsides.find(
		(weekEndOutside) => weekEndOutside.date && isSameISOWeek(weekEndOutside.date, subWeeks(referenceDate, 1)),
	);
}

export function getDriversOutsideCurrentWeek(driver: Driver, referenceDate: Date): WeekEndOutside | undefined {
	if (!driver?.weekEndOutsides?.length) return undefined;
	return driver.weekEndOutsides.find(
		(weekEndOutside) => weekEndOutside.date && isSameISOWeek(weekEndOutside.date, referenceDate),
	);
}

export function changeDriversWeekEndOutSide(
	driver: Driver,
	isJobRemove: boolean,
	referenceDate: Date,
	jobs: JobWrapper[],
	job?: JobWrapper,
): boolean {
	const outSideDate = startOfISOWeek(referenceDate);
	const currentWeekEndOutside = getDriversOutsideCurrentWeek(driver, outSideDate);
	if (
		!isJobRemove &&
		job &&
		currentWeekEndOutside?.jobDate &&
		isAfter(currentWeekEndOutside.jobDate, getJobsLastDeliveryDate(job))
	) {
		return false;
	}
	let calculationJobs = job ? jobs.concat(job) : jobs;
	if (isJobRemove) calculationJobs = calculationJobs.filter((calcJob) => calcJob.backendId !== job?.backendId);
	const lastWeeksJob = getDriversLastJobOfWeekForDate(calculationJobs, outSideDate, driver.backendId);
	const destination = lastWeeksJob ? getLastDestination(lastWeeksJob) : null;
	if (!destination) return false;
	const weekEndOutside = new WeekEndOutside(
		outSideDate,
		destination.position.addressLine,
		destination.position.zip,
		destination.position.city,
		destination.position.country,
		destination.position.latitude,
		destination.position.longitude,
		lastWeeksJob?.backendId,
		lastWeeksJob ? getJobsLastDeliveryDate(lastWeeksJob) : undefined,
	);
	driver.weekEndOutsides = driver.weekEndOutsides.filter(
		(weekEndOutside) => weekEndOutside.date?.getTime() !== outSideDate.getTime(),
	);
	driver.weekEndOutsides.push(weekEndOutside);
	return true;
}

export function deleteWeekEndOutSide(driver: Driver, date: Date) {
	driver.weekEndOutsides = driver.weekEndOutsides.filter((outside) => outside.date?.getTime() !== date.getTime());
}

import { TimetableFilteredEntry } from './TimetableFilterClasses';
import { TimetableBlockEntry, TimetableJobEntry, TimetableTimewindowEntry, timetableHeight } from '../TimetableClasses';
import { getJobsRelationsForTimetableColoring, getRelationForZip } from '../../relation/RelationFunctions';
import { TimeTable } from '../TimeTable';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { TimetableEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import {
	getFirstPickUp,
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
	getLastDestination,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';

export function setTimetableEntryColorStyle(entry: TimetableFilteredEntry, index: number) {
	if (entry.vehicleJobStatus.overlapping && !entry.vehicleEntry.vehicle.isSpot) {
		// attention: We need to add here ALL css-classes because of the "[CLASS]="mapEntry.entryBackgroundClass" in HTML
		entry.entryBackgroundClass = 'driverIsOverlapping';
	} else if (entry.driverEntry?.isWeekEndOutsideCurrentWeek && !entry.driverEntry?.driver.isAlwaysOutside) {
		entry.entryBackgroundClass = 'driverIsCurrentWeekEndOutside';
	} else {
		index % 2 === 0 ? (entry.entryBackgroundClass = 'even') : (entry.entryBackgroundClass = 'odd');
	}
}

export function returnStyledTimetableEntries(filteredData: TimetableFilteredEntry[]) {
	filteredData.forEach((data, index) => {
		setTimetableEntryColorStyle(data, index);
	});
	return filteredData;
}

/**
 * Manipulates the given Jobs by setting their style-color based on the given Relations.
 * @param jobs
 * @param relations
 */
export function setJobsTimetableColorByRelation(jobs: TimetableJobEntry[], relations: Relation[]) {
	jobs.forEach((jobEntry) => {
		const jobsRelations = getJobsRelationsForTimetableColoring(jobEntry.jobWrapper, relations);
		if (!jobsRelations.length) return;
		jobEntry.showDarkText = jobsRelations.some((relation) => relation.darkTextColor);
		jobsRelations.forEach((relation, index) => {
			jobEntry['capacityColor' + (index + 1)] = relation.color;
			if (index === jobsRelations.length - 1) {
				if (jobsRelations.length === 1) {
					jobEntry.color = jobEntry.capacityColor1;
				} else if (jobsRelations.length === 2) {
					jobEntry.color =
						'repeating-linear-gradient(to right, ' + jobEntry.capacityColor1 + ' , ' + jobEntry.capacityColor2 + ')';
				} else {
					jobEntry.color =
						'repeating-linear-gradient(to right, ' +
						jobEntry.capacityColor1 +
						' , ' +
						jobEntry.capacityColor2 +
						' , ' +
						jobEntry.capacityColor3 +
						')';
				}
			}
		});
	});
}

export function setTimetableEntriesOverlappingStyles(entry: TimetableFilteredEntry) {
	// TODO: make the vehicleEntry calculation process stateless and call on every job change (drag, preview, dispatch ....)
	if (!entry.vehicleJobStatus.overlapping) {
		return;
	}
	console.debug(
		`setTimetableEntriesOverlappingStyles, vehicle ${entry.vehicleEntry.vehicle.licensePlate} has total overlapping${entry.vehicleJobStatus.overlapping}`,
	);
	entry.height = (entry.vehicleJobStatus.overlapping + 1) * timetableHeight + 'px';
	entry.jobsEntry.forEach((jobEntry) => {
		console.debug(
			`setTimetableEntriesOverlappingStyles, job ${jobEntry.jobWrapper.job.identifier} has overlapping ${jobEntry.overlapping}`,
		);
		if (!jobEntry.overlapping) return;
		jobEntry.marginTop = jobEntry.overlapping * timetableHeight + 'px';
	});
}

export function setBlockEntryColor(blockEntry: TimetableBlockEntry, relations: Relation[]) {
	if (!blockEntry.block.blockZip) return;
	const relation = getRelationForZip(blockEntry.block.blockZip, 'DE', relations);
	if (!relation) return;
	// We allow 2 colors maximum
	if (blockEntry.capacityColor1 && blockEntry.capacityColor2) return;
	blockEntry.capacityColor1
		? (blockEntry.capacityColor2 = relation.color)
		: (blockEntry.capacityColor1 = relation.color);
}

export function setJobsTimetableBasicStyles(
	jobsEntries: TimetableJobEntry[],
	timetable: TimeTable,
	timetableWidthInPxl: number,
) {
	jobsEntries.forEach((jobsEntry) => {
		jobsEntry.marginLeft = timetable.getUnitForTimetable(
			timetable.getMarginByDateForObjectInTimetable(getJobsFirstPickupDate(jobsEntry.jobWrapper)),
			null,
		);
		jobsEntry.wholeJobWidth = timetable.getUnitForTimetable(
			timetable.calculateObjectsWidth(
				getFirstPickUp(jobsEntry.jobWrapper).plannedDate,
				getJobsLastDeliveryDate(jobsEntry.jobWrapper),
			),
			null,
		);
		let width = timetable.calculateObjectsWidth(
			getJobsFirstPickupDate(jobsEntry.jobWrapper),
			getLastDestination(jobsEntry.jobWrapper).plannedDate,
		);
		jobsEntry.width = timetable.getUnitForTimetable(
			timetable.calculateObjectsWidth(
				getJobsFirstPickupDate(jobsEntry.jobWrapper),
				getJobsLastDeliveryDate(jobsEntry.jobWrapper),
			),
			width,
		);
		if (timetable.isDayTimetable) {
			// Calculate from relative to total pixel for comparisson
			const widthInPxl = timetableWidthInPxl * (parseInt(jobsEntry.wholeJobWidth) / 100);
			jobsEntry.hideTimewindow = widthInPxl < 60;
		} else {
			jobsEntry.hideTimewindow = width < 60;
		}
	});
}

export function setTimetableEventsStyles(events: TimetableEvent[], timetable: TimeTable) {
	if (!events.length) return;

	events.forEach((event) => {
		event.marginLeft = timetable.getUnitForTimetable(
			timetable.getMarginByDateForObjectInTimetable(event.startTime),
			null,
		);
		event.width = timetable.getUnitForTimetable(timetable.calculateObjectsWidth(event.startTime, event.endTime), null);
	});
}

/**
 * Returns an Timewindowentry for a given Job with style settings set
 */
export function calculateTimewindowEntryStyles(
	jobEntry: TimetableJobEntry,
	timetable: TimeTable,
): TimetableTimewindowEntry {
	let timewindowEntry: TimetableTimewindowEntry = new TimetableTimewindowEntry(jobEntry);
	timewindowEntry.marginLeft = timetable.getUnitForTimetable(
		timetable.getMarginByDateForObjectInTimetable(getFirstPickUp(jobEntry.jobWrapper).earliestDate),
		null,
	);
	timewindowEntry.width = timetable.getUnitForTimetable(
		timetable.calculateObjectsWidth(
			getFirstPickUp(jobEntry.jobWrapper).earliestDate,
			getLastDestination(jobEntry.jobWrapper).latestDate,
		),
		null,
	);
	timewindowEntry.color = jobEntry.color;
	return timewindowEntry;
}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QuillModule } from 'ngx-quill';

import { ClientModule } from '../client/client.module';
import { ContractorModule } from '../contractor/contractor.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DialogModule } from '../dialog/dialog.module';
import { DriverModule } from '../driver/driver.module';
import { ImportModule } from '../import/import.module';
import { InterfaceModule } from '../interfaces/interfaces.module';
import { JobModule } from '../job/job.module';
import { MenuModule } from '../menu/menu.module';
import { NotificationModule } from '../notifications/notification.module';
import { RelationModule } from '../relation/relations.module';
import { SidenavModule } from '../sidenav/sidenav.module';
import { SynonymModule } from '../synonym/synonym.module';
import { TableModule } from '../table/table.module';
import { TimetableModule } from '../timetable/timetable.module';
import { VehicleModule } from '../vehicle/vehicle.module';
import { SettingsModule } from '../settings/settings.module';
import { FlutaroAppComponent } from './app.component';
import { FlutaroCustomMaterialModule } from './app.material2.module';
import { AppRoutingModule } from './app.routing.module';
import { FlutaroErrorHandler } from './FlutaroErrorHandler';
import { WebsocketModule } from './push/push.module';
import { HotTableModule, HotTableRegisterer } from '@handsontable/angular';
import { AuthFbModule } from './auth/auth.fb.module';
import { firebaseUiAuthConfig } from './auth/firebaseUi';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { FirebaseUIModule } from 'firebaseui-angular';
import { CommunicatorModule } from '../communicatorApp/communicator.module';
import { ChatModule } from '../chat/chat.module';
import { environment } from '../../environments/environment';
import { CorrectionModule } from '../interfaces/ExternalJob/correction/correction.module';
import { IntegrationsModule } from '../integrations/integrations.module';
import { CostsModule } from '../costs/costs.module';
import { PipesModule } from './pipes.module';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { MapModule } from '../map/map.module';
import { EventModule } from '../event/event.module';
import { CompanyModule } from '../company/company.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlutaroPipesModule } from 'flutaro-lib-angular/lib/pipes/flutaro.pipes.module';
import { ComponentsModule } from './components/components.module';
import { AppFormModule } from '../form/form.module';
import { AppTokenForceRefreshInterceptor } from './app-token-force-refresh-interceptor.service';
import { AppHttpRetryInterceptor } from 'flutaro-lib-angular/lib/interceptor/app-http-retry-interceptor';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MatIconModule } from '@angular/material/icon';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const FlutaroTooltipDefaults: MatTooltipDefaultOptions = {
	showDelay: 1000,
	hideDelay: 0,
	touchendHideDelay: 1000,
};

@NgModule({
	imports: [
		BrowserModule,
		HotTableModule.forRoot(),
		HttpClientModule,
		MatIconModule,
		ComponentsModule,
		FlutaroPipesModule,
		AppRoutingModule,
		AuthFbModule,
		TranslateModule.forRoot(),
		AngularFireModule.initializeApp(environment.firebaseConfig),
		//provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideFirestore(() => getFirestore()),
		FirebaseUIModule.forRoot(firebaseUiAuthConfig),
		AngularFireAuthModule,
		AngularFirestoreModule,
		BrowserAnimationsModule,
		FlutaroCustomMaterialModule,
		CommonModule,
		WebsocketModule,
		SynonymModule,
		MenuModule,
		TimetableModule,
		RelationModule,
		JobModule,
		FormsModule,
		NotificationModule,
		InterfaceModule,
		DashboardModule,
		ImportModule,
		TableModule,
		ClientModule,
		DriverModule,
		VehicleModule,
		DialogModule,
		SidenavModule,
		ContractorModule,
		InterfaceModule,
		QuillModule.forRoot(),
		SettingsModule,
		NgxChartsModule,
		CommunicatorModule,
		ChatModule,
		AppFormModule,
		MccColorPickerModule.forRoot({}),
		CorrectionModule,
		IntegrationsModule,
		CostsModule,
		PipesModule,
		MapModule,
		EventModule,
		CompanyModule,
	],
	providers: [
		{
			provide: LOCALE_ID,
			useValue: 'de-DE', // 'de-DE' for Germany, 'fr-FR' for France ...
		},
		{
			provide: ErrorHandler,
			useClass: FlutaroErrorHandler,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpRetryInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppTokenForceRefreshInterceptor,
			multi: true,
		},
		HotTableRegisterer,
		{
			provide: 'TranslateService',
			useClass: TranslateService, // 'de-DE' for Germany, 'fr-FR' for France ...
		},
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: FlutaroTooltipDefaults },
	],
	bootstrap: [FlutaroAppComponent],
	declarations: [FlutaroAppComponent],
})
export class AppModule {
	constructor() {}
}

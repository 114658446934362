<div fxLayout="column">
	<mat-toolbar fxLayoutAlign="center center">
		<h1 class="mat-toolbar-tools dialogHeadline">
			<i class="material-icons dialogIconBackground driverBlockDialogIconBackground"></i>
			<i class="material-icons dialogIconToolbar">block</i>{{ 'DRIVER_VEHICLE_BLOCK' | translate }}
		</h1>
	</mat-toolbar>
	<mat-dialog-content fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
		<div
			[class.blockDialogIsBlocked]="block && block.blocked"
			class="blockDialogBlockStatus"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutGap="10px"
		>
			<div fxLayout="column" fxLayoutAlign="center center">
				<p class="blockDialogStatusLabelToggle">{{ 'AVAILABLE' | translate }}</p>
			</div>
			<div fxLayout="column" fxLayoutAlign="center center">
				<mat-slide-toggle [(ngModel)]="block && block.blocked">
					{{ 'BLOCK' | translate }}
				</mat-slide-toggle>
			</div>
		</div>
		<div *ngIf="block && block.blocked" fxLayout="column" fxLayoutAlign="start center">
			<p class="dividerSidenavDotted"></p>
			<mat-form-field fxFlex="100">
				<input
					#postalCode
					[(ngModel)]="block.blockZip"
					[matTooltipPosition]="'below'"
					matInput
					matTooltip="{{ 'BLOCK_ZIP_CODE_TT' | translate }}"
					maxlength="5"
					pattern="[0-9]{5}"
					placeholder="{{ 'BLOCK_ZIP_CODE' | translate }}"
					type="tel"
				/>
				<mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
			</mat-form-field>
		</div>
		<div *ngIf="block && block.blocked" class="driverNotesBlockReasonDescription" fxLayout="column">
			<mat-form-field>
				<textarea
					[(ngModel)]="block.blockReasonDescription"
					[cdkAutosizeMaxRows]="5"
					[cdkAutosizeMinRows]="1"
					[cdkTextareaAutosize]="true"
					[matTooltipPosition]="'below'"
					matInput
					matTooltip="{{ 'BLOCK_REASON_TT' | translate }}"
					placeholder="{{ 'BLOCK_REASON' | translate }}"
				>
				</textarea>
			</mat-form-field>
		</div>
		<mat-checkbox *ngIf="block && block.blocked" [(ngModel)]="isBlockedFor24Hours">
			{{ 'BLOCK_FOR_DAY' | translate }}
		</mat-checkbox>
		<div *ngIf="block && block.blocked && !isBlockedFor24Hours" fxLayout="column">
			<app-datetimepicker
				(isDateValid)="isDateValid = $event"
				[(ngModel)]="block.blockStartDate"
				[type]="'datetime'"
				placeholder="{{ 'BLOCK_START_DATE' | translate }}"
			>
			</app-datetimepicker>

			<app-datetimepicker
				(isDateValid)="isDateValid = $event"
				[(ngModel)]="block.blockEndDate"
				[type]="'datetime'"
				placeholder="{{ 'BLOCK_END_DATE' | translate }}"
			>
			</app-datetimepicker>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
		<button (click)="onAbort()" class="buttonCancel mat-raised" mat-button>
			<mat-icon><i class="material-icons">close</i></mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button (click)="saveBlock()" [disabled]="!isDateValid" class="buttonDone mat-primary_flutaro" mat-button>
			<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</div>

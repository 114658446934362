<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground relationDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">grain</i>
		{{ 'RELATION' | translate }} <span class="dialogToolbarObjectName">{{ relation.name }}</span>
	</h1>
</mat-toolbar>

<mat-dialog-content>
	<div class="layout-padding layout-margin" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
		<div fxFlex="100" fxLayout="column">
			<h4 class="dialogHeadlineContent">
				<i class="material-icons dialogIcon">assignment</i> {{ 'RELATION_DATA' | translate }}
			</h4>
			<div fxLayout="row">
				<mat-form-field fxFlex="30">
					<input [(ngModel)]="relation.name" matInput name="name" placeholder="Name" required />
				</mat-form-field>
				<mat-form-field class="inputDividerRow" fxFlex="70">
					<input
						[(ngModel)]="relation.description"
						matInput
						name="description"
						placeholder="{{ 'DESCRIPTION' | translate }}"
					/>
				</mat-form-field>
			</div>

			<div fxLayout="row">
				<h4 class="dialogHeadlineContentNotFirst">
					<i class="material-icons dialogIcon">color_lens</i> {{ 'COLOR' | translate }}
				</h4>
			</div>
			<div fxLayout="row">
				<mcc-color-picker
					[mccConnectedColorPickerOrigin]="trigger"
					hideUsedColors="true"
					mccConnectedColorPicker
					style="margin-right: 20px; margin-top: 10px"
				>
				</mcc-color-picker>
				<mat-form-field>
					<input
						#trigger="mccColorPickerOrigin"
						[(ngModel)]="relation.color"
						matInput
						mccColorPickerOrigin
						placeholder="{{ 'BACKGROUND_COLOR' | translate }}"
					/>
				</mat-form-field>
				<div class="marginTop10" fxLayout="row">
					<p class="relationFontLabelOuter">{{ 'LIGHT_FONT' | translate }}</p>
					<mat-slide-toggle [(ngModel)]="relation.darkTextColor">
						<p class="relationFontLabelInner">{{ 'DARK_FONT' | translate }}</p>
					</mat-slide-toggle>
				</div>

				<p class="marginLeft20">Preview:</p>
				<div class="inputDividerRow" fxLayout="row">
					<div [style.background]="relation.color" class="jobsNewRelationDialog">
						<div class="labelJob">
							<div [class.labelJobDark]="relation.darkTextColor" class="labelJobPickupAndDeliveryPlaces" fxLayout="row">
								<div class="labelJobAlignRight" fxFlex>
									<span> Berlin </span>
								</div>
								<div class="labelJobSeperator">
									<span>➝</span>
								</div>
								<div class="labelJobAlignLeft" fxFlex>
									<span> München </span>
								</div>
							</div>
							<div [class.labelJobTimewindowDark]="relation.darkTextColor" class="labelJobTimewindow" fxLayout="row">
								<div class="labelJobAlignRight" fxFlex>
									<span> 08:00 </span>
								</div>
								<div class="labelJobSeperator">
									<span>➝</span>
								</div>
								<div class="labelJobAlignLeft" fxFlex>
									<span> 20:00 </span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h4 class="dialogHeadlineContentNotFirst">
				<i class="material-icons dialogIcon">color_lens</i> {{ 'ASSIGNMENT_MECHANISMN' | translate }}
			</h4>

			<div fxLayout="column">
				<p class="dialogRadioLabel">{{ 'ASSIGNED_VEHICLE_IN_RELATION' | translate }}:</p>
				<div fxFlex fxLayout="row" fxLayoutGap="20px">
					<mat-slide-toggle
						[ngModel]="relation.assignedVehicleIDs && relation.assignedVehicleIDs.length"
						class="marginTop15"
						disabled
					>
						<span class="mat-checkbox-label">
							{{
								(relation.assignedVehicleIDs.length
									? 'ASSIGNED_VEHICLE_IN_RELATION_MANUAL'
									: 'ASSIGNED_VEHICLE_IN_RELATION_AUTOMATED'
								) | translate
							}}
						</span>
					</mat-slide-toggle>
					<mat-form-field fxFlex>
						<mat-select
							[(ngModel)]="relation.assignedVehicleIDs"
							multiple
							placeholder="{{ 'ASSIGNED_VEHICLES' | translate }}"
						>
							<mat-option *ngFor="let vehicle of dialogData.vehicles" [value]="vehicle.backendId">
								{{ vehicle.licensePlate }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<p class="dialogRadioLabel">{{ 'RELATION_MODE' | translate }}:</p>
				<div fxLayout="row" fxLayoutGap="10px">
					<mat-radio-group [(ngModel)]="relation.mode" fxLayout="row" fxLayoutGap="10px">
						<mat-radio-button [value]="'PickUp'" class="mat-primary">
							{{ 'PICKUP' | translate }}
						</mat-radio-button>
						<mat-radio-button [disabled]="true" [value]="'Delivery'" class="mat-primary">
							{{ 'DELIVERY' | translate }}
						</mat-radio-button>
						<mat-radio-button [value]="'PickUpAndDelivery'" class="mat-primary">
							{{ 'PICKUP_AND_DELIVERY' | translate }}
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<h4 class="dialogHeadlineContentNotFirst" style="margin-top: 30px; margin-bottom: 0px">
					<i class="material-icons dialogIcon">home</i> {{ 'TERRITORY_ASSIGNMENT' | translate }}
				</h4>

				<div
					*ngIf="relation.mode === 'PickUpAndDelivery' || relation.mode === 'PickUp'"
					class="fullWidth marginTop20"
					fxLayout="column"
				>
					<div fxFlex="100" fxLayout="row">
						<div class="jobDialogIconsPickupDelivery pickupImage"></div>

						<app-country-select [(country)]="relation.pickUpCountry"></app-country-select>

						<mat-chip-list #pickUpZips class="pickUpDeliveryChips inputDividerRow" fxFlex>
							<mat-chip
								(removed)="removePickUpZip(zip)"
								*ngFor="let zip of relation.pickUpZips"
								[removable]="removable"
								[selectable]="selectable"
							>
								{{ zip }}
								<mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
							</mat-chip>
							<input
								(matChipInputTokenEnd)="addPickUpZip($event)"
								[matChipInputAddOnBlur]="addOnBlur"
								[matChipInputFor]="pickUpZips"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
								class="pickUpDeliveryChips"
								placeholder="{{ 'RELATION_ZIP_CODE_TT' | translate }}"
							/>
						</mat-chip-list>
					</div>
				</div>

				<div
					*ngIf="relation.mode === 'PickUpAndDelivery' || relation.mode === 'Delivery'"
					class="fullWidth marginTop20"
					fxLayout="column"
				>
					<div fxFlex="100" fxLayout="row">
						<div class="jobDialogIconsPickupDelivery deliveryImage"></div>

						<app-country-select [(country)]="relation.deliveryCountry"></app-country-select>

						<mat-chip-list #deliveryZips class="pickUpDeliveryChips inputDividerRow" fxFlex>
							<mat-chip
								(removed)="removeDeliveryZip(zip)"
								*ngFor="let zip of relation.deliveryZips"
								[removable]="removable"
								[selectable]="selectable"
							>
								{{ zip }}
								<mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
							</mat-chip>
							<input
								(matChipInputTokenEnd)="addDeliveryZip($event)"
								[matChipInputAddOnBlur]="addOnBlur"
								[matChipInputFor]="deliveryZips"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
								class="pickUpDeliveryChips"
								placeholder="{{ 'RELATION_ZIP_CODE_TT' | translate }}"
							/>
						</mat-chip-list>
					</div>
				</div>

				<p class="allowedInput">
					{{ 'ALLOWED_ZIP_CODES' | translate }}: 1, 10117, 1000-2111, 100-211, 10-21 {{ 'OR' | translate }} 1-2
				</p>
				<div *ngIf="invalidZipsList.length !== 0">
					<p class="invalidZipWarning">
						<i class="material-icons invalidZipWarningIcon">warning</i> {{ 'WARNING' | translate }}:
						<span class="invalidZipMessage">
							{{ invalidZipsList.length }} {{ 'INVALID_ZIP_1' | translate }} <br />{{ 'INVALID_ZIP_2' | translate }}:
						</span>
					</p>
					<p class="invalidZipWarningList">{{ invalidZipsList.toString() }}</p>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<app-flutaro-audit
	[(tenant)]="relation.tenant"
	[companySettings]="dialogData.companySettings"
	[createdAt]="relation.createdAt"
	[createdBy]="relation.createdBy"
	[lastModifiedAt]="relation.lastModifiedAt"
	[lastModifiedBy]="relation.lastModifiedBy"
	[userProfile]="dialogData.userProfile"
>
</app-flutaro-audit>
<!--SAVE AND CANCEL-->
<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
	<button (click)="onAbort()" class="importButton mat-raised" mat-button type="button">
		<mat-icon><i class="material-icons">close</i></mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button (click)="onSave()" class="importButtonDone mat-primary_flutaro" mat-button>
		<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

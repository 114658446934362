import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	Output,
	Pipe,
	PipeTransform,
	ViewChild,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import {
	createMapMarkerOptionsAddedStop,
	createMapMarkerOptionsEmptyKmAddress,
	createMapMarkerOptionsForDestination,
	translateJobPairingMode,
	translatePositionActivity,
} from './MapFunctions';
import { DomSanitizer } from '@angular/platform-browser';
import { format } from 'date-fns';
import { APP_DATE_TIME_FORMAT } from '../flutaro-datepicker/AppDateClasses';
import { DestinationType, JobWrapper } from '@flutaro/package/lib/model/Job';
import { AppMapMarker, AppMapPosition, AppMarkerType } from './WebMapClasses';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { FlutaroLatLng } from '@flutaro/package/lib/model/Positiondata';
import {
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) {}

	transform(value) {
		return this.sanitized.bypassSecurityTrustHtml(value);
	}
}

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input() polylinePath: google.maps.LatLngLiteral[];
	@Input() job: JobWrapper;
	@Input() providedMarkers: AppMapMarker[] = [];
	@Output() showJobChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() showJobsPositionsChange: EventEmitter<AppMapMarker> = new EventEmitter<AppMapMarker>();
	// Map Controls based on job or planning view
	// Map Inputs
	mapOptions: google.maps.MapOptions = {
		zoom: 7,
		zoomControl: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		mapId: 'ebb58d6e73909046',
		center: new FlutaroLatLng(51.502148, 10.715638),
	} as google.maps.MapOptions;
	mapBounds = new google.maps.LatLngBounds();
	// Component
	@ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
	@ViewChild(GoogleMap) map: GoogleMap;
	infoWindowContent;
	// Polyline - for displaying the route
	polylineOptions: google.maps.PolylineOptions = {
		strokeColor: '#ff4081', // color of "route"
		strokeOpacity: 0.8,
		strokeWeight: 2,
		geodesic: true,
	};
	// Markers - for displaying emptyKmStart, addedStop & Jobs Destination as Places on Map
	markers: AppMapMarker[];

	constructor(public _ngZone: NgZone) {}

	async ngOnChanges() {
		this.markers = this.providedMarkers;
		if (this.job) {
			this.initMarkersForJob();
		}
		await this.updateCurrentMarkersMapsBounds();
		this.initPolylinePath();
	}

	initPolylinePath() {
		if (this.polylinePath?.length) {
			this.polylineOptions.path = this.polylinePath;
		} else {
			const markerWithPosition = this.markers.filter((marker) => !!marker.position).map((marker) => marker.position);
			if (markerWithPosition?.length) this.polylineOptions.path = markerWithPosition;
		}
	}

	ngAfterViewInit() {
		window['angularComponentRef'] = { component: this, zone: this._ngZone };
	}

	ngOnDestroy() {
		window['angularComponentRef'] = null;
	}
	initMarkersForJob() {
		// Empty-Km-Address
		const emptyKmAddressMarker = new AppMapMarker(
			createMapMarkerOptionsEmptyKmAddress(),
			new FlutaroLatLng(
				this.job.costCalculation.emptyKmAddress.latitude,
				this.job.costCalculation.emptyKmAddress.longitude,
			),
		);
		emptyKmAddressMarker.data = new AppMapPosition(null, this.job, AppMarkerType.EMPTY_KM_ADDRESS);
		this.markers.push(emptyKmAddressMarker);
		// Added Stop
		if (this.job.costCalculation.addedStop) {
			const addedStopMarker = new AppMapMarker(
				createMapMarkerOptionsAddedStop(),
				new FlutaroLatLng(this.job.costCalculation.addedStop.latitude, this.job.costCalculation.addedStop.longitude),
			);
			addedStopMarker.data = new AppMapPosition(null, this.job, AppMarkerType.ADDED_STOP);
			this.markers.push(addedStopMarker);
		}
		this.initMarkersForJobsDestinations();
	}

	initMarkersForJobsDestinations() {
		this.job.destinations.forEach((dest, destIndex) => {
			const marker = new AppMapMarker(
				createMapMarkerOptionsForDestination(dest.locationType === DestinationType.PICKUP),
				new FlutaroLatLng(dest.position.latitude, dest.position.longitude),
			);
			marker.data = new AppMapPosition(null, this.job, AppMarkerType.DESTINATION, destIndex);
			this.markers.push(marker);
		});
	}

	async updateCurrentMarkersMapsBounds() {
		if (!this.markers?.length) {
			console.log(`updateCurrentMarkersMapsBounds, no markers for update - aborting`);
			return;
		}
		if (!this.map) {
			await flutaroWait(100);
			if (!this.map) {
				console.log(`updateCurrentMarkersMapsBounds, no map instance - aborting`);
				return;
			}
		}
		this.updateMapsBounds(this.markers.map((marker) => marker.position));
		return true;
	}

	updateMapsBounds(positions: google.maps.LatLngLiteral[]) {
		if (!positions.length || !this.map) {
			console.log(`updateMapsBounds, no positions for update of bounds or no map instance - aborting`);
			return;
		}
		this.mapBounds = new google.maps.LatLngBounds();
		for (let position of positions) {
			this.mapBounds.extend(position);
		}
		this.map.fitBounds(this.mapBounds);
		const mapAutoZoom = this.map.getZoom();
		console.log(`setMapCenterToPositionsBounds, Map-Auto-Zoom: ${mapAutoZoom}`);
		if (mapAutoZoom > this.mapOptions.zoom) {
			this.map.googleMap.setZoom(this.mapOptions.zoom);
		}
		console.log(`setMapCenterToPositionsBounds, Map-Zoom (after possible adjustment): ${this.map.getZoom()}`);
	}

	showJobOnMap(jobId: string) {
		console.log(`showJobOnMap, called with ${jobId}`);
		this.showJobChange.emit(jobId);
	}

	showAllJobsPositionsOnMap(jobId: string) {
		console.log(`showAllJobsPositionsOnMap, called for job ${jobId}`);
		const jobsMarker = this.markers.find((marker) => marker.data.jobId === jobId);
		this.showJobsPositionsChange.emit(jobsMarker);
	}

	openInfoWindow(marker: MapMarker, index: number, flutaroMarker: AppMapMarker) {
		let infoWindowContent;
		// TODO: distinguish between "latest GPS Position" (with Buttons and Label) and "one of many App-Users positions" (no label, no action buttons)
		switch (flutaroMarker.data.type) {
			case AppMarkerType.EMPTY_KM_ADDRESS:
				infoWindowContent = flutaroMarker.data.job.costCalculation.emptyKmAddress.toString();
				break;
			case AppMarkerType.DESTINATION:
				infoWindowContent =
					flutaroMarker.data.job.destinations[flutaroMarker.data.destinationIndex].position.toString();
				break;
			case AppMarkerType.ADDED_STOP:
				infoWindowContent = flutaroMarker.data.job.costCalculation.addedStop.toString();
				break;
			case AppMarkerType.GPS_POSITION:
				const markerData = this.markers[index].data;
				const jobsIdentifier = markerData.jobIdentifier ? markerData.jobIdentifier : markerData?.job?.job.identifier; // TODO: refactor for only markerData.jobIdentifier
				const jobStartDateString = format(
					markerData.jobsPickupPlannedDate ? markerData.jobsPickupPlannedDate : getJobsFirstPickupDate(markerData.job),
					APP_DATE_TIME_FORMAT,
				);
				const jobEndDateString = format(
					markerData.jobsDeliveryPlannedDate
						? markerData.jobsDeliveryPlannedDate
						: getJobsLastDeliveryDate(markerData.job),
					APP_DATE_TIME_FORMAT,
				);
				const positionDateString = format(markerData.location.timestamp, APP_DATE_TIME_FORMAT);
				const activityString = translatePositionActivity(markerData.location.activity.type);
				const jobPairingMode = translateJobPairingMode(markerData.location.extras.pairing);
				infoWindowContent =
					`<h4><i class="material-icons mapMarkerWindowIconHeadline">gps_fixed</i>GPS Details für ${markerData.licensePlate}</h4>` +
					'<div>' +
					`<p><i class="material-icons mapMarkerWindowIcon">phonelink_ring</i><b>Letzte Aktivität um:</b> ${positionDateString}</p>` +
					`<p><i class="material-icons mapMarkerWindowIcon">description</i><b>Auftrag:</b> ${jobsIdentifier}</p>` +
					`<p><i class="material-icons mapMarkerWindowIcon">timelapse</i><b>Auftragszeitfenster:</b> ${jobStartDateString} - ${jobEndDateString} </p>` +
					`<p><i class="material-icons mapMarkerWindowIcon">traffic</i><b>Auftragsstatus:</b> ${jobPairingMode}</p>` +
					`<p><i class="material-icons mapMarkerWindowIcon">local_shipping</i><b>Fahrzeugstatus:</b> ${activityString}</p>` +
					`<button class="mapMarkerWindowButton" onclick="window.angularComponentRef.zone.run(() => { window.angularComponentRef.component.showJobOnMap(\'${markerData.jobId}\')})">Zeige Auftrag</button>` +
					`<button class="mapMarkerWindowButton" onclick="window.angularComponentRef.zone.run(() => { window.angularComponentRef.component.showAllJobsPositionsOnMap(\'${markerData.jobId}\')})">Zeige alle GPS Positionen des Auftrags</button>` +
					'</div>';
		}
		this.infoWindowContent = new google.maps.InfoWindow({
			content: infoWindowContent,
		});
		this.infoWindow.open(marker as unknown as MapMarker);
	}
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
	selector: 'app-auth-login',
	templateUrl: './auth.login.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLoginComponent {
	constructor(public afAuth: AngularFireAuth) {}
}

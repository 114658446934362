import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import {
	AppScheduleBasics,
	DriverSchedule,
	SCHEDULE_TYPES,
	TrailerSchedule,
} from '@flutaro/package/lib/model/AppScheduleClasses';
import { DriverAppointmentSchedule } from '@flutaro/package/lib/model/DriverAppointmentSchedule';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	getClosestScheduleBeforeDate,
	getSchedulesEntityName,
} from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { AppBasicComponent } from '../../app.components.basic.component';

@Component({
	selector: 'app-schedule-edit',
	templateUrl: './schedule-edit.component.html',
	styleUrls: ['./schedule-edit.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleEditComponent extends AppBasicComponent implements OnChanges, AfterViewInit {
	@Input() type: SCHEDULE_TYPES;
	@Input() public vehicle: Vehicle;
	@Input() public schedule: TrailerSchedule | DriverSchedule | DriverAppointmentSchedule;
	@Input() schedules: AppScheduleBasics[] = [];
	@Input() public isNew: boolean;
	@Input() disabled: boolean;
	@Output() public scheduleChange: EventEmitter<TrailerSchedule | DriverSchedule | DriverAppointmentSchedule> =
		new EventEmitter<TrailerSchedule | DriverSchedule | DriverAppointmentSchedule>();
	@Output() public isAbort: EventEmitter<boolean> = new EventEmitter<boolean>();
	TYPES = SCHEDULE_TYPES;
	_entityName: string; // used with autocomplete component

	ngOnChanges() {
		this.setEntityNameForAutocompleteDisplay();
	}

	private setEntityNameForAutocompleteDisplay() {
		this._entityName = getSchedulesEntityName(this.type, this.schedule?.entityId, this.vehicles, this.drivers);
	}

	ngAfterViewInit() {
		if (this.isNew) {
			const previousSchedule = getClosestScheduleBeforeDate(this.schedules, this.schedule.startDate, this.schedule.id);
			if (previousSchedule) this.schedule.startDate = previousSchedule.endDate;
		}
	}

	clearEntityInSchedule($event) {
		$event.stopPropagation();
		this.schedule.entityId = '';
		this.schedule.entityName = '';
		this._entityName = this.schedule.entityName;
	}

	setEntityInSchedule(entity: Driver | Vehicle) {
		this.schedule.entityId = entity.backendId;
		this.schedule.entityName = entity.toString();
		this._entityName = this.schedule.entityName;
	}

	storeChanges() {
		this.scheduleChange.emit(this.schedule);
	}

	abortChanges() {
		this.isAbort.emit(true);
	}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DriverSchedule, SCHEDULE_TYPES, TrailerSchedule } from '@flutaro/package/lib/model/AppScheduleClasses';
import { AppBasicComponent } from '../../app.components.basic.component';

@Component({
	selector: 'app-schedule-display',
	templateUrl: './schedule-display.component.html',
	styleUrls: ['./schedule-display.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleDisplayComponent extends AppBasicComponent {
	@Input() schedules: DriverSchedule[] | TrailerSchedule[];
	@Input() type: SCHEDULE_TYPES;
	@Input() startDate: Date;
	@Input() endDate: Date;
	TYPES = SCHEDULE_TYPES;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import orderBy from 'lodash-es/orderBy';

import { TableDeleteDialogComponent } from '../../table/table.delete.dialog.component';
import { DashboardNewsProvider } from './dashboard.news.provider';
import { WidgetNewsDialogComponent } from './widget.news.dialog.component';
import { DashboardNews } from '@flutaro/package/lib/model/DashboardNews';
import { isBeforeOrSame } from '@flutaro/package/lib/functions/DataDateFunctions';

@Component({
	selector: 'news-widget',
	templateUrl: './dashboard.widget.news.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardNewsWidget implements OnChanges {
	@Input() public news: DashboardNews[];
	@Input() public author: string;
	@Input() public usersCompany: string;
	@Input() public editorMode: boolean;
	displayedNews: DashboardNews[] = [];

	constructor(
		public newsProvider: DashboardNewsProvider,
		@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
		@Inject(MatDialog) private dialog: MatDialog,
	) {}

	ngOnChanges(changes) {
		this.filterNewsForDate();
		this.sortData();
		this.changeDetectorRef.markForCheck();
	}

	addNews() {
		let newsConfig = new MatDialogConfig();
		newsConfig.data = {
			news: new DashboardNews(),
			author: this.author,
			editorMode: this.editorMode,
			company: this.usersCompany,
		};
		let dialogRef = this.dialog.open(WidgetNewsDialogComponent, newsConfig);
		dialogRef.afterClosed().subscribe((news) => {
			if (!news) {
				return;
			}
			this.newsProvider.store(news);
		});
	}

	editNews(editNews: DashboardNews) {
		let newsConfig = new MatDialogConfig();
		newsConfig.data = {
			news: editNews,
			author: this.author,
			editorMode: this.editorMode,
			company: this.usersCompany,
		};
		let dialogRef = this.dialog.open(WidgetNewsDialogComponent, newsConfig);
		dialogRef.afterClosed().subscribe((news) => {
			if (!news) {
				return;
			}
			this.newsProvider.store(news);
		});
	}

	deleteNews($event, editNews) {
		$event.stopPropagation();
		let deleteConfig = new MatDialogConfig();
		deleteConfig.data = { dataType: 'Neuigkeit', dataName: editNews.title };
		let dialogRef = this.dialog.open(TableDeleteDialogComponent, deleteConfig);
		dialogRef.afterClosed().subscribe((result) => {
			if (!result) {
				return;
			}
			this.newsProvider.remove(editNews);
		});
	}

	private sortData() {
		this.displayedNews = orderBy(this.displayedNews, ['date'], ['desc']);
	}

	private filterNewsForDate() {
		if (!this.editorMode) {
			this.displayedNews = this.news.filter((news) => isBeforeOrSame(news.date, new Date()));
		} else {
			this.displayedNews = this.news;
		}
		this.displayedNews = this.displayedNews.filter(
			(news) => news.author !== 'FlutaroNews' || isBeforeOrSame(news.date, new Date()),
		);
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalJobModule } from './ExternalJob/externalJob.module';
import { InterfaceNotificationComponent } from './interfaces.notification.component';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';

@NgModule({
	declarations: [InterfaceNotificationComponent],
	imports: [CommonModule, FlutaroCustomMaterialModule, ExternalJobModule],
	providers: [],
	exports: [InterfaceNotificationComponent],
})
export class InterfaceModule {}

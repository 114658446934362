<div class="mat-whiteframe-4dp dashboardWidget">
	<i class="material-icons dashboardIconBackground dashboardNewsIconBackground"></i>
	<i class="material-icons dashboardIconHead">message</i>
	<button
		mat-button
		id="addNewsArticleButton"
		class="mat-small"
		(click)="addNews()"
		*ngIf="editorMode"
		matTooltip="{{'NEWS_ADD_NEW' | translate}}"
		[matTooltipPosition]="'below'"
	>
		<i class="material-icons">add_circle_outline</i>
	</button>
	<div class="row dashboardHeadlineRow">
		<label class="dashboardHeadline">{{'NEWS' | translate}}</label>
	</div>
	<div
		fxLayout="column"
		class="newsNoArticleRow newsArticleRowMain"
		*ngIf="displayedNews.length > 0"
		(click)="editNews(displayedNews[0])"
	>
		<p class="newsDateAndHeadline">
			<span class="newsArticleDate">{{displayedNews[0].date | appFormatDate:'date'}}</span>
			<span class="newsArticleTitle">{{displayedNews[0].title}}</span>
		</p>
		<p class="newsArticleText">{{displayedNews[0].shortDescription}}</p>
		<span class="newsArticleAuthor">{{'FROM' | translate}} {{displayedNews[0].author}}</span>
		<button
			type="button"
			mat-button
			class="deleteButtonNews mat-secondary"
			*ngIf="editorMode && displayedNews[0].company === usersCompany"
			(click)="deleteNews($event, displayedNews[0])"
		>
			<i class="newsDeleteIcon material-icons">delete</i>
		</button>
	</div>
	<div class="newsNoArticleRow" *ngIf="displayedNews.length === 0">
		<p class="newsDateAndHeadline">{{'NEWS_NO_NEWS_AVAILABLE' | translate}}</p>
	</div>

	<mat-accordion class="margin0">
		<mat-expansion-panel class="newsExpansionPanel">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<i class="material-icons dialogIconExpansionPanel">message</i>{{'NEWS_MORE' | translate}}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div *ngIf="displayedNews.length < 2">
				<p class="newsArticleText">{{'NEWS_NO_MORE_NEWS_AVAILABLE' | translate}}</p>
			</div>
			<div *ngFor="let news of displayedNews; let i = index">
				<div *ngIf="i > 0" (click)="editNews(news)" class="newsArticleRow">
					<p class="newsDateAndHeadline">
						<span class="newsArticleDate">{{news.date | appFormatDate:'date'}} </span>
						<span class="newsArticleTitle">{{news.title}}</span>
					</p>
					<p class="newsArticleText">{{news.shortDescription}}</p>
					<span class="newsArticleAuthor">{{'FROM' | translate}} {{news.author}}</span>
					<button
						type="button"
						mat-button
						class="deleteButtonNews mat-secondary"
						*ngIf="editorMode && news.company === usersCompany"
						(click)="deleteNews($event, news)"
					>
						<i class="newsDeleteIcon material-icons">delete</i>
					</button>
				</div>
				<p class="dividerDashboardNewsDotted100"></p>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>

import { Injectable } from '@angular/core';
import { TimetableFilteredEntry } from '../timetable/data/TimetableFilterClasses';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import {
	getJobForEarliestAdjustment,
	getOverlappingJobsCorrespondingJob,
	setJobsPlannedDatesToLatest,
} from './JobAdjustmentFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { flutaroCloneDeep } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { setJobsPlannedDatesToEarliestValidDate } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { areTwoJobsOverlapping } from '@flutaro/package/lib/functions/job/JobOverlappingFunctions';
import { jobDestinationsAreValid } from '@flutaro/package/lib/functions/job/JobValidationFunctions';

@Injectable({
	providedIn: 'root',
})
export class JobAdjustmentService {
	dialogRef: MatDialogRef<ConfirmDialogComponent>;

	constructor(
		private notifications: FlutaroNotificationsProvider,
		private flutaroJobService: FlutaroJobService,
		public dialog: MatDialog,
		private translate: TranslateService,
	) {}

	public async adjustOverlappingJobs(timetableEntries: TimetableFilteredEntry[], userSettings: UserSettings) {
		let adjustedJobs: JobWrapper[] = [];
		let unadjustedJobs: JobWrapper[] = [];
		timetableEntries.forEach((tEntry) => {
			if (!tEntry.vehicleJobStatus.overlapping) return;
			const overlappingJobs: JobWrapper[] = tEntry.jobsEntry
				.filter((job) => job.overlapping === 1)
				.map((jobEntry) => jobEntry.jobWrapper);
			const correspondingJobs: JobWrapper[] = tEntry.jobsEntry
				.filter((job) => job.overlapping === 0)
				.map((jobEntry) => jobEntry.jobWrapper);

			overlappingJobs.forEach((overlappingJob) => {
				const correspondingJob = flutaroCloneDeep(
					getOverlappingJobsCorrespondingJob(overlappingJob, correspondingJobs),
				);
				const earlierJob = flutaroCloneDeep(
					getJobForEarliestAdjustment(overlappingJob, correspondingJob),
				) as JobWrapper;
				const laterJob = earlierJob.backendId === overlappingJob.backendId ? correspondingJob : overlappingJob;
				setJobsPlannedDatesToEarliestValidDate(earlierJob);
				setJobsPlannedDatesToLatest(laterJob, userSettings.planningSettings.jobDatesAdjustmentOnlyTimes);
				const isOverlappingAfterAdjustment = areTwoJobsOverlapping(earlierJob, laterJob);
				if (isOverlappingAfterAdjustment) {
					unadjustedJobs.push(overlappingJob);
				} else {
					adjustedJobs.push(earlierJob);
					adjustedJobs.push(laterJob);
				}
			});
		});
		// Post Validation & Filtering
		unadjustedJobs = unadjustedJobs.concat(adjustedJobs.filter((job) => !jobDestinationsAreValid(job)));
		adjustedJobs = adjustedJobs.filter((job) => jobDestinationsAreValid(job));
		if (!adjustedJobs.length) {
			this.notifications.showBasicSnackBar(this.translate.instant('JOB_OVERLAPPING_ADJUST_ERROR_NOT-POSSIBLE'));
			return;
		}

		const doAdjustment = await this.openConfirmationDialog(adjustedJobs);
		if (!doAdjustment) {
			this.notifications.showActionAbortedSnackBar();
			return;
		}
		await this.flutaroJobService.updateBulk(adjustedJobs);
		const successfullyAdjustedText = this.translate.instant('JOB_OVERLAPPING_ADJUST_SUCCESS_RESULT');
		this.notifications.showBasicSnackBar(`${adjustedJobs.length} ${successfullyAdjustedText}`);
	}

	openConfirmationDialog(adjustedJobs: JobWrapper[]) {
		this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
			disableClose: false,
		});
		const adjustConfirmationText = this.translate.instant(
			adjustedJobs.length === 1
				? 'JOB_OVERLAPPING_ADJUST_CONFIRMATION_SINGLE'
				: 'JOB_OVERLAPPING_ADJUST_CONFIRMATION_MULTIPLE',
		);
		this.dialogRef.componentInstance.confirmMessage = `${adjustedJobs.length} ${adjustConfirmationText}`;
		return lastValueFrom(this.dialogRef.afterClosed());
	}
}

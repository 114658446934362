import { ChatMessage, FBChatChannel } from '@flutaro/package/lib/model/ChatClasses';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { CollectionReference } from '@firebase/firestore';
import { appParseChannel } from '@flutaro/package/lib/functions/AppChatFunctions';

export function parseAphroditeChannel(
	channel: FBChatChannel,
	messagesCollection: CollectionReference<ChatMessage>,
	vehicle?: Vehicle,
	driver?: Driver,
): FlutaroChatChannel {
	const parsedChannel: FlutaroChatChannel = transformObjectIntoAphroditeChannelClass(appParseChannel(channel));
	parsedChannel.vehicle = vehicle;
	parsedChannel.driver = driver;
	parsedChannel.messagesCollection = messagesCollection;
	return parsedChannel;
}

export function transformObjectIntoAphroditeChannelClass(object: FBChatChannel): FlutaroChatChannel {
	const channelAsClass = new FlutaroChatChannel();
	Object.assign(channelAsClass, object);
	return channelAsClass;
}

/**
 * Added by WebApp/App Attributes to received List of Channels
 */
export class FlutaroChatChannel extends FBChatChannel {
	messagesCollection: CollectionReference<ChatMessage>;
	vehicle: Vehicle | undefined;
	driver: Driver | undefined;
}

<div class="chatContainer">
	<div class="layout-padding" fxLayout="column" fxLayoutGap="10px" style="height: 100%">
		<div fxLayout="row" fxLayoutGap="10px">
			<mat-form-field
				[floatLabel]="'always'"
				[matTooltipPosition]="'below'"
				fxFlex="100"
				matTooltip="{{ 'CHAT_SEARCH_TT' | translate }}"
			>
				<mat-label>{{ 'SEARCH' | translate }}</mat-label>
				<input [(ngModel)]="channelSearchText" matInput type="text" />
				<mat-icon class="chatSearchIcon" matPrefix>search</mat-icon>
			</mat-form-field>
		</div>
		<div class="chatMessagesList">
			<cdk-virtual-scroll-viewport itemSize="50" style="height: 100%">
				<mat-list class="sidenavMdList" fxFlex="100">
					<mat-list-item
						(click)="openChannel(channel)"
						*cdkVirtualFor="let channel of channels | filterElementsBy: channelSearchText"
						class="mat-2-line sidenavMdListItem"
					>
						<img class="matMenuChatImg" src="assets/pictures/Flutaro_Logo_white_iconOnly.svg" />
						<span *ngIf="channel.newCompanyMessage" class="chatSidenavUnreadMessage"></span>
						<div (click)="openChannel(channel)" class="mat-list-item-text" layout="column">
							<h3 class="chatSidenavMdListH3" mat-line>{{ channel.toString() }}</h3>
						</div>
						<span class="chatSidenavMdListDate">{{ channel.lastModified | relativeTime }}</span>
						<i class="sidenavMdListOpenIcon material-icons">keyboard_arrow_right</i>
					</mat-list-item>
				</mat-list>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
	<div *ngIf="chatProvider.$openedChannel | async" class="chatChannel">
		<app-chat-channel
			(leave)="closeChannel()"
			(openJobChange)="openJobChange.emit($event)"
			(sendMessageChange)="newMessagChange.emit($event)"
			[channel]="chatProvider.$openedChannel | async"
			[messages]="channelMessagesProvider.$channelMessages | async"
			[userProfile]="userProfile"
		>
		</app-chat-channel>
	</div>
</div>

import { endOfDay, isAfter, isBefore, isSameDay } from 'date-fns';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { DriverStatistics } from '../statistics/Statistics';
import { MIN_AVAILABLE_DRIVING_TIME } from './RelationStatusClasses';
import { isAfterOrSame, isBeforeOrSame } from '@flutaro/package/lib/functions/DataDateFunctions';
import { isDriverBlockedAtReferenceDay } from '@flutaro/package/lib/functions/driver/DriverFunctions';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { getVehiclesCurrentDriver } from '@flutaro/package/lib/functions/VehicleFunctions';
import { RelationCapacityEntry } from '../timetable/TimetableClasses';
import { getDriversOutsideLastWeekEndForReferenceDate } from '../driver/DriverWeekEndOutsideFunctions';
import {
	getFirstPickUp,
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
	getLastDestination,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';
import {
	filterJobsWithStartOnReferenceDate,
	isDriversLastJobBlock,
} from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';
import { isJobAssigned } from '@flutaro/package/lib/functions/job/JobValidationFunctions';

export function isLocationInRelation(
	positionZip: string,
	relationZips: string[],
	positionCountry?: string,
	relationCountry?: string,
): boolean {
	if (!positionZip && !positionCountry) return false;
	if (!positionZip) return positionCountry === relationCountry;
	let isZipInRange = relationZips.some((zip) =>
		zip.indexOf('-') > -1 ? isZipIsInRangeOfRelation(positionZip, zip) : checkIfZipIsInSingleZip(positionZip, zip),
	);
	if (positionCountry && relationCountry && positionCountry !== relationCountry) isZipInRange = false;
	return isZipInRange;
}

export function isZipInRelation(positionZip: string, positionCountry: string, relation: Relation): boolean {
	return isLocationInRelation(
		positionZip,
		relation[relation.mode === 'Delivery' ? 'deliveryZips' : 'pickUpZips'],
		positionCountry,
		relation[relation.mode === 'Delivery' ? 'deliveryCountry' : 'pickUpCountry'],
	);
}

export function isVehicleInRelation(
	positionZip: string,
	positionCountry: string,
	relation: Relation,
	vehicleId: string,
): boolean {
	if (relation.assignedVehicleIDs.length) return relation.assignedVehicleIDs.includes(vehicleId);

	return isLocationInRelation(
		positionZip,
		relation[relation.mode === 'Delivery' ? 'deliveryZips' : 'pickUpZips'],
		positionCountry,
		relation[relation.mode === 'Delivery' ? 'deliveryCountry' : 'pickUpCountry'],
	);
}

export function isJobInRelation(job: JobWrapper, relation: Relation): boolean {
	let isJobInRelation: boolean;
	if (
		relation.mode === 'PickUp' &&
		isLocationInRelation(
			getFirstPickUp(job).position.zip,
			relation.pickUpZips,
			getFirstPickUp(job).position.country,
			relation.pickUpCountry,
		)
	) {
		isJobInRelation = true;
	} else if (
		relation.mode === 'PickUpAndDelivery' &&
		isLocationInRelation(
			getFirstPickUp(job).position.zip,
			relation.pickUpZips,
			getFirstPickUp(job).position.country,
			relation.pickUpCountry,
		) &&
		isLocationInRelation(
			getLastDestination(job).position.zip,
			relation.deliveryZips,
			getLastDestination(job).position.country,
			relation.deliveryCountry,
		)
	) {
		// Job is in relation if both start and end positions match
		isJobInRelation = true;
	}
	return isJobInRelation;
}

/**
 * For calculating a timetables jobs relation based on Jobs Delivery
 * @param job
 * @param relation
 */
export function isJobInRelationTimetableView(job: JobWrapper, relation: Relation): boolean {
	if (!job.vehicleId) return;
	let isJobInRelation: boolean;
	if (
		relation.mode === 'PickUp' &&
		isLocationInRelation(
			getLastDestination(job).position.zip,
			relation.pickUpZips,
			getLastDestination(job).position.country,
			relation.pickUpCountry,
		)
	) {
		isJobInRelation = true;
	} else if (
		relation.mode === 'PickUpAndDelivery' &&
		isLocationInRelation(
			getFirstPickUp(job).position.zip,
			relation.pickUpZips,
			getFirstPickUp(job).position.country,
			relation.pickUpCountry,
		) &&
		isLocationInRelation(
			getLastDestination(job).position.zip,
			relation.deliveryZips,
			getLastDestination(job).position.country,
			relation.deliveryCountry,
		)
	) {
		// Job is in relation if both start and end positions match
		isJobInRelation = true;
	}
	return isJobInRelation;
}

/**
 *
 * @param job
 * @param relations
 */
export function getJobsRelationsForTimetableColoring(job: JobWrapper, relations: Relation[]): Relation[] {
	return relations.filter((relation) => isJobInRelationTimetableView(job, relation));
}

export function getRelationForZip(
	positionZip: string,
	positionCountry: string,
	relations: Relation[],
): Relation | undefined {
	return relations.find((relation) => isZipInRelation(positionZip, positionCountry, relation));
}

function isZipIsInRangeOfRelation(zip: string, zipRange: string): boolean {
	if (!zip || !zipRange) {
		return !zip && !zipRange;
	}
	let leftBound = zipRange.substr(0, zipRange.indexOf('-'));
	let leftNumber = parseInt(leftBound);
	let rightBound = zipRange.substr(zipRange.indexOf('-') + 1);
	let rightNumber = parseInt(rightBound);
	const jobZipRangeNumber = parseInt(zip.substring(0, leftBound.length));
	return jobZipRangeNumber >= leftNumber && jobZipRangeNumber <= rightNumber;
}

function checkIfZipIsInSingleZip(jobZip: string, zip: string): boolean {
	if (!jobZip || !zip) {
		return !jobZip && !zip;
	}
	let jobsZipAsNumber: number = parseInt(jobZip);
	let zipAsNumber: number = parseInt(zip);
	return jobsZipAsNumber === zipAsNumber || jobZip.startsWith(zip);
}

export function isRangeOfZipsInvalid(zipWithRange: string): boolean {
	let leftBound = zipWithRange.substr(0, zipWithRange.indexOf('-'));
	let rightBound = zipWithRange.substr(zipWithRange.indexOf('-') + 1);
	// The 2 Zips in a Range need to have the same Size.
	// Check this here so that if on Number transformation a leading zero gets eliminated we still can check for a valid input
	if (leftBound.length !== rightBound.length) {
		return true;
	}
	// Zips Range has to be a valid Number
	if (isNaN(<any>leftBound) || isNaN(<any>rightBound)) {
		return true;
	}
	let leftNumber = parseInt(leftBound, 10);
	let rightNumber = parseInt(rightBound, 10);
	// The right Bound always has to be bigger than the left bound
	if (leftNumber > rightNumber || leftNumber === rightNumber) {
		return true;
	}
	// Zips can have maximum of 5 digits
	if (leftBound.length > 10 || rightBound.length > 10) {
		return true;
	}
	// Rule out all Zips that are valid
	return false;
}

export function isSingleZipInvalid(zip: string): boolean {
	return isNaN(<any>zip) || zip.length > 10;
}

export function addNoRelationEntry(
	relations: RelationCapacityEntry[],
	activeVehicles: Vehicle[],
	jobs: JobWrapper[],
): RelationCapacityEntry {
	let unavailableVehicles = [];
	let unavailableJobs = [];
	relations.forEach((relation) => {
		unavailableVehicles = unavailableVehicles.concat(relation.entitiesIds);
		unavailableJobs = unavailableJobs.concat(relation.jobIds);
	});

	const vehiclesWithoutRelation = activeVehicles
		.filter((vehicle) => !unavailableVehicles.includes(vehicle.backendId))
		.map((vehicle) => vehicle.backendId);
	let noRelation: RelationCapacityEntry = new RelationCapacityEntry();
	noRelation.color = 'rgb(64, 193, 172)';
	noRelation.jobIds = jobs.filter((job) => !unavailableJobs.includes(job.backendId)).map((job) => job.backendId);
	noRelation.id = 'noRelation';
	noRelation.entitiesIds = vehiclesWithoutRelation;
	return noRelation;
}

/**
 * Get all Jobs in the current Week until the end of todays day and return the latest job for a Driver
 */
export function getVehiclesLastJob(jobs: JobWrapper[], vehicle: Vehicle, referenceDate: Date) {
	// TODO: refactor me to so cost-calculation and events use same function
	const referenceDaysEndDate = endOfDay(referenceDate);
	const jobsUntilNow: JobWrapper[] = jobs.filter(
		(job) => job.vehicleId === vehicle.backendId && isBefore(getJobsLastDeliveryDate(job), referenceDaysEndDate),
	);
	let latestJob = Math.max.apply(
		null,
		jobsUntilNow.map((job) => {
			return getLastDestination(job).plannedDate;
		}),
	);
	return jobsUntilNow.filter((job) => getLastDestination(job).plannedDate.getTime() === latestJob)[0];
}

export function calculateEntitiesForRelation(
	jobs: JobWrapper[],
	referenceDate: Date,
	activeVehicles: Vehicle[],
	drivers: Driver[],
	relation: Relation,
): string[] {
	return activeVehicles
		.filter((vehicle) => {
			const planningEntitiesLastJob = getVehiclesLastJob(jobs, vehicle, referenceDate);
			const driver = getVehiclesCurrentDriver(vehicle, drivers);
			let isCurrentDriversLastJobBlock = false;
			let driverWeekEndOutside;
			if (driver) {
				isCurrentDriversLastJobBlock = isDriversLastJobBlock(driver, planningEntitiesLastJob, referenceDate);
				driverWeekEndOutside = getDriversOutsideLastWeekEndForReferenceDate(driver, referenceDate);
			}

			let isInRelation: boolean;
			const vehiclesAddress = vehicle.isDepotAddressBased ? vehicle.depotAddress : driver?.homeAddress;
			if (!vehiclesAddress) return false; // Early Return
			// Case: Spot Vehicle - no Job, always from vehicle depot
			if (
				vehicle.isSpot &&
				isVehicleInRelation(vehiclesAddress.zip, vehiclesAddress.country, relation, vehicle.backendId)
			) {
				isInRelation = true;
				// Case: From Home - no Job, no Block
			} else if (
				!planningEntitiesLastJob &&
				!isCurrentDriversLastJobBlock &&
				!driverWeekEndOutside &&
				isVehicleInRelation(vehiclesAddress.zip, vehiclesAddress.country, relation, vehicle.backendId)
			) {
				isInRelation = true;
				// Case: From Home, but has weekEndOutSide set
			} else if (
				!planningEntitiesLastJob &&
				!isCurrentDriversLastJobBlock &&
				driverWeekEndOutside &&
				isVehicleInRelation(driverWeekEndOutside.zip, driverWeekEndOutside.country, relation, vehicle.backendId)
			) {
				console.log(`calculateEntitiesForRelation, isDriverWeekEndOutsideForReferenceDate`);
				isInRelation = true;
				// Case: Block in relation
			} else if (
				isCurrentDriversLastJobBlock &&
				isVehicleInRelation(driver.block.blockZip, 'DE', relation, vehicle.backendId)
			) {
				isInRelation = true;
				// Case: Job is in relation
			} else if (
				planningEntitiesLastJob &&
				isVehicleInRelation(
					getLastDestination(planningEntitiesLastJob).position.zip,
					getLastDestination(planningEntitiesLastJob).position.country,
					relation,
					vehicle.backendId,
				)
			) {
				isInRelation = true;
			}
			return isInRelation;
		})
		.map((vehicle) => vehicle.backendId);
}

export function calculateRelationCapacities(
	timetableNoDriverJobs: JobWrapper[],
	currentWeekJobs: JobWrapper[],
	referenceDate: Date,
	relations: Relation[],
	isSundayTable: boolean,
	drivers: Driver[],
	activeVehicles: Vehicle[],
	driverStatistics: Map<string, DriverStatistics>,
) {
	const plannedJobs = currentWeekJobs.filter((job) => isJobAssigned(job));
	// Filter vehicles based on various conditions before categorizing into relations
	const filteredVehicles = activeVehicles.filter((vehicle) => {
		const vehiclesDriver = getVehiclesCurrentDriver(vehicle, drivers);
		if (
			vehiclesDriver &&
			(driverStatistics.get(vehiclesDriver.backendId)?.dailyDrivingTime >= MIN_AVAILABLE_DRIVING_TIME ||
				isDriverBlockedAtReferenceDay(vehiclesDriver, referenceDate))
		)
			return false;

		return !plannedJobs.filter(
			(job) =>
				job.vehicleId === vehicle.backendId && // Jobs timewindow is blocking full range
				((isBeforeOrSame(getJobsFirstPickupDate(job), referenceDate) &&
					isAfter(getJobsLastDeliveryDate(job), referenceDate) &&
					!isSameDay(getJobsLastDeliveryDate(job), referenceDate)) ||
					// Job ending after referenceDay
					(isAfterOrSame(getJobsFirstPickupDate(job), referenceDate) &&
						isSameDay(getJobsFirstPickupDate(job), referenceDate) &&
						isAfter(getJobsLastDeliveryDate(job), referenceDate) &&
						!isSameDay(getJobsLastDeliveryDate(job), referenceDate))),
		).length;
	});

	const referenceDateFilteredNoDriverJobs = filterJobsWithStartOnReferenceDate(
		timetableNoDriverJobs,
		referenceDate,
		isSundayTable,
	);
	let relationsForTimetable: RelationCapacityEntry[] = [];
	relations.forEach((relation) => {
		let tempRelation = new RelationCapacityEntry();
		tempRelation.color = relation.color;
		tempRelation.id = relation.backendId;
		tempRelation.jobIds = referenceDateFilteredNoDriverJobs
			.filter((job) => isJobInRelation(job, relation))
			.map((job) => job.backendId);
		tempRelation.entitiesIds = calculateEntitiesForRelation(
			plannedJobs,
			referenceDate,
			filteredVehicles,
			drivers,
			relation,
		);
		relationsForTimetable.push(tempRelation);
	});
	relationsForTimetable.push(
		addNoRelationEntry(relationsForTimetable, filteredVehicles, referenceDateFilteredNoDriverJobs),
	);

	return relationsForTimetable;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AddressesMenuComponent } from '../menu/menu.addresses.component';
import { DashboardMenuComponent } from '../menu/menu.dashboard.component';
import { SettingsMenuComponent } from '../menu/menu.import.component';
import { AppMenuJobsComponent } from '../menu/menu.jobs.component';
import { SetupMenuComponent } from '../menu/menu.setup.component';
import { TimetableMenuComponent } from '../timetable/timetable.menu.component';
import { RoleGuardService } from './routes/routes.role.guard';
import { AuthLoginComponent } from './auth/auth.login.component';
import { ROLES } from '@flutaro/package/lib/model/AuthClasses';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';

const appRoutes: Routes = [
	{
		path: 'dashboard',
		component: DashboardMenuComponent,
		data: {
			expectedRole: ROLES.WEBAPP_USER,
		},
		canActivate: [RoleGuardService],
	},
	{ path: 'planning', component: TimetableMenuComponent },
	{
		path: 'jobs',
		component: AppMenuJobsComponent,
		data: {
			expectedRole: ROLES.WEBAPP_USER,
			readOnlyRole: ROLES.READONLY_USER,
		},
		canActivate: [RoleGuardService],
	},
	{
		path: 'setup',
		component: SetupMenuComponent,
		data: {
			expectedRole: ROLES.WEBAPP_USER,
		},
		canActivate: [RoleGuardService],
	},
	{
		path: 'addresses',
		component: AddressesMenuComponent,
		data: {
			expectedRole: ROLES.WEBAPP_USER,
		},
		canActivate: [RoleGuardService],
	},
	{
		path: 'import',
		component: SettingsMenuComponent,
		data: {
			expectedRole: ROLES.WEBAPP_USER,
		},
		canActivate: [RoleGuardService],
	},
	{
		path: 'login',
		component: AuthLoginComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
	providers: [
		{ provide: APP_BASE_HREF, useValue: '#' },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		RoleGuardService,
	],
})
export class AppRoutingModule {}

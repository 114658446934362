import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppDeleteDialogData } from '../../table/TableClasses';

@Component({
	selector: 'app-job-delete-dialog',
	templateUrl: './job.repeat.delete.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobRepeatDeleteDialogComponent {
	dataType;
	dataName;

	constructor(@Inject(MAT_DIALOG_DATA) public data: AppDeleteDialogData) {
		if (data) {
			this.dataType = data.dataType;
			this.dataName = data.dataName;
		}
	}
}

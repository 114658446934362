import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { FlutaroTableComponent } from '../table/table.component';
import { DriverDialogComponent } from './driver.dialog.component';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Component({
	selector: 'app-driver-table',
	templateUrl: './driver.table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTableComponent extends FlutaroTableComponent {
	constructor(
		public driverService: FlutaroDriverService,
		private vehicleService: FlutaroVehicleService,
		public translate: TranslateService,
		public dialog: MatDialog,
	) {
		super(dialog, driverService, translate);
	}

	initTableSettings() {
		this.elementTypeName = this.translate.instant('DRIVER');
		this.collectionName = FlutaroCollection.DRIVER;
		this.displayedColumns = ['lastName', 'license', 'phone', 'trainings', 'edit', 'delete'];
		this.dataDialog = DriverDialogComponent;
	}

	async showDeleteDialogAndDeleteOnConfirm(driver: Driver) {
		const isDelete = await super.showDeleteDialogAndDeleteOnConfirm(driver, driver.toString());
		if (!isDelete) return;
		return this.vehicleService.removeDriversSchedulesFromVehicles(driver.backendId);
	}

	protected buildDataSearchString(data: any) {
		let driver: Driver = <Driver>data;
		return (
			driver.toString() + driver.phone + driver.email + driver.homeAddress.toString() + driver.trainings.toString()
		);
	}
}

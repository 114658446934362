import { Injectable } from '@angular/core';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { flutaroWait, to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { HttpClient } from '@angular/common/http';
import { AtheneService } from '../../athene/athene.service';
import QueryAutocompletePrediction = google.maps.places.QueryAutocompletePrediction;
import { prepareAddressLineForGeocoding } from '@flutaro/package/lib/functions/LocationFunctions';
import QueryAutocompletionRequest = google.maps.places.QueryAutocompletionRequest;
import FindPlaceFromQueryRequest = google.maps.places.FindPlaceFromQueryRequest;
import AutocompletionRequest = google.maps.places.AutocompletionRequest;

@Injectable()
export class AddressSearchProvider {
	activeRequestsCounter: number = 0;
	private _autoCompleteCache: Map<string, QueryAutocompletePrediction[]> = new Map<
		string,
		QueryAutocompletePrediction[]
	>();

	constructor(private http: HttpClient, private athene: AtheneService) {}

	async autocompleteAddressSearch(address: string): Promise<QueryAutocompletePrediction[]> {
		console.debug(`autocompleteAddressSearch, called for address ${address}`);
		const addressSearchString = prepareAddressLineForGeocoding(address);
		if (this._autoCompleteCache.get(addressSearchString)) {
			console.debug(`autocompleteAddressSearch, cache response for ${address}`);
			return this._autoCompleteCache.get(addressSearchString);
		}
		const gAutocomplete = new google.maps.places.AutocompleteService();
		while (this.activeRequestsCounter > 0) {
			await flutaroWait(1000);
		}
		++this.activeRequestsCounter;
		const autocompleteRequest: AutocompletionRequest = { types: ['geocode'], input: address };

		const [autocompleteError, autocompleteResult] = await to(gAutocomplete.getPlacePredictions(autocompleteRequest));
		--this.activeRequestsCounter;
		if (autocompleteError) {
			console.error(autocompleteError);
			return;
		}
		this._autoCompleteCache.set(addressSearchString, autocompleteResult.predictions);
		console.log('autocompleteAddressSearch, got autocompleteResult: ');
		console.log(autocompleteResult);
		// TODO: filter for postal-code only, add info-message to users to include a postalCode
		return autocompleteResult.predictions;
	}

	async geocodeAddressAsSynonym(addressLine: string): Promise<Synonym> {
		return await this.athene.requestSynonymForAddress(addressLine);
	}
}

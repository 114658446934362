import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableDeleteDialogComponent } from './table.delete.dialog.component';
import { CommonModule } from '@angular/common';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';

@NgModule({
	declarations: [TableDeleteDialogComponent],
	exports: [TableDeleteDialogComponent],
	imports: [CommonModule, FormsModule, FlutaroCustomMaterialModule],
	providers: [],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class TableModule {}

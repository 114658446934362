import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import cloneDeep from 'lodash-es/cloneDeep';
import { JobFilterStatus } from '../timetable/data/TimetableFilterClasses';

export enum AppSideNav {
	JOB = 'JOB',
	VEHICLE = 'VEHICLE',
	CHAT = 'CHAT',
	NEW_JOBS = 'NEW_JOBS',
	INFO = 'INFO',
}

export class SidenavData {
	job: JobWrapper; // job for sidenav-edit actions
	originalJob: JobWrapper;
	jobIsEdited: boolean = false;
	vehicle: Vehicle;
	originalVehicle: Vehicle;

	constructor() {}

	setJobSideNavData(job: JobWrapper) {
		this.originalJob = job;
		this.job = cloneDeep(job);
		this.jobIsEdited = false;
	}

	setVehicleSideNavData(vehicle: Vehicle) {
		this.originalVehicle = vehicle;
		this.vehicle = cloneDeep(vehicle);
	}
}

export class LocationLabel {
	startLocationLabel: string = '';
	endLocationLabel: string = '';
}

export const CUSTOM_COLORS = [
	// color fors both
	{
		name: 'Invalide',
		value: '#EE6363',
	},
	{
		name: 'Invalid',
		value: '#EE6363',
	},
	{
		name: 'Alle ok',
		value: '#6DC17A',
	},
	{
		name: 'All ok',
		value: '#6DC17A',
	},
	// color for jobStatusStatistics
	{
		name: 'Eingeplant',
		value: '#cbb556',
	},
	{
		name: 'Planned',
		value: '#cbb556',
	},
	{
		name: 'Gesendet',
		value: '#34b7f1',
	},
	{
		name: 'Empfangen',
		value: '#679dc1',
	},
	{
		name: 'Ohne Kosten',
		value: '#C68690',
	},
	{
		name: 'Without costs',
		value: '#C68690',
	},
	{
		name: 'Status vorhanden',
		value: '#f9e153',
	},
	{
		name: 'Gestartet',
		value: '#f47aac',
	},
	{
		name: 'Angenommen',
		value: '#4db9a8',
	},
	{
		name: 'Abgelehnt',
		value: '#fcc742',
	},
	// color for driverStatusStatistics
	{
		name: 'Uninformiert',
		value: '#cbb556',
	},
	{
		name: 'Doppelbelegt',
		value: '#FFBACA',
	},
	{
		name: 'Overlapping',
		value: '#FFBACA',
	},
	{
		name: 'Zeitkritisch',
		value: '#FF9955',
	},
];
export interface ChartStatusData {
	value: number;
	name: any;
	extra: { key: JobFilterStatus | JobStatus | string };
}

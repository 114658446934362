import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent, SafeHtmlPipe } from './map.component';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { MapPlanningComponent } from './map-planning/map-planning.component';
import { MapPlanningMenuComponent } from './map-planning-menu/map-planning-menu.component';
import { MapPlanningToolbarComponent } from './map-planning-toolbar/map-planning-toolbar.component';
import { PositionPairingSelectComponent } from './position-pairing-select/position-pairing-select.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
	declarations: [
		MapComponent,
		MapPlanningComponent,
		MapPlanningMenuComponent,
		SafeHtmlPipe,
		MapPlanningToolbarComponent,
		PositionPairingSelectComponent,
	],
	imports: [CommonModule, FlutaroCustomMaterialModule, MatDividerModule],
	exports: [MapComponent, MapPlanningMenuComponent],
})
export class MapModule {}

<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground clientDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">business</i>
		{{ 'CLIENT' | translate }}
		<span class="dialogToolbarObjectName">{{ client.name }}</span>
	</h1>
</mat-toolbar>

<mat-dialog-content>
	<div class="layout-padding layout-margin" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
		<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column">
			<h4 class="dialogHeadlineContent">
				<i class="material-icons dialogIcon">assignment</i> {{ 'CLIENT_DATA' | translate }}
			</h4>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field fxFlex="50">
					<input [(ngModel)]="client.name" matInput placeholder="Name" required />
				</mat-form-field>
				<mat-form-field class="matSelectDriverJobDialog" fxFlex="50">
					<mat-select [(ngModel)]="client.abcClassification" placeholder="{{ 'CLASSIFICATION' | translate }}">
						<mat-option [value]="'A'">A {{ 'CLIENT' | translate }}</mat-option>
						<mat-option [value]="'B'">B {{ 'CLIENT' | translate }}</mat-option>
						<mat-option [value]="'C'">C {{ 'CLIENT' | translate }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<app-address-search [(synonym)]="client.contactAddress" fxFlex="100"> </app-address-search>
			</div>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field fxFlex="30">
					<input
						[(ngModel)]="client.officePhone"
						matInput
						placeholder="{{ 'TELEPHONE_OFFICE' | translate }}"
						type="tel"
					/>
				</mat-form-field>
				<mat-form-field fxFlex="35">
					<input
						[(ngModel)]="client.dispatchPhone"
						matInput
						placeholder="{{ 'TELEPHONE_DISPOSITION' | translate }}"
						type="tel"
					/>
				</mat-form-field>
				<mat-form-field fxFlex="30">
					<input [(ngModel)]="client.officeEmail" matInput placeholder="{{ 'EMAIL' | translate }}" />
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<h4 class="dialogHeadlineContentNotFirst">
					<i class="material-icons dialogIcon">assignment_turned_in</i> {{ 'REQUIREMENTS' | translate }}
				</h4>
			</div>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
				<mat-form-field fxFlex="50">
					<mat-select
						[(ngModel)]="client.requirements.requiredVehicle"
						placeholder="{{ 'VEHICLETYPE_REQUIREMENTS' | translate }}"
					>
						<mat-option *ngFor="let requiredVehicle of VehicleTypes" [value]="requiredVehicle">
							{{ requiredVehicle | translateVehicleType }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field fxFlex="50">
					<mat-select
						[(ngModel)]="client.requirements.requirements"
						[multiple]="true"
						placeholder="{{ 'DRIVER_QUALIFICATIONS' | translate }}"
					>
						<mat-option *ngFor="let driverQualification of DriverQualifications" [value]="driverQualification">
							{{ driverQualification | translateRequirement }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<mat-form-field fxFlex="100">
					<mat-select
						[(ngModel)]="client.requirements.properties"
						[multiple]="true"
						placeholder="{{ 'VEHICLE_EQUIPMENT_REQUIRED' | translate }}"
					>
						<mat-option *ngFor="let vehicleEquipment of DriverQualifications" [value]="vehicleEquipment">
							{{ vehicleEquipment | translateRequirement }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
				<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column">
					<h4>
						<div class="jobDialogIcons pickupImage"></div>
						{{ 'PICKUP' | translate }}
					</h4>
					<div fxLayout="column">
						<app-job-regulating-time
							[(regulating)]="client.requirements.startRegulatingTimeInMinutes"
						></app-job-regulating-time>
						<!-- <job-timewindow [(timewindow)]="client.requirements.timewindowStart"></job-timewindow> -->
					</div>
				</div>
				<mat-divider style="margin-left: 10px; margin-right: 10px"></mat-divider>
				<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column">
					<h4 class="dialogHeadlineDelivery">
						<div class="jobDialogIcons deliveryImage"></div>
						{{ 'DELIVERY' | translate }}
					</h4>
					<div fxLayout="column">
						<app-job-regulating-time
							[(regulating)]="client.requirements.endRegulatingTimeInMinutes"
						></app-job-regulating-time>
						<!-- <job-timewindow [(timewindow)]="client.requirements.timewindowEnd"></job-timewindow> -->
					</div>
				</div>
			</div>
		</div>

		<mat-divider></mat-divider>
		<!-- TODO: remove following div after re-implementation of clientJobTemplate -->
		<div style="min-width: 250px"></div>

		<!-- <div name="clientJobTemplates" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" >
        <mat-toolbar fxLayout="row">
            <div class="mat-toolbar-tools">
                <i class="material-icons dialogToolBarAdd">description</i>
                <span>Auftragsvorlagen</span>
            </div>
             <button mat-fab id="addClientDialogButton" class="mat-fab mat-flut" (click)="createNewProfile()" matTooltip="Neue Auftragsvorlage anlegen."
                [matTooltipPosition]="'below'">
                <mat-icon class="material-icons fab-icon-add">
                    <i class="material-icons addIconDialogButton">add</i>
                </mat-icon>
            </button>
        </mat-toolbar>
        <mat-list class="dialogMdList">
            <mat-list-item class="mat-2-line dialogMdListItem" *ngFor="let profile of client.profiles" (click)="editProfile(profile)">
                <i class="material-icons dialogMdListIcon">receipt</i>
                <div class="mat-list-item-text" layout="column">
                    <h3 class="dialogMdListTextH3">{{ profile.profileIdentifier }}</h3>
                    <h4 class="dialogMdListTextH4">{{ profile.description }}</h4>
                </div>
                <button mat-button class="deleteButtonMdList mat-secondary" (click)="deleteProfile(profile)">
                    <i class="dialogMdListDeleteIcon material-icons">delete</i>
                    Löschen
                </button>
            </mat-list-item>
        </mat-list>
    </div> -->
	</div>
</mat-dialog-content>
<app-flutaro-audit
	[(tenant)]="client.tenant"
	[createdAt]="client.createdAt"
	[lastModifiedBy]="client.lastModifiedBy"
	[createdBy]="client.createdBy"
	[companySettings]="dialogData.companySettings"
	[userProfile]="dialogData.userProfile"
	[lastModifiedAt]="client.lastModifiedAt"
>
</app-flutaro-audit>
<!--SAVE AND CANCEL-->
<mat-dialog-actions fxLayout="row" class="dialogFooterMD2" fxLayoutAlign="end none">
	<button (click)="onAbort()" class="importButton mat-raised" mat-button>
		<mat-icon>
			<i class="material-icons">close</i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button (click)="onSave()" class="importButtonDone mat-primary_flutaro" mat-button>
		<mat-icon>
			<i class="material-icons material-icons-white">done</i>
		</mat-icon>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlutaroCustomMaterialModule } from '../app/app.material2.module';
import { DialogModule } from '../dialog/dialog.module';
import { FlutaroDatetimepickerModule } from '../flutaro-datepicker/flutaro-datepicker.module';
import { CostsDataService } from './costs.data.service';
import { CostsDialogComponent } from './costs.dialog.component';
import { CostsCalculationProvider } from './costs.calculation.provider';
import { JobModule } from '../job/job.module';
import { SynonymModule } from '../synonym/synonym.module';
import { MatRadioModule } from '@angular/material/radio';
import { MapModule } from '../map/map.module';
import { PipesModule } from '../app/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocationModule } from '../location/location.module';

@NgModule({
	declarations: [CostsDialogComponent],
	exports: [],
	imports: [
		CommonModule,
		FormsModule,
		FlutaroCustomMaterialModule,
		DialogModule,
		FlutaroDatetimepickerModule,
		JobModule,
		SynonymModule,
		MatRadioModule,
		MapModule,
		MatRadioModule,
		MatRadioModule,
		PipesModule,
		TranslateModule,
		LocationModule,
	],
	providers: [CostsDataService, CostsCalculationProvider],
})
// AppModule is used to bootstrap angular2 for the upgradeAdapter in upgrade_adapter.ts
export class CostsModule {}

<mat-toolbar color="primary">
	<h1 class="mat-toolbar-tools dialogHeadline" name="jobFormHeader" fxLayoutAlign="center center">
		<div class="text-center">
			<i class="material-icons dialogIcon">delete</i>
			{{ dataType }} {{ 'DELETE' | translate }}
		</div>
	</h1>
</mat-toolbar>
<!-- Delete job or all Jobs of Series -->
<mat-dialog-content *ngIf="dataType === 'Wiederholenden Auftrag'">
	<p class="deleteConfirmMessage">
		{{ 'JOB_REPEAT_DELETE_1' | translate }}
		<span class="fontWeight600">{{ dataName }}</span>
		{{ 'JOB_REPEAT_DELETE_2' | translate }}
	</p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end none" *ngIf="dataType === 'Wiederholenden Auftrag'">
	<button mat-button class="importButton mat-raised" [mat-dialog-close]="'abort'">
		<mat-icon>
			<i class="material-icons"> close </i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button mat-button class="importButtonDone mat-primary_flutaro" [mat-dialog-close]="'job'">
		<mat-icon>
			<i class="material-icons material-icons-white"> done </i>
		</mat-icon>
		{{ 'DELETE_JOB' | translate }}
	</button>
	<button
		mat-button
		class="importButtonDone mat-primary_flutaro"
		[mat-dialog-close]="'series'"
		matTooltip="{{ 'JOB_REPEAT_DELETE_ALL_TT' | translate }}"
	>
		<mat-icon>
			<i class="material-icons material-icons-white"> done </i>
		</mat-icon>
		{{ 'JOB_REPEAT_DELETE_ALL' | translate }}
	</button>
</mat-dialog-actions>

<!-- Delete all Jobs of Series -->
<mat-dialog-content *ngIf="dataType === 'Wiederholende Aufträge'">
	<p class="deleteConfirmMessage">
		{{ 'JOB_REPEAT_DELETE_ALL_CONFIRM_1' | translate }}
		<span class="fontWeight600">{{ dataName }}</span>
		{{ 'JOB_REPEAT_DELETE_ALL_CONFIRM_2' | translate }}
	</p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end none" *ngIf="dataType === 'Wiederholende Aufträge'">
	<button mat-button class="importButton mat-raised" [mat-dialog-close]="false">
		<mat-icon>
			<i class="material-icons"> close </i>
		</mat-icon>
		{{ 'CANCEL' | translate }}
	</button>
	<button mat-button class="importButtonDone mat-primary_flutaro" [mat-dialog-close]="true">
		<mat-icon>
			<i class="material-icons material-icons-white"> done </i>
		</mat-icon>
		{{ 'DELETE' | translate }}
	</button>
</mat-dialog-actions>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { FlutaroTableComponent } from '../table/table.component';
import { ContractorDataProvider } from './contractor.data.provider';
import { ContractorDialogComponent } from './contractor.dialog.component';
import { Contractor } from '@flutaro/package/lib/model/Contractor';
import { TranslateService } from '@ngx-translate/core';
import { ContractorDialogData } from 'src/app/dialog/AppDialog';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Component({
	selector: 'app-contractor-table',
	templateUrl: './contractor.table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorTableComponent extends FlutaroTableComponent {
	constructor(
		private contractorProvider: ContractorDataProvider,
		public dialog: MatDialog,
		public translate: TranslateService,
		private vehicleProvider: FlutaroVehicleService,
	) {
		super(dialog, contractorProvider, translate);
	}

	initTableSettings() {
		this.elementTypeName = this.translate.instant('SUBCONTRACTOR');
		this.collectionName = FlutaroCollection.CONTRACTOR;
		this.displayedColumns = ['name', 'description', 'contractorId', 'edit', 'delete'];
		this.dataDialog = ContractorDialogComponent;
	}

	async showDeleteDialogAndDeleteOnConfirm(contractor: Contractor) {
		const isDeleted = await super.showDeleteDialogAndDeleteOnConfirm(contractor, contractor.toString());
		if (!isDeleted) return false;
		// TODO: refactor and delete me to not use vehicle - contractorId anymore
		this.vehicleProvider.getVehiclesForContractor(contractor.backendId).forEach((vehicle) => {
			vehicle.contractorId = null;
			this.vehicleProvider.store(vehicle);
		});
	}

	createDialogData(contractor: Contractor): ContractorDialogData {
		return new ContractorDialogData(
			this.userSettings,
			this.userProfile,
			this.companySettings,
			contractor,
			this.vehicles,
			this.drivers,
		);
	}

	protected buildDataSearchString(contractor: Contractor): string {
		return contractor.toString() + contractor.contractorId;
	}
}

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';
import { VehicleStatus, VehicleStatusDialogData } from '@flutaro/package/lib/model/VehicleStatus';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';

@Component({
	selector: 'app-vehicle-status-dialog',
	templateUrl: './vehicle.status.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleStatusDialogComponent implements OnInit {
	status: VehicleStatus;
	isNew: boolean;
	vehicle: Vehicle;
	userProfile: FlutaroUserProfile;
	// Internal
	isValidStatus: boolean = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialogData: VehicleStatusDialogData,
		private dialogRef: MatDialogRef<VehicleStatusDialogComponent>,
		private vehicleProvider: FlutaroVehicleService,
	) {}

	ngOnInit() {
		this.status = this.dialogData.status;
		this.isNew = this.dialogData.isNew;
		this.vehicle = this.dialogData.vehicle;
		this.userProfile = this.dialogData.userProfile;
	}

	changeStatusOnDateValidState(isValid: boolean) {
		this.isValidStatus = isValid;
	}

	saveStatus() {
		if (!this.isValidStatus) return;
		this.status.updatedOnDate = new Date();
		this.status.user = this.userProfile.displayName;
		this.dialogRef.close(this.status);
	}

	deleteStatus() {
		this.vehicle.timetableSettings.statuses = this.vehicle.timetableSettings.statuses.filter(
			(status) => status !== this.dialogData.status,
		);
		this.vehicleProvider.store(this.vehicle);
		this.dialogRef.close(false);
	}

	onAbort() {
		this.dialogRef.close(false);
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';
import { DateInterval } from '@flutaro/package/lib/functions/DataDateFunctions';

@Component({
	selector: 'app-date-range',
	templateUrl: 'dialog.daterange.component.html',
	styles: [
		`
			.date-range-component {
				max-width: 430px;
			}

			.date-range-button {
				margin-left: 10px;
				position: relative;
				top: -10px;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroDaterangeComponent {
	@Input() type: string;
	@Input() minDate: Date;
	@Output() dateChange: EventEmitter<DateInterval> = new EventEmitter();
	startDate: Date = startOfDay(new Date());
	endDate: Date = endOfDay(new Date());

	constructor() {}

	searchDataForDateRange() {
		this.prepareDates();
		this.dateChange.emit(new DateInterval(this.startDate, this.endDate));
	}

	private prepareDates() {
		this.endDate = endOfDay(this.endDate);
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
	PoseidonCostsRequest,
	PoseidonCostsResponse,
	PoseidonDistanceResponse,
} from '@flutaro/package/lib/model/services/PoseidonClasses';
import { JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { createDistanceRequestForDestinations } from '@flutaro/package/lib/functions/PoseidonRequestFunctions';
import { transformURLSearchParamIntoHttpParams } from 'flutaro-lib-angular/lib/functions/AppAngularHttpFunctions';
import { lastValueFrom } from 'rxjs';
import {
	updateDestinationsForDistanceResponse,
	updateJobForDistanceResponse,
} from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';

@Injectable()
export class IntegrationsPoseidonService {
	constructor(private http: HttpClient) {}

	public async getCostCalculationResult(request: PoseidonCostsRequest): Promise<PoseidonCostsResponse> {
		console.debug(`getCostCalculationResult, starting`);
		// Validation
		if (!request.waypoints || request.waypoints.length < 3) {
			console.log(`getCostCalculationResult, invalid parameters provided`);
			return;
		}
		let httpParams = new HttpParams();
		for (const [k, v] of Object.entries(request)) {
			if (k === 'waypoints') continue;
			httpParams = httpParams.append(k, v);
		}
		request.waypoints.forEach(
			(waypoint, index) =>
				(httpParams = httpParams.append(`waypoint${index}`, `${waypoint.latitude},${waypoint.longitude}`)),
		);
		return lastValueFrom(
			this.http.get<PoseidonCostsResponse>(environment.POSEIDON_URL + '/costs/web', { params: httpParams }),
		);
	}

	async getDistanceForJob(job: JobWrapper) {
		const distanceResponse = await this.getDistanceForDestinations(job.destinations);
		updateJobForDistanceResponse(job, distanceResponse);
		return job;
	}

	public async getDistanceForDestinations(destinations: JobDestination[]): Promise<PoseidonDistanceResponse> {
		console.debug(`getRoutingResult, starting`);
		// Validation
		if (destinations?.length < 2) {
			console.debug(`getRoutingResult, not enough parameters provided`);
			return new PoseidonDistanceResponse();
		}
		const requestParams = transformURLSearchParamIntoHttpParams(createDistanceRequestForDestinations(destinations));
		const distanceResponse = await lastValueFrom(
			this.http.get<PoseidonDistanceResponse>(environment.POSEIDON_URL + '/distance/web', { params: requestParams }),
		);
		updateDestinationsForDistanceResponse(destinations, distanceResponse);
		return distanceResponse;
	}
}

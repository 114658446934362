import { Pipe, PipeTransform } from '@angular/core';
import { DestinationType, JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { appSortBy, getValueFromStringPath } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { AppSorting } from '@flutaro/package/lib/model/AppClasses';
import { ExternalJobInCorrection } from '../interfaces/ExternalJob/ExternalJobWeb';
import { getFirstPickUp, getLastDestination } from '@flutaro/package/lib/functions/job/DestinationFunctions';
import { isCostCalculationJobUpdate } from '@flutaro/package/lib/functions/job/JobDataFunctions';

@Pipe({
	name: 'numberInPercentage',
})
export class NumberInPercentagePipe implements PipeTransform {
	transform(value: number): number {
		return value ? parseFloat((value * 100).toFixed(2)) : 0;
	}
}

@Pipe({
	name: 'getLastDestination',
	pure: true,
})
export class GetLastDestinationPipe implements PipeTransform {
	transform(job: JobWrapper): JobDestination {
		return getLastDestination(job);
	}
}

@Pipe({
	name: 'getFirstPickUp',
	pure: true,
})
export class GetFirstPickUpPipe implements PipeTransform {
	transform(job: JobWrapper): JobDestination {
		return getFirstPickUp(job);
	}
}

@Pipe({
	name: 'getFirstPickUpDate',
	pure: true,
})
export class GetFirstPickUpDatePipe implements PipeTransform {
	transform(job: JobWrapper): Date {
		return getFirstPickUp(job).plannedDate;
	}
}

@Pipe({
	name: 'getLastDeliveryDate',
	pure: true,
})
export class GetLastDeliveryDatePipe implements PipeTransform {
	transform(job: JobWrapper): Date {
		return getLastDestination(job).plannedDate;
	}
}

@Pipe({
	name: 'allJobsMaximumDestinationsLength',
	pure: true,
})
export class GetAllJobsMaximumDestinationsLength implements PipeTransform {
	transform(jobs: JobWrapper[] | ExternalJobInCorrection[]): number {
		if (!jobs.length) return 0;
		const sortingKey = jobs[0].destinations?.length ? 'destinations.length' : 'fixedJobDestinations.length';
		const sortedJobs = appSortBy(jobs, sortingKey, AppSorting.DESCENDING);
		return getValueFromStringPath(sortedJobs[0], sortingKey);
	}
}

@Pipe({
	name: 'getPickupDestinations',
})
export class GetPickupDestinationsPipe implements PipeTransform {
	transform(destinations: JobDestination[]): JobDestination[] {
		return destinations.filter((destination) => destination.locationType === DestinationType.PICKUP);
	}
}

@Pipe({
	name: 'getDeliveryDestinations',
})
export class GetDeliveryDestinationsPipe implements PipeTransform {
	transform(destinations: JobDestination[]): JobDestination[] {
		return destinations.filter((destination) => destination.locationType === DestinationType.DELIVERY);
	}
}

@Pipe({
	name: 'isDifferentJobCosts',
	pure: false,
})
export class GetIsDifferentJobCostsPipe implements PipeTransform {
	transform(job: JobWrapper, refJob: JobWrapper): boolean {
		return isCostCalculationJobUpdate(job, refJob);
	}
}

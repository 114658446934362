import { ExcelJob } from '../import.excel.classes';
import { VEHICLE_TYPE_TRANSLATED } from '@flutaro/package/lib/model/VehicleConstants';

export function handleExcelRowInit(dataType: string, jobRow: ExcelJob) {
	if (dataType === 'Auftrag') {
		initJobsExcelRowVehicleType(jobRow);
	}
}

export function initJobsExcelRowVehicleType(jobRow: ExcelJob) {
	if (!jobRow.requiredVehicle) {
		jobRow.requiredVehicle = VEHICLE_TYPE_TRANSLATED.TRUCK;
	}
}

<mat-card *ngIf="(schedules | schedulesInRange: startDate:endDate)?.length">
	<mat-card-title
		><mat-icon class="material-icons driverSidenavTrailerIcon">rv_hookup</mat-icon
		>{{ (type === TYPES.TRAILER_SCHEDULE ? 'TRAILER_SCHEDULE' : 'DRIVER_SCHEDULE') | translate }}</mat-card-title
	>
	<mat-card-content>
		<div *ngFor="let schedule of schedules | schedulesInRange: startDate:endDate | appOrderBy: '-startDate'">
			<div class="driverSidenavVehicleType" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
				<i class="material-icons driverSidenavIcon">local_shipping</i>
				<span class="driverSidenavLabel"
					>{{ (type === TYPES.TRAILER_SCHEDULE ? 'TRAILER' : 'DRIVER') | translate }}:</span
				>
				<div>
					{{ schedule.entityId | scheduleEntityName: type:vehicles:drivers }}
				</div>
			</div>
			<div
				class="driverSidenavVehicleType"
				*ngIf="type === TYPES.TRAILER_SCHEDULE && false"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
			>
				<i class="material-icons driverSidenavIcon">assignment</i>
				<span class="driverSidenavLabel">Trailer {{ 'TYPE' | translate }}:</span>
				<div>{{ schedule.trailer.carType | translateVehicleType }}</div>
			</div>
			<div class="driverSidenavVehicleType" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
				<i class="material-icons driverTrailerScheduleIcon">schedule</i>
				<div>{{ schedule.startDate | appFormatDate: 'datetime' }}</div>
				<div *ngIf="schedule.endDate" class="marginLeft3">- {{ schedule.endDate | appFormatDate: 'datetime' }}</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>

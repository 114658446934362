import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlutaroDatetimepickerModule } from '../flutaro-datepicker/flutaro-datepicker.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from './pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	imports: [
		CdkTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatTableModule,
		MatButtonModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatRadioModule,
		MatTabsModule,
		MatCardModule,
		FlexLayoutModule,
		MatSlideToggleModule,
		MatListModule,
		MatRippleModule,
		MatSnackBarModule,
		MatToolbarModule,
		MatProgressSpinnerModule,
		MatExpansionModule,
		MatMenuModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		MatDatepickerModule,
		MatNativeDatetimeModule,
		MatDatetimepickerModule,
		BrowserAnimationsModule,
		ScrollingModule,
		FlutaroDatetimepickerModule,
		DragDropModule,
		ReactiveFormsModule,
		PipesModule,
	],
	exports: [
		CdkTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatTableModule,
		MatButtonModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatRadioModule,
		MatTabsModule,
		MatCardModule,
		FlexLayoutModule,
		MatSlideToggleModule,
		MatListModule,
		MatRippleModule,
		MatSnackBarModule,
		MatToolbarModule,
		MatProgressSpinnerModule,
		MatExpansionModule,
		MatMenuModule,
		MatDatepickerModule,
		MatChipsModule,
		MatNativeDatetimeModule,
		MatDatetimepickerModule,
		BrowserAnimationsModule,
		ScrollingModule,
		FlutaroDatetimepickerModule,
		DragDropModule,
		ReactiveFormsModule,
		GoogleMapsModule,
		TranslateModule,
		PipesModule,
	],
	//providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }], // TODO: switch to this at some point
})
export class FlutaroCustomMaterialModule {
	constructor() {}
}

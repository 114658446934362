export function getJobIdFromDragAndDropEvent(event) {
	const jobsId = event.el.id.substring(14);
	return jobsId;
}

export function getVehicledFromDragEvent(event) {
	const vehicleId = event.container.id.substring(8);
	return vehicleId;
}

export function getVehicleIdFromDropEvent(event) {
	const vehicleId = event.target.id.substring(8);
	return vehicleId;
}

export function setScrollForTimetableScrollLeftAddSub(scrollLeftAddSub: number, isWeekPlanningDayMode: boolean) {
	setTimeout(() => {
		let timetable = document.getElementById('timetableSection');
		if (!timetable) {
			return;
		}
		const scrollPxFactor = 576;
		timetable.scrollLeft += scrollLeftAddSub * scrollPxFactor;
		if (isWeekPlanningDayMode) timetable.scrollLeft = timetable.scrollLeft - scrollPxFactor;
	}, 100);
}

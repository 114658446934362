<mat-form-field
	[style.width]="isMultiple ? '190px' : '160px'"
	[appearance]="'outline'"
	class="countrySelectPicker mat-form-field-no-padding"
>
	<mat-select
		(ngModelChange)="countryChange.emit($event)"
		[(ngModel)]="country"
		[matTooltipPosition]="'below'"
		[multiple]="false"
		matTooltip="{{ 'COUNTRY_ASSIGNMENT_TT' | translate }}"
		placeholder="{{ (placeholder ? placeholder : 'COUNTRY_ASSIGNMENT') | translate }}"
		*ngIf="!isMultiple"
	>
		<mat-option
			*ngFor="let countryISO2 of APP_COUNTRIES; let index = i; let odd = odd"
			[value]="countryISO2"
			[class.mat-option-odd]="odd"
			>{{ countryISO2 | locationCountryISO3 }}</mat-option
		>
	</mat-select>
	<!-- this is due to Error: Cannot change multiple mode of select after initialization - Material Error, cant use dynamic switch of multiple -->
	<div *ngIf="isMultiple" fxLayout="row" fxLayoutAlign="start end">
		<mat-select
			[(ngModel)]="countries"
			(ngModelChange)="countriesChange.emit($event)"
			[matTooltipPosition]="'below'"
			[multiple]="true"
			matTooltip="{{ 'EXCLUDED_COUNTRIES_TOOLTIP' | translate }}"
			placeholder="{{ (placeholder ? placeholder : 'COUNTRY_ASSIGNMENT') | translate }}"
		>
			<mat-option
				*ngFor="let countryISO2 of APP_COUNTRIES; let index = i; let odd = odd"
				[value]="countryISO2"
				[class.mat-option-odd]="odd"
				>{{ countryISO2 | locationCountryISO3 }}</mat-option
			>
		</mat-select>
		<button
			(click)="clearCountries()"
			aria-label="Clear"
			class="clearCountriesButton"
			mat-button
			matSuffix
			*ngIf="countries?.length"
		>
			<i class="material-icons synonymClearButtonIcon">close</i>
		</button>
	</div>
</mat-form-field>

import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { CostsCalculationProvider } from '../../costs/costs.calculation.provider';
import { FlutaroJobService } from '../../data/data-services/data.job.service';
import { PreviewCostRequest } from '../TimetableClasses';
import { Subject } from 'rxjs';
import { JobsCostCalculationPreview } from '@flutaro/package/lib/model/costs/CostCalculation';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';
import { JobHelperService } from '../../job/job.helper.service';
import { flutaroCloneDeep } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Injectable()
export class TimetableService {
	$costPreview: Subject<JobsCostCalculationPreview> = new Subject();
	_waitingRequest: PreviewCostRequest;
	_isRequesting: boolean = false;

	constructor(
		private costsProvider: CostsCalculationProvider,
		private jobProvider: FlutaroJobService,
		private vehicleService: FlutaroVehicleService,
		private jobHelper: JobHelperService,
	) {}

	async requestPreviewCosts(previewRequest: PreviewCostRequest) {
		if (this._isRequesting) {
			this._waitingRequest = previewRequest;
			return;
		}
		this._isRequesting = true;
		const jobBeforeChange: JobWrapper = this.jobProvider.getElementById(previewRequest.jobId);
		const newVehicle = this.vehicleService.getElementById(previewRequest.vehicleId);
		let jobAfterChange: JobWrapper = await this.jobHelper.changeJobAttributesForNewVehicle(
			cloneDeep(jobBeforeChange),
			newVehicle,
		);
		await flutaroWait(500); // TODO: once we use reddit store this can be immediately
		const updatedCosts = await this.costsProvider.calculatePreviewCosts(jobAfterChange);
		this._isRequesting = false;
		if (this._waitingRequest) {
			const previewRequest = flutaroCloneDeep(this._waitingRequest);
			this._waitingRequest = null;
			this.requestPreviewCosts(previewRequest);
		}
		if (!updatedCosts) {
			console.debug(`requestPreviewCosts, no updatedCosts from Server. Aborting`);
			return;
		}
		this.$costPreview.next(
			new JobsCostCalculationPreview(
				previewRequest.vehicleId,
				updatedCosts.job.costCalculation,
				updatedCosts.job.revenue,
			),
		);
	}

	resetCostPreview() {
		console.debug(`resetCostPreview`);
		this.$costPreview.next(null);
	}
}

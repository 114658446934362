import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlutaroTableComponent } from '../table/table.component';
import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { LocationDialogComponent } from './location.dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { Synonym } from '@flutaro/package/lib/model/Synonym';

@Component({
	selector: 'app-location-table',
	templateUrl: './location.table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationTableComponent extends FlutaroTableComponent {
	constructor(
		public locationService: FlutaroSynonymService,
		public dialog: MatDialog,
		public translate: TranslateService,
	) {
		super(dialog, locationService, translate);
		this.elementTypeName = this.translate.instant('LOCATION');
		this.collectionName = FlutaroCollection.LOCATION;
		this.displayedColumns = ['code', 'addressLine', 'zip', 'city', 'edit', 'delete'];
		this.dataDialog = LocationDialogComponent;
	}
	protected buildDataSearchString(data: any) {
		let location: Synonym = <Synonym>data;
		return location.code + location.addressLine;
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { updateFormValues, validateFormFields } from '../../form/FlutaroFormFunctions';
import { startOfDay } from 'date-fns';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ContractorRate } from '@flutaro/package/lib/model/Contractor';
import { vehicleTypesToString } from '@flutaro/package/lib/functions/VehicleFunctions';
import { CurrencyFormValidator } from '../../form/form.validators';

@Component({
	selector: 'app-contractor-rate',
	templateUrl: './contractor-rate.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./contractor-rate.component.scss'],
})
export class ContractorRateComponent implements OnInit {
	@Input() isNew: boolean;
	@Input() contractorRate: ContractorRate;
	@Output() rateSave = new EventEmitter<ContractorRate>();
	contractorRateForm: FormGroup;
	VehicleTypes = vehicleTypesToString();
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;

	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.initializeFormFields();
	}

	onSubmit() {
		if (!this.contractorRateForm.valid) {
			validateFormFields(this.contractorRateForm);
			return;
		}
		this.contractorRateForm.patchValue({
			date: startOfDay(this.contractorRate.date),
		});
		updateFormValues(
			this.contractorRateForm,
			this.contractorRate,
			[
				'dailyRate',
				'kmRate',
				'reducedDailyRate',
				'vehicleTypeWithSpecificRateFixedCosts',
				'includedEmptyKmForCustomerEmptyKmRuleCalculation',
				'costForKmForCustomerEmptyKmRuleCalculation',
			],
			new ContractorRate(),
		);

		this.rateSave.emit(this.contractorRate);
	}

	onAbort() {
		this.rateSave.emit(null);
	}

	addCustomerIDEmptyKmRule(event: MatChipInputEvent): void {
		const input = event.input;
		const customerId = event.value;
		if (!customerId) return;
		console.log(`addCustomerIDEmptyKmRule, input: ${input}, value: ${customerId}`);
		let customerIDs: string[] = this.contractorRateForm.get('customerIDsForCustomerEmptyKmRuleCalculation').value;
		if (!customerIDs) customerIDs = [];
		customerIDs.push(customerId);
		this.contractorRateForm.patchValue({
			customerIDsForCustomerEmptyKmRuleCalculation: customerIDs,
		});
		event.input.value = '';
	}

	removeCustomerIDEmptyKmRule(customerId: string): void {
		let customerIDs: string[] = this.contractorRateForm.get('customerIDsForCustomerEmptyKmRuleCalculation').value;
		customerIDs = customerIDs.filter((id) => id !== customerId);
		this.contractorRateForm.patchValue({
			customerIDsForCustomerEmptyKmRuleCalculation: customerIDs,
		});
	}

	private initializeFormFields() {
		// <!-- TODO: max. two decimals --> for cost-Attributes
		this.contractorRateForm = this.fb.group({
			dailyRate: [
				this.contractorRate.dailyRate?.toString().replace('.', ','),
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			kmRate: [
				this.contractorRate.kmRate?.toString().replace('.', ','),
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			reducedDailyRate: [
				this.contractorRate.reducedDailyRate?.toString().replace('.', ','),
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			vehicleTypeWithSpecificRateFixedCosts: [
				this.contractorRate.vehicleTypeWithSpecificRateFixedCosts?.toString().replace('.', ','),
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			vehicleTypeWithSpecificRate: this.contractorRate.vehicleTypeWithSpecificRate,
			alwaysApplyDailyRate: this.contractorRate.alwaysApplyDailyRate,
			kmRateIncludesToll: this.contractorRate.kmRateIncludesToll,
			useReducedDailyRate: this.contractorRate.useReducedDailyRate,
			date: this.contractorRate.date,
			activateCustomerEmptyKmRuleCalculation: this.contractorRate.activateCustomerEmptyKmRuleCalculation,
			customerIDsForCustomerEmptyKmRuleCalculation: [this.contractorRate.customerIDsForCustomerEmptyKmRuleCalculation],
			includedEmptyKmForCustomerEmptyKmRuleCalculation: [
				this.contractorRate.includedEmptyKmForCustomerEmptyKmRuleCalculation?.toString().replace('.', ','),
				[CurrencyFormValidator.validateCurrencyNumber],
			],
			costForKmForCustomerEmptyKmRuleCalculation: [
				this.contractorRate.costForKmForCustomerEmptyKmRuleCalculation?.toString().replace('.', ','),
				[CurrencyFormValidator.validateCurrencyNumber],
			],
		});
	}
}

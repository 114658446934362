<div [fxLayout]="relationDirection" *ngIf="$relationCapacities">
	<div id="relations1" fxLayout="column" fxLayoutAlign="start start">
		<div
			*ngFor="let capacity of $relationCapacities | async; index as i; trackBy: trackByFn"
			[class.marginBottom10]="isDashboard && i < 3"
			[class.fullWidth]="isDashboard"
		>
			<app-relation-capacities
				*ngIf="i < 3"
				[capacity]="capacity"
				[isDashboard]="isDashboard"
				(relationFilterChange)="relationFilterChangeEmit($event)"
				[resetFilterStatus]="resetFilterStatus"
			>
			</app-relation-capacities>
		</div>
	</div>
	<div id="relations2" fxLayout="column" fxLayoutAlign="start start">
		<div
			*ngFor="let capacity2 of $relationCapacities | async; index as j; trackBy: trackByFn"
			[class.marginBottom10]="isDashboard && j > 2 && j < 6"
			[class.fullWidth]="isDashboard"
		>
			<app-relation-capacities
				*ngIf="j > 2 && j < 6"
				[capacity]="capacity2"
				[isDashboard]="isDashboard"
				(relationFilterChange)="relationFilterChangeEmit($event)"
				[resetFilterStatus]="resetFilterStatus"
			>
			</app-relation-capacities>
		</div>
	</div>
	<div id="relations3" fxLayout="column" fxLayoutAlign="start start">
		<div
			*ngFor="let capacity3 of $relationCapacities | async; index as k; trackBy: trackByFn"
			[class.marginBottom10]="isDashboard && k > 5 && k < 9"
			[class.fullWidth]="isDashboard"
		>
			<app-relation-capacities
				*ngIf="k > 5 && k < 9"
				[capacity]="capacity3"
				[isDashboard]="isDashboard"
				(relationFilterChange)="relationFilterChangeEmit($event)"
				[resetFilterStatus]="resetFilterStatus"
			>
			</app-relation-capacities>
		</div>
	</div>
	<div fxLayout="column" fxLayoutAlign="start start" id="relations4">
		<div
			*ngFor="let capacity4 of $relationCapacities | async; index as l; trackBy: trackByFn"
			[class.marginBottom10]="isDashboard && l > 8 && l < 12"
			[class.fullWidth]="isDashboard"
		>
			<app-relation-capacities
				(relationFilterChange)="relationFilterChangeEmit($event)"
				*ngIf="l > 8 && l < 12"
				[capacity]="capacity4"
				[isDashboard]="isDashboard"
				[resetFilterStatus]="resetFilterStatus"
			>
			</app-relation-capacities>
		</div>
	</div>
</div>

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';

import { FlutaroRelationsService } from '../data/data-services/data.relations.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isRangeOfZipsInvalid, isSingleZipInvalid } from './RelationFunctions';
import { RelationDialogData } from './RelationClasses';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { CompanySettings } from '@flutaro/package/lib/model/CompanySettings';

@Component({
	selector: 'relation-dialog',
	templateUrl: './relation.dialog.component.html',
	styleUrls: ['./relation.dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationDialogComponent implements OnInit {
	relation: Relation;
	invalidZipsList: string[] = [];
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	userProfile: FlutaroUserProfile;
	companySettings: CompanySettings;

	constructor(
		public relationService: FlutaroRelationsService,
		@Inject(MAT_DIALOG_DATA) public dialogData: RelationDialogData,
		private notifications: FlutaroNotificationsProvider,
		public dialogRef: MatDialogRef<RelationDialogComponent>,
	) {}

	ngOnInit() {
		this.userProfile = this.dialogData.userProfile;
		this.companySettings = this.dialogData.companySettings;
		this.relation = cloneDeep(this.dialogData.data);
	}

	removePickUpZip(zip: string): void {
		this.removeZipFromZips(zip, this.relation.pickUpZips);
	}

	removeDeliveryZip(zip: string): void {
		this.removeZipFromZips(zip, this.relation.deliveryZips);
	}

	addPickUpZip(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if (!value) return;
		this.addZipInZips(value, this.relation.pickUpZips);
		input.value = '';
	}

	addDeliveryZip(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if (!value) return;
		this.addZipInZips(value, this.relation.deliveryZips);
		input.value = '';
	}

	addZipInZips(zip: string, zips: string[]): void {
		zips.push(zip.trim());
		this.checkIfAllZipsAreValid();
	}

	checkIfAllZipsAreValid() {
		this.invalidZipsList =
			this.relation.pickUpZips.filter((zip) => {
				if (zip.indexOf('-') > -1) {
					return isRangeOfZipsInvalid(zip);
				} else {
					return isSingleZipInvalid(zip);
				}
			}) &&
			this.relation.deliveryZips.filter((zip) => {
				if (zip.indexOf('-') > -1) {
					return isRangeOfZipsInvalid(zip);
				} else {
					return isSingleZipInvalid(zip);
				}
			});
	}

	onSave() {
		if (!this.allZipsAreValid()) {
			this.notifications.showBasicSnackBar('Es sind nicht alle Postleizahlenbereiche gültig.');
			return;
		}
		if (!this.relation.name) {
			this.notifications.showBasicSnackBar('Feld: Name ist erforderlich.');
			return;
		}
		this.relationService.store(this.relation);
		this.dialogRef.close();
	}

	onAbort() {
		this.dialogRef.close();
	}

	protected allZipsAreValid() {
		this.checkIfAllZipsAreValid();
		return this.invalidZipsList.length === 0;
	}

	private removeZipFromZips(zip: string, zips: string[]): void {
		const index = zips.indexOf(zip);
		if (index >= 0) zips.splice(index, 1);
		this.checkIfAllZipsAreValid();
	}
}

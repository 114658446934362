<div fxLayoutAlign="end center">
	<app-client-add [companySettings]="companySettings" [userProfile]="userProfile" [userSettings]="userSettings">
	</app-client-add>
</div>

<div class="table-container mat-elevation-z8">
	<div *ngIf="!data.length" class="addNewObjectBackground" fxLayout="column" fxLayoutAlign="center center">
		<div class="addNewClientImage"></div>
		<div class="addNewObjectDiv">
			<h4>
				{{ 'CLIENT' | translate }}
			</h4>
			<p class="fontWeight500">{{ 'CLIENT_TABLE_NO_DATA' | translate }}</p>
			<p class="fontWeight500">
				{{ 'TABLE_NO_DATA_1' | translate }} <i class="addNewObjectAddDialogIcon material-icons">add_circle</i>
				{{ 'TABLE_NO_DATA_2_CLIENT' | translate }}
			</p>
		</div>
	</div>
	<div class="table-filter-header">
		<i class="material-icons table-filter-icon">search</i>
		<mat-form-field>
			<input (keyup)="applyFilter($event)" matInput placeholder="{{ 'FILTER_CLIENTS' | translate }}" />
		</mat-form-field>
	</div>

	<table [dataSource]="dataSource" class="tableBodyScroll mat-elevation-z8" mat-table matSort>
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">business</i>
				Name
			</th>
			<td *matCellDef="let client" mat-cell>{{ client.name }}</td>
		</ng-container>

		<ng-container matColumnDef="abcClassification">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">star</i>
				{{ 'CLASSIFICATION' | translate }}
			</th>
			<td *matCellDef="let client" mat-cell>{{ client.abcClassification }}</td>
		</ng-container>

		<ng-container matColumnDef="address">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">place</i>
				{{ 'ADDRESS' | translate }}
			</th>
			<td *matCellDef="let client" mat-cell>
				{{ client.contactAddress.addressLine }} {{ client.contactAddress.zip }} {{ client.contactAddress.city }}
			</td>
		</ng-container>

		<ng-container matColumnDef="officePhone">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">phone</i>
				{{ 'TELEPHONE_OFFICE' | translate }}
			</th>
			<td *matCellDef="let client" mat-cell>{{ client.officePhone }}</td>
		</ng-container>

		<ng-container matColumnDef="dispatchPhone">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">phone</i>
				{{ 'TELEPHONE_DISPOSITION' | translate }}
			</th>
			<td *matCellDef="let client" mat-cell>{{ client.dispatchPhone }}</td>
		</ng-container>

		<ng-container matColumnDef="requiredVehicle">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>
				<i class="material-icons dataTableHeaderIcon">assignment_turned_in</i>
				{{ 'REQUIREMENTS' | translate }}
			</th>
			<td *matCellDef="let client" mat-cell>
				{{ client.requirements.requiredVehicle | translateVehicleType }} {{ client.requirements.requirements }}
				{{ client.requirements.properties }}
			</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th
				*matHeaderCellDef
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-header-cell
			>
				<i class="material-icons dataTableHeaderIcon">delete</i> {{ 'DELETE' | translate }}
			</th>
			<td
				*matCellDef="let client"
				[class.hideElement]="userProfile.hasDisableAllCreateDeleteRole"
				[width]="'125px'"
				mat-cell
			>
				<button
					(click)="showDeleteDialogAndDeleteOnConfirm(client, client.toString())"
					[disabled]="userProfile.hasDisableAllCreateDeleteRole"
					class="deleteButton"
					mat-button
				>
					<i class="materialTimetableIcon material-icons">delete</i>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th *matHeaderCellDef [width]="'125px'" mat-header-cell>
				<i class="material-icons dataTableHeaderIcon">edit</i> {{ 'EDIT' | translate }}
			</th>
			<td *matCellDef="let element" [width]="'125px'" mat-cell>
				<button (click)="showEditDialog(element)" class="editButton" mat-button>
					<i class="materialTimetableIcon material-icons">edit</i>
				</button>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons></mat-paginator>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { getDriverNameByDriverId } from '@flutaro/package/lib/functions/driver/DriverFunctions';
import { getAllSchedulesForElement, getCurrentSchedule } from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { SCHEDULE_TYPES } from '@flutaro/package/lib/model/AppScheduleClasses';

@Pipe({
	name: 'isDriverActivated',
})
export class IsDriverActivatedPipe implements PipeTransform {
	transform(driver: Driver, vehicles: Vehicle[]): boolean {
		const driverSchedules = getAllSchedulesForElement(driver.backendId, vehicles, SCHEDULE_TYPES.DRIVER_SCHEDULE);
		return !!getCurrentSchedule(driverSchedules);
	}
}

@Pipe({
	name: 'filterForActivatedDrivers',
})
export class FilterForActivatedDriversPipe implements PipeTransform {
	transform(drivers: Driver[], vehicles: Vehicle[]): Driver[] {
		return drivers.filter(
			(driver) =>
				!!getCurrentSchedule(getAllSchedulesForElement(driver.backendId, vehicles, SCHEDULE_TYPES.DRIVER_SCHEDULE)),
		);
	}
}

@Pipe({
	name: 'filterForInactiveDrivers',
})
export class FilterForInactiveDriversPipe implements PipeTransform {
	transform(drivers: Driver[], vehicles: Vehicle[]): Driver[] {
		return drivers.filter(
			(driver) =>
				!getCurrentSchedule(getAllSchedulesForElement(driver.backendId, vehicles, SCHEDULE_TYPES.DRIVER_SCHEDULE)),
		);
	}
}

@Pipe({
	name: 'getDriversNameById',
})
export class GetDriversNameByIdPipe implements PipeTransform {
	transform(driverId: string, drivers: Driver[]): string {
		return getDriverNameByDriverId(driverId, drivers);
	}
}

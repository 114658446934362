import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataChange } from '@flutaro/package/lib/model/AppDataTypes';

@Injectable()
export class AppPushProvider {
	/**
	 * TODO: this will be replaced by simple $data of the data services.
	 * New mechanism works on checking the "lastModifiedAt"-Attribute to detect if a data object was changed after an event (like opening the JobSidenav)
	 */
	public dataChangeSubject: Subject<DataChange<any>> = new Subject<DataChange<any>>();

	constructor() {}
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { FlutaroRelationsService } from '../data/data-services/data.relations.service';
import { FlutaroTableComponent } from '../table/table.component';
import { RelationDialogData } from './RelationClasses';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { RelationDialogComponent } from './relation.dialog.component';

@Component({
	selector: 'app-relation-table',
	templateUrl: './relation.table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationTableComponent extends FlutaroTableComponent {
	constructor(
		public dialog: MatDialog,
		public notifications: FlutaroNotificationsProvider,
		public translate: TranslateService,
		public relationProvider: FlutaroRelationsService,
	) {
		super(dialog, relationProvider, translate);
	}

	initTableSettings() {
		this.elementTypeName = this.translate.instant('RELATION');
		this.collectionName = FlutaroCollection.RELATION;
		this.displayedColumns = ['name', 'color', 'zips', 'edit', 'delete'];
		this.dataDialog = RelationDialogComponent;
	}

	createDialogData(data: Relation): RelationDialogData {
		return new RelationDialogData(this.userSettings, this.userProfile, this.companySettings, data, this.vehicles);
	}

	protected buildDataSearchString(data: any) {
		let relation: Relation = <Relation>data;
		return relation?.name + relation?.zips?.toString();
	}
}

import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { JobWrapperValidationAttributes, dataTypesDefinition } from '../import.excel.classes';
import { checkTotalValidationStatus } from '../validation/ExcelImportValidationFunctions';

@Component({
	selector: 'app-excel-import-status',
	templateUrl: './import.status.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroImportStatusComponent implements OnChanges {
	dataTypesDefinition = dataTypesDefinition;
	@Input() importType: string;
	@Input() currentValidationStatus: Map<number, any>;
	driverSummary: DriverStatusSummary;
	jobSummary: JobStatusSummary;
	vehicleSummary: VehicleStatusSummary;
	isAllCorrect: boolean = true;

	ngOnChanges(changes) {
		if (changes['currentValidationStatus']) {
			this.calculateStatusSummary();
			this.isAllCorrect = checkTotalValidationStatus(this.currentValidationStatus);
		}
	}

	calculateStatusSummary() {
		switch (this.importType) {
			case dataTypesDefinition.job:
				this.calculateJobStatusSummary();
				break;
			case dataTypesDefinition.driver:
				this.calculateDriverStatusSummary();
				break;
			case dataTypesDefinition.vehicle:
				this.calculateVehicleStatusSummary();
				break;
			default:
				break;
		}
	}

	calculateDriverStatusSummary() {
		this.driverSummary = new DriverStatusSummary();
		this.currentValidationStatus.forEach((driver) => {
			if (!driver.hasFullName) this.driverSummary.invalidName++;
			if (!driver.hasValidAddress) this.driverSummary.invalidAddress++;
			if (!driver.hasValidVehicle) this.driverSummary.invalidVehicle++;
			if (!driver.hasValidVehicleAssignment) this.driverSummary.invalidVehicleAssignment++;
		});
	}

	calculateJobStatusSummary() {
		this.jobSummary = new JobStatusSummary();
		this.currentValidationStatus.forEach((validation) => {
			const job = <JobWrapperValidationAttributes>validation;
			if (!job.hasIdentifier) this.jobSummary.invalidIdentifier++;
			if (!job.hasUniqueIdentifier) this.jobSummary.identifierNotUnique++;
			if (!job.hasValidPickUpDates) this.jobSummary.invalidPickUpDates++;
			if (!job.hasValidDeliveryDates) this.jobSummary.invalidDeliveryDates++;
			if (!job.hasValidPickUpAddresses) this.jobSummary.invalidPickUpAddresses++;
			if (!job.hasValidDeliveryAddresses) this.jobSummary.invalidDeliveryAddresses++;
			if (!job.hasValidDriver) this.jobSummary.invalidDriver++;
			if (!job.hasValidVehicle) this.jobSummary.invalidVehicle++;
			if (!job.hasValidVehicleType) this.jobSummary.invalidVehicleType++;
		});
	}

	calculateVehicleStatusSummary() {
		this.vehicleSummary = new VehicleStatusSummary();
		this.currentValidationStatus.forEach((vehicle) => {
			if (!vehicle.hasLicensePlate) this.vehicleSummary.invalidLicensePlate++;
		});
	}
}

class DriverStatusSummary {
	invalidName: number = 0;
	invalidAddress: number = 0;
	invalidVehicle: number = 0;
	invalidVehicleAssignment: number = 0;
}

class JobStatusSummary {
	invalidIdentifier: number = 0;
	identifierNotUnique: number = 0;
	invalidPickUpDates: number = 0;
	invalidPickUpAddresses: number = 0;
	invalidDeliveryDates: number = 0;
	invalidDeliveryAddresses: number = 0;
	invalidVehicle: number = 0;
	invalidVehicleType: number = 0;
	invalidDriver: number = 0;
}

class VehicleStatusSummary {
	invalidLicensePlate: number = 0;
}

class LocationStatusSummary {
	missingIdentifier: number = 0;
	invalidCode: number = 0;
	invalidAddress: number = 0;
}

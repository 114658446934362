import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { InterfaceService } from '../data/data-services/data.interface.service';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { ExternalJobsProvider } from '../interfaces/ExternalJob/externalJobs.provider';
import {
	endOfISOWeek,
	endOfToday,
	endOfTomorrow,
	startOfISOWeek,
	startOfToday,
	startOfTomorrow,
	subWeeks,
} from 'date-fns';
import { DriverStatistics, JobStatisticsSummary, JobSummary } from '../statistics/Statistics';
import {
	calculateJobsStatisticsForTimeRange,
	calculateStatisticsForTimeRange,
	calculateStatisticsMapForDrivers,
} from '../statistics/statistics.functions';
import { DashboardStatisticsModes } from './DashboardClasses';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { DashboardNews } from '@flutaro/package/lib/model/DashboardNews';
import { FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { Relation } from '@flutaro/package/lib/model/Relation';
import { DateInterval } from '@flutaro/package/lib/functions/DataDateFunctions';
import { AppBasicComponent } from '../app/components/app.components.basic.component';
import { filterJobsStartingInTimeRange } from '@flutaro/package/lib/functions/job/FlutaroJobFunctions';

@Component({
	selector: 'app-dashboard-dispatcher',
	templateUrl: './dashboard.dispatcher.component.html',
	styleUrls: ['./dashboard.dispatcher.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardDispatcherComponent extends AppBasicComponent implements OnChanges, OnDestroy {
	@Input() relations: Relation[];
	@Input() news: DashboardNews[];
	@Input() loading: boolean;
	@Input() events: Map<string, FlutaroEvent[]>;
	jobStatisticsSummary: JobStatisticsSummary = new JobStatisticsSummary();
	jobSummary: JobSummary = new JobSummary();
	driverStatistics: Map<string, DriverStatistics> = new Map<string, DriverStatistics>();
	dashboardMode = DashboardStatisticsModes;
	activeStatistic: string;
	startDate: Date;
	endDate: Date;
	today: Date = new Date();
	unassignedJobs: JobWrapper[] = [];
	statisticsMinDate = subWeeks(new Date(), 6);
	isIndividualDataLoad: boolean;

	constructor(
		public jobService: FlutaroJobService,
		public interfaceProvider: InterfaceService,
		public externalJobsProvider: ExternalJobsProvider,
	) {
		super();
		this.showTodaysStatistics();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['jobs']) {
			this.calculateStatistics(this.jobs);
			this.calculateDriverStatistics();
		}
		if (changes['events']) this.calculateDriverStatistics();
	}

	ngOnDestroy() {
		if (this.isIndividualDataLoad) this.jobService.getAndSetDataForCurrentTimetable();
	}

	setDashboardDates(dates: DateInterval) {
		this.startDate = dates.start;
		this.endDate = dates.end;
	}

	async setDashboardDatesFromDateRangeComponent(interval: DateInterval) {
		this.isIndividualDataLoad = true;
		this.setDashboardDates(interval);
		const jobs = await this.jobService.getDataFromServerForTimeRange(interval.start, interval.end);
		this.calculateStatistics(jobs);
	}

	showTodaysStatistics() {
		this.activeStatistic = DashboardStatisticsModes.TODAY;
		this.startDate = startOfToday();
		this.endDate = endOfToday();
		if (!this.jobs) return;
		this.calculateStatistics(this.jobs);
	}

	showTomorrowsStatistics() {
		this.activeStatistic = DashboardStatisticsModes.TOMORROW;
		this.startDate = startOfTomorrow();
		this.endDate = endOfTomorrow();
		if (!this.jobs) return;
		this.calculateStatistics(this.jobs);
	}

	showWeeksStatistics() {
		this.activeStatistic = DashboardStatisticsModes.WEEK;
		this.startDate = startOfISOWeek(new Date());
		this.endDate = endOfISOWeek(new Date());
		if (!this.jobs) return;
		this.calculateStatistics(this.jobs);
	}

	showIndividualStatistics() {
		this.activeStatistic = DashboardStatisticsModes.INDIVIDUAL;
	}

	createUnassignedJobsList(jobs: JobWrapper[]) {
		this.unassignedJobs = filterJobsStartingInTimeRange(jobs, this.startDate, this.endDate, true);
	}

	protected calculateDriverStatistics() {
		this.driverStatistics = calculateStatisticsMapForDrivers(this.drivers, this.events, this.startDate, this.endDate);
	}

	private calculateStatistics(jobs: JobWrapper[]) {
		this.jobStatisticsSummary = calculateStatisticsForTimeRange(jobs, this.startDate, this.endDate);
		this.calculateJobStatistics(jobs);
	}

	private calculateJobStatistics(jobs: JobWrapper[]) {
		this.createUnassignedJobsList(jobs);
		this.jobSummary = calculateJobsStatisticsForTimeRange(jobs, this.startDate, this.endDate);
	}
}

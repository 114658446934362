import { FormArray, FormControl } from '@angular/forms';
import { clearFormValidation } from '../../form/FlutaroFormFunctions';
import { isAfter, isBefore } from 'date-fns';
import { isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';

export function adjustDestinationsFormDatesOnDateChange(
	destinationsForm: FormArray,
	index: number,
	isEarliestChange: boolean,
) {
	if (!destinationsForm) {
		console.error('ERROR: Expected destinationForm. Aborting.');
		return;
	}
	const destinationsEarliestDate: Date = destinationsForm.value[index].earliestDate;
	const destinationsPlannedDate = destinationsForm.value[index].plannedDate;
	const destinationsLatestDate: Date = destinationsForm.value[index].latestDate;
	if (!destinationsLatestDate) return;

	if (
		isEarliestChange ||
		!destinationsPlannedDate ||
		!isValidDate(destinationsPlannedDate) ||
		isBefore(destinationsPlannedDate, destinationsEarliestDate) ||
		isAfter(destinationsPlannedDate, destinationsLatestDate)
	) {
		destinationsForm.controls[index].patchValue({
			plannedDate: destinationsEarliestDate,
		});
		if (!destinationsForm.controls[index].get('plannedDate').valid)
			clearFormValidation(<FormControl>destinationsForm.controls[index].get('plannedDate'));
	}
	if (
		!destinationsLatestDate ||
		!isValidDate(destinationsLatestDate) ||
		isAfter(destinationsEarliestDate, destinationsLatestDate)
	) {
		destinationsForm.controls[index].patchValue({
			latestDate: destinationsEarliestDate,
		});
		if (!destinationsForm.controls[index].get('latestDate').valid)
			clearFormValidation(<FormControl>destinationsForm.controls[index].get('latestDate'));
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { JobPairing } from '@flutaro/package/lib/model/Positiondata';

@Component({
	selector: 'app-map-planning-toolbar',
	templateUrl: './map-planning-toolbar.component.html',
	styleUrls: ['./map-planning-toolbar.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('toolbarAnimation', [
			transition(':enter', [style({ top: 0, opacity: 0 }), animate('500ms', style({ top: 64, opacity: 1 }))]),
			transition(':leave', [style({ top: 64, opacity: 1 }), animate('500ms', style({ top: 0, opacity: 0 }))]),
		]),
	],
})
export class MapPlanningToolbarComponent {
	@Output() showOverview: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() jobPairingFilter: EventEmitter<JobPairing | null> = new EventEmitter<JobPairing | null>();

	constructor() {}

	goToOverview() {
		this.showOverview.emit(true);
	}

	jobPairingFilterChange(pairingFilter: JobPairing | null) {
		console.log(`jobPairingSelectChange, new pairing filter: ${pairingFilter}`);
		this.jobPairingFilter.emit(pairingFilter);
	}
}

<div [@sidenavAnimation] class="flutaro-sidenav">
	<mat-toolbar class="flutaroSidenavHeader">
		<div *ngIf="sidenavProvider.sideNavData.jobIsEdited">
			<div class="saveButtonSidenavActiveBackground"></div>
			<p class="saveButtonSidenavActiveText">{{ 'SAVE_ACTION' | translate }}</p>
		</div>
		<button
			(click)="saveJob($event)"
			[disabled]="userProfile.hasReadOnlyRole || !sidenavProvider.sideNavData.jobIsEdited"
			[matTooltipPosition]="'below'"
			class="buttonSidenavMain saveButtonSidenavs"
			mat-button
			matTooltip="{{ 'SAVE' | translate }}"
		>
			<i class="material-icons sidenavButtonIcon"> done </i>
		</button>
		<button
			(click)="closeSideNav($event)"
			[matTooltipPosition]="'below'"
			class="buttonSidenavMain closeButtonSidenavs"
			mat-button
			matTooltip="{{ 'CLOSE_TT' | translate }}"
		>
			<i class="material-icons sidenavButtonIcon"> close </i>
		</button>

		<h1 class="mat-toolbar-tools sideNavheadlineJob">
			<div class="sidenavJobToolbarHeadlineUnderline">
				<!-- <i class="material-icons sidenavToolbarHeadlineIcon">description</i>  -->
				{{ job.job.identifier }}
			</div>
		</h1>
		<button
			(click)="jobHelperService.openJobEditDialog(job)"
			[matTooltipPosition]="'below'"
			class="buttonSidenavMainOpenJobDialog"
			mat-button
			matTooltip="{'OPEN_JOBDIALOG_TT' | translate}}"
		>
			<i class="material-icons sidenavButtonOpenJobDialogIcon"> launch </i>
		</button>
	</mat-toolbar>
	<div
		[style.height]="isOpenTimetableToolbar ? 'calc(100vh - 64px - 64px - 64px)' : 'calc(100vh - 64px - 64px)'"
		class="sidenavFixedHeader padding5 flutaro-sidenav-inner"
		fxLayout="column"
	>
		<div fxLayout="row">
			<flutaro-job-status
				[status]="job.job.status"
				[userProfile]="userProfile"
				[deactivatePlannedStatus]="!!job.appSettings.appUserEmail_UID"
				[vehicleId]="job.vehicleId"
				(statusChange)="changeJobsStatus($event)"
				fxFlex
			></flutaro-job-status>

			<button
				(click)="sendJobToApp()"
				*ngIf="!job.appSettings.inSyncWithApp && !(userProfile | isReadonly)"
				[disabled]="sidenavProvider.sideNavData.jobIsEdited"
				[matTooltipPosition]="'left'"
				class="mat-raised mat-small mat-ink-ripple"
				id="dispatchToDriverSidenav"
				matTooltip="{{ 'DISPATCHTODRIVER_SIDENAV_TT' | translate }}"
			>
				<div class="sendToAppUserIcon sendToAppUserIconPositioning"></div>
			</button>
			<button
				(click)="deleteJobFromAppUser()"
				*ngIf="job.appSettings.driverUID"
				[disabled]="sidenavProvider.sideNavData.jobIsEdited"
				[matTooltipPosition]="'left'"
				color="warn"
				id="deleteJobFromAppUser"
				matTooltip="{{ 'DELETEJOBFROMAPPUSER_TT' | translate }}"
			>
				<i class="material-icons deleteJobFromAppUserIcon">phonelink_erase</i>
			</button>
		</div>
		<mat-form-field *ngIf="job.job.status === 6" class="jobSidenavDeclinedReasonDiv" style="width: 100%">
			<textarea
				[(ngModel)]="job.appSettings.declineReason"
				cdkAutosizeMaxRows="5"
				cdkAutosizeMinRows="1"
				disabled
				matInput
				placeholder="{{ 'DECLINEREASON_PLACEHOLDER' | translate }}"
				style="color: #505759"
			>
			</textarea>
		</mat-form-field>

		<app-job-app-history *ngIf="job.appSettings" [appSettings]="job.appSettings"> </app-job-app-history>

		<div class="jobEditSidenavRow marginTop10" fxLayout="row" fxLayoutAlign="center center">
			<div class="flutaroDescriptionBackground width70" fxLayout="row" fxLayoutAlign="center center">
				<p class="jobEditSidenavDrivingTime">{{ 'DRIVING_TIME' | translate }}</p>
			</div>
			<div class="jobEditValueBackground" fxLayout="row" fxLayoutAlign="center center">
				<p class="jobEditValue">
					{{
						!job.vehicleId || !job.costCalculation
							? (job.totalTimeInMinutes | minutesToDrivingTime)
							: (job.costCalculation.emptyDistanceTravelTime + job.costCalculation.loadedDistanceTravelTime
							  | minutesToDrivingTime)
					}}
				</p>
			</div>
			<mat-divider></mat-divider>
			<div class="jobEditValueBackground" fxLayout="row" fxLayoutAlign="center center">
				<p class="jobEditValue">
					{{
						!job.vehicleId || !job.costCalculation
							? (job.totalDistance | metersInKm)
							: (job.costCalculation.emptyDistance + job.costCalculation.loadedDistance | metersInKm)
					}}
					km
				</p>
			</div>
			<div class="flutaroDescriptionBackground width70" fxLayout="row" fxLayoutAlign="center center">
				<p class="jobEditSidenavDistance">{{ 'DISTANCE' | translate }}</p>
			</div>
		</div>

		<div fxLayout="column" class="marginTop10">
			<div fxLayout="row" fxLayoutAlign="start center" class="info-box-jobSidenav emptyDistanceDashboard">
				<i class="material-icons">more_horiz</i>
				<div class="count marginRight10">
					{{
						job.vehicleId && job.costCalculation
							? (job.costCalculation.emptyDistance | metersInKm) + ' km'
							: ('NOT_PLANNED' | translate)
					}}
				</div>
				<div class="title">
					{{ 'EMPTYDISTANCE' | translate }}
					<span *ngIf="job.vehicleId && job.costCalculation" class="desc">
						({{
							job.costCalculation.emptyDistance /
								(job.costCalculation.emptyDistance + job.costCalculation.loadedDistance) | numberInPercentage
						}}
						%)
					</span>
				</div>
			</div>
			<div class="info-box-jobSidenav jobKPI" fxLayout="row" fxLayoutAlign="start center">
				<i class="material-icons">timeline</i>
				<div class="count marginRight10">
					{{ job.vehicleId ? (job.costCalculation.profit | currency: 'EUR') : ('NOT_PLANNED' | translate) }}
				</div>
				<div class="title">{{ 'PROFIT' | translate }}</div>
			</div>
		</div>
		<div fxLayout="column" fxLayoutGap="10px">
			<mat-accordion *ngIf="!userProfile.hasReadOnlyRole" fxFlex="100">
				<mat-expansion-panel class="jobSidenavCostExpansionPanel">
					<mat-expansion-panel-header [collapsedHeight]="'35px'">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">show_chart</i> {{ 'REVENUE_AND_COSTS' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxLayout="column" fxLayoutGap="10px">
						<div
							class="jobEditSidenavRow flutaroDescriptionBackground"
							fxLayout="row"
							fxLayoutAlign="space-around center"
						>
							<p class="accordionRowText">
								<i class="material-icons sidenavCostRowIcon">euro_symbol</i>
								{{ 'REVENUE' | translate }}
							</p>
							<div fxLayout="row" fxLayoutAlign="center center">
								<p class="accordionRowValueBackgroundValue accordionRowValueBackground">
									{{ job.revenue | currency: 'EUR' }}
								</p>
							</div>
						</div>
						<div
							class="jobEditSidenavRow flutaroDescriptionBackground"
							fxLayout="row"
							fxLayoutAlign="space-around center"
						>
							<p class="accordionRowText">
								<i class="material-icons sidenavCostRowIcon">attach_money</i>
								{{ 'COSTS' | translate }}
							</p>

							<p class="accordionRowValueBackgroundValue accordionRowValueBackground">
								{{ job.costCalculation.totalCosts | currency: 'EUR' }}
							</p>
						</div>
						<mat-divider class="marginTop10"></mat-divider>
						<div
							*ngIf="job.vehicleId"
							class="jobSidenavCostDescriptionBackground"
							fxLayout="row"
							fxLayoutAlign="space-between center"
						>
							<p class="jobSidenavCostDecriptionValue" fxLayout="row" fxLayoutAlign="start center">
								<i class="material-icons greyColorMediumSize">attach_money</i>
								{{ 'COST_CALCULATION' | translate }}
							</p>
							<button
								(click)="showCostsDialog()"
								[matTooltipPosition]="'left'"
								class="mat-small jobSidenavCostDialog"
								mat-button
								matTooltip="{{ 'COST_CALCULATION_START_TT' | translate }}"
							>
								<i class="material-icons driverSidenav-block-icon">chevron_right</i>
							</button>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>

		<p class="dividerSidenavDottedBeforeVehicle"></p>

		<app-sidenav-job-destination
			[(jobWrapper)]="job"
			(jobWrapperChange)="updateJobIsEdited($event)"
			[userProfile]="userProfile"
		>
		</app-sidenav-job-destination>

		<p class="dividerSidenavDottedBeforeVehicle"></p>
		<app-select-vehicle-for-job
			[vehicles]="vehicles"
			[drivers]="drivers"
			[job]="job"
			[userProfile]="userProfile"
			(vehicle)="changeVehicleAndPreview($event)"
			[componentLayout]="'column'"
			[companySettings]="companySettings"
			[selectedDate]="timetable.selectedDate"
		>
		</app-select-vehicle-for-job>

		<p class="dividerSidenavDotted"></p>
		<div fxLayout="column" fxLayoutGap="10px">
			<div class="flutaroSideNavComponentBasicStyle" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
				<i class="material-icons jobSidenavIconSmall">business</i>
				<span class="flutaroSidenavLabel">{{ 'CLIENT' | translate }}:</span>
				<div>{{ job.job.clientsName }}</div>
			</div>
			<div class="flutaroSideNavComponentBasicStyle" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
				<i class="material-icons jobSidenavIconSmall">directions_bus_filled</i>
				<span class="flutaroSidenavLabel">{{ 'VEHICLETYPE_REQUIREMENTS' | translate }}:</span>
				<div>{{ job.job.requiredVehicle | translateVehicleType }}</div>
			</div>
			<div
				class="flutaroSideNavComponentBasicStyle flutaroSideNavLastComponentBasicStyle"
				fxLayout="row"
				fxLayoutGap="10px"
				fxLayoutAlign="start center"
			>
				<i class="material-icons jobSidenavIconSmall">assignment_turned_in</i>
				<span class="flutaroSidenavLabel">{{ 'JOB_REQUIREMENTS' | translate }}:</span>
				<div>{{ job.job.requirements.toString() }}</div>
			</div>
		</div>
	</div>
</div>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { MenuComponentProvider } from './menu.component.provider';
import { ContractorDataProvider } from '../contractor/contractor.data.provider';
import { FlutaroClientService } from '../data/data-services/data.client.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { WebAuthService } from '../app/auth/web-auth.service';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { APP_MENU } from './MenuClasses';
import { CompanyService } from '../company/company.service';

@Component({
	selector: 'app-menu-setup',
	templateUrl: './menu.setup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupMenuComponent implements OnInit {
	MENU = APP_MENU;
	constructor(
		public menuProvider: MenuComponentProvider,
		public contractorProvider: ContractorDataProvider,
		public clientService: FlutaroClientService,
		public driverService: FlutaroDriverService,
		public vehicleService: FlutaroVehicleService,
		public authFb: WebAuthService,
		public userSettingsProvider: UserSettingsProvider,
		public companyService: CompanyService,
	) {}

	ngOnInit() {
		const SETUP_BASIC_TABS = [APP_MENU.VEHICLES, APP_MENU.CUSTOMERS, APP_MENU.CONTRACTOR];
		if (!this.companyService.$companySettings.getValue().isDriverDeactivated)
			SETUP_BASIC_TABS.unshift(APP_MENU.DRIVERS);
		this.menuProvider.initTabsAfterMenuChange(SETUP_BASIC_TABS);
	}
}

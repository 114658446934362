import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserSettingsProvider } from './settings.user.provider';
import { UserSettings } from '@flutaro/package/lib/model/UserSettings';
import { AppInternationalizationService } from '../app/app.internationalization.service';
import { AppLanguages } from '@flutaro/package/lib/model/AppClasses';
import { APP_MENU } from '@flutaro/package/lib/model/WebAppClasses';
import { VEHICLE_PROFILES_SPEED } from '@flutaro/package/lib/model/FlutaroRequirements';
import { AppDialogBasicData } from 'src/app/dialog/AppDialog';

@Component({
	selector: 'app-user-settings',
	templateUrl: './settings.user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsUserComponent implements OnInit {
	public userSettings: UserSettings;
	vehicleSpeedProfiles = VEHICLE_PROFILES_SPEED;
	PLANNING_VIEWS = APP_MENU;

	constructor(
		public userProvider: UserSettingsProvider,
		@Inject(MatDialogRef) public dialogRef: MatDialogRef<SettingsUserComponent>,
		private languageService: AppInternationalizationService,
		@Inject(MAT_DIALOG_DATA) public dialogData: AppDialogBasicData,
	) {}

	ngOnInit() {
		this.userSettings = this.dialogData.userSettings;
	}

	changeAppLanguage(languageCode: AppLanguages) {
		console.log(`Changing App-Language to languageCode: ${languageCode}`);
		this.languageService.changeAppLanguage(<AppLanguages>languageCode);
	}

	saveProfileChanges() {
		this.userProvider.updateUserSettings(this.userSettings);
		this.dialogRef.close(true);
	}

	abbortProfileChanges() {
		this.dialogRef.close(false);
	}
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { WebAuthService } from '../auth/web-auth.service';

@Injectable()
export class RoleGuardService implements CanActivate {
	constructor(public authFb: WebAuthService, public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		// this will be passed from the route config
		// on the data property
		const expectedRole = route.data.expectedRole;
		const readOnlyRole = route.data.readOnlyRole;
		let isAuthorized = this.authFb.$userIsAuthorized.getValue();

		if (!isAuthorized) {
			this.router.navigate(['login']);
			return false;
		}

		if (readOnlyRole && this.authFb.getUserProfile().hasReadOnlyRole) {
			return true;
		}

		if (this.authFb.getUserProfile().roles.indexOf(expectedRole) === -1) {
			if (this.authFb.getUserProfile().hasReadOnlyRole) {
				this.router.navigate(['planning']);
			}
			return false;
		}

		return true;
	}
}

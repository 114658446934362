<mat-toolbar fxLayoutAlign="center center">
	<h1 class="mat-toolbar-tools dialogHeadline">
		<i class="material-icons dialogIconBackground vehicleDialogIconBackground"></i>
		<i class="material-icons dialogIconToolbar">local_shipping</i>
		{{ 'VEHICLE' | translate }} <span class="dialogToolbarObjectName">{{ vehicle.name }}</span>
		<span *ngIf="contractorName" class="dialogToolbarContractor"
			>➝ {{ 'SUBCONTRACTOR' | translate }} : {{ contractorName }}</span
		>
	</h1>
</mat-toolbar>
<form (ngSubmit)="onSave()" [formGroup]="vehicleForm">
	<mat-dialog-content
		class="layout-padding layout-margin fullWidth"
		fxLayout="row"
		fxLayout.sm="column"
		fxLayout.xs="column"
		fxLayoutGap="10px"
	>
		<div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" class="fullWidth">
			<div fxLayout="column">
				<h4 class="dialogHeadlineContent">
					<i class="material-icons dialogIcon">assignment</i>
					{{ 'VEHICLE_DATA' | translate }}
				</h4>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input formControlName="name" matInput placeholder="Name" required />
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input
							formControlName="licensePlate"
							matInput
							name="licensePlate"
							placeholder="{{ 'LICENSEPLATE' | translate }}"
							required
						/>
					</mat-form-field>
				</div>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
					<mat-slide-toggle
						fxFlex="50"
						formControlName="isActivatedForPlanning"
						[disabled]="!companySettings.isDriverDeactivated"
						*ngIf="vehicleForm.get('type').value !== 'Trailer'"
					>
						{{ 'IS_ACTIVATED_FOR_PLANNING' | translate }}
					</mat-slide-toggle>

					<div fxLayout="column" fxFlex="50" fxLayoutAlign="center start">
						<p *ngIf="!companySettings.isDriverDeactivated && vehicleForm.get('type').value !== 'Trailer'">
							Current Driver: {{ vehicle | getVehiclesDriverNameNow : dialogData.drivers }}
						</p>
						<p *ngIf="!companySettings.isTrailerDeactivated && vehicleForm.get('type').value !== 'Trailer'">
							Current Trailer: {{ vehicle | getVehiclesTrailerNameNow }}
						</p>
					</div>
				</div>

				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">
					<app-address-search
						[(synonym)]="vehicle.depotAddress"
						[required]="vehicleForm.get('isDepotAddressBased').value && vehicleForm.get('type').value !== 'Trailer'"
						placeholder="{{ 'VEHICLE_DEPOT_ADDRESS' | translate }}"
						[enableLocations]="true"
						($isInvalid)="isAddressInvalid = $event"
						fxFlex="50"
					>
					</app-address-search>
					<mat-slide-toggle
						formControlName="isDepotAddressBased"
						[disabled]="companySettings.isDriverDeactivated"
						fxFlex="50"
						class="isDepotAddressBasedSlider"
						(change)="cd.detectChanges()"
						*ngIf="vehicleForm.get('type').value !== 'Trailer'"
					>
						{{ 'IS_DEPOT_ADDRESS_BASED_CALCULATION_VEHICLE' | translate }}
					</mat-slide-toggle>
				</div>

				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
					<mat-form-field class="matSelectDriverJobDialog" fxFlex>
						<mat-select
							(valueChange)="changeType($event)"
							formControlName="type"
							placeholder="{{ 'VEHICLE_BASIC_TYPE' | translate }}"
						>
							<mat-option *ngFor="let vehicleType of VEHICLE_TYPES" [value]="vehicleType">
								{{ vehicleType.toString() | translate }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="matSelectDriverJobDialog" fxFlex>
						<mat-select formControlName="carType" placeholder="{{ 'VEHICLE_TYPE' | translate }}">
							<mat-option
								*ngFor="let vehicleType of CAR_TYPES | filterVehicleTypesForBasicType : vehicleForm.get('type').value"
								[value]="vehicleType"
							>
								{{ vehicleType.toString() | translate }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div
					*ngIf="vehicleForm.get('type').value !== 'Trailer'"
					fxLayout="row"
					fxLayout.sm="column"
					fxLayout.xs="column"
					fxLayoutGap="10px"
				>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input
							formControlName="vehiclePhone"
							matInput
							placeholder="{{ 'TELEPHONE_VEHICLE' | translate }}"
							type="tel"
						/>
					</mat-form-field>
					<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
						<input
							formControlName="vehicleEmail"
							matInput
							[required]="
								dialogData.userProfile.hasAppActivatedRole && vehicleForm.get('chooseEmailBasedOnVehicle').value
							"
							placeholder="{{ 'EMAIL_VEHICLE' | translate }}"
						/>
						<mat-error *ngIf="vehicleForm.controls['vehicleEmail'].errors?.email">{{
							'EMAIL_ENTER_VALID' | translate
						}}</mat-error>
					</mat-form-field>
				</div>
			</div>

			<div *ngIf="vehicleForm.get('type').value !== 'Trailer'" fxLayout="column">
				<h4 class="dialogHeadlineContentNotFirst">
					<i class="material-icons dialogIcon">touch_app</i>
					{{ 'APP_SETTINGS' | translate }}
				</h4>
				<div fxLayout="column" fxLayoutGap="10px">
					<mat-slide-toggle
						formControlName="chooseEmailBasedOnVehicle"
						matTooltip="{{ 'APP_USER_CHOOSE_EMAIL_TT' | translate }}"
					>
						{{ 'APP_USER_CHOOSE_EMAIL' | translate }}
					</mat-slide-toggle>
				</div>
			</div>

			<div fxLayout="column" *ngIf="vehicleForm.value.type !== 'Trailer'">
				<h4 class="dialogHeadlineContentNotFirst">
					<i class="material-icons dialogIcon">settings_suggest</i>
					{{ 'VEHICLE_CALCULATION' | translate }}
				</h4>
				<div fxLayout="column" fxLayoutGap="10px">
					<mat-slide-toggle formControlName="isSpot" matTooltip="{{ 'VEHICLE_SPOT_TT' | translate }}">
						{{ 'VEHICLE_SPOT' | translate }}
					</mat-slide-toggle>
				</div>
			</div>
		</div>

		<mat-divider></mat-divider>

		<div
			class="layout-padding layout-margin fullWidth"
			fxLayout="row"
			fxLayoutAlign="center start"
			fxLayout.sm="column"
			fxLayout.xs="column"
		>
			<mat-accordion>
				<mat-expansion-panel>
					<mat-expansion-panel-header class="vehicleDialogPanelHeader">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">assignment_turned_in</i>
							{{ 'VEHICLE_CHARACTERISTICS' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxLayout="column">
						<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
							<mat-form-field class="matSelectDriverJobDialog" fxFlex fxFlex.gt-sm="50">
								<mat-select
									formControlName="vehicleProfile"
									matTooltip="{{ 'VEHICLE_SPEED_PROFILE_TT' | translate }}"
									placeholder="{{ 'VEHICLE_SPEED_PROFILE' | translate }}"
									required
								>
									<mat-option *ngFor="let vehicleProfile of vehicleSpeedProfiles" [value]="vehicleProfile.value">
										{{ vehicleProfile.title }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="vehicleForm.controls['vehicleProfile'].errors">{{
									'VEHICLE_SPEED_PROFILE_REQUIRED_MESSAGE' | translate
								}}</mat-error>
							</mat-form-field>
							<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
								<mat-select
									[matTooltipPosition]="'below'"
									formControlName="vehicleWeight"
									matTooltip="{{ 'VEHICLE_WEIGHT_TT' | translate }}"
									placeholder="{{ 'VEHICLE_WEIGHT' | translate }}"
								>
									<mat-option [value]="'7500kg'" class="mat-option-odd">7,5T</mat-option>
									<mat-option [value]="'18000kg'">18T</mat-option>
									<mat-option [value]="'25000kg'" class="mat-option-odd">25T</mat-option>
									<mat-option [value]="'26000kg'">26T</mat-option>
									<mat-option [value]="'36000kg'" class="mat-option-odd">36T</mat-option>
									<mat-option [value]="'40000kg'">40T</mat-option>
									<mat-option [value]="'44000kg'" class="mat-option-odd">44T</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
							<mat-form-field class="matSelectDriverJobDialog" fxFlex>
								<mat-select
									formControlName="specialAttributes"
									multiple
									placeholder="{{ 'VEHICLE_EQUIPMENT' | translate }}"
								>
									<mat-option *ngFor="let equipment of VehicleEquipments" [value]="equipment"
										>{{ equipment | translateRequirement }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
							<mat-form-field fxFlex>
								<input
									formControlName="carmanufacturer"
									matInput
									placeholder="{{ 'VEHICLE_MANUFACTURER' | translate }}"
								/>
							</mat-form-field>
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel>
					<mat-expansion-panel-header class="vehicleDialogPanelHeader">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">straighten</i>
							{{ 'VEHICLE_DIMENSIONS_CAPACITY' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div
						[matTooltipPosition]="'below'"
						fxLayout="row"
						fxLayout.sm="column"
						fxLayout.xs="column"
						fxLayoutGap="10px"
						matTooltip="{{ 'VEHICLE_DIMENSIONS_TT' | translate }}"
					>
						<mat-form-field fxFlex="100" fxFlex.gt-sm="30">
							<input
								[(ngModel)]="vehicle.length"
								[ngModelOptions]="{ standalone: true }"
								matInput
								placeholder="{{ 'VEHICLE_LENGTH' | translate }}"
								type="number"
							/>
						</mat-form-field>
						<mat-form-field fxFlex="100" fxFlex.gt-sm="35">
							<input
								[(ngModel)]="vehicle.width"
								[ngModelOptions]="{ standalone: true }"
								matInput
								placeholder="{{ 'VEHICLE_WIDTH' | translate }}"
								type="number"
							/>
						</mat-form-field>
						<mat-form-field fxFlex="100" fxFlex.gt-sm="30">
							<input
								[(ngModel)]="vehicle.height"
								[ngModelOptions]="{ standalone: true }"
								matInput
								placeholder="{{ 'VEHICLE_HEIGTH' | translate }}"
								type="number"
							/>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
							<input
								[(ngModel)]="vehicle.loadUnitCapacity"
								[ngModelOptions]="{ standalone: true }"
								matInput
								placeholder="{{ 'LOADING_CAPACITY' | translate }}"
								type="number"
							/>
						</mat-form-field>

						<mat-form-field class="matSelectDriverJobDialog" fxFlex="100" fxFlex.gt-sm="50">
							<mat-select
								[(ngModel)]="vehicle.loadUnit"
								[ngModelOptions]="{ standalone: true }"
								placeholder="{{ 'LOADING_UNIT' | translate }}"
							>
								<mat-option *ngFor="let loadUnit of LoadUnits" [value]="loadUnit.title">
									{{ loadUnit.title }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel *ngIf="vehicleForm.get('type').value !== 'Trailer'">
					<mat-expansion-panel-header class="vehicleDialogPanelHeader">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">monetization_on</i>
							{{ 'COSTS_TOLL' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field fxFlex="100" fxFlex.gt-sm="33">
							<mat-select
								[matTooltipPosition]="'below'"
								formControlName="emissionCategory"
								matTooltip="{{ 'EMISSION_CATEGORY_TT' | translate }}"
								placeholder="{{ 'EMISSION_CATEGORY' | translate }}"
							>
								<mat-option [value]="'1'" class="mat-option-odd">EURO I</mat-option>
								<mat-option [value]="'2'">EURO II</mat-option>
								<mat-option [value]="'3'" class="mat-option-odd">EURO III</mat-option>
								<mat-option [value]="'4'">EURO IV</mat-option>
								<mat-option [value]="'5'" class="mat-option-odd">EURO V</mat-option>
								<mat-option [value]="'6'">EURO VI</mat-option>
								<mat-option [value]="'7'" class="mat-option-odd">EURO EEV</mat-option>
								<mat-option [value]="'8'">Electric</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field fxFlex="100" fxFlex.gt-sm="33">
							<mat-select
								[matTooltipPosition]="'below'"
								formControlName="axisNumber"
								matTooltip="{{ 'VEHICLE_AXIS_TT' | translate }}"
								placeholder="{{ 'VEHICLE_AXIS' | translate }}"
							>
								<mat-option [value]="2" class="mat-option-odd">2 {{ 'AXIS' | translate }}</mat-option>
								<mat-option [value]="3">3 {{ 'AXIS' | translate }}</mat-option>
								<mat-option [value]="4" class="mat-option-odd">4 {{ 'AXIS' | translate }}</mat-option>
								<mat-option [value]="5">5 {{ 'AXIS' | translate }}</mat-option>
								<mat-option [value]="6" class="mat-option-odd">6 {{ 'AXIS' | translate }}</mat-option>
								<mat-option [value]="7">7 {{ 'AXIS' | translate }}</mat-option>
								<mat-option [value]="8" class="mat-option-odd">8 {{ 'AXIS' | translate }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
						<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
							<!-- TODO: max. two decimals -->
							<input formControlName="kmRate" matInput placeholder="{{ 'VEHICLE_KM_RATE' | translate }}" />
							<mat-error *ngIf="vehicleForm.controls['kmRate'].errors">{{
								'VEHICLE_COSTS_REQUIRED_MESSAGE' | translate
							}}</mat-error>
						</mat-form-field>
						<mat-form-field fxFlex="100" fxFlex.gt-sm="50">
							<input
								formControlName="fixedDailyCosts"
								matInput
								placeholder="{{ 'VEHICLE_FIXED_DAILY_COSTS' | translate }}"
							/>
							<mat-error *ngIf="vehicleForm.controls['fixedDailyCosts'].errors">{{
								'VEHICLE_COSTS_REQUIRED_MESSAGE' | translate
							}}</mat-error>
						</mat-form-field>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel
					*ngIf="!dialogData.companySettings.isDriverDeactivated && vehicleForm.get('type').value !== 'Trailer'"
				>
					<mat-expansion-panel-header class="vehicleDialogPanelHeader">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">today</i>
							{{ 'DRIVER_SCHEDULE' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>

					<app-schedule
						[(vehicle)]="vehicle"
						[drivers]="dialogData.drivers"
						[schedules]="vehicle.driverSchedules"
						[type]="SCHEDULES.DRIVER_SCHEDULE"
						[vehicles]="dialogData.vehicles"
					>
					</app-schedule>
				</mat-expansion-panel>

				<mat-expansion-panel
					*ngIf="
						!dialogData.companySettings.isTrailerDeactivated &&
						(vehicleForm.get('carType').value === 'Truck' || vehicleForm.get('carType').value === 'MegaTruck')
					"
				>
					<mat-expansion-panel-header class="vehicleDialogPanelHeader">
						<mat-panel-title>
							<i class="material-icons dialogIconExpansionPanel">today</i>
							{{ 'TRAILER_SCHEDULE' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<app-schedule
						[(vehicle)]="vehicle"
						[schedules]="vehicle.trailerSchedules"
						[type]="SCHEDULES.TRAILER_SCHEDULE"
						[vehicles]="dialogData.vehicles"
					>
					</app-schedule>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</mat-dialog-content>
	<app-flutaro-audit
		[(tenant)]="vehicle.tenant"
		[companySettings]="dialogData.companySettings"
		[createdAt]="vehicle.createdAt"
		[createdBy]="vehicle.createdBy"
		[lastModifiedAt]="vehicle.lastModifiedAt"
		[lastModifiedBy]="vehicle.lastModifiedBy"
		[userProfile]="dialogData.userProfile"
	>
	</app-flutaro-audit>
	<!--SAVE AND CANCEL-->
	<mat-dialog-actions class="dialogFooterMD2" fxLayout="row" fxLayoutAlign="end none">
		<button (click)="onAbort()" class="importButton mat-raised" mat-button type="button">
			<mat-icon><i class="material-icons">close</i></mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button
			class="importButtonDone mat-primary_flutaro"
			mat-button
			type="submit"
			[disabled]="vehicleForm.invalid || isAddressInvalid || dialogData.userProfile.hasReadOnlyRole"
		>
			<mat-icon><i class="material-icons material-icons-white">done</i></mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</form>

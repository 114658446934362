import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from '@flutaro/package/lib/model/AppClasses';
import ger from '@flutaro/package/src/assets/i18n/ger.json';
import en from '@flutaro/package/src/assets/i18n/en.json';

@Injectable({
	providedIn: 'root',
})
export class AppInternationalizationService {
	constructor(public translate: TranslateService) {
		this.onInit();
	}

	onInit() {
		this.translate.setTranslation('ger', ger);
		this.translate.setTranslation('en', en);
		this.setDefaultLanguage();
	}

	setDefaultLanguage() {
		this.changeAppLanguage(AppLanguages.GERMAN);
		const browserLanguage = this.translate.getBrowserLang(); // TODO: use me for default setting :)
	}

	/**
	 *
	 * @param language: valid values are: 'en', 'ger'
	 */
	changeAppLanguage(language: AppLanguages) {
		if (!language) {
			console.error(`ERROR in changeAppLanguage: No AppLanguage provided.`);
			return;
		}
		console.log(`Setting App language to: ${language}`);
		this.translate.setDefaultLang(language);
	}
}

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DriverDialogData } from 'src/app/dialog/AppDialog';
import { Driver } from '@flutaro/package/lib/model/Driver';
import { TableAddComponent } from '../table/table.add.component';
import { DriverDialogComponent } from './driver.dialog.component';

@Component({
	selector: 'app-driver-add',
	templateUrl: '../table/table.add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverAddComponent extends TableAddComponent {
	dialogComponent = DriverDialogComponent;

	constructor(public dialog: MatDialog) {
		super(dialog);
	}

	createDialogData(): DriverDialogData {
		return new DriverDialogData(this.userSettings, this.userProfile, this.companySettings, new Driver());
	}

	configureDialogAppearance(dialogConfig: MatDialogConfig) {
		dialogConfig.panelClass = 'full-width-dialog';
	}
}

<div *ngIf="createdAt || lastModifiedAt" class="auditMainDiv" fxLayout="row">
	<div class="auditSection">
		<div class="auditCrtDiv" fxLayout="column">
			<div class="auditDivTitle">{{ 'CREATED' | translate }}:</div>
			<div class="auditDivTitle">{{ 'LAST_MODIFIED' | translate }}:</div>
		</div>
		<div class="auditCrtDiv" fxLayout="column">
			<p class="auditSpan">{{ createdAt | relativeTime }}</p>
			<p class="auditSpan">{{ lastModifiedAt | relativeTime }}</p>
		</div>
		<div fxLayout="column">
			<div>
				<b style="color: #40c1ac">{{ 'BY' | translate }}</b>
				<span> {{ createdBy ? createdBy : ('NO_DATA_AVAILABLE' | translate) }}</span>
			</div>
			<div>
				<b style="color: #40c1ac">{{ 'BY' | translate }}</b>
				<span> {{ lastModifiedBy ? lastModifiedBy : ('NO_DATA_AVAILABLE' | translate) }}</span>
			</div>
		</div>
	</div>

	<div *ngIf="userProfile && companySettings?.isTenantActivated">
		<app-tenant
			[(tenant)]="tenant"
			(tenantChange)="tenantChange.emit($event)"
			[companySettings]="companySettings"
			[disabled]="!(userProfile | isTenantAdmin)"
		>
		</app-tenant>
	</div>
</div>

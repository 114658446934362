import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';

import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { DriverAppointment, DriverAppointmentAppointment, DriverAppointments } from './DriverObjects';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlternatingWorkweek, Appointment, Driver, RegularWorkweek } from '@flutaro/package/lib/model/Driver';
import { DriverDialogData } from 'src/app/dialog/AppDialog';
import { updateFormValues } from '../form/FlutaroFormFunctions';
import { DRIVER_LICENSES, DRIVER_QUALIFICATION } from '@flutaro/package/lib/model/FlutaroRequirements';
import { trimStartEndDriverAttributes } from '@flutaro/package/lib/functions/driver/DriverDataFunctions';
import { appSortBy } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { AppSorting } from '@flutaro/package/lib/model/AppClasses';
import { isValidSynonym } from '@flutaro/package/lib/functions/SynonymFunctions';
import { DriverAppointmentSchedule } from '@flutaro/package/lib/model/DriverAppointmentSchedule';

@Component({
	selector: 'app-driver-dialog',
	templateUrl: './driver.dialog.component.html',
	styleUrls: ['./driver.dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDialogComponent implements OnInit {
	driverForm: FormGroup;
	driver: Driver;
	isAppointmentInput: boolean = false;
	isAppointmentEdit: boolean;
	appointmentTypes: DriverAppointment[] = DriverAppointments;
	showAppointmentAddressErrorMessage: boolean = false;
	showAppointmentTypeErrorMessage: boolean = false;
	inputAppointment: Appointment;
	showAppointmentActionErrorMessage: boolean = false;
	isDateValid: boolean = true;
	DriverQualifications = Object.values(DRIVER_QUALIFICATION);
	DriverLicenses = Object.values(DRIVER_LICENSES);
	private originalAppointment: DriverAppointmentSchedule;

	constructor(
		public dialogRef: MatDialogRef<DriverDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: DriverDialogData,
		private driverService: FlutaroDriverService,
		private notifications: FlutaroNotificationsProvider,
		private vehicleProvider: FlutaroVehicleService,
		private fb: FormBuilder,
	) {}

	private static transformInputAppointment(appointmentInput: Appointment): Appointment {
		let appointment: Appointment = new Appointment();
		appointment.appointmentType = appointmentInput.appointmentType;
		appointment.location = appointmentInput.location;
		appointment.note = appointmentInput.note;
		appointment.startDate = appointmentInput.startDate;
		appointment.endDate = appointmentInput.endDate;
		return appointment;
	}

	ngOnInit() {
		this.driver = cloneDeep(this.dialogData.data);
		this.initializeFormFields();
	}

	onSave() {
		if (this.isAppointmentInput || this.isAppointmentEdit) {
			this.showAppointmentActionErrorMessage = true;
			return;
		}
		if (!isValidSynonym(this.driver.homeAddress)) {
			this.notifications.showBasicSnackBar('Feld: Adresse ist ungültig.');
			return;
		}
		updateFormValues(this.driverForm, this.driver, []);
		trimStartEndDriverAttributes(this.driver);
		this.driverService.store(this.driver);
		this.dialogRef.close();
	}

	onAbort() {
		this.dialogRef.close();
	}

	/**
	 * Creates a new Appointment and binds it to the view-model
	 */
	createNewAppointment() {
		this.inputAppointment = new Appointment();
		this.isAppointmentEdit = false;
		this.isAppointmentInput = true;
	}

	saveAppointmentInputs() {
		if (
			this.inputAppointment.appointmentType === DriverAppointmentAppointment &&
			!this.inputAppointment.location.latitude
		) {
			this.showAppointmentAddressErrorMessage = true;
			return;
		}

		if (!this.inputAppointment.appointmentType) {
			this.showAppointmentTypeErrorMessage = true;
			return;
		}
		if (this.isAppointmentEdit) {
			this.originalAppointment.appointmentType = this.inputAppointment.appointmentType;
			this.originalAppointment.endDate = this.inputAppointment.endDate;
			this.originalAppointment.location = this.inputAppointment.location;
			this.originalAppointment.note = this.inputAppointment.note;
			this.originalAppointment.startDate = this.inputAppointment.startDate;
		} else {
			this.driver.appointments.push(DriverDialogComponent.transformInputAppointment(this.inputAppointment));
		}
		appSortBy(this.driver.appointments, 'startDate', AppSorting.DESCENDING);
		this.cancelAppointmentInputs();
	}

	cancelAppointmentInputs() {
		this.inputAppointment = null;
		this.isAppointmentInput = false;
		this.isAppointmentEdit = false;
	}

	editAppointment(appointment: DriverAppointmentSchedule) {
		this.isAppointmentEdit = true;
		this.inputAppointment = new DriverAppointmentSchedule();
		this.inputAppointment.appointmentType = appointment.appointmentType;
		this.inputAppointment.location = appointment.location;
		this.inputAppointment.note = appointment.note;
		this.inputAppointment.startDate = appointment.startDate;
		this.inputAppointment.endDate = appointment.endDate;
		this.originalAppointment = appointment;
		this.isAppointmentInput = true;
	}

	deleteAppointment(appointment: DriverAppointmentSchedule) {
		let index = this.driver.appointments.indexOf(appointment);
		if (index > -1) {
			this.driver.appointments.splice(index, 1);
		}
	}

	// In addition to having the isRegularDriver Boolean also reset the Workseet Object
	resetWorkweekSettings(isRegularWorkWeekDriver: boolean) {
		if (isRegularWorkWeekDriver) {
			this.driver.alternatingWorkWeek = null;
			this.driver.regularWorkweek = new RegularWorkweek();
		} else {
			this.driver.regularWorkweek = null;
			this.driver.alternatingWorkWeek = new AlternatingWorkweek();
		}
	}

	private initializeFormFields() {
		this.driverForm = this.fb.group({
			firstName: [this.driver.firstName, [Validators.required]],
			lastName: [this.driver.lastName, [Validators.required]],
			phone: this.driver.phone,
			email: [this.driver.email, [Validators.email]],
			isAlwaysOutside: this.driver.isAlwaysOutside,
			goHomeEveryDay: this.driver.goHomeEveryDay,
			keepCargoDuringAbsence: this.driver.keepCargoDuringAbsence,
		});
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleStatus, VehicleStatusDialogData } from '@flutaro/package/lib/model/VehicleStatus';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VehicleStatusDialogComponent } from '../../vehicle/status/vehicle.status.dialog.component';
import { VehicleStatusStoreChange } from '../component/TimetableComponentClasses';
import { AppBasicComponent } from '../../app/components/app.components.basic.component';
import { lastValueFrom } from 'rxjs';
import { TimetableFilteredEntry } from '../data/TimetableFilterClasses';
import { TimetableVehicleStatus } from '../TimetableClasses';

@Component({
	selector: 'app-timetable-vehicle-entry',
	templateUrl: './timetable-vehicle-entry.component.html',
	styleUrls: ['./timetable-vehicle-entry.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableVehicleEntryComponent extends AppBasicComponent {
	@Output() vehicleStatusStoreChange: EventEmitter<VehicleStatusStoreChange> = new EventEmitter();
	@Input() mapEntry: TimetableFilteredEntry;
	@Input() vehicle: Vehicle;
	constructor(private dialog: MatDialog) {
		super();
	}

	async openStatusDialog(status: VehicleStatus, vehicle: Vehicle) {
		let dialogConfig = new MatDialogConfig();
		dialogConfig.data = new VehicleStatusDialogData(false, status, vehicle, this.userProfile);

		let dialogRef = this.dialog.open(VehicleStatusDialogComponent, dialogConfig);
		const statusChange = await lastValueFrom(dialogRef.afterClosed());
		if (!statusChange) return;
		this.vehicleStatusStoreChange.emit(new VehicleStatusStoreChange(statusChange, false, vehicle));
	}

	trackByStatusesProperties(index, statusEntry: TimetableVehicleStatus) {
		return statusEntry?.status.displayDate.getTime();
	}
}

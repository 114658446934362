import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import concat from 'lodash-es/concat';
import find from 'lodash-es/find';
import groupBy from 'lodash-es/groupBy';
import keys from 'lodash-es/keys';
import orderBy from 'lodash-es/orderBy';
import { DragulaService } from 'ng2-dragula';
import { takeUntil } from 'rxjs/operators';
import { FlutaroRelationsService } from '../data/data-services/data.relations.service';
import { TimetableFilterController } from '../timetable/data/timetable.filter.controller';
import { hideDroppedElementInTimetable } from '../timetable/TimetableFunctions';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { SidenavBasicComponent } from './sidenav.basic.component';
import { getJobIdFromDragAndDropEvent } from '../timetable/component/TimetableComponentFunctions';
import { BehaviorSubject } from 'rxjs';
import { DestinationType, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import {
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
	getLastDestination,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';

@Component({
	selector: 'app-newjobs-sidenav',
	templateUrl: './sidenav.newjobs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavNewjobsComponent extends SidenavBasicComponent {
	@Input() referenceDateFilteredJobs: JobWrapper[] = [];
	@Input() locations: Synonym[];
	@Output() dragChange: EventEmitter<string | null> = new EventEmitter<string | null>();
	filter = { pickup: null, delivery: null };
	filterBackup = {
		singleJobs: [],
		jobsInArea: [],
	};
	$isDrag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	singleJobs: JobWrapper[] = [];
	jobsInArea: JobInArea[] = [];
	groupByStartOrEnd: string = NoDriverSortingGroups.STARTZIP;
	groupByRelations: boolean = false;
	currentExpanded: string;

	constructor(
		public jobProvider: FlutaroJobService,
		private dragulaService: DragulaService,
		protected cd: ChangeDetectorRef,
		private timetableFilterController: TimetableFilterController,
		private relationService: FlutaroRelationsService,
	) {
		super(cd);
		this.subscribeToDragEvents();
	}

	calculateSidenavData() {
		if (!this.referenceDateFilteredJobs) return;
		this.jobsInArea = [];
		this.singleJobs = [];
		// Object: key: zip, value: JobWrapper[]
		let groupedJobs = groupBy(
			this.referenceDateFilteredJobs,
			(jobWrapper) => jobWrapper.destinations[0].position.zip || jobWrapper.destinations[0].position.code,
		);
		if (this.groupByRelations) this.calculateRelationsFromGroupedJobs(groupedJobs);
		keys(groupedJobs).forEach((zip) => {
			if (groupedJobs[zip].length > 1) {
				let jobInArea: JobInArea = new JobInArea();
				jobInArea.label = zip;
				if ((<any>groupedJobs[zip][0]).destinations[0].position.city)
					jobInArea.label = jobInArea.label + ' : ' + (<any>groupedJobs[zip][0]).destinations[0].position.city;
				(<any>jobInArea).jobs = groupedJobs[zip];
				this.jobsInArea.push(jobInArea);
			} else {
				let singleJob = groupedJobs[zip][0];
				this.singleJobs.push(<any>singleJob);
			}
		});
		this.filterBackup.singleJobs = this.singleJobs;
		this.filterBackup.jobsInArea = this.jobsInArea;
		this.filterData();
	}

	filterData() {
		let pickupSearch;
		let deliverySearch;

		if (this.filter.pickup) pickupSearch = this.filter.pickup.toLowerCase().replace(/ /g, '');
		if (this.filter.delivery) deliverySearch = this.filter.delivery.toLowerCase().replace(/ /g, '');
		this.jobsInArea = cloneDeep(this.filterBackup.jobsInArea);
		this.jobsInArea.forEach((area) => {
			area.jobs = area.jobs.filter((job) => this.filterJobs(job, pickupSearch, deliverySearch));
			return area.jobs.length > 0;
		});
		this.singleJobs = this.filterBackup.singleJobs.filter((job) => this.filterJobs(job, pickupSearch, deliverySearch));
		this.sortData();
	}

	calculateRelationsFromGroupedJobs(groupedJobs) {
		keys(groupedJobs).forEach((zip) => {
			let jobsZip =
				this.groupByStartOrEnd === NoDriverSortingGroups.STARTZIP
					? groupedJobs[zip][0].destinations[0].position.zip
					: getLastDestination(<JobWrapper>groupedJobs[zip][0]).position.zip;
			const jobsCountry =
				this.groupByStartOrEnd === NoDriverSortingGroups.STARTZIP
					? groupedJobs[zip][0].destinations[0].position.country
					: getLastDestination(<JobWrapper>groupedJobs[zip][0]).position.country;
			let jobsRelation = this.relationService.getZipsRelation(jobsZip, jobsCountry);
			if (jobsRelation) {
				let relationLabel = 'Relation ' + jobsRelation.name;
				let alreadyExistingRelationArea: JobInArea = find(this.jobsInArea, {
					label: relationLabel,
				});
				if (alreadyExistingRelationArea) {
					alreadyExistingRelationArea.jobs = concat(alreadyExistingRelationArea.jobs, groupedJobs[zip]);
				} else {
					let relationArea: JobInArea = new JobInArea();
					relationArea.label = relationLabel;
					relationArea.color = jobsRelation.color;
					relationArea.showDarkText = jobsRelation.darkTextColor;
					relationArea.jobs = groupedJobs[zip];
					this.jobsInArea.push(relationArea);
				}
				// Delete relation-grouped Jobs from list for grouping of others)
				delete groupedJobs[zip];
			}
		});
	}

	private sortData() {
		this.singleJobs = orderBy(this.singleJobs, ['job.earliestStartDate'], ['asc']);
		this.jobsInArea = orderBy(this.jobsInArea, ['label'], ['asc']);
		this.jobsInArea.forEach((area) => (area.jobs = orderBy(area.jobs, ['job.earliestStartDate'], ['asc'])));
	}

	private filterJobs(job, startSearch, endSearch) {
		if (startSearch && !endSearch) {
			return this.filterJobByDestinationsForUsersSearch(job, startSearch, DestinationType.PICKUP);
		} else if (endSearch && !startSearch) {
			return this.filterJobByDestinationsForUsersSearch(job, endSearch, DestinationType.DELIVERY);
		} else if (endSearch && startSearch) {
			return (
				this.filterJobByDestinationsForUsersSearch(job, endSearch, DestinationType.DELIVERY) &&
				this.filterJobByDestinationsForUsersSearch(job, startSearch, DestinationType.PICKUP)
			);
		} else {
			return true;
		}
	}

	private filterJobByDestinationsForUsersSearch(
		job: JobWrapper,
		usersSearch: string,
		destinationType: DestinationType,
	): boolean {
		return job.destinations.some((destination) => {
			if (destination.locationType !== destinationType) return false;
			const addressString = destination.position.toString();
			const jobsSearchString = job.job.identifier;
			const destinationsSearchString = (addressString + jobsSearchString).toLowerCase().replace(/ /g, '');
			return destinationsSearchString.indexOf(usersSearch) !== -1;
		});
	}

	private subscribeToDragEvents() {
		this.dragulaService
			.drag()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((drag) => {
				this.$isDrag.next(true);
				const sourceRow = <HTMLElement>drag.source;
				// Early abort if same row and abort if unplannedJobs-Sidenav
				if (sourceRow.id !== 'unplanned_job') return;
				const jobsId: string = getJobIdFromDragAndDropEvent(drag);
				this.dragChange.emit(jobsId);
			});
		this.dragulaService
			.dragend()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.$isDrag.next(false);
				this.dragChange.emit(null);
			});
		this.dragulaService
			.out()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((out) => {
				const destinationRow = out.container;
				const sourceRow = <HTMLElement>out.source;
				const jobElement = <HTMLElement>out.el;
				let originInTimetable = sourceRow.id.substring(0, 13) === 'timetable_job';
				// Early abort if same row and if sourceElement is from timetable
				if (destinationRow === sourceRow || originInTimetable || originInTimetable) return;

				if (sourceRow.classList.contains('jobOnDropZoneHover')) sourceRow.classList.remove('jobOnDropZoneHover');
				sourceRow.style.height = '25px';
				const jobsId: string = getJobIdFromDragAndDropEvent(out);
				let job: JobWrapper = this.jobProvider.getElementById(jobsId);
				jobElement.style.marginLeft = this.timetable.getUnitForTimetable(
					this.timetable.getMarginByDateForObjectInTimetable(getJobsFirstPickupDate(job)),
				);
				jobElement.style.width = this.timetable.getUnitForTimetable(
					this.timetable.calculateObjectsWidth(getJobsFirstPickupDate(job), getJobsLastDeliveryDate(job)),
				);
			});
		this.dragulaService
			.over()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((over) => {
				const destinationRow = over.container;
				const sourceRow = <HTMLElement>over.source;
				if (destinationRow === sourceRow || destinationRow.id !== 'unplannedDropZone') return;
				// Set Class for Job on Hover
				if (!destinationRow.classList.contains('jobOnDropZoneHover')) {
					destinationRow.className += ' jobOnDropZoneHover';
				}
			});
		this.dragulaService
			.drop()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((drop) => {
				const destinationRow = drop.target;
				const sourceRow = <HTMLElement>drop.source;
				// Quit if drop in Timetable
				if (destinationRow.id !== 'unplannedDropZone') return;

				hideDroppedElementInTimetable(sourceRow);
				// If drop from unplanned Sidenav in dropZone only recalculate Sidenav Data to display the dragged Job correctly
				if (sourceRow.id === 'unplanned_job') {
					this.calculateSidenavData();
					this.cd.markForCheck();
					return;
				}
				const jobsId: string = getJobIdFromDragAndDropEvent(drop);
				let job: JobWrapper = this.jobProvider.getElementById(jobsId);
				this.timetableFilterController.dispatchJobToUnassigned(job);
			});
	}
}

class JobInArea {
	jobs: JobWrapper[] = [];
	// Area label
	label: string = '';
	color: string = '#40c1ac';
	showDarkText: boolean = false;
}

export const NoDriverSortingGroups = {
	STARTZIP: 'start',
	ENDZIP: 'end',
};

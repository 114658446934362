<button
	(click)="openAddDialog()"
	*ngIf="companySettings && userProfile && userSettings && !userProfile.hasDisableAllCreateDeleteRole"
	[matTooltipPosition]="'below'"
	class="addOpenDialogButton"
	mat-fab
	matTooltip="{{ 'ADD_BUTTON_TT' | translate }}"
>
	<mat-icon class="material-icons">
		<i class="material-icons addOpenIcon1"> add </i>
		<i class="material-icons addOpenIcon2"> add </i>
	</mat-icon>
</button>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { FlutaroSynonymService } from '../data/data-services/data.synonym.service';
import { MenuComponentProvider } from '../menu/menu.component.provider';
import { FlutaroSidenavProvider } from '../sidenav/sidenav.provider';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { TimetableComponentProvider } from './data/timetable.component.service';
import { FlutaroTimetableFilterService } from './data/timetable.data.provider';
import { WebAuthService } from '../app/auth/web-auth.service';
import { TimetableFilterStoreProvider } from './data/timetable.filter.store.provider';
import { UserSettingsProvider } from '../settings/settings.user.provider';
import { APP_MENU } from '../menu/MenuClasses';
import { TimetableService } from './data/timetable.service';
import { PreviewCostRequest } from './TimetableClasses';
import { EventService } from '../event/event.service';
import { endOfDay, setISODay, subWeeks } from 'date-fns';
import { WeekDayChange } from './component/TimetableComponentClasses';
import { FlutaroRelationsService } from '../data/data-services/data.relations.service';
import { DriverStatistics } from '../statistics/Statistics';
import { calculateStatisticsMapForDrivers } from '../statistics/statistics.functions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { CompanyService } from '../company/company.service';
import { ContractorDataProvider } from '../contractor/contractor.data.provider';
import { TimetableJobStoreProvider } from './data/timetable.job.store.provider';

@Component({
	selector: 'app-timetable-menu',
	templateUrl: './timetable.menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableMenuComponent implements OnInit, OnDestroy {
	showPlanningMode: boolean = false;
	$showNoteMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	driverStatistics: Map<string, DriverStatistics> = new Map<string, DriverStatistics>();
	$reloadedJobs: BehaviorSubject<JobWrapper[]> = new BehaviorSubject<JobWrapper[]>([]);
	jobsFromMapReloaded: boolean = false;
	MENU = APP_MENU;
	private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

	constructor(
		public timetableProvider: TimetableComponentProvider,
		public timetableFilterService: FlutaroTimetableFilterService,
		public menuProvider: MenuComponentProvider,
		private changeDetectorRef: ChangeDetectorRef,
		public sidenavProvider: FlutaroSidenavProvider,
		public driverService: FlutaroDriverService,
		public jobsProvider: FlutaroJobService,
		public locationProvider: FlutaroSynonymService,
		public eventService: EventService,
		public vehicleProvider: FlutaroVehicleService,
		public authFb: WebAuthService,
		public filterStore: TimetableFilterStoreProvider,
		public userSettings: UserSettingsProvider,
		public timetableService: TimetableService,
		public relationService: FlutaroRelationsService,
		public companyService: CompanyService,
		public contractorProvider: ContractorDataProvider,
		public timetableJobProvider: TimetableJobStoreProvider,
	) {}

	ngOnInit() {
		this.subscribeToDataSources();
		this.initToolbarSettings();
	}

	initToolbarSettings() {
		if (this.userSettings.$userSettings.getValue().planningSettings.openNotesOnStart) {
			this.$showNoteMode.next(true);
		}
		const userProfile = this.authFb.$userProfile.getValue();
		const userSettings = this.userSettings.$userSettings.getValue();
		const tabs = userProfile.isMapPlanningCompany
			? [APP_MENU.DAY_PLANNING, APP_MENU.WEEK_PLANNING, APP_MENU.MAP_PLANNING]
			: [APP_MENU.DAY_PLANNING, APP_MENU.WEEK_PLANNING];
		const tabIndex = tabs.indexOf(userSettings.planningSettings.defaultTimetableView);
		this.menuProvider.initTabsAfterMenuChange(tabs, tabIndex);
	}

	ngOnDestroy() {
		this.timetableProvider.initNowForSelectedDayOfWeek();
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
	}

	async reloadJobs(jobIDs: string[]) {
		console.log(`reloadJobs, called in timetable-menu-component for jobIDs: ${jobIDs}`);
		// TODO: make me a bulk get-call
		if (this.jobsFromMapReloaded) {
			console.debug(`reloadJobs, jobsFromMapReloaded already called. Aborting`);
			return;
		}
		const loadedJobs = await Promise.all(jobIDs.map((jobID) => this.jobsProvider.getElementByIdFromServer(jobID)));
		this.$reloadedJobs.next(loadedJobs);
		this.jobsFromMapReloaded = true;
	}

	async onWeekDayChange(weekDayChange: WeekDayChange, skipScroll?: boolean) {
		console.debug(`onWeekDayChange called with new weekDayChange`);
		const timetable = this.timetableProvider.$timetable.getValue();
		this.timetableProvider.setTimetablesSelectedDate(
			weekDayChange.selectedDate,
			weekDayChange.forceTimetableRangeRecreate,
		);
		if (!timetable.isDayTimetable && !skipScroll) this.changeTimetableScroll(weekDayChange.selectedWeekday);
		this.timetableProvider.updateDayOfWeek(weekDayChange.selectedWeekday);
	}

	changeTimetableScroll(scrollFactor: number) {
		return this.timetableProvider.setScrollForTimetableByWeekday(scrollFactor);
	}

	changePlanningMode(isPlanningModeActive: boolean) {
		this.showPlanningMode = isPlanningModeActive;
		if (!this.showPlanningMode) this.filterStore.resetPlanningViewRemovedFilter();
	}

	changeNoteMode(isModeActive: boolean) {
		this.$showNoteMode.next(isModeActive);
	}

	closeCurrentSidenav() {
		this.sidenavProvider.sidenavPop();
	}

	processJobDrag(jobId?: string) {
		if (!jobId) {
			this.timetableFilterService.resetTimetableMapValidationsOnDragEnd();
		} else {
			const job = this.jobsProvider.getElementById(jobId);
			this.timetableFilterService.updateTimetableMapOnDragForJobValidations(
				job,
				this.companyService.$companySettings.getValue(),
			);
		}
	}

	handleCostPreviewRequest(request: PreviewCostRequest) {
		if (!request || !request.jobId || !request.vehicleId) {
			this.timetableService.resetCostPreview();
			return;
		}
		this.timetableService.requestPreviewCosts(request);
	}

	private calculateDriverStatistics() {
		const drivers = this.driverService.getData();
		const events = this.eventService.$events.getValue();
		const timetable = this.timetableProvider.$timetable.getValue();
		if (!events?.size || !drivers?.length || !timetable?.selectedDate) {
			console.log(`calculateDriverStatistics, not enough values provided. aborting`);
			return;
		}
		this.driverStatistics = calculateStatisticsMapForDrivers(
			drivers,
			events,
			timetable.selectedDate,
			endOfDay(timetable.selectedDate),
		);
		this.changeDetectorRef.markForCheck();
	}

	private initializeTimetableAndDataAfterTabSwitch(isDay: boolean) {
		console.log(`initializeTimetableAndDataAfterTabSwitch, called`);
		this.timetableProvider.switchTimetableIsDayTable(isDay);
		if (!isDay)
			this.timetableProvider.setScrollForTimetableByWeekday(this.timetableProvider.$selectedDayOfWeek.getValue());
	}

	private subscribeToDataSources() {
		this.timetableProvider.arrowRightSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((arrowRightAction) => {
			const timetable = this.timetableProvider.$timetable.getValue();
			const currentDayOfWeek = this.timetableProvider.$selectedDayOfWeek.getValue();

			let newDayOfWeek = currentDayOfWeek + 1;
			if (newDayOfWeek === 7 && !timetable.isSundayTable) newDayOfWeek = 8;
			if (newDayOfWeek === 9 || (newDayOfWeek === 8 && timetable.isSundayTable)) newDayOfWeek = 1;
			let newWeekDate = setISODay(this.timetableProvider.$timetable.getValue().startDate, newDayOfWeek);
			if (timetable.isDayTimetable && (currentDayOfWeek === 7 || currentDayOfWeek === 8))
				newWeekDate = subWeeks(newWeekDate, 1);

			const weekDayChange = new WeekDayChange(newWeekDate, newDayOfWeek, false);
			this.onWeekDayChange(weekDayChange);
		});
		this.timetableProvider.arrowLeftSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((arrowLeftAction) => {
			const timetable = this.timetableProvider.$timetable.getValue();
			const currentDayOfWeek = this.timetableProvider.$selectedDayOfWeek.getValue();

			let newDayOfWeek = currentDayOfWeek - 1;
			if (newDayOfWeek === 0) newDayOfWeek = timetable.isSundayTable ? 7 : 8;
			if (newDayOfWeek === 7 && !timetable.isSundayTable) newDayOfWeek = 6;
			let newDateOfWeek = setISODay(timetable.startDate, newDayOfWeek);
			if (timetable.isDayTimetable && (currentDayOfWeek === 7 || currentDayOfWeek === 8))
				newDateOfWeek = subWeeks(newDateOfWeek, 1);

			const weekDayChange = new WeekDayChange(newDateOfWeek, newDayOfWeek, false);
			this.onWeekDayChange(weekDayChange);
		});
		let lastWeekDayByScroll = 0; // Prevent timetable from constant recalculations
		this.timetableProvider.$scrollLeft.pipe(takeUntil(this.ngUnsubscribe)).subscribe((scrollLeft) => {
			if (scrollLeft <= 0) return;
			const dayOfWeek = Math.floor(scrollLeft / 576) + 1;
			if (lastWeekDayByScroll === dayOfWeek) return;
			lastWeekDayByScroll = dayOfWeek;
			const weekDayChange = new WeekDayChange(
				setISODay(this.timetableProvider.$timetable.getValue().startDate, dayOfWeek),
				dayOfWeek,
				false,
			);
			this.onWeekDayChange(weekDayChange, true);
		});
		this.menuProvider.$showTimetableToolbar.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showToolbar) => {
			this.changeDetectorRef.markForCheck();
		});
		this.menuProvider.$currentTab.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currentTab) => {
			if ([APP_MENU.DAY_PLANNING, APP_MENU.WEEK_PLANNING, APP_MENU.MAP_PLANNING].indexOf(currentTab) < 0) return;
			let isDay = currentTab === APP_MENU.DAY_PLANNING;
			this.initializeTimetableAndDataAfterTabSwitch(isDay);
		});
		this.eventService.$events.subscribe((newEvents) => {
			this.calculateDriverStatistics();
		});
	}
}

<div fxLayout="column">
	<mat-toolbar fxLayoutAlign="center center">
		<h1 class="mat-toolbar-tools dialogHeadline">
			<i class="material-icons dialogIconBackground datesAdjustDialogIconbackground"></i>
			<i class="material-icons dialogIconToolbar">tune</i>{{ 'ADJUST_DELIVERY' | translate }}
		</h1>
	</mat-toolbar>
	<mat-dialog-content class="layout-margin" fxFlex="100" fxLayoutGap="10px" fxLayout="column">
		<div class="adjustDatesDialogTimeWindowDiv" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
			<p class="adjustDatesDialogTimeWindowText">
				<i class="material-icons sidenavEarlyLatestIcon">timelapse</i>{{ 'TIMEWINDOW_ACTUAL' | translate }}:
			</p>
			<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
				<span class="sideNavEarlyLatestLabel">
					<i class="material-icons sidenavEarlyLatestIcon">access_time</i>{{ 'EARLIESTDATE' | translate }}
				</span>
				<p class="margin0 fontWeight500">
					{{ destination.earliestDate | appFormatDate: 'datetime' }}
				</p>
			</div>
			<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
				<span class="sideNavEarlyLatestLabel">
					<i class="material-icons sidenavEarlyLatestIcon">alarm</i>{{ 'LATESTDATE' | translate }}
				</span>
				<p class="margin0 fontWeight500">
					{{ destination.latestDate | appFormatDate: 'datetime' }}
				</p>
			</div>
		</div>
		<div class="adjustDatesDialogDatepickerTextDiv">
			<app-datetimepicker
				(dateChange)="setDateInDestinationAsEarliestDate($event)"
				[date]="destination.earliestDate"
				[min]="minDate"
				[max]="destination.latestDate"
				[disabled]="disabled"
				placeholder="{{ 'ADJUST_EARLIEST_AND_LATEST_DELIVERY' | translate }}"
				[type]="'datetime'"
			>
			</app-datetimepicker>
		</div>
		<p class="fontWeight500">
			<i class="material-icons jobCostProfileOtherJobsInfoIcon">info_outline</i>
			<i>"{{ 'EARLIEST_DELIVERY' | translate }}"</i> {{ 'AND' | translate }}
			<i>"{{ 'PLANNED_DELIVERY' | translate }}"</i> {{ 'ARE_ADJUSTED' | translate }}
		</p>
	</mat-dialog-content>

	<mat-dialog-actions fxLayout="row" class="dialogFooterMD2" fxLayoutAlign="end none">
		<button mat-button class="buttonCancel mat-raised" (click)="onAbort()">
			<mat-icon>
				<i class="material-icons">close</i>
			</mat-icon>
			{{ 'CANCEL' | translate }}
		</button>
		<button (click)="onSave()" class="buttonDone mat-primary_flutaro" mat-button>
			<mat-icon>
				<i class="material-icons material-icons-white">done</i>
			</mat-icon>
			{{ 'SAVE' | translate }}
		</button>
	</mat-dialog-actions>
</div>

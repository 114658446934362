import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FlutaroNotificationsProvider } from '../notifications/notification.snack.provider';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { environment } from '../../environments/environment';
import { WebAuthService } from '../app/auth/web-auth.service';
import { FlutaroJobService } from '../data/data-services/data.job.service';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { flutaroWait, to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import {
	getAppUserEmailByVehicleOrDriver,
	setJobAppInSyncAttributeOnVehicleChange,
} from '@flutaro/package/lib/functions/communicator/AppCommunicatorFunctions';
import { DeleteJobFromAppUserRequest } from '@flutaro/package/lib/model/communicator/CommunicatorInterfaces';
import { lastValueFrom } from 'rxjs';
import { areStringsEqualIgnoringCaseAndSpaces } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { isAuthorizedForAppAction } from '@flutaro/package/lib/functions/auth/AppAuthFunctions';

@Injectable()
export class CommunicatorServerProvider {
	constructor(
		private http: HttpClient,
		private notifications: FlutaroNotificationsProvider,
		private vehicleProvider: FlutaroVehicleService,
		private driverProvider: FlutaroDriverService,
		private authFb: WebAuthService,
		public jobsProvider: FlutaroJobService,
	) {}

	/**
	 * Sends jobs to App-Drivers and updates internal data from response of successful operation.
	 * @param jobs
	 */
	async sendJobChangesToDriver(jobs: JobWrapper[]): Promise<boolean> {
		if (!jobs.length || !isAuthorizedForAppAction(this.authFb.getUserProfile())) return;
		// Section 1: Email Addresses of Drivers/Vehicles have changed or no App-Settings set for JOb. Updating Job before continuing with actions
		const jobsWithChangedAppUserEmail = this.setCurrentAssignedAppUsersEmail(
			this.filterForJobsWithChangedAppUserEmail(jobs),
		);
		if (jobsWithChangedAppUserEmail.length) {
			this.notifications.showBasicSnackBar(
				`Email Adressen von ${jobsWithChangedAppUserEmail.length} Aufträgen werden aktualisiert... bitte noch einen Moment warten`,
			);
			let [error, successfulOperation] = await to(this.jobsProvider.updateBulk(jobsWithChangedAppUserEmail));
			if (error) {
				this.notifications.showBasicSnackBar(
					`Fehler: Email Adressen konnten nicht aktualisiert werden. Bitte lade die Seite neu und probiere es noch einmal`,
				);
				throw new Error(error);
			}
			await flutaroWait(500);
		}

		// Section 2: Send jobs to AppUsers
		const [error, jobsSentToAppUser] = await to(
			lastValueFrom(
				this.http.post<JobWrapper[]>(
					environment.routingAppApiUrl + '/sendJobChangesToAppUser',
					jobs.map((job) => job.backendId),
				),
			),
		);
		if (error || !jobsSentToAppUser || !jobsSentToAppUser.length) {
			this.notifications.showBasicSnackBar(
				`Fehler: Aufträge konnten nicht an App-Benutzer gesendet werden. Bitte lade die Seite neu und probieren es noch einmal.`,
			);
			throw new Error(error);
		}
		this.notifications.showBasicSnackBar(
			`${jobsSentToAppUser.length} Aufträge zur Übertragung an App User übermittelt`,
		);
		return true;
	}

	filterForJobsWithChangedAppUserEmail(jobs: JobWrapper[]): JobWrapper[] {
		return jobs.filter((job) => {
			const currentAppUserEmail = this.getJobsVehiclesUserEmail(job);
			return !areStringsEqualIgnoringCaseAndSpaces(currentAppUserEmail, job.appSettings.appUserEmail);
		});
	}

	setCurrentAssignedAppUsersEmail(jobs: JobWrapper[]): JobWrapper[] {
		return jobs.map((job) => this.setAppUserEmailInJob(job));
	}

	setAppRelatedJobAttributesOnDriverChange(job: JobWrapper) {
		this.setAppUserEmailInJob(job);
		setJobAppInSyncAttributeOnVehicleChange(job);
	}

	async deleteJobFromAppUserBySidenavAction(job: JobWrapper) {
		const deleteRequest = new DeleteJobFromAppUserRequest(job, false, false);
		return this.API_deleteJobFromAppUser(deleteRequest);
	}

	async deleteJobFromAppUserByDriverChange(job: JobWrapper, isInSync: boolean): Promise<boolean> {
		const deleteRequest = new DeleteJobFromAppUserRequest(job, false, isInSync);
		return this.API_deleteJobFromAppUser(deleteRequest);
	}

	async deleteJobFromAppUserBySystemDelete(job: JobWrapper) {
		const deleteRequest = new DeleteJobFromAppUserRequest(job, true, false);
		return this.API_deleteJobFromAppUser(deleteRequest);
	}

	async API_deleteJobFromAppUser(request: DeleteJobFromAppUserRequest): Promise<boolean> {
		if (!request.jobId || !request.driverUID || !request.appUserEmail_UID) {
			console.error('ERROR in API_deleteJobFromAppUser. Not enough parameters in request provided.');
			throw new Error('ERROR in API_deleteJobFromAppUser. Job didnt fulfill requirements');
		}
		let deletePath = `${environment.routingAppApiUrl}/webapp/${request.jobId}`;

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: request,
		};

		const [deleteError, updatedJob] = await to(lastValueFrom(this.http.delete<JobWrapper>(deletePath, options)));
		if (deleteError) {
			// TODO: translate me
			this.notifications.showErrorMessage(
				`Systemfehler: Auftrag ${request.jobIdentifier} konnte NICHT von App User ${request.appUserEmail_UID} gelöscht werden. Bitte versuche es noch einmal oder wende dich an den Flutaro Support`,
			);
			throw new Error(deleteError);
		}
		this.notifications.showBasicSnackBar(
			`Auftrag ${request.jobIdentifier} wurde von App User ${request.appUserEmail_UID} gelöscht.`,
		);
		return true;
	}

	private getJobsVehiclesUserEmail(job: JobWrapper): string {
		const vehicle = this.vehicleProvider.getElementById(job.vehicleId);
		const driver = this.driverProvider.getElementById(job.driver);
		return getAppUserEmailByVehicleOrDriver(vehicle, driver);
	}

	private setAppUserEmailInJob(job: JobWrapper): JobWrapper {
		job.appSettings.appUserEmail = job.vehicleId ? this.getJobsVehiclesUserEmail(job) : undefined;
		return job;
	}
}

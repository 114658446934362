import {
	differenceInMinutes,
	differenceInWeeks,
	getISOWeek,
	isBefore,
	isSameSecond,
	setHours,
	setISODay,
	setMinutes,
	startOfISOWeek,
} from 'date-fns';
import { getLastEvent, isFirstJobAfterShiftStart, reduceEventCounterByDuration } from './EventFunctions';
import { AppEventCounter, EVENT_PARAMETERS, EVENT_TYPE, FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { DestinationType, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Driver } from '@flutaro/package/lib/model/Driver';
import {
	DateInterval,
	isAfterOrSame,
	isBeforeOrSame,
	isValidDate,
} from '@flutaro/package/lib/functions/DataDateFunctions';
import {
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
} from '@flutaro/package/lib/functions/job/DestinationFunctions';

export function createBreakEvent(jobId: string, date: Date, duration: number) {
	let event = new FlutaroEvent(EVENT_TYPE.BREAK, jobId, duration, date);
	return event;
}

export function createContinuousDrivingBreakEvent(jobId: string, date: Date) {
	let event = new FlutaroEvent(
		EVENT_TYPE.BREAK,
		jobId,
		EVENT_PARAMETERS.CONTINUOUS_DRIVING_RECOVERY_DURATION_STANDARD,
		date,
	);
	return event;
}

export function createDailyRecoveryBreak(jobId: string, startDate: Date) {
	let event = new FlutaroEvent(
		EVENT_TYPE.DAILY_RECOVERY_BREAK,
		jobId,
		EVENT_PARAMETERS.DAILY_RECOVERY_DURATION_STANDARD,
		startDate,
	);
	return event;
}

export function createWaitEvent(jobId: string, startDate: Date, endDate: Date) {
	let event = new FlutaroEvent(EVENT_TYPE.WAITING, jobId, differenceInMinutes(endDate, startDate), startDate);
	return event;
}

export function createEventForType(type: EVENT_TYPE, jobId: string, duration: number, date: Date) {
	let event = new FlutaroEvent(type, jobId, duration, date);
	return event;
}

export function generateEventsTypeJobSchedule(
	type: EVENT_TYPE.EMPTY_DRIVE | EVENT_TYPE.LOADED_DRIVE,
	job: JobWrapper,
	startDate: Date,
	counter: AppEventCounter,
): FlutaroEvent[] {
	let events = [];
	let totalTravelTime =
		type === EVENT_TYPE.EMPTY_DRIVE
			? job.costCalculation.emptyDistanceTravelTime
			: job.costCalculation.loadedDistanceTravelTime;
	if (!totalTravelTime) totalTravelTime = 0;
	let travelCounter = 0;
	let latestEventDate = startDate;

	if (totalTravelTime === 0) {
		const availableDrivingTime_Day = EVENT_PARAMETERS.MAXIMUM_DAILY_DRIVING_DURATION - counter.dailyDrivingTime;
		const driveEvent = createEventForType(type, job.backendId, availableDrivingTime_Day, latestEventDate);
		events.push(driveEvent);
	}

	while (travelCounter !== totalTravelTime) {
		const currentTravelTimeDiff = totalTravelTime - travelCounter;
		const requiredContinuousDrivingTime = currentTravelTimeDiff + counter.continuousDrivingTime;
		const requiredDailyDrivingTime = counter.dailyDrivingTime + currentTravelTimeDiff;
		if (
			requiredContinuousDrivingTime > EVENT_PARAMETERS.MAXIMUM_CONTINUOUS_DRIVING_DURATION ||
			requiredDailyDrivingTime > EVENT_PARAMETERS.DAILY_RECOVERY_DURATION_STANDARD
		) {
			// We need a Break - either 45min or a full 11h break
			const availableContinuousDrivingTime =
				EVENT_PARAMETERS.MAXIMUM_CONTINUOUS_DRIVING_DURATION - counter.continuousDrivingTime;
			if (counter.dailyDrivingTime + availableContinuousDrivingTime > EVENT_PARAMETERS.MAXIMUM_DAILY_DRIVING_DURATION) {
				// We need a big break - lets drive as far as we can and then take a full recovery break
				const availableDrivingTime_Day = EVENT_PARAMETERS.MAXIMUM_DAILY_DRIVING_DURATION - counter.dailyDrivingTime;
				// Create travel for availableDrivingTime_Day
				const driveEvent = createEventForType(type, job.backendId, availableDrivingTime_Day, latestEventDate);
				events.push(driveEvent);
				latestEventDate = driveEvent.endTime;
				const dailyRecoveryEvent = createDailyRecoveryBreak(job.backendId, latestEventDate);
				events.push(dailyRecoveryEvent);
				latestEventDate = dailyRecoveryEvent.endTime;
				counter.dailyDrivingTime = 0;
				counter.continuousDrivingTime = 0;
				travelCounter += availableDrivingTime_Day;
				continue;
			}
			const driveEvent = createEventForType(type, job.backendId, availableContinuousDrivingTime, latestEventDate);
			events.push(driveEvent);
			latestEventDate = driveEvent.endTime;
			const dailyRecoveryEvent = createContinuousDrivingBreakEvent(job.backendId, latestEventDate);
			events.push(dailyRecoveryEvent);
			latestEventDate = dailyRecoveryEvent.endTime;
			counter.continuousDrivingTime = 0;
			counter.dailyDrivingTime += availableContinuousDrivingTime;
			travelCounter += availableContinuousDrivingTime;
		} else {
			const driveEvent = createEventForType(type, job.backendId, currentTravelTimeDiff, latestEventDate);
			events.push(driveEvent);
			latestEventDate = driveEvent.endTime;
			counter.continuousDrivingTime += currentTravelTimeDiff;
			counter.dailyDrivingTime += currentTravelTimeDiff;
			travelCounter += currentTravelTimeDiff;
		}
	}

	return events;
}

export function createLoadedDriveEventForJob(job: JobWrapper, driverEvents: FlutaroEvent[], counter: AppEventCounter) {
	const jobStartDate = getJobsFirstPickupDate(job);
	const deliveryEndDate = getJobsLastDeliveryDate(job);
	let loadedEvents = generateEventsTypeJobSchedule(EVENT_TYPE.LOADED_DRIVE, job, jobStartDate, counter);
	const lastEvent = loadedEvents[loadedEvents.length - 1];
	if (isBefore(lastEvent.endTime, deliveryEndDate)) {
		const waitEvent = createWaitEvent(job.backendId, lastEvent.endTime, deliveryEndDate);
		loadedEvents = loadedEvents.concat([waitEvent]);
		reduceEventCounterByDuration(waitEvent.duration, counter);
	}
	return loadedEvents;
}

export function createBreakEventAfterEmptyDrive(
	job: JobWrapper,
	emptyDriveEvents: FlutaroEvent[],
	counter: AppEventCounter,
): FlutaroEvent[] {
	const lastEvent = emptyDriveEvents[emptyDriveEvents.length - 1];
	const timeDiffInMinutes: number = differenceInMinutes(getJobsFirstPickupDate(job), lastEvent.endTime);
	if (timeDiffInMinutes < 0) return null;
	reduceEventCounterByDuration(timeDiffInMinutes, counter);
	const breakEvents = [createBreakEvent(job.backendId, lastEvent.endTime, timeDiffInMinutes)];
	return breakEvents;
}

export function createEmptyDriveEventForJob(
	job: JobWrapper,
	vehiclesEvents: FlutaroEvent[],
	counter: AppEventCounter,
	driver?: Driver,
) {
	const jobStartDate = getJobsFirstPickupDate(job);
	let startDate: Date;
	// Case 1 : First job or first job of jobs week
	if (!vehiclesEvents.length || isFirstJobAfterShiftStart(job, vehiclesEvents)) {
		if (driver) {
			const workweekStartDay = driver.regularWorkweekDriver
				? driver.regularWorkweek.regularWorkweekStart.weekDay + 1
				: driver.alternatingWorkWeek[
						getISOWeek(getJobsFirstPickupDate(job)) % 2 === 0 ? 'evenWorkweekStart' : 'oddWorkweekStart'
				  ].weekDay + 1;
			const workweekStartHour = driver.regularWorkweekDriver
				? driver.regularWorkweek.regularWorkweekStart.hour + 1
				: driver.alternatingWorkWeek[
						getISOWeek(getJobsFirstPickupDate(job)) % 2 === 0 ? 'evenWorkweekStart' : 'oddWorkweekStart'
				  ].hour + 1;
			const workweekStartMinutes = driver.regularWorkweekDriver
				? driver.regularWorkweek.regularWorkweekStart.minute + 1
				: driver.alternatingWorkWeek[
						getISOWeek(getJobsFirstPickupDate(job)) % 2 === 0 ? 'evenWorkweekStart' : 'oddWorkweekStart'
				  ].minute + 1;
			startDate = setMinutes(
				setHours(setISODay(jobStartDate, workweekStartDay), workweekStartHour),
				workweekStartMinutes,
			);
		} else {
			startDate = startOfISOWeek(getJobsFirstPickupDate(job));
		}
	} else {
		const lastEvent = getLastEvent(vehiclesEvents);
		startDate = lastEvent.endTime;
	}

	const emptyEvents = generateEventsTypeJobSchedule(EVENT_TYPE.EMPTY_DRIVE, job, startDate, counter);
	return emptyEvents;
}

export function createShiftStartEndEvents(driver: Driver, jobs: JobWrapper[]) {
	const jobsTimeRange = new DateInterval(
		getJobsFirstPickupDate(jobs[0]),
		getJobsLastDeliveryDate(jobs[jobs.length - 1]),
	);
	const weekCounter = differenceInWeeks(jobsTimeRange.start, jobsTimeRange.end);
}

export function isValidEventsForJob(job: JobWrapper, events: FlutaroEvent[]): boolean {
	const invalidEvents = events.filter(
		(event) =>
			!isValidDate(event.startTime) || !isValidDate(event.endTime) || !isAfterOrSame(event.endTime, event.startTime),
	);
	if (invalidEvents.length) {
		//console.error(`isValidEventsForJob, ${invalidEvents.length}events dont fulfill basic requirements regarding event-dates. Events:`);
		//console.error(invalidEvents);
		return false;
	}
	const emptyEvents = events.filter((event) => event.type === EVENT_TYPE.EMPTY_DRIVE);
	const isEmptyEventsValid = emptyEvents.every(
		(event) =>
			isBeforeOrSame(event.startTime, getJobsFirstPickupDate(job)) &&
			isBeforeOrSame(event.endTime, getJobsFirstPickupDate(job)),
	);
	const loadedEvents = events.filter((event) => event.type === EVENT_TYPE.LOADED_DRIVE);
	let isLoadedEventsValid = loadedEvents.every(
		(event) =>
			isAfterOrSame(event.startTime, getJobsFirstPickupDate(job)) &&
			isBeforeOrSame(event.endTime, getJobsLastDeliveryDate(job)),
	);
	isLoadedEventsValid =
		isLoadedEventsValid &&
		job.destinations.every((destination, destinationIndex) => {
			const isValid = loadedEvents.some((loadedEvent) =>
				destination.locationType === DestinationType.PICKUP
					? isSameSecond(loadedEvent.startTime, destination.plannedDate)
					: isBeforeOrSame(loadedEvent.endTime, destination.plannedDate) &&
					  isAfterOrSame(loadedEvent.startTime, job.destinations[destinationIndex - 1].plannedDate),
			);
			return isValid;
		});
	return isEmptyEventsValid && isLoadedEventsValid;
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FlutaroClientService } from '../data/data-services/data.client.service';
import { ClientDialogComponent } from './client.dialog.component';
import { FlutaroTableComponent } from '../table/table.component';
import { WebAuthService } from '../app/auth/web-auth.service';
import { Client } from '@flutaro/package/lib/model/Client';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Component({
	selector: 'client-table',
	templateUrl: './client.table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientTableComponent extends FlutaroTableComponent {
	constructor(
		public clientService: FlutaroClientService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public authFb: WebAuthService,
	) {
		super(dialog, clientService, translate);
		this.elementTypeName = this.translate.instant('CLIENT');
		this.collectionName = FlutaroCollection.CLIENT;
		this.displayedColumns = [
			'name',
			'abcClassification',
			'address',
			'officePhone',
			'dispatchPhone',
			'requiredVehicle',
			'edit',
			'delete',
		];
		this.dataDialog = ClientDialogComponent;
	}

	protected buildDataSearchString(client: Client) {
		return client.name + client.dispatchPhone + client.requirements.requiredVehicle + client.contactAddress.toString();
	}
}

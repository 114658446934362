import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import assign from 'lodash-es/assign';
import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';

import { FlutaroVehicleService } from '../../data/data-services/data.vehicle.service';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { DestinationTypeExternal, ExternalJob, ExternalJobsDialogData } from '@flutaro/package/lib/model/ExternalJob';
import { parseElementsBasics } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { DestinationType, JobDestination } from '@flutaro/package/lib/model/Job';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { ExternalJobInCorrection } from './ExternalJobWeb';
import { TranslateService } from '@ngx-translate/core';
import {
	getVehiclesDriverIdByDate,
	isVehicleActivatedForCompanyByDate,
} from '@flutaro/package/lib/functions/VehicleFunctions';
import { JobCosts } from '@flutaro/package/lib/model/costs/Costs';
import { JobAppSettings } from '@flutaro/package/lib/model/AppClasses';
import { VEHICLE_TYPE } from '@flutaro/package/lib/model/VehicleConstants';
import { setDestinationsPlannedDatesToEarliestValidDate } from '@flutaro/package/lib/functions/job/JobDataFunctions';
import { destinationsAreValid } from '@flutaro/package/lib/functions/job/JobValidationFunctions';
import { BehaviorSubject } from 'rxjs';

@Component({
	templateUrl: './externalJob.dialog.component.html',
	styleUrls: ['./externalJob.dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalJobDialogComponent implements OnInit {
	$isInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	externalJobs: ExternalJobInCorrection[];

	constructor(
		public dialogRef: MatDialogRef<ExternalJobDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: ExternalJobsDialogData,
		public vehicleProvider: FlutaroVehicleService,
		private notifications: FlutaroNotificationsProvider,
		private changeDetectorRef: ChangeDetectorRef,
		private translate: TranslateService,
	) {}

	ngOnInit() {
		this.processExternalJobs();
	}

	onSubmit() {
		const externalJobsForRequest = this.externalJobs.filter((extJob) => extJob.isChecked);
		if (!this.externalJobsImportIsValid(externalJobsForRequest)) return;
		this.dialogRef.close(this.createExternalJobsFromJobsInCorrection(externalJobsForRequest));
	}

	setExternalJobsLicensePlateAttributes(licensePlate: string, externalJob: ExternalJob) {
		// TODO: Refactor this into using vehicle-select-component (as in JobSidenav or in Vehicle-DriverSchedule) with Input activatedOnly
		// Goal: externalJobImport Component only implements the component and sets correct attributes on change instead of redundant code
		if (!licensePlate) {
			delete externalJob.vehicleId;
			delete externalJob.driver;
			return;
		}
		const externalJobRefDate = externalJob.fixedJobDestinations[0].earliestDate;
		const vehicle = this.vehicleProvider.getVehicleByLicensePlate(licensePlate);
		if (!vehicle || !isVehicleActivatedForCompanyByDate(vehicle, this.dialogData.companySettings, externalJobRefDate)) {
			// Only active vehicles can be used for job assignments
			delete externalJob.vehicleId;
			delete externalJob.driver;
			return;
		}
		externalJob.vehicleId = vehicle.backendId;
		externalJob.licensePlate = vehicle.licensePlate;
		externalJob.driver = getVehiclesDriverIdByDate(vehicle, externalJobRefDate);
	}

	private processExternalJobs() {
		this.externalJobs = this.dialogData.externalJobs.map((extJob) => {
			let feExtJob = new ExternalJobInCorrection();
			assign(feExtJob, pick(extJob, keys(extJob)));
			parseElementsBasics(feExtJob);
			return feExtJob;
		});
		this.prepareExternalJobsData();
		this.$isInitialized.next(true);
	}

	private prepareExternalJobsData() {
		for (const externalJob of this.externalJobs) {
			if (!externalJob.fixedJobDestinations?.length) externalJob.fixedJobDestinations = [];
			if (!externalJob.destinations?.length) continue;

			for (const destination of externalJob.destinations) {
				let jobDestination: JobDestination = new JobDestination();
				assign(jobDestination, pick(destination, keys(jobDestination)));
				jobDestination.position = new Synonym(
					destination.city,
					destination.zip,
					destination.street,
					destination.country,
				);
				if (jobDestination.locationType === DestinationTypeExternal.DELIVERY)
					jobDestination.locationType = DestinationType.DELIVERY;
				if (jobDestination.locationType === DestinationTypeExternal.PICKUP)
					jobDestination.locationType = DestinationType.PICKUP;
				externalJob.fixedJobDestinations.push(jobDestination);
			}
			this.setExternalJobsLicensePlateAttributes(externalJob.licensePlate, externalJob);
			setDestinationsPlannedDatesToEarliestValidDate(externalJob.fixedJobDestinations);
			externalJob.job.requiredVehicle = externalJob.requiredVehicle;
			externalJob.vehicleLicensePlate = externalJob.licensePlate;
			externalJob.costCalculation = new JobCosts(externalJob.revenue);
			externalJob.appSettings = new JobAppSettings();
		}
		return true;
	}

	private createExternalJobsFromJobsInCorrection(externalJobs: ExternalJobInCorrection[]): ExternalJob[] {
		return externalJobs.map((extJob) => {
			delete extJob.isChecked;
			delete extJob.job;
			delete extJob.contractorId; // TODO: deploy routing.api with updated ExternalJob
			delete extJob.costCalculation;
			delete extJob.appSettings;
			extJob.licensePlate = extJob.vehicleLicensePlate;
			delete extJob.vehicleLicensePlate;
			delete extJob['isLocked'];
			return extJob as ExternalJob;
		});
	}

	private externalJobsImportIsValid(externalJobsForRequest: ExternalJob[]): boolean {
		if (!externalJobsForRequest.length) {
			this.notifications.showBasicSnackBar(this.translate.instant('EXTERNALJOBS_IMPORT_ERROR_NOSELECT'));
			return false;
		}
		const invalidExternalJobs = this.getInvalidExternalJobs(externalJobsForRequest);
		if (invalidExternalJobs.length) {
			const errorMessage =
				invalidExternalJobs.length === 1
					? this.translate.instant('EXTERNALJOBS_IMPORT_ERROR_INVALID_JOB_SELECTED')
					: this.translate.instant('EXTERNALJOBS_IMPORT_ERROR_INVALID_JOBS_SELECTED');
			this.notifications.showBasicSnackBar(
				invalidExternalJobs.length === 1 ? errorMessage : `${invalidExternalJobs.length} ${errorMessage}`,
			);
			return false;
		}
		return true;
	}

	private getInvalidExternalJobs(jobs: ExternalJob[]): ExternalJob[] {
		return jobs.filter((job) => !this.isExternalJobValid(job));
	}

	private isExternalJobValid(job: ExternalJob): boolean {
		const validDestinations = destinationsAreValid(job.fixedJobDestinations);
		const validVehicle = !job.vehicleLicensePlate || !!job.vehicleId;
		const validTenant = !job.invalidTenantId || !!job.tenant?.id;
		const validRequiredVehicle = Object.values<string>(VEHICLE_TYPE).includes(job.requiredVehicle);
		return validDestinations && validVehicle && validTenant && validRequiredVehicle;
	}
}

import { startOfISOWeek } from 'date-fns';
import { AppEventCounter, FlutaroEvent } from '@flutaro/package/lib/model/FlutaroEvent';
import { isAfterOrSame, isBeforeOrSame } from '@flutaro/package/lib/functions/DataDateFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { appSortBy } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { AppSorting } from '@flutaro/package/lib/model/AppClasses';
import { getJobsFirstPickupDate } from '@flutaro/package/lib/functions/job/DestinationFunctions';

export function getTotalTruckTravelTimeForDuration(duration: number) {
	// Below 4,5h BreakTime --> including no additional break time
	if (duration <= 270) {
		return duration;
		// Below 9h BreakTime --> including 45min additional break time
	} else if (duration <= 540) {
		return duration + 45;
		// Below 54h Week BreakTime --> including additional break times for 4,5h break and 11h rest brek
	} else if (duration <= 3240) {
		// Math.floor() (round down)
		// Math.ceil() (round up)
		const days = Math.floor(duration / 540);
		const additionalBreakTime = days * 54 + days * (11 * 60);
		return duration + additionalBreakTime;
	} else {
		// TODO: Implement me with additional weekBreak-Time, implement for n+1 cases
		const days = Math.floor(duration / 540);
		const additionalBreakTime = days * 54 + days * (11 * 60);
		return duration + additionalBreakTime;
	}
}

export function getLastEvent(events: FlutaroEvent[], untilDate?: Date): FlutaroEvent {
	if (!events || !events.length) return null;
	let calculationEvents = events;
	if (untilDate) calculationEvents = calculationEvents.filter((event) => isBeforeOrSame(event.endTime, untilDate));
	const sortedEvents = appSortBy(calculationEvents, 'endTime', AppSorting.DESCENDING);
	return sortedEvents[0];
}

export function isFirstJobAfterShiftStart(job: JobWrapper, driverEvents: FlutaroEvent[]): boolean {
	const start = startOfISOWeek(getJobsFirstPickupDate(job));
	const eventsAfterStart = driverEvents.filter((event) => isAfterOrSame(event.endTime, start));
	return !eventsAfterStart.length;
}

export function reduceEventCounterByDuration(duration: number, counter: AppEventCounter) {
	counter.continuousDrivingTime - duration < 0
		? (counter.continuousDrivingTime = 0)
		: (counter.continuousDrivingTime = counter.continuousDrivingTime - duration);
	counter.dailyDrivingTime - duration < 0
		? (counter.dailyDrivingTime = 0)
		: (counter.dailyDrivingTime = counter.dailyDrivingTime - duration);
}

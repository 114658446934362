<mat-toolbar fxLayout="row" fxLayoutAlign="start center">
	<div fxLayout="row" fxLayoutAlign="start center">
		<img alt="flutaro_logo" class="toolbarLogoMain hidden-xs" src="assets/pictures/Flutaro_Logo_white.svg" />
		<img alt="flutaro_logo" class="toolbarLogoMain hidden-xl" src="assets/pictures/Flutaro_Logo_white_iconOnly.svg" />
		<button
			(click)="sidenavProvider.openFlutaroSidenav()"
			*ngIf="authFb.$userProfile | async | hasAphroditeAccess"
			[matTooltipPosition]="'below'"
			class="mainToolbarButton"
			mat-button
			matTooltip="{{ 'MENU_MAIN_TT' | translate }}"
		>
			<mat-icon class="material-icons mainToolbarIcon">menu</mat-icon>
			<div class="mainToolbarLabel">{{ 'MENU' | translate }}</div>
		</button>
	</div>
	<!--(selectedIndexChange)="changeTab()" -->
	<mat-tab-group
		[disablePagination]="true"
		[(selectedIndex)]="menuProvider.currentTabIndex"
		(selectedTabChange)="changeTab()"
		fxLayoutAlign="start center"
	>
		<mat-tab *ngFor="let tab of menuProvider.$currentTabs | async">
			<ng-template mat-tab-label>
				<!-- TODO: @Gerrit: can we find an Icon for every tab we have? -->
				<mat-icon *ngIf="false">thumb_up</mat-icon>
				{{ tab | translate }}
			</ng-template>
		</mat-tab>
	</mat-tab-group>
	<div fxLayout="row" fxLayoutAlign="end center">
		<div *ngIf="menuProvider.showSearch && menuProvider.isSearchActive" class="hidden-xs searchDiv" fxLayout="row">
			<mat-divider class="headerSearchBarDivider"></mat-divider>
			<mat-form-field class="searchField" mat-theme="searchField" style="width: 170px; margin: 0; margin-top: -1px">
				<i class="material-icons searchField-icon">search</i>
				<input
					(keydown)="searchInputKeydown($event)"
					[ngModel]="searchService.query.filter"
					(ngModelChange)="setQueryFilter($event)"
					appAutofocus
					class="mainSearchFieldInput"
					matInput
					placeholder="{{ 'SEARCH_2' | translate }}"
				/>
				<button
					(click)="menuProvider.applyFilterInTimetable()"
					*ngIf="searchService.query.filter"
					[matTooltipPosition]="'below'"
					aria-label="Enter"
					class="searchEnterButton"
					mat-button
					matTooltip="{{ 'SEARCH_BUTTON_TT' | translate }}"
				>
					<i class="material-icons mainToolbarIconSearch">keyboard_return</i>
				</button>
				<button
					(click)="clearSearch()"
					*ngIf="searchService.query.filter"
					[matTooltipPosition]="'below'"
					aria-label="Clear"
					class="searchClearButton"
					mat-button
					matTooltip="{{ 'SEARCH_DELETE_TT' | translate }}"
				>
					<i class="material-icons mainToolbarIconSearch">close</i>
				</button>
			</mat-form-field>
			<button
				[matMenuTriggerFor]="menu"
				[matTooltipPosition]="'below'"
				[matTooltipShowDelay]="500"
				class="AdvSrchSettingButton"
				mat-button
				matTooltip="{{ 'SEARCH_SHOW_OPTIONS_TT' | translate }}"
			>
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu" xPosition="after">
				<div class="AdvSrchSettingMenu">
					<button (click)="$event.stopPropagation()" mat-menu-item>
						<mat-checkbox [(ngModel)]="userSettings.searchSettings.excludeDriversHomeaddress">
							{{ 'EXCLUDEDRIVERSHOMEADDRESS' | translate }}
						</mat-checkbox>
					</button>
					<button (click)="$event.stopPropagation()" mat-menu-item>
						<mat-checkbox [(ngModel)]="userSettings.searchSettings.showAllJobsOfDriver"
							>Alle
							{{ 'SHOWALLJOBSOFDRIVER' | translate }}
						</mat-checkbox>
					</button>
					<button (click)="$event.stopPropagation()" mat-menu-item>
						<mat-checkbox [(ngModel)]="userSettings.searchSettings.excludeAppointmentAddress">
							{{ 'EXCLUDEAPPOINTMENTADDRESS' | translate }}
						</mat-checkbox>
					</button>
					<button (click)="$event.stopPropagation()" mat-menu-item>
						<mat-checkbox [(ngModel)]="userSettings.searchSettings.showJobsWithActionOnChoosenDay">
							{{ 'SHOWJOBSWITHACTIONONCHOOSENDAY' | translate }}
						</mat-checkbox>
					</button>
					<mat-slide-toggle
						(click)="$event.stopPropagation()"
						*ngIf="(menuProvider.$currentTab | async) === MENU.WEEK_PLANNING"
						[(ngModel)]="userSettings.searchSettings.searchScope"
						class="sliderCustomWidth"
					>
						<span *ngIf="!userSettings.searchSettings.searchScope" class="mat-checkbox-label">
							{{ 'SEARCHSCOPE_DAY' | translate }}
						</span>
						<span *ngIf="userSettings.searchSettings.searchScope" class="mat-checkbox-label">
							{{ 'SEARCHSCOPE_WEEK' | translate }}
						</span>
					</mat-slide-toggle>
				</div>
				<div class="AdvSrchSettingMenuBtn">
					<button
						(click)="saveFilter()"
						[matTooltipPosition]="'below'"
						[matTooltipShowDelay]="300"
						class="buttonCancel mat-raised"
						mat-button
						matTooltip="{{ 'FILTER_SAVE_FILTER' | translate }}"
					>
						<mat-icon>
							<i class="material-icons">save</i>
						</mat-icon>
						{{ 'SAVE' | translate }}
					</button>
					<button
						(click)="menuProvider.applyFilterInTimetable()"
						class="importButtonDone mat-primary_flutaro"
						mat-button
					>
						<mat-icon><i class="material-icons material-icons-white">search</i></mat-icon>
						{{ 'SEARCH' | translate }}
					</button>
				</div>
			</mat-menu>
		</div>
		<div class="hidden-xs" fxLayout="row" fxLayoutAlign="start center">
			<button
				(click)="showHideSearch()"
				*ngIf="menuProvider.isSearchActive"
				[matTooltipPosition]="'below'"
				class="mainToolbarButton"
				mat-button
				matTooltip="{{ 'SEARCH_IS_ACTIVE_TT' | translate }}"
			>
				<mat-icon class="material-icons mainToolbarIcon">search</mat-icon>
				<div class="mainToolbarLabel">{{ 'SEARCH' | translate }}</div>
			</button>
		</div>
		<button
			(click)="showHideSidenav('INFO')"
			*ngIf="menuProvider.isInfoActive"
			[matTooltipPosition]="'below'"
			class="mainToolbarButton hidden-xs"
			mat-button
			matTooltip="{{ 'STATISTICS_SHOW_HIDE_TT' | translate }}"
		>
			<mat-icon class="material-icons mainToolbarIcon">timeline</mat-icon>
			<div class="mainToolbarLabel">{{ 'STATISTICS' | translate }}</div>
			<div class="statisticBadgeMenuRow" fxLayout="row">
				<span
					*ngIf="timetableProvider.filterStatusStore.$isActiveFilterMode | async"
					[class.showBadgeAnimation]="timetableProvider.filterStatusStore.$isActiveFilterMode | async"
					[matTooltipPosition]="'below'"
					class="statistic-badge"
					matTooltip="{{ 'STATISTICS_IS_ACTIVE_FILTER_TT' | translate }}"
				>
					<i class="material-icons statistic-badgeIcon">filter_list</i>
				</span>
				<span
					*ngIf="(timetableProvider.$referenceDateFilteredInvalidJobs | async).length"
					[class.showBadgeAnimation]="(timetableProvider.$referenceDateFilteredInvalidJobs | async).length"
					[matTooltipPosition]="'below'"
					class="statistic-decline-badge"
					matTooltip="{{ 'JOBS_INVALID_NEEDS_ACTION_TT' | translate }}"
				>
					<i class="material-icons statistic-badgeIcon">filter_list</i>
				</span>
			</div>
		</button>
		<button
			(click)="showHideSidenav('NEW_JOBS')"
			*ngIf="menuProvider.isNewJobsView"
			[disabled]="authFb.$userProfile | async | isReadonly"
			[matTooltipPosition]="'below'"
			class="mainToolbarButton"
			mat-button
			matTooltip="{{ 'DASHBOARD_SHOW_HIDE_NEW_JOBS_TT' | translate }}"
		>
			<mat-icon class="material-icons mainToolbarIcon">notifications_none</mat-icon>
			<div class="mainToolbarLabel">{{ 'NEW_JOBS_NEW' | translate }}</div>
			<div class="jobsNewButtonMenuRow" fxLayout="row">
				<span
					*ngIf="(timetableProvider.$referenceDateUnassignedJobs | async).length > 0"
					[class.showBadgeAnimation]="(timetableProvider.$referenceDateUnassignedJobs | async).length"
					[matTooltipPosition]="'below'"
					class="notifications-badge"
					matTooltip="{{ 'DASHBOARD_NEW_JOBS_DAY_TT' | translate }}"
					>{{ (timetableProvider.$referenceDateUnassignedJobs | async).length }}
				</span>
				<span
					*ngIf="!timetable?.isDayTimetable"
					[class.showBadgeAnimation]="(timetableProvider.$unassignedJobs | async).length"
					[matTooltipPosition]="'below'"
					class="notifications-badge-today"
					matTooltip="{{ 'DASHBOARD_NEW_JOBS_TIMEFRAME_TT' | translate }}"
					>{{ (timetableProvider.$unassignedJobs | async).length }}
				</span>
			</div>
		</button>
		<button
			(click)="showHideSidenav('CHAT')"
			*ngIf="menuProvider.isChatActive && (authFb.$userProfile | async).hasAppActivatedRole"
			[matTooltipPosition]="'below'"
			class="mainToolbarButton hidden-xs"
			mat-button
			matTooltip="{{ 'CHAT_SHOW_HIDE_TT' | translate }}"
		>
			<mat-icon class="material-icons mainToolbarIcon">chat_bubble_outline</mat-icon>
			<div class="mainToolbarLabel">{{ 'CHAT' | translate }}</div>
			<span
				*ngIf="(chatProvider.$channels | async | filterKeyForValue: 'newCompanyMessage':true).length"
				[matTooltipPosition]="'below'"
				class="chat-badge showBadgeAnimation"
				matTooltip="{{ 'CHAT_CHANNEL_WITH_MESSAGES_TT' | translate }}"
			>
				{{ (chatProvider.$channels | async | filterKeyForValue: 'newCompanyMessage':true).length }}
			</span>
		</button>
		<button
			(click)="clickMissionControlButton()"
			*ngIf="menuProvider.isToolbarView"
			[matTooltipPosition]="'below'"
			class="mainToolbarButton hidden-xs"
			mat-button
			matTooltip="{{ 'MENU_TT' | translate }}"
		>
			<mat-icon *ngIf="!showTimetableToolbar" class="material-icons mainToolbarIcon">expand_more</mat-icon>
			<div *ngIf="!showTimetableToolbar" class="mainToolbarLabel">{{ 'MORE' | translate }}</div>
			<mat-icon *ngIf="showTimetableToolbar" class="material-icons mainToolbarIcon">expand_less</mat-icon>
			<div *ngIf="showTimetableToolbar" class="mainToolbarLabel">{{ 'LESS' | translate }}</div>
		</button>
	</div>
	<div fxFlex fxLayoutAlign="end center">
		<button
			[matMenuTriggerFor]="menuUserSettings"
			[matTooltipPosition]="'left'"
			class="accountButton"
			mat-icon-button
			matTooltip="{{ 'MENU_SHOW_USER_MENU_TT' | translate }}"
		>
			<mat-icon class="accountButtonIcon">account_box</mat-icon>
		</button>
		<mat-menu #menuUserSettings="matMenu">
			<button (click)="openUserSettings()" mat-menu-item>
				<mat-icon>settings</mat-icon>
				<span class="matMenuText">{{ 'USER_SETTING' | translate }}</span>
			</button>
			<p class="dividerMenuDotted" *ngIf="companySettings.isTenantActivated"></p>
			<div
				(click)="$event.stopPropagation()"
				disabled
				*ngIf="companySettings.isTenantActivated"
				mat-menu-item
				fxLayout="column"
				fxLayoutAlign="center start"
			>
				<app-tenant
					[tenant]="userProfile.tenant"
					(tenantChange)="changeAppTenant($event)"
					[companySettings]="companySettings"
					[disabled]="(userProfile | isTenantAdmin) === false"
					[isFlutaroHeader]="true"
				>
				</app-tenant>
			</div>

			<p class="dividerMenuDotted"></p>
			<a (click)="authFb.signOut()" class="noTextDecoration" mat-menu-item>
				<mat-icon>exit_to_app</mat-icon>
				<span class="matMenuText">{{ 'LOGOUT' | translate }}</span>
			</a>
		</mat-menu>
	</div>
	<p *ngIf="companySettings.isTenantActivated" class="selectedTenant">
		{{ userProfile.tenant?.id | translateTenant: companySettings.tenants }}
	</p>
</mat-toolbar>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TimetableJobDisplaySettings, TimetableJobDisplayTitle } from '@flutaro/package/lib/model/UserSettings';

@Component({
	selector: 'app-settings-timetable-job-titles',
	templateUrl: './settings-timetable-job-titles.component.html',
	styleUrls: ['./settings-timetable-job-titles.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsTimetableJobTitlesComponent {
	@Input() settings: TimetableJobDisplaySettings;
	@Output() settingsChange: EventEmitter<TimetableJobDisplaySettings> = new EventEmitter<TimetableJobDisplaySettings>();
	JOB_DISPLAY_TITLE = TimetableJobDisplayTitle;

	constructor() {}

	changeJobsTitle(newJobsTitle: TimetableJobDisplayTitle, attribute: string) {
		this.settings[attribute] = newJobsTitle;
		this.settingsChange.emit(this.settings);
	}
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableDeleteDialogComponent } from './table.delete.dialog.component';
import { FlutaroDataService } from '../data/FlutaroService';
import { isDate } from 'date-fns';
import { AppDialogSettings } from 'src/app/dialog/AppDialog';
import { prepareUserQuery, transformSearchString } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { TranslateService } from '@ngx-translate/core';
import { getValueFromStringPath } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { createDialogConfig } from '../form/FlutaroFormFunctions';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { lastValueFrom } from 'rxjs';
import { Synonym } from '@flutaro/package/lib/model/Synonym';
import { AppBasicComponent } from '../app/components/app.components.basic.component';

@Component({
	selector: 'app-basic-table',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlutaroTableComponent extends AppBasicComponent implements OnInit, OnChanges {
	@Input() data;
	@Input() isDataLoading: boolean;
	dataSource;
	dataDialog;
	// Set values and functions in inheriting child class
	collectionName: FlutaroCollection;
	elementTypeName: string; // TODO: refactor and delete me to translate collectionName instead
	displayedColumns: string[];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		public dialog: MatDialog,
		public dataService: FlutaroDataService<any>,
		public translate: TranslateService,
	) {
		super();
	}

	ngOnInit() {
		// mat-paginator: german translation
		this.paginator._intl.itemsPerPageLabel = this.translate.instant('ELEMENT_PER_PAGE');
		this.paginator._intl.nextPageLabel = this.translate.instant('NEXT_PAGE');
		this.paginator._intl.previousPageLabel = this.translate.instant('PREVIOUS_PAGE');
		this.paginator._intl.firstPageLabel = this.translate.instant('FIRST_PAGE');
		this.paginator._intl.lastPageLabel = this.translate.instant('LAST_PAGE');
		this.paginator._intl.getRangeLabel = this.germanRangeLabel;
		this.initTableSettings();
	}

	initTableSettings() {}

	initDataSource(data) {
		this.dataSource = new MatTableDataSource(this.dataFilter(data));
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
			const value = getValueFromStringPath(data, sortHeaderId);
			if (!value) {
				console.log(`initDataSource, couldnt get value for ${sortHeaderId}`);
				return;
			}

			switch (typeof value) {
				case 'number':
					return value.toString();
				case 'string':
					return value.toLowerCase();
				case 'object':
					if (value instanceof Synonym) {
						return value.toStringReverse()?.toLowerCase();
					}
					if (isDate(value)) return value;
					return value.toString();
				default:
					console.log(`Unknown table-sort type. Header: ${sortHeaderId}, Value:
			${JSON.stringify(value)}`);
			}
		};
	}

	applyFilter(event: KeyboardEvent) {
		const target = event.target as HTMLTextAreaElement;
		const filterValue = target.value;
		let filterSearch = prepareUserQuery(filterValue);
		this.initDataSource(
			this.data.filter((data) => transformSearchString(this.buildDataSearchString(data)).includes(filterSearch)),
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes['data']) return;
		this.initDataSource(this.data);
	}

	showEditDialog(data: any): void {
		let editConfig = createDialogConfig();
		editConfig.data = this.createDialogData(data);
		this.dialog.open(this.dataDialog, editConfig);
	}

	async showDeleteDialogAndDeleteOnConfirm(data: any, nameOfElement: string): Promise<boolean> {
		let deleteConfig = new MatDialogConfig();
		deleteConfig.data = { dataType: this.elementTypeName, dataName: nameOfElement };
		let dialogRef = this.dialog.open(TableDeleteDialogComponent, deleteConfig);
		const isConfirmedDelete = await lastValueFrom(dialogRef.afterClosed());
		if (!isConfirmedDelete) {
			return false;
		}
		await this.dataService.remove(data);
		return true;
	}

	protected buildDataSearchString(data) {
		return data.toString();
	}

	protected dataFilter(data: any[]) {
		return data;
	}

	protected createDialogData(data: any): AppDialogSettings<any> {
		return new AppDialogSettings(this.userSettings, this.userProfile, this.companySettings, data, this.collectionName);
	}

	protected germanRangeLabel = (page: number, pageSize: number, length: number): string => {
		const of_label = this.translate.instant('OF');
		if (length == 0 || pageSize == 0) return `0 ${of_label} ${length}`;

		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return `${startIndex + 1} - ${endIndex} ${of_label} ${length}`;
	};
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FlutaroDriverService } from '../data/data-services/data.driver.service';
import { FlutaroVehicleService } from '../data/data-services/data.vehicle.service';
import { FlutaroTableComponent } from '../table/table.component';
import { VehicleDialogComponent } from './vehicle.dialog.component';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { VehicleDialogData } from 'src/app/dialog/AppDialog';
import { TranslateService } from '@ngx-translate/core';
import { getVehiclesCurrentDriver } from '@flutaro/package/lib/functions/VehicleFunctions';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';

@Component({
	selector: 'app-vehicle-table',
	templateUrl: './vehicle.table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTableComponent extends FlutaroTableComponent {
	@Input() data: Vehicle[];

	constructor(
		private vehicleService: FlutaroVehicleService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public driverProvider: FlutaroDriverService,
	) {
		super(dialog, vehicleService, translate);
	}

	initTableSettings() {
		this.elementTypeName = this.translate.instant('VEHICLE');
		this.collectionName = FlutaroCollection.VEHICLE;
		this.displayedColumns = [
			'name',
			'type',
			'carType',
			'licensePlate',
			'specialAttributes',
			'carmanufacturer',
			'loadUnitCapacity',
			'edit',
			'delete',
		];
		if (!this.companySettings.isDriverDeactivated) this.displayedColumns.splice(1, 0, 'driver');
		this.dataDialog = VehicleDialogComponent;
	}

	async showDeleteDialogAndDeleteOnConfirm(vehicle: Vehicle) {
		return await super.showDeleteDialogAndDeleteOnConfirm(vehicle, `${vehicle.name}/${vehicle.licensePlate}`);
	}

	createDialogData(vehicle: Vehicle): VehicleDialogData {
		return new VehicleDialogData(
			this.userSettings,
			this.userProfile,
			this.companySettings,
			vehicle,
			this.data,
			this.drivers,
		);
	}

	protected buildDataSearchString(vehicle: Vehicle): string {
		let searchCombinedString =
			vehicle.name + vehicle.carType + vehicle.licensePlate + vehicle.specialAttributes + vehicle.carmanufacturer;
		const driver = getVehiclesCurrentDriver(vehicle, this.drivers);
		if (driver) searchCombinedString += driver.toString();
		return searchCombinedString;
	}
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { WebAuthService } from './auth/web-auth.service';
import { Observable, catchError, lastValueFrom } from 'rxjs';

@Injectable()
export class AppTokenForceRefreshInterceptor implements HttpInterceptor {
	constructor(private auth: WebAuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(async (error: HttpErrorResponse) => {
				// Routing.api: 403, others: 401
				if (error.status === 401 || error.status === 403) {
					console.log(`AppTokenForceRefreshInterceptor, auth-token-error detected`);
					const newToken = await this.auth.getIdToken(true);
					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${newToken}`,
						},
					});
				}
				return await lastValueFrom(next.handle(request));
			}),
		);
	}
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FlutaroNotificationsProvider } from '../../notifications/notification.snack.provider';
import { addMinutes } from 'date-fns';
import { JobDestination } from '@flutaro/package/lib/model/Job';
import { DestinationDialogData } from 'src/app/dialog/AppDialog';
import { destinationDatesAreValid } from '@flutaro/package/lib/functions/job/JobValidationFunctions';

@Component({
	selector: 'app-job-adjust-dates-dialog',
	templateUrl: './job.dates.adjust.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobAdjustDatesDialogComponent implements OnInit {
	disabled: boolean;
	destination: JobDestination;
	minDate: Date;

	constructor(
		public dialogRef: MatDialogRef<JobAdjustDatesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: DestinationDialogData,
		private notifications: FlutaroNotificationsProvider,
	) {}

	ngOnInit() {
		this.disabled = this.dialogData.disabled;
		this.destination = this.dialogData.destination;
		const prevDestination = this.dialogData.prevDestination;
		this.minDate = addMinutes(prevDestination.earliestDate, this.destination.timeInMinutes);
	}

	setDateInDestinationAsEarliestDate(date: Date) {
		this.destination.earliestDate = date;
	}

	onSave() {
		this.destination.plannedDate = this.destination.earliestDate;
		if (!destinationDatesAreValid(this.destination)) {
			this.notifications.showInvalidDestinationsError();
			return;
		}
		this.dialogRef.close(this.destination);
	}

	onAbort() {
		this.dialogRef.close(false);
	}
}
